<template>
<b-container class="position-relative m-h-400" fluid ref="containerLoader">
  <div class="conta-empresa box-formulario d-flex align-items-center justify-content-center">
    <div class="row" v-if="!loadingEvent">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center">
            <p style="text-center">{{$t("Enter below the data of the manager of the Company Account")}}</p>
          </div>
          <div class="col-md-12 d-flex justify-content-center foto-perfil">
            <img
              :src="service.entidade.fotoAvatar"
              alt="cover"
              v-if="service.entidade.fotoAvatar"
            />
            <img src="/static/img/avatar.png" alt="cover" v-else />
          </div>
          <div class="col-md-12 my-4 d-flex justify-content-center">
            <file-button-icon @change="handleAvatarChange" :btnClass="'btn btn-info btn-change-user-picture text-uppercase rounded-pill'">
              {{$t('Replace user photo')}}
            </file-button-icon>
          </div>
          <div class="col-md-6">
            <text-input
              variant="dark"
              class="text-uppercase"
              :required="true"
              :label="$t('First name')"
              :placeholder="'John'"
              v-model="service.entidade.primeironome"
            />
          </div>
          <div class="col-md-6">
            <text-input
              variant="dark"
              class="text-uppercase"
              :required="true"
              :label="$t('Last name')"
              :placeholder="'Smith'"
              v-model="service.entidade.sobrenome"
            />
          </div>
          <div class="col-md-6">
            <text-input
              variant="dark"
              class="text-uppercase"
              :required="true"
              :label="$t('E-mail')"
              :placeholder="'john.smith@jobecam.com'"
              v-model="service.entidade.email"
            />
          </div>
          <div class="col-md-6">
            <select-input
              :optionList="idiomaSrv.optionSiglaList"
              class="text-uppercase"
              variant="dark"
              :required="true"
              :label="$t('Language')"
              :placeholder="'Português'"
              v-model="service.entidade.lingua"
            />
          </div>
          <div class="col-md-6 phone">
            <label :class="phoneStyle">{{ $t("Telephone") }}*</label>
            <vue-tel-input
              required
              v-model="service.entidade.perfil.telefones"
              placeholder="e.g. 11999999999"
              class="tel-input"
              defaultCountry="br"
              @input="handleChangeInputTel"
              v-bind="vueTelProps"
            ></vue-tel-input>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 d-flex align-items-center justify-content-center mt-5">
        <button type="button" class="btn btn-info" @click.prevent="save" v-promise-btn="{promise}">
          <i class="fas fa-check mr-2"></i>{{$t("Save")}}
        </button>
      </div>
    </div>
  </div>
</b-container>
</template>

<script lang="ts">
import FileButtonIcon from '@/components/button-icon/FileButtonIcon.vue';
import Idioma from "@/core/Services/Basic/Idioma";
import Empresa from '@/core/Services/Empresa/Empresa';
import { siteCandidateEndPoint } from '@/core/endpoint';
import { EmpresaEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component<ContaEmpresa>({
  components: {
    'file-button-icon': FileButtonIcon
  }
})
export default class ContaEmpresa extends Vue {
  public service: Empresa;
  public idiomaSrv: Idioma;
  public promise: Promise<any>|null = null;
  public currentURL: string = siteCandidateEndPoint||'';
  public editURL: boolean = false;
  public loading: boolean = false;
  public nomeUrl: string ="";

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;

  constructor() {
    super();
    this.service = new Empresa();
    this.idiomaSrv = new Idioma();
  }

  public async mounted() {
    this.loading = true;
    if(!this.empresa) {
      const _: any = this;
      _.loadingEvent = true;
      let loader: any = this.$loading.show({
        // Optional parameters
        container: this.$refs.containerLoader,
        isFullPage: false,
        canCancel: false,
        loader: 'dots'
      });
      await this.fetchEmpresaInfo();
      _.loadingEvent = false;
      loader.hide();
    }

    this.$set(this.service,'entidade', JSON.parse(JSON.stringify(this.empresa)));
    this.nomeUrl = this.service.entidade.nomeUrl || "";
    this.loading = false;
  }
  
  get phoneStyle() {
    return this.service.entidade!.perfil!.telefones == '' ? 'invalid' : '' 
  }

  
  public handleChangeInputTel(a: any,b: any) {
    if (this.service.entidade.perfil)
      this.service.entidade.perfil.telefones = b.number.international
  }
  
  
  get companyURL() {
    return (this.service.entidade.nomeUrl) ? this.currentURL+'/e/'+this.service.entidade.nomeUrl : '...';
  }

  public save() {
    this.promise = this.service.save().then((valid: boolean) => {
      if (valid) {
        this.$alert.addMsg(this.$t('Saved successfully'));
        this.$alert.callBoxCheck('OK');
        this.editURL = false;
      }
      this.fetchEmpresaInfo()
    });
  }
  
  public onCopy() {
    this.$alert.addMsg(""+this.$t("Jobecam profile link copied successfully"));
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public onError() {
    console.log("not copied");
  }

  public handleAvatarChange(file: any) {
    if (file) {
      this.service.saveAvatar(file)
      .then(async (data: any) => {
        this.$alert.addMsg(this.$t('Saved successfully'))
        this.$alert.callBoxCheck('Ok');
        await this.fetchEmpresaInfo();
        this.service.entidade.fotoAvatar = this.empresa?.fotoAvatar;
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.box-formulario {
    border: 1px solid $border-gray;
    border-radius: 8px;

    .foto-perfil {
      img {
        width: 6em;
        height: 6em;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .phone {
    height: 72px;

    .vti__dropdown {
      padding: 8px;
      // border-bottom: white solid;
    }
  }
  .box-formulario {
    padding: 2em 0;
    > div {
      width: calc(50% - 1em);
    }
    .requisitos-troca-senha {
      padding: 0;
      list-style: none;
      & li {
        background-position: 0 3px;
        background-size: 15px;
        padding-bottom: 0.8em;
        color: $purple;
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f05a";
          padding-right: 0.8em;
        }
      }
    }
  }

  
@include media("<desktop") {
    .box-formulario {
      > div {
        width: 100%;
      }
    }
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
}
</style>