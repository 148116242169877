<template>
  <div class="bar">
    <canvas  ref="canvas" class="bar-chart circle01"></canvas>  

  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';

@Component<BarChart>({
  extends: Bar
})
export default class BarChart extends Mixins(Bar) {
  @Prop()
  private seriesOne!: any[];

  @Prop()
  private seriesTwo!: any[];

  @Prop({default: true})
  private displayLegend!: boolean;

  @Prop()
  private labels!: string[];

  @Prop({ default: '' })
  private cColors!: string;

  @Prop({ default: '' })
  private vColors!: string;

  @Prop({ default: [] })
  private borderColor!: string[];

  private seriesColors: {[key: string]: string} = {};

  public  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          label: this.$t('Candidate').toString(),
          // barThickness: 'flex',
          barPercentage: 0.5,
          categoryPercentage: 0.2,
          backgroundColor: this.cColors,
          data: this.seriesOne,
        },
        {
          label: this.$t('Vacancy').toString(),
          // barThickness: 'flex',
          barPercentage: 0.5,
          categoryPercentage: 0.2,
          backgroundColor: this.vColors,
          data: this.seriesTwo,
        }
      ]     
    }, 
    {
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          fontSize: 14
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .bar-chart {
    height: 100%;
  }
  .bar {
    position: relative;
    min-height: 300px
  }
  .caption {
    font-family: Lato,sans-serif;
    color: #222;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 225px;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  .radar .circle01 {
    position: absolute;
    top: 0px;
    left: 0px;
  }
</style>