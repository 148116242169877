<template>
  <b-modal :id="id" :size="size" :hide-header="true" :hide-footer="true" >
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-end" >
          <i class="fa fa-times cursor-pointer" @click="hideModal(id)"></i>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-center" style="position:relative"  >
          <lootie animation="share" :show="true" :autoplay="true" :loop="true" :width="100" :height="100" v-model="lottie"/>
        </div>
      </b-col>
      <b-col cols="12" class="text-center mt-3">
        {{posicao}} 
      </b-col>
      <b-col cols="12" class="text-center mt-3">
        <strong class="text-uppercase"> {{$t("Invite Talents Sharing this link")}} </strong>
      </b-col>
      <b-col cols="12" class="text-center my-4">
        <h5 class="text-secondary"> {{ value }} </h5>
      </b-col>
      <b-col cols="5" class="text-center mx-auto">
        <div class="shadow img-thumbnail img-fluid rounded" style="width:100%">
          <qrcode v-if="value" :value="value" :options="{ width: 150 }" tag="img"></qrcode>
        </div>
        <small>{{$t('QRCode to access interview')}} </small>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import LootieAnimation from '@/components/loader/LootieAnimation.vue';


@Component({
  components: {
    'lootie': LootieAnimation
  }
})
export default class ModalQRCode extends Vue {
  @Prop({default: ""})
  public value!: string;
  
  @Prop({default: ""})
  public posicao!: string;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: 'sm'})
  public size!: string;

  @Prop({default: ''})
  public id!: string;
  
  @Prop({default: ''})
  public headerClass!: string;

  public lottie: any = null;

  constructor() {
    super()
  }
  
  public hideModal(id) {
    this.$bvModal.hide(id)
  }

}
</script>

<style>

</style>