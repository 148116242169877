<template>
  <b-row>
    <b-col md="12">
      <vue-cal 
        ref="calendar"
        :cell-click-hold="false" 
        :disable-views="['years', 'year', 'week']"
        :time-from="8 * 60"
        :time-to="19 * 60"
        :locale="getLang"
        default-view="month"
        @view-change="startDate"
        show-all-day-events
        events-on-month-view="long"
        :events="events"
        style="height: 600px; width:100%"
        :on-event-click="onEventClick"
      >
      </vue-cal>
      <b-modal :id="'modalCalendarEvent'" size="lg" :header-class="{
        'bg-info': agendaStatus == 'ALTERADA',
        'bg-danger':  agendaStatus == 'CANCELADO',
        'bg-primary': agendaStatus == 'CONFIRMADO',
        'bg-light': ['ALTERADA','CONFIRMADO','CANCELADO'].indexOf(agendaStatus) == -1 
      }" :footer-class="'cal-foot'" >
        <template v-slot:modal-header>
          <div class="w-100 d-flex justify-content-between" v-if="selectedEvent">
            <div>
              <i class="far fa-eye-slash" v-if="selectedEvent.agenda.nomeCandidato == 'Candidato'" />
              {{$t(selectedEvent.agenda.nomeCandidato)}}</div>
            <strong>{{ selectedEvent.startDateSTR }} - {{ selectedEvent.endDateSTR }}</strong>
          </div>
        </template>
        <template v-slot:modal-footer v-if="selectedEvent">
          <b-button @click="done" variant="success">
            {{$t('Ok')}}
          </b-button>
          <b-button @click="editEvent(selectedEvent)" variant="info" v-if="selectedEvent && selectedEvent.agenda.status != 'CANCELADO'">
            <i class="fa fa-edit"></i> {{$t('Edit Event')}}
          </b-button>
          <b-button @click="deleteEvent(selectedEvent)" variant="danger" v-if="selectedEvent && selectedEvent.agenda.status != 'CANCELADO'">
            <i class="fa fa-time"></i> {{$t('Cancel Event')}}
          </b-button>
        </template>

        <div class="modal-body-m" v-if="selectedEvent">
          <div class="pt-3">
            <div><strong>{{$t('Candidate')}}:</strong> <i class="far fa-eye-slash" v-if="selectedEvent.agenda.nomeCandidato == 'Candidato'" /> {{$t(selectedEvent.agenda.nomeCandidato)}} </div>
            <div v-if="selectedEvent.agenda.posicao"><strong>{{$t('Job')}}:</strong> {{selectedEvent.agenda.posicao}} </div>
            <div><strong>Status:</strong> {{$t(agendaStatusText)}} </div>
            <div>
              <strong>{{$t('Invite Manager')}}: </strong> 
              <a :href="selectedEvent.agenda.publicManagerLink" target="_blank"> {{selectedEvent.agenda.publicManagerLink}} </a> 
            </div>
            <div>
              <strong>{{$t('Invite Candidate')}}: </strong>
              <a :href="selectedEvent.agenda.candidateLink" target="_blank"> {{selectedEvent.agenda.candidateLink}} </a> 
            </div>
            <div>
              <strong>{{$t('Access')}}: </strong>
              <a :href="'/room/'+selectedEvent.agenda.roomToken" target="_blank"> {{$t('Recruiter')}} </a> 
            </div>
          </div>
          <div v-if="selectedEvent.agenda.registros">
          <strong>{{$t('Observations')}}:</strong>
            <div v-for="registro in selectedEvent.agenda.registros" :key="registro.id">
              <p class="mb-0" v-if="registro.obsRecrutador">
                 <i><strong>{{$t('Recruiter')}}</strong>: {{registro.obsRecrutador}}</i> 
              </p>
              <p class="mb-0" v-if="registro.obsCandidato">
                <i><strong>{{$t('Candidate')}}</strong>:  {{registro.obsCandidato}}</i>
              </p>
              
            </div>
          </div>
        </div>

      </b-modal>
      <modal-agendar id="modalCalendar" :edit="true" :registryId="registryId" @success="eventSuccessfullyEdited"/>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EntrevistaAgenda from '@/core/Services/Calendar/EntrevistaAgenda';
import { VagaEntity, CandidaturaEntity, AgendaEntrevistaEntity, DateEntity } from "@/core/models/Entities";
import Modal from '@/components/modal/Modal.vue';
import ModalCreateInterviewEvent from "@/components/modal/ModalCreateInterviewEvent.vue";
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/pt-br.js'
import 'vue-cal/dist/i18n/es.js'

@Component({
  components: {
    VueCal,
    'modal-agendar': ModalCreateInterviewEvent,
    Modal
  }
})
export default class CalendarioRoom extends Vue {
  public vagaId!: number;
  public candidatoId!: number;
  public agendaSRV!: EntrevistaAgenda;
  public events: any[] = [];
  public agendaList: AgendaEntrevistaEntity[] = [];
  public locale: any = 'pt-br';
  public show: boolean = false;
  public selectedEvent: any| null = null;
  public currentDate: Date =  new Date();
  

  constructor() {
    super();
    this.agendaSRV = new EntrevistaAgenda()
  }

  get registryId() {
    return this.selectedEvent?.agenda?.id
  }
  get agendaStatus() {
    return this.selectedEvent?.agenda?.status
  }
  get agendaStatusText() {
    switch (this.agendaStatus) {
      case 'ALTERADA':
        return 'Changed'; 
      case 'RECUSADA':
        return 'Refused'; 
      case 'CONFIRMADA':
        return 'Confirmed';
      case 'ATIVA':
        return 'Active';
      case 'CANCELADA':
        return 'Canceled';
      default:
        return '-'
    } 
  }
  public startDate(dt: any) {
    if(dt.startDate instanceof Date)
      this.currentDate = dt.startDate;
    this.loadEvents()    
  }

  public mounted() {
    this.loadEvents()    
  }

  public loadEvents() {
    this.agendaSRV.fetchAll({ mes: this.currentDate.getMonth()+1 }).then((data: any) => {
      this.agendaList = data['entrevistasAgendadas']
      this.events = []
      this.agendaList.forEach((agenda: AgendaEntrevistaEntity) => {
        
        this.events.push({
          start: this.$showDateResponse(agenda.dataInicio!.date+''),
          end: this.$showDateResponse(agenda.dataFim!.date+''),
          startDateSTR: this.$showDateFromObject(agenda.dataInicio!),
          endDateSTR: this.$showDateFromObject(agenda.dataFim!).substring(11,16),
          title: `${this.$t('Interview')} ${(agenda.nomeCandidato == 'Candidato' ? '<i class="far fa-eye-slash" />' : '')} ${this.$t(agenda.nomeCandidato)}`,
          content: `<small><i class="fa fa-suitcase"></i> ${agenda.posicao} </small>`,
          id: agenda.id,
          agenda,
          class: agenda.status,
          token: agenda.roomToken
        })
      })
    }).catch((err: any) => {
			console.log(err)
    })
  }

  public done() {
    this.$bvModal.hide('modalCalendarEvent');
  }

  public onEventClick(event: any, e: any) {
    this.selectedEvent = event
    this.$bvModal.show('modalCalendarEvent');

    // Prevent navigating to narrower view (default vue-cal behavior).
    e.stopPropagation()
  }

  public editEvent(event) {
    
    let msg: any = this.$t('Are you sure you want to edit this interview?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if(v) {
        this.selectedEvent = event;
        this.$bvModal.show('modalCalendar')
      }
    });
  }

  public eventSuccessfullyEdited(agenda: any) {
    
    this.$bvModal.hide('modalCalendarEvent')
    const index:number = this.events.findIndex((e: any) => { return e.id == agenda.id });
    console.log(index);
    if(index > -1) {
    this.events.splice(index,1)
    this.events.push({
          start: this.$showDateResponse(agenda.dataInicio!.date+''),
          end: this.$showDateResponse(agenda.dataFim!.date+''),
          startDateSTR: this.$showDateFromObject(agenda.dataInicio!),
          endDateSTR: this.$showDateFromObject(agenda.dataFim!).substring(11,16),
          title: `${this.$t('Interview')} ${(agenda.nomeCandidato == 'Candidato' ? '<i class="far fa-eye-slash" />' : '')} ${this.$t(agenda.nomeCandidato)}`,
          content: `<small><i class="fa fa-suitcase"></i> ${agenda.posicao} </small>`,
          id: agenda.id,
          agenda,
          class: agenda.status,
          token: agenda.roomToken
        })
    }
  }
  public deleteEvent(event) {
    let msg: any = this.$t('Are you sure you want to cancel this interview?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  

      if (v) {
        this.agendaSRV.delete({id: event.id}).then(() => {
          this.$bvModal.hide('modalCalendarEvent');
          this.events = this.events.filter(obj => {
            return obj.id !== event.id
          })
          this.successAlert(this.$t('Event successfully canceled').toString())
        }).catch((err: any) => {
          console.log('err')
          console.log(err)
          this.errorAlert(this.$t('Oops! Something went wrong').toString())
        });
      }
    });
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(this.$t('Success').toString());
    this.$toast.open();
  }

  public errorAlert(msg: string) {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(this.$t('Error').toString());
    this.$toast.open();
  }

  get getLang() {
    if (this.$i18n.locale == 'pt-BR') {
      return 'pt-br'
    }
    if (this.$i18n.locale == 'en') {
      return 'en'
    }
    if (this.$i18n.locale == 'es') {
       return 'es'
    }
    return
    
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.vuecal__cell-events {
  .ATIVA {background-color: var(--info);border: 1px solid #eee;color: #fff;}
  .ALTERADA {background-color: var(--success);border: 1px solid #eee;color: #fff;}
  .RECUSADA {background-color: var(--warning);border: none;color: #fff;}
  .CONFIRMADA {background-color: var(--primary);border: none;color: #fff;}
  .CANCELADA {background-color:var(--danger);border: 1px solid var(--orange);color: #fff;}
}
.cal-head {
  background: $info;
  color: white;
}
.cal-foot {
  background: $light;
}
</style>