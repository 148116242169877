import Cookies from 'js-cookie'

export const insertIntoCookies = (
  key: string,
  value: unknown,
  encrypt?: boolean
) => {
  let valueInString = JSON.stringify(value)

  if (encrypt) {
    valueInString = btoa(valueInString)
  }

  Cookies.set(key, valueInString)
}

export const getValueIntoCookies = (key: string, isEncrypted?: boolean) => {
  if (Cookies.get(key)) {
    if (isEncrypted) {
      return JSON.parse(atob(Cookies.get(key) as string))
    }

    return JSON.parse(Cookies.get(key) as string)
  }
}

export const removeValueIntoCookies = (key: string) => {
  if (Cookies.get(key)) {
    Cookies.remove(key)
  }
}


export default class ShareSession {
    constructor() {
        if (location.href.indexOf('/emp/') > -1) {
            return;
        }
        try {
            const cdata: any = getValueIntoCookies(`logincompanyData`)
            const cdata2: any = getValueIntoCookies(`loginData`)
            if(cdata && cdata.access_token){
                sessionStorage.setItem("token_type", 'Bearer');	
                sessionStorage.setItem("access_token", cdata.access_token);
                sessionStorage.setItem("profile_type", "recruiter");
            }else if(cdata2 && cdata2.access_token){
                sessionStorage.setItem("token_type", 'Bearer');	
                sessionStorage.setItem("access_token", cdata2.access_token);
                sessionStorage.setItem("profile_type", "recruiter");
            }
        }catch(e) {
            //do nothing
        }
        // transfers sessionStorage from one tab to another
        const sessionStorageTransfer = (event: any) => {
            if (!event) { event = window.event; } // ie suq
            if (!event.newValue) { return; }          // do nothing if no value to work with
            if (event.key === 'JB__C_CDL_EVT') {
                // another tab asked for the sessionStorage -> send it
                localStorage.setItem('JB_COMPANY_SESSION_SHARE', JSON.stringify(sessionStorage));
            } else if (event.key === 'JB_COMPANY_SESSION_SHARE' && !sessionStorage.getItem('access_token')) {
                // another tab sent data <- get it
                const data = JSON.parse(event.newValue);
                for (const key in data) {
                    if (key) {
                        sessionStorage.setItem(key, data[key]);
                    }
                }
            }
            setTimeout(() => {
                // the other tab should now have it, so we're done with it.
                localStorage.removeItem('JB_COMPANY_SESSION_SHARE'); // <- could do short timeout as well.
            }, 500);
        };

        // listen for changes to localStorage
        if (window.addEventListener) {
            window.addEventListener('storage', sessionStorageTransfer, false);
        } else {
            (window as any).attachEvent('onstorage', sessionStorageTransfer);
        }

        if (!sessionStorage.length) {
            localStorage.setItem('JB__C_CDL_EVT', 'foobar');
            localStorage.removeItem('JB__C_CDL_EVT');
        }
    }

    public forceCheck() {
        console.log('force Check');
        localStorage.setItem('JB__C_CDL_EVT', 'foobar');
        localStorage.removeItem('JB__C_CDL_EVT');
    }
}

