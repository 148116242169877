<template>
  <b-card class="my-2 py-2 shadow rounded-card" v-if="formacoes && formacoes.length > 0">
    <!-- body -->
    <b-row >
      <b-col md="12" class="mb-3 d-flex justify-content-between alignt-items-end">
        <h3 class="text-info m-0">{{$t('Academic')}}</h3>
      </b-col>
      <b-col md="12"  >
        <!-- <div class="table-responsive">
          <b-table striped :fields="fields" :items="items">
          </b-table>
        </div> -->

        <b-list-group>
          <b-list-group-item v-for="exp in formacoes" :key="exp.id" href="#"  class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{exp.atividade}}</h5>
              <small>{{exp.status||(exp.atual_ == 1 ? $t("Incomplete") : $t("Complete"))}}</small>
            </div>

            <small> {{exp.tipo}} </small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import { FormacaoEntity } from "@/core/models/Entities";

@Component
export default class CardPdfAcademicInfo extends Vue {
  @Prop()
  public formacoes!: FormacaoEntity[];
  
  public items: any[] = [];
  public fields: any[] = [];

  constructor() {
    super();
  }

  public mounted(){
    this.fields = [
      { key: "atividade", label: this.$t('Course')},
      { key: "status", label: this.$t('Status') },
      { key: "tipo", label: this.$t('Type') }
    ];
    // this.loadData()
  }  

  public loadData() {
    this.items = [];
    this.formacoes.forEach((el: any) => {
      this.items.push({
        atividade: el.atividade,
        status: el.status||(el.atual_ == 1 ? this.$t("Incomplete") : this.$t("Complete")),
        tipo: el.tipo||" - "
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
        text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }

}
</script>

