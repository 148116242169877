<template>
  <div class="box-container d-flex flex-column">
    <b-row>
      <b-col md="12" class="mb-4">
        <h3 class="text-md-left text-center">
          <span class="ml-2">{{$t(title)}}</span>
        </h3>
        <div class="text-secondary">
          <small>{{$t(description)}}</small>
        </div>
      </b-col>
      
    </b-row>
     <b-container class="position-relative m-h-400" fluid ref="containerLoader">
      <b-card v-if="filterParams.length > 0">
        <b-form @submit.prevent="loadData(false)">
        <b-row class="mb-2">
          <b-col md="12" v-for="f in filterParams" :key="f.param">
            <b-form-group v-if="f.param == ':vguid'"
              :label="$t('Job')" 
              label-cols-sm="2"
              label-cols-md="2"
              label-cols-lg="2"
              label-class="jb-label-shadow-border"
              label-for="vagaSelect-report">
                  <multi-select 
                    id="vagaSelect-report"
                    class="searh-job-input"
                    v-model="selectedVaga" 
                    :options="vagaOptions" 
                    :multiple="f.type == 'array'"
                    label="text" 
                    track-by="text"
                    :show-labels="false"
                    :placeholder="$t('Select')">
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">{{ $t(props.option.text) }}</div>
                      </template>
                  </multi-select>
            </b-form-group>
            <div v-if="f.param == ':dtIni'"  class="d-flex justify-content-md-between align-items-end">
                <b-form-datepicker id="input-date-pub" class="input -date-pub" v-model="dtIni" value-as-date :locale="$i18n.locale" ></b-form-datepicker>
              <div class="mx-3">{{$t('Until')}}</div> 
              <b-form-datepicker id="input-date-pub-end" class="input-date-pub-end" v-model="dtEnd" value-as-date :locale="$i18n.locale" ></b-form-datepicker>
            </div>
            
            <text-input
              v-if="f.param == ':cinfo'"
              :label="$t('Talent Data')"
              variant="gray-bordered"
              v-model="cInfo"
              label-cols-sm="2"
              label-cols-md="2"
              label-cols-lg="2"
              label-class="jb-label-shadow-border"
              :placeholder="$t(f.placeholder||'Enter your search')"
            />  
            <!-- <b-form-group :label="$t('Dados do Talento')" v-if="f.param == ':cinfo'">
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input-candidate-string"
                  v-model="cInfo"
                  type="search"
                  :placeholder="$t('Digite sua busca')"
                ></b-form-input>
              </b-input-group>
            </b-form-group> -->
            <text-input
              v-if="f.param == ':minfo'"
              :label="$t('Manager data')"
              variant="gray-bordered"
              v-model="mInfo"
              label-cols-sm="2"
              label-cols-md="2"
              label-cols-lg="2"
              label-class="jb-label-shadow-border"
              :placeholder="$t(f.placeholder||'Enter your search')"
            />
          </b-col>
        </b-row>
        <b-row>
          
          <b-col md="12" sm="12" class="mt-2 d-flex justify-content-end">
            <b-button type="submit" variant="outline-info" v-promise-btn="{promise}"  >
              <i class="fas fa-search"></i>
              {{$t('Search')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </b-card>
      <hr v-if="filterParams.length > 0" />
    <!-- User Interface controls -->
    <b-row v-if="items?.length > 0">
      
      <b-col md="6" class="my-2">
        <b-form-group
          
          :label="$t('Filter')"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$t('Enter your search')"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      
      <b-col md="6" class="my-2 d-flex justify-content-center" v-if="items?.length > 0">
          <div class="btn-animation m-2" style="position:relative"  v-show="startDownload" >
              <lootie animation="download" :show="true" :autoplay="true" :width="25" :height="25" v-model="lottieDownload"/>
            </div>
        <b-button variant="outline-primary" size="sm"  @click="downloadReport" v-promise-btn="{promise}">  
              <i class="fas fa-file-download"></i>  Download
          </b-button>
          <a id="downloadLink" href="javascript:void(0)" ></a>
      </b-col>

      <b-col md="6" class="my-2" v-if="totalRows > 50">
        <b-form-group
          :label="$t('By page')"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-2 " v-if="totalRows > 50">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-row v-if="items?.length > 0">
      <b-col md="12" class="mt-4">
          <b-table
            :id="'cp'+reportId"
            :items="items"
            :fields="fields"
            striped
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(status)="row">
              {{ $t(row.item.status) }}
            </template>

            <template #cell(actions)="row">
              <div class="platform-only">
                <b-dropdown size="sm" variant="outline-primary" no-caret class="m-2">
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                  <b-dropdown-item-button @click="info(row.item, row.index, $event.target)" class="mr-1" v-if="row.item.content || row.item.content_html">{{$t('Open content')}}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="linkto(row.item.action_link)" class="mr-1" v-if="row.item.action_link">{{$t('Open Link')}}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="row.toggleDetails" v-if="row.item.details">{{ row.detailsShowing ? '-' : '+' }} {{$t('Details')}} </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </template>

            <template #row-details="row">
              <b-card>
                <b-card-text v-html="row.item.details"></b-card-text>
              </b-card>
            </template>
          </b-table>
      </b-col>
    </b-row>
    <!-- Main table element -->
    
    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only size="lg" @hide="resetInfoModal">
      <pre v-if="infoModal.content">{{ infoModal.content }}</pre>
      <div v-if="infoModal.contentHtml">
        <div id="mail_default_content">
          <div v-html="infoModal.contentHtml"></div>
        </div>
      </div>
    </b-modal>
  </b-container>
  </div>
</template>

<script lang="ts">
import ReportFilter from '@/core/Services/Reports/ReportFilter';
import { EmpresaEntity, ReportFilters } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import ExportTableCustom from './ExportTableCustom';

import LootieAnimation from '@/components/loader/LootieAnimation.vue';
import Dashboard from '@/core/Services/Empresa/Dashboard';
import Multiselect from 'vue-multiselect';

@Component<ReportTablePage>({
  components: {
    'multi-select': Multiselect,
    'lootie': LootieAnimation
  }
})
export default class ReportTablePage extends Vue {

  // <!-- #region attributes -->
  public reportList: ReportFilters[] = []
  private service: ReportFilter = new ReportFilter()
  private serviceDash: Dashboard = new Dashboard()

  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  public items: any[] = [];
  public fields: any[] = [];
  public totalRows: number = 1;
  public reportId: number|null = null;
  public lottieDownload: any = null;
  public promise: Promise<any>|null = null;
  public currentPage: number = 1;
  public perPage: number = 50;
  public pageOptions: any[] = [];
  public sortBy: string = '';
  public sortDesc: boolean = false;
  public startDownload: boolean = false;
  public sortDirection: string = 'asc';
  public filter: any = null;
  public filterParams: any = [];
  public filterOn: any[] = [];
  public title: string = 'Relatório';
  public description: string = '';
  public infoModal: any = {
    id: 'info-modal-custom-report',
    title: '',
    content: '',
    contentHtml: null,
  };

  //filter attributes
  public vguid: string|string[] = "";
  public dtIni: Date|null = null;
  public dtEnd: Date|null = null;
  public cInfo: string|null = null;
  public mInfo: string|null = null;

  public selectedVaga: any = null;
  public vagaOptions: any[] = [];
  
  // <!-- #endregion -->


  // <!-- #region LifecycleHooks -->
  
  public mounted() {
    this.reportId = (+this.$route.params.rid);
    this.loadJobs();
    this.loadData(true)
  }

  // <!-- #endregion -->

  get sortOptions() {
    // Create an options list from our fields
    return this.fields
      .filter(f => f.sortable)
      .map(f => {
        return { text: f.label, value: f.key }
      })
  }

  
  public async loadJobs() {
    const data: any[] = await this.serviceDash.fetchJobsList();
    this.vagaOptions.push({value: '', text: this.$t('All')})
    data.forEach(vagaOptin => {
      this.vagaOptions.push(vagaOptin)
    });
  }

  public loadData(unset?: boolean) {
    if(!this.reportId) return;

    const set: boolean = !unset;
    this.vguid = "";

    if(this.selectedVaga?.value) {
      this.vguid = this.selectedVaga?.value;
    } else if(Array.isArray(this.selectedVaga)) {
      this.vguid = this.selectedVaga.map(_ => _.value)
    }
    const filter: any = {
      id: this.reportId, 
      cinfo: (this.cInfo||""), 
      minfo: (this.mInfo||""), 
      vguid: (this.vguid||""), 
      dtIni: (this.dtIni||""), 
      dtEnd: (this.dtEnd||""),
      set
    };
    const _: any = this;
    _.loadingEvent = true;
    let loader: any = this.$loading.show({
      // Optional parameters
      container: this.$refs.containerLoader,
      isFullPage: false,
      canCancel: false,
      loader: 'dots'
    });

    this.promise = this.service?.fetchWithData(filter)
    .then((data: any) => {
      this.title = data.title;
      this.description = data.description;
      this.items = data.set;
      this.fields = data.fields;
      this.totalRows = this.items?.length
      this.pageOptions = [50, 100, 200, { value: this.totalRows, text: this.$t("All").toString() }]
      this.filterOn = this.fields.map((el:any) => { return el.key })
      this.filterParams = data.filters?.filter((el:any) => { return el.param != ":eid" })
    })
    .catch((err:  any) => {
      console.error(err);
    }).finally(() => {
      loader.hide();
      _.loadingEvent = false;
    })
  }

  public info(item:any , index: any, button: any) {
    this.infoModal.title = this.$t('Content').toString()
    this.infoModal.content = item.content
    this.infoModal.contentHtml = item.content_html
    this.$root.$emit('bv::show::modal', this.infoModal.id, button)
  }
  public resetInfoModal() {
    this.infoModal.title = ''
    this.infoModal.content = ''
    this.infoModal.contentHtml = null
  }
  public onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems?.length
    this.currentPage = 1
  }
  
  public downloadReport() {
    try {
      this.startDownload = true;
      this.lottieDownload.stop();
      this.lottieDownload.play();
      console.log('start aqui')
      const table: any = document.querySelector('#cp'+this.reportId);
      const fName: string = 'jobecam_report_'+this.reportId+(new Date().getTime())+'.csv';
      const x = new ExportTableCustom(table,fName,document.querySelector('#downloadLink'));
      console.log('end aqui');
      
      (document.querySelector('#downloadLink') as HTMLLinkElement)!.click();
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        this.startDownload = false;
      },1500)
    }
  }

  public linkto(link: string) {
    const fc: string = link;
    window.open(fc, '_blank');
  }

  
}
</script>