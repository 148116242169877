<template>
  <b-sidebar
      :id="id"
      :aria-controls="id"
      :aria-expanded="value?'true':'false'"
      :title="$t('Team')"
      width="400px"
      shadow
      @shown="shown"
      @hidden="hidden"
      lazy
      right 
    >
    <form-equipe @saved="saved"> </form-equipe>
  </b-sidebar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormEquipe from "@/views/equipes/partials/FormEquipe.vue";

@Component({
  components:{
    FormEquipe
  }
})
export default class SidebarEquipe extends Vue {

  @Prop({default: null})
  public id!: string;

  @Prop({default: false})
  public value!: boolean;

  public mounted() {
  }

  public saved() {
    this.$emit('done')
  }

  public hidden(){
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }
  
}
</script>
