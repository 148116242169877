<template>
  <div class="root-wrapper" :class="{'root-wrapper--sidebar-open': sidebarIsOpen}">
    <!-- Sidebar -->
    <sidebar-main @on-sidebar="toggleSidebar()"></sidebar-main>

    <!-- General Area -->
    <div class="root-wrapper__general">
      <!-- Header -->
      <app-header :key="$route.fullPath" @on-sidebar="toggleSidebar()"></app-header>
      <!-- Content -->
      <app-content></app-content>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/layout/TheHeader.vue';
import SidebarMain from '@/views/layout/SidebarMain.vue';
import Content from '@/components/layout/Content.vue';

@Component<RootLoggedArea>({
  components: {
    'sidebar-main': SidebarMain,
    'app-header': Header,
    'app-content': Content,
  },
})
export default class RootLoggedArea extends Vue {
  public sidebarIsOpen: Boolean = false;

  updated() {
    // console.log('update root')
  }

  mounted() {
    // console.info('App this router:', this.$router)
    // console.info('App currentRoute:', this.$router.currentRoute)
  }

  public toggleSidebar() {
    this.sidebarIsOpen = !this.sidebarIsOpen
  }
}
</script>

<style lang="scss" scoped>
.root-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;

  &__general {
    flex: 1;
    flex-direction: column;
    width: 100%;

    overflow: hidden;
  }

  transform: translateX(0px);
  transition: transform 0.5s ease-in-out;

  &--sidebar-open {
    transform: translateX(217px);
  }
}
</style>