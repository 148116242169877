<template>
  <div class="box-container d-flex flex-column mb-5">

    <b-row>
      <b-col class="mb-2" sm="12" md="12" lg="6" v-for="(parceiro, i) in parceiros" :key="i">
        <div>
          <b-card no-body class="overflow-hidden" style="max-width: 540px;">
            <b-row no-gutters>
              <b-col md="4">
                <b-card-img :src="parceiro.foto"></b-card-img>
              </b-col>
              <b-col md="8">
                <b-card-body :title="parceiro.nome">
                  <b-card-text class="informacoes">
                    {{parceiro.titulo}}
                    {{parceiro.email}}
                    <a :href="parceiro.site" target="_blank"> {{parceiro.site}} </a>
                    <a :href="parceiro.linkedin" target="_blank"> {{parceiro.linkedin}} </a>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>





  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Icon from "@/components/icon/Icon.vue";

@Component({
  components: {
    icon: Icon
  }
})
export default class PluralParceiros extends Vue {
  public parceiros : any = null;

  public created() {
    this.parceiros = [
      {
        nome: "Cristina Kerr",
        foto:"/static/img/plural/parceiros/criskerr.png",
        titulo: "CEO da CKZ Diversidade - Consultora em diversidade e inclusão, liderança e cultura inclusiva, viés inconsciente e ESG",
        email: "criskerr@ckzdiversidade.com.br",
        site: "www.ckzdiversidade.com.br",
        linkedin: "",
      },
      {
        nome: "Regina Madalozzo",
        foto: "/static/img/plural/parceiros/reginamadalozzo_.png",
        titulo: "Consultora, Pesquisadora e Palestrante sobre Vieses Inconscientes e Liderança Feminina nas empresas - Co-fundadora da Moura Madalozzo Consultoria Econômica",
        email: "reginamadalozzo@gmail.com",
        site: "",
        linkedin: "https://www.linkedin.com/in/reginamadalozzo/",
      },
      {
        nome: "Maristella Iannuzzi",
        foto: "/static/img/plural/parceiros/maristella_.png",
        titulo: "CEO da CMI Business Transformation e Conselheira Consultiva",
        email: "",
        site: "",
        linkedin: "",
      },
    ];
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

  .informacoes {
    font-size: 14px;
  }

</style>