<template>
  <b-sidebar
      :id="id"
      :aria-controls="id"
      :aria-expanded="value?'true':'false'"
      :title="$t('Configuring background image')"
      width="500px"
      v-model="value"
      @shown="shown"
      @hidden="hidden"
      left 
    >
    <b-card>
      <b-row>
        <b-col md="12" class="d-flex justify-content-end">
          <b-button variant="danger" pill class="border border-primary" @click="clear">
            {{$t('Clear')}}
          </b-button>
        </b-col>
        <b-col md="12" class="my-2">
          <h5>{{$t('Background Effects')}}</h5>
        </b-col>
        <b-col md="2" offset="3" class="my-2">
          <b-button variant="light" @click="blur(3)" class="">
            <i class="fas fa-ban fa-2x"></i>
          </b-button>
        </b-col>
        <b-col md="2"  class="my-2">
          <b-button variant="light" @click="blur(1)" class="">
            <b-img src="/static/img/blur_icon.png"></b-img>
          </b-button>
        </b-col>
        <b-col md="2" class="my-2">
          <b-button variant="light" @click="blur(2)">
            <b-img src="/static/img/blur_2_icon.png"></b-img>
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col md="12" class="my-2">
          <h5>{{$t('Background images')}}</h5>
        </b-col>
        <b-col md="3" class="my-2 ">
          <b-button variant="light">
            <div>
              <input type="file" id="choose-file" name="choose-file" @change="getImageData" accept="image/*" />
              <label for="choose-file">
                <i class="fas fa-plus-circle fa-2x"></i>
              </label>
            </div>
          </b-button>
        </b-col>
        <b-col md="3" v-for="(b,i) in listOfBackgrounds" :key="i" class="my-2 ">
          <b-button variant="light" @click="select(b)">
            <b-img :src="b" ></b-img>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-sidebar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SidebarBackgroundImage extends Vue {
  
  @Prop({default: null})
  public id!: string;

  @Prop({default: false})
  public value!: boolean;

  public listOfBackgrounds: string[] = [];
  public listOfCustomBackgrounds: string[] = [];
  
  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }

  public mounted() { 
    let bcks: string[] = []
    for(let i = 1; i < 29; i++ ) {
      bcks.push(location.protocol + "//" + location.host + "/static/img/small/poster"+i+".png")
    }
    let custom: string|null = localStorage.getItem('JB_ROOM_CUSTOM_BCKS')
    if(custom) {
      try {
        this.listOfCustomBackgrounds = JSON.parse(atob(custom));
      }catch(e) {
        // some error
        this.listOfCustomBackgrounds = [];
      }
    }
    this.listOfBackgrounds = this.listOfCustomBackgrounds.concat(bcks);
  }

  public select(url) {
    this.$emit('selected',url)
  }
  public blur(n: number|null) {
    this.$emit('blur',n)
  }
  public clear() {
    this.$emit('blur',3)
    this.$emit('selected',null)
  }

  getImageData() {
    console.log('aqui')
    const chooseFile: HTMLInputElement|null = document.querySelector("#choose-file");
    if(chooseFile && chooseFile.files !== null) {
      const files = chooseFile.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", (event: ProgressEvent<FileReader>) => {
          if(event.target?.result) {
            this.listOfCustomBackgrounds.push(""+event.target?.result)
            this.listOfBackgrounds.unshift(""+event.target?.result)
            this.$emit('selected', event.target?.result)
            try {
              localStorage.setItem('JB_ROOM_CUSTOM_BCKS',btoa(JSON.stringify(this.listOfCustomBackgrounds)))
            } catch(e) {
              console.error('Cannot save the selected image')
            }
          }
        });    
      }
      }
    }
}
</script>

<style lang="css" scoped>

    [type="file"] {
      height: 0;  
      width: 0;
      overflow: hidden;
    }
    [type="file"] + label {
      font-family: sans-serif;
      background: #f44336;
      padding: 10px 30px;
      border: 2px solid #f44336;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      transition: all 0.2s;
    }
    [type="file"] + label:hover {
      background-color: #fff;
      color: #f44336;
    }
    #img-preview {
      display: none; 
      width: 155px;   
      border: 2px dashed #333;  
      margin-bottom: 20px;
    }
    #img-preview img {  
      width: 100%;
      height: auto; 
      display: block;   
    }
  </style>