<template>
  <transition>
    <div class="row">
      <div class="col-md-12">
        <p>{{ $t("Welcome to our Cultural Match!") }}</p>
        <p>{{ $t("It's a mini-quiz (only 6 questions) to match the environment preferences between company and candidate. Everything is automatic and based on an internationally proven methodology, isn't that great?") }} </p>
        <p v-show="!more"> <a href="javascript:void(0)" @click="more = true">{{ $t("Learn more") }}...</a> </p>
        <p v-show="more"> {{$t("One of the biggest reasons for dissatisfaction and lack of productivity at work is cultural misalignment. When the behavior of managers, the processes and way of being of the company is very different from the employee.")}} </p>
        <p v-show="more"> {{$t("All companies have a little of everything, but some are more open to long-term collaboration and some are more focused on short-term results; some are more product innovators, others like standardization and need to be followed in detail.")}} </p>
        <p v-show="more"> {{$t("We are all human and can adapt to these different cultures, but some may require more effort and undermine our performance and job satisfaction. So with our Cultural Match, we have already aligned expectations from the beginning. Try to respond with that in mind.")}}  <br/>
        {{$t("If you're a recruiter: flag the most intense cultural traits in your organization.")}} <br />
        {{$t("If you are a candidate: flag the preferences you value most.")}} </p>
        <p v-show="more"> {{$t("Keep in mind that this is just an approximation of cultural affinities, it is not an elimination test ;)")}} </p>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class AssessmentDescription extends Vue {
  public more: boolean = false;
}
</script>

<style lang="scss" scoped>
</style>