
<template>
  <b-card
    no-body
    class="plan-card"
    header-tag="header"
    footer-tag="footer"
    :title="bodyTitle"
    :style="style">
    <b-card-header
      :header-bg-variant="headerBgColor" class="d-flex justify-content-center align-content-center flex-wrap">
      <h1>{{ planName||jobcoins}}</h1>
      <p class="text-uppercase mb-0 my-2" style="font-size: 11px;">{{$t(headerText)}}</p>
    </b-card-header>
    <div class="main-body">
      <div class="paid-body">

        <!-- <div class="price text-center text-uppercase"> -->
       <div v-if="this.$i18n.locale == 'pt-BR'" class="price text-center text-uppercase">
          <span>R$</span><span class="price__title">{{price}}</span><span>,00*</span>
          <p v-if="periodo === EPeriodoPlano_Anual">{{$t('Monthly') }}</p>
        </div>

       <div v-else class="price text-center text-uppercase">
         <span>USD</span><span class="price__title">{{price_usd}}</span><span>,00*</span> 
         <p v-if="periodo === EPeriodoPlano_Anual">{{$t('Monthly') }}</p>
       </div>

        <div class="feature-list" >
          <ul v-if="!listFeat">
            <li>{{$t("Interviews with 4 questions up to 2 minutes")}}</li>
            <li v-if="periodo === EPeriodoPlano_Anual">{{$t("1 year period for using credits")}}</li>
            <li v-else>{{$t("1 month period for using credits")}}</li>
          </ul>
          <ul v-else>
            <li v-for="feat in listFeat" :key="feat" v-html="$t(feat)"></li>
          </ul>
        </div>
       <b-button pill class="button-popular text-uppercase" v-if="popular">{{$t("Most popular")}}</b-button>
       <b-button pill class="button-popular text-uppercase" v-if="promocional && !textButton">{{$t("Promotional")}}</b-button>
       <b-button pill class="button-popular text-uppercase" v-if="promocional && textButton">{{$t(textButton)}}</b-button>
      </div>
    </div>
    <b-card-footer class="text-center">
      <b-button
        v-if="showBtn"
        pill
        :variant="buttonColor"
        class="text-uppercase"
        @click="$emit('planSelected', planId, free)">
        {{$t("Try it out")}}
      </b-button>
      <p v-if="promocional">*{{$t("Limited-Time Offer")}}</p>
      <p v-else-if="periodo === EPeriodoPlano_Anual">*{{$t("Cumulative interviews")}}</p>
      <p v-else>*{{$t("Non-cumulative interviews")}}</p>
    </b-card-footer>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';

@Component
export default class PlanCard extends Vue {
  private EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;

  @Prop() private headerText!: string;
  @Prop() private bodyTitle!: string;
  @Prop() private textColor!: string;
  @Prop() private headerBgColor!: string;
  @Prop() private jobcoins!: string;
  @Prop() private planName!: string;
  @Prop() private price!: string;
  @Prop() private price_usd!: string;
  @Prop() private planType!: string;
  @Prop() private periodo!: EPeriodoPlano;
  @Prop() private buttonColor!: string;
  @Prop() private textButton!: string;
  @Prop() private listjson!: string;
  @Prop() private planId!: string;
  @Prop({ default: false }) private showBtn!: boolean;
  @Prop({ default: false }) private free!: boolean;
  @Prop({ default: false }) private popular!: boolean;
  @Prop({ default: false }) private promocional!: boolean;

  public avaliablelist: string[]|null = null;

  private get style() {
    let style = '';

    if (this.textColor) {
      style += `color: ${this.textColor}; `;
    }

    return style;
  }

  get listFeat() {
    if (this.listjson && this.avaliablelist == null) {
      try {
        this.avaliablelist = JSON.parse(this.listjson);
      } catch(e) {
        this.avaliablelist = null;
      }
    }
    return this.avaliablelist;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
  .plan-card {
    // width: 18em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    border: none;

    & .card-header {
      height: 140px;
      text-align: center;
      color: white;
      margin-bottom: 20px;
      & h1 {
        font-weight: bold;
        font-size: 3em;
        text-shadow: 2px 2px 5px rgba(0,0,0,0.2);
        margin: 0;
        white-space: nowrap;
      }
    }

    & .price {
      // margin-bottom: 30px;
      &__title {
        font-size: 2.5em;
        font-weight: bold;
      }
      & h1 {
        margin-bottom: 1em;
        font-size: 2.5em;
        font-weight: bold;
      }
      & p {
        font-size: 11px;
        margin-top: -7px;
      }

      &.free-price {
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        h1 {
          margin: 0;
        }
      }
    }



    & .feature-list {
      & ul {
        padding: 0 15px;
        list-style: none;
        min-height: 200px;
        & li {
          background: url('~@/assets/imgs/candidato/success.svg') no-repeat top left;
          background-position: 0 3px;
          background-size: 15px;
          padding: 0 0 5px 24px;
          font-size: 13px;
        }
      }
    }

    & .feature-list ul li.feature-list__no {
      background: url('~@/assets/imgs/candidato/cancel.svg') no-repeat top left;
      background-position: 0 3px;
      background-size: 15px;
      padding: 0 0 15px 24px;
      font-size: 13px;
    }

    & .interview-note p {
      margin: 0;
      font-size: 13px;
    }

    & .card-footer {
      background: transparent;
      padding-bottom: 1em;
      padding-top: 5px;

      & p {
        margin-bottom: 5px;
        font-size: 14px;
      }

      & button {
        width: 100%;
        // height: 2.5em;
        color: white;
      }
    }

    & .paid-body {
      position: relative;

      & .button-popular {
        position: absolute;
        background: #fa3e80;
        border-color: #fa3e80;
        left: 50%;
        transform: translateX(-50%);
        top: -35px;
        font-size: 0.9em;
        width: 90%;
        // height: 30px;
        padding: 4px;
        font-weight: bold;
      }
    }
  }
</style>
