<template>
  <div class="box-container d-flex flex-column">
    <div class="row">
      <div class="col-12 col-md-12">
        <h3 class="text-md-left text-center">{{$t('Writing Test')}}</h3>
      </div>
    </div>
    <form-exam :essay="true" @saved="saved" />
  </div>
</template>

<script lang="ts">
// List Company evaluations
import { Vue, Component, Prop } from 'vue-property-decorator';
import FormExam from './partials/FormExam.vue';
import { QuestionaryEntity } from '@/core/models/Entities';

@Component({
  components: {
    FormExam
  }
})
export default class AddEssayExam extends Vue {
 
  constructor() {
    super();
  }

  public saved(q: QuestionaryEntity) {
    this.$alert.addMsg(this.$t('Saved successfully').toString());
    this.$alert.callBoxCheckWithTitle(this.$t('Ok').toString());
    this.$router.replace({name: 'EditExam', params: { qid: q.id+"" }, query: {lang: this.$i18n.locale}})
    return;
  }


}
</script>

<style lang="scss">

</style>