<template>
  <div class="candidato-list" :class="{ 'isActive' : !('mobileIsShowQuestions') }">
    <div class="row">
      <div class="col-md-12">
        <h3>{{$t("Applications")}}</h3>
      </div>
    </div>
    <div class="row candidato-list__list">
      <div class="col-md-12 candidato-list__list__candidates-actions" v-if="!isPublic">
        <div class="row">
          <b-col md="12">
            <b-button-group class="d-flex">
              <b-dropdown variant="outline-info" right :text="$t('Bulk actions')">
                <b-dropdown-item href="javascript:void(0)" v-if="!blockApprove" @click="sendBulkList('Aprovar')"> <i class="icon-aprovados mr-2" /> {{$t('Approve')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" v-if="!blockApprove" @click="sendBulkList('Reprovar')"> <i class="icon-reprovados mr-2" /> {{$t('Reprove')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" v-if="!blockApprove" @click="sendBulkList('Analisar')"> <i class="icon-analise mr-2" /> {{$t('Analyze')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" @click="sendBulkList('Mensagem')"><i class="icon icon-enviar mr-2" /> {{$t('Send message')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" @click="sendBulkList('Gestor')"> <i class="icon icon-compartilhar mr-2" /> {{$t('Forward to Manager')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" @click="openSidebarGroupEval(false)"> <i class="fas fa-tasks mr-2" /> {{$t('Evaluation List')}}</b-dropdown-item>
                <b-dropdown-item href="javascript:void(0)" @click="sendBulkList('Chance')"> <i class="icon icon-trocar mr-2" /> {{$t('New chance to record')}}</b-dropdown-item>
              </b-dropdown>
              <b-button @click="openSidebarGroupEval(false)" variant="outline-info" 
                v-b-tooltip.hover
                :title="$t('Evaluation List')"> 
                <i class="fas fa-tasks"></i>
              </b-button>
              <b-button @click="showModalFiltro" variant="outline-info" 
                 v-b-tooltip.hover
                :title="$t('Advanced filters')"> 
              <i class="fas fa-search-plus"></i>
              </b-button>
            </b-button-group >
          </b-col>           
        </div>
      </div>
      <!-- Lista -->
      <div class="candidaturas-list">
        <!-- ANALISE -->
        <candidaturas-list v-if="analiseC"
          v-model="analiseC.candidaturas"
          :candidaturas="analiseC.candidaturas"
          :listType="'analise'"
          :listTypeIcon="'analise'"
          :buttonText="buttonText('analise')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="analiseC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'analise')"
        />
        <div class="d-flex justify-content-center my-3" v-else>
          <b-spinner small variant="primary" label="Loading..."></b-spinner>
        </div>
        <!-- END: ANALISE -->
        
        <!-- APROVADOS -->
        <candidaturas-list  v-if="aprovadosC"
          v-model="aprovadosC.candidaturas"
          :candidaturas="aprovadosC.candidaturas"
          :listType="'aprovados'"
          :listTypeIcon="'aprovados'"
          :buttonText="buttonText('aprovados')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="aprovadosC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'aprovados')"
        />
        <div class="d-flex justify-content-center my-3" v-else-if="!isPublic">
          <b-spinner small variant="success" label="Loading..."></b-spinner>
        </div>
        <!-- END: APROVADOS -->
        
        <!-- REPROVADOS -->
        <candidaturas-list v-if="reprovadosC"
          v-model="reprovadosC.candidaturas"
          :candidaturas="reprovadosC.candidaturas"
          :listType="'reprovados'"
          :listTypeIcon="'reprovados'"
          :buttonText="buttonText('reprovados')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="reprovadosC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'reprovados')"
        />
        <div class="d-flex justify-content-center my-3"  v-else-if="!isPublic">
          <b-spinner small variant="danger" label="Loading..."></b-spinner>
        </div>
        
        <!-- END: REPROVADOS -->

        <!-- PENDENTES -->
        <candidaturas-list v-if="pendentesC"
          v-model="pendentesC.candidaturas"
          :candidaturas="pendentesC.candidaturas"
          :listType="'pendentes'"
          :listTypeIcon="'interrogao'"
          :buttonText="buttonText('pendentes')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="pendentesC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'pendentes')"
        />
        <div class="d-flex justify-content-center my-3"  v-else-if="!isPublic">
          <b-spinner small variant="pink" label="Loading..."></b-spinner>
        </div>
        
        <!-- END: PENDENTES -->
        
        <!-- REPESCAGEM -->
        <candidaturas-list v-if="repescagemC"
          v-model="repescagemC.candidaturas"
          :candidaturas="repescagemC.candidaturas"
          :listType="'repescagem'"
          :listTypeIcon="'interrogao'"
          :buttonText="buttonText('repescagem')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="repescagemC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'repescagem')"
        />
        
        <!-- END: REPESCAGEM -->

        <!-- REVISAO -->
        <candidaturas-list v-if="revisaoC && vaga && vaga.revisaoCurricular"
          v-model="revisaoC.candidaturas"
          :candidaturas="revisaoC.candidaturas"
          :listType="'revisao'"
          :listTypeIcon="'interrogao'"
          :buttonText="buttonText('revisao')"
          :hasButton="true"
          :currentid="currentid"
          :candidaturasQTD="revisaoC.qtd"
          @selected="selected"
          @checkbox="checkbox"
          @infinite="infinityHandler($event,'revisao')"
        />
        
        <!-- END: REVISAO -->
      </div>
      <modal-filtro id="modalFiltro" @advancedFilter="advancedFilter"  v-if="!isPublic" />
      <sidebar-grupo-avaliacao ref="sga" id="createGrupoAvaliacao" :vaga="vaga" :candidaturas="selectedCandidaturas" @selectedApplication="selectedApplication" v-model="sidebarGA"  v-if="!isPublic" />
    </div>
  </div>
</template>

<script lang="ts">
import SidebarGrupoAvaliacao from "@/components/avaliacao/sidebar/SidebarGrupoAvaliacao.vue";
import ModalFiltro from "@/components/modal/ModalFiltro.vue";
import {
CandidaturaEntity,
CandidaturasViewModel,
JobApplicationFilter,
VagaEntity
} from '@/core/models/Entities';
import CandidaturasList from "@/views/vagas/details/partials/CandidaturasList.vue";
import { Component, Prop, Vue } from 'vue-property-decorator';

  const checkedFilter: any = (v: CandidaturaEntity) => {
    return v.checked
  }

@Component({
  components: {
    "candidaturas-list": CandidaturasList,
    'modal-filtro': ModalFiltro,
    SidebarGrupoAvaliacao
  },
})
export default class VagaCandidaturas extends Vue {
  public analise: CandidaturasViewModel|null = null;
  public aprovados: CandidaturasViewModel|null = null;
  public reprovados: CandidaturasViewModel|null = null;
  public pendentes: CandidaturasViewModel|null = null;
  public revisao: CandidaturasViewModel|null = null;
  public repescagem: CandidaturasViewModel|null = null;

  @Prop({default: () => { return new VagaEntity()}})
  public vaga!: VagaEntity;

  @Prop({default: false})
  public isPublic!: boolean;


  get analiseC() { return this.analise; }
  get aprovadosC() { return this.aprovados; }
  get reprovadosC() { return this.reprovados; }
  get pendentesC() { return this.pendentes; }
  get revisaoC() { return this.revisao; }
  get repescagemC() { return this.repescagem; }
  
  get blockApprove () {
    return (this.vaga.blockApprove == true);
  }

  public selectedList: number[] = [];
  public currentid: number|null = null;
  public sidebarGA: boolean = false;

  public setList(key: string, c: CandidaturasViewModel) {
    this.$set(this,key,c);
  }
  
  public mounted() {
    this.selectedList
  }

  public showModalFiltro() {
    this.$bvModal.show('modalFiltro')
  }
  

  public showModalEspera() {
    this.$bvModal.show('modalListaEspera')
  }

  
  public showModalRevisao() {
    this.$bvModal.show('modalListaRevisao')
  }
  
  public selected(c: CandidaturaEntity) {
    this.currentid = c.id||null;
    this.$emit('selected', c, false);
  }
  public selectedApplication(cid: any) {
    const c1: CandidaturaEntity = new CandidaturaEntity();
    c1.id = cid;
    this.$emit('selected', c1, true);
  }

  public buttonText(key: string) {
    switch(key) {
      case 'aprovados':
        return 'Approved Candidates';
      case 'reprovados':
        return 'Failed Candidates';
      case 'analise':
        return 'Analyze Candidates';
      case 'pendentes':
        return 'Pending Candidates';
      case 'repescagem':
        return 'Candidates in waiting list';
      case 'revisao':
        return 'Candidates in Review';
      default:
        return 'Candidate';
    }
  }
  
  public hasButton(key: string) {
    switch(key) {
      case 'aprovados':
        return true;
      case 'reprovados':
        return true;
      case 'analise':
        return true;
      case 'pendentes':
        return true;
      case 'repescagem':
        return true;
      case 'revisao':
        return true;
      default:
        return false;
    }
  }

  get selectedCandidaturas() {
    let x: CandidaturaEntity[] = []
    if(this.analise && this.aprovados && this.reprovados && this.repescagem && this.revisao && this.pendentes)
      return x.concat(
        this.analise!.candidaturas.filter(checkedFilter),
        this.aprovados!.candidaturas.filter(checkedFilter),
        this.reprovados!.candidaturas.filter(checkedFilter),
        this.repescagem!.candidaturas.filter(checkedFilter),
        this.pendentes!.candidaturas.filter(checkedFilter),
        this.revisao!.candidaturas.filter(checkedFilter),
        )
    return x;
  }

  public checkbox(id: number, checked: boolean) {
    if (checked) {
      this.selectedList.push(id)
    } else {
      const index: number = this.selectedList.indexOf(id);
      if (index>-1) {
        this.selectedList.splice(index,1)
      }
    }
  }

  public sendBulkList(action: string) {
    // Faz o emit da lista(this.selectedList) e o tipo de método(action) para Candidaturas.vue
    if (typeof this.selectedList !== 'undefined' && this.selectedList.length > 0) {
      this.$emit('sendBulkList', this.selectedList, action)
      // this.selectedList = []
      console.log("selected list:"+this.selectedList)
      this.LimparCheckeds();
    } else {
      this.$alert.addMsg(this.$t('No selected candidate!'));
      this.$alert.callBoxWarn();
    }
  }

  public openSidebarGroupEval(openCreate?: boolean) {
    this.sidebarGA = !this.sidebarGA;
    if(openCreate && this.$refs.sga) {
      (this.$refs.sga as SidebarGrupoAvaliacao).toggleCreateNew()
    }
  }

  public ArrayIsEmpty(Arr: any) {
    return typeof Arr === 'undefined' || Arr.length < 1 || Arr == null;
  }


  public LimparCheckeds() {
    if (!this.ArrayIsEmpty(this.analise!.candidaturas)) {
      this.analise!.candidaturas.forEach(e => {
        e.checked = false;
      });
    }

    if (!this.ArrayIsEmpty(this.aprovados!.candidaturas)) {
      this.aprovados!.candidaturas.forEach(e => {
        e.checked = false;
      });
    }
    
    if (!this.ArrayIsEmpty(this.reprovados!.candidaturas)) {
      this.reprovados!.candidaturas.forEach(e => {
        e.checked = false;
      });
    }
    
    if (!this.ArrayIsEmpty(this.pendentes!.candidaturas)) {
      this.pendentes!.candidaturas.forEach(e => {
        e.checked = false;
      });
    }   
    if (!this.ArrayIsEmpty(this.repescagem!.candidaturas)) {
      this.repescagem!.candidaturas.forEach(e => {
        e.checked = false;
      });
    }  
    if (!this.ArrayIsEmpty(this.revisao!.candidaturas)) {
      this.revisao!.candidaturas.forEach(e => {
        e.checked = false;
      });
    } 
    this.selectedList = [];
  }

  public advancedFilter(filter: JobApplicationFilter, selecteds: any) {
    this.$emit('advancedFilter', filter, selecteds)
  }

  public newChance() {
    this.$emit('newChance')
  }

  public allCandidaturas() {
    this.$emit('allCandidaturas')
  }

  public infinityHandler($state: any,key: string) {
    this.$emit('infinite',$state,key)
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.repescagem {
  button {
    font-size: 0.8em;
  }
}
.candidato-list {
  width: 30%;
  margin-left: 0.2em;
  border-top-right-radius: 8px;
  h3 {
    font-size: 1.2rem;
  }
  &__list {
    margin-top: 1em;

    &__candidates-actions {
      &--filter {
        a {
          font-size: 12px;
        }
      }
      a {
        color: $blue;
      }
      label {
        font-size: 0.7rem;
      }
      .config {
        button {
          font-size: 0.7rem;
          padding: 5px 10px;
        }
        button:first-child {
          border-radius: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        button:last-child {
          border-radius: 0;
          border-color: $border-gray;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.candidaturas-list {
  width: 100%;
  margin-left: 0.2em;
  border-top-right-radius: 8px;
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;

  h3 {
    font-size: 1.2rem;
  }
  &__list {
    margin-top: 1rem;
    // &__all {
    //   border-bottom: 1px solid #dcdcdc;
    //   // &--status-btn {
    //   //   position: absolute;
    //   //   bottom: 28px;
    //   //   left: 36px;
    //   // }
    // }
  }

  .buttons-separators {
      button {
        color: white;
        font-size: 0.8rem;
      }
      .reprovados {
        background-color: $danger;
        border-color: $danger;
      }

      .analise {
        background-color: $primary;
        border-color: $primary;
      }

      .pendentes {
        background-color: $pink;
        border-color: $pink;
      }
      
      .revisao {
        background-color: $cyan;
        border-color: $cyan;
      }
      .repescagem {
        background-color: $orange;
        border-color: $orange;
      }
    }
}

.bulk-action .dropdown-toggle {
  // border-top-left-radius: 0;
  // border-bottom-left-radius: 0;
  // border-left: 0;
  font-size: 0.87rem;
  background-color: $blue;
  border-color: $blue;
  color: white;
}

</style>