<template>
  <b-form-group
    class="custom-select-group"
    :class="variant"
    :id="'input-group-' + scopeID"
    :label="(label ? label+(required?'*':'') : '')"
    :label-for="'input-' + scopeID"
    :state="state"
  >
    <b-form-select
      :id="'input-' + scopeID"
      @input="handleInput($event)"
      @change="handleChange($event)"
      :required="required"
      :placeholder="placeholder"
      :value="value"
      v-model="currentValue"
      :options="optionList"
      :disabled="disabled"
      :state="state"
    ></b-form-select>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SelectInput extends Vue {
  @Prop({default: ''}) public variant!: string;
    
    @Prop({default: null})
    public value!: any;

    @Prop({default: false})
    public required!: boolean;

    @Prop({default: false})
    public disabled!: boolean;
    
    @Prop({default: () => {return null}})
    public placeholder!: object;
    
    @Prop({default: () => {return null}})
    public label!: string;
    
    @Prop({default: () => {return {}; }})
    public optionList!: any[];

    @Prop({default: null}) 
    public state!: null|boolean|string;


    public scopeID: string;
    public currentValue: any|null = null;

    @Watch('value')
    public valueChanged() {
      this.currentValue = this.value;
    }

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput() {
        this.$emit('input', this.currentValue);
    }

    public handleChange() {
        this.$emit('change', this.currentValue);
    }

    public mounted() {
        setTimeout(() => {
            this.currentValue = this.value;
        },1500)
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/_variables";

select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  border: none;
  border-radius: 0 !important;
  background-image: linear-gradient(45deg, transparent 50%, $light-gray 60%),
    linear-gradient(135deg, $light-gray 40%, transparent 50%) !important;
  background-position: calc(100% - 16px) 13px, calc(100% - 8px) 13px, 100% 0;
  background-size: 8px 8px, 8px 8px;
  padding: 0;
  border-bottom: 1.5px solid $light-gray !important;
  color: $blue;
  &:focus {
    color: $blue;
    border-bottom-color: $blue !important;
  }
  &:after {
    color: red;
  }
}
.form-group .col-form-label,
.form-group:valid .col-form-label {
  color: white !important;
}

select::placeholder {
  color: rgba(140, 140, 140, 0.65) !important;
}
select.dark select:focus {
  box-shadow: 3px 3px 10px transparent;
  background: transparent;
}
.gray-bordered {
  select {
    border: 1.5px solid $light-gray !important;
    border-radius: 0 !important;
    padding-left: 5px;
    box-shadow: 1px 1px 2px #ccc;
    color: $dark-gray;
  }
  &:focus {
    select {
      color: $blue;
      border-color: $blue !important;
    }
  }
}

.light select{
  border: 1px solid #aaa !important;
}

</style>


<style lang="scss">
@import "@/assets/scss/_variables";

.custom-select-group label {
  font-size: 1em;
  padding: 0;
  color: $dark-gray;
  margin: 0;
}
.custom-select-group:valid label {
  font-weight: bold;
  color: white;
}

.custom-select-group.light label,
.custom-select-group.dark label {
  color: #000;
}
.custom-select-group.is-valid label {
  color: #000;
}
.custom-select-group.is-invalid label {
  color: red !important;
}
</style>
