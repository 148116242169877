<template>
<transition name="fade" appear>
  <div id="video">
    <br/>
    <b-row>
      <b-col sm="12" md="12" lg="6" v-for="(v, i) in videos" :key="i">
        <b-card class="card-video">
          <!-- header -->
          <div class="card-video__header">
            <b-col>
              <div class="card-video__header-title">
                <p>{{v.titulo}}</p>
              </div>
            </b-col>
          </div>
          <!-- content -->
          <div class="card-video__content">
            <iframe 
              width="100%" height="280" :src="getURL(v.videoUrl)"
              frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen 
              >
            </iframe>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</transition>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import { PluralPostEntity } from '@/core/models/Entities';
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class PluralVideos extends Vue {
  public service: PluralPostService;
  public videos: PluralPostEntity[]|null = null;
  public loadingPage:boolean = false;
  

  constructor() {
    super();
    this.service = new PluralPostService(); 
  }

  public mounted() {
    this.loadingPage = true;

    this.service.fetchAll({g:'VIDEO'}).then((data: any) => {
      console.log(data);
      this.videos = data['pluralPosts'];
      this.videos?.reverse();
    }).catch((err) => { 
      console.log(err) 
    }).finally(()=> {
      this.loadingPage = false;
    })
  }


  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

  $card-transition: all ease-in-out 0.24s;

  iframe {
    border-radius: 4px;
  }

  .card-video {
    margin-bottom: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    &__header {
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      color: $blue;
      border-bottom: $blue 1px dashed
    }
  }
</style>