<template>
  <span>
    <b-button :id="'button-'+id" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
    <b-tooltip :target="'button-'+id" placement="top">
      {{$t(tip)}}
      <slot />   
    </b-tooltip>
  </span>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HelpTip extends Vue {
    
  @Prop({default: null})
  public id!: any;
    
  @Prop({default: null})
  public tip!: any;
  
}
</script>

