<template>
  <div class="modal-content">
    <button class="btn btn-danger" @click="$bvModal.hide('modalFiltro')">
      <i class="icon-cancelar"></i>
    </button>
    <div class="row p-5">
      <div class="col-md-12">
        <h3 class="m-0">{{$t("Advanced filters")}}</h3>
      </div>
      <div class="col-md-12 my-4">
        <b-form-input 
          v-model="searchAll"
          class="search-candidaturas"
          :placeholder="$t('General Search')"
        ></b-form-input>
      </div>
      <div class="col-md-6">
        <select-input
          v-model="areaAtuacao"
          :optionList="areaAtuacaoService.optionList"
          variant="dark"
          :label="$t('Field of Work')"
          :placeholder="$t('Human resources')"
        />
      </div>
      <div class="col-md-6">
        <select-input
          v-model="nivelEscolar"
          :optionList="nivelEscolaridadeService.optionList"
          variant="dark"
          :label="$t('Education level')"
          :placeholder="$t('University education')"
        />
      </div>
      <div class="col-md-6">
        <select-input
          v-model="idioma"
          :optionList="idiomaService.optionFullList"
          variant="dark"
          :label="$t('Language')"
          :placeholder="'English'"
        />
      </div>
      <div class="col-md-6">
        <select-input
          v-model="pais"
          :optionList="paisService.optionList"
          variant="dark"
          :label="$t('Country')"
          placeholder="Brasil"
          @change="checkCountry"
        />
      </div>
      <div class="col-md-12 d-flex p-0" v-if="!showLocalidadeText">
        <div class="col-md-6">
          <select-input
            v-model="estado"
            :optionList="estadoService.optionList"
            variant="dark"
            :label="$t('State')"
            placeholder="São Paulo"
            @change="chainToCity"
          />
        </div>
        <div class="col-md-6">
          <select-input
            v-model="cidade"
            :optionList="cidadeService.optionList"
            variant="dark"
            :label="$t('City')"
            :placeholder="'São Paulo'"
          />
        </div>
      </div>
      <div class="col-md-12 d-flex p-0" v-else>
        <div class="col-md-6">
          <b-form-group class="custom-input-group" :label="$t('State')">
            <b-form-input 
              v-model="estadoText"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group class="custom-input-group" :label="$t('City')">
            <b-form-input
              v-model="cidadeText"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-12">
        <b-form-group :label="$t('Filtering by belonging group')">
            <b-row class="px-2">
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoLgbt"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                >
                </checkbox-input>
                <img class="image" id="lgbt" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_lgbt%2B.png'" />
                <b-tooltip target="lgbt" placement="bottom">
                  LGBTQIA+
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoIndiginas"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                >
                </checkbox-input>
                <img class="image" id="indigena" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_comunidade_indigena.png'" />
                <b-tooltip target="indigena" placement="bottom">
                  {{$t('Indigenous Community')}}
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoRefugiados"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                  :label="$t('')"
                >
                </checkbox-input>
                <img class="image" id="imigrante" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_refugiados_imigrantes.png'" />
                <b-tooltip target="imigrante" placement="bottom">
                  {{$t('Refugee and Immigrants')}}
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoMovBlack"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                  :label="$t('')"
                >
                </checkbox-input>
                <img class="image" id="black" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_movimento_black.png'" />
                <b-tooltip target="black" placement="bottom">
                  {{$t('Afro-descendants Community')}}
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoEmpoderaFeminino"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                  :label="$t('')"
                >
                </checkbox-input>
                <img class="image" id="feminino" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_empoderamento_feminino.png'" />
                <b-tooltip target="feminino" placement="bottom">
                  {{$t('Womens Movement')}}
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupoPCD"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                  :label="$t('')"
                >
                </checkbox-input>
                <img class="image" id="pcd" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_pcd.png'" />
                <b-tooltip target="pcd" placement="bottom">
                  {{$t('PWD Group')}}
                </b-tooltip>
              </b-col>
              <b-col md="2" style="text-align: center">
                <checkbox-input
                  class="checkbox-de"
                  v-model="grupo3Idade"
                  :accepted="true"
                  :not_accepted="false"
                  :switch_type="true"
                  :input_size="'lg'"
                  :label="$t('')"
                >
                </checkbox-input>
                <img class="image" id="3idade" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_3idade.png'" />
                <b-tooltip target="3idade" placement="bottom">
                  {{$t('Senior citizen group')}}
                </b-tooltip>
              </b-col>
            </b-row>

            
        </b-form-group>
      </div>        
      <div class="col-md-12">
        <b-form-group :label="$t('Filter by status')">
          <b-form-checkbox
            v-model="allSelected"
            aria-controls="options"
            @change="allStatus"
          >
            {{ allSelected ? $t('Unselect') : $t('Select All') }}
          </b-form-checkbox>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
            @change="allStatus(false)"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>

      <div class="col-md-12 d-flex justify-content-around mt-4">
        <button type="button" class="btn btn-outline-info text-uppercase" @click="limparFiltro" v-promise-btn="{promise:currentPromise}">
          <!-- <i class="fas fa-check mr-2"></i> -->
          {{$t("Clear filters")}}
        </button>
        <button type="button" class="btn btn-info text-uppercase" @click="handleFiltrar" v-promise-btn="{promise:currentPromise}">
          <i class="fas fa-check mr-2"></i>
          {{$t("Apply filters")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AreaAtuacaoService from "@/core/Services/AreaAtuacao";
import IdiomaService from "@/core/Services/Basic/Idioma";
import CidadeService from "@/core/Services/Cidade";
import GestaoCandidaturaService from "@/core/Services/Empresa/GestaoCandidatura";
import EstadoService from "@/core/Services/Estado";
import NivelEscolaridadeService from "@/core/Services/NivelEscolaridade";
import PaisService from "@/core/Services/Pais";
import { CandidaturaEntity, JobApplicationFilter } from "@/core/models/Entities";
import { FilterElement } from "@/core/util";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component
export default class ModalFiltro extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  public selectFilters: FilterElement[] = [];
  public loadingCandStatus: boolean = true;
  public showLocalidadeText: boolean = false;
  public searchText = '';
  public statusFilter: any = '';
  public estado: string = '';
  public cidade: string = '';
  public estadoText: string = '';
  public cidadeText: string = '';
  public idioma: string = '';
  public pais: string = '';
  public areaAtuacao: string = '';
  public nivelEscolar: string = '';
  public searchAll: string = '';
  public currentPromise: any|null = null;
  public options: any [] | null = null;
  public selected: any [] | null = null;
  public allSelected: boolean = false;
  public grupoPCD: boolean = false;
  public grupoEmpoderaFeminino: boolean = false;
  public grupoMovBlack: boolean = false;
  public grupoRefugiados: boolean = false;
  public grupoIndiginas: boolean = false;
  public grupoLgbt: boolean = false;
  public grupo3Idade: boolean = false;

  public gCandidaturaService!: GestaoCandidaturaService
	public areaAtuacaoService!: AreaAtuacaoService
	public nivelEscolaridadeService!: NivelEscolaridadeService
	public estadoService!: EstadoService
	public cidadeService!: CidadeService
	public paisService!: PaisService
  public idiomaService!: IdiomaService
  public candidaturas : CandidaturaEntity[] = [];

  constructor() {
    super()
    this.gCandidaturaService = new GestaoCandidaturaService();
    this.areaAtuacaoService = new AreaAtuacaoService();
    this.nivelEscolaridadeService = new NivelEscolaridadeService();
    this.estadoService = new EstadoService();
    this.cidadeService = new CidadeService();
    this.paisService = new PaisService();
    this.idiomaService =  new IdiomaService();
  }

  public mounted() {
    this.areaAtuacaoService.loadOptions()
    this.nivelEscolaridadeService.loadOptions()
    this.estadoService.loadOptions()
    this.paisService.loadOptions()
    this.idiomaService.loadOptions()
    this.options = [
      {text: this.$t('Under review'), value: 'analise'},
      {text: this.$t('Approved'), value: 'aprovados'},
      {text: this.$t('Reproveds'), value: 'reprovados'},
      {text: this.$t('Pending'), value: 'pendentes'}
    ]
    this.selected = ['analise','aprovados','reprovados','pendentes'];
    this.grupoPCD = false;
    this.grupoEmpoderaFeminino = false;
    this.grupoMovBlack = false;
    this.grupoRefugiados = false;
    this.grupoIndiginas = false;
    this.grupoLgbt = false;
    this.grupo3Idade = false;
  }

  @Watch('selected')
  public selectedOption() {
    this.allSelected =  this.selected == this.options!.map(_ => {return _.value});
  }

  public allStatus(checked) {
    this.selected = checked ? this.options!.map(_ => {return _.value}) : []
  }

  public checkCountry() {
    if (this.pais === "BR") {
      this.showLocalidadeText = false;
    } else {
      this.showLocalidadeText = true;
    }
  }

  public chainToCity(value?: any) {
    this.cidadeService.loadOptions(this.estado);
  }

  public handleFiltrar() {
    let filter: JobApplicationFilter = new JobApplicationFilter(null,1);
    filter.aa = this.areaAtuacao;
    filter.esc = this.nivelEscolar;
    filter.co = this.pais;
    filter.es = this.estado;
    filter.ci = this.cidade;
    filter.s = this.searchAll;
    filter.idm = this.idioma;

    filter.gamb = this.grupoMovBlack?'1':''; //-- grp_afin_mov_black
    filter.galg = this.grupoLgbt?'1':''; //-- grp_afin_lgbt
    filter.gaci = this.grupoIndiginas?'1':''; //-- grp_afin_comu_indi
    filter.gari = this.grupoRefugiados?'1':''; //-- grp_afin_refu_imig
    filter.gapc = this.grupoPCD?'1':''; //-- grp_afin_pcd
    filter.gaef = this.grupoEmpoderaFeminino?'1':''; //-- grp_afin_empo_femi
    filter.ga3i = this.grupo3Idade?'1':''; //-- grp_afin_3_idade

    this.$emit('advancedFilter', filter, this.selected)

    this.areaAtuacao = ''
    this.nivelEscolar = ''
    this.pais = ''
    this.estado = ''
    this.cidade = ''
    this.searchAll = ''
    this.idioma = ''
    this.selected = ['analise','aprovados','reprovados','pendentes'];    
  }

  public limparFiltro() {
    this.areaAtuacao = ''
    this.nivelEscolar = ''
    this.pais = ''
    this.estado = ''
    this.cidade = ''
    this.searchAll = ''
    this.idioma = ''
    this.selected = []
    this.grupoPCD = false;
    this.grupoEmpoderaFeminino = false;
    this.grupoMovBlack = false;
    this.grupoRefugiados = false;
    this.grupoIndiginas = false;
    this.grupoLgbt = false;
    this.grupo3Idade = false;
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.modal-content {
  .search {
    max-width: 100% !important;
  }

  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8rem;
      display: flex;
    }
  }
  h3 {
    font-size: 1.8rem;
  }
  button {
    font-size: 1rem;
  }
}

input {
  border: none;
  border-radius: 0 !important;
  padding: 0;
  border-bottom: 1.5px solid $light-gray !important;
  color: $blue;
  &:focus {
    color: $blue;
    border-bottom-color: $blue !important;
  }
  &:after {
    color: red;
  }
}
// .form-group .col-form-label,
// .form-group:valid .col-form-label {
//   color: white !important;
// }

input::placeholder {
  color: rgba(140, 140, 140, 0.65) !important;
}
input.dark select:focus {
  box-shadow: 3px 3px 10px transparent;
  background: transparent;
}

</style>

<style lang="scss">
@import "@/assets/scss/_variables";

#modalFiltro .modal-body {
  padding: 0;
}

.custom-input-group legend {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.search-all .search__wrapper{
  border: 1px solid $light-gray;
}

.search-all .search__wrapper__input::placeholder {
  color: $gray;
}
</style>
