import { render, staticRenderFns } from "./ReportTableSimple.vue?vue&type=template&id=0b43c280&scoped=true"
import script from "./ReportTableSimple.vue?vue&type=script&lang=ts"
export * from "./ReportTableSimple.vue?vue&type=script&lang=ts"
import style1 from "./ReportTableSimple.vue?vue&type=style&index=1&id=0b43c280&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b43c280",
  null
  
)

export default component.exports