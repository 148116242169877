// tslint:disable
import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ProfileState } from "./types";
import { RootState } from "../types";

export const state: ProfileState = {
    user: undefined,
    token: sessionStorage.getItem("access_token") || undefined,
    error: false,
    company_info: undefined,
    recruiter_info: undefined
};

const namespaced: boolean = true;

export const profile: Module<ProfileState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
