<template>
  <transition>
    <div class="row">
      <div class="col-md-12">
        <ul>
          <li>{{ $t("We will present four statements for each question") }}</li>
          <li>{{ $t("You must distribute points in a space of 1 to 10 for each statement indicated") }}</li>
          <li>{{ $t("As one the lowest degree of affinity and 10 the highest degree of affinity") }}</li>
          <li>{{ $t("Respond according to the strongest traits of the corporate culture you are in, the true preferences and behavior of leaders in terms of management, processes and work environment.") }} </li>
          <li>{{ $t("This quiz is just an approximation of cultural affinities, it already brings good insights for recruitment, but for in-depth definition of company culture, it is no substitute of a full diagnosis and mapping.") }}</li>
          <li>{{ $t("Remember that the cultural profile is not definitive, it may change according to the maturity stage of the company, the market context or the leadership group.") }}</li>
        </ul>
        <p>
          {{$t("Go and rock!")}}
        </p>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class AssessmentInstruction extends Vue {

}
</script>

<style lang="scss" scoped>
</style>