<template>
  <transition appear name="bounce">
    <div class="modal_wrapper">
      
      <div class="vld-parent">
          <loading :active="loadingSave" 
          :can-cancel="false" 
          :background-color="'#EEECEC'"
          :opacity="1"
          :is-full-page="true"></loading>
      </div>
    <b-modal id="modalImportJobsSAP" size="xl" :title="$t('Import Job')">
      <b-container>
        <b-row>
          <b-col md="12">
            <h3>{{$t("Enter the request number to import the vacancy")}}</h3>
          </b-col>
          <b-col md="12">
            <b-form inline>
              <label class="sr-only" for="inline-form-input-code">{{$t("Requisition number")}}</label>
              <b-input-group prepend="Nº" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="inline-form-input-code"
                  :placeholder="$t('Requisition number')"
                  v-model="jobCode"
                ></b-form-input>
              </b-input-group>

              <b-button variant="primary" @click="searchJob">
                <i class="fa fa-search"></i>
              </b-button>
            </b-form>
          </b-col>
          <b-col md="12">
            <b-row v-if="jobRequisition">
              <b-col md="6" class="mx-auto my-4">
                <b-input-group 
                  size="lg"
                  :prepend="$t('Are you sure you want to import this vacancy?')">
                  <b-button size="lg" variant="primary" class="rounded-0" @click="importJob" > {{$t('Yes')}} </b-button>
                  <b-button size="lg" variant="danger" class="rounded-0" @click="jobRequisition = null" > {{$t('No')}} </b-button>
                </b-input-group>
              </b-col>
              <b-col md="12">
                <b-card >
                  <b-card-header>
                    {{jobRequisition.jobReqId}} - {{jobRequisition.jobReqLocale.results[0].jobTitle}}
                  </b-card-header>             
                  <b-card-text v-html="jobRequisition.jobReqLocale.results[0].externalJobDescription"></b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ImportJobSAP from '@/core/Services/Vaga/ImportJobSFAP';
import Loading from 'vue-loading-overlay';


@Component({
  components: {
    Loading
  }
})
export default class ModalImportJobsSAP extends Vue {
  public fields: any[] = [];
  public items: any[] = [];
  public loading: boolean = false;
  public loadingSave: boolean = false;
  public jobCode: string = '';
  public jobRequisition: any|null = null;
  public service: ImportJobSAP = new ImportJobSAP();

  //chave de retorno do conjunto de job_boards
  private SF_SAP: string = "SF_SAP";

  public mounted() {
    this.fields = [
      { key: 'id', label: this.$t('Code'), class: 'text-center' },
      { key: 'title', label: this.$t('Name'), class: 'text-center' },
      { key: 'absolute_url', label: 'URL', class: 'text-center' },
      { key: 'optin', label: '-', class: 'text-center' }
    ];
  }

  public searchJob() {
    this.service.fetch(+this.jobCode).then((data) => {
      if(data)
        this.jobRequisition = data;
    }).catch(() => {
      const _: any = this;
      _.toastError(this.$t('Nothing Found').toString());
      this.jobRequisition = null;
    })
  }

  public async importJob() {
    try {
        this.loadingSave = true;
        this.$bvModal.hide('modalImportJobsSAP')
        this.service.create({ jobReqId: this.jobCode })
        .then((data) => {
          if(data?.id)
            this.$router.push({
              name: "JobsOpeningsEdit",
              params: { id: data.id+"" },
              query: { lang: this.$i18n.locale }
            })
        }).finally(() => {
          this.loadingSave = false;
        })
        console.log('importar vaga')
        
      } catch (error) {
        console.warn('error pulling job data')
      }
  }
}
</script>

<style lang="scss" scoped>
  .circle-round {
    &:hover{
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border-color: #f8f9fa !important;
    }
    border-radius: 50% !important;
    padding: 7px !important;
    border-width: 2px;
    line-height: 1em;
    font-size: 0.875rem;
  }
</style>