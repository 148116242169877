<template>
  <div class="box-container d-flex flex-column">
    <div class="row">
      <div class="col-6">
        <h3 class="text-md-left text-center">{{$t('Tests List')}}</h3>
      </div>
      <b-col md="6">
        <!-- <b-button class="float-right" variant="outline-primary" :to="{path:'/exam/add', query: {lang: $i18n.locale}}"> -->
        <b-button class="float-right" variant="outline-primary" @click="addTest(1)">
          <i class="fas fa-tasks mr-2"></i>
          {{$t('New Test')}}
        </b-button> 
        <!-- <b-button class="float-right mr-2" variant="outline-primary" :to="{path:'/exam/add_essay', query: {lang: $i18n.locale}}"> -->
        <b-button class="float-right mr-2" variant="outline-primary" @click="addTest(0)">
          <i class="fas fa-tasks mr-2"></i>
          {{$t('New Essay')}}
        </b-button>
      </b-col>
    </div>
    <div class="row">
        <b-col md="12" class=" pt-2 mt-2">
          <b-table id="examsTable" bordered striped hover responsive :items="items" :fields="fields" :busy="isBusy" >
            <template v-slot:cell(action)="data">
              <b-button variant="info" :to="{name: 'EditExam', params: {qid: data.value}, query: { lang: $i18n.locale }}" >
                <i class="far fa-edit"></i>
              </b-button>
              <b-button variant="outline-danger" class="ml-1" @click="deleteExam(data.value)" >
                <i class="fa fa-times"></i>
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
    </div>
  </div>
</template>

<script lang="ts">
// List Company evaluations
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Exam from '@/core/Services/Exams/Exam';
import { QuestionEntity, EmpresaEntity } from '@/core/models/Entities';


@Component
export default class Exams extends Vue {
  public service: Exam;
  public list: QuestionEntity[];
  public page: number = 1;
  public limit: number = 10; 
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;

  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  constructor() {
    super();
    this.service = new Exam();
    this.list = []
  }

  public async mounted() {
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
    }
    
    this.fields = [     
      { key: 'name', label: this.$t('Tests'), class: 'text-left' },
      { key: 'status', label: this.$t('Status'), class: 'text-center' },
      { key: 'action', label: this.$t('Actions'), class: 'text-center' },
    ];
    this.items = [];
    this.service.fetchAll({
      page: this.page,
      limit: this.limit
    }).then((data) => {
      this.list = data['exams'];
      this.list.forEach((item: any) => {
        this.items!.push({
          name: item.name,
          status: item.status == 1 ? this.$t('Active') : this.$t('Inactive'),
          action: item.id
        })
      })
    })
  }

  public addTest(isTest: number){
    if(this.empresa.configuracoes.ENABLE_QUESTIONARY!=0){

      console.log(this.empresa.configuracoes.LIMIT_QUESTIONARYS,"___TAMANHO:___",this.items!.length)
      const limit: number = parseInt(this.empresa.configuracoes.LIMIT_QUESTIONARYS);
      if(!isNaN(limit) && (limit <= 0 || limit > this.items!.length)) {
        if(isTest){
          this.$router.push({ path: "/exam/add", query: { lang: this.$i18n.locale } });
        }else{
          this.$router.push({ path: "/exam/add_essay", query: { lang: this.$i18n.locale } });          
        }
      }else{
        this.$toast.variant = 'warning';
        this.$toast.addMsg(this.$t('You have reached the maximum test/writing number you can register!'))
        this.$toast.addTitle(this.$t('Exceeded limit').toString());
        this.$toast.open();
      }
    }else{
      this.$toast.variant = 'warning';
      if(isTest){
        this.$toast.addMsg(this.$t('Your plan is not permitted to create test!'))
      }else{
        this.$toast.addMsg(this.$t('Your plan is not permitted to create writing!'))
      }
      this.$toast.addTitle(this.$t('Exceeded limit').toString());
      this.$toast.open();
    }
  }


  public deleteExam(id: number) {
    this.service.delete({id}).then(() => {
      this.$toast.variant = 'success';
      this.$toast.addMsg(this.$t('Deleted Successfully'))
      this.$toast.addTitle(this.$t('-').toString());
      this.$toast.open();
      const index: number = this.items!.findIndex(_ => { return _.action == id})
      this.items!.splice(index,1)
    }).catch(() => {
      this.$toast.variant = 'danger';
      this.$toast.addMsg(this.$t('Cannot delete because it is in use'))
      this.$toast.addTitle(this.$t('Oops').toString());
      this.$toast.open();
    })
  }

}
</script>

<style lang="scss">

</style>