<template>
  <transition appear name="slide">
    <div class="v-card v-sheet theme--light">
      <div class="v-card__title text-base font-weight-semibold">
        <div>
            <p class="mb-2"> {{$t(title)}} </p>
        </div>
      </div>
      <div class="v-card__text" style="position: relative;">
        <div style="min-height: 300px;">
          <div :id="'chart_donut_'+scopeID" v-if="value && value.series">
            <apexchart type="donut" height="300" :options="value" :series="value.series"></apexchart>
          </div>
        </div>

        <div class="resize-triggers">
            <div class="expand-trigger">
              <div style="width: 326px; height: 351px;"></div>
            </div>
            <div class="contract-trigger"></div>
        </div>
      </div>
    </div>
  </transition>
</template>



<script lang="ts">
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CardDonutChart extends Vue {

  public scopeID: string;
  
  constructor() {
    super();
    this.scopeID = (new Date()).getTime() + '' + (Math.random()*1000);
  }

  @Prop({default: {}})
  public value!: any;

  @Prop({default: ''})
  public title!: string;

}
</script>