<template>
  <div class="text-input-append">
    <text-input
      :value="value"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      :label="label"
      :type="type"
      @input="handleInput($event)"
      @blur="handleBlur($event)"
      @change="handleChange($event)"
    />
    <i :class="iconClass"></i>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import AvatarUpload from '@/components/form/avatar-upload/AvatarUpload.vue';

@Component
export default class TextInputAppend extends Vue {
  @Prop({ default: null }) public value!: string;
  @Prop({ default: false }) public required!: Boolean;
  @Prop({ default: false }) public disabled!: Boolean;
  @Prop({ default: "" }) public placeholder!: string;
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "text" }) public type!: string;
  @Prop({ default: "fas fa-link" }) public icon!: string;

  private iconClass = `text-input-append__append-icon ${this.icon}`

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
  
  public handleChange(v: any) {
    this.$emit('change', v);
  }
}
</script>

<style lang="scss" scoped>
/**
** Sidebar component
**/
.text-input-append {
  position: relative;

  &__append-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #03abcc;
    width: 33px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  input {
    padding-right: 2.5rem;
  }
}
</style>