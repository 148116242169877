<template>
<b-list-group-item class="flex-fill">
    <div class="d-flex justify-content-between">
      <label :for="'checkbox_'+id" class="m-0"> 
        {{$t(label)}} 
        <slot />
      </label>
      <b-form-checkbox :id="'checkbox_'+id" :disabled="disabled" v-model="model" @input="handleChange"  :value="checked" switch :unchecked-value="unchecked" >
      </b-form-checkbox>
    </div>
  </b-list-group-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ListGroupItemCheckbox extends Vue {
  
  public model: any|null =null;
    
  @Prop({default: null})
  public label!: any;
    
  @Prop({default: null})
  public id!: any;
  
  @Prop({default: true})
  public checked!: any;
  
  @Prop({default: true})
  public value!: any;
  
  @Prop({default: false})
  public unchecked!: any;
  
  @Prop({default: false})
  public disabled!: any;

  public handleChange() {
    this.$emit('input',this.model)
  }

  public mounted() {
    this.model = this.value;
  }


}
</script>

