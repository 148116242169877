<template>
  <transition appear name="slide">
    <div class="v-card v-sheet theme--light" style="margin-top:50px">
      <div class="d-flex">
        <div>
          <div class="v-card__title text-base font-weight-semibold"> {{$t(title)}} </div>
          <div class="v-card__text d-flex align-center">
            <h2 class="text-2xl font-weight-semibold"> {{value}} </h2>
          </div>
          <div class="v-card__text">
          </div>
        </div>
        <div class="spacer">
        </div>
        <div class="illustrator-img">
          <div class="v-image v-responsive theme--light" style="width: 73px;">
            <div class="v-responsive__sizer" style="padding-bottom: 242.466%;">
            </div>
            <div class="v-image__image v-image__image--cover" :style="{ backgroundImage: 'url('+srcImg+')', backgroundPosition: 'center center' }">
            </div>
            <div class="v-responsive__content" style="width: 146px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>



<script lang="ts">
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CardImageTotal extends Vue {


  @Prop({default: ''})
  public title!: string;

  @Prop({default: ''})
  public value!: string;
  
   @Prop({default: ''})
  public srcImg!: string;



}
</script>