<template>
  <header class="header flex-md-row bg-white" role="banner">
    <div class="header__wrapper d-flex justify-content-between">
      <button class="btn d-block d-lg-none mr-0 px-0 py-0" @click="toggleSidebar()">
        <i class="fas fa-bars"></i>
      </button>

      <div class="header__title d-flex">
        <i
          class="fas fa-chevron-left go-back d-none d-md-block"
          @click="$router.go(-1)"
          v-b-tooltip.hover
          :title="$t('Back')"
        />

        <h4 class="text-uppercase m-0">{{ $t($route.meta.title) }}</h4>
      </div>

      <nav class="header__block header__nav d-flex ml-0">
        <div class="header__block">
          <!-- <button-icon @click="goToVagasWithFilter" class="d-md-none d-sm-block" iconName="filtrar" :inverse="true"></button-icon>
          <b-dropdown  variant="link" class="d-md-none d-sm-block"  toggle-class="text-decoration-none" no-caret>
            <template slot="button-content">
              <button-icon iconName="outros" :inverse="true"></button-icon>
            </template>
            <b-dropdown-item  @click="config()">
              <button-icon iconName="config" ></button-icon> {{ $t("Settings") }}
            </b-dropdown-item>
            <b-dropdown-item  @click="logout()">
              <button-icon  @click="logout()" iconName="sair"></button-icon> {{ $t("Logout") }}
            </b-dropdown-item>
          </b-dropdown>

          <search-input class="d-none d-md-block"></search-input> -->
        </div>
        
        <!-- Member get Member -->
        <button-icon 
          class="header__nav--btn d-none d-md-block" 
          id="universal_access"
           @click="initUW()" 
          iconName="fas fa-universal-access" 
          :inverse="true"
          v-b-tooltip.hover
          v-if="$i18n.locale == 'pt-BR'"
          :title="$t('Accessibility')"
        ></button-icon>

        <!-- Member get Member -->
        <button-icon 
          class="header__nav--btn d-none d-md-block" 
          @click="initHT()" 
          iconName="fas fa-sign-language" 
          :inverse="true"
          v-b-tooltip.hover
          v-if="$i18n.locale == 'pt-BR'"
          :title="$t('Accessibility')"
        ></button-icon>

        <!-- Member get Member -->
        <button-icon 
          class="header__nav--btn d-none d-md-block text-warning" 
          @click="gotoInvite()" 
          iconName="compartilhar" 
          :inverse="true"
          v-b-tooltip.hover
          :title="$t('Invite')"
        ></button-icon>

        <!-- Mensagens -->
        <button-icon 
          class="header__nav--btn d-none d-md-block" 
          @click="goMessage()" 
          iconName="mensagens" 
          :inverse="true"
          v-b-tooltip.hover
          :title="$t('Messages')"
        ></button-icon>

        <!-- Ajuda -->
        <button-icon 
          class="header__nav--btn d-none d-md-block" 
          @click="goHelp()" 
          iconName="ajuda" 
          :inverse="true"
          v-b-tooltip.hover
          :title="$t('Help')"
        ></button-icon>

        <!-- Idiomas -->
        <b-dropdown 
          class="d-none d-md-block idiomas" 
          variant="link" 
          toggle-class="text-decoration-none" 
          no-caret
          v-b-tooltip
          :title="$t('Language')">
          <template slot="button-content">
            <button-icon class="header__nav--btn p-0"
              iconName="planeta" 
              :inverse="true"
            ></button-icon>
          </template>
          <b-dropdown-item
            v-for="lang in langs"
            :key="lang.locale"
            @click="setLocale(lang)">{{ lang.name }}</b-dropdown-item>
        </b-dropdown>

        <!-- Logout -->
        <!-- <button-icon class="header__nav--btn d-none d-md-block fas fa-cog" @click="config()" :inverse="true"></button-icon> -->
        <button-icon 
          class="header__nav--btn d-none d-md-block" 
          @click="logout()" 
          iconName="sair" 
          :inverse="true"
          v-b-tooltip.hover
          :title="$t('Logout')"
        ></button-icon>
      </nav>
    </div>

    <slot></slot>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, Action } from 'vuex-class';
import SearchInput from '@/components/search/Search.vue';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';

@Component({
  components: {
    'search-input': SearchInput,
    'button-icon': ButtonIcon,
  },
})
export default class TheHeader extends Vue {
  public title!: string;
  public showFilter:boolean =true;
  public showTop:boolean =true;

  public langs = [
    {
      id: '10',
      locale: 'pt-BR',
      name: 'Português'
    },
    {
      id: '3',
      locale: 'es',
      name: 'Español'
    },
    { id: '2',
      locale: 'en-US',
      name: 'English'
    },
    { id: '5',
      locale: 'fr',
      name: 'Français'
    }
  ];

  private smallConfigs = [
    {
      locale: 'pt-BR',
      name: 'Português'
    },
    {
      locale: 'es',
      name: 'Español'
    },
    {
      locale: 'en-US',
      name: 'English'
    },
    { 
      locale: 'fr',
      name: 'Français'
    }
  ];

  @Getter("isEntrevistaPage", { namespace: 'profile' }) private isEntrevistaPage!: boolean;

  @Action('setEmpresaData', { namespace: 'profile' })
  public setEmpresaData!: (data: {}) => void;

  private get show(): boolean {
    return !this.isEntrevistaPage;
  }

  public setSeen() {
    sessionStorage.setItem('topAlert','1')
  }

  public logout() {
    this.$router.push({ path: '/logout', query: {lang: this.$i18n.locale }});
  }

  public config() {
    this.$router.push({ path: '/settings', query: { lang: this.$i18n.locale }});
  }

  public goMessage() {
    this.$router.push({path: '/messages', query: {lang: this.$i18n.locale }});
  }
  public gotoInvite() {
    this.$router.push({path: '/invite', query: {lang: this.$i18n.locale }});
  }

  public goHelp() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/2`;
    window.open(fc, '_blank');
    //this.$router.push({path: '/help', query: {lang: this.$i18n.locale }});
  }

  public goToVagasWithFilter() {
    this.$router.push({ path: '/vagas', query: { filter: (this.showFilter?"1":"0"), lang: this.$i18n.locale }});
    this.showFilter = !this.showFilter;
  }

  public mounted() {
    this.title = this?.$router?.currentRoute?.meta?.title
    this.showTop = (!(sessionStorage.getItem('topAlert') == '1') && ((new Date(2020,1,20, 11,30)) > (new Date())));
  }

  public setLocale(lang: any) {
    if (this.$i18n.locale === lang.locale) {
      return;
    }
    this.$i18n.locale = lang.locale;
    // this.saveLanguage(lang);
    this.$router.replace({path:  this.$router.currentRoute.path, query: { lang: lang.locale } })
  }

  public toggleSidebar() {
    this.$emit('on-sidebar');
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

/**
** Header component
**/
.header {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;

  padding: 14px 32px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

  // z-index: 1;

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    position: relative;
  }

  &__title {
    display: flex;
    align-items: center;
    flex: 1;
    color: $light-gray;

    i.go-back {
      cursor: pointer;
    }
  }

  &__block {
    display: flex;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0;

    &--btn{
      color: $light-gray;
      vertical-align: sub;
    }
    .fa-cog{
      font-size: 1.55rem;
      padding-right: 20px;
    }

    
  }

  &__box-prime {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;

    &__button {
      color: $light-gray;
      border-color: $light-gray;
      margin-right: 10px;
    }

    &__text {
      width: 100px;
      color: $light-gray;
      font-size: 14px;
      line-height: 17px;
    }
  }

  h2 {
    margin: 0;
    color: $light-gray;
  }

  i {
    padding-right: 24px;
    font-size: 22px;
  }

  .mobile-others {
    position: absolute;
    right: 0;
  }

  .fa-bars {
    font-size: 30px;
    color: gray;
  }
}

@media (max-width: 575.98px) {
  .header__block { width: initial; }
}
</style>

<style lang="scss">
  .idiomas > .btn-link {
    padding: 1px 7px 0;
  }
</style>