import Toast from '@/components/alerts/Toast.vue';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { baseEndPoint } from '@/core/endpoint';
import { i18n } from '@/plugins/i18n';
import router from '@/router';
import axios, { Method } from 'axios';
import Router from 'vue-router';

export default class Repository {
  public endPointAPI: ApiRouter;
  public $i18n: any = i18n;
  private baseURL: string = baseEndPoint;
  private repoAxios: any = axios;
  private $router: Router = router;
  private accessToken: string = '';

  constructor(ep: ApiRouter) {
    this.endPointAPI = ep;
    const h: any = { 'Authorization': '', 'Content-Type': '', 'Accept': '', 'Accept-Language': i18n.locale };
    this.endPointAPI.me = { url: '/v1/me', method: 'GET', headers: h, grantType: '' };
  }

  public setI18n(i18nx: any) {
    this.$i18n = i18nx;
  }

  public getRouter(): Router {
    return this.$router;
  }

  public getI18n(): any {
    return this.$i18n;
  }

  public handleTimeout(key: string, data: any, ctype: string): any {
    if (process.env.NODE_ENV != 'local') {
      setTimeout(() => {
        this.call(key, data, ctype);
      }, 1000);
    }
    const message: any = new Toast();
    message.addMsg(this.$i18n.t('Aguarde um momento...').toString());
    message.addTitle(this.$i18n.t('OPS').toString());
    message.open();
  }

  public sleep(ms: any) {
    return new Promise((resolve: any) => setTimeout(resolve, ms));
  }

  public call(key: string, data: any, ctype: string, blockCatch?: boolean, noAuth?: boolean): any {
    return new Promise((resolve: any, reject: any): any => {
      try {
        if (!!this.endPointAPI && !!key && !!this.endPointAPI[key]) {
          let url: any = this.endPointAPI[key]!.url;
          let idn: string = '';

          switch (ctype) {
            case 'multipart/form-data':
              this.endPointAPI[key]!.headers['Content-Type'] = ctype;
              if (data && data.get('id')) {
                idn = data.get('id');
              }
              break;
            case 'application/json':
              this.endPointAPI[key]!.headers['Content-Type'] = ctype;
              if (Object.keys(data).indexOf('id') > -1) {
                idn = data.id;
              }
            default:
              break;
          }

          if (!this.endPointAPI[key]!.headers.Authorization && !noAuth) {
            if (sessionStorage.getItem('access_token')) {
              const token: string = sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token');
              this.endPointAPI[key]!.headers.Authorization = token;
            }
          }

          if (!this.endPointAPI[key]!.headers['Accept-Language']) {
            this.endPointAPI[key]!.headers['Accept-Language'] = i18n.locale
          }

          if (this.endPointAPI[key]!.url.indexOf('/{id}') > -1) {
            idn = !idn ? '0' : idn;
            url = this.endPointAPI[key]!.url.replace('/{id}', '/' + idn);
            delete data.id;
          }

          if (this.endPointAPI[key]!.method === 'GET') {
            const keys: string[] = Object.keys(data);
            if (keys.length > 0) {
              url += (url.indexOf('?') === -1) ? '?' : '&';
              keys.forEach((k: any, indexK:number) => {                
                url = url + k + '=' + encodeURIComponent(data[k]);
                if((indexK) < (keys.length-1) )
                  url += '&';
              });
            }
          } 

          if (process.env.NODE_ENV == 'local') {
            url += (url.indexOf('?') === -1) ? '?' : '&';
            url = url + 'XDEBUG_SESSION_START=PHPSTORM';
          }

          const instance = axios.create({
            baseURL: this.baseURL,
            headers: this.endPointAPI[key]!.headers,
            timeout: 60 * 1 * 1000,
          });
          instance.request({
            data,
            method: (this.endPointAPI[key]!.method as Method),
            url,
          }).then((response: any) => {
            resolve(response.data);
          }).catch((reason) => {
            try {
              // blocks the execution of default error catch
              if (blockCatch) {
                reject(reason);
                return;
              }

              const dt: any = reason.response?.data;
              if (reason.code === 'ECONNABORTED') {
                // TODO: Treat timeout errors
                this.handleTimeout(key, data, ctype);
                return;
              }
              if (dt.type && dt.type.indexOf('w3.org') > -1) {
                //   Message.error("" + dt.detail.replace(/\n/g, "<br />"));
                if (dt.detail !== 'Not Allowed') {
                  this.openAlert(dt.detail.split('\n'), this.$i18n.t('OPS').toString());
                } else {
                  this.pushToLogout();
                }
              } else if (dt.type && dt.type.indexOf('account_status_err') > -1) {
                this.companyStatusIncomplete(dt.detail);
              } else if (dt.message === 'invalid_token') {
                this.pushToLogout();
              } else if (dt.status === 500) {
                this.openAlert([dt.message]);
              }
            } catch (e) {
              // tslint:disable-next-line: no-console
              console.log('e');
            } finally {
              // $.unblockUI();
            }
            reject(reason);
          }).then(() => {
            if (idn) {
              data.id = idn;
            }
            // setTimeout(// $.unblockUI, 60000);
          });
        } else {
          this.pushToLogout();
          console.log(this.endPointAPI)
          reject('Can not call without endPoint Defined !');
        }
      } catch (err) {
        this.pushToLogout();
        reject(err);
      }
    });
  }

  public companyStatusIncomplete(detail: string) {
    const arr: string[] = detail.split('\n');

    
    // openPaths
    const path: string = this.getRouter().currentRoute.fullPath;
    const openPaths: string[] = ['/profile','/plan/','/room/','/redirectLogin'];
    for (let index = 0; index < openPaths.length; index++) {
      if (path.indexOf(openPaths[index]) > -1)
        return true;
    }

    arr.push(this.$i18n.t('Vamos te redirecionar para o formulário de Perfil').toString());
    this.openAlert(arr, this.$i18n.t('Dados incompletos').toString());
    setTimeout(() => {
      this.$router.push({ path: '/profile', query: { lang: this.getI18n().locale }, hash: '#pr1' });
    }, 4000);
    return true;
  }

  public pushToLogout() {
    this.removeItensFromStorage();
    if (router.currentRoute.name === 'Login') {
      return;
    }
    if (router.currentRoute.fullPath.indexOf('redirectUrl') >= 0 ) {
      return;
    }
    this.$router.replace({
      path: '/login', query: {
        lang: this.$i18n.locale, redirectUrl: router.currentRoute.fullPath,
      },
    });
  }

  public openAlert(msg: string[], title?: string) {
    const alert: any = new Toast();
    alert.variant = 'danger';
    const l: boolean = msg.length <= 1;
    msg.forEach((m: string) => {
      if (m == '' && l) {
        alert.addMsg('Ops! Ocorreu um erro inesperado!');
      } else {
        alert.addMsg(m);
      }
    });
    if (title) {
      alert.addTitle(title);
    }
    alert.open();
    console.log('toast as to be opened');
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public create(data: any, ctype?: string, blockCatch?: boolean): Promise<any> {
    return this.call('create', data, (!ctype ? 'application/json' : ctype), blockCatch);
  }

  public update(data: any, ctype?: string, blockCatch?: boolean): Promise<any> {
    return this.call('update', data, (!ctype ? 'application/json' : ctype), blockCatch);
  }

  public fetch(nid: number|string, blockCatch?: boolean): Promise<any> {
    return this.call('fetch', { id: nid }, 'application/json', blockCatch);
  }

  public fetchWithData(data: any, blockCatch?: boolean): Promise<any> {
    return this.call('fetch', (!data ? {} : data), 'application/json', blockCatch);
  }

  public fetchAll(data?: any, blockCatch?: boolean): Promise<any> {
    return this.call('fetchAll', (!data ? {} : data), 'application/json', blockCatch);
  }

  public fetchByToken(data?: any, blockCatch?: boolean): Promise<any> {
    return this.call('fetchByToken', (!data ? {} : data), 'application/json', blockCatch);
  }

  public delete(data?: any, blockCatch?: boolean): Promise<any> {
    return this.call('delete', (!data ? {} : data), 'application/json', blockCatch);
  }

  public me(): Promise<any> {
    return this.call('me', {}, 'application/json');
  }
  
  public meup(data?: any): Promise<any> {
    return this.call('meup', data, 'application/json');
  }

  public myInfo(): Promise<any> {
    return this.call('info', {}, 'application/json');
  }

  public auth(data: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      try {
        const key: string = 'auth';
        const ctype: string = 'application/json';
        if (!!this.endPointAPI && !!key) {
          let url: string = data.grant_type === 'recruiter' ? '/v1/recruiter-auth' : this.endPointAPI[key]!.url;

          this.endPointAPI[key]!.headers['Content-Type'] = ctype;

          if (process.env.NODE_ENV == 'local') {
            url += (url.indexOf('?') === -1) ? '?' : '&';
            url = url + 'XDEBUG_SESSION_START=PHPSTORM';
          }

          data.lang = i18n.locale;

          axios({
            baseURL: this.baseURL,
            data,
            headers: this.endPointAPI[key]!.headers,
            method: (this.endPointAPI[key]!.method as Method),
            url,
          }).then((response) => {
            // console.log("aqui");
            resolve(response.data);
            // setTimeout(// $.unblockUI, 2500);
          }).catch((reason) => {
            if (reason.response) {
              const dt: any = reason.response.data;
              if (dt.type.indexOf('rfc-editor.org') > -1) {
                reject(reason.response.data);
                return;
              }
            }
            reject(reason);
          });
        } else {
          reject('Can not call without endPoint Defined !');
        }
      } catch (err) {
        // console.log(err)
        reject(err);
      }
    });
  }

  public searchByCEP(cep?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      if (!cep) {
        reject(null);
      }
      axios({ url: 'https://viacep.com.br/ws/' + cep!.replace('-', '') + '/json/' })
        .then((response) => {
          const dta = response.data;
          if (!(!dta || dta.erro)) {
            resolve(dta);
          }
          reject('Erro');
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  public saveLocalData(key :string,value :any,expireAt: any) {
    if(key && value) {
      value['jb_expires_at'] = expireAt;
      sessionStorage.setItem(key,btoa(JSON.stringify(value)))
    }
  }

  public _ti(min: number) {
    const d: Date = new Date();
    d.setMinutes(d.getMinutes()+min)
    return d.getTime();
  }

  public getLocalData(key:string) {
      try {
        if(sessionStorage.getItem(key)){
          const d: any = JSON.parse(atob(sessionStorage.getItem(key)+''))
          const now: Date = new Date();
          if((d.jb_expires_at != null && d.jb_expires_at >= now.getTime()) || !d.jb_expires_at) {
            delete d.jb_expires_at;
            return d;
          }
        } 
        return null;
      } catch (e) {
        console.log('cant parse local Data');
        sessionStorage.removeItem(key);
        return null;
      }
  }

}
