<template>
  <div class="box-data">
    <b-form @submit.prevent="saveData">
    <b-row>
      <b-col md="12" class="d-flex justify-content-end align-items-center" >
        <b-link v-if="value.id" :to="{name: 'JobsOpeningsSimpleEdit', params: {id: value.id}, query: {lang: $i18n.locale}}"> 
          <small>{{$t('Change to simplified')}}</small> 
        </b-link>
      </b-col>
      <b-col md="6" sm="12">
        <h2>{{$t('General information')}}</h2>
        <b-row>
          <b-col md="6" sm="12">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Position')"
              :placeholder="$t('Human resources analyst')"
              v-model="value.posicao"
            />
          </b-col>
          <b-col md="6" sm="12">
            <select-input
              :optionList="getListNivel"
              :required="true"
              :class="redForRequired(value.nivel)"
              variant="dark"
              :label="$t('Level')"
              v-model="value.nivel"
              :value="value.nivel"
            />
          </b-col>
          <b-col md="4" sm="12">
            <date-input
              :label="$t('Job Closing')"
              :id="'input-date-end'"
              class="input-date-end"
              v-model="dtFinaliza"
              :placeholder="'dd/mm/yyyy'"
              :format="'DD/MM/YYYY'"
              :typeable="true"
              variant="dark"
              :bootstrap-styling="true"
            />
          </b-col>
          <b-col md="8" sm="12">
            <text-input
              variant="dark"
              :required="false"
              :label="$t('Work time')"
              :placeholder="$t('Full-time')"
              v-model="value.periodoTrabalho"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6" sm="12">
        <h2>{{$t('Location Information')}}</h2>
        <b-row>
          <b-col sm="3">
            <text-input
              variant="dark"
              :required="false"
              :label="$t('Zip Code')"
              v-model="value.zipcode"
              :placeholder="'00000000'"
              @blur="searchByCEP"
            />
          </b-col>
          <b-col sm="9">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Location')"
              v-model="value.localidade"
              :placeholder="$t('Location Sample')"
            />
          </b-col>
          <b-col md="6" sm="12">
            <select-input
              v-show="$i18n.locale=='pt-BR'"
              :optionList="estadoSRV.optionList"
              :class="redForRequired(estadoId)"
              variant="dark"
              :required="$i18n.locale=='pt-BR'"
              :label="$t('State')"
              v-model="estadoId"
              @change="chainToCity"
              :value="estadoId"
            />
          </b-col>
          <b-col md="6" sm="12">
            <select-input
              v-show="$i18n.locale=='pt-BR'"
              :optionList="cidadeSRV.optionList"
              :class="redForRequired(value.cidadeId)"
              variant="dark"
              :required="$i18n.locale=='pt-BR'"
              :label="$t('City')"
              v-model="value.cidadeId"
              :value="value.cidadeId"
              @change="updateLocalidade"

            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col md="4">
        <h2>{{$t('Job Display Options')}}</h2>
        <b-list-group>

          <ListGroupItemCheckbox label="Hide Address" id="hideMap" v-model="value.hideMap" >
            <HelpTip id="hideMap" tip="Location is removed from the display to the candidate" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Send Feedback on close" id="sendMessageAfterClosing" v-model="value.sendMessageAfterClosing" >
            <HelpTip id="sendMessageAfterClosing" tip="Send automatic feedback email to not approved candidates in the process" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Confidencial" id="confidencial" v-model="value.confidencial" :checked="'SIM'" :unchecked="'NAO'">
            <HelpTip id="confidencial" tip="Detailed company data is hidden from candidate" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Restricted" id="restrita" v-model="value.restrita" :checked="'SIM'" :unchecked="'NAO'">
            <HelpTip id="restrita" tip="Only candidates who receive the job link can apply, the vacancy does not appear in the Jobecam board" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Internal" id="interna" v-model="value.interna">
            <HelpTip id="interna" tip="Internal vacancy to be worked within the company, when selecting the vacancy will not appear in the Board of Jobecam, only guests will be able to access the vacancy page" />
          </ListGroupItemCheckbox>

        </b-list-group>
      </b-col>
      <b-col md="4">
        <h2>{{$t('Talent Selection')}}</h2>
        <b-list-group>
          <ListGroupItemCheckbox label="Anonymous Interview" id="blind" :checked="1" :unchecked="0"
            :disabled="forceAnonimous" v-model="value.blind" v-if="permitBlind && !forceAnonimous">
            <HelpTip id="blind" tip="All candidate information is hidden, only the sound of the interview can be evaluated" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Anonymous Resume" id="blind_curr" :disabled="value.requireProfile" v-model="value.mostrarCurriculoOculto" v-if="permitBlind">
            <HelpTip id="blind_curr" tip="Enable hidden CV so candidate can share information for your hidden review" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Prevent talent revelation" id="block_approve" v-model="value.blockApprove" v-if="permitBlind && permitBlockApprove">
            <HelpTip id="block_approve" tip="Blocks the talent revelation functionality in the room" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Required Full Profile" id="fullprofile" :disabled="value.mostrarCurriculoOculto"
            v-model="value.requireProfile" v-if="!forceAnonimous">
            <HelpTip id="fullprofile" >
              <ul class="list-unstyled">
                <li> {{ $t("Contact data") }}</li>
                <li> {{ $t("Resume PDF") }}</li>
                <li> {{ $t("Professional experience") }}</li>
                <li> {{ $t("Academic background") }}</li>
                <li> {{ $t("Video Curriculum") }}</li>
                <li> {{ $t("In case of anonymous curriculum, this option is disabled") }}</li>
              </ul>
            </HelpTip>
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Review Step" id="revisaoCurricular" v-model="value.revisaoCurricular" v-if="permitReview">
            <HelpTip id="revisaoCurricular" tip="Candidates can only record the interview after approval by Recruiter" />
          </ListGroupItemCheckbox>

        </b-list-group>
      </b-col>
        
      <b-col md="4">
        <h2>{{$t('Additional steps and settings')}}</h2>
        <text-input
          variant="dark"
          :required="false"
          :label="$t('Post')"
          v-model="value.cargo"
          maxlength="20"
          :placeholder="'...'"
        />
        <text-input
          variant="dark"
          :required="false"
          :label="$t('Application button text')"
          v-model="value.applyButtonText"
          maxlength="20"
          :placeholder="$t('Apply now')"
        /> 
        <text-input
          variant="dark"
          :required="false"
          :label="$t('Contracting Company')"
          v-model="value.nomeEmpresa"
          maxlength="20"
          :placeholder="'...'"
        />
        <label for="daysToRecord" class="m-0">
          <!-- :label="$t('Dias para gravar a entrevista')" -->
          {{$t('Days to record the interview')}}
          <b-button id="button-daysToRecord" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
          <b-tooltip target="button-daysToRecord" placement="top">
            <ul class="list-unstyled">
              <li> {{ $t("Inform the days that the candidate has to record the interview from his application!") }}</li>
              <li> {{ $t("If you leave the field empty or fill it with 0, no limit of days will be applied to carry out the video interview!") }}</li>
            </ul>
          </b-tooltip>
        </label>
        <text-input 
          id="daysToRecord"
          type="number"
          variant="dark"
          step="1.0"
          placeholder="0"
          v-model="value.daysToRecord"
        />    
      </b-col>
    </b-row>

    <!-- Remuneração -->
    <b-row>
      <b-col md="12">
        <h2>{{$t('Remuneration')}}</h2>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col md="4" sm="12">
            <text-input 
                variant="dark"
                :required="false"
                :label="$t('Salary information (free text)')"
                :placeholder="$t('i.e. negotiating, confidential ...')"
                v-model="value.salario"
              />
          </b-col>
          <b-col md="2" sm="12" class="d-flex align-items-center justify-content-center">
            <b-form-checkbox v-model="value.hideSalary" switch :value="false" :unchecked-value="true">
              <label> {{ $t('Show salary')  }}</label>
            </b-form-checkbox>
          </b-col>
          <b-col md="3" sm="6">
            <text-input 
              type="number"
              variant="dark"
              :required="!value.hideSalary"
              :label="$t('Minimum Salary')"
              step="0.01"
              v-model="value.salaryMin"
            />
          </b-col>
          <b-col md="3" sm="6" >
            <text-input
              type="number"
              variant="dark"
              :required="false"
              :label="$t('Maximum Salary')"
              step="0.01"
              v-model="value.salaryMax"
            />
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <!-- permissão em vaga -->
    <form-vaga-equipes v-if="permitEquipes" v-model="selectedTeams" :equipes-vaga="value.equipesVaga" />

    <!-- descrição de vaga -->
    <div class="row">
      <div class="col-12 col-md-12">
        <h2> {{$t('Job Details')}} </h2>
      </div>
      <div class="col-sm-12 col-md-3">
        <select-input
          :optionList="listCategoria"
          :required="false"
          variant="dark"
          :label="$t('Category')"
          v-model="value.category"
          :value="value.category"
        />
      </div>
      <div class="col-sm-12 col-md-3">
        <select-input
          :optionList="getListContractType"
          :required="false"
          variant="dark"
          :label="$t('Type of Contract')"
          v-model="value.contractType"
          :value="value.contractType"
        />
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <form @submit.prevent="addBeneficio">
          <text-input
            variant="dark"
            :required="false"
            :label="$t('Benefits')"
            v-model="beneficioValue"
            maxlength="255"
            :placeholder="'...'"
            class="mb-0"
          />
          <small>{{$t("Type the benefit and hit 'enter' on the keyboard to enter it")}}.</small>
        </form>
        <info-tag
          class="tags-beneficios float-right"
          v-for="(beneficio, i) in beneficiosArr"
          v-bind:key="'b_'+i"
          :text="beneficio"
          :size="'small'"
          icon="fa fa-times"
          @click="delBeneficio(i)"
        />

      </div>
      <div class="col-md-6 col-sm-12">
        <h6 :class="redForRequired(value.descricao)">{{ $t("Job Description") }}*</h6>
        <editor :init="tinyMCEini" v-model="value.descricao"></editor>
      </div>
      <div class="col-md-6 col-sm-12">
        <h6 :class="redForRequired(value.competencias)">{{ $t("Competences") }}*</h6>
        <editor :init="tinyMCEini" v-model="value.competencias"></editor>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md-12 box-save-data">
        <button type="submit"
          class="btn btn-info btn-salvar text-uppercase"
          v-promise-btn="{promise}"
        >
          <i class="icon-check"></i>
          {{$t('Save and Proceed')}}
        </button>
      </div>
    </div>
    </b-form>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Validate } from "vuelidate-property-decorators";
import { required, sameAs, email, requiredIf } from "vuelidate/lib/validators";
import { VagaEntity, DateEntity, EmpresaEntity, RecrutadorEntity } from "@/core/models/Entities";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import Editor from "@tinymce/tinymce-vue";
import EstadoService from "@/core/Services/Estado";
import CidadeService from "@/core/Services/Cidade";
import moment from "moment";
import PosicaoTrabalho from '@/core/Services/Basic/PosicaoTrabalho';
import FormVagaEquipes from './FormVagaEquipes.vue';
import ListGroupItemCheckbox from '@/components/lists/ListGroupItemCheckbox.vue';
import HelpTip from '@/components/tooltip/HelpTip.vue';

@Component({
  components: {
    editor: Editor,
    FormVagaEquipes,
    ListGroupItemCheckbox,
    HelpTip
  }
})
export default class FormVagaDados extends Vue {
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public posicaoTrabalho!: PosicaoTrabalho;

  public faixaVariavel: boolean = true;
  public dtFinaliza: Date | null = null;
  public service: GestaoVaga;
  public estadoId: any = "";
  public cidadeId: any = "";
  
  public listNivel: any = [];
  public promise: Promise<any> | null = null;
  public listContractType: any = []; 
  public beneficioValue: string| null = null;
  public selectedTeams: any = []; 
  

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: 'profile' })
  public recrutador?: RecrutadorEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;
  
  @Prop({ default: () => { return new VagaEntity(); } })
  public value!: VagaEntity;

  constructor() {
    super();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
    this.service = new GestaoVaga();
    this.posicaoTrabalho = new PosicaoTrabalho();
    this.estadoSRV.loadOptions();
    this.posicaoTrabalho.loadOptions();
  }

  public async handleVagaChange() {
    this.value.simplificada = false;

    if (this.value.cidade) {
      this.estadoId = this.value.cidade.ufId;
      this.chainToCity();
    }

    this.updateDataFinaliza();
  }

  get forceAnonimous () {
    return (this.empresa?.configuracoes?.ENABLE_FORCE_ANONIMOUS == true);
  }

  get listCategoria() {
    return this.posicaoTrabalho ? this.posicaoTrabalho.optionList : [];
  }

  get permitEquipes () {
    return (this.empresa?.configuracoes?.ENABLE_RECRUITING_GROUP == true);
  }


  public updateDataFinaliza() {
    if (this.value.dataEncerra != null && this.value.dataFinaliza == null) {
      this.dtFinaliza = moment(
        (<DateEntity>this.value.dataEncerra).date
      ).toDate();
    } else if (this.value.dataFinaliza == null) {
      this.dtFinaliza = moment()
        .add(1, "M")
        .toDate();
    } else if (this.value.dataFinaliza != null) {
      this.dtFinaliza = moment(
        (<DateEntity>this.value.dataFinaliza).date
      ).toDate();
    }
  }

  public searchByCEP() {
    this.cidadeSRV
      .searchByCEP(this.value.zipcode)
      .then(dta => {
        this.value.localidade = `${dta.logradouro}, ${dta.bairro} - ${dta.localidade} / ${dta.uf}`;
        this.estadoId = this.estadoSRV.estadoByName(dta.uf);
        this.chainToCity(dta.localidade);
      });
  }

  get permitReview () {
    return (this.empresa && this.empresa!.configuracoes && this.empresa!.configuracoes['REVIEW_ENABLED'] == true);
  }


  get getListNivel() {
    return this.listNivel = [
      { text: this.$t("Trainee"), value: "Trainee" },
      { text: this.$t("Aprendiz"), value: "Aprendiz" },
      { text: this.$t("Estágio"), value: "Estágio" },
      { text: this.$t("Júnior"), value: "Júnior" },
      { text: this.$t("Pleno"), value: "Pleno" },
      { text: this.$t("Sênior"), value: "Sênior" },
      { text: this.$t("Consultor"), value: "Consultor" },
      { text: this.$t("C Level"), value: "C Level" },
      { text: this.$t("Outros"), value: "Outros" }
    ];
  }

  get getListContractType() {
    return this.listContractType = [
      { text: '', value: '' },
      { text: this.$t('CLT (Efetivo)'), value: 'CLT (Efetivo)' },
      { text: this.$t('Prestador de Serviços (PJ)'), value: 'Prestador de Serviços (PJ)' },
      { text: this.$t('Freelancer'), value: 'Freelancer' },
      { text: this.$t('Trainee'), value: 'Trainee' },
      { text: this.$t('Autônomo'), value: 'Autônomo' },
      { text: this.$t('Temporário'), value: 'Temporário' },
      { text: this.$t('Cooperado'), value: 'Cooperado' },
      { text: this.$t('Estagiário'), value: 'Estagiário' },
      { text: this.$t('Associado'), value: 'Associado' },
    ];
  }

  public async mounted() {
    if (!this.empresa)
       await this.fetchEmpresaInfo();

    this.handleVagaChange()
  }

  get permitBlind () {
    return (this.empresa?.configuracoes?.BLIND_JOB_REVIEW == true);
  }

  get permitBlockApprove () {
    return (this.empresa?.configuracoes?.ENABLE_CLOSE_APPROVEMENT == true);
  }

  public getDateDB(v: Date): string {
    const day = v.getUTCDate().toString();
    const month = (v.getUTCMonth() + 1).toString();
    const year = v.getUTCFullYear();
    return (
      year +
      "-" +
      (month.length == 1 ? "0" + month : month) +
      "-" +
      (day.length == 1 ? "0" + day : day)
    );
  }

  public saveData() {
    this.value.equipes = this.selectedTeams?.map((t: any) => { return t.id })

    if (this.dtFinaliza)
      this.value.dataFinaliza = this.getDateDB(this.dtFinaliza);

    this.value.simplificada = false;

    this.service.entidade = this.value;
    this.promise = this.service
      .save()
      .then(data => {
        //seta o id da vaga se deu certo.
        this.$emit('input',data);
        this.$emit("saved", data);
      })
      .catch(err => {
        console.log(err);
        this.$emit("error");
      });
  }

  public async updateLocalidade() {
    if (!this.value.cidadeId) return;

    if (!this.value.localidade) {
      let cid = await this.cidadeSRV.fetch(this.value.cidadeId);  
      this.value.localidade = cid.completoNome;
    }
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(this.estadoId);
    if (value)
      this.value!.cidadeId = this.cidadeSRV.cidadeByName(value);
  }


  get beneficiosArr() {
   return this.value.beneficios||[];
  }

  public addBeneficio() {
    if (!this.beneficioValue)
      return;

    if (!this.value.beneficios)
      this.value.beneficios = [];

    this.value.beneficios.push(this.beneficioValue); 
    this.beneficioValue = '';
  }

  public delBeneficio(i: number) {
    if (this.value.beneficios) {
      this.value.beneficios.splice(i,1);
    }
  }

}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";

.principal-container {
  .box-save-data {
    display: flex;
    justify-content: flex-end;
  }
}

// Media Queries
@include media("<desktop") {
  .title {
    font-size: 2em !important;
  }
}
@include media(">desktop") {
  .title {
    font-size: 2em !important;
  }
  .box-data,
  .box-questions {
    font-size: 0.8em;
  }
}
@include media(">large") {
  .box-data {
    font-size: 1.4em;
  }
}
</style>