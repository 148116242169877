<template>
  <div class="nivel" :class="theme">
    <label>{{ label }}</label>
    <div class="progress">
      <div
        v-for="i in howManyLevels"
        :key="i"
        :class="{ active: i <= activeLevels }"
        :style="{ width: (100 / howManyLevels) + '%' }"
        class="progress-bar"
        role="progressbar"
      ></div>
      <!-- <div class="progress-bar active" role="progressbar" style="width: 20%"></div>
      <div class="progress-bar active" role="progressbar" style="width: 20%"></div>
      <div class="progress-bar" role="progressbar" style="width: 20%"></div>
      <div class="progress-bar" role="progressbar" style="width: 20%"></div>
      <div class="progress-bar" role="progressbar" style="width: 20%"></div>-->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class NivelBar extends Vue {
    @Prop({ default: 5 }) public howManyLevels!: string;
    @Prop({ default: 2 }) public activeLevels!: string;
    @Prop({ default: '' }) public label!: string;
    @Prop({ default: '' }) public theme!: string;
}
</script>

<style lang="scss" scoped>
.nivel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  padding: 0.5em 1em;
  label {
    font-size: 1em;
  }

  .progress {
    background-color: transparent;
    height: 0.7em;
    .progress-bar {
      background-color: #c1fffd;
      &.active {
        background-color: #6adad7;
      }
      &:hover {
        background-color: #6adad7;
      }
    }
    .progress-bar:first-child {
      margin-right: 0.2em;
    }
    .progress-bar:last-child {
      margin-left: 0.2em;
    }
    .progress-bar:not(:first-child):not(:last-child) {
      margin: 0 0.2em;
    }
  }
  &.light {
    label {
      color: white;
    }
  }
}
</style>
