<template>
  <b-card no-body>    
    <b-card-body>
      <b-form @submit.prevent="saveData">
        <b-row>
          <b-col cols="11"> 
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Title')"
              :placeholder="vaga.posicao"
              :maxlength="100"
              v-model="avaliacao.title"
            />
          </b-col>
          <b-col cols="1" class="d-flex flex-wrap justify-content-center align-items-middle">
            <b-button id="button-title-ga" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
            <b-tooltip target="button-title-ga" placement="top">
              <p> {{$t('Title of this evaluation list, this information goes on the subject of the email sent to management')}}</p>   
            </b-tooltip>
          </b-col>
          <b-col md="12">
            <a href="javascript:void(0)" @click="more = !more"> 
              {{ $t('More options')  }}  
              <i class="fa fa-angle-down" v-if="more" ></i>
              <i class="fa fa-angle-right" v-else ></i>
            </a>
          </b-col>
          <b-col sm="12" v-if="more" class="d-flex">
            <textarea-input
              class="w-100"
              variant="dark"
              :required="true"
              :label="$t('Message to the manager')"
              :maxlength="255"
              v-model="avaliacao.description"
            />
            <b-button id="button-desc-ga" variant="x" pill size="sm" class="text-blue flex-shrink-1 " > <i class="icon-ajuda" /> </b-button>
            <b-tooltip target="button-desc-ga" placement="top">
              <p> {{$t('Description will be added to the body of the email, sent to those who will be evaluating the talents')}}</p>   
            </b-tooltip>
          </b-col>
          <b-col sm="12"  v-if="more">
            <!-- <div class="d-flex justify-content-start pr-5 mr-5 mb-1">
              <label for="canExpires" class="pr-3">{{$t("Limit access time")}}</label>
              <b-form-checkbox
                id="canExpires"
                v-model="avaliacao.canExpires"
                name="check-can-expires"
                switch
              >
              </b-form-checkbox>
            </div> -->
          </b-col>
          <b-col sm="12" v-if="more && avaliacao.canExpires">
            <div class="d-flex align-items-center">
              <text-input
              class="flex-grow-1"
                type="number"
                variant="dark"
                :required="true"
                :label="$t('Expires in')"
                v-model="avaliacao.daysToExpire"
                :maxNumber="90"
              /> <small>*{{$t('days')}}</small>
            </div>
            <b-alert variant="light" show>
              {{$t('Link expires in')}} {{expirationDate}}
            </b-alert>
          </b-col>

          <b-col md="12" v-if="candidatos.length > 0">
            <b-table striped hover :items="candidatos" :fields="fieldsCandidatos" small>
              <template #cell(fotoAvatar)="row">
                <div class="foto-perfil-avaliacao">
                  <img :src="row.value" v-if="row.value && row.value.indexOf('media.licdn.com')==-1"/>
                  <img src="/static/img/avatar.png" alt v-else/>
                </div>
              </template>
              <template #cell(primeironome)="row">
                <div class="d-flex flex-column">
                  <div class="text-truncate">{{ (!!row.item.email) ? (row.value+' '+row.item.sobrenome) : row.item.cid }}</div>
                  <span class="text-gray m-0">{{ row.item.email }}</span>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col md="12" class="pt-4 mt-3 border-top text-center" v-else>
            <b-alert variant="warning" show>
              <div> <strong> {{$t('Attention')}}</strong> </div>
              <div> {{$t('No selected candidacy')}} </div>
            </b-alert>
          </b-col>
          
          <b-col md="12" >
            <form-gestores @add="addManager" ></form-gestores>
          </b-col>
           <b-col md="12" >
            <b-table striped hover :items="avaliacao.gestores" :fields="fieldsManagers" small>
              <template #cell(guid)="row">
                <b-button variant="danger" class="circle-round" pill size="sm" @click="remManager(row.item)">
                  <small><i class="fas fa-times"></i></small>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        
        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-center">
            <b-button type="submit" variant="green" class="btn px-5 shadow" v-promise-btn="{promise}">
              <i class="far fa-paper-plane"></i>
              {{$t('Save')}}
            </b-button>
          </b-col>
        </b-row>  
      </b-form>
    </b-card-body>

  </b-card>
</template>

<script lang="ts">
import GrupoAvaliacao from '@/core/Services/Empresa/GrupoAvaliacao';
import { CandidaturaEntity, EmpresaEntity, GrupoAvaliacaoEntity, GrupoAvaliacaoGestorEntity, RecrutadorEntity, VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import FormGestores from './FormGestores.vue';

@Component({
  components: {
    FormGestores
  }
})
export default class FormGrupoAvaliacao extends Vue {

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: "profile" })
  public recrutador?: RecrutadorEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
    
  @Prop({ default: null })
  public candidaturas!: CandidaturaEntity[]|null;

  public avaliacao: GrupoAvaliacaoEntity = new GrupoAvaliacaoEntity();
  public service: GrupoAvaliacao;
  public promise: Promise<any>|null = null;
  public more: boolean = false;
  public fieldsManagers: any[] = [
    { key: 'nomeGestor', label: this.$t('Name'), class: 'text-center' },
    { key: 'emailGestor', label: this.$t('E-mail'), class: 'text-center' },
    { key: 'guid', label: '-', class: 'text-center' },
  ]
  public fieldsCandidatos: any[] = [
    { key: 'fotoAvatar', label: ' ', class: 'text-center' },
    { key: 'primeironome', label: this.$t('Candidates'), class: 'text-left' }
  ]

  get expirationDate() {
   const c = new Date(new Date().getTime()+(this.avaliacao.daysToExpire*24*60*60*1000))
   return this.$formatDate(c,'DD/MM/YYYY',this.$i18n.locale);
  }

  constructor(){
    super();
    this.service = new GrupoAvaliacao();
    this.avaliacao = new GrupoAvaliacaoEntity();
  }

  get isBlind() {
    return (this.vaga?.blind||0) == 1
  }

  get candidatos() {
    return this.candidaturas?.map((v) => { return { ...v.candidato, cid: v.id } })
  }
  public mounted() {
    this.avaliacao.description = this.$t('Evaluate').toString();
  }

  public addManager(gestor: GrupoAvaliacaoGestorEntity) {
    if(!this.avaliacao.gestores)
      this.avaliacao.gestores = [];

    if(!gestor.emailGestor && !gestor.nomeGestor)
      return;
    
    const index:number = this.avaliacao.gestores.findIndex((e: GrupoAvaliacaoGestorEntity) => { return e.emailGestor == gestor.emailGestor });
    if(index == -1)
      this.avaliacao.gestores.push(gestor)

  }

  public remManager(gestor: GrupoAvaliacaoGestorEntity) {
    const index:number = this.avaliacao.gestores.findIndex((e: GrupoAvaliacaoGestorEntity) => { return e.emailGestor == gestor.emailGestor });
    if(index > -1) {
      if(!this.avaliacao.gestores[index].guid) {
        this.avaliacao.removedGestores.push(this.avaliacao.gestores[index].guid+"")
      }

      this.avaliacao.gestores.splice(index,1)
    }
  }


  public validateData(): boolean {
    let valid: boolean = true;
    this.avaliacao.candidaturas = (this.candidaturas?.map(v => {return v.id||0 })||[])

    // candidato é obrigatório
    if(!this.avaliacao.title) {
      this.$alert.addMsg(this.$t('It is not possible to perform the action without defining the title'))
      valid = false;
    } 
    
    if(!this.avaliacao.description) {
      this.$alert.addMsg(this.$t('It is not possible to perform the action without defining the description'))
      valid = false;
    } 
    
    if(this.avaliacao.candidaturas.length == 0) {
      this.$alert.addMsg(this.$t('It is not possible to perform the action without defining the talents'))
      valid = false;
    } 
    
    // // empresa é obrigatório se logado
    if(this.avaliacao.gestores.length == 0) {
      this.$alert.addMsg(this.$t('It is not possible to perform the action without defining managers'))
      valid = false;
    } 
    
    if(!valid){
      this.$alert.callBoxWarn()
    }

    return valid;
  }

  public cleanForm() {
    this.avaliacao.guid = null;
    this.avaliacao.title = null;
    this.avaliacao.description = null;
  }

  public saveData() {

    if(!this.validateData())
      return;
      
    this.promise = this.service.create(this.avaliacao)
    .then((d: any) => {
      this.$toast.variant = 'success';
      this.$toast.addMsg(this.$t('Saved successfully').toString())
      this.$toast.addTitle(this.$t('Success').toString());
      this.$toast.open();

      this.$emit('saved',this.avaliacao)
    }).catch((e: any) => {
      this.$toast.variant = 'danger';
      this.$toast.addMsg(this.$t('An error occurred when loading the evaluations!').toString())
      this.$toast.addTitle(this.$t('Oops').toString());
      this.$toast.open();
      this.$emit('error',e.message)
    })

    
  }

}
</script>
