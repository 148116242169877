<template>
  <div>
    <b-button 
      v-b-toggle.collapse-answer 
      class="collapse-question text-left btn-info"
      :class="showCollapse ? 'collapsed' : null"
      :aria-expanded="showCollapse ? 'true' : 'false'"
      aria-controls="collapse-text"
      @click="showCollapse = !showCollapse"
    >
      {{question}}
      <i class="fas fa-chevron-down"></i>
    </b-button>
    <b-collapse id="collapse-text" v-model="showCollapse">
      <b-card-body class="collapse-content">
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonCollapse extends Vue {
  @Prop({default: ""}) public question!: string;

  public showCollapse: boolean = false;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

  .collapse-question {
    width: 100%;
    padding: 1rem;
    margin-bottom: 10px;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .collapse-content {
    width: 100%;
    background: #f3f3f3;
    margin-bottom: 10px;

    &.card-body {
      font-size: 1rem;
      color: $gray;
      border-radius: 4px;
    }
  }
</style>
