<template>
<b-form-group
    :id="'input-group-' + scopeID"
    :label="label+''"
    :label-for="'input-' + scopeID">
    <b-form-checkbox :id="'input-' + scopeID"
        @input="handleInput($event)"
        @click="handleClick($event)"
        :required="required"
        :checked="value"
        :value="accepted"
        :disabled="disabled"
        :unchecked-value="not_accepted"
        :switch="switch_type"
        :size="input_size">
        <slot></slot>
    </b-form-checkbox>
</b-form-group>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CheckboxInput extends Vue {
    public scopeID: string;

    @Prop()
    public value!: any;

    @Prop({default: false})
    public required!: boolean;

    @Prop({default: ''})
    public label!: string;

    @Prop({default: ''})
    public input_size!: string;

    @Prop({default: () => { return false } })
    public accepted!: any;

    @Prop({default: () => { return false } })
    public switch_type!: any;

    @Prop({default: () => { return false } })
    public not_accepted!: any;

    @Prop({default: () => { return false } })
    public disabled!: any;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput (v: any) {
        this.$emit('input', v);
    }
}
</script>

<style lang="scss" scoped>
</style>

