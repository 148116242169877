
export enum ECandidaturaStatus {
  Aprovado = 'APROVADO',
  Reprovado = 'REPROVADO',
  Analise = 'EMANALISE',
  Pendente = 'VIDEOSPENDENTES',
  Repescagem = 'EMREPESCAGEM',
  Processando = 'VIDEOSEMPROCESSAMENTO',
  ExamesPendentes = 'EXAMESPENDENTES',
  Registro = 'EMREGISTRO'
}
