
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { MetodoPagamentoEntity } from '@/core/models/Entities';

export default class Metodo extends Repository {
  public entidade: MetodoPagamentoEntity;
  constructor() {
    super(new ApiRouter({
        create: { url: '/v1/metodo-pagamento', method: 'POST', headers: {} },
        fetch: { url: '/v1/metodo-pagamento/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/metodo-pagamento', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/metodo-pagamento/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new MetodoPagamentoEntity();
  }

  public fetchCurrent(): Promise<any> {
    return this.fetchWithData({id: 0, current: true}).then((data) => {
      this.entidade = data;
    })
  }

}
