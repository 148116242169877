<template>
  <b-container>
    <b-row>
    <div class="col-12">
      
      <div class="vld-parent">
          <loading :active.sync="isLoading" 
          :can-cancel="false" 
          :background-color="'#EEECEC'"
          :opacity="1"
          :is-full-page="true"></loading>
      </div>

      <div v-show="isErro" align="center">
        <h1>{{$t("Application not found!")}}</h1>
        <img src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/open_live_room.jpg" style="width:50%" />
      </div>

      <!-- Info do Candidato -->
      <div v-show="!isErro && !isLoading" class="candidato-info" :class="{ 'isActive' : (mobileIsShowQuestions) }">
        <vaga-header :vaga="vaga"/>
        <div class="candidaturas-empty text-center mb-5" v-if="!candidatura">
          <img
            src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/video-curriculo-empty.png"
            class="candidaturas-empty__img"
            alt="candidaturas-empty.png"
          >
          <p class="candidaturas-empty__text">{{$t("Select a candidate beside to view video interviews.")}}</p>
        </div>
        <div v-else-if="!isLoading" >
          <candidato-header
            ref="cheader"
            :candidatura="candidatura" 
            :vaga="vaga" 
            @statusAprovado="statusAprovado"
            @statusReprovado="statusReprovado"
            @statusAnalise="statusAnalise"
            @granted="ncGranted" 
            @not-granted="ncNotGranted" 
            :is-public="true"
          />
          <video-interview :candidatura="candidatura" :vaga="vaga" :token="token" :is-public="true" />
          <candidato-rating :candidatura="candidatura" :vaga="vaga" :is-public="true" :token="token" />
        </div>    
      </div>

      <modal-message 
        id="modalBulkMessage" 
        :candidatura="candidatura"
        :vaga="vaga"
        :multipleSelection="[candidato.id]"
      />
      <modal-share-link 
        id="modalBulkShareLink" 
        :candidatura="candidatura"
        :multipleSelection="[candidatura.id]"
      />
      <modal-new-chance 
        id="modalBulkNewChance" 
        :candidatura="candidatura"
        :multipleSelection="[candidatura.id]"
      />
    </div>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import ModalNewChance from "@/components/modal/ModalNewChance.vue";
import ModalSendMessage from "@/components/modal/ModalSendMessage.vue";
import ModalShareLink from "@/components/modal/ModalShareLink.vue";
import PublicCandidaturaService from '@/core/Services/Empresa/PublicCandidatura';
import CandidatoHeader from "@/views/candidatos/CandidatoHeader.vue";
import CandidatoRating from "@/views/candidatos/CandidatoRating.vue";
import VagaDetailHeader from "@/views/vagas/details/VagaDetailHeader.vue";
import VagaVideoInterview from "@/views/vagas/details/VagaVideoInterview.vue";
import Loading from 'vue-loading-overlay';
import { Component, Vue } from 'vue-property-decorator';


import { ECandidaturaStatus } from '@/core/Enums/ECandidaturaStatus';
import {
CandidatoEntity,
CandidaturaEntity,
CandidaturasViewModel,
EmpresaEntity,
VagaEntity
} from '@/core/models/Entities';

@Component<PublicCandidatura>({
  components: {
    'button-icon': ButtonIcon,
    'vaga-header': VagaDetailHeader,
    'candidato-header': CandidatoHeader,
    'video-interview': VagaVideoInterview,
    'candidato-rating': CandidatoRating,
    'modal-message': ModalSendMessage,
    'modal-share-link': ModalShareLink,
    'modal-new-chance': ModalNewChance,
    Loading
  },
})
export default class PublicCandidatura extends Vue {
  public mobileIsShowQuestions = false;
  public service: PublicCandidaturaService;
  // Candidatura Selecionada
  public candidatura: CandidaturaEntity = new CandidaturaEntity();
  public candidato: CandidatoEntity = new CandidatoEntity();
  public vaga: VagaEntity = new VagaEntity();
  public vagaId: number = 0;
  public empresa: EmpresaEntity = new EmpresaEntity();
  public statusCandidato!: string;
  public token: string|null = null;
  public isLoading: Boolean = true;
  public isErro: Boolean = false;

  // Lista de Candidaturas retornada pela busca (OBS:de acordo com os status informados)
  public candidaturasViewModel: Map<string,CandidaturasViewModel> = new Map<string, CandidaturasViewModel>();

  constructor() {
    super();
    this.service = new PublicCandidaturaService();
  }
  
  public async filterResults() {
    await this.service.fetchAll({token: this.token}).then((data: any) => {
      if (data) {
        this.vaga = data['vaga'];
        this.candidatura = data['candidatura'];
        this.empresa = (this.vaga.empresa||new EmpresaEntity());
        this.vagaId = this.vaga.id||0;
        this.statusCandidato = this.candidatura.status||'';
      }
    }).catch((err) => {
      console.log(err);
      this.isErro = true;
    });
    this.isLoading = false;
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public mounted() {
    this.cleanSession();
    this.isLoading = true;
    this.token = (this.$route.params.tkn as string);
    this.filterResults();
    document.body.style.overflow = 'auto';
  }

  public statusReprovado(id: number) {
    this.service.update({id: id, status: ECandidaturaStatus.Reprovado, token: this.token})
    .then((data: any) => {
     console.log('done')   
    }).catch((err: any) => {
      console.log(err)
    })
  }
  public magicRefreshCandidatoInfo(data: CandidaturaEntity) {
    this.candidato = data['candidato'];
    this.candidatura!.status = 'APROVADO';
  }

  public statusAprovado(id: number) {
    this.service.create({id: id, status: ECandidaturaStatus.Aprovado, token: this.token})
      .then((data: any) => {
        this.magicRefreshCandidatoInfo(data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public statusAnalise(id: number) {
    this.service.create({id: id, status: ECandidaturaStatus.Analise, token: this.token})
      .then((data: any) => {
     console.log('done')
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public ncGranted(cid) {
    this.$alert.addMsg(this.$t('New chance granted to the candidate ({cid})',{ cid }));
    this.$alert.callBoxCheck(this.$t('Ok').toString()).then(() => {
      window.close();
    });
  }

  public ncNotGranted(cid) {
    console.log('not-granted',cid)
  }


  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

  .candidato-info,
  .candidato-list {
    background-color: $white;
    padding: 1.5em;
  }
  .candidato-info {
    margin-right: 0.2em;
    border-top-left-radius: 8px;
    padding: 0 1.5em;
    p {
      margin: 0;
    }
    // .info-header {
    //   padding: 1em 0;
    //   border-bottom: 1px solid #dcdcdc;
    //   h1 {
    //     font-size: 2.5em;
    //   }
    // }
    .info-questions {
      .foto-perfil {
        padding: 2em;
        img {
          width: 6em;
          border-radius: 50%;
        }
      }
      .buttons {
        h3 {
          font-size: 1.5em;
        }
      }
    }
  }

  .candidaturas-empty {
    // border: 1px solid #efefef;
    border-radius: 6px;
    &__text {
      font-size: 1rem;
      color: $blue;
    }
    &__img {
      width: 500px;
      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }

@include media("<desktop") {
  .container-children {
    font-size: 0.8em;
    margin: 0;
    margin-top: 1em;
    padding: 0;
    border: 0;

    &.mobile {
      .candidato-info,
      .candidato-list {
        width: 100%;
        border: 0;
        border-radius: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
      }
      .candidato-info {
        position: absolute;
        right: 100vw;
        transition: right 0.5s ease;
        background-color: white;
        z-index: 9999;
        &.isActive {
          right: 0 !important;
          transition: right 0.5s ease;
        }
        .info-questions {
          .foto-perfil,
          .buttons {
            margin: 1em 0;
          }
          .foto-perfil {
            padding: 0;
            img {
              width: 4em;
            }
          }
        }
        .videos-questions {
          .buttons-question {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            button {
              flex: 0 0 auto;
              width: 12em;
            }
          }
          .video {
            .xd {
              height: 160px;
            }
          }
        }
        .ratings {
          margin-bottom: 4em;
        }
      }
      .candidato-list {
        padding: 1em;
        h3 {
          margin: 0;
        }
        .list {
          margin-top: 0.5em;
        }
      }
    }
  }
}
</style>

<style lang="scss">
// #modalFiltro .modal-body {
//   padding: 0;
// }
</style>
