<template>
  <transition appear name="slide">
    <b-row class="my-2">
      <b-col md="12" class="mb-3 py-2 ">
        <hr />
        <h4 class="text-info"> {{$t('Tests')}}</h4>
        <small>{{ $t('Define the tests to be applied to candidates after qualifying questions') }}</small>
      </b-col>
      <b-col md="3" >
        <b-button id="button-tempo-total" variant="light" pill size="sm" class="p-0 float-right text-info" > <i class="icon-ajuda" /> </b-button>
        <b-tooltip target="button-tempo-total" placement="top">
          <p> {{$t('Total time the candidate has to take ALL the tests')}}</p>   
        </b-tooltip>
        <text-input
          variant="dark"
          :required="true"
          :label="$t('Total time (minutes)')"
          v-model="tempoTotal"
          @blur="updateMaxTime"
          type="number"
          :placeholder="'180'"
        />
      </b-col>
      <b-col md="3">
        <b-button id="button-perc-corte" variant="light" pill size="sm" class="p-0  float-right text-info" > <i class="icon-ajuda" /> </b-button>
        <b-tooltip target="button-perc-corte" placement="top">
          <p> {{$t('Minimum cut-off score for each test, if the candidate does not pass this minimum he will be automatically disqualified')}}</p>   
        </b-tooltip>
        <text-input
          variant="dark"
          :required="true"
          :label="$t('% Passing Score')"
          v-model="notaCorte"
          type="number"
          :placeholder="'70'"
        />
      </b-col>
      <b-col md="5">
         <select-input
            :optionList="service.optionList"
            variant="dark"
            :label="$t('Test Select')"
            v-model="currentExamId"
          />
      </b-col>
      <b-col md="1" class="text-center">
        <label for="btn-add-tests" style="font-size:12px;">{{$t('Add')}}</label> <br />
        <b-button variant="outline-primary" class="shadow-sm shadow-sm-hover" id="btn-add-tests"  @click="addQuestionaryJob" 
            v-promise-btn="{promise}"  >
            <i class="fa fa-plus" />
        </b-button>
      </b-col>
      <b-col md="12">
        <b-table id="examsTable" striped hover responsive :items="items" :fields="fields" :busy="isBusy" >
            <template v-slot:cell(action)="data">
              <b-button
                  v-b-tooltip.hover :title="$t('Delete')" variant="outline-danger" @click="delQuestionaryJob(data.value)" >
                <i class="fa fa-times"></i>
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
      </b-col>
    </b-row>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import Exam from '@/core/Services/Exams/Exam';
import JobExam from '@/core/Services/Exams/JobExam';
import { QuestionaryJobEntity } from '@/core/models/Entities';

@Component
export default class FormVagaExames extends Vue {
  public service: Exam;
  public serviceJobExam: JobExam;
  public listExams: any[] = [];
  public listJobExams: any[] = [];
  public currentExamId: any = "";
  public tempoTotal: number = 30;
  public notaCorte: number = 70;
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;
  public promise: Promise<any>| null = null;
  
  @Prop()
  public vagaId!: number

  @Prop({default: []})
  public value!: any[]

  constructor() {
    super();
    this.service = new Exam();
    this.serviceJobExam = new JobExam();
  }

  public mounted() {
    this.fields = [     
      { key: 'name', label: this.$t('Name'), class: 'text-left' },
      { key: 'notaCorte', label: this.$t('% Passing Score'), class: 'text-left' },
      { key: 'action', label: ' - ', class: 'text-center' },
    ];
    this.items = [];
    this.service.loadOptions();
    this.isBusy = true;
    if(this.value && this.value.length > 0) {
      this.value.forEach((jq: QuestionaryJobEntity) => {
        this.notaCorte = jq.notaCorte;
        this.tempoTotal = jq.tempoMaximo
        this.items!.push({
            name: jq.name,
            notaCorte: jq.notaCorte,
            action: jq.id
        })
      })
    }
    this.isBusy = false;
  }

  public updateMaxTime() {
    this.value.forEach((jq: QuestionaryJobEntity) => {
      jq.tempoMaximo = this.tempoTotal;
    })
  }

  public addQuestionaryJob() {
    if (!this.currentExamId ) {
      this.$alert.addMsg(this.$t('Questionnaire not Informed'));
      this.$alert.callBoxWarn();
      return;
    }

    try {
      const c: QuestionaryJobEntity = new QuestionaryJobEntity();
      c.questionaryId = this.currentExamId;
      c.notaCorte =  this.notaCorte;
      c.tempoMaximo = this.tempoTotal;
      
      
      this.value.push(c);
      this.$emit('input',this.value);
      
      this.items!.push({
        name: this.service.getQName(this.currentExamId+""),
        notaCorte: this.notaCorte,
        action: "new_"+(new Date()).getTime()
      })
    } catch (e: any) {
      console.log(e.message);
    }
    
  }

  public delQuestionaryJob(id: number|string|null) {
    if (!id)
      return;

    if(typeof id == "string" && id.indexOf("new_") >= 0) {
      const index = this.items!.findIndex(_ => {return _.action == id})
      if (index>-1)
        this.items!.splice(index,1);

      return;
    }

    this.promise = this.serviceJobExam.delete({id})
    .catch(() => {})
    .finally(() => {
      const index = this.items!.findIndex(_ => {return _.action == id})
      if (index>-1)
        this.items!.splice(index,1);
    })
  }

}
</script>

<style lang="scss" scoped>
.vaga-exames-container {
    padding: 1em;
    border-radius: 8px;
    border: 2px solid #ececec;
}
</style>