<template>
  <div class="ratings">
    <div class="row">
      <b-col md="12" v-if="vaga && enableMultiEval">
        <form-avaliacao :vaga="vaga" :candidato="candidatura.candidato" :coUID="empresaGUID" :token="token" @evaluated="evaluated" />
      </b-col>
      <!-- modelo antigo, habilita se não esta habilitado o modelo de multiavaliacao -->
      <div class="col-md-12 rating" v-if="vaga && !enableMultiEval">
        <label>{{$t("Recruiter Evaluation")}}</label>
        <star-rating2
          class="mb-3"
          v-model="candidatura.ratingRecrutador"
          :read-only="isPublic"
          :rating="3"
          :item-size="20"
          :increment="1"
        ></star-rating2>
        <text-area
          class="text-uppercase"
          variant="dark"
          v-model="candidatura.comentarioRecrutador"
          :required="false"
          :placeholder="$t('Write a comment')"
          :rows="4"
          :disabled="isPublic"
          :label="$t('Comment')"
        />
      </div>
      <div class="col-md-12 rating" v-if="vaga && !enableMultiEval">
        <label>{{$t("Manager Evaluation")}}</label>
        <star-rating2
          class="mb-3"
          v-model="candidatura.ratingGestor"
          :read-only="!isPublic"
          :rating="3"
          :item-size="20"
          :increment="1"
        ></star-rating2>
        <text-area
          class="text-uppercase"
          variant="dark"
          v-model="candidatura.comentarioGestor"
          :required="false"
          :placeholder="$t('Write a comment')"
          :rows="4"
          :disabled="!isPublic"
          :label="$t('Comment')"
        />
      </div>
      <div class="col-md-12 d-flex justify-content-center mb-5 save" v-if="vaga && !enableMultiEval">
        <button 
          type="button" 
          class="btn btn-info text-uppercase" 
          @click="saveCandidatura" 
          v-promise-btn="{promise:currentPromise}"
        >
          <i class="fas fa-check mr-2"></i>
          {{$t("Save")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AvaliacoesProfissional from '@/components/avaliacao/AvaliacoesProfissional.vue';
import FormAvaliacao from '@/components/avaliacao/FormAvaliacao.vue';
import GestaoCandidaturaService from "@/core/Services/Empresa/GestaoCandidatura";
import PublicCandidatura from "@/core/Services/Empresa/PublicCandidatura";
import { CandidaturaEntity, EmpresaEntity, VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";

@Component({
  components: {
    FormAvaliacao,
    'avaliacaoes': AvaliacoesProfissional
  },
})
export default class CandidatoRating extends Vue {
  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;
  
  @Prop({ default: '' })
  public token!: string;

  @Prop({ default: null})
  public accessCode!: string;

  public gCandidaturaService!: GestaoCandidaturaService;
  public publicCandidatura!: PublicCandidatura;
  public currentPromise: any|null = null;

  constructor() {
    super()
    this.gCandidaturaService = new GestaoCandidaturaService()
    this.publicCandidatura = new PublicCandidatura()
	}

  
  get enableMultiEval () {
    if(!this.isPublic) {
      return (this.empresa?.configuracoes['ENABLE_MULTI_EVALUATION'] == true);
    } else {
      return (this.vaga?.empresa?.configuracoes['ENABLE_MULTI_EVALUATION'] == true)
    }
  }

  get empresaGUID() {
    return this.vaga?.empresa?.guid||null;
  }

  public saveCandidatura() {
    if (!this.isPublic) {
      this.currentPromise = this.gCandidaturaService.update(this.candidatura).then((data: any) => {
        this.successAlert()
      }).catch((err: any) => {
        console.log(err)
      })
    } else {
      const c: any = {
        id: this.candidatura.id,
        comentarioGestor: this.candidatura.comentarioGestor, 
        ratingGestor: this.candidatura.ratingGestor, 
        token: this.token
      }
      console.log(c);
      this.currentPromise = this.publicCandidatura.create(c).then((data: any) => {
        this.successAlert()
      }).catch((err: any) => {
        console.log(err)
      })
    }
  }

  public successAlert() {
    this.$alert.addMsg( this.$t('Saved successfully').toString());
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public evaluated(c: boolean) {
    this.$emit('evaluated',c)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.ratings {
  .rating {
    margin-bottom: 2em;
    label {
      font-size: 1rem;
    }
  }
  .save button {
    font-size: 1rem;
  }
}

</style>