
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { FaturaEntity } from '@/core/models/Entities';

export default class Fatura extends Repository {
  public entidade: FaturaEntity;
  constructor() {
    super(new ApiRouter({
        create: { url: '/v1/fatura-pagamento', method: 'POST', headers: {} },
        fetch: { url: '/v1/fatura-pagamento/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/fatura-pagamento', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/fatura-pagamento/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new FaturaEntity();
  }

}
