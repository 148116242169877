import { render, staticRenderFns } from "./EmpresaAssessment.vue?vue&type=template&id=bf4edb7c&scoped=true"
import script from "./EmpresaAssessment.vue?vue&type=script&lang=ts"
export * from "./EmpresaAssessment.vue?vue&type=script&lang=ts"
import style0 from "./EmpresaAssessment.vue?vue&type=style&index=0&id=bf4edb7c&prod&lang=scss&scoped=true"
import style1 from "vue-step-indicator/dist/vue-step-indicator.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf4edb7c",
  null
  
)

export default component.exports