<template>
  <transition appear name="slide">
  <b-row class="mb-1 mt-3">
    <b-col>
      <h4 class="text-info"> {{$t("Skills")}}</h4>
    </b-col>
    <b-col md="12" class="d-flex justify-content-between" >
      <auto-complete
        class="d-none d-md-block text-uppercase flex-fill"
        :label="$t('Skill')"
        :get-result-value="s => s.name"
        :search="searchHabilities"
        :placeholder="'Ex:. Microsoft Excel, AutoCad, Windows, Linux'"
        @submit="habilidadeSelecionada = $event"
        variant="dark"
      />
      <div>
        <b-form-rating
        v-b-tooltip.hover
        no-border
        :title="$t('Desired minimum level')"
        icon-empty="circle"
        icon-half="circle-half"
        icon-full="circle-fill"
        variant="danger"
        v-model="nivel"
        class="flex-fill"
      ></b-form-rating>

      </div>
      <b-button 
        v-b-tooltip.hover :title="$t('Add')"
        variant="info"  size="sm" 
        @click.prevent="addHabilidade()"
        v-promise-btn="{promise:habilitiesPromise}"
      >
       <i class="fa fa-plus-circle"></i>
      </b-button>
    </b-col>
    <b-col md="12" > 
      
      <hr />
      <b-badge v-if="getListHabilidades.length == 0" variant="warning">
        {{$t('No informed skills')}} 
      </b-badge>

      <b-button variant="light" class="m-1 float-left" size="sm" v-for="(hab, i) in getListHabilidades" v-bind:key="i">
        {{getNome(hab)}}
        <b-badge variant="light" class="ml-1">
          {{hab.nivel}} <span class="sr-only">{{$t('Level')}}</span>
        </b-badge>
          <b-button variant="outline-danger" pill size="sm"  @click="delHabilidade(hab,i)">
            <i class="fa fa-times" />
          </b-button>
      </b-button>
    </b-col>
  </b-row>
  </transition>
</template>


<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  HabilidadeEntity,
  VagasHabilidadesEntity,
} from "@/core/models/Entities";
import Habilidade from "@/core/Services/Habilidade";
import VagaHabilidades from "@/core/Services/Vaga/VagasHabilidades";
import Autocomplete from '@trevoreyre/autocomplete-vue'

@Component<FormVagaHabilidades>({
  components: {
    'auto-complete': Autocomplete
  }
})
export default class FormVagaHabilidades extends Vue {
  public serviceHabilidade: Habilidade;
  public serviceVagaHab: VagaHabilidades;

  public habilidades: HabilidadeEntity[] = [];
  public habilidadeSelecionada: HabilidadeEntity | null = null;

  public buscaHabilidades: string = "";
  public habilitiesPromise: any | null = null;
  public hashHabilidades: Map<number, number> | null = null;
  public nivel: number = 0;

  @Prop({default: []})
  public value!: VagasHabilidadesEntity[];
  
  @Prop({default: 0})
  public vagaId!: number

  public searchHabilities(v1: string) {
    if (v1.length < 1) { return [] }
     return new Promise((resolve: (v: any[]) => void) => {
       const _this : any  = this;
       clearTimeout(_this.timeoutPID);

       if(!v1)
        return;
        
        _this.timeoutPID = setTimeout(async (currentValue: string) => {
          this.habilitiesPromise = this.serviceHabilidade.fetchAll({s: currentValue}).then((data) => {
            this.updateHabilidadesList(data.habilidades)
            const arrFilter: any[] = this.habilidades.filter((hb: HabilidadeEntity) => {  
                if(hb && hb.name)
                  return (hb.name.toLowerCase().indexOf(currentValue.toLowerCase()) > -1) 

                return false;
              })
            resolve(arrFilter)
          })
        }, 500, v1);
      })
  }

  constructor() {
    super();
    this.serviceHabilidade = new Habilidade();
    this.serviceVagaHab = new VagaHabilidades();
  }

  get getListHabilidades() {
    return this.value;
  }
  
  get disableAdd() {
    return !this.habilidadeSelecionada;
  }

  public getNome(h: VagasHabilidadesEntity) {
    return h.name||h.habilidade?.name;
  }

  public updateHabilidadesList(habs: any[]) {
    if (this.hashHabilidades == null) {
      this.hashHabilidades = new Map<number, number>();
    }
    habs.forEach((value: any, index: number) => {
      if (!this.hashHabilidades!.has(value.id!)) {
        this.habilidades.push(value);
        this.hashHabilidades!.set(value.id!, index);
      }
    });
  }

  public mounted() {
  }

  public addHabilidade() {
    if (!this.habilidadeSelecionada) {
      this.$alert.addMsg(this.$t("Select an skill to proceed"));
      this.$alert.callBoxWarn();
      return;
    }

    if (this.value != null) {
      if (
        this.value.find(
          habilidade =>
            habilidade.habilidadeId === this.habilidadeSelecionada!.id
        )
      ) {
        this.$toast.variant = 'warning';
        this.$toast.addMsg(this.$t("This skill has already been added."));
        this.$toast.open();
        return;
      }
    }

    if (this.habilidadeSelecionada) {
      let h: VagasHabilidadesEntity = {
        name: this.habilidadeSelecionada.name,
        vagaId: this.vagaId,
        nivel: this.nivel,
        id: 0,
        habilidade: this.habilidadeSelecionada,
        habilidadeId: this.habilidadeSelecionada!.id,
        applyOnProfile: false
      };
      this.value.push(h);
      this.$emit('input',this.value);
    }

    this.habilidadeSelecionada = null;
    this.nivel = 0;
  }

  public delHabilidade(hab: any, index: number) {
    this.$confirm.addMsg(this.$t("Are you sure you want to delete skill?"));
    this.$confirm.callBoxSuccess().then((flag: boolean) => {
      if (flag) {
        this.$emit('removed',hab);
        this.value.splice(index, 1);
        this.$emit('input',this.value);
      } else {
        console.log("canceled");
      }
    });
  }

}
</script>
