<template>
  <transition>
    <div id="chatContainer">
      <div id="chatComponent">
          <div id="chatToolbar" class="d-flex justify-content-between">
              <div class="pl-3"> CHAT</div>
              <b-button
              size="sm"
              variant="light"
              @click="closeChat"
              >
                <i class="fas fa-times"></i>
              </b-button>
          </div>
          <div class="message-wrap" ref="chatScroll">

            <div
              v-for="(m, i) in messages"
              :key="i"
              :class="'message' + (m.connectionId !== user.getConnectionId() ? ' left' : ' right')"
            >
              <div class="msg-detail">
                  <div class="msg-info">
                    <p> {{m.nickname}}</p>
                  </div>
                  <div class="msg-content">
                    <span class="triangle" />
                    <p class="text">{{m.message}}</p>
                  </div>
              </div>
            </div>
          </div>

          <div id="messageInput">
            
            <textarea
              placeholder="..."
              id="chatInput"
              value=""
              class="form-control m-0 pr-5"
              v-model="chatInputValue"
              @keyup="handlePressKey"
            ></textarea>
            <button-icon 
              id="sendButton"
              :color="'white'"
              :border="'#81e9b0'"
              :back="'#1add72'"
              :backHover="'transparent'"
              @click.prevent="sendMessage"
            >
            <i class="fas fa-paper-plane"></i>
            </button-icon>
            
          </div>
      </div>
  </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import ButtonIcon from "@/components/ButtonIconRounded.vue"
import UserModel from '@/core/recording/UserModel';

@Component<Chat>({
  components: {
    ButtonIcon
  }
})
export default class Chat extends Vue {
  public chatInputValue: string|null = ''
  public options: any;
  public wasUpdated: boolean = false;
  
  @Prop({default: () => {new UserModel() }})
  private user!: UserModel;

  @Prop({default: []})
  private messageList!: any[];


  get messages() {
    return this.messageList;
  }
  
  public messageReceived() {
    this.$emit('messageReceived')
  }

  public scrollToBottom() {
    setTimeout(() => {
      try {
        const x: HTMLDivElement = (this.$refs.chatScroll as HTMLDivElement);
        x.scrollTop = x.scrollHeight;
      } catch (err) {}
    }, 20);
  }

  public IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get primeiroNome() {
    return "nameof-1";
  }

  public closeChat() {
    this.$emit('close')
  }

  public sendMessage() {
    if (this.user && this.chatInputValue?.trim()) {
      let message = this.chatInputValue.replace(/ +(?= )/g, '');
      if (message !== '' && message !== ' ') {
        const data = { 
          message: message, 
          nickname: this.user.nickname, 
          streamId: this.user.getStreamManager().stream.streamId 
        };
        this.user.getStreamManager().stream.session.signal({
            data: JSON.stringify(data),
            type: 'chat',
        });
        this.scrollToBottom();
      }
    } else {
      console.log('user or message not defined')
    }
    // this.setState({ message: '' });
    this.chatInputValue = null
  }

  public handlePressKey(event) {
    if (event.key === 'Enter') {
        this.sendMessage();
    }
  }
}
</script>

<style lang="css" src="@/assets/css/live-interview/chat.css"></style>