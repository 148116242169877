<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="lg"
    title="Large Modal"
    ref="modalMoreOptions"
    lazy
  >
    <form-filtro @advancedFilter="advancedFilter" />
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FormFiltro from './partials/FormFiltro.vue';

@Component({
  components: {
    FormFiltro
  }
})
export default class ModalFiltro extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  public advancedFilter(filter: any, selected: any) {
    this.$emit('advancedFilter',filter, selected)
  }

}
</script>
