<template>
    <div class="box-jobecoins">
        <!-- Adicionar Jobcoins -->
        <div class="row">
            <div class="col-md-8 mx-auto">
                <form-add-jobcoins 
                    :vaga="vaga"
                    :LabelButtonSubmit="'Save and Proceed'"
                    :showButtonPlano="false"
                    @hideModalAddJobcoins="hideModalAddJobcoins"
                    @prevStep="backData"
                >
                </form-add-jobcoins>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <button
                        class="btn btn-outline-secondary text-uppercase rounded-pill"
                        @click.prevent="backData"
                >{{$t('Back')}}</button>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import FormAddJobcoins from '@/components/form/FormJobcoins.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VagaEntity } from '../../../core/models/Entities';

@Component<FormVagaJobcoins>({
    components:{
        'form-add-jobcoins': FormAddJobcoins
    }
})
export default class FormVagaJobcoins extends Vue {
    @Prop({ default: () => {return new VagaEntity()}})
    public vaga!: VagaEntity;

    public hideModalAddJobcoins() {
        this.$router.push({path: '/my-jobs-openings/view/'+this.vaga.id, query: {lang: this.$i18n.locale}});
    }
    
    public backData() {
        this.$emit('PrevStep', true)
    }
}

</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_media-queries";
    @import "@/assets/scss/main";
    @import "@/assets/scss/_variables";

.principal-container {

    .box-jobecoins {
        padding-top: 1em;
        h3 {
            font-size: 1.5em;
            margin: 0;
            margin-top: 2em;
        }
        .table-info-data {
            .info {
                width: 100%;
                margin: 1em 0;
                .line {
                    display: flex;
                    line-height: 2;
                    font-size: 1em;
                    .label {
                        width: 25%;
                        text-align: right;
                        margin-right: 0.5em;
                    }
                    .value {
                        width: 75%;
                        text-align: left;
                        margin-left: 0.5em;
                    }
                }
            }
        }
        .jobcoins-investidos {
            border-radius: 8px;
            border: 2px solid $blue;
            background-color: $blue;
            padding: 1em;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3 {
                margin: 1em 0;
            }
            .points {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid $white;
                border-radius: 2rem !important;
                margin: 0;
                height: 1.5em;
                min-width: 3em;
                font-size: 2em;
            }
            p {
                text-align: center;
                margin: 1em 6em;
            }
        }
    }
 }

// Media Queries
@include media("<desktop") {
    .title {
        font-size: 2em !important;
    }
}
@include media(">desktop") {
    .title {
        font-size: 2em !important;
    }
    .box-data,
    .box-questions {
        font-size: 0.8em;
    }
}
@include media(">large") {
    .box-data {
        font-size: 1.4em;
    }
}
</style>

