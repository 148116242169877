<template>
  <b-form-group :label="label+(required?' *':'')" :class="variant" class="form-text-area">
    <b-form-textarea
      @input="handleInput($event)"
      @blur="handleBlur($event)"
      :required="required"
      :class="variant"
      :placeholder="placeholder"
      :value="value ? value : ''"
      :rows="rows"
      :maxlength="maxlength"
    ></b-form-textarea>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextArea extends Vue {

  @Prop({default: ""}) public label!: String;
  @Prop({default: ""}) public value!: String;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: ""}) public required!: boolean;
  @Prop({default: ""}) public placeholder!: String;
  @Prop({default: "2"}) public rows!: String;
  @Prop({default: 3000}) public maxlength!: number;

    public handleInput(v: any) {
        this.$emit('input', v);
    }

    public handleBlur(v: any) {
        this.$emit('blur', v);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/_variables";

textarea {
  border: none;
  border-bottom: 2px solid $light-gray !important;
  border-radius: 0 !important;
  background: transparent;
  color: white;
  padding: 0;
}
textarea:focus {
  color: white;
  background-color: transparent;
  border-bottom: 2px solid white !important;
}
textarea:valid {
  border-bottom: 2px solid white !important;
}

textarea.dark {
  border-bottom-color: $light-gray !important;
  color: $blue;
}
textarea.dark:focus {
  color: $blue;
  border-bottom-color: $blue !important;
}
.form-group .col-form-label {
  color: white !important;
}

.form-control::placeholder {
  color: rgba(140, 140, 140, 0.65) !important;
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables";

.form-text-area .col-form-label {
  color: $light-gray;
  font-size: 12px;
}
.form-text-area:valid .col-form-label {
  font-weight: bold;
  color: white !important;
}
.form-text-area.dark .col-form-label {
  color: #000;
}
.form-text-area:valid.dark .col-form-label {
  color: #000 !important;
}
</style>

