<template>
  <transition appear name="slide">
    <div class="v-card v-sheet theme--light">
      <div class="v-card__title text-base font-weight-semibold">
        <div>
            <p class="mb-2"> {{$t(title)}} [{{total}}] </p>
        </div>
      </div>
      <div class="v-card__text" style="position: relative;">
        <div style="min-height: 300px;">
          <div :id="chartId" v-if="value && value.series">
            <apexchart type="bar" height="300" :options="value" :series="value.series"></apexchart>
          </div>
        </div>

        <div class="resize-triggers">
            <div class="expand-trigger">
              <div style="width: 326px; height: 351px;"></div>
            </div>
            <div class="contract-trigger"></div>
        </div>
      </div>
    </div>
  </transition>
</template>



<script lang="ts">
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CardBarChart extends Vue {

  @Prop({default: {}})
  public value!: any;

  @Prop({default: ''})
  public chartId!: string;

  @Prop({default: ''})
  public title!: string;

  @Prop({default: ''})
  public total!: string;

}
</script>