<template>
  <b-container fluid >
    <b-row class="mt-3 position-relative m-h-400" ref="containerLoader_1">
      <b-col >  
        <b-form @submit.prevent="save">
          <text-input
            variant="gray-bordered"
            class="mt-4"
            :required="true"
            :label="$t('Team name')"
            :placeholder="$t('Eg purchases, financial, etc ...')"
            v-model="service.entidade.title"
            maxlength="50"
          />
          <b-form-group 
            :label="$t('Recruiters')" 
            label-for="recrutadoresSelect"
            class="vaga-select-list">
            <multi-select 
              id="recrutadoresSelect"
              class="searh-job-input"
              v-model="selectedRecruiters" 
              :options="recruitersOptions" 
              :multiple="true"
              label="nome" 
              track-by="nome"
              :custom-labels="customLabel"
              :show-labels="false"
              :placeholder="$t('Select')">
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ $t(props.option.nome) }}</div>
                </template>
            </multi-select>
          </b-form-group>

          <b-card :title="$t('Current team')">
            <div class="justify-content-start d-flex" v-if="service.entidade.recrutadores.length > 0">
              <h5 class="mr-1" v-for="(h,i) in service.entidade.recrutadores" :key="i">
                <b-badge variant="blue" class="mr-1"> 
                  {{h.name}} 
                  <b-button variant="danger" size="sm" class="ml-3" pill @click="deleteRecrutador(h.id)">
                    <i class="fa fa-times"></i>
                  </b-button>
                </b-badge>  
              </h5>
            </div>  
            <div v-else>
              <b-alert variant="warning" show class="text-center">
                {{$t('No recruiter registered.')}}
              </b-alert>
            </div>
          </b-card>
          
           

          <div class="justify-content-end d-flex mt-3">
            <b-button variant="primary" type="submit" pill v-promise-btn="{promise}"> 
              <i class="fa fa-save mr-2" />
              {{$t('Save')}}
            </b-button>
          </div>
        </b-form>
      </b-col>
      
    </b-row>
  </b-container>
</template>


<script lang="ts">
// List Company evaluations
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';
import Equipe from '@/core/Services/Equipes/Equipe';
import EquipeRecrutador from '@/core/Services/Equipes/EquipeRecrutador';
import { EmpresaEntity } from '@/core/models/Entities';
import Multiselect from 'vue-multiselect';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";

@Component<FormEquipe>({
  components:{
    'multi-select': Multiselect,
  }
})
export default class FormEquipe extends Vue {
  
  public service: Equipe;  
  public serviceER: EquipeRecrutador;  
  public serviceR: GestaoRecrutador;  
  public promise: Promise<any>|null = null;  
  public selectedRecruiters: any[] = [];
  public recruitersOptions: any[] = [];
  
  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  constructor() {
    super();
    this.service = new Equipe();
    this.serviceER = new EquipeRecrutador();
    this.serviceR = new GestaoRecrutador();
  }

  public customLabel({text,value}) {
    return text;
  }

  public async mounted() {
    const _: any = this;
      _.openLoading(this.$refs.containerLoader_1);
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
    }

    const dataR: any = await this.serviceR.fetchAll();
    this.recruitersOptions = (dataR?.recrutadores||[]);    

    const eid: number = parseInt(this.$route.params.eid);
        
    if (eid && !isNaN(eid)) {
      this.service.entidade =  await this.service.fetch(eid)
    }

    _.closeLoading();
  }

  public save() {
    this.service.entidade.rids = (this.selectedRecruiters.map((v: any) => { return v.id })||[])
    // console.log(this.service.entidade)
    this.promise = this.service.save().then((d: any) => {
      this.$emit('saved',true)
    })
  }

  public deleteRecrutador(id: string|number) {
    const _: any = this;
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.cancelTitle = 'No';
    this.$confirm.oktitle = 'Yes';
    this.$confirm.addMsg(this.$t('Are you sure you want to remove this team account?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        _.openLoading(this.$refs.containerLoader);
        this.serviceER.delete({id}).then(() => {
          this.$toast.variant = 'success';
          this.$toast.addMsg(this.$t('Deleted Successfully'))
          this.$toast.addTitle(this.$t('-').toString());
          this.$toast.open();
          const index: number = this.service.entidade.recrutadores?.findIndex(_ => { return _.id == id})
          this.service.entidade.recrutadores?.splice(index,1)
        }).catch(() => {
          this.$toast.variant = 'danger';
          this.$toast.addMsg(this.$t('Cannot delete because it is in use'))
          this.$toast.addTitle(this.$t('Oops').toString());
          this.$toast.open();
        }).finally(() => {
          _.closeLoading();
        })
      }
    });    
    
  }

}
</script>

<style lang="scss">

</style>