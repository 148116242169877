<template>
  <transition>
    <div class="card-recrutador d-flex flex-column align-items-center justify-content-center my-2">
      <img class="mb-2" :src="recrutador.fotoAvatar||'/static/img/avatar.png'" />
      <label>{{ recrutador.nome }}</label>
      <span>{{ recrutador.email }}</span>
      <button
        type="button"
        class="btn btn-info btn-sm text-uppercase rounded-pill my-3"
      >{{ recrutador.cargo||' - ' }}</button>
      <div class="d-flex">
        <button-icon-rounded
          v-b-tooltip.hover
          :title="$t('Edit')"
          color="rgb(95, 170, 201)"
          border="rgb(95, 170, 201)"
          backHover="rgb(95, 170, 201)"
          fontSize="1.2em"
          class="mr-2"
          @click="edit"
        >
          <span class="fas fa-pencil-alt"></span>
        </button-icon-rounded>
        <button-icon-rounded
          v-b-tooltip.hover
          :title="$t('Delete')"
          color="rgb(196, 80, 80)"
          border="rgb(196, 80, 80)"
          backHover="rgb(196, 80, 80)"
          fontSize="1.2em"
          class="ml-2"
          @click="remove"
        >
          <span class="icon-lixo"></span>
        </button-icon-rounded>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RecrutadorEntity } from '@/core/models/Entities';
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';

@Component
export default class CardRecrutador extends Vue {
  public service: GestaoRecrutador;

  constructor() {
    super();
    this.service = new GestaoRecrutador(); 
  }

  @Prop({ default: () => { return new RecrutadorEntity(); } })
  public recrutador!: RecrutadorEntity;

  public edit() {
    this.$router.push({ name: 'RecruitersEdit', params: { id: this.recrutador.id+"" }, query: {lang: this.$i18n.locale} });
  }

  public async remove() {
    this.$confirm.addMsg(this.$t("Are you sure you want to remove this recruiter?").toString())
    const check = await this.$confirm.callBox();
     if (check) {
       this.service.delete({id: this.recrutador.id}).then((data: any) => {
           this.$alert.addMsg(this.$t("Removed recruiter").toString())
           this.$alert.callBoxCheckWithTitle(this.$t("Ok").toString())
           this.$emit('removed',this.recrutador.id);
       })
     }
  }

}
</script>

<style lang="scss" scoped>
  .card-recrutador {
    min-height: 270px;
    border-radius: 8px;
    border: 2px solid #ececec;
    width: 20%;
    min-width: 250px;
    max-width: 290px;
    margin: 2px;
    img {
      width: 5em;
      height: 5em;
      border-radius: 50%;
    }
    label,
    span {
      line-height: 1em;
      margin: 0 0 10px 0; 
    }
    label {
      font-size: 1.3em;
    }
    span {
      font-size: 0.9em;
    }
  }
</style>