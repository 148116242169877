<template>
  <transition appear="" name="fade">
    <b-card class="card-candidato "  no-body>
      <b-card-body class="p-1">
        <b-row >
          <b-col md="12" class="d-flex justify-content-end">           
            <b-dropdown size="sm" variant="white" class="border-none bg-white" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item 
                v-if="hasLinkedIn"
                title="LinkedIn"
                @click="goLinkedInProfile"><i class="fab fa-linkedin"></i> LinkedIn </b-dropdown-item>
              <b-dropdown-item
                :title="$t('Curriculum')"
                variant="outline-info"
                @click="downloadCurriculo"><i class="fas fa-download"></i> {{$t('Curriculo')}}</b-dropdown-item>
              <b-dropdown-item 
                :title="$t('Send message')"
                @click="message"><i class="icon-mensagens"></i> {{$t('Send message')}}</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col md="12" class="text-center">
            <b-img thumbnail class="mb-2 shadow" src="/static/img/avatar.png" v-if="!candidato.fotoAvatar"></b-img>
            <b-img thumbnail class="mb-2 shadow" :src="candidato.fotoAvatar" v-else></b-img>
          </b-col>
          <b-col md="12">
            <h4 class="mb-2 text-center">
              <a :href="getProfileURL" target="_blank" :title="candidato.primeironome">  {{ candidato.primeironome }} </a> 
            </h4>
          </b-col>
          <b-col md="12" class="text-center mb-2">
             <b-button size="sm" pill v-b-tooltip.hover :title="$t('{percentage}% of Match',{ percentage })" :variant="percentageColor">
              {{percentage}}
              <small> <b-badge pill variant="light">%<span class="sr-only">{{percentage}}%</span></b-badge></small>
            </b-button>
          </b-col>
          <b-col md="12 d-flex flex-column justify-content-center text-center">
            <span class="font-weight-bold mb-2" v-if="candidato.perfil.profissao2">
              {{ candidato.perfil.profissao2 }}
            </span>
            <span class="mb-2 text-lowercase">{{ candidato.email }}</span>
            <span class="mb-2" v-if="candidato.perfil.telefone">
              {{ candidato.perfil.telefone }}
            </span>
            <span class="mb-2" v-if="candidato.perfil.areaatuacaoId">
              {{ candidato.perfil.areaAtuacao.descricao }}
            </span>
            <span class="mb-2" v-if="candidato.perfil.escolaridadeId">
              {{ candidato.perfil.escolaridade.descricao }}
            </span>
            <span class="mb-2" v-if="candidato.perfil.nomeCidade">
              {{ candidato.perfil.nomeCidade }} / {{ candidato.perfil.nomeEstado }}
            </span>
          </b-col>
          <b-col md="12" v-b-tooltip.hover v-if="showGP" :title="gruposPertencimento">
            <b-avatar-group size="25px"  >
                <b-avatar v-if="candidato.perfil.grupoLgbt" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_lgbt%2B.png'" variant="info"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupoIndiginas" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_comunidade_indigena.png'" variant="info"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupoRefugiados" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_refugiados_imigrantes.png'" variant="info"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupoMovBlack" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_movimento_black.png'"  variant="info"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupoPCD" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_pcd.png'" variant="info"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupoEmpoderaFeminino" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_empoderamento_feminino.png'" variant="success"></b-avatar>
                <b-avatar v-if="candidato.perfil.grupo3Idade" :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_3idade.png'" variant="success"></b-avatar>
              </b-avatar-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CandidatoEntity, EmpresaEntity } from '@/core/models/Entities';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { siteCandidateEndPoint } from "@/core/endpoint";
import { Getter } from 'vuex-class';

@Component
export default class CardCandidatos extends Vue {
  public service: CandidatoService;
  public currentSiteCandidate: string = siteCandidateEndPoint;
  public avatar: string|null = null;
  public curriculum: string|null = null;

  constructor() {
    super();
    this.service = new CandidatoService(); 
  }

  @Prop({ default: () => { return new CandidatoEntity(); } })
  public candidato!: CandidatoEntity;

  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  get showGP() {
    return (!this.empresa?.configuracoes?.HIDE_DIVERSITY_MODAL);
  }

  get gruposPertencimento() {
    let arr: string[] = []
    if (this.candidato.perfil.grupoLgbt)
      arr.push('LGBTQIA+');
    if (this.candidato.perfil.grupoIndiginas )
      arr.push(this.$t('Indigenous Community').toString());
    if (this.candidato.perfil.grupoRefugiados )
      arr.push(this.$t('Refugee and Immigrants').toString());
    if (this.candidato.perfil.grupoMovBlack )
      arr.push(this.$t('Afro-descendants Community').toString());
    if (this.candidato.perfil.grupoPCD )
      arr.push(this.$t('PWD Group').toString());
    if (this.candidato.perfil.grupoEmpoderaFeminino )
      arr.push(this.$t('Womens Movement').toString());
    if (this.candidato.perfil.grupo3Idade )
      arr.push(this.$t('Senior citizen group').toString());

    return arr.join(', ');
  }
  get percentage() {
    return (this.candidato?.matchCompany?.percentage||0);
  }
  get percentageColor() {
    const p: number = this.percentage
    
    if (p > 80) {
      return "success"
    }
    if (p < 50) {
      return "warning"
    }
    if (p > 50 && p < 80) {
      return "info"
    }
    return
  }

  public mounted() {
    
  }
  
  get getProfileURL() {
    return this.currentSiteCandidate + '/c/' + this.candidato.nomeUrl;
  }
  
  public async downloadCurriculo() {
    await this.service.fetchFiles({guid: this.candidato.guid}).then((d: any) => {
      this.curriculum = d?.urlCurriculum;
    })
    if (this.curriculum ) {
      window.open(this.curriculum, '_blank')      
    } else {
      this.$alert.addMsg(this.$t('At the moment the candidate did not make any curriculum available'))
      this.$alert.callBoxWarn()
    }
  }
  
  public goProfile() {
    window.open(this.currentSiteCandidate + '/c/'+this.candidato.nomeUrl, '_blank')
  }

  get hasLinkedIn() {
    return !!this.candidato.perfil.linkLinkedin;
  }
  
  public goLinkedInProfile() {
    window.open(this.candidato.perfil.linkLinkedin, '_blank')
  }

  public message() {
    this.$emit('showMessage',this.candidato)
  }

}
</script>

<style lang="scss" scoped>
  .card-candidato {
    min-height: 270px;
    border-radius: 8px;
    border: 2px solid #ececec;
    width: 20%;
    min-width: 250px;
    max-width: 290px;
    margin: 2px;
    img {
      width: 5em;
      height: 5em;
      border-radius: 50%;
      object-fit: cover;
    }
    label,
    span {
      line-height: 1em;
      margin: 0;
    }
    label {
      font-size: 1.3em;
    }
    span {
      font-size: 0.9em;
    }

    .icon-user, .icon-mensagens {
      vertical-align: middle;
    }

    .percentage-match {
      font-size: 0.8em;
    }
  }
</style>