// tslint: disable: no-string-literal
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { TagTranscricaoEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';


export default class TagTranscricao extends Repository {

    public entidade: TagTranscricaoEntity;

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/tag-transcricao', method: 'POST', headers: {} },
                delete: { url: '/v1/tag-transcricao/{id}', method: 'DELETE', headers: {} },
                fetch: { url: '/v1/tag-transcricao/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/tag-transcricao', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/tag-transcricao/{id}', method: 'PUT', headers: {} }}));
        this.entidade = new TagTranscricaoEntity('');
    }

    public save(): Promise<any> {
       if (this.entidade.id && this.entidade.id > 0) {
            return super.update(this.entidade);
       }
       return super.create(this.entidade);
    }

}


