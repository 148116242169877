<template>
  <div class="container-children mobile">
    
    <!-- Info do Candidato -->
    <div :class="{ 'candidato-info': true, 'isActive' : (mobileIsShowQuestions) }">
      <div class="d-flex d-md-none mt-3">
        <button-icon-rounded
          color="rgb(70, 150, 204)"
          border="rgb(70, 150, 204)"
          backHover="rgb(70, 150, 204)"
          fontSize="1.2em"
          class="mr-2"
          @click="showListCandidates()"
        >
          <span class="fas fa-arrow-left"></span>
        </button-icon-rounded>
      </div>
      <vaga-header :vaga="vaga"/>
      <div class="candidaturas-empty text-center mb-5" v-if="!candidatura">
        <img
          src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/video-curriculo-empty.png"
          class="candidaturas-empty__img"
          alt="candidaturas-empty.png"
        >
        <p class="candidaturas-empty__text">{{$t("Select a candidate beside to view video interviews.")}}</p>
      </div>
      <div v-else >
        <candidato-header 
          ref="cheader"
          :candidatura="candidatura" 
          :vaga="vaga" 
          @statusAprovado="statusAprovado"
          @statusReprovado="statusReprovado"
          @statusAnalise="statusAnalise"
          @repescagemStatus="repescagemRevisaoStatus"
          @revisaoStatus="repescagemRevisaoStatus"
          @granted="ncGranted" 
          @not-granted="ncNotGranted" 
        />
        <video-interview :candidatura="candidatura" :vaga="vaga"/>
        <candidato-rating :candidatura="candidatura" :vaga="vaga" @evaluated="setCurrent(candidatura,true)"/>
      </div>    
    </div>
    <candidaturas-bar ref="cbar"
      @selected="selected"
      @filter="doFilter"
      @paginate="paginate"
      @sendBulkList="candidatosChecked"
      @advancedFilter="toFilter"
      @allCandidaturas="allCandidaturas"
      @infinite="infiniteHandler"
      @setCurrent="setCurrent"
      :vaga="vaga"
      @granted="ncGranted" 
      @not-granted="ncNotGranted" />

    <modal-message 
      id="modalBulkMessage" 
      :candidatura="candidatura"
      :vaga="vaga"
      :multipleSelection="idsCandidatos"
    />
    <modal-share-link 
      id="modalBulkShareLink" 
      :candidatura="candidatura"
      :multipleSelection="ids"
    />
    <modal-new-chance 
      id="modalBulkNewChance" 
      :candidatura="candidatura"
      :multipleSelection="ids"
      @granted="ncGranted" 
      @not-granted="ncNotGranted" 
    />
  </div>
</template>

<script lang="ts">
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import ModalNewChance from "@/components/modal/ModalNewChance.vue";
import ModalSendMessage from "@/components/modal/ModalSendMessage.vue";
import ModalShareLink from "@/components/modal/ModalShareLink.vue";
import { ECandidaturaStatus } from '@/core/Enums/ECandidaturaStatus';
import GestaoCandidaturaService from '@/core/Services/Empresa/GestaoCandidatura';
import RepescagemService from "@/core/Services/Vaga/Repescagem";
import {
CandidatoEntity,
CandidaturaEntity,
CandidaturasViewModel,
JobApplicationFilter,
VagaEntity
} from '@/core/models/Entities';
import CandidatoHeader from "@/views/candidatos/CandidatoHeader.vue";
import CandidatoRating from "@/views/candidatos/CandidatoRating.vue";
import VagaCandidaturas from "@/views/vagas/details/VagaCandidaturas.vue";
import VagaDetailHeader from "@/views/vagas/details/VagaDetailHeader.vue";
import VagaVideoInterview from "@/views/vagas/details/VagaVideoInterview.vue";
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'button-icon': ButtonIcon,
    'vaga-header': VagaDetailHeader,
    'candidato-header': CandidatoHeader,
    'video-interview': VagaVideoInterview,
    'candidato-rating': CandidatoRating,
    'candidaturas-bar': VagaCandidaturas,
    'modal-message': ModalSendMessage,
    'modal-share-link': ModalShareLink,
    'modal-new-chance': ModalNewChance
  },
})
export default class Candidaturas extends Vue {
  public mobileIsShowQuestions = false;
  public service: GestaoCandidaturaService;
  public candidatoCheckbox: boolean = false;
  public ids: number[] = [];
  public idsCandidatos: number[] = [];
  // Candidatura Selecionada
  public candidatura: CandidaturaEntity|null = null;
  public candidato: CandidatoEntity|null = null;
  public vaga: VagaEntity[] = [];
  public vagaId: number = 0;
  public statusCandidato!: string;
  public limit: number = 10;
  // default statuses search
  public analise: string[] = ['EMANALISE', 'EXAMESEMANALISE'];
  public aprovados: string[] = ['APROVADO'];
  public reprovados: string[] = ['REPROVADO', 'EXAMESREPROVADO'];
  public repescagem: string[] = ['EMREPESCAGEM'];
  public revisao: string[] = ['REVISAOCURRICULAR'];
  public pendentes: string[] = ['VIDEOSPENDENTES', 'VIDEOSEMPROCESSAMENTO', 'EXAMESPENDENTES'];

  public candidaturaId: number|null = null;

  // Lista de Candidaturas retornada pela busca (OBS:de acordo com os status informados)
  public candidaturasViewModel: Map<string,CandidaturasViewModel> = new Map<string, CandidaturasViewModel>();

  constructor() {
    super();
    this.service = new GestaoCandidaturaService();
  }

  public doFilter(key: string, value: JobApplicationFilter)
  {
    this.candidaturasViewModel.get(key)!.filter = value;
    this.loadFilterOf(key);
  }

  public paginate(key: string, $state?: any)
  {
    console.log('paginate'+ key)
    this.candidaturasViewModel.get(key)!.filter.page++;
    this.loadFilterOf(key, $state);
  }

  public infiniteHandler($state: any, key: string) {
    this.paginate(key, $state)
  }
  
  public refreshCandidaturasBar(key: string) {
    if (this.candidaturasViewModel.get(key) && (this.$refs.cbar as VagaCandidaturas))
      (this.$refs.cbar as VagaCandidaturas).setList(key, this.candidaturasViewModel.get(key)!);
  }

  public async loadFilterOf(key: string, $state?: any) {
    await this.filterResults(key, $state);
    this.refreshCandidaturasBar(key);
  }
  
  public buildVM() {
    this.candidaturasViewModel = new Map<string,CandidaturasViewModel>();
    this.candidaturasViewModel.set( 'analise',new CandidaturasViewModel('analise', this.analise));
    this.candidaturasViewModel.set('aprovados',new CandidaturasViewModel('aprovados', this.aprovados));
    this.candidaturasViewModel.set('reprovados',new CandidaturasViewModel('reprovados', this.reprovados));
    this.candidaturasViewModel.set('pendentes',new CandidaturasViewModel('pendentes', this.pendentes));
    this.candidaturasViewModel.set('repescagem',new CandidaturasViewModel('repescagem', this.repescagem));
    this.candidaturasViewModel.set('revisao',new CandidaturasViewModel('revisao', this.revisao));
    // this.candidaturasViewModel.set('repescagem',new CandidaturasViewModel('repescagem', this.repescagem));
  }

  showQuestions() {
    this.mobileIsShowQuestions = true;
  }
  showListCandidates() {
    this.mobileIsShowQuestions = false;
  }

  public selected(c: CandidaturaEntity, doFetch: boolean = true) {
    this.showQuestions();
    this.setCurrent(c,doFetch)
  }
  
  public setCurrent(c: CandidaturaEntity, doFetch: boolean = true) {
    const curr: Date = (c.lastLoadingTime||new Date());
    const now: Date = new Date();
    const diffMs = (now.getTime() - curr.getTime()); // milliseconds between now & Christmas
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes 
    // se existe se foi forçado ou o tempo eh maior que 17 min
    if (process.env.NODE_ENV != 'production') {
      console.log('dif in ms', diffMs);
      console.log('dif in mins', diffMins);
    }
    if (c.id && (doFetch || (diffMins > 17))) { 
      this.candidatura = null;
      this.candidato = null;
      this.service.fetch(c.id).then((c1: CandidaturaEntity) => {
        c1.lastLoadingTime = new Date();
        this.updateCandidaturaObject(c1);
        this.setCurrent(c1, false);
      })
    } else {
      this.candidatura = c;
      this.candidato = c.candidato;
      this.pushPathState()
    }
  }

  public getKeyFromStatus(status: string) {
    if(this.analise.indexOf(status) > -1) 
      return 'analise';
    if(this.aprovados.indexOf(status) > -1) 
      return 'aprovados';
    if(this.reprovados.indexOf(status) > -1) 
      return 'reprovados';
    if(this.repescagem.indexOf(status) > -1) 
      return 'repescagem';
    if(this.revisao.indexOf(status) > -1) 
      return 'revisao';
    if(this.pendentes.indexOf(status) > -1) 
      return 'pendentes';

    return 'pendentes';
  }

  public updateCandidaturaObject(c1: CandidaturaEntity) {
    if(c1.status) {
      const key = this.getKeyFromStatus(c1.status)
      const index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex(_ => { return _.id === c1.id});
      if (index>=0) {
        c1.checked = this.candidaturasViewModel.get(key)!.candidaturas[index].checked||false;
        this.candidaturasViewModel.get(key)!.candidaturas[index] = c1
        return
      }
    }
  }

  public pushPathState(){
    const lang: string = `lang=${this.$i18n.locale}`
    const cid: string = `cid=${this.candidatura?.id}`
    const fullPath: string = `${this.$route.path}?${cid}&${lang}`
    history.pushState({},'',  fullPath )
  }

  public showModalFiltro() {
    this.$bvModal.show('modalFiltro');
  }

  public  async filterResults(key: string, $state?: any) {
    this.candidaturasViewModel.get(key)!.filter.vaid = this.vagaId;
    await this.service.fetchAll(this.candidaturasViewModel.get(key)!.filter).then((data: any) => {
      if (data) {
        const today = new Date();
        data['candidaturas'].forEach((element: CandidaturaEntity) => {
          element.lastLoadingTime = today;
          this.candidaturasViewModel.get(key)!.candidaturas.push(element);
        });
        this.candidaturasViewModel.get(key)!.qtd = data[key]
        this.candidaturasViewModel.get(key)!.vaga = data['vaga'];
        this.vaga = data['vaga']; 
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  public created() {
    this.vagaId = parseInt(this.$route.params.id);
    this.candidaturaId = parseInt(""+this.$route.query.cid);
    if (!isNaN(this.candidaturaId) && this.candidaturaId)
      this.loadOneApplication()
  }

  public loadOneApplication() {
    this.service.fetch(this.candidaturaId || 0).then((c: CandidaturaEntity) => {
      this.setCurrent(c, false);
    })
  }

  public async mounted() {
    this.buildVM();
    await this.loadFilterOf('analise');
    this.loadFilterOf('aprovados');
    this.loadFilterOf('reprovados')
    this.loadFilterOf('pendentes');
    this.loadFilterOf('repescagem');
    this.loadFilterOf('revisao');
    if (this.candidaturasViewModel.get('analise')!.candidaturas[0] && !this.candidaturaId)
      this.setCurrent(this.candidaturasViewModel.get('analise')!.candidaturas[0]);
  }

  // STATUS

  public exchangeCandidaturaBetweenLists(index: number, from: string, to: string) {
    const c: CandidaturaEntity = this.candidaturasViewModel.get(from)!.candidaturas[index];
    this.candidaturasViewModel.get(from)!.candidaturas.splice(index, 1)
    this.candidaturasViewModel.get(to)!.candidaturas.push(c);
    this.refreshCandidaturasBar(from);
    this.refreshCandidaturasBar(to);
  }

  public updateStatusToReprovado(id: number) {
    const keys: string[] = ['aprovados', 'analise'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    keys.forEach((key: string) => {
      index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex(_ => { return _.id === id});
      if (index>=0) {
        c = this.candidaturasViewModel.get(key)!.candidaturas[index];
        c.status = ECandidaturaStatus.Reprovado;
        this.exchangeCandidaturaBetweenLists(index, key, 'reprovados');
        return
      }
    });
  }
  public updateStatusToAprovado(id: number) {
    const keys: string[] = ['reprovados', 'analise'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    keys.forEach((key: string) => {
      index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex(_ => { return _.id === id});
      if (index>=0) {
        c = this.candidaturasViewModel.get(key)!.candidaturas[index];
        c.status = ECandidaturaStatus.Aprovado;
        this.exchangeCandidaturaBetweenLists(index, key, 'aprovados');
        return
      }
    });
  }
  public updateStatusToAnalise(id: number) {
    const keys: string[] = ['reprovados', 'aprovados'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    keys.forEach((key: string) => {
      index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex(_ => { return _.id === id });
      if (index>=0) {
        c = this.candidaturasViewModel.get(key)!.candidaturas[index];
        c.status = ECandidaturaStatus.Analise;
        this.exchangeCandidaturaBetweenLists(index, key, 'analise');
        return
      }
    });
  }

  public statusReprovado(id: number) {
    this.service.update({id: id, status: ECandidaturaStatus.Reprovado})
      .then((data: any) => {
        this.updateStatusToReprovado(id)
        this.successToast()
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public repescagemRevisaoStatus(status: string, id: any) {
    const repescagemSrv = new RepescagemService();
    repescagemSrv.update({id, status}).then(() => {
      this.successToast()
    })
  }


  public magicRefreshCandidatoInfo(data: CandidaturaEntity) {
    this.candidato = data['candidato'];
    this.candidatura!.candidato = this.candidato;
    const index: number = this.candidaturasViewModel.get('aprovados')!.candidaturas.findIndex(_ => { return _.id === data.id});
    if (index >= 0) {
      this.candidaturasViewModel.get('aprovados')!.candidaturas[index] = data; 
      this.refreshCandidaturasBar('aprovados');
    }
    this.candidatura!.status = 'APROVADO';
  }

  public statusAprovado(id: number) {
    this.service.update({id: id, status: ECandidaturaStatus.Aprovado})
      .then((data: CandidaturaEntity) => {
        this.updateStatusToAprovado(id);
        this.successToast();
        this.magicRefreshCandidatoInfo(data);
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public statusAnalise(id: number) {
    this.service.update({id: id, status: ECandidaturaStatus.Analise})
      .then((data: any) => {
        this.updateStatusToAnalise(id)
        this.successToast()
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public percorreListaComCallBack(ids: number[], callback: Function) {
    ids.forEach(id => callback(id))
  }

  public getCandidatosIds() {
    const keys: string[] = ['reprovados', 'aprovados','analise', 'pendentes','revisao','repescagem'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    this.idsCandidatos = [];
    keys.forEach((key: string) => {
      this.ids.forEach((id: number) => {
        index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex(_ => { return _.id === id});
        if (index>=0) {
         this.idsCandidatos.push(+this.candidaturasViewModel.get(key)!.candidaturas[index].idcandidato);
        }
      })
    });
  }

  public candidatosChecked(ids: any, action: string) {
    this.ids = ids;
    console.log(this.ids)
    if (!ids && !action) {
      return
    }

    if (ids && action) {
      switch(action) {
        case 'Aprovar':{
          this.$confirm.addTitle(this.$t('Attention').toString())
          this.$confirm.addMsg(this.$t('Are you sure you want to change the status of this candidate (s) to approve?'))
          this.$confirm.callBoxDanger().then((v:boolean) => {  
            if (v) {
              this.percorreListaComCallBack(ids,this.statusAprovado);
            }
          });          
          break;
        }
        case 'Reprovar':{
          this.$confirm.addTitle(this.$t('Attention').toString())
          this.$confirm.addMsg(this.$t('Are you sure you want to change the status of this candidate (s) to disapprove?'))
          this.$confirm.callBoxDanger().then((v:boolean) => {  
            if (v) {
              this.percorreListaComCallBack(ids,this.statusReprovado);
            }
          });          
          break;
        }
        case 'Analisar':{
          this.$confirm.addTitle(this.$t('Attention').toString())
          this.$confirm.addMsg(this.$t('Are you sure you want to change the status of this candidate (s) for analysis?'))
          this.$confirm.callBoxDanger().then((v:boolean) => {  
            if (v) {
              this.percorreListaComCallBack(ids,this.statusAnalise);
            }
          });          
          break;
        }
        case 'Mensagem':
          this.getCandidatosIds();
          this.$bvModal.show('modalBulkMessage'); break;
        case 'Gestor':
          this.$bvModal.show('modalBulkShareLink'); break;
        case 'Chance':
          this.$bvModal.show('modalBulkNewChance'); break;
        default:
          break;
      }
    }
  }

  public toFilter(filter: JobApplicationFilter, selectedKeys?: string [] | null) {
    const keys: string[] = selectedKeys || ['reprovados', 'analise', 'aprovados', 'pendentes','repescagem','revisao'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    keys.forEach((key: string) => {
      this.candidaturasViewModel.get(key)!.candidaturas = [];
      const s: string[]|null = this.candidaturasViewModel.get(key)!.filter.status;
      this.candidaturasViewModel.get(key)!.filter = filter;
      this.candidaturasViewModel.get(key)!.filter.status = this[key];
      this.loadFilterOf(key);
      this.$bvModal.hide('modalFiltro')
    });
  }

  public removeCandidaturaFromListAny(cid: number) {
    const keys: string[] = ['reprovados', 'analise', 'aprovados', 'pendentes','repescagem','revisao'];
    let c: CandidaturaEntity|undefined;
    let index: number|undefined;
    keys.forEach((key: string) => {
      index = this.candidaturasViewModel.get(key)!.candidaturas.findIndex( _ => { return _.id == cid });
      if (index > -1) {
        this.candidaturasViewModel.get(key)!.candidaturas.splice(index, 1);
        this.refreshCandidaturasBar(key); 
      }
    });
  }
  
  public ncGranted(cid) {
    if (this.candidatura && this.candidatura.id === cid) {
      // it means that is currently selected
      this.removeCandidaturaFromListAny(this.candidatura.id||0);
      this.candidatura = null;
      this.candidato = null;
    }
  }

  public ncNotGranted(cid) {
    console.log('not-granted',cid)
  }

  public allCandidaturas() {
    this.buildVM();
    this.loadFilterOf('analise');
    this.loadFilterOf('aprovados');
    this.loadFilterOf('reprovados');
    this.loadFilterOf('pendentes');
    this.loadFilterOf('repescagem');
    this.loadFilterOf('revisao');
  }

  public successToast() {
    this.$toast.addTitle(""+this.$t('Success'))
    this.$toast.addMsg(this.$t('Status changed successfully!'))
    this.$toast.variant = 'success'
    this.$toast.open()
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

  .candidato-info,
  .candidato-list {
    background-color: $white;
    padding: 1.5em;
  }
  .candidato-info {
    width: 70%;
    margin-right: 0.2em;
    border-top-left-radius: 8px;
    padding: 0 1.5em;
    p {
      margin: 0;
    }
    // .info-header {
    //   padding: 1em 0;
    //   border-bottom: 1px solid #dcdcdc;
    //   h1 {
    //     font-size: 2.5em;
    //   }
    // }
    .info-questions {
      .foto-perfil {
        padding: 2em;
        img {
          width: 6em;
          border-radius: 50%;
        }
      }
      .buttons {
        h3 {
          font-size: 1.5em;
        }
      }
    }
  }

  .candidaturas-empty {
    // border: 1px solid #efefef;
    border-radius: 6px;
    &__text {
      font-size: 1.1rem;
      color: $blue;
    }
    &__img {
      width: 30rem;
      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }


@include media("<desktop") {
  .container-children {
    font-size: 0.8em;
    margin: 0;
    margin-top: 1em;
    padding: 0;
    border: 0;

    &.mobile {
      .candidato-info,
      .candidato-list {
        width: 100%;
        border: 0;
        border-radius: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
      }
      .candidato-info {
        position: absolute;
        right: 100vw;
        transition: right 0.5s ease;
        background-color: $white;
        z-index: 98;
        &.isActive {
          right: 0 !important;
          transition: right 0.5s ease;
        }
        .info-questions {
          .foto-perfil,
          .buttons {
            margin: 1em 0;
          }
          .foto-perfil {
            padding: 0;
            img {
              width: 4em;
            }
          }
        }
        .videos-questions {
          .buttons-question {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            button {
              flex: 0 0 auto;
              width: 12em;
            }
          }
          .video {
            .xd {
              height: 160px;
            }
          }
        }
        .ratings {
          margin-bottom: 4em;
        }
      }
      .candidato-list {
        padding: 1em;
        h3 {
          margin: 0;
        }
        .list {
          margin-top: 0.5em;
        }
      }
    }
  }
}
</style>

<style lang="scss">
// #modalFiltro .modal-body {
//   padding: 0;
// }
</style>
