<template>
   <div :class="{ 'mesgs': true, 'isShow' : isMobile }">
    <div v-if="root" class="mesgs-header">
      <div class="d-flex d-md-none m-3 go-back cursor-pointer" @click="backToList">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="chat_img">
        <img
            v-if="root.candidato && root.candidato.fotoAvatar"
            :src="root.candidato.fotoAvatar" class="rounded-circle"
            alt="Avatar"
          />
        <img src="/static/img/avatar.png" alt v-else/>
      </div>
      <div class="chat_ib">
        <h5>{{root.assunto}}</h5>
        <p v-if="root.candidato && root.candidato.primeironome " >{{root.candidato.primeironome + " " + root.candidato.sobrenome}}</p>
        <p v-else >{{ $t("Professional")}}</p>
      </div>
      <div class="exclude-messages text-center">
        <i class="icon-lixo"></i>
      </div>
    </div>

    <div id="messageContentContainer" class="msg_history" v-if="root">
      <div class="incoming_msg" v-if="root.remetente == 'C'">
        <div class="received_msg">
        <div class="triangulo"></div>
          <div class="received_withd_msg">
            <span class="time_date">{{showDate(root.createdAtObject)}}</span>
            <div v-html="root.mensagem"> </div>
          </div>
        </div>
      </div>
      <div class="outgoing_msg" v-else>
        <div class="sent_msg">
          <div class="triangulo"></div>
          <div class="sent_msg__text">
            <span class="time_date">{{showDate(root.createdAtObject)}}</span>
            <div v-html="root.mensagem"> </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div v-for="msg in currMessages" :key="msg.id">
        <div class="incoming_msg" v-if="msg.remetente == 'C'">
          <div class="received_msg">
          <div class="triangulo"></div>
            <div class="received_withd_msg">
              <span class="time_date">{{showDate(msg.createdAtObject)}}</span>
              <div v-html="msg.mensagem"> </div>
            </div>
          </div>
        </div>
        <div class="outgoing_msg" v-else>
          <div class="sent_msg">
            <div class="triangulo"></div>
            <div class="sent_msg__text">
              <span class="time_date">{{showDate(msg.createdAtObject)}}</span>
              <div v-html="msg.mensagem"> </div>
            </div>
          </div>
        </div>
      <div class="clearfix"></div>
      </div>
    </div>
    <div class="type_msg" v-if="root">
      <div class="input_msg_write">
        <text-input
          class="text-uppercase"
          variant="dark"
          :label="$t('Reply') + ' ' + primeironome"
          :placeholder="$t('Write a message to') + ' ' + primeironome"
          v-model="messageText"
        />
        <b-button variant="success" size="sm" class="btn rounded-pill text-uppercase" pill @click="sendMessage" v-promise-btn="{promise}">
           {{$t('Send')}} <i class="fa fa-paper-plane" ></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MessageJob from '@/core/Services/Basic/MessageJob';
import { MessageJobEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Mensagem extends Vue {
  public root: MessageJobEntity|null = null;
  public currMessages: MessageJobEntity|null = null;
  public messageJobService: MessageJob = new MessageJob;
  public messageText: string = '';
  public promise: Promise<any>|null = null;

  @Getter('getProfileType', { namespace: 'profile' })
  public getProfileType!: string;

  @Prop({ default: false }) public mobile!: boolean;

  public setRoot(message: MessageJobEntity) {
    this.root = message;
  }
  
  get isMobile() {
    return this.mobile
  }

  get primeironome() {
    return this.root?.candidato?.primeironome || "";
  }

  public setMessagesList(messages?: MessageJobEntity) {
    if (!messages)
      return;
    
    this.currMessages = messages;
    

    setTimeout(() => {
      const _this: any = this;
      _this.scroolBottom('messageContentContainer')
    },500)
  }

  public created() {
  }

  public doSearch(value: string) {
    
  }

  public backToList() {
    this.$emit('back',false)
  }

  public sendMessage() {
    if (this.messageText != '' && this.root != null) {
      const newMessage = new MessageJobEntity;
      const who: string = this.getProfileType;
      
      newMessage.mensagem = this.messageText;
      newMessage.assunto = this.root.assunto;
      newMessage.paiId = this.root!.id;
      newMessage.remetente = who === "company" ? "E" : "R";
      newMessage.candidatoId = this.root!.candidato!.id;

      this.promise = this.messageJobService.create(newMessage)
      .then((data: any) => {
        this.messageText = '';
        this.$emit('sended',this.root);
      });
    }
  }

  public showDate(data: any) {
    return this.$showDateFromObject(data)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

img {
  max-width: 100%;
}
.mesgs {
  float: left;
  padding: 0;
  width: 69%;
  background-color: white;
  height: 100%;
  .mesgs-header {
    display: flex;
    align-items: center;
    border-bottom: 4px solid $back-content-color;
    width: 100%;
    height: 8em;
    padding: 1em;
    .chat_img {
      width: 10%;
    }
    .chat_ib {
      padding: 0 0 0 2em;
      width: 80%;
      h5,
      p {
        margin: 0;
      }
      h5 {
        font-size: 1em;
        color: $font-title-dark-gray;
      }
      p {
        font-size: 0.8em;
        color: $font-title-light-gray;
      }
    }
    .exclude-messages {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 10%;
      color: $orange !important;
      i {
        margin-right: 0.5em;
        margin-bottom: 0.2em;
        cursor: pointer;
        display: flex;
      }
      label {
        margin: 0;
        cursor: pointer;
      }
    }
  }
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  float: left;
  width: 46%;
  .triangulo {
    width: 0;
    height: 0;
    position: relative;
    top: 1.5em;
    right: 5.5%;
    border-top: 0 solid transparent;
    border-right: 2em solid $green-light;
    border-bottom: 1.5em solid transparent;
  }
}
.received_withd_msg {
  background: $green-light none repeat scroll 0 0;
  border-radius: 0.3em;
  color: white;
  font-size: 1em;
  margin: 0;
  padding: 1em;
}
.time_date {
  color: white;
  display: block;
  font-size: 0.8em;
  margin: 0.5em 0 0;
  text-align: left;
}
.sent_msg__text {
  background: $blue none repeat scroll 0 0;
  border-radius: 0.3em;
  font-size: 1em;
  margin: 0;
  color: #fff;
  padding: 1em;
  width: 94%;
  .time_date {
    color: #ffffff;
  }
}
.outgoing_msg {
  overflow: hidden;
  margin: 1em 0 2em;
}
.sent_msg {
  float: right;
  width: 46%;
  .triangulo {
    width: 0;
    height: 0;
    position: relative;
    top: 1.5em;
    left: 92.5%;
    border-top: 0 solid transparent;
    border-left: 2em solid $blue;
    border-bottom: 1.5em solid transparent;
  }
}
.input_msg_write {
  display: flex;
  align-items: center;
  width: 100%;
  .form-group {
    width: 78%;
    margin-right: 2%;
  }
  button {
    width: 20%;
    color: #fff;
    font-size: 0.8em;
    height: 3.5em;
    border-color: $green;
    background-color: $green;
    &:hover {
      color: #fff;
      background-color: $green-light;
    }
  }
}
.type_msg {
  border-top: 4px solid $back-content-color;
  display: flex;
  padding: 2em;
  height: 8em;
}
.messaging {
  margin: 1em;
  height: calc(100vh - 55px - 2em);
}
.msg_history {
  height: calc(100% - 16em);
  overflow-y: auto;
  padding: 2em;
}
::-webkit-scrollbar {
  width: 0.4em;
  background: #e3e0d6;
  margin: 1em;
  padding: 18px;
}
::-webkit-scrollbar-track {
  background: rgba(232, 232, 232, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(163, 243, 241);
}

// Media Queries
@include media(">desktop", "<large") {
  .main {
    font-size: 0.7em;
  }
}

@include media("<desktop") {
  .main {
    font-size: 0.6em;
  }

  .content-messages {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: calc(100vh - 90px);
  }
  .main-messages {
    font-size: 0.7em;
    padding: 0;
    .messaging {
      margin: 0;
    }

    .inbox_people {
      border-radius: 0;
      h1 {
        font-size: 3em;
      }
    }
  }
  .inbox_people {
    width: 100%;
    .mesgs-header {
      .go-to-messages {
        display: block;
      }
    }
  }
  .mesgs {
    width: 100%;
    position: absolute;
    left: 100vw;
    transition: left 0.5s ease;
    &.isShow {
      left: 0 !important;
      transition: left 0.5s ease;
    }
    .mesgs-header {
      .go-back {
        font-size: 1.5em;
      }
    }
  }
  .msg_history {
    height: calc(100% - 27em);
    .sent_msg {
      width: 60%;
      .triangulo {
        left: 87.5%;
      }
    }
    .incoming_msg {
      margin: 1em 0;
      .incoming_msg_img {
        width: 12%;
      }
      .received_msg {
        width: 88%;
      }
    }
    p {
      font-size: 1.3em;
    }
  }
  .type_msg {
    padding: 0 2em;
    height: 9em;
  }
  .input_msg_write {
    align-items: flex-end;
    .rounded-circle {
      cursor: pointer;
      width: 3.5em;
      height: 3.5em;
      margin-bottom: 0.5rem !important;
      margin-left: 1rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-color: $green;
      background-color: $green;

      &:hover {
        color: #fff;
        background-color: $green-light;
      }
      i {
        font-size: 3em;
        display: flex;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

#modalNewMessage .modal-body {
  padding: 0;
}
.sent_msg__text a {
  color: $blue-light;
}
</style>