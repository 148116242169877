<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="lg"
    title="Large Modal"
    ref="modalAddJobcoins"
    body-class="p-0"
    lazy
  >

    <div class="modal-content m-content">
      <button class="btn btn-danger" @click="$bvModal.hide('modalAddJobcoins')">
        <i class="icon-cancelar"></i>
      </button>

      <form-add-jobcoins
        :vaga="vaga"
        @hideModalAddJobcoins="hideModalAddJobcoins"  
      >
      </form-add-jobcoins>

    </div>  
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaEntity } from "@/core/models/Entities";
import VagaShareService from "@/core/Services/Basic/VagaShare";
import GestaoHCService from "@/core/Services/Empresa/GestaoCredito";
import GestaoVagaService from "@/core/Services/Empresa/GestaoVaga";
import Modal from '@/components/modal/Modal.vue'
import Alert from '@/components/alerts/Alert.vue';
import FormAddJobcoins from '@/components/form/FormJobcoins.vue'

@Component({
  components: {
    Modal,
    'form-add-jobcoins': FormAddJobcoins,
  }
})
export default class ModalAddJobcoins extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  public hideModalAddJobcoins(data: any) {
    this.$emit('hideModalAddJobcoins', data)
  }

}

</script>
