<template>
  <div class="box-container">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RootInviteMember extends Vue {

}
</script>

<style>

</style>