<template>
  <b-card>
    <b-card-header class="mt-3 bg-white">
      <h1 class="text-center text-blue">
        <i class="fa-2x icon-information-polygon"></i>
      </h1>
    </b-card-header>
    <b-card-body class="text-center p-0">

    <h1 class="mb-3 text-dark">{{$t("Before you start, optimize your experience:")}} </h1>

    <p > 
      {{$t("For an ideal experience on our platform, we recommend that you access the interview link using Google Chrome, Microsoft Edge or Brave browsers")}}.
      {{$t("These browsers offer superior performance and compatibility, ensuring that you make the most of your interaction")}}
    </p>

    <b-alert show variant="info" class="text-dark text-center">
      <div> {{$t("Feel free to contact us if you have questions, we are here to help")}} </div>
    </b-alert>

    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OptimizeExperienceContent extends Vue {
}
</script>