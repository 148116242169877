<template>
<b-container>
  
  <b-row class="pt-5" v-if="loading">
    <b-col md="12">
      <div class="d-flex justify-content-center mb-3" >
          <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="primary"></b-spinner>
      </div>
    </b-col>
  </b-row>
  <b-row v-if="!loading">
    <b-col md="12">
      <transition appear name="fade">
        <div class="dados-perfil-empresa box-formulario-empresa d-flex align-items-center justify-content-center">
          <b-form ref="form" @submit="submitForm">
          <div class="row">
            <div class="col-md-12">
              <div class="image-background-perfil d-flex flex-column justify-content-center align-items-center">
              <div class="image-background-perfil-card">
                <img :src="service.entidade.background" v-if="service.entidade.background" />
                <img src="https://www.w3schools.com/css/mountain.jpg" v-else />
              </div>
                <div class="row">
                  <div class="col-md-12 mt-4 d-flex justify-content-center foto-perfil">
                    <img :src="service.entidade.logoempresa" v-if="service.entidade.logoempresa" />
                    <img src="/static/img/avatar.png" v-else />
                  </div>
                  <div class="col-md-12 my-4 d-flex justify-content-center">
                    <file-button-icon @change="handleLogoChange" v-b-tooltip.hover :title="$t('Recommended size of 150x150 pixels')"  :btnClass="'btn btn-light btn-change-user-picture text-uppercase rounded-pill border-secondary'">
                      {{$t('Replace company logo')}}
                    </file-button-icon>
                  </div>
                </div>
                <file-button-icon @change="handleBackgroundChange" :btnClass="'btn btn-light btn-change-background text-uppercase rounded-pill align-self-end border-secondary'">
                  {{$t('Replace background image')}}
                </file-button-icon>
              </div>
              <div class="formulario-empresa p-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h5>{{ $t("Company identification") }}</h5>
                      </div>
                      <div class="col-md-12">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('Fantasy Name')"
                          :placeholder="'Jobecam'"
                          v-model="service.entidade.nomeEmpresa"
                        />
                      </div>
                      <div class="col-md-7">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('Company Name')"
                          :placeholder="'Jobecam LTDA'"
                          v-model="service.entidade.razaosocial"
                        />
                      </div>
                      <div class="col-md-5">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('CNPJ')"
                          v-model="service.entidade.cnpj"
                        />
                      </div>
                      <div class="col-md-6">
                        <auto-complete-input
                          v-model="service.entidade.tipo"
                          :data="areasArr"
                          :value="service.entidade.tipo"
                          :label="$t('Field of Work')"
                          :placeholder="'T.I.'"
                          class="text-uppercase"
                          variant="dark"
                        />
                      </div>
                      <div class="col-md-6">
                        <auto-complete-input
                          v-model="service.entidade.setor"
                          :data="setorArr"
                          :value="service.entidade.setor"
                          :label="$t('Sector')"
                          :placeholder="'Internet'"
                          class="text-uppercase"
                          variant="dark"
                        />
                      </div>
                      <div class="col-md-6">
                        <select-input
                          :optionList="tamanho.optionList"
                          class="text-uppercase"
                          variant="dark"
                          :label="$t('Size')"
                          :placeholder="'Autonomo'"
                          v-model="service.entidade.tamanho"
                        />
                      </div>
                      <div class="col-md-6">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :label="$t('Founded in')"
                          :placeholder="'2005'"
                          v-model="service.entidade.fundada"
                        />
                      </div>
                      <div class="col-md-6">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :label="'Site'"
                          :placeholder="'www.jobecam.com'"
                          v-model="service.entidade.site"
                        />
                      </div>
                      <div class="col-md-6">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :label="$t('Institutional Video')"
                          :placeholder="'www.youtube.com/jobecam'"
                          v-model="service.entidade.videoApresentacao"
                        />
                      </div>
                      <div class="col-md-12">
                        <h5 class="my-3">{{$t("Who we are")}}</h5>
                      </div>
                      <div class="col-md-12">
                        <editor :init="initTiny" v-model="service.entidade.descricao"></editor>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h5>{{$t("Business Location")}}</h5>
                      </div>
                      <div class="col-md-12">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('Zip Code')"
                          :placeholder="'00000-000'"
                          v-model="service.entidade.cep"
                          @blur="searchByCEP"
                        />
                      </div>
                      <div class="col-md-8">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('Address')"
                          :placeholder="'Av. Paulista'"
                          v-model="service.entidade.endereco"
                        />
                      </div>
                      <div class="col-md-4">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('Number')"
                          :placeholder="'330'"
                          v-model="service.entidade.numero"
                        />
                      </div>
                      <div class="col-md-6">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="false"
                          :label="$t('Complement')"
                          :placeholder="'...'"
                          v-model="service.entidade.complemento"
                        />
                      </div>
                      <div class="col-md-6">
                        <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('District')"
                          :placeholder="'...'"
                          v-model="service.entidade.bairro"
                        />
                      </div>
                      <div class="col-md-6 phone">
                        <!-- <text-input
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="'Telefone'"
                          :placeholder="'+55 (11) 91234-4321'"
                          v-model="service.entidade.telefones"
                        /> -->
                        <label :class="phoneStyle">{{ $t("Telephone") }}*</label>
                        <vue-tel-input
                          required
                          v-model="service.entidade.celularContato"
                          placeholder="e.g. 11999999999"
                          class="tel-input"
                          defaultCountry="br"
                          @input="handleChangeInputTel"
                          v-bind="vueTelProps"
                        ></vue-tel-input>
                      </div>
                      <div class="col-md-6">
                        <select-input
                          :optionList="paisSRV.optionList"
                          class="text-uppercase"
                          variant="dark"
                          :required="true"
                          :label="$t('Country')"
                          v-model="service.entidade.pais"
                        />
                      </div>
                      <div class="col-md-6">
                        <select-input
                          v-if="service.entidade.pais == 'BR'"
                          :optionList="estadoSRV.optionList"
                          class="text-uppercase"
                          variant="dark"
                          :required="true"
                          :label="$t('State')"
                          v-model="estadoId"
                          @change="chainToCity"
                        />
                        <text-input
                          v-else
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('State')"
                          :placeholder="'São Paulo'"
                          v-model="service.entidade.uf"
                        />
                      </div>
                      <div class="col-md-6">
                        <select-input
                          v-if="service.entidade.pais == 'BR'"
                          :optionList="cidadeSRV.optionList"
                          class="text-uppercase"
                          variant="dark"
                          :required="true"
                          :label="$t('City')"
                          v-model="service.entidade.cidadeId"
                          @change="checkCountry"
                        />
                        <text-input
                          v-else
                          variant="dark"
                          class="text-uppercase"
                          :required="true"
                          :label="$t('City')"
                          :placeholder="'São Paulo'"
                          v-model="service.entidade.nomeCidade"
                          @change="checkCountry"
                        />
                      </div>
                      <div class="col-md-12">
                        <h5 class="my-3">{{$t("Specialization")}}</h5>
                      </div>Specialization
                      <div class="col-md-12">
                        <text-area
                          variant="dark"
                          class="text-uppercase"
                          :rows="5"
                          :maxlength="255"
                          v-model="service.entidade.especializacao"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end"
            >
              <b-button type="submit" class="btn btn-info m-3" v-promise-btn="{promise}">
                <i class="fas fa-check mr-2"></i>{{$t("Save")}}
              </b-button>
            </div>
          </div>
          </b-form>
        </div>
      </transition>
    </b-col>
  </b-row>
</b-container>

</template>

<script lang="ts">
import FileButtonIcon from '@/components/button-icon/FileButtonIcon.vue';
import EmpresaTamanho from "@/core/Services/Basic/EmpresaTamanho";
import Idioma from "@/core/Services/Basic/Idioma";
import CidadeService from "@/core/Services/Cidade";
import PerfilEmpresa from '@/core/Services/Empresa/PerfilEmpresa';
import EstadoService from "@/core/Services/Estado";
import PaisService from "@/core/Services/Pais";
import { siteCandidateEndPoint } from '@/core/endpoint';
import { EmpresaEntity, PerfilEmpresaEntity } from '@/core/models/Entities';
import Editor from "@tinymce/tinymce-vue";
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component<DadosPerfil>({
  components: {
    'file-button-icon': FileButtonIcon,
    Editor
  }
})
export default class DadosPerfil extends Vue {
  public loading: boolean = false;
  public service: PerfilEmpresa;
  public idiomaSrv: Idioma;
  public currentURL: string = siteCandidateEndPoint||'';
  public areasArr: any[] = [];
  public setorArr: any[] = [];
  public estadoId: number|null = null;

  public paisSRV!: PaisService;
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public tamanho!: EmpresaTamanho;
  public promise: Promise<any>|null = null;
  public initTiny: any = {
    height: 300,
    menubar: false,
    plugins:
      "print preview paste searchreplace autolink fullscreen  link    table  hr   anchor toc",
    toolbar:
      "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
    content_css: "//www.tinymce.com/css/codepen.min.css"
  };

  @Action('fetchEmpresaInfo', { namespace: 'profile' })
  public fetchEmpresaInfo: any;

  @Getter('companyInfo', { namespace: 'profile' })
  public empresa?: EmpresaEntity;
  
  constructor() {
    super();
    this.service = new PerfilEmpresa();
    this.idiomaSrv = new Idioma();
    this.paisSRV = new PaisService();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
    this.tamanho = new EmpresaTamanho();
    this.estadoSRV.loadOptions();
    this.paisSRV.loadOptions();
    this.tamanho.loadOptions();
  }

  public async created() {
    this.loading = true;
    this.idiomaSrv.loadOptions();
    
    if (!this.empresa) 
      await this.fetchEmpresaInfo();

    if (this.empresa?.id && this.empresa?.perfil) {
      this.service.entidade = Object.assign(new PerfilEmpresaEntity(),this.empresa?.perfil) ;
      this.loadLocation();
    }   
    this.loadLists();
    this.loading = false;
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(this.estadoId);
    if (value) 
      this.service.entidade!.cidadeId = this.cidadeSRV.cidadeByName(value);
  }
  
  public loadLocation() {
    this.estadoId = this.service.entidade.ufId;
    this.checkCountry();
    this.estadoSRV.loadOptions(0);
    this.cidadeSRV.loadOptions(this.service.entidade.ufId);
  }

  public checkCountry() {
    if (this.service.entidade.pais === "BR") {
      this.service.entidade.uf = "";
      this.service.entidade.cidade = "";
    } else {
      this.service.entidade.cidadeId = null;      
      this.service.entidade.ufId = null;  
      this.estadoId = null;
    }
  }

  public handleChangeInputTel(a: any,b: any) {
    this.service.entidade.celularContato = b.number.international
  }
  
  public searchByCEP() {
    this.cidadeSRV
      .searchByCEP(this.service.entidade.cep)
      .then(dta => {
        this.service.entidade.endereco = dta.logradouro;
        this.service.entidade.bairro = dta.bairro;
        this.service.entidade.pais = "BR";
        this.estadoId = this.estadoSRV.estadoByName(dta.uf);
        this.chainToCity(dta.localidade);
      });
  }

  public loadLists() {
    let i: number = 1;
    for(i;i<=18; i++) {
      this.areasArr.push(this.$t("macroSetor." + i).toString())
    }
    i = 1;
    for(i;i<=148; i++) {
      if (i!=2)
        this.setorArr.push(this.$t("setorIndustrial." + i).toString())
    }
  }

  public submitForm(evt: any) {
    evt.preventDefault();
    this.service.entidade.id = this.empresa?.id;
    this.promise = this.service.save().then(async (data: any) => {
        await this.fetchEmpresaInfo()
        this.$alert.addMsg(this.$t('Saved successfully'))
        this.$alert.callBoxCheck('Ok');
    });
  }

  public handleLogoChange(file: any) {
    if (file) {
      this.service.saveLogoEmpresa(file)
      .then((data: any) => {
        // this.alert.addTitle(this.$t('Aviso').toString())
        this.$alert.addMsg(this.$t('Saved successfully'))
        this.$alert.callBoxCheck('Ok');
        setTimeout(()=> {
          this.service.entidade.logoempresa += '&v='+ (new Date()).getTime();
        },3000)
      })
    }
  }

  public handleBackgroundChange(file: any) {
    if (file) {
      this.service.saveBackgroundAvatar(file)
      .then((data: any) => {
        // this.alert.addTitle(this.$t('Aviso').toString())
        this.$alert.addMsg(this.$t('Saved successfully'))
        this.$alert.callBoxCheck('Ok');
        setTimeout(()=> {
          this.service.entidade.background += '&v='+ (new Date()).getTime();
        },3000)
      })
    }
  }

  public get phoneStyle() {
    return this.service.entidade.telefones == '' ? 'invalid' : '' 
  }

  public beforeDestroy() {
    this.promise = null;
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

  .box-formulario-empresa {
    border: 1px solid $border-gray;
    border-radius: 8px;

    .foto-perfil {
      img {
        width: 6em;
        height: 6em;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
 .box-formulario-empresa {
    .image-background-perfil {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      max-height: 300px;
      position: relative;
      .image-background-perfil-card {
        position: absolute;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        min-height: 100%;
        width: 100%;
        top: 0px;
        img {
          position: absolute;
          top: 0;
          max-width: 100%;
        }
      }
      .btn-change-background {
        position: relative;
        top: 1em;
        margin-right: 1em;
      }
    }
  }

  
@include media("<desktop") {
  h1 {
    font-size: 2em;
  }
  .btn-change-user-picture,
  .btn-close-window,
  .btn-save-recruiter {
    font-size: 0.9em;
  }

  .phone {
    height: 72px;

    .vti__dropdown {
      padding: 8px;
      // border-bottom: white solid;
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables";

  .tel-input {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 0.15rem solid #cfcfcf !important;
    font-size: 1rem;
    input {
      color: $blue;
      // font-size: 1rem;
    }
  }

  .phone {
    label {
      font-size: 12px;
      text-transform: uppercase;
      margin: 0;
      vertical-align: top;
    }

    .vti__dropdown {
      padding: 6px;
      // border-bottom: white solid;
    }
  }

  .invalid {
    // border-bottom: 1.5px solid red !important;
    color: red;
  }

  .tel-input:focus-within {
    box-shadow: none !important;
    border-bottom: solid 0.15rem $blue !important;
    background-color: none;
  }
</style>