<template>
  <b-form-group
    :label="label+(required?' *':'')"
    class="auto-complete-form-group"
    :title="title"
    :class="variant"
    :state="state">
    
      <auto-complete
        :class="'p-0 m-0 '+variant"
        id="input-habilities"
        ref="typeahead"
        @input="handleInput($event)"
        :search="search"
        :placeholder="placeholder"
        :aria-label="placeholder"
        :get-result-value="getResultValue"
        @submit="handleSelect($event)"
        :debounce-time="500"
        :default-value="value"
      />
    <b-form-invalid-feedback v-if="invalidFeedback">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
    <slot v-else name="invalidFeedback" />
  </b-form-group>
</template>

<script lang="ts">
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<AutoCompleteInput>({
  components: {
    'auto-complete': Autocomplete,
  }
})
export default class AutoCompleteInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: 'text'}) public type!: string;
  @Prop({default: ''}) public value!: string;
  @Prop({default: ''}) public serializer!: any;
  @Prop({default: () => { return [] }}) public data!: any[];
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;
  
  public title!: string;

  public timeout: any = null;

  public search(v1: string) {
    return new Promise((resolve: (v: any[]) => void) => {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(async (currentValue: string) => {
      const arrFilter: any[] = this.data.filter((hb: string) => {  
          if(hb)
            return (hb.toLowerCase().indexOf(currentValue.toLowerCase()) > -1) 

          return false;
        })
      resolve(arrFilter)
      }, 500, v1);
    })
  }
  
  public getResultValue(r: string) {
    return r;
  }

  public currentModel: string = '';

  public mounted() {
    this.title = this.value
  }

  public handleInput(v: any) {
    this.$emit('input', v?.target?.value);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
  
  public handleSelect(v: any) {
    this.$emit('input', v);
    this.$emit('selected', v);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../../../node_modules/@trevoreyre/autocomplete-vue/dist/style.css';
  @import "@/assets/scss/_variables";

  .auto-complete-form-group {
    ul {
      z-index: 99 !important;
    }
    input {
      border: none;
      border-radius: 0 !important;
      background-color: #fff;
      padding: 5px !important;

      border-bottom-color: $light-gray !important;
      color: $blue;
    }
    input:focus {
      color: $blue;
      border-bottom-color: $blue !important;
      box-shadow: none !important;
      background-color: #fff;
    }
    input:valid {
      border-bottom: 1.5px solid $light-gray !important;
    }
    .gray-bordered {
      input {
        border: 1.5px solid $light-gray !important;
        border-radius: 0 !important;
        padding-left: 5px;
        box-shadow: 1px 1px 2px #ccc;
        color: $dark-gray;
      }
      &:focus {
        input {
          color: $blue;
          border-color: $blue !important;
        }
      }
    }

    .form-group .col-form-label,
    .form-group:valid .col-form-label {
      color: white !important;
    }

    .form-control::placeholder {
      color: rgba(140,140,140,0.65) !important;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .form-control.is-invalid:focus {
      border-bottom-color: #dc3545 !important;
    }
  }

  .auto-complete-form-group .col-form-label {
    font-size: 12px;
    padding: 0;
    color: $light-gray;
  }
  .auto-complete-form-group:valid .col-form-label {
    color: white;
  }

  .auto-complete-form-group .col-form-label {
    color: #000;
  }
  .auto-complete-form-group:valid .col-form-label {
    color: #000;
  }
  .auto-complete-form-group:invalid .col-form-label {
    color: red;
  }
</style>

