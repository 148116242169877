<template>
  <div class="image-container">
    <!--   Big container   -->
    <div class="container">
      <div class="text-center">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h3>{{ $t(contentText) }}</h3>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";

const namespace: string = "profile";

@Component
export default class RedirectLogin extends Vue {
  @Action("companyRequest", { namespace }) public companyRequest: any;
  public contentText: string = "";

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }
  public created() {
    if (this.$route.query.accessToken) {
      if (this.$route.query.logoutURL) {
        sessionStorage.setItem(
        "logoutURL",
        this.$route.query.logoutURL + ""
      );
      }
      sessionStorage.setItem(
        "access_token",
        this.$route.query.accessToken + ""
      );
      let profile_type = this.$route.query.profile_type || "company";
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", ""+profile_type);
      this.companyRequest()
        .then(() => {
          this.contentText = "Loading";

          let rurl: any =
            this.$route.query.redirectTo ||
            sessionStorage.getItem("redirectTo") ||
            "/";
          setTimeout(() => {
            this.$router.push({
              path: rurl,
              query: { lang: this.$i18n.locale }
            });
          }, 500);
        })
        .catch(() => {
          this.removeItensFromStorage();
          this.contentText = "There was a problem. Wait a moment we`ll redirect you";
          this.redirectError();
        });
    } else {
      this.removeItensFromStorage();
      this.contentText = "There was a problem. Wait a moment we`ll redirect you";
      this.redirectError();
    }
  }
  redirectError() {
    setTimeout(() => {
      this.$router.push({
        path: "/login",
        query: { lang: this.$i18n.locale }
      });
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  background: linear-gradient(
    to bottom,
    rgba(0, 170, 204, 0.8) 0%,
    rgba(6, 111, 182, 0.8) 100%
  );
  .container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>