export default class ExportTable {
  public static tmpColDelim: string = String.fromCharCode(11);
  public static tmpRowDelim: string = String.fromCharCode(0);
  public static colDelim: string = ';';
  public static rowDelim: string = '\r\n';

  public static formatRows(rows) {
    return rows.join(ExportTable.tmpRowDelim)
        .split(ExportTable.tmpRowDelim).join(ExportTable.rowDelim)
        .split(ExportTable.tmpColDelim).join(ExportTable.colDelim);
  }
  public static grabRow(row: HTMLElement, i: number) {
    let $cols: any = row.querySelectorAll('td');
    if (!$cols.length)
      $cols = row.querySelectorAll('th');
    return [...$cols].map(ExportTable.grabCol).join(ExportTable.tmpColDelim);
  }
  public static grabCol(col: HTMLElement, i: number) {
      return col.innerHTML.trim(); // escape double quotes
  }

  public $headers: any|null = null;
  public $rows: any|null = null;
  public csv: string = '';

  constructor($table: any, filename: string, a: any) {
    this.$headers = $table.querySelectorAll('thead tr');
    this.$rows = $table.querySelectorAll('tbody tr');

    // Grab text from table into CSV formatted string
    this.csv = '';
    this.csv += ExportTable.formatRows([...this.$headers].map(ExportTable.grabRow));
    this.csv += ExportTable.rowDelim;
    this.csv += ExportTable.formatRows([...this.$rows].map(ExportTable.grabRow)) + '';

    // Data URI
    const BOM = '\uFEFF';
    const csvData = 'data:application/csv;charset=utf-8,' + BOM + encodeURIComponent(this.csv);

    a.setAttribute('download', filename);
    a.setAttribute('href', csvData);
  }
}