<template>
  <div class="cadastro-empresa">
    <public-header />
  
    <div class="cadastro-container">
      <div class="sections-container">
        <section class="hero-section">
          <h1 class="h2">{{ $t('Try a new way to hire') }}</h1>
        </section>
        
        <section class="form-section">
          <register-form v-if="!shouldConfirmAccount" @success="goToConfirmationStep" />
          <confirmation-form v-else @success="successConfirmation" :email="companyEmail" />
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import RegisterForm from './partials/RegisterForm.vue';
import ConfirmationForm from './partials/ConfirmationForm.vue';
import Logo from '@/components/logo/Logo.vue';
import PublicHeader from '@/components/layout/PublicHeader.vue';

@Component({
  components: {
    RegisterForm,
    ConfirmationForm,
    Logo,
    PublicHeader
  },
})
export default class Cadastro extends Vue {
  public companyEmail?: string;

  public shouldConfirmAccount = false;

  public goToConfirmationStep(data) {
    this.companyEmail = data.email;
    this.shouldConfirmAccount = true;
  }

  public successConfirmation() {
    const accessToken = sessionStorage.getItem("access_token");

    if (!accessToken) return;

    window.location.replace(
      `/redirectLogin?accessToken=${accessToken}&redirectTo=/home?lang=${this.$i18n.locale}`
    );
  }
}
</script>

<style>
body {
  overflow: auto !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/_variables";

div.cadastro-empresa {
  font-family: nerislight;
}

div.cadastro-container {
  display: flex;
  justify-content: center;

  width: 100%;
  min-height: 85vh;

  div.sections-container {
    width: 90%;
    min-height: 90%;
    margin-bottom: 32px;
    border-radius: 20px;
    overflow: hidden;

    -webkit-box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;

    @media only screen and (max-width: 400px) {
      width: 100%;
    }
  }

  section.hero-section {
    background: url("https://jobecam-assets.s3-us-west-1.amazonaws.com/svg/login_empresa.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    
    flex: 1;
    padding: 16px 0;

    h1 {
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding: 0px 16px;
    }

    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  section.form-section {
    flex: 1;
    margin: auto 0;
    padding: 16px 0px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    form {
      width: 100%;
    }
  }
}
</style>
