
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { QuestionaryEntity } from '@/core/models/Entities';


export default class ExamImage extends Repository {

  public optionList?: any[];

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/exam-image', method: 'POST', headers: {} },
      fetch: { url: '/v1/exam-image/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/exam-image', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/exam-image/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/exam-image/{id}', method: 'DELETE', headers: {} }
    }));
    this.optionList = [];
  }
  
  

  public saveQuestion(qid: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('question', '1');
    formData.append('id', '0');
    formData.append('qid', qid);
    formData.append('image', file);
    return super.update(formData, 'multipart/form-data');
  }

  public saveAnswer(aid: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('answer', '1');
    formData.append('id', '0');
    formData.append('aid', aid);
    formData.append('image', file);
    return super.update(formData, 'multipart/form-data');
  }
}


