<template>
  <div class="container-fluid not-gutters text-center">   
    <br/>
    <b-alert show v-if="temGrupo">{{$t('Candidate did not report any belonging groups!')}}</b-alert>
    <b-row class="px-2" >
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoLgbt ">
<<<<<<< HEAD
        <img class="image"  :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_lgbt%2B.png'" />
        <span>LGBTQIA+</span>
=======
        <img class="image"  :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_lgbt%2B.png'" />
        <span>LGBTQI+</span>
>>>>>>> 9de33b243f035486ae5c994414174ab5e57e27b9
      </b-col>
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoIndiginas ">
        <img class="image"  :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_comunidade_indigena.png'" />
        <span>{{$t('Indigenous Community')}}</span>
      </b-col>
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoRefugiados ">
        <img class="image"  :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_refugiados_imigrantes.png'" />
        <span>{{$t('Refugee and Immigrants')}}</span>
      </b-col>
      <b-col disabled md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoMovBlack ">
        <img class="image"  :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_movimento_black.png'" />
        <span>{{$t('Afro-descendants Community')}}</span>
      </b-col>
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoPCD ">
        <img disabled class="image" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_pcd.png'" />
        <span>{{$t('PWD Group')}}</span>
      </b-col>
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupoEmpoderaFeminino ">
        <img class="image"  :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_empoderamento_feminino.png'" />
        <span>{{$t('Womens Movement')}}</span>
      </b-col>
      <b-col md="2" cols="6" style="text-align: center" v-if="candidatura.candidato.perfil.grupo3Idade ">
        <img class="image"  :src="'https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_3idade.png'" />
        <span>{{$t('Senior citizen group')}}</span>
      </b-col>
    </b-row>  

    <br/>
    <br/>

  </div>
</template>

<script lang="ts">
// List Company evaluations
import {
CandidaturaEntity
} from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class GruposPertencimento extends Vue {

  @Prop({default: () => { new CandidaturaEntity() }})
  public candidatura!: CandidaturaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;

  constructor() {
    super();
  }

  get temGrupo(): boolean {
    return (
      ( (this.candidatura.candidato.perfil.grupoLgbt == false) &&
      (this.candidatura.candidato.perfil.grupoIndiginas== false) &&
      (this.candidatura.candidato.perfil.grupoRefugiados== false) &&
      (this.candidatura.candidato.perfil.grupoMovBlack== false) &&
      (this.candidatura.candidato.perfil.grupoPCD== false) &&
      (this.candidatura.candidato.perfil.grupo3Idade== false) &&
      (this.candidatura.candidato.perfil.grupoEmpoderaFeminino== false) ) ||
      (this.candidatura.candidato.perfil.aceiteDadosSensiveis== false)
    );
  }

}
</script>

<style lang="scss">
img {
  width: 100%;
  height: auto;
}
.grayscale { filter: grayscale(100%); }
.contrast { filter: contrast(160%); }
</style>
