<template>
  <transition>
      <header class="toolbar" id="header">
          <b-navbar toggleable="sm" class="d-flex justify-content-between m-0 p-0" type="light" variant="jb-light">
            <div class="recording-status" v-show="recordingEnabled">
              <b-button variant="danger">
                {{$t('Recording')}} <i class="fa fa-circle"></i>
              </b-button>
            </div>

            <b-button
              v-b-tooltip.hover
              :title="$t('Share room')"
              @click="showFixedAlert"
              class="share-button ml-3"
            >
              <span class="mr-2">{{ $t('Share room') }}</span>
              <b-icon icon="share" />
            </b-button>

            <b-nav class="toolbar flex-wrap align-content-center justify-content-sm-start justify-content-md-center">
                <b-nav-text>
                  <b-button :variant="!value.isAudioActive() ? 'toolbar-danger-closed' : 'toolbar-danger'"
                    v-b-tooltip.hover
                    :title="$t('Mute/Unmute Mic')"
                    class="navButton" id="navMicButton" @click="micStatusChanged"><i :class="micIcon" ></i></b-button>
                </b-nav-text>
                <b-nav-text>
                  <b-button :variant="!value.isVideoActive() ? 'toolbar-danger-closed' : 'toolbar-danger'"
                    v-b-tooltip.hover
                    :title="$t('Close / Open Camera')"
                    class="navButton" id="navCamButton" @click="camStatusChanged"><i :class="videoIcon" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text v-show="!isSharing" >
                  <b-button variant="default"
                    v-b-tooltip.hover
                    :title="$t('Share screen')"
                   :class="{'navButton':true, 'd-none d-md-block': !isSharing }" @click="screenShare"><i :class="screenIcon" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text v-show="isSharing">
                  <b-button variant="default"
                    v-b-tooltip.hover
                    :title="$t('Stop Screen Sharing')"
                    :class="{'navButton':true, 'd-none d-md-block': isSharing }"  @click="stopScreenShare" ><i class="fa fa-ban" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text>
                  <b-button variant="default"
                    v-b-tooltip.hover
                    :title="$t('FullScreen')"
                   class="navButton" color="inherit" @click="toggleFullscreen" ><i :class="fullScreenIcon" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text>
                  <b-button variant="toolbar-danger-closed" class="navButton"
                    v-b-tooltip.hover
                    :title="isAuthenticatedUser ? $t('Close Room') :$t('Exit Room')"
                    @click="leaveSession"  ><i class="fa fa-power-off" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text>
                   <b-button 
                    v-b-tooltip.hover
                    title="Chat" 
                    variant="default" class="navButton" @click="toggleChat" >
                    <i v-if="showNotification" class="far fa-comment-dots text-warning" ></i>
                    <i v-else class="fa fa-comments text-white" ></i>
                    </b-button>
                </b-nav-text>                  
                <!-- <b-nav-text v-show="showNotification">
                  <b-button 
                    v-b-tooltip.hover
                    title="Chat" 
                    variant="default"  class="navButton"  @click="toggleChat" ><i class="far fa-comment-dots" ></i></b-button>
                </b-nav-text>  -->
                <b-nav-text  v-show="isAuthenticatedUser && !recordingEnabled && permitRecord">
                  <b-button 
                    v-b-tooltip.hover
                    :title="$t('Start Recording')" 
                    class="navButton text-danger border-none" variant="default"  id="popover-reactive-2" ref="button">
                    <i class="fa fa-circle"></i>
                  </b-button>
                </b-nav-text> 
                <b-nav-text v-show="isAuthenticatedUser && recordingEnabled && permitRecord">
                  <b-button 
                    v-b-tooltip.hover
                    :title="$t('End Recording')"  class="navButton text-secondary" variant="light" @click="stopRecording"  >
                    <i class="fa fa-square"></i>
                  </b-button>    
                </b-nav-text>              
                <!-- <b-nav-text v-show="isAuthenticated">
                  <b-button variant="default"  :class="{'navButton':true, 'd-none d-md-block': isAuthenticated }"
                    v-b-tooltip.hover
                    :title="$t('Copiar Link de Talento')"
                    v-clipboard:copy="candidateLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  ><i class="fa fa-user" ></i></b-button>
                </b-nav-text>                  
                <b-nav-text v-show="isAuthenticated">
                  <b-button variant="default" :class="{'navButton':true, 'd-none d-md-block': isAuthenticated }"
                    v-b-tooltip.hover
                    :title="$t('Copiar link de Gestor')"
                    v-clipboard:copy="managerLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  ><i class="fa fa-user-tie" ></i></b-button>
                </b-nav-text>               -->
            </b-nav>
            
            <div>
              <b-dropdown dropup no-caret variant="light" right v-if="!isProductionEnv">
                <template v-slot:button-content>
                <i class="fa fa-filter"></i>
                </template>
                <b-dropdown-item v-for="i in [1,2,3,4,5,6,7]" :key="'a'+i" @click="audioFilter(i)"> Audio Filter ({{i}}) </b-dropdown-item>
                <b-dropdown-item v-for="i in [1,2,3,4,5,6,7]" :key="'v'+i" @click="videoFilter(i)"> Video Filter ({{i}}) </b-dropdown-item>
              </b-dropdown>

              <b-button class="px-1" variant="light"  id="popover-reactive-1" ref="button"  v-if="!isProductionEnv">
                <i class="fa fa-ellipsis-v"></i>
              </b-button>
            </div>

              <b-popover
                target="popover-reactive-2"
                triggers="click"
                :show.sync="showPopoveRec"
                placement="top"
                container="container"
                ref="popover"
                @show="onShow"
                @shown="onShown"
                @hidden="onHidden"

              >
                <template v-slot:title>
                  <b-button  @click="onClose"  class="close" aria-label="Close">
                    <span class="d-inline-block" aria-hidden="true">&times;</span>
                  </b-button>
                  {{$t('Record Room')}}
                </template>
              <div >
                <label for="recname">{{$t('Recording Name')}}</label>
                <b-form-input id="recname" v-model="recName" type="text"></b-form-input>
              </div>
              
              <div class="mt-1">
                <b-button class="px-1" variant="primary" @click="doCallStartRecordingWithName()">
                  {{$t('Start Recording')}}
                </b-button>
              </div>
            </b-popover>

             <b-popover
                v-if="!isProductionEnv"
                target="popover-reactive-1"
                triggers="click"
                :show.sync="showPopover"
                placement="top"
                container="container"
                ref="popover"
                @show="onShow"
                @shown="onShown"
                @hidden="onHidden"
              >
                <template v-slot:title>
                  <b-button @click="onClose" class="close" aria-label="Close">
                    <span class="d-inline-block" aria-hidden="true">&times;</span>
                  </b-button>
                  Interactive Audio Filter
                </template>
              <div>
                <label for="range-pitch">Pitch: {{ pitch }}</label>
                <b-form-input id="range-pitch" v-model="pitch" type="range" min="0" max="5" step="0.01"></b-form-input>
              </div>
              <div>
                <label for="range-rate">Rate: {{ rate }}</label>
                <b-form-input id="range-rate" v-model="rate" type="range" min="0" max="5" step="0.01"></b-form-input>
              </div>
              <div>
                <label for="range-tempo">Tempo: {{ tempo }}</label>
                <b-form-input id="range-tempo" v-model="tempo" type="range" min="0" max="5" step="0.01"></b-form-input>
              </div>
              <div>
                <b-button class="px-1" variant="primary" @click="audioCustomFilter()">
                  OK
                </b-button>
              </div>
            </b-popover>
        </b-navbar>
    </header>
  </transition>  
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import Logo from '@/components/logo/Logo.vue';
import { siteCandidateEndPoint } from "@/core/endpoint";

@Component<Toolbar>({
  components: {
    ButtonIcon,
    Logo
  }
})
export default class Toolbar extends Vue {
  public recordingPromise: Promise<any>|null = null;

  @Prop({default: () => {return new UserModel()}})
  private value!: UserModel;

  @Prop({default: false })
  public showNotification!: boolean;

  @Prop({default: false })
  private isAuthenticatedUser!: boolean;

  @Prop({default: [] })
  private audioDevices!: any[];

  @Prop({default: [] })
  private videoDevices!: any[];

  @Prop({default: "" })
  private sessionId!: string;

  @Prop({default: false })
  private fullscreen!: boolean;

  @Prop({default: false })
  private sharing!: boolean;

  @Prop({default: false })
  private recordingEnabled!: boolean;

  @Prop({default: false })
  private blindEnabled!: boolean;

  @Prop({default: false })
  private permitRecord!: boolean;
  
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public recName: string = '';
  public showPopover: boolean = false;
  public showPopoveRec: boolean = false;
  public pitch: number = 1;
  public rate: number = 1;
  public tempo: number = 1;

  private currentAudioDeviceId: string = "";
  private currentVideoDeviceId: string = "";


  public micStatusChanged(event: any) {
    this.$emit('micStatusChanged', event);
  }

  public camStatusChanged(event: any) {
    this.$emit('camStatusChanged', event);
  }

  public screenShare(event): any {
   this.$emit('screenShare', event);
  }

  public leaveSession(event: any) {
    this.$emit('leaveSession', event);
  }

  public toggleFullscreen(event: any) {
    this.$emit('toggleFullscreen', event);
  }

  public stopScreenShare(event) {
    this.$emit('stopScreenShare', event);
  }

  public toggleChat(event: any) {
    const property: string = this.showNotification ? 'block' : 'none';
    this.$emit('toggleChat', property)
  }

  public audioFilter(v: any) {
    this.$emit('audioFilter', v)
  }

  public audioCustomFilter(v: any) {
    this.$emit('audioCustomFilter', this.pitch,this.rate, this.tempo);
    this.showPopover = false;
  }
  
  public removeFilter(v: any) {
    this.$emit('removeFilter', v)
  }

  public videoFilter(v: any) {
    this.$emit('videoFilter', v)
  }

  public doCallStartRecordingWithName() {
    this.$emit('startRecording', this.recName, this.recordingPromise)
    this.showPopoveRec = false;
  }

  public startRecording(v: any) {
    this.showPopoveRec = true;
  }

  public stopRecording(v: any) {
    this.$emit('stopRecording', v, this.recordingPromise)
  }

  public showFixedAlert(event) {
    this.$emit('showFixedAlert', event);
  }

  get isProductionEnv() {
     return (process.env.NODE_ENV == 'production');
  }


  get isAuthenticated() {
    return this.isAuthenticatedUser;
  }

  onClose() {
    this.showPopover = false
    this.showPopoveRec = false
  }
  onOk() {
  }
  onShow() {
    // This is called just before the popover is shown
    // Reset our popover form variables
  }
  onShown() {
    // Called just after the popover has been shown
    // Transfer focus to the first input
  }
  onHidden() {
    // Called just after the popover has finished hiding
    // Bring focus back to the button
  }

  get isSharing() {
    return this.sharing;
  }

  get micIcon() {
    return this.value.isAudioActive() ? 'fa fa-microphone' : 'fa fa-microphone-slash';
  }

  get videoIcon() {
    return this.value.isVideoActive() ? 'fa fa-video' : 'fa fa-video-slash';
  }
  
  get fullScreenIcon() {
    return this.fullscreen ? 'fa fa-compress' : 'fa fa-expand';
  }

  get screenIcon() {
    return this.value.isScreenShareActive() ? 'fa fa-window-close' : 'fa fa-window-restore';
  }

 
  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
    this.$bvModal.hide('modalCopyLink')
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }

  public doCallToggleDevices() {
    this.$emit('toggleDevices',this.currentVideoDeviceId,this.currentAudioDeviceId)
  }

  get candidateLink() {
    return this.URLcandidato + "/sala-entrevista/" + this.$route.params.sid;
  }
  get managerLink() {
    return this.URL + "/emp/sala-entrevista/" + this.$route.params.sid +"?b="+this.blindEnabled;
  }
}
</script>

<style lang="css" src="@/assets/css/live-interview/toolbar.css"></style>