<template>
  <b-card no-body>
    <b-card-header v-if="showHeader">
      <div class="row">
        <div class="col-md-2 col-4 foto-perfil d-flex justify-content-center">
          <img :src="avatar" v-if="!isBlind && avatar"/>
          <img src="/static/img/avatar.png" alt v-else/>
        </div>
        <div class="col-md-6 col-8 d-flex align-items-center buttons">
          <div class="row">
            <div class="col-md-12 mb-2">
              <h3 class="m-0">{{nomeCompleto}} 
                <small>
                <i v-if="temExpTemForm" 
                class="fa fa-exclamation-triangle text-warning" 
                v-b-tooltip.hover
                :title="temExpTemForm"  /></small>
              </h3>
              <span class="m-0" v-if="candidato.email">{{candidato.email}} </span> 
              <span class="m-0" v-if="localidade"> - {{localidade}}</span> <br />  
              <span class="m-0" v-if="candidato.perfil.telefone">{{$t('Telephone')}}: {{candidato.perfil.telefone}}</span>
              
            </div>
          </div>
        </div>
      </div>
      
    </b-card-header>
    
    <b-card-body>
      <b-form @submit.prevent="saveData">
        <b-row>
          <b-col :md="colSize" sm="12" v-if="!recrutador && !empresa">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Evaluator')"
              :placeholder="$t('Enter your name')"
              v-model="avaliacao.nomeAvaliador"
            />
          </b-col>
          <b-col :md="colSize" sm="12"  v-if="!recrutador && !empresa">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('E-mail')"
              :placeholder="$t('Fill in with your corporate email')"
              v-model="avaliacao.emailAvaliador"
            />
          </b-col>

          <b-col :md="colSize" sm="12">
            <h6 :class="redForRequired(avaliacao.nota)">{{ $t("Score") }}* 
                <b-button id="button-scoreeval" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                <b-tooltip target="button-scoreeval" placement="left">
                  <ul class="list-unstyled">
                    <li> {{ $t("Evaluation Score") }}</li>
                    <li> {{ $t("This has a minimum value of 0 and a maximum of 5") }}</li>
                    <li> {{ $t("Will rank the talents evaluated") }}</li>
                  </ul>
                </b-tooltip>
                </h6>
            <b-form-rating v-model="avaliacao.nota" stars="5" variant="warning"></b-form-rating>
          </b-col>

          
          <b-col :md="colSize" sm="12">
            <h6 :class="redForRequired(avaliacao.nota)">{{ $t("Liked") }}?*
                <b-button id="button-likedeval" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                <b-tooltip target="button-likedeval" placement="left">
                  <ul class="list-unstyled">
                    <li> {{ $t("After human resources analysis") }}:</li>
                    <li> {{ $t("If you liked this talent will be advised") }}</li>
                    <li> {{ $t("If neutral this talent will be in standby") }}</li>
                    <li> {{ $t("If you disliked this talent to be advised against") }}</li>
                  </ul>
                </b-tooltip></h6>
            <b-button-group class="d-flex">
              <b-button :variant="avaliacao.status == curti ? 'primary' : 'outline-primary'" @click="avaliacao.status = curti"> <i class="far fa-thumbs-up" /> {{$t('Like')}}</b-button>
              <b-button :variant="avaliacao.status == neutro ? 'info' : 'outline-info'" @click="avaliacao.status = neutro"><i class="far fa-meh" /> {{$t('Neutral')}}</b-button>
              <b-button :variant="avaliacao.status == naocurti ? 'warning' : 'outline-warning'" @click="avaliacao.status = naocurti"><i class="far fa-thumbs-down" /> {{$t('Dislike')}}</b-button>
            </b-button-group>
          </b-col>
          
          <b-col md="12" sm="12" class="mt-2">
            <h6 :class="redForRequired(avaliacao.corpo)">{{ $t("Evaluation") }}*</h6>
            <editor :init="initTinySimple" v-model="avaliacao.corpo" ref="tinyMCE"></editor>
          </b-col>
          <b-col md="12" sm="12" class="mt-2 d-flex justify-content-center">
            <button type="button"
              class="btn btn-outline-warning btn-clean text-uppercase "
              @click="cleanForm"
            >
              <i class="fa fa-trash"></i>
              {{$t('Clear')}}
            </button>
            <button type="submit"
              class="btn btn-info btn-salvar text-uppercase ml-1"
              v-promise-btn="{promise}"
            >
              <i class="icon-check"></i>
              {{$t('Save')}}
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>

  </b-card>
</template>

<script lang="ts">
import { CandidatoEntity, EmpresaEntity, RecrutadorEntity, VagaEntity, AvaliaProfissionalEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import Editor from "@tinymce/tinymce-vue";
import AvaliaProfissional from '@/core/Services/Empresa/AvaliaProfissional';
import { EAvaliacaoStatus } from '@/core/Enums/EAvaliacaoStatus';

@Component({
  components: {
    editor: Editor
  }
})
export default class FormAvaliacao extends Vue {

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: "profile" })
  public recrutador?: RecrutadorEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({ default: false})
  public showHeader!: boolean;
  
  @Prop({ default: null})
  public coUID!: string;
  
  @Prop({ default: null})
  public raToken!: string;
  
  @Prop({ default: null})
  public token!: string;

  @Prop({ default: () => { new CandidatoEntity() } })
  public candidato!: CandidatoEntity;
  
  @Prop({ default: "6"})
  public colSize!: string;

  @Prop({default: null})
  public publicName!: string;

  @Prop({default: null})
  public publicEmail!: string;

  public avaliacao: AvaliaProfissionalEntity = new AvaliaProfissionalEntity();
  public service: AvaliaProfissional;
  public promise: Promise<any>|null = null;

  public curti: EAvaliacaoStatus = EAvaliacaoStatus.Curti;
  public neutro: EAvaliacaoStatus = EAvaliacaoStatus.Neutro;
  public naocurti: EAvaliacaoStatus = EAvaliacaoStatus.NaoCurti;

  constructor(){
    super();
    this.service = new AvaliaProfissional();
    this.avaliacao = new AvaliaProfissionalEntity();
    this.avaliacao.status = this.neutro;
    this.avaliacao.nota = 0;
  }

  get avatar() 
  {
    return this.candidato?.fotoAvatar
  }

  get localidade() {
    return (this.candidato.perfil.localidade!=undefined) ? `${this.candidato.perfil.localidade} ${this.candidato.perfil.nomeCidade} / ${this.candidato.perfil.nomeEstado}` : '';
  }

  get temExpTemForm() {
    let x: string = "";
    if (this.candidato?.perfil?.temExperiencia===false) {
      x += this.$t('Looking for the first job').toString()+" ";
    }
    if (this.candidato?.perfil?.temExperiencia===false) {
      x += this.$t('Has no Academic Data').toString();
    }
    return x;
  }
  
  get nomeCompleto () {
    return (!this.isBlind) ? `${this.candidato?.primeironome} ${this.candidato?.sobrenome}` : this.$t('Talent in anonymous process');
  }

  get isBlind() 
  {
    return (this.vaga?.blind||0) == 1
  }

  public mounted() {
    if(this.publicEmail)
      this.avaliacao.emailAvaliador = this.publicEmail;
    
    if(this.publicName)
      this.avaliacao.nomeAvaliador = this.publicName;
  }

  public validateData(): boolean {
    let valid: boolean = true;

    // candidato é obrigatório
    if(this.candidato == null) {
      this.$alert.addMsg(this.$t('It is not possible to accomplish the action without defining the talent to be evaluated'))
      valid = false;
    } 
    
    // empresa é obrigatório se logado
    if(!this.avaliacao.coUID) {
      this.$alert.addMsg(this.$t('It is not possible to accomplish the action without defining the company that is evaluating'))
      valid = false;
    } 

    if(!this.avaliacao.nomeAvaliador){
      this.$alert.addMsg(this.$t('It is necessary to inform the name of who is evaluating'))
      valid = false;
    }
    
    if(!this.avaliacao.emailAvaliador){
      this.$alert.addMsg(this.$t('You must inform the e-mail of who is evaluating'))
      valid = false;
    }
    
    if(!this.avaliacao.corpo){
      this.$alert.addMsg(this.$t('It is necessary to describe your evaluation'))
      valid = false;
    }
    
    if(!this.avaliacao.nota){
      this.$alert.addMsg(this.$t('It is necessary to inform a score for talent'))
      valid = false;
    }
    
    if(!this.avaliacao.status){
      //padrão
      this.avaliacao.status = EAvaliacaoStatus.Neutro
    }

    if(!valid){
      this.$alert.callBoxWarn()
    }

    return valid;
  }

  public cleanForm() {
    this.avaliacao.guid = null;
    this.avaliacao.status = EAvaliacaoStatus.Neutro;
    this.avaliacao.nota = 0;
    this.avaliacao.corpo = "";
  }

  public saveData() {
    const _: any = this;

    //setting up who is who
    this.avaliacao.reUID = this.recrutador?.guid||null;
    this.avaliacao.caUID = this.candidato?.guid||null;
    this.avaliacao.coUID = this.empresa?.guid||this.coUID||null;
    this.avaliacao.vagaURL = this.vaga?.nomeUrl||null;

    if(this.recrutador != null) {
      this.avaliacao.nomeAvaliador = this.recrutador.nome||null;
      this.avaliacao.emailAvaliador = this.recrutador.email||null;
    } else if(this.empresa != null) {
      this.avaliacao.nomeAvaliador = this.empresa.primeironome||null;
      this.avaliacao.emailAvaliador = this.empresa.email||null;
    }
    
    if(!this.validateData())
      return;
    
    let postData: any = this.avaliacao;

    if(this.$route.name == 'PublicCandidatura' || this.$route.name == 'ManagerEvaluationView')
    {
      postData.__ = this.token;
      postData.from = this.$route.name;
    }
    
    if(this.$route.name == 'PublicRoomManager')
    {
      const token = this.raToken;
      postData.__ = token;
      postData.from = this.$route.name;
    }
    
    if(this.$route.name == 'RoomManagerFull')
    {
      postData.sid = this.$route.params.sid;
      postData.from = this.$route.name;
    }

    this.promise = this.service.create(postData)
    .then(() => {
      _.toastSuccess('Evaluation sent successfully');
      this.$emit('evaluated',true)
      this.cleanForm();
    }).catch(() => {
      _.toastError('An error occurred by saving the evaluation');
    })
  }

}
</script>

<style lang="scss" scoped>
  .foto-perfil {
    padding: 0 2em 2em 2em;
    position: relative;
    display: flex;
    // object-fit: cover;
    border-radius: 200px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 90px;
        height: 90px;
    }
  }
</style>