<template>
  <transition name="fade">
    <div class="wrapper-clean">
      <div class="background-color-style"></div>
      <b-container>
        <b-row>
          <b-col md="12">
            <template v-if="candidato">
              <b-container class="mb-3">
                <b-row>
                  <b-col md="12" class="mt-5">
                    <dados-pessoais-view :candidato="candidato" :isPublic="true" :ispdf="true" :display-name="name" :display-video="false" class="dados-pessoais-pdf"/>
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <card-pdf-professional-info :experiencias="experiencias" v-model="candidato" />
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <card-pdf-academic-info :formacoes="formacoes"  />
                  </b-col>
                  <b-col md="12" class="mt-2 ">
                    <card-pdf-skills :habilidades="habilidades" />
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-col>
        </b-row>
      </b-container>
      <div class="footer-background-color-style">
        <span class="text-white label-by-footer">By Jobecam</span>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { CandidatoEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import CardPdfAcademicInfo from './partials/CardPdfAcademicInfo.vue';
import CardPdfProfessionalInfo from './partials/CardPdfProfessionalInfo.vue';
import CardPdfSkills from './partials/CardPdfSkills.vue';
import DadosPessoaisView from './partials/DadosPessoaisView.vue';

@Component<HiddenTalentInfoPage>({
  components: {
    DadosPessoaisView,
    CardPdfProfessionalInfo,
    CardPdfSkills,
    CardPdfAcademicInfo
  }
})
export default class HiddenTalentInfoPage extends Vue {

  public candidato: CandidatoEntity|null = null;
  public service: CandidatoService = new CandidatoService();
  public name: string = ""; 

  get experiencias() {
    return this.candidato?.perfil?.experiencias;
  }

  get formacoes() {
    return this.candidato?.perfil?.formacoes;
  }

  get habilidades() {
    return this.candidato?.perfil?.habilidades;
  }

  public async mounted() {
    const guid = this.$route.params.guid
    const name: string = (this.$route.query.n||"")+""
    if(name) {
      this.name = atob(name);
    }
    this.candidato = await this.service.fetchHidden({guid})
    setTimeout(() => {
      const colors: string[] = ["blue","orange","purple", "darkgreen", "lightcoral", "lightpink"]
      const x: HTMLDivElement|null = document.querySelector('.sidebar-colored')
      const height: number = (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight);
      if(x != null) {
        
        if(x.offsetHeight < height)
          x.style.height =  height+'px'

        x.style.backgroundColor = colors[(Math.floor((Math.random() * 6)))];
      }
      
    },100)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.wrapper-clean {
  position: relative;
  .background-color-style {
    position: absolute;
    left: 0;
    right: 0;
    height: 250px;
    background-color: $blue;
    z-index: -1;
  }
  .footer-background-color-style {
    background-color: $blue;
    height: 75px;
    position: relative;
    z-index: -1;
    margin-top: -35px;
  }
  .label-by-footer {
    right: 0;
    bottom: 5px;
    position: absolute;
  }
}
</style>