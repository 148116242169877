
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class AreaAtuacao extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/areaatuacao', method: 'POST', headers: {} },
                fetch: { url: '/v1/areaatuacao/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/areaatuacao', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/areaatuacao/{id}', method: 'PUT', headers: {} }}));
        this.optionList = [];
    }

    public async loadOptions() {
        // const data: any = await this.fetchAll();
        const areaAtuacoes =  Object.keys(this.getI18n().messages.en.areaAtuacoes)
        if (areaAtuacoes.length > 0) {
            this.optionList = [
                {
                    text: '',
                    value: ''
                }
            ];
            areaAtuacoes.forEach((id: any) => {
                this.optionList!.push({
                    text: this.getI18n().t('areaAtuacoes.' + id),
                    value: id,
                });
            });
        }
    }


}


