<template>
  <b-card no-body>    
    <b-card-body>
      <b-form @submit.prevent="saveData">
        <b-row>
           <b-col md="12" >
            <b-table striped hover :items="items" :fields="fields" :busy="isBusy">
               <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(title)="row">
                <div class="text-left">
                  <div> {{row.value}} </div>
                  <span> {{$t('Nº of applications')}} ({{row.item.qtdCandidatos}})</span> <br />
                  <span> {{$t('Nº of Managers')}} ({{row.item.qtdGestores}})</span>
                </div>
              </template>
              <template #cell(gestores)="row">
                <b-button variant="outline-info" size="sm" @click="row.toggleDetails">
                  <i v-if="row.detailsShowing" class="fa fa-angle-down"></i>
                  <i v-else class="fa fa-angle-right"></i>
                </b-button>
              </template>

              <template #row-details="row">
                <b-list-group>
                  <b-list-group-item active class="text-center  py-1">
                    <strong>{{$t('Managers')}}</strong>
                  </b-list-group-item>
                  <b-list-group-item v-for="(value, key) in row.item.gestores" :key="key">
                    {{ value.nomeGestor}} 
                    <span v-if="value.s"> {{$t('Accessed in')}} ({{ value.viewAt}}) </span>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="mt-2">
                  <b-list-group-item active class="text-center py-1">
                    <strong>{{$t('Applications')}}</strong>
                  </b-list-group-item>
                  <b-list-group-item v-for="(value, key) in row.item.avaliados" :key="key" class="d-flex justify-content-between">
                    {{ value.nomeAvaliado}} 
                   
                    <b-button variant="outline-info" size="sm" @click="selectedApplication(value.candidato.primeironome)">
                      <i class="fa fa-eye"></i>
                    </b-button>
                  </b-list-group-item>
                </b-list-group>
              </template>
            </b-table>
          </b-col>
          
        </b-row>
      </b-form>
    </b-card-body>

  </b-card>
</template>

<script lang="ts">
import { CandidatoEntity, EmpresaEntity, RecrutadorEntity, VagaEntity,  GrupoAvaliacaoEntity, CandidaturaEntity, GrupoAvaliacaoGestorEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import GrupoAvaliacao from '@/core/Services/Empresa/GrupoAvaliacao';
import FormGestores from './FormGestores.vue';
import AvaliacoesProfissional from "@/components/avaliacao/AvaliacoesProfissional.vue"

@Component({
  components: {
    FormGestores,
    'avaliacoes': AvaliacoesProfissional
  }
})
export default class ListaGrupoAvaliacao extends Vue {

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: "profile" })
  public recrutador?: RecrutadorEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
    
  public avaliacao: GrupoAvaliacaoEntity = new GrupoAvaliacaoEntity();
  public service: GrupoAvaliacao;
  public promise: Promise<any>|null = null;
  public items: any[] = []
  public fields: any[] = []
  public isBusy: boolean = false;
  public sidebarEval: boolean = false;
  public candidato: CandidatoEntity|null = null;


  get enableMultiEval () {
    return (this.empresa?.configuracoes['ENABLE_MULTI_EVALUATION'] == true);
  }

  constructor(){
    super();
    this.service = new GrupoAvaliacao();
    this.avaliacao = new GrupoAvaliacaoEntity();
  }

  get isBlind() {
    return (this.vaga?.blind||0) == 1
  }

  public mounted() {
    this.isBusy = true;
    this.fields = [
      { key: 'title', label: this.$t('Title'), class: 'text-center' },
      { key: 'gestores', label: '', class: 'text-center' }
    ];
    
    this.service.fetchAll({ vguid: this.vaga.guid }).then((data: any) => {
      this.items = data['grupos'];
    }).finally(()=>{
      this.isBusy = false
    })
  }

  public selectedApplication(cid: any) {
    this.$emit('selectedApplication',cid)
  }

  public cleanForm() {
    this.avaliacao.guid = null;
    this.avaliacao.title = null;
    this.avaliacao.description = null;
  }

  public saveData() {
  }

}
</script>
