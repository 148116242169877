
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class EmpresaTamanho extends Repository {
  public optionList: any[];

  constructor() {
    super(new ApiRouter({
      fetch: { url: '/v1/empresa-tamanho/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/empresa-tamanho', method: 'GET', headers: {} }
    }));
    this.optionList = [];
  }

  public loadOptions() {
    this.fetchAll().then((data) => {
      if (data && data.empresaTamanhos) {
        this.optionList = [
          {
            text: this.getI18n().t('Selecione'),
            value: ''
          }
        ];
        data.empresaTamanhos.forEach((item: any) => {
          this.optionList!.push({
            text: item.descricao,
            value: item.id,
          });
        });
      }
    });
  }

}


