<template>
<b-container>
  <b-row class="pt-5">
    <b-col md="12">
      <div class="d-flex justify-content-center mb-3" v-if="!list">
          <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="primary"></b-spinner>
      </div>
    </b-col>
  </b-row>
  <transition name="fade">
  <b-row v-if="list" class="d-flex justify-content-center">

    <b-col v-for="(room,i) in list" :key="room.key" class="my-2 room-col">
      <b-card
        :header-text-variant="'white'"
        class="room-card"
      >
        <template v-slot:header>
          <b-row>
            <b-col class="d-flex align-items-center justify-content-start header-title">
              <div class="text-truncate">
                <div class="d-flex align-items-center mb-1">
                  <i class="fas fa-eye-slash mr-2" v-if="room.blind" />
                  <p v-b-tooltip.hover :title="room.label" class="my-0 d-inline-block text-truncate">{{room.label}}</p>
                </div> 

                <span class="room-date">
                  <small>{{ currentTime(room.createdAtDT.date) }}</small>
                </span>
              </div>
            </b-col>

            <b-col class="d-flex align-items-center justify-content-end header-status">
              <b-button
                pill
                class="btn-light power-off-button py-0 px-2 d-flex align-items-center"
                :class="{ 'text-success': room.status !== 'completed', 'text-white': room.status === 'completed', 'bg-danger': room.status === 'completed' }"
                v-b-tooltip.hover
                :title="room.status !== 'completed' ? $t('Close Room') : $t('Closed')"
                :disabled="room.status === 'completed'"
                @click="room.status != 'completed' && closeRoom(list[i],i)"
              >
                <small>
                  {{ room.status === 'completed' ? $t('Closed') : $t('Opened') }}
                </small>

                <span class="power-icon-wrapper ml-2"
                  v-if="currentlyClosingId !== room.id && room.status !== 'completed'"
                >
                  <b-icon icon="power" />
                </span>

                <b-spinner v-if="currentlyClosingId === room.id" small /> 
              </b-button>
            </b-col>
          </b-row>
        </template>

        <div class="d-flex flex-column mb-4">
          <b-button
            class="room-card-body-button mb-1"
            pill
            @click="participantsModal($event,room)"
          >
            <b-icon v-if="loadingRoom != room.id" icon="people-fill" class="mr-2" /> 
            <b-spinner v-else small class="mr-2" />

            <span>{{ $t('List talents') }}</span>
          </b-button>

          <b-button
            class="room-card-body-button"
            pill
            @click="showRecordingsModalFor(room)"
            :disabled="room.recordings.length <= 0"
          >
            <b-icon icon="camera-video-fill" class="mr-2" /> 
            <span>{{ $t('Watch recordings') }} ({{ room.recordings.length }})</span>
          </b-button>
        </div>

        <footer class="d-flex justify-content-between">
          <b-dropdown
            class="links-dropdown mr-2"
            :text="$t('Share room')"
            variant="outline-dark-info"
            v-if="room.status != 'completed'"
          >
            <b-dropdown-item @click="copyManagerLink($event,room.key,room.blind)">{{ $t('Copy manager`s link') }}</b-dropdown-item>
            <b-dropdown-item @click="copyCandidateLink($event,room.key,room.blind)">{{ $t('Copy talent`s link') }}</b-dropdown-item>
            <b-dropdown-item @click="copyAccessLink($event,room.key,room.blind)">{{ $t("Copy recruiter's link") }}</b-dropdown-item>
            <b-dropdown-item v-if="enableAnonima" @click="copyAnonymousRoomAccessLink($event,room.key,room.blind)">{{ $t("Anonymous Room") }}</b-dropdown-item>
          </b-dropdown>

          <b-button
            v-b-tooltip.hover
            :title="$t('Visit room')" 
            variant="dark-info"
            pill
            @click="goToRoom(room.key, room.blind)"
            v-if="room.status != 'completed'"
          >
            {{ $t('Visit room') }}
          </b-button> 
        </footer>
      </b-card>
    </b-col>

    <b-col md="12">
      <b-button pill variant="default" class="mb-4 mt-2 border-light"
        size="sm" block
        @click="fetchNext"
      >
          {{ $t("Show more") }} <i class="fa fa-angle-double-down"></i>
          <b-spinner small type="grow" v-show="loading" class="mr-1" ></b-spinner> 
      </b-button>
    </b-col>

    </b-row>
  </transition>
    <b-modal id="bv-recordingModal" hide-footer size="xl" :title="$t('Interview room recordings')">
      <div class="d-block text-center" v-if="currentRoom && currentRoom.recordings.length >0">
        <b-row>
          <b-col md="12" class="mb-2">
            <b-button v-b-tooltip.hover :title="$t('Link to management report')" variant="outline-info"  squared 
              @click="managerPublicLink($event,currentRoom)"> 
                <i class="fa fa-lock" /> {{$t('Link to management report')}}
            </b-button>
          </b-col>
          <b-col md="3">
            <b-list-group class="rounded-0">
              <b-list-group-item href="#" variant="light">{{$t('Select your Recording')}}</b-list-group-item>
              <b-list-group-item @click="currentRecodingIndex = i" v-for="(rec,i) in currentRoom.recordings" :key="i" href="javascript:void(0)" :active="currentRecodingIndex == i">
                {{rec.recName||rec.key}}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="9">
            <video-player :options="currentRecordingToPlay"  :blind="false" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="bv-participantsModal" hide-footer size="xl" :title="$t('Interview Room Talents')">
      <div class="d-block text-center" v-if="currentRoom">
        <b-row>
          <b-col md="12" class="mb-2">
            <b-button v-b-tooltip.hover :title="$t('Link to management report')" variant="outline-info" squared 
              @click="managerPublicLink($event,currentRoom)"> 
                <i class="fa fa-lock" /> {{$t('Link to management report')}}
            </b-button>
          </b-col>
          <b-col md="12" v-if="currentRoom.participants && currentRoom.participants.length > 0">            
            <b-table striped hover :fields="participantFields" :items="currentRoomParticipants">
              <template v-slot:cell(approved)="data">
                <i class="fa fa-check" v-if="data.value == 'x'" />            
              </template>
              <template v-slot:cell(action)="data">
                      
                <b-button variant="blue" class="text-uppercase border-white"  
                  v-if="enableMultiEval && data.item.guid"
                  v-b-tooltip.hover
                  v-b-toggle="'sidebar-avaliacao-'+data.item.guid"
                  :title="$t('View History of Reviews of this talent')"
                  >
                  <i class="icon-mensagens mr-2"></i>
                  {{$t("See reviews")}}
                </b-button> 
                <b-button variant="info" class="mx-1 text-uppercase border-white"  
                  v-if="data.item.guid"
                  @click="openHiddenProfile(data.item.guid, data.item.nickname)"
                  v-b-tooltip.hover
                  :title="$t('Anonymous Resume')"
                  >
                  <i class="fa fa-user-secret mr-2"></i>
                </b-button>
              
                <b-sidebar
                    v-if="enableMultiEval && data.item.guid"
                    :id="'sidebar-avaliacao-'+data.item.guid"
                    :title="$t('Reviews of {name}', {name: data.item.name})"
                    width="500px"
                    lazy
                  >
                  <div class="px-3 py-2">
                    <avaliacoes :candidato="data.value" :session="data.item.session" />
                  </div>
                </b-sidebar>
              </template>
            </b-table>
          </b-col>
          <b-col md="12" v-else>
            <b-alert variant="info">
              {{$t('No candidate found')}}
            </b-alert>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-container> 
</template>

<script lang="ts">
import AvaliacoesProfissional from "@/components/avaliacao/AvaliacoesProfissional.vue";
import VideoPlayer from "@/components/video/VideoPlayer.vue";
import PublicRoom from '@/core/Services/Video/PublicRoom';
import Room from '@/core/Services/Video/Room';
import RoomParticipant from '@/core/Services/Video/RoomParticipant';
import { siteCandidateEndPoint } from "@/core/endpoint";
import { CandidatoEntity, EmpresaEntity, RoomEntity } from '@/core/models/Entities';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";

@Component({
  components: {
    'video-player': VideoPlayer,
    'avaliacoes': AvaliacoesProfissional
  }
})
export default class RoomFolders extends Vue {
  public service!: Room;
  public publicRoom!: PublicRoom;
  public serviceParticipant!: RoomParticipant;
  public currentRoom: RoomEntity|null = null;
  public currentRecodingIndex: number|null = null;
  public list: any[]|null = null;
  public page: number = 0;
  public limit: number = 12;
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public currentlyClosingId: number|null = null
  public participantFields: any[] = [];
  public participantList: any[] = [];
  public isBusy: boolean = false;
  public loading: boolean = false;
  public loadingRoom: number = 0;
  

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;
  
  constructor() {
    super();
    this.service = new Room();
    this.serviceParticipant = new RoomParticipant();
    this.publicRoom = new PublicRoom();
  }

  public mounted() {
    this.fetchNext();
    this.participantFields = [     
      { key: 'name', label: this.$t('Name'), class: 'text-center' },
      { key: 'email', label: this.$t('E-mail'), class: 'text-center' },
      { key: 'telefone', label: this.$t('Telephone'), class: 'text-center' },
      { key: 'nickname', label: this.$t('Nickname'), class: 'text-center' },
      { key: 'approved', label: this.$t('Revealed'),class: 'text-center' },
      { key: 'action', label: ' - ',class: 'text-center' },
    ]
  }

  public fetchNext() {
    this.page++;
    this.loadList();
  }   

  public currentTime(data: any) {
    // return this.$showDateFromObject(data);
    //console.log(data);
    //return this.$showDateResponse(data);

    return moment(data).format("DD/MM/YYYY HH:mm");
    
  }

  public durationInMin(duration: number) {
    return `${Math.round(duration/60)} min`
  }

  public sbShow() {
    console.log('fetchdata');
    (this.$refs.sidebarAvaliacao as any).fetchData()
  }

  public sbHidden() {
    console.log('hideside');
  }

  public openHiddenProfile(guid: string, name: string) {
    let perfil = this.$router.resolve({
      name: "HiddenTalentInfoPage", params: {guid}, query: {lang: this.$i18n.locale, n: btoa(name||"") }
    });
    window.open(perfil.href, '_blank')
  }

  get enableMultiEval () {
    return (this.empresa?.configuracoes['ENABLE_MULTI_EVALUATION'] == true);
  }

  get enableSSOLinks () {
    return (this.empresa?.configuracoes['ENABLE_SSO_LINKS'] == true);
  }

  get enableAnonima () {
    return (this.empresa?.configuracoes['ENABLE_ANONIMA'] == true);
  }


  
  get currentRoomParticipants() {
    if (this.currentRoom && this.currentRoom.participants.length > 0) {
      let arr: any[] = [];
      const _: any = this;
      let aux: any|null = null;
      this.currentRoom.participants.forEach((p) => {
        try{
          let nickname: string = "";
          let nicknameArr: string[] = [];
          aux = JSON.parse(p.client_datas)
          for (let idx = 0; idx < aux.length; idx++) {
            if (aux[idx] != null && aux[idx].clientData) {
              nickname = aux[idx].clientData;
              if(nicknameArr.indexOf(nickname) == -1)
                nicknameArr.push(nickname);
            }
          }
          let ca: CandidatoEntity|null = null;
          if(p.candidato) {
            ca = new CandidatoEntity();
            ca.guid = p.candidato;
          }
          const nome_candidato: string = (this.currentRoom?.blockApprove ? "" : p.nome_candidato)||"";
          arr.push({ 
            name: nome_candidato||p.nome_recrutador||p.nome_empresa, 
            email: p.email, 
            telefone: p.telefone, 
            nickname: nicknameArr.join(', ')||'' , 
            approved: (parseInt(p.approved_) > 0 ? 'x' : ''), 
            action: ca,
            guid: p.candidato,
            session: p.session_id
          });
        }catch(e) {
          console.log('erro no parse')
        }
      });
      return arr;
    }
    return null
  }

  get currentRecordingToPlay() {
    if (!this.currentRoom)
      return null;

    const url = this.currentRoom.recordings[this.currentRecodingIndex||0].url;
    return {
      videoURLMP4: url,
      audioDistorcedURLWAV: url,
      autoplay: false,
      controls: true,
      sources: [
        {
          src: url,
          type: "video/mp4"
        }
      ]
    }
  }

  public managerPublicLink(e: any, room: RoomEntity) {
    if (room)
      this.publicRoom.createLink(room.id).then((data) => {
        // TODO console.log
        console.log(data)
        if (data && data.lkn)
          this.$copyText(data.lkn,e.target).then(this.onCopy).catch(this.onError)
        else
          this.onError()
      }).catch(this.onError)
  }

  public async participantsModal(e: any,room: RoomEntity) {
    const _this: any = this;
    this.currentRoom = room;
    this.loadingRoom = room.id;
    const list: any = await this.serviceParticipant.fetchAll({
      rid: room.id
    })
    if (list && list.length > 0 && list[0].session_id) {
      this.currentRoom.participants = list
      this.$bvModal.show('bv-participantsModal')
    } else {
      _this.toastError('No participant found')
    }
    this.loadingRoom = 0;
  }

  public loadList() {
    this.loading = true;
    this.service.fetchAll({
      page: this.page,
      limit: this.limit
    }).then((data) => {
      if (!this.list)
        this.list = [];

      this.list = this.list.concat(data.rooms);
      
    }).finally(() => {
      this.loading = false;
    })
  }
  
  public copyAnonymousRoomAccessLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.anonymousRoomLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  
  public copyAccessLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.accessLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  
  public copyCandidateLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.candidateLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  
  public copyManagerLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.managerLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }

  public goToRoom(key: string, blind?: boolean) {
    window.open(this.accessLink(key,blind), '_blank');
  }
  
  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }
    
  public candidateLink(key: string,blind?: boolean) {
    return this.URLcandidato + "/sala-entrevista/" + key + "?b="+(!!blind);
  }
  public anonymousRoomLink(key: string,blind?: boolean) {
    const url = this.URLcandidato + "/p/sala-entrevista/" + key + "?b="+(!!blind);
    const sprURL = (window['jbprod'] == true) ? `https://spr.jobecam.com/sp/${this.empresa?.guid}` : `https://spr-hom.jobecam.com/sp/${this.empresa?.guid}`;
    if(this.enableSSOLinks)
      return `${sprURL}?redirectTo=${encodeURIComponent(url)}`

    return url
  }
  public managerLink(key: string,blind?: boolean) {
    return this.URL + "/emp/sala-entrevista/" + key + "?b="+(!!blind);
  }
  public accessLink(key: string,blind?: boolean) {
    return this.URL + "/room/" + key + "?b="+(!!blind) + "&lang=" + this.$i18n.locale;
  }

  public async closeRoom(room: RoomEntity, index: number) {
    this.currentlyClosingId = room.id;
    await this.service.close(room.id).then(_ => {
      room.status = 'completed';
    });
    this.currentlyClosingId = null;
    if (this.list) {
      const a = this.list[index];
      this.list.push(a);
      this.list.splice(index,1)
    }
  }

  public showRecordingsModalFor(room: RoomEntity) {
    this.currentRoom = room;
    this.$bvModal.show('bv-recordingModal')
  }

}
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .room-col {
    flex: 0 0 33% !important;
    max-width: 33% !important;

    @media screen and (max-width: 1100px) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }

    @media screen and (max-width: 780px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .room-card {
    $roomCardBorderRadius: 10px;
    border-radius: $roomCardBorderRadius;

    box-shadow: 0px 4px 25px rgba(100, 100, 100, 0.50);

    .card-header {
      border-radius: $roomCardBorderRadius $roomCardBorderRadius 0 0;

      .header-title {
        width: 66% !important;
      }

      .header-status {
        width: 34% !important;
      }
    }

    footer {
      button, .dropdown {
        flex: 1;
      }

      @media screen and (max-width: 1220px) {
        flex-direction: column;

        .links-dropdown {
          margin-right: 0 !important;
          margin-bottom: 4px;
        }
      }
    }

    .card-header span.room-date {
      border: 1px solid #fff;
      border-radius: 50rem !important;

      color: #fff;

      padding: 1px 8px;
    }

    .card-header {
      background: $blue;
    }

    span.power-icon-wrapper {
      background: $green;
      border-radius: 100%;
      width: 17px;
      height: 17px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $white;
        height: 0.9rem;
      }
    }

    .power-off-button[disabled] {
      opacity: 1;
    }

    .room-card-body-button {
      background: rgba($dark-info, 0.15) !important;
      color: $dark-info !important;
      flex: 1;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }

    .room-card-body-button[disabled] {
      opacity: 0.6 !important;
    }

    .links-dropdown button {
      border-radius: 50rem !important;
    }
  }
</style>