<template>
  <div class="box-container d-flex flex-column">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ReportPage extends Vue {

  
}
</script>