<template>
  <modal :show="show" :id="id" :title="title" :size="'lg'">
    <b-form-group>
      <b-row>
        <b-col md="6">
          <label for="input-date-interview">{{ $t('Interview Date') }}</label>
          <b-form-datepicker
            :id="'input-date-interview'"
            class="input-date-interview"
            v-model="dateInterview"
            value-as-date
            :locale="$i18n.locale"
            :placeholder="'dd/mm/yyyy'"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          ></b-form-datepicker>
        </b-col>
        <b-col md="6">
          <select-input 
            :label="$t('Timezone')" 
            :optionList="tzlist"
            v-model="timezone"
            class="initial-time"
          />
        </b-col>
        <b-col md="6">
          <select-input 
            :label="$t('Start Time')" 
            :optionList="timeList"
            v-model="horaInicio"
            class="initial-time"
          />
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('Average Time')" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              button-variant="outline-primary"
              id="btn-radios-minutes"
              v-model="minutosTotais"
              :options="minutesList"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-minutes"
              buttons
              @change="horaFim = sumMinutes(horaInicio,minutosTotais)"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <text-input 
            variant="dark"
            :label="$t('Comment')"
            :placeholder="$t('Interview with candidate')"
            v-model="obs"
          />
        </b-col>
      </b-row>
    </b-form-group>
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="saveInterviewDate"
          v-promise-btn="{promise}"
          v-if="!registryId"
        >
          {{$t("Save")}}
        </b-button>
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="editInterviewDate"
          v-promise-btn="{promise}"
          v-else
        >
          {{$t("Edit")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import EntrevistaAgenda from '@/core/Services/Calendar/EntrevistaAgenda';
import TimezoneHelper from "@/core/helper/TimezoneHelper";
import { CandidaturaEntity, VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from './Modal.vue';

@Component({
  components: {
    Modal,
  }
})
export default class ModalCreateInterviewEvent extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({ default: null })
  public registryId!: number;

  public obs: string = '';
  public vagaId!: number;
  public candidatoId!: number;
  public agendaSRV!: EntrevistaAgenda;
  public timeList: any[] = [];
  public minutesList: any[] = [];
  public minutosTotais: any = 15;
  public dateInterview: Date | null = null;
  public horaInicio: string = '';
  public horaFim: string = '';
  public promise: Promise<any>|null = null;

  public tzlist: any = null;
  public timezone: string = "";

  constructor() {
    super();
    this.agendaSRV = new EntrevistaAgenda()
  }

  get candidatoNome() {
    return this.candidatura.candidato?.primeironome||this.candidatura.id
  }

  get title() {
    if(!this.registryId)
      return this.$t('Schedule interview with') + ' ' + this.candidatoNome

    return this.$t('Schedule interview')
  }

  public async mounted() {
    this.tzlist = (await TimezoneHelper.tzlist()).timezones;
    this.timezone = await TimezoneHelper.GuessTimezone();
  }
  
  public created() {
    for (let index = 1; index <= 24; index++) {
      this.timeList.push(
        {
          text: `${index}:00`,
          value: `${index}:00`
        },
        {
          text: `${index}:30`,
          value: `${index}:30`
        }
      )
    }
    for (let j = 1; j <= 4 ; j++) {
      this.minutesList.push(
        {
          text: `${j*15} min`,
          value: `${j*15}`
        }
      )
    }
  }

  public sumMinutes(t1: string,minutes: string){
    let splitTime1 = t1.split(':');

    let hour = parseInt(splitTime1[0]);
    let minute = parseInt(splitTime1[1])+parseInt(minutes);
    hour = Math.floor(hour + minute/60);
    minute = Math.floor(minute%60);
    return hour.toString().padStart(2,'0')+':'+minute.toString().padStart(2,'0');
  }

  

  public saveInterviewDate() {
    let horaInicio: string = this.horaInicio ? this.horaInicio : '';
    let horaFim: string = this.horaFim ? this.horaFim : '';
    if(this.minutosTotais != null) {
      horaFim = this.sumMinutes(horaInicio,this.minutosTotais);
    }
    let data = this.dateInterview ? this.dateInterview : '';
    let obs = this.obs ? this.obs : '';
    this.promise = this.agendaSRV.create({
      dataInicio: this.$dateUTCDB((this.dateInterview || (new Date)), horaInicio),
      dataFim: this.$dateUTCDB((this.dateInterview || (new Date)), horaFim),
      obs: obs,
      vagaId: this.vaga.id,
      candidatoId: this.candidatura.candidato.id,
      timezone: this.timezone,
    }).then((d: any) => {
      this.successAlert(this.$t('Invitation sent successfully').toString())
      this.$bvModal.hide(this.id)
      this.$emit('success',d)
    }).catch((err: any) => {
      console.log(err)
    })
  }
  
  public editInterviewDate() {
    let horaInicio: string = this.horaInicio ? this.horaInicio : '';
    let horaFim: string = this.horaFim ? this.horaFim : '';
    if(this.minutosTotais != null) {
      horaFim = this.sumMinutes(horaInicio,this.minutosTotais);
    }
    let data = this.dateInterview ? this.dateInterview : '';
    let obs = this.obs ? this.obs : '';
    this.promise = this.agendaSRV.update({
      id: this.registryId,
      dataInicio: this.$dateUTCDB((this.dateInterview || (new Date)), horaInicio),
      dataFim: this.$dateUTCDB((this.dateInterview || (new Date)), horaFim),
      obs: obs,
      timezone: this.timezone,
    }).then((d: any) => {
      this.successAlert(this.$t('Invitation sent successfully').toString())
      this.$bvModal.hide(this.id)
      this.$emit('success',d)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(this.$t('Success').toString());
    this.$toast.open();
  }
}
</script>

<style lang="scss">
  .initial-time, .final-time{
    label {
      color: black;
    }
  }
</style>