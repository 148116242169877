
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class JobExam extends Repository {

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/questionary-job', method: 'POST', headers: {} },
      fetch: { url: '/v1/questionary-job/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/questionary-job', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/questionary-job/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/questionary-job/{id}', method: 'DELETE', headers: {} },
    }));
  }


}


