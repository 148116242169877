<template>
  <div class="sound-animation-wrapper" v-show="user.speaking">
    <div class="animation-container">
        <div :class="{'sound-container': true, 'animate': user.speaking}">
          <div class="rect-1"></div>
          <div class="rect-2"></div>	
          <div class="rect-3"></div>
        </div>
    </div>
	</div>
</template>


<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';

@Component
export default class SpeakingBox extends Vue {
  
  @Prop({default: null})
  public user!: UserModel;

}
</script>


<style lang="scss" src="@/assets/css/live-interview/speaking-box.scss"></style>