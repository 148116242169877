<template>
  <div class="card-curso box-container d-flex flex-column mb-5">
    <br/> 
    <plural-header/>

    <div class="title"><h1>{{curso.titulo}}</h1></div>


    <b-row>
      <b-col class="mb-2" md="6">
        <b-img :src="curso.arquivo" alt="Imagem curso" ></b-img>
        <div v-html="curso.texto"> </div>
      </b-col>
      <b-col class="mb-2" md="6">
        
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1" v-for="(aula, i) in aulas" :key="i">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <!-- <b-button block v-b-toggle.accordion-1 variant="info">{{aula.title}}</b-button> -->
              <b-button block @click="ToggColapse(i)" variant="orange">{{aula.title}}</b-button>
            </b-card-header>
            <b-collapse :id="'accordion'+i" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="card-video__content">
                  <iframe 
                    width="100%" height="280" :src="getURL(aula.url)"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen 
                    >
                  </iframe>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import PluralHeader from '@/views/plural/PluralHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PluralCursoView extends Vue {
  public service: PluralPostService;
  public curso: any = null;
  public url: any = null;
  public loadingPage:boolean = false;
  public aulas: any = null;

  constructor() {
    super();
    this.service = new PluralPostService(); 
  }  

  public async mounted() {
    this.loadingPage = true;

    this.curso = this.$route.params.post;
    console.log(this.curso)
    if(this.curso == undefined || this.curso == null){
      this.url = this.$route.params.url;
      this.service.fetchAll({s:this.url}).then((data: any) => {
        console.log(data);
        this.curso = data['pluralPosts'][0];
        this.aulas = JSON.parse(data['pluralPosts'][0]['cursos']);
        this.curso.reverse();
      }).catch((err) => { 
        console.log(err) 
      }).finally(()=> {
        this.loadingPage = false;
      })
    }  
  }  

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }

  public ToggColapse(id:any){
    this.$root.$emit('bv::toggle::collapse', 'accordion'+id);
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
  .header-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .card-curso{
    .title {
      text-align: center;
      padding-bottom: 10px;
    }
  }
</style>