<template>
  <fieldset class="question-container" @keyup.esc="isEditable = false">
    <legend class="question-container__legend">  </legend>
    <b-row>
      <b-col md="12" class="bg-light border-bottom pt-3">
        <b-row no-gutters>
          <!-- Iniciando definição de questão -->
          <b-col md="12" v-show="!isEditable || evaluation" @click="isEditable = true" class="question-container__description my-1 py-2">
            <p class="mb-0"> {{ questionDescription }} </p>
            <small v-if="evaluation && value.questionTypeId < 3">({{value.points}} pts)</small>
          </b-col>
          <b-col md="12" v-show="isEditable && !evaluation">
            <form @submit="doSubmit" >
              <b-row>
                <b-col md="8">
                  <text-input
                      variant="dark"
                      :required="false"
                      :placeholder="$t('Theoretical Mathematics Test')"
                      v-model="value.description"
                      @input="update"
                      :maxlength="1024"
                    />
                  </b-col>
                <b-col md="4" v-if="value.id>0">
                  <label class="hidden-upload" :for="'hidden-upload-q-'+qIndex">
                    <b-form-file
                      :id="'hidden-upload-q-'+qIndex"
                      accept="image/*"
                      :placeholder="$t('Add Image')"
                      drop-placeholder="Drop image here"
                      @change="handleImageChange"
                    ></b-form-file>
                  <i class="fa fa-plus" /> &nbsp;  {{ $t('Add Image') }}
                  </label>
                </b-col>
              </b-row>
            </form>
          </b-col>
          <b-col md="12" v-if="value.images && value.images.length > 0"> 
            <b-container fluid class="p-2">
              <b-row class="justify-content-center">
                <b-col md="2" v-for="(img,index) in value.images" :key="img.id" class="text-center">
                  <b-button variant="danger" block size="sm" @click="removeImage(img.id)"  v-if="isEditable && !evaluation">
                    <i class="fa fa-times"></i> {{$t('Delete')}}
                  </b-button>
                  <b-img thumbnail fluid :src="img.url" :alt="'Image '+ index" @click="openImageModal(img.url)"></b-img>
                  <p class="my-1"> <i>{{$t('Figure')}} {{qIndex+1}}.{{index+1}} </i> </p>
                </b-col>
              </b-row>
            </b-container>
          </b-col>

        </b-row>
      </b-col>

      <!-- INICIO DAS RESPOSTAS -->
      <b-col md="12" v-show="value.questionTypeId < 3 || evaluation" v-for="(answer,index) in value.answers" v-bind:key="index">
        <form-answer v-model="value.answers[index]" @input="update" :a-index="index" :q-index="qIndex" 
          :question-type-id="value.questionTypeId" 
          :evaluation="evaluation" 
          :answers="answers"
          :points="value.points"
          @pointGiven="pointGiven"
          @openImageModal="openImageModal"
          @removeAnswer="removeAnswer" />
      </b-col>
      <b-col md="12" class="pt-2" v-show="value.questionTypeId > 2 && !evaluation">
        <textarea-input 
            variant="dark"
            :required="false"
            :label="''"
            :disabled="evaluation" />
      </b-col>
    </b-row>
    <b-row v-if="!evaluation" no-gutters class="mt-1 border-top" style="margin:0 -15px;min-height:60px;">
      <b-col md="1" class="d-flex">
        <b-button size="sm"  variant="danger" class="rounded-0 " block @click="removeQuestion"> 
          <i class="fa fa-times"></i> {{$t('Delete')}}
        </b-button>
      </b-col>
      <b-col md="1" class="d-flex ml-1 mr-3" v-if="value.questionTypeId < 3">
        <b-button size="sm"  variant="primary" class="rounded-0 " block @click="addAnswer"> 
          <i class="fa fa-plus"></i> {{$t('Option')}}
        </b-button>
      </b-col>
      <b-col class="mx-1" md="1"  v-if="value.questionTypeId < 3">
        <text-input
            variant="light"
            type="number"
            :required="false"
            class="mb-1"
            :label="$t('Points')"
            :placeholder="'1'"
            v-model="value.points"
            @input="update"
          />
      </b-col>
      <b-col md="11" class="d-flex" v-else>
        <b-alert class=" w-100 mb-0" style="line-height:37px;" show variant="warning"> 
         <i class="fas fa-exclamation-triangle"></i> {{$t('Descriptive questions will not be considered in the applicant`s assessment')}}
        </b-alert>
      </b-col>
      <b-col md="3" class="mx-1" v-if="value.questionTypeId < 3" >
        <select-input 
          :optionList="questionTypes"
          variant="light"
          class="mb-1 float-left"
          :label="$t('Type')"
          v-model="value.questionTypeId"
          :value="value.questionTypeId"
          @change="update"
          style="width:80%"
        />
        <b-button id="button-blind" variant="light" pill size="sm" class="p-0" > <i class="icon-ajuda" /> </b-button>
        <b-tooltip target="button-blind" placement="top">
          <p> <strong>{{$t('Multiple choice')}}:</strong> {{$t('Candidate can select several options, the score is divided between the options and when calculating the results, a wrong answer eliminates a correct one')}}</p>
          <p> <strong>{{$t('Unique choice')}}:</strong> {{$t('Candidate can select one option only')}} </p>   
        </b-tooltip>
      </b-col>
    </b-row>
  </fieldset>
</template>

<script lang="ts">
// List Company evaluations
import ExamImage from '@/core/Services/Exams/ExamImage';
import { AnswerEntity, QuestionEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormAnswer from './FormAnswer.vue';

@Component({
  components: {
    FormAnswer
  }
})
export default class FormQuestion extends Vue {
  
  @Prop({default: () => {new QuestionEntity()}})
  public value!: QuestionEntity;
  
  @Prop({ default: 0 })
  public qIndex!: number;

  @Prop({default: false})
  public evaluation!: boolean

  @Prop({default: {}})
  public answers!: any

  public questionTypes: any[] = [
    { text: this.$t('Select'), value: '' }
  ];

  public isEditable: boolean = false;
  public descricao: string  = '';
  public currentImgURL: string  = '';
  public imageService: ExamImage|null = null;

  constructor() {
    super();
  }

  get firstAnswerDescriptionFromQuestion() {
    if (this.value.answers && this.value.answers.length > 0) {
      if (this.answers && this.answers.length > 0 && this.answers[this.value.answers[0].id] !== undefined) {
        return this.answers[this.value.answers[0].id].description;
      }     
    }      
      return '';
  }

  get questionDescription() {
    return `${this.qIndex+1}. ${this.value.description}`;
  }

  public pointGiven(answerId: number, questionaryAnswerCandidateId: number, points: number, candidaturaStatus: string ) {
    this.$emit('pointGiven',answerId,questionaryAnswerCandidateId,points,candidaturaStatus);
  }


  public openImageModal(url: string) {
    this.$emit('openImageModal',url)
  }

  public handleImageChange(event: any) {
    if (this.value.id >0 ) {
      this.imageService = new ExamImage();
      const file: any = event.target.files[0];
      if (file) {
        this.imageService.saveQuestion(this.value.id+"", file).then((d: any) => {
          this.$alert.title = " ";
          this.$alert.addMsg(this.$t("Saved successfully").toString());
          this.$alert.callBoxCheckWithTitle(this.$t("Ok").toString());
          if (!this.value.images) 
            this.value.images = [];
            
          this.value.images?.push(d);
        })
        .catch((d: any) => {
        })
        event.target.value = '';
      }
    } else {
      this.$toast.variant = 'danger';
      this.$toast.addMsg(this.$t('You must save this test to perform this action'))
      this.$toast.addTitle(this.$t('Oops').toString());
      this.$toast.open();
    }
  }
  public removeImage(id: number) {
    if (confirm(this.$t('Are you sure you want to delete this image?').toString())) {
      this.imageService = new ExamImage();
      if (id > 0) {
        this.imageService.delete({id, question: '1'}).then((d: any) => {
          this.$toast.variant = 'success';
          this.$toast.addMsg(this.$t('Deleted Successfully'))
          this.$toast.addTitle(this.$t('Success').toString());
          this.$toast.open();
          if (this.value.images) {
            const index: number = this.value.images.findIndex((a: any) => {
              return a.id == id;
            });
            if (index> -1)
              this.value.images?.splice(index,1);
          }
        })
        .catch((d: any) => {
        })
      }
    }
  }

  public mounted() {
    if (!this.value.description) {
      this.value.description = this.$t('Question').toString() + ' ' + (this.qIndex+1);
    } 
    if (this.value.questionTypeId < 3) {
      this.questionTypes.push({ text: this.$t('Multiple choice'), value: 1 });
      this.questionTypes.push({ text: this.$t('Unique choice'), value: 2 });
    }
    if (this.value.questionTypeId > 2) {
      this.questionTypes.push({ text: this.$t('Description'), value: 3 });
    }
    this.descricao = this.value.description;
  }

  public doSubmit(event: any) {
    // this.value.description = this.descricao;
    this.isEditable = false;
    this.update();
    event.preventDefault();
    return false;
  }
  

  public update() {
    this.$emit('input', this.value)
  }

  public removeQuestion() {
    this.$emit('removeQuestion', this.qIndex)
  }

  public removeAnswer(aIndex: number) {
    if (this.value.answers[aIndex]) {
      if (!this.value.removedAnswers) {
        this.value.removedAnswers = []
      }
      if (this.value.answers[aIndex].id > 0)
        this.value.removedAnswers.push(this.value.answers[aIndex])
      this.value.answers.splice(aIndex,1);
    }
  }


  public addAnswer() {
    this.value.answers.push(new AnswerEntity());
    this.$emit('input', this.value)
  }

}
</script>

<style lang="scss">
</style>