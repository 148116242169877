// tslint:disable:no-string-literal
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { RepescagemEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';


export default class Repescagem extends Repository {
    public entidade: RepescagemEntity;
    public listaEspera: RepescagemEntity[] = [];

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/repescagem', method: 'POST', headers: {} },
            delete: { url: '/v1/repescagem/{id}', method: 'DELETE', headers: {} },
            fetch: { url: '/v1/repescagem/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/repescagem', method: 'GET', headers: {} }, // tem parametro de Busca "s"
            update: { url: '/v1/repescagem/{id}', method: 'PUT', headers: {} }}));
        this.entidade = new RepescagemEntity();
    }

    public fetchFromVaga(vagaId: number): Promise<any> {
        return super.fetchAll({vagaId}).then((data: any) => {
            this.listaEspera = data['reserva'];
        });
    }
}
