<template>
  <!-- Tabs -->
  <div class="tab">
    <div class="box-search">
      <form class="search" @submit.prevent="doSearch">
        <label for="input-search-1" class="search__wrapper">
          <i class="search__wrapper__prefix fas fa-search"></i>
          <input
            for="input-search-1"
            class="search__wrapper__input"
            type="search"
            :placeholder="$t('Search')"
            v-model="searchRecebidas"
          />
        </label>
      </form>
    </div>
    <div class="inbox_chat">
      <div class="chat_list" 
       @click="setCurrRootMessage(message)"
        v-for="message in rootMessages"
        :key="message.id"
        :class="{'active_chat': currRootMessage && message.id == currRootMessage.id}">
        <div class="chat_people px-2 py-3">
          <!-- <div class="chat_select">
            <checkbox-input></checkbox-input>
          </div> -->
          <div class="chat_img">
            <img
              v-if="message.candidato && message.candidato.fotoAvatar"
              :src="message.candidato.fotoAvatar" 
              alt="Avatar Usuario"
            />
            <img src="/static/img/avatar.png" alt v-else/>
            
          </div>
          <div class="chat_ib">
            <div class="chat_date">{{showDate(message.createdAtObject)}}</div>
            <h5>
              {{ message.assunto }}
            </h5>
            <p>{{ message.candidato.primeironome||$t("Professional")  }}</p>
            <i class="icon-lixo"></i>
          </div>
        </div>
      </div>
      <infinite-loading :identifier="infiniteId" spinner="waveDots" @infinite="infiniteHandler">
        <b-alert slot="no-more">{{ $t("Sorry, this is all we got") }}</b-alert>
        <b-alert slot="no-results">{{$t("No messages found")}}</b-alert>
      </infinite-loading>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SearchInput from '@/components/search/Search.vue';
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import MessageJob from "@/core/Services/Basic/MessageJob";
import { MessageJobEntity, EmpresaEntity, CandidatoEntity } from '@/core/models/Entities';
import InfiniteLoading from "vue-infinite-loading"

@Component<ListaMensagens>({
  components: {
    'search-input': SearchInput,
    CheckboxInput,
    TextInput,
    'infinite-loading': InfiniteLoading
  }
})
export default class ListaMensagens extends Vue {
  public messageJobService: MessageJob = new MessageJob;
  public mobileIsShowMessages = false;
  public rootMessagesCache: MessageJobEntity[]|null = null;
  public rootMessages: MessageJobEntity[]|null = null;
  public containedIds: number[]|null = null;
  public currRootMessage: MessageJobEntity|null = null;
  public page: number = 1;
  public infiniteId: number = (new Date().getTime());

  public searchRecebidas = '';
  public searchEnvidas = '';
  public sendMessage = '';

  public mounted() {
    this.rootMessages = this.rootMessagesCache = [];
    this.containedIds = [];
    this.loadMessages(null);
  }

  public loadMessages($state: any|null) {
    const dataSearch: any = {
      search: this.searchRecebidas,
      page: this.page
    }
    this.messageJobService.fetchAll(dataSearch).then(data => {
      if (data) {
        data.mensagens.forEach((element: MessageJobEntity) => {
          if (this.containedIds!.indexOf(element.id!) == -1) {
            this.containedIds!.push(element.id!)
            this.rootMessages!.push(element)
          }
        });
        this.rootMessagesCache = this.rootMessages;
        if ($state) {
          $state.loaded();
        }
      if (!data!.mensagens!.length) {
        $state.complete();
      }
      } else {
        $state.complete();
      }
    });
  }

  public newSearch(): Promise<any> {
    const dataSearch: any = {
      search: this.searchRecebidas,
      page: 1,
      limit:100,
    }
    return this.messageJobService.fetchAll(dataSearch).then(data => {
      if (data) {
        data.mensagens.forEach((element: MessageJobEntity) => {
          if (this.containedIds!.indexOf(element.id!) == -1) {
            this.containedIds!.push(element.id!)
            this.rootMessagesCache!.push(element)
          }
        });
      }
    });
  }

  public infiniteHandler($state: any)
  {
    this.page++
    this.loadMessages($state);
  }

  public setMessagesList() {
    this.mobileIsShowMessages = false;
  }


  public setCurrRootMessage(message: MessageJobEntity) {
    this.mobileIsShowMessages = true;
    this.currRootMessage = message;
    this.$emit('select', message)
  }
  
  public async doSearch(value: string, request: boolean = true) {
    if (this.searchRecebidas && this.searchRecebidas != '') {
      const listFilter = (<Array<MessageJobEntity>>this.rootMessagesCache).filter((msg: MessageJobEntity) => (msg.assunto+msg.candidato!.primeironome+msg.candidato!.sobrenome).toLowerCase().indexOf(this.searchRecebidas.trim().toLowerCase()) > -1);

      if (listFilter) {
        this.rootMessages = listFilter;
      }
      if (request) {
        await this.newSearch()
        this.doSearch('',false)
      }
    } else {
      this.rootMessages = this.rootMessagesCache;
    }
  }

  public showDate(data: any) {
    return this.$showDateFromObject(data)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

img {
  max-width: 100%;
}
.chat_people {
  display: flex;
  align-items: center;
  overflow: hidden;
  clear: both;
  border-bottom: 1px transparent solid;
  cursor: pointer;

  &:hover {
    background: #f8f8f8;
    border-bottom: 1px #cccccc solid 
  }
  .chat_select {
    float: left;
    width: 6%;
    margin-right: 3%;
    .form-group {
      margin: 0 !important;
    }
  }
  .chat_img {
    width: 11%;
  }

  .chat_ib {
    width: 89%;
    padding: 0 0 0 1em;
    h5 {
      font-size: 1rem;
      color: $font-title-dark-gray;
      margin: 0 0 0.4em 0;
    }
    p {
      font-size: 0.9rem;
      color: $font-title-light-gray;
      margin: auto;
    }
    i {
      display: flex;
      justify-content: flex-end;
      font-size: 1rem;
    }
    .chat_date {
      font-size: 0.8rem;
      text-align: right;
    }
  }
}
.chat_list {
  margin: 0;
}
.active_chat {
  background: #20c99721;
  border-bottom: 1px $teal solid 
}
.tab {
  height: 100%;
  .box-search {
    padding: 1em 2em 0em 2em;
    .search {
      max-width: 100% !important;
    }
  }
  .inbox_chat {
    height: calc(100% - 50px);
    overflow-y: scroll;

  }
}

// Media Queries
@include media(">desktop", "<large") {
  .main {
    font-size: 0.7em;
  }
}

@include media("<desktop") {
  .main {
    font-size: 1em;
  }

  .tab {
    height: calc(100% - 85px);
  }

  .content-messages {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: calc(100vh - 90px);
  }
  .main-messages {
    font-size: 0.7em;
    padding: 0;
    .messaging {
      margin: 0;
    }

    .inbox_people {
      border-radius: 0;
      h1 {
        font-size: 3em;
      }
    }
  }
  .inbox_people {
    width: 100%;
    .mesgs-header {
      .go-to-messages {
        display: block;
      }
    }
  }
  
}
</style>
