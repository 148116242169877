<template>
  <div class="box-container d-flex flex-column mb-5">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-md-left text-center">{{ $t("Edit Recruiter") }}</h1>
      </div>
    </div>
    <div class="box-formulario d-flex align-items-center justify-content-center">
        <recrutador-form ref="rForm" @save="edit" :promise="promise" /> 
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import RecrutadorForm from './RecrutadorForm.vue';
import { RecrutadorEntity } from '@/core/models/Entities';
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';

@Component<EditRecrutador>({
  components: {
    RecrutadorForm,
  }
})
export default class EditRecrutador extends Vue {
  public service: GestaoRecrutador = new GestaoRecrutador();
  public recrutador!: RecrutadorEntity;
  public promise: any|null = null;

  public created() {
    // console.log(this.$route.params.id)
    if (!isNaN(parseInt(this.$route.params.id))) {
      this.service.fetch(+this.$route.params.id).then((data: RecrutadorEntity) => {
        this.recrutador = data;
        (<RecrutadorForm>this.$refs.rForm).setRecrutador(this.recrutador)
      });
    } 
    // TODO: tratar quando não tem id
  } 

  public edit(recrutador: RecrutadorEntity) {
    if (!recrutador.senha)
      delete recrutador.senha;

    delete recrutador.fotoAvatar;
      
    this.promise = this.service.update(recrutador).then((data) => {
      this.$router.push({path:'/profile', hash: "#pr2", query: {lang: this.$i18n.locale} });
    });
    // TODO: tratar se der erro ao salvar recrutador
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
.box-container {
  min-height: calc(100vh - 55px - 32px);
  .box-formulario {
    > div {
      width: calc(50% - 1em);
    }
    .foto-perfil {
      img {
        width: 6em;
        border-radius: 50%;
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>