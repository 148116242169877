<template>
    <div class="videos-questions">
      <transition name="slide" appear v-if="vaga">
        <b-tabs content-class="mt-3" >
          <b-tab :title="$t('Interview')" active>
            <video-interview :candidatura="candidatura" :is-public="isPublic" :vaga="vaga"  :token="token" />
          </b-tab>
          <b-tab :title="$t('Tests')" v-if="vaga.questionaries && vaga.questionaries.length > 0 "  >
            <b-card no-body class="border">
              <b-tabs card lazy>
                <b-tab :title="questionaryJob.questionary.name" v-for="(questionaryJob,i) in vaga.questionaries" :key="i">
                  <evaluate-exam :questionaryJob="questionaryJob" 
                    :questionary="questionaryJob.questionary" 
                    :cid="candidatura.id"
                    :token="token" />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab :title="$t('Belonging Groups')" v-if="hasBelongingGroups">
            <grupos-pertencimento :candidatura="candidatura" :is-public="isPublic" />
          </b-tab>
        </b-tabs>
      </transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import VideoInterview from "./VideoInterview.vue";
import EvaluateExam from '@/views/exams/EvaluateExam.vue';
import GruposPertencimento from '@/views/candidatos/GruposPertencimento.vue';

import { 
  CandidaturaEntity, 
  EmpresaEntity, 
  QuestionaryCandidatoEntity, 
  VagaEntity  } from "@/core/models/Entities";


@Component({
  components: {
    VideoInterview,
    EvaluateExam,
    GruposPertencimento
  },
})
export default class VagaVideoInterview extends Vue {
  public loaded: boolean = false;


  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Prop({default: () => { new CandidaturaEntity() }})
  public candidatura!: CandidaturaEntity;

  @Prop({default: () => { new VagaEntity() }})
  public vaga!: VagaEntity;

  @Prop({default: ''})
  public token!: string;

  @Prop({ default: false })
  public isPublic!: boolean;

  constructor() {
    super();
  }


  get e() {
    return (this.isPublic ? this.vaga?.empresa : this.empresa)
  }

  get hasBelongingGroups() {
    return (this.candidatura?.candidato.perfil.grupoLgbt || 
    this.candidatura?.candidato.perfil.grupoIndiginas || 
    this.candidatura?.candidato.perfil.grupoRefugiados ||
    this.candidatura?.candidato.perfil.grupoMovBlack ||
    this.candidatura?.candidato.perfil.grupoPCD ||
    this.candidatura?.candidato.perfil.grupo3Idade ||
    this.candidatura?.candidato.perfil.grupoEmpoderaFeminino)
     && (!this.e?.configuracoes?.HIDE_DIVERSITY_MODAL) && !this.isPublic
    ;
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.videos-questions {
  .show-competencies button {
    font-size: 0.9rem;
  }
  &__question p {
    font-size: 1.2rem;
  }
  &__buttons-question {
    padding-right: 5px;
    overflow-y: auto;
    max-height: 405px;
    p {
      margin-bottom: 5px;
    }
    button {
      background-color: rgb(238, 250, 255);
      // min-height: 7em;
      border: 0;
      border-radius: 5px;
      color: rgb(0, 171, 204);
      margin-bottom: 10px;
      font-size: 0.8rem;
      text-align: center;
      span {
        font-size: 1.5rem;
      }
      &:hover {
        background-color: rgb(95, 170, 201);
        color: white;
      }
      &.no-answer {
        background-color: white;
        border: 1px solid rgb(170, 84, 192);
        color: rgb(170, 84, 192);
        &:hover {
          background-color: rgb(170, 84, 192);
          color: white;
        }
      }
    }
  }

  &__no-response {
    min-height: 300px;
    color: orange;
    .fa-exclamation-triangle{
      font-size: 45px;
    }
    span {
      font-size: 1rem;
    }
  }
  .video {
    .xd {
      width: 100%;
      height: 312px;
      border-radius: 5px;
      background-color: black;
    }
  }
}
</style>