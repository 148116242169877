<template>
  <transition appear name="slide">
    <CardCommon title="Talents by group of belonging">
        <div class="responsive  w-100">
          <div class="d-flex justify-content-around" v-if="dados">
            <div
              v-for="(ag,i) in aggregator"
              :key="i+ag.key"
              class="v-avatar elevation-1 rounded primary"
              style="min-height: 125px; min-width: 125px; padding-right: 10px;"
            >
              <p class="text-xs mb-0" align="center"> {{$t(ag.label)}} </p>
              
              <div>
                <img class="image" :id="ag.key" :src="ag.img" />
                <h4 class="text-xl font-weight-semibold" align="center"> {{dados[ag.key]}} </h4>
              </div>
            </div>
          </div>
        </div>
    </CardCommon>
  </transition>
</template>



<script lang="ts">
import { AggDiversityEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CardCommon from './CardCommon.vue';

@Component({
  components: {
    CardCommon
  }
})
export default class CardGruposPertencimento extends Vue {


  @Prop({default: null})
  public dados!: AggDiversityEntity;

  public aggregator: any = [
    { label: this.$i18n.t("Female"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_empoderamento_feminino.png", key: "grp_feminino" },
    { label: this.$i18n.t("Afro-descendants"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_movimento_black.png", key: "grp_mov_black" },
    { label: this.$i18n.t("Senior Citizens"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_3idade.png", key: "grp_3idade" },
    { label: this.$i18n.t("People with disabilities"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_pcd.png", key: "pcd" },
    { label: this.$i18n.t("LGBTQIA+"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_lgbt%2B.png", key: "grp_lgbt" },
    { label: this.$i18n.t("Refugees"), img: "https://jobecam-assets.s3-us-west-1.amazonaws.com/bandeira_refugiados_imigrantes.png", key: "grp_imigrante" }
  ]



}
</script>

<style scoped>
.responsive{
  overflow: scroll;
}

.v-avatar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-avatar img {
  width: 115px;
}
</style>