<template>
  <div class="card-plano-atual">
    <div class="row">
      <div class="col-md-12">
        <p> {{$t('This is your current plan. You can upgrade by clicking on GET NEW PLAN')}}. </p>
      </div>
      <div class="col-md-12 d-flex justify-content-center">
        <div class="plan-card" v-if="service.entidade.id">
          <div class="d-flex bg-blue text-white header">
            <div
              class="w-50 d-flex flex-column justify-content-center align-items-center jobcoins"
            >
              <h1 class="text-white">{{service.entidade.planoCredito.credito_qtd}}</h1>
              <p class="text-uppercase">{{$t('Interviews')}} {{periodo == 1 ? $t('Monthly') : ''}}</p>
            </div>
            <div
              class="price w-50 d-flex flex-column justify-content-center align-items-center text-uppercase"
            >
              <div>
                <span v-if="$i18n.locale=='pt-BR'">BRL</span>
                <span v-else>USD</span>
                <span class="price__title" v-if="$i18n.locale=='pt-BR'">{{service.entidade.planoCredito.preco}}</span>
                <span class="price__title" v-if="$i18n.locale!='pt-BR'">{{service.entidade.planoCredito.preco_usd}}</span>
                <span>,00*</span>
              </div>
              <p v-if="periodo == 1"> {{$t('Monthly')}} </p>
            </div>
          </div>
          <div class="body">
            <div class="feature-list">
              <ul v-if="!listFeat">
                <li>{{$t("Interviews with 3 questions up to 2 minutes")}}</li>
                <li
                  v-if="periodo === EPeriodoPlano_Anual"
                >{{$t("1 year period for using credits")}}</li>
                <li v-else>{{$t("1 month period for using credits")}}</li>
              </ul>
              <ul v-else>
                <li v-for="feat in listFeat" :key="feat" v-html="feat"></li>
              </ul>
            </div>
            <div class="interview-note text-left">
              <p v-if="periodo === EPeriodoPlano_Anual">*{{$t("Cumulative interviews")}}</p>
              <p v-else>*{{$t("Non-cumulative interviews")}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <p> {{$t('Your Jobcoins will be credited every month according to the chosen plan. Cumulative for annual plans')}}. </p>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <b-link class="btn btn-outline-secondary btn-pay text-uppercase rounded-pill mx-2" :to="{path:'/payments', query: { lang: $i18n.locale }}"
          >{{$t('Payment and Invoice')}}</b-link>
          <!-- <b-link class="btn btn-outline-secondary text-uppercase rounded-pill mx-2" :to="{path:'/plan/cancel', query: { lang: $i18n.locale }}"
          >{{$t('Cancelar Assinatura')}}</b-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import Assinatura from '@/core/Services/Payments/Assinatura';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';

@Component
export default class PlanoAtual extends Vue {
  @Prop() private listjson!: string;

  public service: Assinatura;

  public EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;
  public EPeriodoPlano_Mes: EPeriodoPlano = EPeriodoPlano.Mensal;


  public avaliablelist: string[]|null = null;

  constructor() {
    super();
    this.service = new Assinatura();
  }

  public created() {
    this.service.fetchWithData({id: 0, current: true})

    if (this.service.entidade && this.service.entidade.planoCredito)
      this.avaliablelist = this.service.entidade.planoCredito!.avaliableList
  }

  get periodo() {
    return this.service.entidade.planoCredito!.period;
  }
  
  get listFeat() {
    if (this.listjson && this.avaliablelist == null) {
      try {
        this.avaliablelist = JSON.parse(this.listjson);
      } catch(e) {
        this.avaliablelist = null;
      }
    }
    return this.avaliablelist;
  }

}
</script>

<style lang="scss" scoped>

@import "@/assets/scss/_variables.scss";
.plan-card {
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 8px 0;
  border: none;
  border-radius: 5px;
  margin: 1em 0 2em;
  .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 7em;
    padding: 1em 0;
    .jobcoins {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }
    h1,
    span,
    p {
      margin: 0;
      line-height: 1em;
    }
    h1 {
      font-weight: bold;
    }
  }
  .body {
    padding: 1em;
    .interview-note {
      padding: 0 1em;
    }
  }
  & .feature-list {
    & ul {
      padding: 0 15px;
      list-style: none;
      & li {
        background: url("~@/assets/success.svg") no-repeat top left;
        background-position: 0 3px;
        background-size: 15px;
        padding: 0 0 15px 24px;
        font-size: 13px;
      }
    }
  }

  & .feature-list ul li.feature-list__no {
    background: url("~@/assets/cancel.svg") no-repeat top left;
    background-position: 0 3px;
    background-size: 15px;
    padding: 0 0 15px 24px;
    font-size: 13px;
  }
  .price {
    // margin-bottom: 30px;
    &__title {
      font-size: 2.5em;
      font-weight: bold;
    }
    & h1 {
      margin-bottom: 1em;
      font-size: 2.5em;
      font-weight: bold;
    }
    & p {
      font-size: 14px;
    }

    &.free-price {
      margin-top: 0.5rem;
      margin-bottom: 1rem;

      h1 {
        margin: 0;
      }
    }
  }
}

.btn-pay {
  font-size: 0.95rem;
}
</style>