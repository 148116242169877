
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class VagaShare extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/vaga-share', method: 'POST', headers: {} },
            fetch: { url: '/v1/vaga-share/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/vaga-share', method: 'GET', headers: {} }, // tem parametro de Busca 's'
            update: { url: '/v1/vaga-share/{id}', method: 'PUT', headers: {} }}) );
    }
}
