<template>
  <transition appear name="fade">
    <b-row class="mt-3 border-top pt-3" no-gutters> 
      <b-col md="12">
        <h6 class="text-secondary">{{ $t('Add evaluators')}}</h6> 
      </b-col>
      <b-col md="5">
        <text-input
          variant="gray-bordered"
          v-model="value.nomeGestor"
          :placeholder="$t('Name')"
        />          
      </b-col>
      <b-col md="5">
        <text-input
          variant="gray-bordered"
          :placeholder="$t('E-mail')"
          v-model="value.emailGestor"
        />    
      </b-col>
      <b-col md="2" class="text-center"> 
        <b-button variant="light" class="shadow" block  @click="add()">
          <i class="fa fa-plus-circle"></i>
        </b-button>
      </b-col>
    </b-row>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GrupoAvaliacaoGestorEntity } from '@/core/models/Entities';

@Component
export default class FormGestores extends Vue {

  public value: GrupoAvaliacaoGestorEntity = new GrupoAvaliacaoGestorEntity();

  public add() {
    this.$emit('add',this.value);
    this.value = new GrupoAvaliacaoGestorEntity();
  }

}
</script>

<style>

</style>