<template>
  <transition appear name="bounce">
    <div class="modal_wrapper">
      
      <div class="vld-parent">
          <loading :active="loadingSave" 
          :can-cancel="false" 
          :background-color="'#EEECEC'"
          :opacity="1"
          :is-full-page="true"></loading>
      </div>
    <b-modal id="modalImportJobsGreenhouse" size="xl" :title="$t('Import Job')">
      <b-container>
        <b-row>
          <b-col md="12">
            <b-table striped hover :fields="fields" :items="items" :busy="loading">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner variant="primary" class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(optin)="row">
                <b-button variant="primary" class="circle-round" size="sm" @click="importJob(row.item)">
                  <i class="fas fa-file-import"></i>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ImportJob from '@/core/Services/Vaga/ImportJobGreenhouse';
import GestaoVaga from '@/core/Services/Empresa/GestaoVaga';
import { filter } from 'vue/types/umd';
import Loading from 'vue-loading-overlay';

import { VagaEntity } from '@/core/models/Entities';
import Vaga from '@/core/Services/Vaga/Vaga';

@Component({
  components: {
    Loading
  }
})
export default class ModalImportJobsGreenhouse extends Vue {
  public fields: any[] = [];
  public items: any[] = [];
  public loading: boolean = false;
  public loadingSave: boolean = false;
  public service: ImportJob = new ImportJob();
  public serviceVaga: GestaoVaga = new GestaoVaga();
  
  //chave de retorno do conjunto de job_boards
  private GHBOARDS: string = "GHBOARDS";

  public mounted() {
    this.fields = [
      { key: 'id', label: this.$t('Code'), class: 'text-center' },
      { key: 'title', label: this.$t('Name'), class: 'text-center' },
      { key: 'absolute_url', label: 'URL', class: 'text-center' },
      { key: 'optin', label: '-', class: 'text-center' }
    ];
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId == 'modalImportJobsGreenhouse') {
        this.loadData()
      }
    })
  }

  public loadData() {
    this.loading = true;
    let filters: any = {};
    this.items = [];
    
    this.service.fetchAll(filters).then((data: any) => {
      if (data && data[this.GHBOARDS] && data[this.GHBOARDS] instanceof Array) {
        const len =  data[this.GHBOARDS].length;
        for (let i = 0; i < len; i++) {
          if (data[this.GHBOARDS][i]?.jobs) {
            for (let j = 0; j < data[this.GHBOARDS][i].jobs.length; j++) {
              this.items.push(Object.assign(data[this.GHBOARDS][i].jobs[j],{ optin: data[this.GHBOARDS][i].jobs[j].id}))
            } 
          }
        }
      }      
    }).finally(() => {
      this.loading = false;
    })
  }

  public async importJob(job: any) {
    let msg: any = undefined;
    this.$confirm.msg.push(this.$createElement('div',this.$t('Are you sure you want to import this vacancy?').toString()));
    const c = this.$createElement('strong',"Cod." + (job.id||""))
    const c2 = this.$createElement('strong'," " + (job.title||""))
    const d = this.$createElement('div',[ c,c2 ])
    this.$confirm.msg.push(d);
    this.$confirm.open({ okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('Cancel').toString(), msg },'check')
    .then(async (v:boolean) => {  
      if (v) {
        console.log(job)
        try {
            const id = job.id;
            const jobContent: any = await this.service.fetch(id);
            if(jobContent[this.GHBOARDS][0]) {
              const ghJob: any = jobContent[this.GHBOARDS][0];
              let vaga: VagaEntity = new VagaEntity();
              const objGhToJobecam: any = {
                posicao: ghJob.title,
                descricao: ghJob.content||" - ",
                localidade: `${ghJob?.location?.name||" - "}`,
                zipcode: "0",
                competencias: " - ",
                confidencial: "SIM",
                restrita: "SIM",
                transcricao: 0,
                blind: false,
                limited: 0,
                hideMap: true,
                ghReferenceId: ghJob.id,
                salario: " ",
                salaryMin: 0,
                salaryMax: null,
                nivel: "Outros",
                simplificada: true
              }
              vaga = Object.assign(vaga,objGhToJobecam)
              this.serviceVaga.entidade = vaga;
              this.loadingSave = true;
              
              this.$bvModal.hide('modalImportJobsGreenhouse')
              
              this.serviceVaga.save().then((data) => {
                if(data?.id)
                  this.$router.push({
                    name: "JobsOpeningsSimpleEdit",
                    params: { id: data.id+"" },
                    query: { lang: this.$i18n.locale }
                  })
              }).finally(() => {
                this.loadingSave = false;
              })
              console.log('importar vaga')
            } else {
              console.warn('não foi possivel retornar dados da vaga')

            }
          } catch (error) {
            console.warn('error pulling job data')
          }
       
      }
    });
    
    
  }
}
</script>

<style lang="scss" scoped>
  .circle-round {
    &:hover{
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border-color: #f8f9fa !important;
    }
    border-radius: 50% !important;
    padding: 7px !important;
    border-width: 2px;
    line-height: 1em;
    font-size: 0.875rem;
  }
</style>