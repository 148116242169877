<template>
  <div class="box-container d-flex flex-column mb-5">
    <div class="box-formulario d-flex align-items-center justify-content-center">
        <recrutador-form ref="rForm" @save="edit" :promise="promise" @avatarSaved="updateObject" :selfUpdate="true" /> 
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter,Action } from 'vuex-class';
import RecrutadorForm from '@/views/recrutadores/RecrutadorForm.vue';
import { RecrutadorEntity } from '@/core/models/Entities';
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';

@Component<ContaRecrutador>({
  components: {
    RecrutadorForm,
  }
})
export default class ContaRecrutador extends Vue {
  public service: GestaoRecrutador = new GestaoRecrutador();
  public recrutador!: any;
  public promise: any|null = null;
  public loading: boolean = false;

  @Getter("getProfile", { namespace: 'profile' }) 
  public getProfile: any;
  
  @Getter("recruiterInfo", { namespace: 'profile' })
  public recrutadorProf?: RecrutadorEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;

  public async mounted() {
    this.loading = true;

    if(!this.recrutadorProf)
      await this.fetchEmpresaInfo();

    window['rec'] = JSON.stringify(this.recrutadorProf);
    this.recrutador = JSON.parse(JSON.stringify(this.recrutadorProf));
    (this.$refs.rForm as RecrutadorForm).setRecrutador(this.recrutador);
    this.loading = false;
    // TODO: tratar quando não tem id
  } 

  public edit(recrutador: RecrutadorEntity) {
    this.promise = this.service.update(recrutador).then((data) => {
      this.updateObject(true) 
    });
    // TODO: tratar se der erro ao salvar recrutador
  }
  public async updateObject(b: boolean) {
    if (b) {
      await this.fetchEmpresaInfo();
      this.recrutador = JSON.parse(JSON.stringify(this.recrutadorProf));
      (this.$refs.rForm as RecrutadorForm).setRecrutador(this.recrutador);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
.box-container {
  min-height: calc(100vh - 55px - 32px);
  .box-formulario {
    > div {
      width: calc(50% - 1em);
    }
    .foto-perfil {
      img {
        width: 6em;
        border-radius: 50%;
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>