<template>
<transition name="slide-fade">
  <div class="container mt-4">
    <b-row>
      <b-col md="3 pb-1">
          <label for="inline-form-input-carrer-btn">{{$t('Add institutional page')}}</label>
        <b-button block variant="primary" @click="addPage"> 
          <i class="fa fa-plus" />  {{$t('New page')}}
        </b-button>
      </b-col>
      
        <div class="col-md-9">
          
          <label for="inline-form-input-carrer">{{$t('URL - Careers Page')}}</label>
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-input-group-prepend is-text>
              {{'https://talentos.jobecam.com/e/'}}
            </b-input-group-prepend>
            <b-input 
                :disabled="!editURL"
                type="text"
                :required="true"
                :placeholder="'page-d'"
                v-model="nomeUrl"
                maxlength="30"></b-input>
            <b-input-group-append>
              <b-button
                v-show="!editURL"
                variant="success"
                @click="editURL = true"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                v-show="editURL"
                variant="success"
                @click="save"
                v-promise-btn="{promise}"
              >
                <i class="fas fa-save"></i>
              </b-button>
              <b-button
                variant="primary"
                v-clipboard:copy="companyURL"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-b-tooltip.hover
                :title="$t('Share Profile')"
              >
                <i class="fa fa-share-alt"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      <b-col md="12">
        <b-card no-body class="border">
          <b-tabs pills card vertical class="institutional-page-tabs">
            <b-tab  v-for="(page,i) in list" :key="i" :title="page.menu" lazy>
              <form-institucional v-model="list[i]" :index="i" @remove="remove" />
            </b-tab>
          </b-tabs>
         </b-card>
      </b-col>
    </b-row>
  </div>
</transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { PaginaInstitucionalEntity, EmpresaEntity } from '@/core/models/Entities';
import Page from '@/core/Services/ContentManager/PaginaInstitucional';
import FormInstitucional from './FormInstitucional.vue';
import {siteCandidateEndPoint} from '@/core/endpoint';
import Empresa from '@/core/Services/Empresa/Empresa';

@Component<PaginaInstitucional>({
  components: {
    FormInstitucional
  }
})
export default class PaginaInstitucional extends Vue {
  public service: Page = new Page();
  public serviceEmpresa: Empresa = new Empresa();
  public promise: any|null = null;
  public list: PaginaInstitucionalEntity[] = [];
  public currentURL: string = process.env.VUE_APP_SITE_TALENTOS_END_POINT!||siteCandidateEndPoint||'';
  public editURL: boolean = false;
  public nomeUrl: string ="";

  @Getter("getProfile", { namespace: 'profile' }) 
  public getProfile: any;
  
  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;

  public async mounted() {
    this.service.fetchAll().then((data: any) => {
      this.list = data;
    })
    if (!this.empresa)
      await this.fetchEmpresaInfo()
    
    if (this.empresa)
      this.serviceEmpresa.entidade = this.empresa;
    
    this.nomeUrl = this.serviceEmpresa.entidade.nomeUrl||"";
  }

  public addPage() {
    let x:PaginaInstitucionalEntity = new PaginaInstitucionalEntity();
    x.menu = "Menu " + (this.list.length+1);
    this.list.push(x);
  }

  public remove(index: number) {
    if (index > -1) {
      this.list.splice(index,1)
    }
  }
  
  public save() {
    if (this.nomeUrl) {
      this.nomeUrl = this.nomeUrl.replaceAll(' ','-').toLowerCase()
      this.serviceEmpresa.entidade.nomeUrl = this.nomeUrl;
    } else {
      return;
    }
    this.promise = this.serviceEmpresa.save().then((valid: boolean) => {
      if (valid) {
        this.$alert.addMsg(this.$t('Saved successfully'));
        this.$alert.callBoxCheck('Ok');
        this.editURL = false;
      }
    });
  }
  
  public onCopy() {
    this.$alert.addMsg(""+this.$t("Jobecam profile link copied successfully"));
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public onError() {
    console.log("not copied");
  }

  
  get companyURL() {
    return (this.serviceEmpresa.entidade.nomeUrl) ? this.currentURL+'/e/'+this.serviceEmpresa.entidade.nomeUrl : '...';
  }

}
</script>

<style lang="scss">
.institutional-page-tabs {
  .nav-pills {
    width: 200px;
  }
}
</style>