<template>
  <div class="info-questions">
    <div class="row">
      <div class="col-md-2 col-4 foto-perfil d-flex justify-content-center">
        <img :src="avatar" v-if="!isBlind && avatar"/>
        <img src="/static/img/avatar.png" alt v-else/>
      </div>
      <div class="col-md-5 p-0 col-8 d-flex align-items-center buttons">
        <div class="row">
          <div class="col-md-12 mb-2">
            <h3 class="m-0">{{nomeCompleto}} 
              <small>
              <i v-if="temExpTemForm" 
              class="fa fa-exclamation-triangle text-warning" 
              v-b-tooltip.hover
              :title="temExpTemForm"  /></small>

            </h3>
            <span class="m-0" v-if="candidatura.anonymousName && candidatura.status != 'APROVADO'">{{candidatura.id}} </span> 
            <span class="m-0" v-if="candidato.email">{{candidato.email}} </span> 
            <span class="m-0" v-if="localidade && permitInfo"> - {{localidade}}</span> <br />  
            <span class="m-0" v-if="candidato.perfil.telefone">{{$t('Telephone')}}: {{candidato.perfil.telefone}}</span>
            <div class="m-0" v-if="candidatura.dataAtualiza">
              <small> <b>{{$t('Date')}}</b> {{dateOnly(candidatura.dataAtualiza)}} </small>
            </div>
            
          </div>
          <div class="action-btn pl-3" > 
            <button-icon-rounded
              v-for="(i, index) in action" :key="index"
              color="rgb(70, 150, 204)"
              border="rgb(70, 150, 204)"
              backHover="rgb(70, 150, 204)"
              fontSize="1rem"
              class="mr-2"
              v-b-tooltip.hover
              :title="i.title"
              @click="i.btnAction"
            >
              <span :class="i.btnIcon"></span>
            </button-icon-rounded>
          </div>
          <!-- <button-icon-rounded
            color="gray"
            border="transparent"
            backHover="gray"
            fontSize="1rem"
            class
          >
            <span style="font-weight: bold">&#9679;&#9679;&#9679;</span>
          </button-icon-rounded> -->
        </div>
      </div>
      <div class="info-questions__candidate-evaluation col-md-5 p-0" v-if="candidatura">
        <div class="row justify-content-center align-items-center d-flex">
          <b-col md="8" class="p-0">
            <b-row>
              <b-col md="8" class="mx-auto">
                <b-form-rating  v-b-tooltip.hover
                  :title="$t('AVG Evaluation')"
                v-model="candidatura.avgRating" readonly no-border size="sm" variant="warning"></b-form-rating>
              </b-col>
              <div class="col-md-12 text-center">
                <span class="status" v-if="candidatura.status == 'EMANALISE'">{{$t("Evaluation under review")}}</span>
                <span class="status" v-if="candidatura.status == 'VIDEOSPENDENTES'">{{$t("Pending Interview")}}</span>
                <span class="status" v-if="candidatura.status == 'APROVADO'">{{$t("Approved Candidate")}}</span>
                <span class="status" v-if="candidatura.status == 'REPROVADO'">{{$t("Reproved Candidate")}}</span>
              </div>
              <div class="col-md-12 d-flex justify-content-center" v-if="!isPublic">
                <p class="text-uppercase mb-2">{{$t("Candidate evaluation")}}</p>
              </div>
              <evaluation-btns 
                v-if="!isPublic"
                :candidatura-id="candidatura.id" 
                :candidatura-status="candidatura.status" 
                :vaga="vaga"
                @modalNewChance="showModalNewChance"
                @statusAprovado="emitStatusAprovado"
                @statusReprovado="emitStatusReprovado"
                @statusAnalise="emitStatusAnalise"
                @repescagemRevisaoStatus="repescagemRevisaoStatus"
                :is-public="isPublic"
              />
            </b-row>
          </b-col>
          <b-col md="4" class="p-0" >
              <h6 class="text-center">Score 
                <b-button id="button-scoreranq" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                <b-tooltip target="button-scoreranq" placement="left">
                  <ul class="list-unstyled">
                    <li> {{ $t("Application score") }}</li>
                    <li> {{ $t("This has a minimum value of 0 and maximum of 100") }}</li>
                    <li> {{ $t("Rank the candidates based on the keywords informed in the job configuration") }}</li>
                  </ul>
                </b-tooltip>
              </h6>
              <h1 class="text-center">{{candidatura.score||' - '}}</h1>
          </b-col>
        </div>
      </div>
    </div>
    <modal-message id="modalMessage" :candidatura="candidatura" :vaga="vaga" :single="true" v-if="!isPublic" />
    <modal-share-link id="modalShareLink" :candidatura="candidatura" v-if="!isPublic" :single="true" />
    <modal-copy-link id="modalCopyLink" ref="copyModal" :candidatura="candidatura" :show="mclShow" v-if="!isPublic" />
    <modal-new-chance id="modalNewChance" :candidatura="candidatura" @granted="ncGranted" @not-granted="ncNotGranted" :single="true" />
    <modal-tags id="modalTags" :candidatura="candidatura" :vaga="vaga"/>
    <modal-agendar id="modalCalendar" v-if="!isPublic" :candidatura="candidatura" :vaga="vaga"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import { CandidaturaEntity, VagaEntity, CandidatoEntity, EmpresaEntity } from "@/core/models/Entities";
import ModalSendMessage from "@/components/modal/ModalSendMessage.vue";
import ModalShareLink from "@/components/modal/ModalShareLink.vue";
import ModalCopyLink from "@/components/modal/ModalCopyLink.vue";
import ModalNewChance from "@/components/modal/ModalNewChance.vue";
import ModalTags from "@/components/modal/ModalTags.vue";
import ModalCreateInterviewEvent from "@/components/modal/ModalCreateInterviewEvent.vue";
import CandidatoEvaluationButtons from "@/views/candidatos/CandidatoEvaluationButtons.vue"
import { siteCandidateEndPoint } from "@/core/endpoint";
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    'button-icon': ButtonIcon,
    'modal-message': ModalSendMessage,
    'modal-share-link': ModalShareLink,
    'modal-copy-link': ModalCopyLink,
    'modal-new-chance': ModalNewChance,
    'modal-tags': ModalTags,
    'modal-agendar': ModalCreateInterviewEvent,
    'evaluation-btns': CandidatoEvaluationButtons
  },
})
export default class CandidatoHeader extends Vue {

  public mclShow: boolean = false;
  public URLCandidato = siteCandidateEndPoint;
  public action: any|null = [];
  public alert: Alert = new Alert;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;
  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Watch('candidatura', {deep: true})
  public handleChangeCandidatura(newValue: CandidaturaEntity) {
    this.handleCandidatura();
  }

  get isBlind() {
    return (this.vaga.blind == 1 && this.candidatura.status != 'APROVADO')
  }

  get temExpTemForm() {
    let x: string = "";
    if (this.candidato.perfil.temExperiencia===false) {
      x += this.$t('Looking for the first job').toString()+" ";
    }
    if (this.candidato.perfil.temExperiencia===false) {
      x += this.$t('Has no Academic Data').toString();
    }
    return x;
  }

  
  get e() {
    return (this.isPublic ? this.vaga?.empresa : this.empresa)
  }

  get permitInfo() {
    return !(this.isPublic ? this.vaga?.empresa?.configuracoes?.ENABLE_CANDIDATE_INFO_FORCE_HIDING == true : this.empresa?.configuracoes?.ENABLE_CANDIDATE_INFO_FORCE_HIDING == true);
  }

  get avatar() 
  {
    return this.candidato.fotoAvatar
  }

  get localidade() {
    return (this.candidato.perfil.localidade!=undefined) ? `${this.candidato.perfil.localidade} ${this.candidato.perfil.nomeCidade} / ${this.candidato.perfil.nomeEstado}` : '';
  }
  
  get candidato() {
    return this.candidatura.candidato;
  }

  get nomeCompleto () {
    if (this.candidato && this.candidato.primeironome && !this.isBlind) { 
        return `${this.candidato.primeironome} ${this.candidato.sobrenome}`
    } else {
      return this.candidatura.anonymousName ? this.$t(this.candidatura.anonymousName) : this.candidatura.id
    }
  }

  public showModalMessage() {
    this.$bvModal.show('modalMessage')
  }

  public showModalShareLink() {
    this.$bvModal.show('modalShareLink')
  }

  public async showModalCopyLink() {
    this.mclShow = true;
    await (this.$refs.copyModal as ModalCopyLink).updateUrl();
    this.$bvModal.show('modalCopyLink')
  }

  public showModalNewChance() {
    this.$bvModal.show('modalNewChance')
  }

  public showModalTags() {
    this.$bvModal.show('modalTags')
  }

  public showModalCalendar() {
    this.$bvModal.show('modalCalendar')
  }

  public emitStatusAprovado(id) {
    this.$emit('statusAprovado', id)
  }

  public emitStatusReprovado(id) {
    this.$emit('statusReprovado', id)
  }

  public emitStatusAnalise(id) {
    this.$emit('statusAnalise', id)
  }

  public repescagemRevisaoStatus(status: string, id: any) {
    this.$emit('repescagemRevisaoStatus', status, id)
  }

  public ncGranted(cid) {
    this.$emit('granted',cid);
  }

  public ncNotGranted(cid) {
    this.$emit('not-granted',cid);
  }

  public goCandidatoPerfil() {
    window.open(this.URLCandidato+'/c/'+this.candidato.nomeUrl)
  }

  public openHiddenProfile() {
    let perfil = this.$router.resolve({
      name: "HiddenTalentInfoPage", params: {guid: this.candidato?.guid+""}, query: {lang: this.$i18n.locale, n: btoa(this.candidatura.anonymousName||"") }
    });
    window.open(perfil.href, '_blank')
  }

  public downloadCurriculo() {
    if (this.candidato.perfil.curriculum && !this.isBlind) {
      window.open(this.candidato.perfil.curriculum, '_blank')      
    } else {
      this.$alert.addMsg(this.$t('At the moment the candidate did not make any curriculum available'))
      this.$alert.callBoxWarn()
    }
  }

  public func() {
    console.log('anonimo')
  }

  public fillActions() {
    this.action = [];
    
    if((this.vaga?.mostrarCurriculoOculto == true) && this.candidatura.status != "APROVADO") {
      this.action.push({
          title: this.$t('Anonymous Resume'),
          btnIcon: 'fa fa-user-secret',
          btnAction: this.openHiddenProfile,
      })
    }
    
    if (this.vaga.blind == 0 || this.candidatura.status == "APROVADO") {
      this.action.push({
          title: this.$t('View Profile'),
          btnIcon: 'icon icon-user',
          btnAction: this.goCandidatoPerfil,
      })
    } 
    if (this.vaga.blind == 0 || this.candidatura.status == "APROVADO") {
      this.action.push({
          title: this.$t('Curriculum'),
          btnIcon: 'icon icon-download',
          btnAction: this.downloadCurriculo
      })
    }
    this.action.push({
        title: 'Tags',
        btnIcon: 'fas fa-hashtag',
        btnAction: this.showModalTags
    })
      // {
      //   title: this.$t('Habilidades'),
      //   btnIcon: 'icon icon-graficos',
      //   btnAction: this.func
      // }

    if (!this.isPublic) {
      this.action.push({
          title: this.$t('Send message'),
          btnIcon: 'icon icon-enviar',
          btnAction: this.showModalMessage
      })
      this.action.push({
          title: this.$t('Share link with manager'),
          btnIcon: 'icon icon-compartilhar',
          btnAction: this.showModalShareLink
        })
      this.action.push({
        title: this.$t('Copy link'),
        btnIcon: 'icon icon-copiar',
        btnAction: this.showModalCopyLink
      })
      if(!this.e?.configuracoes?.ENABLE_HIDE_SCHEDULE_MEETING) {
        this.action.push({
          title: this.$t('Schedule interview'),
          btnIcon: 'far fa-calendar-alt',
          btnAction: this.showModalCalendar
        })
      }
    }
  }

  public handleCandidatura() {
     this.$nextTick(() => {
      this.fillActions();
    })       
  }

  public mounted() {
    this.handleCandidatura();
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.info-questions {
  padding: 2em 0 2em 0;
  .foto-perfil {
    padding: 0 2em 2em 2em;
    position: relative;
    display: flex;
    // object-fit: cover;
    border-radius: 200px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 90px;
        height: 90px;
    }
  }
  .buttons {
    h3 {
      font-size: 1.4rem;
    }
  }

  .status {
    font-size: 0.9rem;
  }

  .action-btn button {
    padding: 0;
  }

  .icon:before {
    vertical-align: middle;
  }

  &__candidate-evaluation {
    p {
      font-size: 0.8rem;
    }
  }
}

</style>