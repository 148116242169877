import Vue from 'vue';
import Vuex, { StoreOptions, ModuleTree } from 'vuex';
import { profile } from '@/store/profile/index';
import { ui } from '@/store/ui/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    profile,
    ui,
  },
  strict: process.env.NODE_ENV !== 'production',
});
