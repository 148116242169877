<template>
    <div class="row">
        <b-col md="4" offset-md="8" class="mb-3 pb-3" v-if="!evaluation">
          <b-button variant="success" block @click="save" size="lg" class="float-right" v-promise-btn="{promise}"> 
            <i class="fa fa-save" />
            {{$t('Save')}}
          </b-button>
        </b-col>
        <!-- Questionário -->
        <b-col md="10">
          <text-input v-if="!evaluation"
              variant="dark"
              :required="false"
              :label="$t('Test name')"
              :placeholder="$t('Theoretical Mathematics Test')"
              v-model="service.entidade.name"
              :maxlength="255"
            />
        </b-col>
        <b-col md="2" v-if="!evaluation">
          <b-form-group :label="$t('Activate/Inactivate')">
            <b-form-checkbox v-model="service.entidade.status" size="lg" switch :value="1" :unchecked-value="0"  />
          </b-form-group>
        </b-col>
        <!-- Questões -->
        <b-col md="12" v-for="(question,index) in service.entidade.questions"  v-bind:key="index">
          <form-question v-model="service.entidade.questions[index]" :q-index="index" 
            :evaluation="evaluation" 
            :answers="answers" 
            @pointGiven="pointGiven"
            @openImageModal="openImageModal"
            @removeQuestion="removeQuestion"  />
        </b-col>
        <b-col md="12" v-if="!evaluation && !service.entidade.isEssay" >
          <div :class="{'py-1 mt-3':true }">
            <b-button variant="primary" size="lg" class="ml-1 rounded-0" @click="firstTierOpen = true" v-if="!firstTierOpen"> 
              <i class="fa fa-plus" />  {{$t('New Question')}}
            </b-button>
            <div v-if="firstTierOpen"> {{$t('Pick the type of question')}} </div>
            <b-button variant="outline-primary" size="lg" class="ml-1 rounded-0" v-if="firstTierOpen"  @click="addMultiple" > 
              <i class="far fa-dot-circle" />  {{$t('Options')}}
            </b-button>
            <b-button variant="outline-primary" size="lg" class="ml-1 rounded-0" v-if="firstTierOpen"  @click="addText" > 
              <i class="far fa-edit" />  {{$t('Text')}}
            </b-button>
            <b-button variant="danger" size="lg" class="ml-1 rounded-0" v-if="firstTierOpen"  @click="firstTierOpen = false" > 
              <i class="fa fa-ban" />  {{$t('Cancel')}}
            </b-button>
          </div>
        </b-col>
      <image-modal :mid="'call-img-question'">
        <img :src="currentImgURL" style="width:100%;cursor:pointer;" @click="$bvModal.hide('call-img-question')" />
      </image-modal>
    </div>
</template>

<script lang="ts">
// List Company evaluations
import ImageModal from '@/components/modal/ImageModal.vue';
import { EQuestionType } from '@/core/Enums/EQuestionType';
import Exam from '@/core/Services/Exams/Exam';
import { AnswerEntity, QuestionEntity, QuestionaryEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormQuestion from './FormQuestion.vue';

@Component({
  components: {
    FormQuestion,
    ImageModal
  }
})
export default class FormExam extends Vue {
  public service: Exam;
  public currentImgURL: string = '';
  public firstTierOpen: boolean = false;
  public promise: Promise<any>|null =null;

  @Prop({default: false})
  public evaluation!: boolean
  
  @Prop({default: false})
  public essay!: boolean
  
  @Prop({default: () => { return new QuestionaryEntity(); }})
  public questionary!: QuestionaryEntity

  @Prop({default: () => { return {}}})
  public answers!: any

  constructor() {
    super();
    this.service = new Exam();
  }
  
  public openImageModal(url: string) {
    this.currentImgURL = url;
    this.$bvModal.show('call-img-question')
  }

  public pointGiven(answerId: number, questionaryAnswerCandidateId: number, points: number, candidaturaStatus: string ) {
    this.$emit('pointGiven',answerId,questionaryAnswerCandidateId,points,candidaturaStatus);
  }

  public async mounted() {
    // this.service = new Exam();
    if (!this.evaluation) {
      const qid: number = parseInt(this.$route.params.qid);
      if (qid && !isNaN(qid)) {
        this.service.entidade =  await this.service.fetch(qid)
      } 
    } else if (this.questionary) {
      this.service.entidade = this.questionary;
    }

    this.loadObjectIfIsEssay();
  }

  public loadObjectIfIsEssay() {
    if (this.essay) {
      this.service.entidade.isEssay = true;
      this.addText();
    }
  }

  public addText() {
    const q: QuestionEntity = new QuestionEntity();
    q.points = 1;
    q.questionTypeId = EQuestionType.Text;
    this.service.entidade.questions.push(q);
    this.firstTierOpen = false;
  }

  public addMultiple() {
    // If is multiple, at least 2 answers
    const q: QuestionEntity = new QuestionEntity();
    q.points = 1;
    q.questionTypeId = EQuestionType.Multiple;
    q.answers.push(new AnswerEntity());
    this.service.entidade.questions.push(q);
    this.firstTierOpen = false;
  }

  public removeQuestion(qIndex: number) {
    if (this.service.entidade.questions[qIndex]) {
      if (!this.service.entidade.removedQuestions) {
        this.service.entidade.removedQuestions = [];
      }
      if (this.service.entidade.questions[qIndex].id > 0)
        this.service.entidade.removedQuestions.push(this.service.entidade.questions[qIndex])
      this.service.entidade.questions.splice(qIndex,1);
    }
  }

  public save() {
    const hasNoQuestions = this.service.entidade.questions.length <= 0;

    if (hasNoQuestions) {
      this.$toast.variant = 'danger';
      this.$toast.addTitle(this.$t('No questions').toString());
      this.$toast.addMsg(this.$t('You have to add at least one question'))
      this.$toast.open();

      return;
    }

    this.promise = this.service.save().then((data: any) => {
      this.$emit('saved', data)
    })
  }

}
</script>

<style lang="scss">
  .answer-content {
    &:hover  {
      .answer-description {
        background: #f8f8f8;
        border: 1px dashed #999;  
        cursor: pointer;
      }
          
      background: #f8f8f8;
      cursor: pointer;
    }
    border: 1px solid #f8f8f8; 
    font-size: 0.9em;
  }
  .question-container{
    border: 1px solid #ccc;
    padding: 0 15px 0 15px;
    margin-top:1em;
    position: relative;
    &__legend {
      position: absolute;
      top: 5px;
      left: 10px;
    }
    &__description {
      font-size: 1.2em;
      &:hover {
        background: #f8f8f8;
        cursor: pointer;
      }
    }
  }
</style>