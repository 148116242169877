<template>
<transition name="fade" appear>
  <div id="plural">
    <!-- <h1>PLURAL ARTIGOS</h1> -->
    <br/>
    <b-row class="align-content-stretch flex-wrap">
      <b-col class="mb-2" sm="12" md="12" lg="4" v-for="(post, i) in posts" :key="i">
        <b-card class="card-post" @click="view(post.tituloUrl)">
          <div class="card-post__content">
            <img :src="post.arquivo" alt="Imagem Post"/>
          </div>

          <div class="card-post__header">
            <b-col>
              <div class="card-post__header-title">
                <p>{{post.titulo}}</p>
              </div>
            </b-col>
          </div>
        </b-card>
      </b-col>
    </b-row>


  <!-- <b-card-group deck>
    <b-card title="Title" img-src="https://picsum.photos/300/300/?image=41" img-alt="Image" img-top>
      <b-card-text>
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This content is a little bit longer.
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Title" img-src="https://picsum.photos/300/300/?image=41" img-alt="Image" img-top>
      <b-card-text>
        This card has supporting text below as a natural lead-in to additional content.
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Title" img-src="https://picsum.photos/300/300/?image=41" img-alt="Image" img-top>
      <b-card-text>
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This card has even longer content than the first to show that equal height action.
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>
  </b-card-group> -->
<!-- 
  <b-card-group deck>  
    <b-card v-for="(post, i) in posts" :key="i" :title="post.titulo" :img-src="post.arquivo" img-alt="Image" img-top @click="view(post.tituloUrl, post)">
      <b-card-text>
        <div v-html="getSmallText(post.texto)"> </div>
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>
  </b-card-group> -->



  <!-- <b-row>
    <b-col class="mb-2" sm="12" md="12" lg="6" v-for="(post, i) in posts" :key="i">

      <b-card no-body class="card-post overflow-hidden" style="max-width: 540px;">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img :src="post.arquivo" alt="Image" class="rounded-0"></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body class="card-post__header" :title="post.titulo">
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row> -->

  <!-- <b-row class="align-content-stretch flex-wrap">
    <b-col class="mb-2" sm="12" md="12" lg="3" v-for="(post, i) in posts" :key="i">
      <b-card
        :title="post.titulo"
        :img-src="post.arquivo"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="card-post mb-2"
      >
      </b-card>

    </b-col>
  </b-row> -->

  </div>
</transition>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import { PluralPostEntity } from '@/core/models/Entities';
import Vue from "vue";
import Component from "vue-class-component";


@Component
export default class PluralArtigos extends Vue {
  public service: PluralPostService;
  public posts: PluralPostEntity[]|null = null;
  public loadingPage:boolean = false;
  public smallText: string = "";

  constructor() {
    super();
    this.service = new PluralPostService(); 
  }

  public mounted() {
    this.loadingPage = true;

    this.service.fetchAll({g:'ARTIGO'}).then((data: any) => {
      console.log(data);
      this.posts = data['pluralPosts'];
      this.posts?.reverse();
    }).catch((err) => { 
      console.log(err) 
    }).finally(()=> {
      this.loadingPage = false;
    })
  }

  public getSmallText(Text: string){
    return Text.slice(0,150);
  }

  public view(url:any) {
    this.$router.push({ 
      name: 'PluralArtigoView', 
      params: { 
        url: url
      }, 
      query: {lang: this.$i18n.locale} });
  }


}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

  $card-transition: all ease-in-out 0.24s;

  iframe {
    border-radius: 4px;
  }


   .card-post {
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

    &__header {
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      color: $blue;
      border-bottom: $blue 1px dashed;
      text-align: center;
      /* height: 100%; */
    }
    &__content {
      overflow: hidden;
      img {
        height: 200px;
      }
    }
  } 

</style>