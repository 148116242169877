<template>
  <b-container fluid class="position-relative m-h-400" ref="containerLoader">
    <form @submit="doSubmit" v-if="entity">
      <b-row>
        <b-col md="12" class="d-flex justify-content-between">
          <div>
            <h3>
              {{ $t("Edit E-mail Template") }}
            </h3>   
          </div>
          <div class="mx-2">
            <b-button variant="primary" pill @click="save" v-promise-btn="{promise}"> 
              <i class="fa fa-save mr-2" />
              {{$t('Save')}}
            </b-button>
          </div>
        </b-col>
        <b-col md="12" class="my-2">
          <b-button pill @click="showTags = !showTags" variant="outline-info"> 
            {{$t('See available tags')}} 
            <i class="fa fa-angle-right ml-2" v-if="!showTags"></i>
            <i class="fa fa-angle-down ml-2" v-else></i>
          </b-button>
          <b-alert :show="showTags" variant="light" >
            
            <strong>{NOME_CANDIDATO}</strong>: {{$t('Changes to the candidate name (only if there are on our base)')}}  <br />
            <strong>{NOME_EMPRESA}</strong>: {{$t('Changes to the company name')}}  <br />
            <strong>{POSICAOVAGA}</strong>: {{$t('Changes to the name of the vacancy being worked')}}  <br />
            <strong>{LINKVAGA}</strong>: {{$t('Changes to the vacancy link on the candidate platform')}}  <br />
          </b-alert>
        </b-col>
        <b-col md="12" class="mt-3 pt-3 border-top">
          <text-input
              variant="dark"
              type="text"
              :required="true"
              class="mb-1"
              :label="$t('E-mail subject')"
              :placeholder="$t('Title')"
              v-model="entity.titleTemplate"
            />
        </b-col>
        <b-col md="12 mt-2">
          <b-form-group :label="$t('E-mail body')" class="form-text-area-mce">
            <editor :init="tinyIni" v-model="entity.bodyTemplate" ></editor>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MailTemplate from '@/core/Services/Empresa/MailTemplate';
import Editor from "@tinymce/tinymce-vue";
import { MailTemplateEntity } from '@/core/models/Entities';

@Component<FormMailTemplate>({
  components: {
    Editor
  }
})
export default class FormMailTemplate extends Vue {
  public service: MailTemplate = new MailTemplate();
  public promise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public entity: MailTemplateEntity| null = null;
  public showTags: boolean = false;
  
  @Prop({ default: null })
  public code!: string;
  
  @Prop({ default: null })
  public language!: string;
  

  public mounted() {
    const _: any = this;
    _.openLoading(this.$refs.containerLoader);
    this.service.fetchWithData({ id:0,code: this.code,language: this.language })
    .then((data: MailTemplateEntity) => {
      this.entity = data;
    })
    .catch(() => {})
    .finally(() => {
      _.closeLoading();
    })
  }

  get tinyIni() {
    const c: any = this;

    return Object.assign(c.tinyMCEini,{height: 500})
  }

  public save() {
    const _this: any = this;
    if(this.entity){
      this.entity.code = this.code;
      if(this.entity?.id != null && this.entity?.id>0) {
        this.service.update(this.entity)
        .then(()=> {_this.toastSuccess("Template de e-mail salvo com sucesso!")})
      } else {
        this.service.create(this.entity)
        .then(()=> {_this.toastSuccess("Template de e-mail salvo com sucesso!")})
      }
    }
  }
  
  public doSubmit(event: any) {
    // this.value.description = this.descricao;
    event.preventDefault();
    return false;
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";

</style>