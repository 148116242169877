<template>
  <div class="card-cartao-atual">
    <div class="row">
      <div class="col-md-12">
        <p></p>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <credit-card :metodo="serviceMetodo.entidade" />
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center my-3">
          <button class="btn btn-info text-uppercase" @click="showModalMP">
            <i class="fas fa-exchange-alt mr-2"></i>{{$t('Change Card')}}
          </button>
        </div>
      </div>
    </div>
    <!-- Modal substituir Cartao -->
    <modal-substituir-cartao
      id="modalChangePaymentMethod"
      @change="handleChange"/>
  </div>
</template>

<script lang="ts">
import MetodoPagamento from '@/core/Services/Payments/MetodoPagamento';
import { Component, Vue } from 'vue-property-decorator';
import ModalSubstituirCartao from './ModalSubstituirCartao.vue';

@Component<CartaoAtual>({
  components: {
    ModalSubstituirCartao
  }
})
export default class CartaoAtual extends Vue {
  public serviceMetodo: MetodoPagamento = new MetodoPagamento();
  
  public async created() {
    // starting async fetching the payment method
    await this.serviceMetodo.fetchCurrent();
  }
  
  public showModalMP() {
    this.$bvModal.show('modalChangePaymentMethod')
  }

  public handleChange(flag: boolean) {
    this.$bvModal.hide('modalChangePaymentMethod');
    this.serviceMetodo.fetchCurrent();
  }

}
</script>

<style lang="scss" scoped>

</style>
