<template>
  <div class="chart02" @mouseleave="handleSectionLeave">
      <vc-donut 
        v-if="sections"
        :background="bg" 
        :foreground="'transparent'"
        :size="size"
        unit="px" 
        :thickness="thickness"
        :has-legend="displayLegend"
        :legend-placement="placementLegend"
        :sections="sections" 
        :total="total"
        :start-angle="0"
        @section-mouseover="handleSectionHover"
        @section-mouseenter="handleSectionHover"
        @section-mousemove="handleSectionHover"
        >
      <h3 :class="'mb-0 ' + textClass">{{total}}</h3>
    </vc-donut>
    <div :id="'follow_me_at_' + scopeID" class="hover_me badge badge-primary shadow" style="display:none"></div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch  } from 'vue-property-decorator';


@Component
export default class DonutChart extends Vue {
  @Prop({default: () => { return []}})
  private series!: number[];

  @Prop({default: true})
  private displayLegend!: boolean;

  @Prop({default: 'bottom'})
  private placementLegend!: string;

  @Prop({default: () => { return []}})
  private labels!: string[];

  @Prop({default: () => { return []}})
  private chartColors!: string[];
  
  @Prop({default: 'white'})
  private bg!: string;
  
  @Prop({default: 'text-dark'})
  private textClass!: string;

  @Prop({default: 120})
  private size!: number;

  @Prop({default: 20})
  private thickness!: number;

  public sections: any[]|null = null;

  public defaultColors: string[] = ['#006400','#645c00','#07c60d','#d9c90f','#ff6d6d']

  public scopeID: string = '';

  get total() {
    return (this.series?.length > 0) ? this.series?.reduce((acc, value) => parseInt(""+acc)+parseInt(""+value), 0) : 0;
  }

  private seriesColors: {[key: string]: string} = {};

  @Watch('series')
  public loadSections() {
    if (this.series && this.labels && this.series.length == this.labels.length) {
      let total: number = 0;
      this.series.forEach((s: number, index: number) => {
        total += parseInt(""+s)
      })
      this.sections = [];
      this.series.forEach((s: number, index: number) => {
         this.sections![index] = { 
          label: this.labels[index], 
          value: parseInt(""+s),
          color: this.chartColors[index]
        }
      });
    }
    return this.sections
  }
  
  constructor() {
    super();
    this.scopeID = ("" + (new Date()).getTime())
  }

  public mounted() {
    this.loadSections()
  }

  public handleSectionLeave(e,e2) {
    const el: HTMLDivElement|null = document.querySelector('#follow_me_at_' + this.scopeID);
    if (el) {
      el.style.display= 'none'
    }

  }
  public handleSectionHover(e,e2) {
    const el: HTMLDivElement|null = document.querySelector('#follow_me_at_' + this.scopeID);
    if (el) {
      el.style.display = 'block'
      el.style.top = (e2.pageY-20) + 'px';
      el.style.left = (e2.pageX-20) + 'px';
      el.innerHTML = ` ${e.label} (${e.value}) `
    }
  
  }
}
</script>

<style lang="scss">
.chart02 {
  position: relative;
  .cdc-legend-item {
    font-size: 12px;
  }
  .cdc-legend-item-color {
    border-radius: 50%;
  }
  .cdc-legend {
    margin-top: 1.5rem !important;
  }
  .hover_me {
    position: fixed;
  }
}
</style>