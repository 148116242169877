<template>
    <div :class="align">
        <text-label :label="label" />
        <p class="mb-1">{{ value }}</p>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        label: String,
        value: String,
        align: String,
    }
})
export default class TextLabelValue extends Vue {}
</script>

<style lang="sass" scoped>
</style>
