import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Record extends Repository {
    public optionList: any[];
    public entidade!: any;

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/record', method: 'POST', headers: {} },
            delete: { url: '/v1/record/{id}', method: 'DELETE', headers: {} },
            fetch: { url: '/v1/record/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/record', method: 'GET', headers: {} },
            update: { url: '/v1/record/{id}', method: 'PUT', headers: {} }}));
        this.optionList = [];
    }

    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.rooms) {
            this.optionList = [];
            data.rooms.forEach((item: any) => {
                this.optionList!.push({
                    createdAt: item.createdAt,
                    key: item.key,
                    status: item.status,
                    text: item.key,
                    value: item.id,
                });
            });
        }
    }

    public startRecording(data?: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if ( data && data.session ) {
                super.create(data).then((data1: any) => {
                    this.entidade = data1;
                    resolve(data1);
                }).catch(reject);
            } else {
                reject({code: 400, message: this.getI18n().t('Necessário informar qual sessão para gravar') });
            }
        });
    }

    public stopRecording(supplier: number, session?: string, rid?: string): Promise<any> {
        return super.update({
            id: 0,
            rid,
            session,
            supplier,
        }).then((data: any) => {
            this.entidade = data;
            return data;
        });
    }

}


