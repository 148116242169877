<template>
  <transition name="fade">
  <div class="mt-5 container">
      <div class="row">
          <div class="col-md-6">
            <b-alert variant="info" show>
              <p>{{$t('Enter your old password and then the new password you want.')}}</p>
              <p>{{$t('Don`t forget that your new password must contain:')}}</p>
              <ul class="list-unstyled requisitos-troca-senha">
                <li>
                  <i v-if="!passwordStrengh.hasOneLower" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasOneLower" class="fa fa-check text-success"></i>
                  {{ $t("At least 1 lowercase alphabetical character") }}
                </li>
                <li>
                  <i v-if="!passwordStrengh.hasOneUpper" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasOneUpper" class="fa fa-check text-success"></i>
                  {{ $t("At least 1 uppercase alphabetical character") }}
                </li>
                <li>
                  <i v-if="!passwordStrengh.hasOneNumber" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasOneNumber" class="fa fa-check text-success"></i>
                  {{ $t("At least 1 numeric character") }}
                </li>
                <li>
                  <i v-if="!passwordStrengh.hasEightChars" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasEightChars" class="fa fa-check text-success"></i>
                  {{ $t("At least 8 characters or longer") }}
                </li>
                <li>
                  <i v-if="!passwordStrengh.hasSpecialChars" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasSpecialChars" class="fa fa-check text-success"></i>
                  {{ $t("At least 1 special character") }}
                </li>
                <li>
                  <i v-if="!passwordStrengh.hasSequence" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasSequence" class="fa fa-check text-success"></i>
                  {{ $t("Cannot contain sequences. Ex.: 123, abc") }} 
                </li>
              </ul>
            </b-alert>
          </div>
            <div class="col-md-6" >
              <b-form ref="form" >
                <text-input
                  variant="dark"
                  :type="'password'"
                  :autocomplete="'off'"
                  v-model="current"
                  :required="true"
                  :label="$t('Enter your current password')"
                  :placeholder="'*******'"
                />
                <text-input
                  variant="dark"
                  :type="'password'"
                  :autocomplete="'off'"
                  v-model="passwordStrengh.password"
                  :required="true"
                  :label="$t('Enter your new password')"
                  :placeholder="'*******'"
                />
                <text-input
                  variant="dark"
                  :type="'password'"
                  :autocomplete="'off'"
                  v-model="confirm"
                  :required="true"
                  :label="$t('Repeat your new password')"
                  :placeholder="'*******'"
                />
                <button type="button" class="btn btn-info" @click="save" v-promise-btn="{promise}">
                  <i class="fas fa-check mr-2"></i>{{$t('Save new password')}}
                </button>
              </b-form>
            </div>
    </div>
  </div>
  </transition>
</template>

<script lang="ts">
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import GestaoEmpresa from '@/core/Services/Empresa/GestaoEmpresa';
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';
import { CB, PasswordStrengh } from '@/core/util';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TrocaSenha extends Vue {
  private service: GestaoEmpresa|GestaoRecrutador;
  private passwordStrengh: PasswordStrengh|null = null;
  private current: string = "";
  private confirm: string = "";
  
  private promise: Promise<any>|null = null;

  get IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  constructor() {
    super();
    this.service = new GestaoEmpresa();
    this.passwordStrengh = new PasswordStrengh();
  }
  
  public isValidForm(midia: boolean) {
    let valid: boolean = true;

      if (!this.confirm || !this.current || !this.passwordStrengh!.password) {
        valid = false;
        this.$alert.addMsg('- '+this.$t('Password'));
      }

      if (this.passwordStrengh!.password != this.confirm) {
          valid = false;
          this.$alert.addMsg('- '+this.$t('Passwords entered in the fields (New Password and Confirm New Password) must be the same.'));
      }

      if (!this.passwordStrengh!.validatePassword()) {
          valid = false;
          this.$alert.addMsg('- '+this.$t('Your password has not passed the security requirements'));
      }

    if (!valid) {
        this.$alert.title = this.$t('Please inform')
        this.$alert.callBoxDanger()
        .catch(err => {
            console.log(err)
        })
    }
    return valid;
  }


  public async save() {

    let valid: boolean = this.isValidForm(false);

    if (!valid) {
        return;
    }
    
    if(this.IsRecruiter) {
      this.service = new GestaoRecrutador();
    } else {
      this.service = new GestaoEmpresa();
    }
    let rtoken: string|null = "";
    try {
      rtoken = await ClientAuthentication.fetchToken('company_login_change_pass');
    } catch(e) {
      console.log(e)
    }
    const dta: any = {
      rtoken,
      currentPassword: this.current,
      newPassword: this.passwordStrengh!.password,
      confirmNewPassword: this.confirm
    };
    const cdta = CB.genC(dta);
    const data: any = {
        id:0,
        passChange: true,
        ...cdta
    }
    this.promise = this.service.update(data).then((data) => {
        this.$alert.title = ' ';
        this.$alert.addMsg(this.$t('Your password has been changed successfully').toString());
        this.$alert.callBoxCheck(this.$t('Ok').toString());
        this.confirm = this.passwordStrengh!.password = this.current = "";
    })
    return false;
  }

  get passwordIsnotEmpty() {
    return this.passwordStrengh!.password;
  }


}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.box-formulario {
    border: 1px solid $border-gray;
    border-radius: 8px;

    .foto-perfil {
      img {
        width: 6em;
        border-radius: 50%;
      }
    }
  }

  .box-formulario {
    padding: 2em 0;
    > div {
      width: calc(50% - 1em);
    }
    .requisitos-troca-senha {
      padding: 0;
      list-style: none;
    }
  }

  
@include media("<desktop") {
    .box-formulario {
      > div {
        width: 100%;
      }
    }
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
}
</style>