<template>
  <modal :title="$t('Bulk invite talents') + ' ' + posicao" :show="show" :id="id" :size="'md'">
    <p>{{$t('Enter comma-separated emails (,)')}}</p>
    <b-form-textarea
      v-model="inputValue"
      rows="3"
      max-rows="10"
    ></b-form-textarea>

    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="massShare"
          v-promise-btn="{promise:currentPromise}"
        >
          {{$t("Send")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaEntity } from "@/core/models/Entities";
import Modal from '@/components/modal/Modal.vue'
import VagaShareService from "@/core/Services/Basic/VagaShare";

@Component({
  components: {
    Modal
  }
})
export default class ModalVagaShare extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public job!: VagaEntity;

  public inputValue: string = ''
  public vagaShareService = new VagaShareService();
  public currentPromise: any|null = null;

  get vaga() {
    return this.job;
  }

  get posicao() {
    return this.job?.posicao||"";
  }
  
  public massShare() {
    let emails: string = this.inputValue
    let valid: boolean = true
    if (emails) {
      let array = emails.split(",")
      let errArray: string[] = []
      array.forEach((el: string): any => {
        if (!el.match(/[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/)) {
          valid = false
          errArray.push(el);
        }
      })
      if (valid == true) {
        this.currentPromise = this.vagaShareService.create({
            id: this.vaga.id,
            invites: array,
            lang:this.$i18n.locale})
          .then((data) => {
            this.inputValue = ''
            // this.successAlert()
          }).catch((err) => { console.log(err) });
        this.$emit('vagaShare')
        this.successAlert()
      } else {
        this.errorAlert(errArray.join(', '))
      }
    }
    
  }

  public successAlert() {
    this.$alert.addMsg(this.$t('Successfully shared!'));
    this.$alert.callBoxCheck(this.$t('OK').toString());
  }

  public errorAlert(el:string) {
    this.$alert.addMsg(this.$t('Invalid e-mail') + ': ' + el)
    this.$alert.callBoxWarn()
  }
}
</script>

<style>

</style>