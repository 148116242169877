<template>
  <div>
    <router-view></router-view>  
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class RootPublicCleanArea extends Vue {

  updated() {
    // console.log('update root')
  }

  mounted() {
    document.body.style.overflow = 'auto';
    document.body.style.backgroundColor = '#fff';
  }

}
</script>


<style lang="scss" scoped>
</style>