import { render, staticRenderFns } from "./SecondFactor.vue?vue&type=template&id=71d457da&scoped=true"
import script from "./SecondFactor.vue?vue&type=script&lang=ts"
export * from "./SecondFactor.vue?vue&type=script&lang=ts"
import style0 from "./SecondFactor.vue?vue&type=style&index=0&id=71d457da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d457da",
  null
  
)

export default component.exports