<template>
  <transition>
    <div class="container-children mobile">
      <div class="room-card">
        <section class="content">
          <hr class="d-block d-md-none d-block d-md-none my-5">
          <div class="box">
            <div class="row">
              <div class="col-md-4 py-3 py-md-0">
                <h1 class="text-center text-md-left">{{$t("Interview Room")}}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="nav justify-content-center">
                  <li class="nav-item" :class="{ 'active' : activeNav == 0}">
                    <a class="nav-link text-uppercase" @click="setActiveNav(0)">{{$t("Create Room")}}</a>
                  </li>
                  <li class="nav-item" :class="{ 'active' : activeNav == 1}" v-if="enableCalendar">
                    <a class="nav-link text-uppercase" @click="setActiveNav(1)">{{$t("Calendar")}}</a>
                  </li>
                  <li class="nav-item" :class="{ 'active' : activeNav == 2}">
                    <a class="nav-link text-uppercase" @click="setActiveNav(2)">{{$t("Interviews")}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="activeNav == 0" >
              <entrevista-room @change="setActiveNav"/>
            </div>
            <div v-if="activeNav == 1" >
              <calendario-room/>
            </div>
            <div v-if="activeNav == 2" >
              <room-folders />
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import RoomService from "@/core/Services/Video/Room";
import EntrevistaRoom from "@/views/live-interview/partials/EntrevistaRoom.vue";
import CalendarioRoom from "@/views/live-interview/partials/CalendarioRoom.vue";
import RoomFolders from "@/views/live-interview/partials/RoomFolders.vue";
import BrowserIssue from "@/plugins/browser-issue";
import { EmpresaEntity } from '@/core/models/Entities';

@Component({
  components: {
    EntrevistaRoom,
    CalendarioRoom,
    RoomFolders
  }
})
export default class Rooms extends Vue {
    public room: RoomService;
    public activeNav = 0;

    
    @Getter("companyInfo", { namespace: "profile" })
    public empresa?: EmpresaEntity;
    
    constructor() {
        super();
        this.room = new RoomService();
    }

    get enableCalendar() {
      return !this.empresa?.configuracoes?.ENABLE_HIDE_SCHEDULE_MEETING
    }

    public async mounted() {
      const x = location.hash.substring(3,location.hash.length);
      if (x && !isNaN(parseInt(x))) {
        this.activeNav = parseInt(x);
      }
    }

    public setActiveNav(nav: number) {
      this.activeNav = nav;
      history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'') + '#ac' + nav )
    }

    public async criarSala() {
      this.room.createLiveInterview(2).then((data) => {
          if (data.id) {
              this.$router.push({ name: 'RoomManagerFull',params: { sid: data.key }, query:  { lang: this.$i18n.locale }})
          }
      }).catch((err) => {
          console.log(err);
      });
    }

    get isProductionEnv() {
      return (process.env.NODE_ENV == 'production');
    }

    public closeRoom(id: any) {
        this.room.close(id).then((data) => { console.log(data) })
    }

    public async enterRoom(key: any) {
      this.$router.push({ name: 'RoomManager',params: { sid: key}, query:  { lang: this.$i18n.locale }})
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.room-card {
  background-color: white;
  padding: 1.5em;
  width: 100%;
  border-radius: 5px;
  .dropdown-text {
    font-size: 0.8em;
  }
  .list {
    .btn-sm {
      line-height: 1;
    }
    h1 {
      font-size: 2.5em;
    }
    &__vagas {
      margin-bottom: 1em;
    }
  }

  .nav {
    border-bottom: solid 1px lightgray;
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
}
</style>