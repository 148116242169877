<template>
  <div class="videos-questions">
    <div class="d-flex justify-content-center videos-questions__question" v-if="perguntaAtual">
      <p>{{ perguntaAtual.numeroPergunta+". "+perguntaAtual.pergunta }}</p>
    </div>
    <div class="row">
      <div class="col-md-2 videos-questions__buttons-question">
        <div class="d-flex flex-md-column order-last order-md-first">
          <b-button 
            :pressed="(pergunta && perguntaAtual && pergunta.id == perguntaAtual.id) ? true : false"

            :class="{
              'mx-md-0 mx-2': true,
              'no-answer': !videosMap.has(pergunta.id),
            }" 
            variant="primary"
            v-for="pergunta in vaga.perguntas" 
            :key="pergunta.id" 
            @click="selectPergunta(pergunta)">
              <span v-if="videosMap.has(pergunta.id)">{{pergunta.numeroPergunta}}</span>
              <span v-else>!</span>
              <p>{{$t("Question")}}</p>
          </b-button>
        </div>
      </div>
      <div :class="'col-md-10 video order-first order-md-last '+ ((isBlindActive)?'blindInterviewWrapper':'')" v-if="perguntaAtual && videosMap.has(perguntaAtual.id)">
        <!-- <div class="xd m-md-0 my-4"></div> -->
        <video-player :options="videosMap.get(perguntaAtual.id)" :distorced="videosMap.get(perguntaAtual.id).audioDistorcedURLWAV" :blind="isBlindActive" v-bind:key="perguntaAtual.id"/>
      </div>

      <!-- Candidato não respondeu a entrevista -->
      <div class="videos-questions__no-response col-md-10 d-flex justify-content-center" v-else>
        <div class="row justify-content-center">
          <div class="col-md-12 row justify-content-center align-items-center">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <span>{{$t("The Candidate did not answer this question")}}</span>
        </div>
      </div>
    </div>
    <div class="row my-3 d-flex justify-content-center" v-if="vaga && candidato">
      <div class="col-md-12 show-competencies">
        <b-nav tabs fill>
          <b-nav-item 
            v-if="candidatura.vagaHabilidades && candidatura.vagaHabilidades.length>0"
            v-b-tooltip.hover
            :title="$t('Skills of this talent according to those registered in the vacancy')"
            @click="showCandidateSkills">
            <i class="icon-graficos mr-2"></i>
            {{$t("View Skills")}}
          </b-nav-item>
          <b-nav-item 
            v-if="enableMatch && candidatura.matchCompany && candidatura.matchCompany.percentage"
            v-b-tooltip.hover
            :title="$t('View Match Details with this talent')"
            @click="showCandidateMatch">
            <i class="fa fa-user mr-2"></i>
            {{$t("Ver match cultural")}}</b-nav-item>
          <b-nav-item 
            v-if="enableMultiEval"
            v-b-tooltip.hover
            v-b-toggle.sidebar-avaliacao
            :title="$t('View History of Reviews of this talent')"
            >
            <i class="fa fa-tasks mr-2"></i>
            {{$t("See reviews")}}
          </b-nav-item>
          <b-nav-item 
            v-if="candidatura.respostaCorte && candidatura.respostaCorte.length>0"
            v-b-tooltip.hover
            v-b-toggle.sidebar-corte
            :title="$t('Qualify Questions')"
            >
            <i class="icon-mensagens mr-2"></i>
            {{$t("Qualify Questions")}}</b-nav-item>
        </b-nav>
        
         <b-sidebar
            v-if="enableMultiEval"
            id="sidebar-avaliacao"
            :title="$t('Reviews of {name}', {name: candidato.primeironome||candidatura.id})"
            width="500px"
            z-index="calc(1030 + 9)"
            @shown="sbShow"
            @hidden="sbHidden"
            lazy
          >
          <div class="px-3 py-2">
            <avaliacoes ref="sidebarAvaliacao" :candidato="candidato" :vaga="vaga" />
          </div>
        </b-sidebar>
        
         <b-sidebar
            id="sidebar-corte"
            :title="$t('Qualify Questions')"
            width="500px"
            @shown="sbShow"
            @hidden="sbHidden"
            lazy
          >                       
          <div class="px-3 py-2" v-if="candidatura.respostaCorte">
            <div class="respostas" v-for="it in candidatura.respostaCorte" :key="it.pergunta">
              <div class="pergunta">{{it.pergunta}} <strong>({{it.resposta == 0 ? $t("No") : $t("Yes") }})</strong>
                &nbsp; <i class="text-success fa fa-check-circle" v-b-tooltip.hover :title="$t('It passed')"  v-if="it.resposta == it.eliminatoria"></i>
                <i class="text-danger fa fa-times-circle" v-b-tooltip.hover :title="$t('Did not pass')" v-if="it.resposta != it.eliminatoria"></i>
              </div>
            </div>
          </div>

        </b-sidebar>

        <modal-candidate-skills 
          id="modalCandidateSkills"
          :vaga="vaga"
          :candidatura="candidatura"
          :is-public="isPublic"
          :token="token"/>
        <modal-candidate-match
          id="modalCandidateMatch"
          :vaga="vaga"
          :candidatura="candidatura"
          :is-public="isPublic"
          :token="token"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import PublicCandidaturaService from "@/core/Services/Empresa/PublicCandidatura";
import VideoPlayer from "@/components/video/VideoPlayer.vue";
import ModalCandidateSkills from "@/components/modal/ModalCandidateSkills.vue"
import ModalCandidateMatch from "@/components/modal/ModalCandidateMatch.vue"
import AvaliacoesProfissional from "@/components/avaliacao/AvaliacoesProfissional.vue"
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import { 
  CandidaturaEntity, 
  VagaPerguntaEntity,
  VagaVideoRespostaEntity, 
  VagaEntity, 
  CandidatoEntity, 
  ExperienciaEntity, 
  FormacaoEntity, 
  EmpresaEntity} from "@/core/models/Entities";


@Component({
  components: {
    'video-player': VideoPlayer,
    'button-icon': ButtonIcon,
    'modal-candidate-skills': ModalCandidateSkills,
    'modal-candidate-match': ModalCandidateMatch,
    'avaliacoes': AvaliacoesProfissional
  },
})
export default class VideoInterview extends Vue {
  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Prop({default: () => { new CandidaturaEntity() }})
  public candidatura!: CandidaturaEntity;

  @Prop({default: () => { new VagaEntity() }})
  public vaga!: VagaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;

  @Prop({ default: false })
  public token!: string;
  
  public candidato: CandidatoEntity|null = null;
  
  private videosResposta!: VagaVideoRespostaEntity[];
  private vagaPerguntas!: VagaPerguntaEntity[];
  private videosMap:Map<string,any> = new Map<string,any>();
  private perguntaAtual: VagaPerguntaEntity|null = null;
  private activePergunta: boolean = false;
  
  @Watch('candidatura')
  public handleChange() {
    this.loadAll()
  }

  @Watch('vaga')
  public handleChangeV() {
    this.loadAll()
  }

  get isBlindActive(): boolean
  {
    return (this.vaga && this.candidatura && this.vaga.blind == 1 && this.candidatura.status !== 'APROVADO');
  }

  get enableMatch() {
    return (this.empresa?.configuracoes?.ENABLE_CULTURAL_MATCH == true);
  }

   get enableMultiEval () {
    if(!this.isPublic) {
      return (this.empresa?.configuracoes['ENABLE_MULTI_EVALUATION'] == true);
    } else {
      return false;
    }
  }

  public sbShow() {
    console.log('fetchdata');
    //(this.$refs.sidebarAvaliacao as any).fetchData()
  }

  public sbHidden() {
    console.log('hideside');
    }
  public mounted() {
    this.loadAll()
  }

  public selectPergunta(p: VagaPerguntaEntity) {
    this.perguntaAtual = p;
  }

  public loadAll() {
    this.videosMap = new Map<string,any>()
    this.candidato = this.candidatura.candidato||new CandidatoEntity()
    this.videosResposta = this.candidatura.videosRespostas||[]
    this.vagaPerguntas = this.vaga!.perguntas||[]
    if (this.videosResposta.length>0) {
      this.videosResposta.forEach((element: any) => {
        //console.log(element)
        this.videosMap.set(element.idpergunta, {
              videoURLMP4: element.videoURLMP4,
              audioDistorcedURLWAV: element.audioDistorcedURLWAV,
              autoplay: false,
              controls: true,
              muted: (element.audioDistorcedURLWAV && this.isBlindActive),
              sources: [
                  {
                      src: element.videoURLMP4,
                      type: "video/mp4"
                  }
              ]
          })
      });
      this.selectPergunta(this.vagaPerguntas[0])
    }
  }

  public goCandidatoPerfil() {
    let perfil = this.$router.resolve({path: '/c/'+this.candidatura.candidato.nomeUrl, query: {lang: this.$i18n.locale }});
    window.open(perfil.href, '_blank')
  }

  public showCandidateSkills() {
    this.$bvModal.show('modalCandidateSkills')
  }

  public showCandidateMatch() {
    this.$bvModal.show('modalCandidateMatch')
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.videos-questions {
  .show-competencies button {
    font-size: 0.9rem;
  }
  &__question p {
    font-size: 1.2rem;
  }
  &__buttons-question {
    padding-right: 5px;
    overflow-y: auto;
    max-height: 405px;
    p {
      margin-bottom: 5px;
    }
    button {
      background-color: rgb(238, 250, 255);
      // min-height: 7em;
      border: 0;
      border-radius: 5px;
      color: rgb(0, 171, 204);
      margin-bottom: 10px;
      font-size: 0.8rem;
      text-align: center;
      span {
        font-size: 1.5rem;
      }
      &:hover {
        background-color: rgb(95, 170, 201);
        color: white;
      }
      &.no-answer {
        background-color: white;
        border: 1px solid rgb(170, 84, 192);
        color: rgb(170, 84, 192);
        &:hover {
          background-color: rgb(170, 84, 192);
          color: white;
        }
      }
    }
  }

  &__no-response {
    min-height: 300px;
    color: orange;
    .fa-exclamation-triangle{
      font-size: 45px;
    }
    span {
      font-size: 1rem;
    }
  }
  .video {
    .xd {
      width: 100%;
      height: 312px;
      border-radius: 5px;
      background-color: black;
    }
  }
}
</style>