import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';

export default class GestaoEmpresa extends Repository {

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/gestao-empresa', method: 'POST', headers: {} },
            // delete: { url: '/v1/gestao-empresa/{id}', method: 'DELETE', headers: {} },
            // fetch: { url: '/v1/gestao-empresa/{id}', method: 'GET', headers: {} },
            // fetchAll: { url: '/v1/gestao-empresa', method: 'GET', headers: {} }, // tem parametro de Busca 's'
            update: { url: '/v1/gestao-empresa/{id}', method: 'PUT', headers: {} }}));
    }

    public call(key: string, data: any, ctype: string): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call(key, data, ctype).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call(key, cdta, ctype).then(resolve).catch(reject);
                });
            }
        });
    }


}
