<template>
  <modal :title="$t('Tags')" :show="show" :id="id" :size="'md'">
    <div v-if="candidatura.tags">{{candidatura.tags}}</div>
    <div v-else> {{$t('No tags identified')}} </div>
    
    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="md"
          class="float-right"
          @click="hideModal(id)"
        >
          {{$t("Ok")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import PublicCandidaturaService from "@/core/Services/Empresa/PublicCandidatura";
import Modal from '@/components/modal/Modal.vue'

@Component({
  components: {
    Modal
  }
})
export default class ModalTags extends Vue {
  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
	public pubCandidaturaService!: PublicCandidaturaService

  constructor() {
    super()
    this.pubCandidaturaService = new PublicCandidaturaService()
    // this.candidatura = new CandidaturaEntity()
  }
  
  public hideModal(id) {
    this.$bvModal.hide(id)
  }

}
</script>

<style>

</style>