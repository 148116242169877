<template>
  <div class="box-container d-flex flex-column mb-5">
    <div class="row">
      <div class="col-md-12 mb-4">
        <h1 class="text-md-left text-center">{{ $t("Company profile") }}</h1>
      </div>
    </div>
    <b-container>
      <div class="row">
        <div class="col-12">
          <b-nav tabs fill align="center">
            <b-nav-item v-for="(h, i) in tabOptions" :key="i" @click="setActiveNav(h.nav)" :active="activeNav == h.nav">
              {{ $t(h.title) }}
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </b-container>
    <!-- Aba de dados da conta -->
    <div v-if="activeNav == 0">
      <conta-empresa v-if="!IsRecruiter" />
      <conta-recrutador v-else />
    </div>
    <!-- Aba de dados da empresa -->
    <div v-if="activeNav == 1 && IsAdmin">
      <dados-perfil />
    </div>
    <!-- Aba de adição de recrutador -->
    <div v-if="activeNav == 2 && IsAdmin">
      <recrutadores />
    </div>
    <!-- Aba de adição de recrutador -->
    <div v-if="activeNav == 3 && !IsRecruiter">
      <extrato :empresa="empresa" />
    </div>
    <!-- Aba de troca de senha -->
    <div v-if="activeNav == 4">
      <troca-senha />
    </div>
    <!-- Aba de troca de senha -->
    <div v-if="activeNav == 5 && IsAdmin && permitMailTemplate">
      <mail-templates />
    </div>
    <!-- Aba de troca de senha -->
    <div v-if="activeNav == 6 && IsAdmin && permitEquipes">
      <equipes />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";
import ContaEmpresa from './partials/ContaEmpresa.vue';
import ContaRecrutador from './partials/ContaRecrutador.vue';
import DadosPerfil from './partials/DadosPerfil.vue';
import PaginaInstitucional from './partials/PaginaInstitucional.vue';
import TrocaSenha from './partials/TrocaSenha.vue';
import Extrato from '@/views/plano/Extrato.vue';
import Recrutadores from '@/views/recrutadores/Recrutadores.vue';
import MailTemplates from '@/views/mail-template/MailTemplates.vue';
import Equipes from '@/views/equipes/Equipes.vue';
import { RecrutadorEntity, EmpresaEntity } from '../../core/models/Entities';

@Component<Perfil>({
  components: {
    ContaEmpresa,
    ContaRecrutador,
    DadosPerfil,
    TrocaSenha,
    Recrutadores,
    Extrato,
    PaginaInstitucional,
    MailTemplates,
    Equipes
  }
})
export default class Perfil extends Vue {

  public activeNav: number = -1;
  public tabOptions: any[] = [];

  @Action('fetchEmpresaInfo', { namespace })
  public fetchEmpresaInfo!: any;

  @Getter('recruiterInfo', { namespace })
  public recrutador!: RecrutadorEntity;

  @Getter('companyInfo', { namespace })
  public empresa!: EmpresaEntity;

  public loadTab() {
    const x = location.hash.substring(3, location.hash.length);
    switch (this.$route.name) {
      case "Teams":
      case "EditTeam":
        this.activeNav = 6;
        break;
      case "Recruiters":
        this.activeNav = 2;
        break;
      default:
        if (x && !isNaN(parseInt(x))) {
          this.activeNav = parseInt(x);
        } else {
          this.activeNav = 0;
        }
        break;
    }
  }

  public created() {
    console.log("created")
    this.loadTab();
  }

  public async mounted() {

    const date = this.empresa?.perfil?.dtFinalPlano?.date?.slice(0, 10)
    const datePlan = new Date(date || "")
    const dateNow = new Date()

    if (!this.recrutador && !this.empresa) {
      await this.fetchEmpresaInfo();
    }
    this.tabOptions.push({ title: "Account Information", nav: 0 });

    if (!this.IsRecruiter && datePlan <= dateNow)
      this.tabOptions.push({ title: "My Plan", nav: 3 });

    this.tabOptions.push({ title: "Change Password", nav: 4 });

    if (this.IsAdmin) {
      this.tabOptions.push({ title: "Company data", nav: 1 });
      this.tabOptions.push({ title: "Recruiters", nav: 2 });
      this.tabOptions.push({ title: "Templates", nav: 5 });
      this.tabOptions.push({ title: "Teams", nav: 6 });
    }

  }

  get IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get IsAdmin() {
    return (sessionStorage.getItem("profile_type") == 'recruiter' && this.recrutador && this.recrutador.permissaoAdm == "Y") || (sessionStorage.getItem("profile_type") == 'company')
  }

  get permitMailTemplate() {
    return (this.empresa?.configuracoes?.ENABLE_EDIT_MAIL_TEMPLATE == true);
  }

  get permitEquipes() {
    return (this.empresa?.configuracoes?.ENABLE_RECRUITING_GROUP == true);
  }

  public setActiveNav(nav: number) {
    this.activeNav = nav;
    switch (this.activeNav) {
      case 2:
        this.$router.push({ name: "Recruiters", query: { lang: this.$i18n.locale } })
        break;
      case 6:
        this.$router.push({ name: "Teams", query: { lang: this.$i18n.locale } })
        break;
      default:
        this.$router.push({ name: "Perfil", query: { lang: this.$i18n.locale }, hash: '#pr' + nav })
        break;
    }
  }

  public saveEmpresa() {

  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.box-container {
  min-height: auto;

  .nav {
    .nav-item {
      cursor: pointer;

      a {
        color: $border-nav-blue;

        &.active {
          color: $blue;
        }
      }

    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;

    h1 {
      font-size: 2em;
    }

    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>