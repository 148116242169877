
import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';


export default class HistoricoCredito extends Repository {
    constructor() {
        super(
            new ApiRouter({
                create: { url: '/v1/historico-credito', method: 'POST', headers: {} },
                fetch: { url: '/v1/historico-credito/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/historico-credito', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/historico-credito/{id}', method: 'PUT', headers: {} },
            }));
    }
}


