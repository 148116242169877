
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { EquipeEntity } from '@/core/models/Entities';


export default class Equipe extends Repository {

  public entidade: EquipeEntity;
  public optionList?: any[];

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/equipe', method: 'POST', headers: {} },
      fetch: { url: '/v1/equipe/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/equipe', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/equipe/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/equipe/{id}', method: 'DELETE', headers: {} }
    }));
    this.entidade = new EquipeEntity();
    this.optionList = [];
  }
  
  public save(): Promise<any> {
    if (this.entidade.id && this.entidade.id > 0) {
      return super.update(this.entidade).then((data: EquipeEntity) => {
        this.entidade = data;
        return data;
      });
    } else {
      return super.create(this.entidade).then((data: EquipeEntity) => {
        this.entidade = data;
        return data;
      });
    }
  }
  

}


