<template>
  <transition name="fade">
    <div class="container-children flex-column">
      <b-card>
        <b-card-body>
          <b-container>
            <b-row >
              <b-col md="12" class="p-0 d-flex flex-wrap align-items-center justify-content-center">
                <b-img src="https://storage.googleapis.com/jobecam-assets/Dashboard_home.png" style="width:30%" fluid alt="Dash"></b-img>
              </b-col>
              
              <b-col md="12" class="d-flex flex-wrap align-items-center justify-content-center">
                <h3 >{{$t('Choose your anonymous interview option')}}</h3>
              </b-col>
              <b-col md="5" offset-md="1" class="p-0d-flex align-items-center justify-content-center">
                <b-button size="lg" block
                  variant="pink"
                  class="shadow p-5 text-white" 
                  :to="{path:'/my-jobs-openings/new-simple', hash: '#new-jobs', query: {lang: $i18n.locale}}"> 
                    <h4 class="m-0">{{$t("Create pre-recorded interview")}} <i class="fas fa-eye-slash" /> </h4>
                </b-button>
              </b-col>
              <b-col md="5" class="p-0 d-flex align-items-center justify-content-center">
                <b-button size="lg" block
                  variant="orange" class=" ml-2 shadow p-5 text-white" 
                  :to="{path:'/rooms', hash: '#ac0', query: {lang: $i18n.locale}}"> 
                  <h4 class="m-0">{{$t("Create live room")}} <i class="fas fa-video-slash"></i></h4>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-card>
    </div>
  </transition>
</template>


<script lang="ts">
// List Company evaluations
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue {

} 
</script>

<style lang="scss" scoped>

</style>