<template>
   <b-modal id="modalNewMessage" centered :hide-header="true"
      :hide-footer="true"
      size="lg"
      title="Large Modal"
    >
      <div class="modal-content">
        <button class="btn btn-danger" @click="$bvModal.hide('modalNewMessage')">
          <i class="icon-cancelar"></i>
        </button>
        <div class="row">
          <div class="col-12">
            <h1>{{$t('New message')}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Destination')"
              :placeholder="'jonh@jobecam.com'"
            />
          </div>
          <div class="col-12">
            <text-input
              variant="dark"
              :required="true"
              :label="$t('Message title')"
              :placeholder="$t('Interview with candidate')"
            />
          </div>
          <div class="col-12">
            <text-area
              variant="dark"
              :required="true"
              :placeholder="$t('Write your message')  "
              :rows="10"
              :label="$t('Message')"
            />
          </div>
          <div class="col-12 mt-1 text-center">
            <b-button
              variant="success"
              size="sm"
              class="btn success rounded-pill text-uppercase"
              pill
            >{{$t('Send message')}}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalNewMessage extends Vue {
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.modal-content {
  padding: 4em;
  h1 {
    font-size: 1.9em;
    padding-bottom: 1.5em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }
  button.success {
    width: 30%;
    color: #fff;
    font-size: 0.8em;
    height: 3.5em;
    border-color: $green;
    background-color: $green;
    &:hover {
      color: #fff;
      background-color: $green-light;
    }
  }
}
</style>