<template>
  
    <button
      type="button"
      :class="btnClass"
      @click="openFormFile"
    >
    <b-form-file
     class="d-none"
      ref="formFile"
      accept="image/*"
      @change="handleChange"
      hidden
    ></b-form-file>
      <slot></slot>
      <icon :name="iconName"></icon>
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BFormFile } from 'bootstrap-vue';

import Icon from '@/components/icon/Icon.vue';

@Component<ButtonIcon>({
  components: {
    'icon': Icon
  },
})
export default class ButtonIcon extends Vue {

  @Prop({default: 'info'}) public iconName!: string;
  @Prop({default: false }) public inverse!: string;
  @Prop({default: 'button-icon' }) public btnClass!: string;

  public clickEvent(event: any) {
    this.$emit('click', event)
  }
  
  private handleChange(ev: any) {
    this.$emit('change', ev.target.files[0])
  }

  private openFormFile() {
    (<any>(<BFormFile>this.$refs.formFile).$el.childNodes[0]!).click();
  }
}
</script>

<style lang="scss" scoped>
/**
** Button Icon
**/
.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  text-align: center;
  border: 0;
  outline: 0;

  &--inverse {
    color: #ffffff;
  }
}

$size-sm: 50px;
$size-md: 100px;
$size-lg: 200px;
/**
** Avatar
**/
.avatar-upload {
  position: relative;
  display: inline-flex;
  padding: 0;
  cursor: pointer;
}
</style>

<style lang="scss">
  .avatar-upload .b-form-file {
    display: none;
  }
  
</style>