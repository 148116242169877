<template>
  <img class="logo" src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/logos/padrao/jobecam_branco_play_azul_escuro.png" alt="">
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Logo extends Vue {

  @Prop({default: false }) public inverse!: string;
  @Prop({default: false }) public small!: boolean;

}
</script>

<style lang="scss" scoped>
/**
** Logo
**/
.logo {
  display: flex;
  width: 11em;
  height: auto;

  &__small {
  width: 7em !important;
  }
  
}
</style>