<template>
    <div class="info-tag mr-1">
        <h2 v-if="size=='elarge'"><b-badge :variant="variant">{{text}} <span> <i :class="icon" @click="handleClick"></i> </span> </b-badge></h2>
        <h3 v-else-if="size=='large'"><b-badge :variant="variant">{{text}} <span> <i :class="icon"  @click="handleClick"></i> </span> </b-badge></h3>
        <h4 v-else-if="size=='medium'"><b-badge :variant="variant">{{text}} <span> <i :class="icon"  @click="handleClick"></i> </span> </b-badge></h4>
        <h5 v-else-if="size=='small'"><b-badge :variant="variant">{{(text)}} <span> <i :class="icon"  @click="handleClick"></i> </span> </b-badge></h5>
        <h6 v-else> <b-badge :variant="variant">{{text}} <span> <i :class="icon"  @click="handleClick"></i> </span></b-badge></h6>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tag extends Vue {
    @Prop({default: ''})
    public text!: any;

    @Prop({default: ''})
    public size!: string;

    @Prop({default: ''})
    public icon!: string;

    @Prop({default: 'info'})
    public variant!: string;

    constructor() {
        super();
    }
    public handleClick() {
        this.$emit('click')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>

    .info-tag
        h5
            margin: 0;
    input
        border: 1px solid #006FF3 !important
        border-radius: 6px !important

    .form-group label
        color: #006FF3

    ::placeholder
        color: #006FF3 !important
</style>

