<template>
  <div class="row">
    <div class="col-md-12 mb-4">
      <img class="header-img" src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/plural/header-logo-jobecam-plural.png" alt="Jobecam Plural Titulo">
      <hr/>
      <h1 v-if="titulo" class="text-md-middle text-center">{{titulo}}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderPlural extends Vue {
  @Prop({ default: '' }) titulo!: string;

  constructor() {
    super();
  }  

}
</script>


<style lang="scss" scoped>
  .header-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
</style>