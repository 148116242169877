<template>
  <b-container slot="head" class="m-content py-0">
    <div class="row" no-gutters>
      <div class="col-md-12" align="center">
        <!-- Title -->
        <div class="row m-content__title">
          <div class="col-12 mb-4">
            <h1 class="mb-0 pb-1">{{$t('Limit number of interviews')}}</h1>
            <p class="mt-0">{{$t('Add the number of jobcoins (interviews) for this job')}}</p>
          </div>
        </div>
        <!-- Description -->
        <div class="row m-content__description mb-4 d-flex justify-content-center">
          <b-form-checkbox size="lg" v-model="selected" switch @change="jobcoins = 0">
            {{ $t('Debit from general balance')}}
            <b-icon icon="question" id="iconsaldo" class="h5 mb-2" />
          </b-form-checkbox>
          <b-tooltip target="iconsaldo" placement="bottom" custom-class="tooltip-infomation">
            <p>{{ $t('By choosing this option you will not limit the number of jobcoins for this job, and will be automatically deducted from your general jobcoin bank.') }}</p>
          </b-tooltip>
        </div>

        <!-- Jobcoin input -->
        <div class="row m-content__jobcoin-input text-center mb-4">
          <div :class="{'col-4 d-flex align-items-center justify-content-center flex-column ': true, 
          'border border-light': !selected,
          'shadow bg-light border border-primary': selected}">
              <h1>{{saldoTotal}}</h1>
              <small>{{ $t('General balance') }}</small>
          </div>
          <div :class="{'col-4 d-flex align-items-center justify-content-center flex-column': true, 
          'border border-light': selected,
          'shadow bg-light border border-primary': !selected}">
            <text-input
              variant="dark"
              :placeholder="'00'"
              :disabled="selected"
              class="m-content__jobcoin-input--input"
              v-model="jobcoins"
            />
            <p>{{$t('Insert Jobcoins')}}</p>
          </div><div :class="{'col-4 d-flex align-items-center justify-content-center flex-column ': true}">
              <h1>{{totalVaga}}</h1>
              <small>{{ $t('Current balance in vacancy') }}</small>
          </div>
        </div>

        <!-- Buttons -->
        <div class="row m-content__buttons">
          <div :class="{'col-12 mt-1 pr-0 pl-0 d-flex' :true, 
                    'justify-content-end' : true }" >

            <b-button variant="info" @click="save(vaga)" v-promise-btn="{promise}">
              <i class="fas fa-coins mr-2"></i>
              {{$t(LabelButtonSubmit)}}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>


<script lang="ts">
import GestaoVagaService from "@/core/Services/Empresa/GestaoVaga";
import { VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";



@Component
export default class FormJobcoins extends Vue {

  @Prop({ default: "" })
  public id!: string;

  @Prop({ default: "Apply and save" })
  public LabelButtonSubmit!: string;
  
  @Action("fetchSaldo", { namespace : 'profile' })
  public fetchSaldo: any;

  @Getter("saldInfo", { namespace: 'profile' })
  public saldoTotal!: number;

  @Prop({ default: () => { return new VagaEntity(); } })
  public vaga!: VagaEntity;

  public minCoins: number = 0;
  public saldo: number = 0;
  public vagasService = new GestaoVagaService();
  public jobcoins: number | null = null;
  public selected: boolean = false;
  public promise: Promise<any>|null = null;

  get getPosition() {
    if (!this.jobcoins) return "";
    const percent = ((100 * (this.jobcoins || 0)) / this.saldo);
    return this.jobcoins ? ( (percent >= 90 ) ? `left:90%` : `left:${percent}%`) : 'left:0%';
  }

  public mounted() {
    this.vagasService = new GestaoVagaService();
    this.selected = this.vaga.limited == 1 ? false : true;
    this.getSaldoCreditosVaga()
  }

  public getSaldoCreditosVaga() {
    let total = 0
    this.vaga.historicoCredito.forEach((element: any,index: number) => {
      total = (element.tipo == "E") ? total+element.creditoQtd : ((element.tipo == "S") ? total-element.creditoQtd : total );
    });
    this.saldo = parseInt(""+total);
  }

  get totalVaga() {
    return 0+this.saldo+(parseInt(""+this.jobcoins)||0);
  }

  public save(vaga: any) {
    const hasInvalidInterviewQuestions = vaga.perguntas.filter((question) => {
      return !question?.idioma || !question?.pergunta;
    }).length > 0;

    if (hasInvalidInterviewQuestions) {
      this.$emit('prevStep');
      this.alertInvalidInterviewQuestion();
      return;
    }

    const hasInterviewQuestions = vaga.perguntas.length > 0;

    if (!hasInterviewQuestions) {
      this.alertInterviewMustHaveAtLeastOneQuestion();
      this.$emit('prevStep');
      return;
    }

    if (this.jobcoins || this.selected) {
      const data: any = { id: vaga.id };
      if (this.selected) {
        data["limited"] = 0;
      } else {
        data["limited"] = 1;
        data["creditoQtdAdd"] = this.jobcoins;
      }
      this.promise = this.vagasService
        .update(data)
        .then(async (data: any) => {
          await this.fetchSaldo();
          this.$emit("hideModalAddJobcoins",data);
          this.successAlert();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      console.log("Insira jobcoins");
      this.$emit("hideModalAddJobcoins");
    }
  }

  public successAlert() {
    this.$alert.addMsg("" + this.$t("Jobcoins added!"));
    this.$alert.callBoxCheck(this.$t("Ok").toString());
  }

  private alertInterviewMustHaveAtLeastOneQuestion() {
    this.$toast.addTitle(this.$t('No interview questions').toString());
    this.$toast.addMsg(this.$t('The interview must have at least one interview question'));
    this.$toast.variant = 'danger';
    this.$toast.open();
  }

  private alertInvalidInterviewQuestion() {
    this.$toast.addTitle(this.$t('Invalid interview question').toString());
    this.$toast.addMsg(this.$t('Make sure all interview questions have title and language defined'));
    this.$toast.variant = 'danger';
    this.$toast.open();
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/main";

.m-content {
  padding: 3em;
  p {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.9em;
    padding-bottom: 1em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }
  button.secondary {
    // color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px;
  }

  button.primary {
    color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px;
  }
  .range_scope {
    position: relative;
  }
  .range_min {
    position: absolute;
    left: 0;
    color: gray;
  }
  .range_max {
    position: absolute;
    right: 0;
    text-align: right;
    color: gray;
  }
  .range_value {
    position: absolute;
    right: 0;
    font-size: 1.5em;
    color: green;
  }
}
</style>

<style lang="scss">
#modalAddJobcoins .modal-body {
  padding: 0;
}

#modalAddJobcoins .modal-dialog {
  width: 650px;
}

.m-content__jobcoin-input--input input.dark {
  font-size: 3rem;
  text-align: center;
}
</style>