<template>
<b-container>
  <b-row class="pt-5">
    <b-col md="12">
      <div class="d-flex justify-content-center mb-3" v-if="!currentRoom">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-col>
  </b-row>
  <b-row v-if="currentRoom">
     <b-col md="12">
        <div class="text-truncate">  <i class="fas fa-eye-slash" v-if="currentRoom.blind" /> {{currentRoom.label}}</div> 
      <span>{{ currentTime(currentRoom.createdAtDT) }}</span> 
    </b-col>
    <b-col md="12">
      <p class="text-muted">{{currentRoom.status == 'completed' ? $t('Closed') : $t('Opened')}}</p>
    </b-col>
    <b-col md="3" v-if="hasRecording">
      <b-list-group class="rounded-0">
        <b-list-group-item href="#" variant="light">{{$t('Select your Recording')}}</b-list-group-item>
        <b-list-group-item @click="currentRecodingIndex = i" v-for="(rec,i) in currentRoom.recordings" :key="i" href="javascript:void(0)"
         :active="currentRecodingIndex == i">
          {{rec.recName||rec.key}}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col md="9" v-if="hasRecording && currentRecodingIndex>=0">
      <video-player :options="currentRecordingToPlay"  :blind="false" />
    </b-col>

    <b-col md="12" v-if="currentRoom.participants.length > 0">
      <h4 class="text-center">{{$t('Candidates')}}</h4>            
      <b-table striped hover :fields="participantFields" :items="currentRoomParticipants">
        <template v-slot:cell(approved)="data">
          <i class="fa fa-check" v-if="data.value == 'x'" />            
        </template>
        <template v-slot:cell(action)="data">
          <b-button variant="info" class="mx-1 text-uppercase border-white"  
            v-if="data.item.guid"
            @click="openHiddenProfile(data.item.guid,data.item.nickname)"
            v-b-tooltip.hover
            :title="$t('Anonymous Resume')"
            >
            <i class="fa fa-user-secret mr-2"></i>
          </b-button>
        </template>
      </b-table>
    </b-col>
    <b-col md="12" v-else>
      <b-alert variant="info">
        {{$t('No candidate found')}}
      </b-alert>
    </b-col>
  </b-row>
  </b-container> 
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PublicRoom from '@/core/Services/Video/PublicRoom';
import JbCard from '@/components/card/JbCard.vue';
import { CandidatoEntity, RoomEntity } from '@/core/models/Entities';
import { siteCandidateEndPoint } from "@/core/endpoint";
import VideoPlayer from "@/components/video/VideoPlayer.vue";

@Component({
  components: {
    'video-player': VideoPlayer,
  }
})
export default class ManagerRoomView extends Vue {
  public service!: PublicRoom;
  public currentRoom: RoomEntity|null = null;
  public currentRecodingIndex: number|null = null;
  public list: any[]|null = null;
  public page: number = 0;
  public limit: number = 20;
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public currentlyClosingId: number|null = null
  public participantFields: any[] = [];

  constructor() {
    super();
    this.service = new PublicRoom();
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public mounted() {
    document.body.style.overflow = 'auto';
    const token:string = this.$route.params.tkn;
    const _this: any = this;
    this.cleanSession();
    this.service.fetchPublicManagerRoom(token).then((_: any) => { 
      if (_)
        this.currentRoom = _
      else
         _this.toastError('Room not found')
    });
    this.participantFields = [     
      { key: 'name', label: this.$t('Name'), class: 'text-center' },
      { key: 'email', label: this.$t('E-mail'), class: 'text-center' },
      { key: 'telefone', label: this.$t('Telephone'), class: 'text-center' },
      { key: 'nickname', label: this.$t('Nickname'), class: 'text-center' },
      { key: 'approved', label: this.$t('Revealed'),class: 'text-center' },
    ]
  }

  get hasRecording() {
    return (this.currentRoom && this.currentRoom.recordings.length > 0)
  }

  public openHiddenProfile(guid: string,name: string) {
    let perfil = this.$router.resolve({
      name: "HiddenTalentInfoPage", params: {guid}, query: {lang: this.$i18n.locale, n: btoa(name||"") }
    });
    window.open(perfil.href, '_blank')
  }

  get currentRoomParticipants() {
    if (this.currentRoom && this.currentRoom.participants.length > 0) {
        let arr: any[] = [];
        const _: any = this;
        let aux: any|null = null;
        this.currentRoom.participants.forEach((p) => {
          try{
            let nickname: string = "";
            let nicknameArr: string[] = [];
            aux = JSON.parse(p.client_datas)
            for (let idx = 0; idx < aux.length; idx++) {
              if (aux[idx] != null && aux[idx].clientData) {
                nickname = aux[idx].clientData;
                if(nicknameArr.indexOf(nickname) == -1)
                  nicknameArr.push(nickname);
              }
            }
            let ca: CandidatoEntity|null = null;
            if(p.candidato) {
              ca = new CandidatoEntity();
              ca.guid = p.candidato;
            }
            arr.push({ 
              name: p.nome_candidato||p.nome_recrutador||p.nome_empresa, 
              email: p.email, 
              telefone: p.telefone, 
              nickname: nicknameArr.join(', ')||'' , 
              approved: (parseInt(p.approved_) > 0 ? 'x' : ''), 
              action: ca,
              guid: p.candidato,
            });
          }catch(e) {
            console.log('erro no parse')
          }
        });
        return arr;
      }
      return null
  }

  public currentTime(data: any) {
    return this.$showDateFromObject(data);
  }

  get currentRecordingToPlay() {
    if (!this.currentRoom || this.currentRecodingIndex == null)
      return null;
    const url = this.currentRoom!.recordings[this.currentRecodingIndex]!.url;
    return {
            videoURLMP4: url,
            audioDistorcedURLWAV: url,
            autoplay: false,
            controls: true,
            sources: [
                {
                    src: url,
                    type: "video/mp4"
                }
            ]
        }
  }

  public copyAccessLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.accessLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  public copyCandidateLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.candidateLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  public copyManagerLink(event: any,key: string,blind?: boolean) {
    this.$copyText(this.managerLink(key,blind),event.target).then(this.onCopy).catch(this.onError)
  }
  
  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }
    
  public candidateLink(key: string,blind?: boolean) {
    return this.URLcandidato + "/sala-entrevista/" + key + "?b="+(!!blind);
  }
  public managerLink(key: string,blind?: boolean) {
    return this.URL + "/emp/sala-entrevista/" + key + "?b="+(!!blind);
  }
  public accessLink(key: string,blind?: boolean) {
    return this.URL + "/room/" + key + "?b="+(!!blind);
  }

  public showRecordingsModalFor(room: RoomEntity) {
    this.currentRoom = room;
    this.$bvModal.show('bv-recordingModal')
  }

}
</script>
