
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { QuestionaryEntity } from '@/core/models/Entities';


export default class Exam extends Repository {

  public entidade: QuestionaryEntity;
  public optionList?: any[];
  public optionNamed?: any;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/exam', method: 'POST', headers: {} },
      fetch: { url: '/v1/exam/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/exam', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/exam/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/exam/{id}', method: 'DELETE', headers: {} }
    }));
    this.entidade = new QuestionaryEntity();
    this.optionList = [];
    this.optionNamed = {};
  }
  
  public async loadOptions() {
    const data: any = await this.fetchAll({ at: true });
    if (data && data.exams) {
      this.optionList = [
        {
          text: this.getI18n().t('Selecione'),
          value: ''
        }
      ];
      data.exams.forEach((item: any) => {
        this.optionNamed[item.id+""] = item.name
        if(item.status==1) {
          this.optionList!.push({
            text: item.name,
            value: item.id, 
          });
        }
      });
    }
  }

  public getQName(id: string) {
    return this.optionNamed[id];
  }

  public save(): Promise<any> {
    if (this.entidade.id && this.entidade.id > 0) {
      return super.update(this.entidade).then((data: QuestionaryEntity) => {
        this.entidade = data;
        return data;
      });
    } else {
      return super.create(this.entidade).then((data: QuestionaryEntity) => {
        this.entidade = data;
        return data;
      });
    }
  }

}


