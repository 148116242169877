<template>
  <div class="vaga-footer col-md-12">
    <div class="row d-flex justify-content-between" v-if="value">
      <div class="d-flex justify-content-center">
       <b-button
          v-b-tooltip.hover
          :title="$t('Job Report')"
          variant="pink"
          pill
          class="circle-button text-white mr-2"
          :to="{ name:'DashboardJobDiversidade', params: {vguid: value.guid}, query: {lang: $i18n.locale}}"
        > 
        <!-- to="{ name:'DashboardByJob', params: {vguid: value.guid}, query: {lang: $i18n.locale}}" -->
          <i class="fas fa-chart-bar"></i>
        </b-button>

        <b-button
          v-if="value.blind"
          variant="primary"
          class="btn text-white rounded-pill mr-2"
          @click="goToInterview"
        >{{$t("Watch blindly interviews")}}</b-button>

        <b-button
          v-else
          variant="primary"
          class="btn text-white rounded-pill mr-2"
          @click="goToInterview"
        >{{$t("Watch interviews")}}</b-button>

        <b-button
          v-if="hasRSToken && (value.status == 'ATIVO')"
          :class="{
            'btn text-white rounded-pill mr-2': true, 
            'btn-blue': (value.statusImpulsao != 'ATIVO'),
            'btn-info': (value.statusImpulsao == 'ATIVO') 
          }"
          @click="multiPublish"
        >
          {{ !value.statusImpulsao ? $t("Boost Job") : ((value.statusImpulsao == 'ATIVO') ? $t('Turn off boost') : $t('Turn on boost')) }}
        </b-button>

        <!-- <b-dropdown :text="$t('Convidar talento')" variant="outline-secondary" class="vaga-footer__dropdown-button" no-caret> -->
        <b-dropdown :text="$t('Invite Candidate')" variant="outline-primary" class="vaga-footer__dropdown-button" >
          <b-dropdown-item @click="compartilharMassa">{{ $t("Share") }}</b-dropdown-item>

          <b-dropdown-item v-if="!value.simplificada" @click="copyLinkVaga">{{ $t("Job Link") }}</b-dropdown-item>
          <b-dropdown-item v-else @click="copyLinkEntrevista">{{ $t("Interview Link") }}</b-dropdown-item>
          <!-- <b-dropdown-item @click="copyLinkRedes">{{ $t("Link para as Redes Sociais") }}</b-dropdown-item> -->
          <!-- {{$t("Convidar talento")}}
          <i class="fas fa-chevron-down mr-2"></i> -->
        </b-dropdown>
      </div>

      <div class="d-flex align-items center">
        <b-button
          v-b-tooltip.hover
          :title="$t('Edit job')"
          variant="primary"
          class="circle-button mr-2"
          @click="editarVaga()"
        >
          <b-icon icon="pen" />
        </b-button>

        <b-button
          v-b-tooltip.hover
          :title="$t('Duplicate')"
          variant="primary"
          class="circle-button mr-2"
          @click="duplicar()"
        >
          <i class="far fa-copy" />
        </b-button>

        <b-dropdown
          no-caret
          variant="primary"
          class="mr-2 circle-dropdown"
        >
          <template #button-content>
            <b-icon icon="three-dots" />
          </template>

          <b-dropdown-item
            v-if="value.status == 'ATIVO'"
            @click="encerrar()"
          >
            <i class="icon-encerrar mr-1" />
            {{ $t("Close") }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="value.status != 'ATIVO'"
            @click="publicar()"
          >
            <i class="far fa-paper-plane mr-1" />
            {{ $t("Publish") }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="value.status != 'INATIVO'"
            @click="inativar()"
          >
            <i class="far fa-times-circle mr-1" />
            {{ $t("Inactivate") }}
          </b-dropdown-item>
        </b-dropdown>

        <b-button
          v-b-tooltip.hover
          :title="$t('Delete')"
          variant="danger"
          class="circle-button mr-2"
          @click="excluir()"
        >
          <b-icon icon="trash" />
        </b-button>

        <button
          type="button"
          class="btn btn-primary text-white rounded-pill plus-jobcoins"
          @click="showJobcoinModal()"
        >
          <i class="fas fa-coins px-0 mx-0" />
          <span class="mx-2">{{ $t("Balance") }}</span>
          <span class="rounded-pill bg-white text-primary jobcoins-balance">{{ trueValue }}</span>
        </button>
      </div>
    </div>

    <modal-qr-code :id="'modalQR'+identifier" size="md" :header-class="'checkHeader'" :posicao="value.posicao" v-model="qrValue" />
  </div>
</template>

<script lang="ts">
import Alert from '@/components/alerts/Alert.vue';
import ModalQRCode from '@/components/modal/ModalQRCode.vue';
import GestaoVagaService from "@/core/Services/Empresa/GestaoVaga";
import MultiPublicacao from "@/core/Services/ThirdParty/MultiPublicacao";
import { siteCandidateEndPoint } from "@/core/endpoint";
import { EmpresaEntity, VagaEntity } from "@/core/models/Entities";
import { BitlyClient } from "bitly";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    'modal-qr-code': ModalQRCode
  },
})
export default class VagaFooter extends Vue {
  @Prop({ default: 0 })
  public saldoTotal!: number;

  @Prop()
  public value!: VagaEntity;
  
  @Prop({ default: false })
  public hasRSToken!: boolean;

  @Prop({ default: null })
  public showCopyFor!: any;

  @Prop({ default: 0 })
  public Balance!: number;
  
  @Action("fetchSaldo", { namespace : 'profile' })
  public fetchSaldo: any;

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;


  public bitly = new BitlyClient('d7bda8e44e904a4f2a3bbe45282cb8b019b5557d', {});
  public alert: Alert = new Alert;
  public URLcandidato: string = siteCandidateEndPoint;
  public vagasService = new GestaoVagaService();
  public multiService: MultiPublicacao = new MultiPublicacao();
  public activeName = 'first';
  public preventDoubleClick: boolean = false;
  public identifier:string = "";
  public qrValue:string = "";

  constructor() {
    super();
    this.identifier = ''+(new Date()).getTime()+Math.random();
  }


  public editarVaga(){
    console.log(this.value.simplificada);
    if(this.value.simplificada){
      this.$router.push({path: '/my-jobs-openings/edit-simple/'+this.value.id, query: {lang: this.$i18n.locale}})
    }else{
      this.$router.push({path: '/my-jobs-openings/edit/'+this.value.id, query: {lang: this.$i18n.locale}});
    }
    
  }

  public mounted() {
    setTimeout(() => {
      if(this.showCopyFor && this.value.id == this.showCopyFor) {
        if(this.value.simplificada) {
          this.copyLinkEntrevista()
        } else {
          this.copyLinkVaga()
        }
      }
    },1000)
  }

  // Alertas

  public onCopy() {
    this.$alert.addMsg(this.$t("Link copied successfully!").toString());
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public onError() {
    this.alert.addMsg(this.$t("Oops! Something went wrong").toString());
    this.alert.addMsg(this.$t("Reload the page and try again").toString());
    this.alert.callBoxWarn();
  }

  // Métodos de Compartilhar vaga

  public compartilharMassa() {
    this.$emit('modalVagaShare', this.value)
  }

  public copyText(text: string) {
    this.$copyText(text).then(() => {
      this.qrValue = text;
      this.$toast.variant = 'success';
      this.$toast.addMsg(this.$t('Link copied successfully!'))
      this.$toast.addMsg(text);
      this.$toast.addTitle(this.$t('Success').toString());
      this.$toast.open();
    }).catch((err: any) => {
      this.qrValue = text;
      console.log('Erro ao copiar')
    })
    this.$bvModal.show('modalQR'+this.identifier);
  }

  public makecopy(text: string) {
    let text_key = `shorten_${text}`
    const _: any = this;
    const savedLink = _.getLocalData(text_key)
    if(!savedLink) {
      const btPromise: Promise<any> = this.bitly.bitlyRequest('shorten',{
          'domain':'jobecam.in',
          "group_guid": "Bj2qhmHcCgV",
          "long_url": text
        });
      btPromise.then((result: any) => {
        this.copyText(result?.link);
        _.saveLocalData(text_key,result?.link)
      }).catch((err: any) => {
        console.log(err);
        this.copyText(text)
      })
    } else {
      this.copyText(savedLink)
    }
  }

  public async copyLinkVaga() {
    this.makecopy(this.URLcandidato+'/invite/'+this.value.nomeUrl)
  }
  public async copyLinkEntrevista() {
    if(this.empresa) {
      this.makecopy(this.URLcandidato+'/cc/'+this.empresa?.nomeUrl+'/v/'+this.value.nomeUrl)
    }
  }

  public showJobcoinModal() {
    this.$emit('showJobcoinModal',this.value)
  }

  public async copyLinkRedes(vaga: any) {
    let result: any;
    let text = this.URLcandidato+'/v/'+vaga.nomeUrl
    result = await this.bitly.bitlyRequest('shorten',{
        'domain':'jobecam.in',
        "group_guid": "Bj2qhmHcCgV",
        "long_url": text
      });
    if (result?.link) {
      this.$copyText(result.link).then(() => {
        this.onCopy()
      }).catch((err: any) => {
        console.log(err)
      })
    } else {
      this.onError()
    }
  }

  public multiPublish() {
    if (this.preventDoubleClick)
      return;

    if (this.value.id) { 
    const status: string = (!this.value.statusImpulsao && this.value.status == 'ATIVO') ? 'a' : ((this.value.statusImpulsao == 'ATIVO') ? 'i' : 'a');
      this.preventDoubleClick = true;
      this.multiService.create({
        vaid: this.value.id,
        status
      }).then(() => {
        this.preventDoubleClick = false;
        this.$alert.addMsg(this.$t("Job Boost performed successfully!").toString());
        this.$alert.callBoxCheck(this.$t('Ok').toString());
        this.$emit('impulsionada', status);
      }).catch(() => {
        this.preventDoubleClick = false;
        // this.alert.addMsg(this.$t("Ops").toString());
        this.alert.addMsg(this.$t("Boost cannot take place at the moment please contact suporte@jobecam.com").toString());
        this.alert.callBoxWarn();
      })
    }
  }

  public goToInterview() {
    this.$router.push({name: "Candidate", params: {id: ""+this.value.id}, query: {lang: this.$i18n.locale}});
    return
  }

  // Métodos de Edição & Encerrar & Excluir & Duplicar

  public encerrar() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you want to close this job?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        this.vagasService.update({status: 'ENCERRADA', id: this.value.id}).then(async () => {
          await this.fetchSaldo();
          this.value.status = 'ENCERRADA'
          this.$emit('encerrada', this.value.id)
          this.$emit('showModalFeedback');          
        }).catch((err: any) => {
          console.log('err')
        });
      }
    });
  }

  public excluir() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you want to delete this job?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        this.vagasService.update({status: 'EXCLUIDA', id: this.value.id}).then(() => {
          this.value.status = 'EXCLUIDA'          
          this.$emit('excluida', this.value.id)
        }).catch((err: any) => {
          console.log(err)
        });
      }
    });
    
  }
  
  public duplicar() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you want to duplicate this job?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        const vagaRouter = this.value.simplificada? 'JobsOpeningsSimpleEdit' : 'JobsOpeningsEdit';

        this.vagasService.create({duplicate: true, vagaOriginalId: this.value.id}).then((data: VagaEntity) => {
          this.$emit('duplicada', this.value.id)
          this.$router.push({name: vagaRouter, query: { lang: this.$i18n.locale }, params: { id: ""+data.id  }})
        }).catch((err: any) => {
          console.log(err)
        });
      }
    });
  }
  public getDateString(v: Date) {
    const day = (v.getUTCDate()).toString();
    const month = (v.getUTCMonth() + 1).toString();
    const year = v.getUTCFullYear();
    return (day.length == 1 ? "0" + day : day) + "/" + (month.length == 1 ? "0" + month : month) + "/" + year;
  }

  public publicar() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you would like to publish this position?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        const d: string = this.getDateString(new Date());
        this.vagasService.update({status: 'ATIVO', id: this.value.id, dataPublicacao: d}).then(() => {
          this.value.status = 'ATIVO'
          this.$emit('publicada', this.value.id)
        }).catch((err: any) => {
          console.log(err)
        });
      }
    });
  }

  public inativar() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you want to inactivate this job?'))
    this.$confirm.callBoxDanger().then((v:boolean) => {  
      if (v) {
        this.vagasService.update({status: 'INATIVO', id: this.value.id}).then(() => {
          this.value.status = 'INATIVO'
          this.$emit('inativada', this.value.id)
        }).catch((err: any) => {
          console.log(err)
        });
      }
    });
  }

  public getSaldoCreditosVaga(vaga: VagaEntity) {
    const total = vaga.historicoCredito.reduce((previous, current) => {
      const sumValue = current.creditoQtd * (current.tipo === 'E' ? 1 : -1);

      return previous + sumValue;
    }, 0);

    return parseInt(total);
  }

  public getDebitosVaga(vaga: VagaEntity) {
    let total = 0;

    vaga.historicoCredito.forEach((element: any,index: number) => {
      total = parseInt(""+((element.tipo == "S") ? total-element.creditoQtd : total ));
    });

    if (vaga.limited == 0 && this.getSaldoCreditosVaga(vaga) <= 0) {
      total = parseInt(""+this.saldoTotal)+ total;
    }

    return total;
  }

  get trueValue() {
    if (!this.value.limited && this.getSaldoCreditosVaga(this.value) < 0) {
      return this.saldoTotal;
    }

    let value = this.getSaldoCreditosVaga(this.value) > 0
      ? this.getSaldoCreditosVaga(this.value)
      : this.getDebitosVaga(this.value);

    return Math.max(0, value);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.vaga-footer {
  padding: 12px 32px;

  a {
    &.edit {
      color: $blue;
    }
    &.encerrar {
      color: $purple;
    }
    &.duplicar {
      color: $blue-light;
    }
    &.excluir {
      color: $orange;
    }
    &.inativar {
      color:hotpink;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

  .vaga-footer .btn {
    font-weight: bold;
    font-size: 0.8rem !important;
  }

  .vaga-footer__dropdown-button .btn {
    border-radius: 50rem;

    i {
      font-size: 1rem;
      vertical-align: bottom;
    }
  }
    
@include media("<desktop") {
  .vaga-footer {
    button {
      font-size: $button-font-size-sm;
    }

    a {
      font-size: $button-font-size-sm;
    }
   }
}
@include media(">desktop", "<=large") {
  .vaga-footer {
    button {
      font-size: $button-font-size-md;
    }

    a {
      font-size: $button-font-size-md;
    }
   }
}

@include media(">large") {
.vaga-footer {
    button {
      font-size: $button-font-size-lg;
    }

    a {
      font-size: $button-font-size-lg;
    }
   }
}

.vaga-footer {
  .circle-button, .circle-dropdown button {
    border-radius: 100% !important;
    width: 28px !important;
    height: 28px !important;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plus-jobcoins span.jobcoins-balance {
    padding: 2px 8px;
  }

  @media screen and (max-width: 1100px) {
    .plus-jobcoins span:not(.jobcoins-balance) {
      display: none;
    }

    .plus-jobcoins span.jobcoins-balance {
      margin-left: 8px;
    }
  }
}
</style>