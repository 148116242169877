import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';



export default class GrupoAvaliacao extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/grupo-avaliacao', method: 'POST', headers: {} },
            fetch: { url: '/v1/grupo-avaliacao/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/grupo-avaliacao', method: 'GET', headers: {} }, 
            delete: { url: '/v1/grupo-avaliacao/{id}', method: 'DELETE', headers: {} },
            update: { url: '/v1/grupo-avaliacao/{id}', method: 'PUT', headers: {} },
        }));
    }

    public fetchWithData(data: any, blockCatch?: boolean | undefined): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call('fetch', (!data ? {} : data), 'application/json', blockCatch).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call('fetch', cdta, 'application/json', blockCatch).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            }
        });
    }
}
