<template>
  <modal :title="$t('Attention')" :show="show" :id="id" :size="'md'">
    <p>{{$t('Are you sure you want to give this candidate another chance?')}}</p>

    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="reset"
          v-promise-btn="{promise:currentPromise}"
        >
          {{$t("Confirm")}}
        </b-button>

        <b-button
          size="md"
          class="float-right mr-2"
          @click="$bvModal.hide(id)"
        >
          {{$t("Cancel")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import GestaoCandidaturaService from "@/core/Services/Empresa/GestaoCandidatura";
import Modal from '@/components/modal/Modal.vue'

@Component({
  components: {
    Modal
  }
})
export default class ModalNewChance extends Vue {
  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: false})
  public single!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({default: () => { return []}})
  public multipleSelection!: any[];
  
  public gCandidaturaService!: GestaoCandidaturaService
  public currentPromise: any|null = null;
  public candidateId: number = 0

  constructor() {
    super()
    this.gCandidaturaService = new GestaoCandidaturaService()
	}

  public reset() {
    if (!this.single) {
      if (this.multipleSelection.length == 0) {
        this.$toast.addTitle(this.$t('Attention').toString())
        this.$toast.addMsg(this.$t('No selected candidacy').toString());
        this.$toast.autoHideDelay = 1000;
        this.$toast.variant = 'warning';
        this.$toast.open()
        this.$bvModal.hide(this.id)
        return;
      }
      this.multipleSelection.forEach((id: number) => {
        this.gCandidaturaService.delete({ id })
        .then((data: any) => {
          this.successAlert(id)
          this.$emit('granted', id)
        }).catch((err: any) => {
          console.log(err)
          this.$emit('not-granted', id)
        })
      });
    } 
    if (this.single && this.candidatura) {
      this.gCandidaturaService.delete({ id:this.candidatura.id||0 })
      .then((data: any) => {
        this.successAlert(this.candidatura.id||0)
        this.$emit('granted', this.candidatura.id||0)
      }).catch((err: any) => {
        console.log(err)
        this.$emit('not-granted', this.candidatura.id||0)
      })
    }
    this.$bvModal.hide(this.id)
  }

  public successAlert(cid: number) {
    this.$toast.addTitle(' - ')
    this.$toast.addMsg(this.$t('New chance granted to the candidate ({cid})',{ cid }));
    this.$toast.autoHideDelay = 1000;
    this.$toast.open()
  }
}
</script>

<style>

</style>