<template>
<transition appear name="slide-fade">
  <div>
    <!-- <div v-if="legend" class="d-flex justify-content-center text-gray mb-4">   -->
    <div class="d-flex justify-content-center text-gray mb-4">  
      <icon :name="icon" size="sm" class="mr-1"></icon>  <span> {{label}}</span> 
    </div>
    <donut-chart
      :series="series"
      :labels="labels"
      :chartColors="colors"
      :displayLegend="legend"
      :bg="bg"
      :size="size"
      :thickness="thickness"
      :textClass="textClass"
      :placementLegend="placeLegend"
    />
  </div>
</transition>
</template>

<script lang="ts">
import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import DonutChart from '@/components/chart/DonutChart.vue';

@Component({
  components: {
    DonutChart
  }
})
export default class DashDonutChart extends Vue { 
  @Prop({default: () => { return []}})
  public series!: number[]
  
  @Prop({default: () => { return []}})
  public colors!: string[]
  
  @Prop({default: () => { return []}})
  public labels!: string[]

  @Prop({default: ''})
  public label!: string

  @Prop({default: ''})
  public icon!: string

  @Prop({default: true})
  public legend!: string

  @Prop({default: 'bottom'})
  public placeLegend!: string

  @Prop({default: 'white'})
  private bg!: string;

  @Prop({default: 120})
  private size!: number;

  @Prop({default: 20})
  private thickness!: number;
  
  @Prop({default: 'text-dark'})
  private textClass!: string;

}
</script>

<style lang="scss" scoped>

</style>