
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class ImportJobGupy extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/gupy/jobs', method: 'POST', headers: {} },
                fetch: { url: '/gupy/jobs/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/gupy/jobs', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/gupy/jobs/{id}', method: 'PUT', headers: {} }
            }));
    }
}
