import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class ReportFilter extends Repository {
    
    constructor() {
        super(new ApiRouter({
                create: { url: '/dashboard/report', method: 'POST', headers: {} },
                fetch: { url: '/dashboard/report/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/dashboard/report', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/dashboard/report/{id}', method: 'PUT', headers: {} }}));
        
    }



}


