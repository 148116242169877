<template>
  <div class="row">
    <div class="col-md-12">
        <div class="box-extrato-plano">
          <h5 class="mt-5 mb-4">{{$t('Plan Extract')}}</h5>
          <b-table small striped hover :items="items" :fields="fields">
            <template v-slot:cell(arrow)="data">
              <i v-if="data.value" class="fas fa-chevron-up text-success"></i>
              <i v-else class="fas fa-chevron-down text-danger"></i>
            </template>
            <template v-slot:cell(origem)="data">
              <div v-if="data.value.vaga" >{{data.value.vaga}}</div>
              <div v-if="data.value.candidato" >{{data.value.candidato}}</div>
              <div v-else> - </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <button @click="loadMoreHistory"
            class="btn btn-outline-secondary btn-more text-uppercase rounded-pill"
          >{{$t('See More Releases')}}</button>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import Assinatura from '@/core/Services/Payments/Assinatura';
import GestaoCredito from "@/core/Services/Empresa/GestaoCredito";
import HistoricoCredito from "@/core/Services/HistoricoCredito";
import { EmpresaEntity } from '@/core/models/Entities';

@Component
export default class ExtratoAtual extends Vue {
  public serviceHistorico: HistoricoCredito;
  public page = 1;
  public limit = 5;

  public fields = [
    { key: "arrow", label: "", class: 'text-center' },
    { key: "jobecoins", label: "JOBCOINS", class: 'text-center' },
    { key: "origem", label: "ORIGEM", class: 'text-center' },
    { key: "data", label: "Data", class: 'text-center' }
  ];
  public items: any[]|null = [];

  constructor() {
    super();
    this.serviceHistorico = new HistoricoCredito();
  }

  public created() {
    this.loadHistory()
  }

  public loadHistory() {
    let data: any = {
      page: this.page,
      limit: this.limit
    }
    this.serviceHistorico.fetchAll(data).then((data) => {
      data['historico'].forEach((el,index) => {
        this.items!.push({
          arrow: el.tipo == "E",
          jobecoins: el.jobcoins,
          origem: {vaga: el.vaga, candidato: el.candidato},
          data: el.data
        })
      });
    })
  }

  public loadMoreHistory() {
    ++this.page;
    this.loadHistory()
  }

}
</script>

<style lang="scss" scoped>
.box-extrato-plano {
  font-size: 0.9rem;
}

.btn-more {
  font-size: 0.95rem;
}
</style>