<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="lg"
    :title="$t('Change Card')"
    ref="modalChangePaymentMethod"
    body-class="p-0"
  >
    <div class="modal-content m-content">
      <button class="btn btn-danger" @click="$bvModal.hide('modalChangePaymentMethod')">
        <i class="icon-cancelar"></i>
      </button>
      <!-- Description -->
      <b-card class="payment-card m-content__description mb-2">
        <b-container slot="header">
          <b-row>
            <b-col md="12" class="payment-option cartao" >
              {{ $t('Credit Card') }}
            </b-col>
          </b-row>
        </b-container>
        <credit-card-form @changed="changed" @canceled="canceled" />        
      </b-card>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreditCardForm from './CreditCardForm.vue';

@Component({
  components: {
    CreditCardForm
  }
})
export default class ModalSubstituirCartao extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  public changed() {
    console.log('changed')
    this.$emit('change', true)
  }
  
  public canceled() {
    console.log('canceled')
    this.$emit('change', false)
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.payment-card {
    height: 100%;

    .card-header {
      padding: 0;

      .payment-option {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        padding: 2%;
        cursor: pointer;

        .icon-img {
          margin-right: 7%;
        }

        &.cartao {
          background-color: $blue-light;
          .icon-img {
            width: 39px;
          }
        }
      }
    }

    .btn-realizar {
      display: block;
      margin: 0 auto;
      padding: 1.3% 9%;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  
.m-content {
  padding: 3em;
  p {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.9em;
    padding-bottom: 1em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }
  button.secondary {
    // color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px
  }

  button.primary {
    color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px
  }
  .range_scope {
    position: relative;
  }
  .range_min {
    position:absolute;
    left: 0;
    color: gray;
  }
  .range_max {
    position: absolute;
    right: 0;
    text-align: right;
    color: gray;
  }
  .range_value {
    position: absolute;
    right: 0;
    font-size: 1.5em;
    color: green;
  }
}
</style>
