<template>
  <div class="card">
    <div class="card__front card__part">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-end">
            <i :class="'card__front-logo text-white card__logo fa-3x fab fa-cc-'+brand" ></i>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-start mt-4">
            
          </div>
        </div>
        <div class="col-md-12">
          <div class="mt-2">
            <p class="card_numer">{{metodo.display_number}}</p>
          </div>
        </div>
      </div>
      <div class="card__space-75">
        <p class="card__info">{{metodo.holder_name}}</p>
      </div>
      <div class="card__space-25">
        <p class="card__info">{{metodo.validation_month}}/{{metodo.validation_year}}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MetodoPagamentoEntity } from '@/core/models/Entities';

@Component
export default class CreditCard extends Vue {
  @Prop({default: () => { new MetodoPagamentoEntity()}})
  public metodo!: MetodoPagamentoEntity;

  get brand() {
    let brand = this.metodo?.brand?.toLowerCase();
    if(brand == 'master') {
      brand ='mastercard'
    }
    return brand||'';
  }

}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 30px;
  color: #162969;
}

.card {
  width: 320px;
  height: 190px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.card__part {
  box-shadow: 1px 1px #aaa3a3;
  top: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  display: inline-block;
  width: 320px;
  height: 190px;
  background-image: linear-gradient(
    to right top,
    #a2beeb,
    #8ecfee,
    #8edde5,
    #a4e7d5,
    #c7eec7
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.card__front {
  padding: 18px;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
}

.card__logo {
  height: 16px;
}

.card__square {
  border-radius: 5px;
  height: 30px;
}

.card_numer {
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 1.2em;
  letter-spacing: 2px;
  color: #fff;
  text-align: start;
}

.card__space-75 {
  width: 75%;
  float: left;
}

.card__space-25 {
  width: 25%;
  float: left;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}
</style>