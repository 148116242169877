<template>
  <div>
    <b-row no-gutters="" class="d-none d-md-block">
      <b-col md="12" class="d-flex justify-content-center overflow-hidden" >
        <logo-jobecam :inverse="false"></logo-jobecam>
      </b-col>
    </b-row>
    <router-view></router-view>  
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import LogoPublic from '@/components/logo/LogoPublic.vue';

@Component<RootPublicEmptyArea>({
  components: {
    'logo-jobecam': LogoPublic,
  }
})
export default class RootPublicEmptyArea extends Vue {
 public sidebarIsOpen: Boolean = false;

  updated() {
    // console.log('update root')
  }

  mounted() {
    // console.info('App this router:', this.$router)
    // console.info('App currentRoute:', this.$router.currentRoute)
  }

  public toggleSidebar() {
    this.sidebarIsOpen = !this.sidebarIsOpen
  }
}
</script>


<style lang="scss" scoped>
.root-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;

  &__general {
    flex: 1;
    flex-direction: column;
    width: 100%;

    overflow: hidden;
  }

  transform: translateX(0px);
  transition: transform 0.5s ease-in-out;

  &--sidebar-open {
    transform: translateX(217px);
  }
}

.margin-correction {
  margin-top: -36px;
  width: 100%;
  margin-bottom: 35px;
}
</style>