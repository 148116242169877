<template>
  <b-card no-title  class="my-2 py-2 shadow rounded-card" v-if="habilidades && habilidades.length > 0">
    <!-- body -->
    <b-row class="mb-3 ">
      <b-col md="12">
        <h3 class="text-info">{{$t('Skills')}}</h3>
      </b-col>
      <b-col md="12">
        <h5 class="float-left" v-for="exp in habilidades" :key="exp.id">
          <b-badge variant="success" class="mr-1">
            {{exp.habilidade.name}}
          </b-badge>  
        </h5>
      </b-col>
    </b-row>
  </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HabilidadeCandidatoEntity } from "@/core/models/Entities";

@Component
export default class CardPdfSkills extends Vue {

  @Prop()
  public habilidades!: HabilidadeCandidatoEntity[];
  public items: any[] = [];
  public fields: any[] = [];
  public num: number = 4

  constructor() {
    super();
  }

  public created() {
    this.fields = [
      { key: "tecnicas" },
      { key: "nivel", label: '' },
    ];
    this.loadData();
  }

  public loadData() {
    this.items = [];
    this.habilidades.forEach((el: any) => {
      this.items.push({
        tecnicas: el.habilidade.name,
        nivel: el.nivel,
      });
    });
  }
}
</script>

<style lang="sass" scoped>
  .descricao-atividade
    font-size: 14px
</style>
