<template>
  <b-card no-body>
    <b-card-header class="p-0" v-if="showHeader">
      <div class="row">
        <div class="col-md-2 col-4 foto-perfil d-flex justify-content-center">
          <img :src="avatar" v-if="!isBlind && avatar"/>
          <img src="/static/img/avatar.png" alt v-else/>
        </div>
        <div class="col-md-6 col-8 d-flex align-items-center buttons">
          <div class="row">
            <div class="col-md-12 mb-2">
              <h3 class="m-0">{{nomeCompleto}} 
                <small>
                <i v-if="temExpTemForm" 
                class="fa fa-exclamation-triangle text-warning" 
                v-b-tooltip.hover
                :title="temExpTemForm"  /></small>
              </h3>
              <span class="m-0" v-if="candidato.email">{{candidato.email}} </span> 
              <span class="m-0" v-if="localidade"> - {{localidade}}</span> <br />  
              <span class="m-0" v-if="candidato.perfil.telefone">{{$t('Telephone')}}: {{candidato.perfil.telefone}}</span>
              
            </div>
          </div>
        </div>
      </div>
      
    </b-card-header>
    
    <b-card-body class="p-0">
      
      <div class="my-3 d-flex justify-content-center" v-if="loading"> 
        <b-spinner label="Loading..." style="width: 5rem; height: 5rem;" variant="primary"></b-spinner>
      </div>
      <b-alert v-else-if="listAvaliacao.length == 0" variant="info" show > {{$t('Nothing Found')}} </b-alert>
      <b-row v-for="avaliacao in listAvaliacao" :key="avaliacao.guid">
        <b-col md="12">
          <!-- Using value -->
          <b-card
            tag="article"
            class="mb-2 border-light shadow"
          >
            <b-card-title>
              <div class="d-flex justify-content-between">
                
                <span>
                  <small class="text-secondary" style="font-size:0.5em"> {{$t('Evaluator')}}:  </small> 
                  <br />
                  {{avaliacao.nomeAvaliador}}
                </span>
                <span>
                  <i v-b-tooltip.hover
                  :title="$t(getTitleStatus(avaliacao.status))"
                  :class="getIconStatus(avaliacao.status)" 
                  />
                </span>
              </div>
            </b-card-title>

            <b-card-sub-title class="text-right">
              {{$showDateFromObject(avaliacao.updatedAt)}}
            </b-card-sub-title>
            
            <b-card-text v-if="avaliacao.vagaPosicao" class="my-1" v-b-tooltip.hover :title="$t('Vacancy')">
              <i class="mr-2 icon icon--lg icon--inherit icon-minhas-vagas" /> {{$t('Vacancy')}}: {{avaliacao.vagaPosicao}}
            </b-card-text>

            <b-card-text v-if="avaliacao.sessionId" class="my-1" v-b-tooltip.hover :title="$t('Live interview')">
              <i class="mr-2 fa fa-video" /> {{$t('Live interview')}}
            </b-card-text>

            <b-form-rating readonly v-model="avaliacao.nota" size="sm" stars="5" class="my-1" no-border variant="primary"></b-form-rating>
            
            <div v-html="avaliacao.corpo" class="bg-light border border-secondary p-2 mt-2"></div>
            
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script lang="ts">
import { CandidatoEntity, EmpresaEntity, RecrutadorEntity, VagaEntity, AvaliaProfissionalEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import Editor from "@tinymce/tinymce-vue";
import AvaliaProfissional from '@/core/Services/Empresa/AvaliaProfissional';
import { EAvaliacaoStatus } from '@/core/Enums/EAvaliacaoStatus';

@Component({
  components: {
    editor: Editor
  }
})
export default class FormAvaliacao extends Vue {

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: "profile" })
  public recrutador?: RecrutadorEntity;

  @Prop({ default: null })
  public vaga!: VagaEntity;

  @Prop({ default: null })
  public session!: string;
  
  @Prop({ default: () => { new CandidatoEntity() } })
  public candidato!: CandidatoEntity;

  @Prop({ default: false})
  public showHeader!: boolean;
  
  public listAvaliacao: AvaliaProfissionalEntity[] = [];
  public service: AvaliaProfissional;
  public promise: Promise<any>|null = null;
  public loading: boolean = false;
  
  public curti: EAvaliacaoStatus = EAvaliacaoStatus.Curti;
  public neutro: EAvaliacaoStatus = EAvaliacaoStatus.Neutro;
  public naocurti: EAvaliacaoStatus = EAvaliacaoStatus.NaoCurti;

  constructor(){
    super();
    this.service = new AvaliaProfissional();
  }

  get avatar() 
  {
    return this.candidato?.fotoAvatar
  }

  public updated() {
    console.log('update em componente de aval')
  }

  public mounted() {
    if(this.empresa && this.candidato)
      this.fetchData()
  }
  
  get localidade() {
    return (this.candidato.perfil.localidade!=undefined) ? `${this.candidato.perfil.localidade} ${this.candidato.perfil.nomeCidade} / ${this.candidato.perfil.nomeEstado}` : '';
  }

  get temExpTemForm() {
    let x: string = "";
    if (this.candidato?.perfil?.temExperiencia===false) {
      x += this.$t('Looking for the first job').toString()+" ";
    }
    if (this.candidato?.perfil?.temExperiencia===false) {
      x += this.$t('Has no Academic Data').toString();
    }
    return x;
  }
  
  get nomeCompleto () {
    return (!this.isBlind) ? `${this.candidato?.primeironome} ${this.candidato?.sobrenome}` : this.$t('Talent in anonymous process');
  }

  get isBlind() 
  {
    return (this.vaga?.blind||0) == 1
  }

  public fetchData() {
    const _: any = this;
    const d: any = { cUID: this.candidato.guid };
    
    if(this.session)
      d.session = this.session;

    this.loading = true;
    this.promise = this.service.fetchAll(d)
    .then((data) => {
        if(data.length > 0)
          this.listAvaliacao = data
    }).catch(() => {
      _.toastError('An error occurred while loading');
    }).finally(() => {
      this.loading = false
    })
  }

  public getTitleStatus(status: string|EAvaliacaoStatus) {
    if(status == this.curti)
      return "Like"
    if(status == this.neutro)
      return "Neutral"
    if(status == this.naocurti)
      return "Dislike"

    return '';
  }

  public getIconStatus(status: string|EAvaliacaoStatus) {
    if(status == this.curti)
      return "fas fa-thumbs-up text-primary"
    if(status == this.neutro)
      return "fas fa-meh text-info"
    if(status == this.naocurti)
      return "fas fa-thumbs-down text-danger"

    return '';
  }
}
</script>

<style lang="scss" scoped>
  .foto-perfil {
    padding: 0 2em 2em 2em;
    position: relative;
    display: flex;
    // object-fit: cover;
    border-radius: 200px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 90px;
        height: 90px;
    }
  }
</style>