<template>
  <modal :title="$t('Job Feedback')" :show="show" :id="id" :size="'lg'" :hidefooter="hide">
    <div id="feedbackBody" class="col-md-12" style="overflow:hidden">
      <iframe 
      :src="url" 
      id="feedbackIframe" 
      width="100%" 
      height="600px" 
      style="overflow-y:scroll;"  
      class="border-0" 
      scrolling="yes"
      >
      </iframe>
    </div>
  </modal>
</template>

<script lang="ts">
import Modal from '@/components/modal/Modal.vue';
import { VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Modal
  }
})
export default class ModalFeedback extends Vue {

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  public url: string = ''
  public hide: Boolean = true;

  constructor() {
    super()
  }
  
  public mounted() {
    if(this.$i18n.locale=='pt-BR')	{      
      this.url = 'https://docs.google.com/forms/d/e/1FAIpQLSdaRjReWBCGpKVyfmcLID7OWdlNNKd3RzQTexkpEPRKr-Y3uQ/viewform';
    }else{
      this.url = 'https://docs.google.com/forms/d/e/1FAIpQLSdlnw4uRoZYCYH2iXoU1c4IZxaS23ZvSiHpczjw9z5-J4fSdw/viewform';
    }
  }
  
}
</script>

<style>
</style>