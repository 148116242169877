
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { PerfilEmpresaEntity } from '@/core/models/Entities';


export default class PerfilEmpresa extends Repository {
  public entidade: PerfilEmpresaEntity;
  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/perfil-empresa', method: 'POST', headers: {} },
      fetch: { url: '/v1/perfil-empresa/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/perfil-empresa', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/perfil-empresa/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new PerfilEmpresaEntity();
  }

  public loadProfile(empresaId: number): Promise<any> {
    return super.fetch(empresaId).then((data: PerfilEmpresaEntity) => { 
      this.entidade = data;
      if (!this.entidade.celularContato)
        this.entidade.celularContato = "";
    });
  }

  public async save(): Promise<any> {
    return super.update(this.entidade)
  }

  public saveBackgroundAvatar(file: any): Promise<any> {
    return this.saveFile('bgavatar', file);
  }

  public saveLogoEmpresa(file: any): Promise<any> {
    return this.saveFile('logo', file);
  }

  public saveFile(key: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', '' + this.entidade.id);
    formData.append(key, file);
    return super.update(formData, 'multipart/form-data');
  }


}
