<template>
  <b-img src="https://storage.googleapis.com/jobecam-assets/top_bar_live_room_.jpg"></b-img>
</template>

<script lang="ts">
import { Component,  Vue } from 'vue-property-decorator';
import { siteCandidateEndPoint, siteLandingEndPoint } from '@/core/endpoint';

@Component
export default class LogoPublic extends Vue {

  public gotoCandidate() {
    const fc = siteCandidateEndPoint;
    window.open(fc, '_blank');
  }
  public gotoCompany() {
    const fc = `${siteLandingEndPoint}/empresa`;
    window.open(fc, '_blank');
  }
}
</script>

<style lang="scss" scoped>
/**
** Logo
**/
.st0{fill:#FFFFFF;}
.st1{clip-path:url(#SVGID_2_);fill:url(#SVGID_3_);}
.st2{clip-path:url(#SVGID_5_);}
.st3{fill:#65C1BD;}
.st4{fill:url(#SVGID_6_);}
.st5{clip-path:url(#SVGID_8_);fill:url(#SVGID_9_);}
.st6{clip-path:url(#SVGID_11_);}
.st7{fill:url(#SVGID_12_);}
.st8{enable-background:new    ;}
.st9{fill:#F3E600;}
</style>