
import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';


export default class InviteMember extends Repository {
  
  constructor() {
    super(
      new ApiRouter({
        create: { url: '/v1/invite-member', method: 'POST', headers: {} },
        delete: { url: '/v1/invite-member/{id}', method: 'DELETE', headers: {} },
        fetch: { url: '/v1/invite-member/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/invite-member', method: 'GET', headers: {} },
        update: { url: '/v1/invite-member/{id}', method: 'PUT', headers: {} },
      }));
  }

}

