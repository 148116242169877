<template>
  <div class="box-container d-flex flex-column mb-5 contratar-plano">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-md-left text-center">{{$t("Hire plan")}}</h1>
      </div>
    </div>

    <b-row>
      <b-col md="6">
        <b-card-group deck class="mt-3" >
          <plan-card v-if="planoAtual"
            :key="planoAtual.id"
            :header-bg-color="'dark-gray'"
            :planId="planoAtual.id"
            :jobcoins="planoAtual.credito_qtd"
            :header-text="!planoAtual.recomendationText ? (!planoAtual.free ? periodoJobcoins : 'Interviews') : planoAtual.recomendationText"
            :promocional="planoAtual.promocional"
            :listjson="planoAtual.avaliableList"
            :plan-name="planoAtual.nome"
            :body-title="planoAtual.free ? $t('Free') : planoAtual.preco.toString()"
            :text-color="'#1D1D1B'"
            :text-button="planoAtual.promocionalText"
            :price="planoAtual.preco"
            :price_usd="planoAtual.preco_usd"
            :free="planoAtual.free"
            :popular="false"
            :atual="true"
            :planType="planoAtual.free ? $t('Free') : planoAtual.credito_qtd.toString()"
          />
          <plan-card v-if="planoEscolhido"
            :key="planoEscolhido.id"
            :header-bg-color="'blue'"
            :planId="planoEscolhido.id"
            :jobcoins="planoEscolhido.credito_qtd"
            :header-text="!planoEscolhido.recomendationText ? (!planoAtual.free ? periodoJobcoins : 'Interviews') : planoEscolhido.recomendationText"
            :promocional="planoEscolhido.promocional"
            :listjson="planoEscolhido.avaliableList"
            :plan-name="planoEscolhido.nome"
            :body-title="planoEscolhido.free ? $t('Free') : planoEscolhido.preco.toString()"
            :text-color="'#1D1D1B'"
            :text-button="planoEscolhido.promocionalText"
            :price="planoEscolhido.preco"
            :price_usd="planoEscolhido.preco_usd"
            :free="planoEscolhido.free"
            :popular="false"
            :selecionado="true"
            :planType="planoEscolhido.free ? $t('Free') : planoEscolhido.credito_qtd.toString()"
          />
        </b-card-group>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <p>{{$t('If your choice is correct, check your card details and confirm the plan change')}}.</p>
          </b-col>
          <b-col md="12">
            <div class="d-flex justify-content-center">
              <credit-card :metodo="serviceMetodo.entidade"></credit-card>
            </div>
          </b-col>
          <b-col md="12">
            <div class="d-flex justify-content-center align-items-center my-3">
              <button class="btn btn-outline-secondary text-uppercase rounded-pill" @click="showModalMP"> 
                {{$t('Replace card')}} 
              </button>
            </div>
          </b-col>
          <b-col md="12">
            <div class="d-flex justify-content-center align-items-center my-3">
              <b-button variant="blue" class="text-uppercase" size="lg" pill @click="confirmPayment" v-promise-btn="{promise}">
                <i class="fas fa-check mr-2"></i>
                {{$t('Confirm plan purchase')}}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-md-6" v-if="planoAtual && planoEscolhido">
        <p>{{$t('You are leaving the {current} plan and subscribing to the {selected} PLAN',{ current: planoAtual.nome, selected: planoEscolhido.nome })}}.</p>
      </div>
    </div>
    
    <!-- Modal substituir Cartao -->
    <modal-substituir-cartao
      id="modalChangePaymentMethod"
      @change="handleChange"/>
  </div>
</template>

<script lang="ts">
import PlanCard from "@/components/PlanCard.vue";
import { EPaymentType } from '@/core/Enums/EPaymentType';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import Assinatura from '@/core/Services/Payments/Assinatura';
import MetodoPagamento from '@/core/Services/Payments/MetodoPagamento';
import Payment from '@/core/Services/Payments/Payment';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import ModalSubstituirCartao from '@/views/pagamentos/partials/ModalSubstituirCartao.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    PlanCard,
    ModalSubstituirCartao
  }
})
export default class PagarPlano extends Vue {
  private coresPlanos = ["dark-gray", "blue", "marine", "green"];
  private EPeriodoPlano_Mensal: EPeriodoPlano = EPeriodoPlano.Mensal;
  private EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;

  private tipoPlano: EPeriodoPlano = EPeriodoPlano.Anual;
  private service = new PlanoCredito();
  
  private planList: PlanoCreditoEntity[] = [];
  private planoEscolhido: PlanoCreditoEntity|null = null;
  private planoAtual: PlanoCreditoEntity|null = null;
  
  public serviceAssinatura: Assinatura = new Assinatura();
  public serviceMetodo: MetodoPagamento = new MetodoPagamento();
  public servicePayment: Payment = new Payment();

  public promise: Promise<any>|null = null;
  
  get periodoJobcoins() {
    return this.tipoPlano === EPeriodoPlano.Mensal ? '' : 'Monthly interviews';
  }

  @Watch('tipoPlano')
  public tipoPlanoChanged() {
    // this.loadPlanos();
  }  

  public showModalMP() {
    this.$bvModal.show('modalChangePaymentMethod')
  }

  public handleChange(flag: boolean) {
    this.$bvModal.hide('modalChangePaymentMethod');
    this.serviceMetodo.fetchCurrent();
  }

  public async created() {
    // starting async fetching the payment method
    this.serviceMetodo.fetchCurrent();
    const planoId = this.$route.query.pid ;
    if (planoId && sessionStorage.getItem('access_token')) {
      this.planoEscolhido = await this.service.fetchWithData({id: 0 ,planid: planoId});
      if (this.planoEscolhido)
        this.tipoPlano = this.planoEscolhido.period!;
      
      await this.serviceAssinatura.fetchWithData({id: 0, current: true})
      
      if (this.serviceAssinatura.entidade.planoCredito && this.serviceAssinatura.entidade.planoCredito.id)
        this.planoAtual = this.serviceAssinatura.entidade.planoCredito
    }

  }

  public async loadPlanos() {
    this.planList = [];

    const data: { planoCreditos: PlanoCreditoEntity[] } = await this.service.fetchAll({ p: this.tipoPlano });

    // if (this.tipoPlano === EPeriodoPlano.Anual) {
    //   data.planoCreditos.pop();
    // }

    this.planList = data.planoCreditos;
  }

  public planoSelecionado(planoId: number) {
    sessionStorage.setItem('planoId', planoId.toString());
    this.$router.push({ name: 'PlanPay', query: {lang: this.$i18n.locale} });
  }

  public confirmPayment() {
    if (!this.canBuyPlan()) {
      this.$alert.addMsg(this.$t('Your purchase was not made at the moment, please contact one of our consultants').toString());
      this.$alert.addTitle(this.$t('Attention').toString());
      this.$alert.callBoxError();
      return;
    }
    const msg: string = this.$t('You are purchasing the {v} plan',{v: this.planoEscolhido!.nome}).toString()
    this.$confirm.open({ okTitle: this.$t('Confirm').toString(), cancelTitle: this.$t('Cancel').toString(), msg },'check').then((flag: boolean) => {
      if (flag) {
        this.makePayment()
      } else {
        console.log('canceled')
      }
    })
  }

  public canBuyPlan(): boolean {
      if (this.planoEscolhido) {
        if (this.planoAtual && this.planoAtual.id !== undefined && this.planoAtual.id !== this.planoEscolhido.id) {
          return (this.planoAtual.period == this.EPeriodoPlano_Anual && this.planoEscolhido.ordem >= this.planoAtual.ordem )
        }
      }
      return true;
  }

  public makePayment() {
      console.log('paid')
      if (this.planoEscolhido) {
        let data = { tp: EPaymentType.IUGU, planoId: this.planoEscolhido.id };
				if (this.canBuyPlan()) {
          this.promise = this.servicePayment.create(data).then((data) => {
            this.$alert.addMsg(this.$t('Your purchase was successfully made').toString());
            this.$alert.callBoxCheck(this.$t('Thank you').toString());
          });
        } else {
          this.$alert.addMsg(this.$t('Your purchase was not made at the moment, please contact one of our consultants').toString());
          this.$alert.addTitle(this.$t('Attention').toString());
          this.$alert.callBoxError();
        }
      } else {
        // plano escolhido não esta setado
      }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
@import "@/assets/scss/_variables.scss";
.switch-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .plano {
    margin: 0;
  }
  .custom-switch {
    margin: 0 4%;
    padding-left: 3rem;
    padding-right: 0rem;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/main";
@import "@/assets/scss/_variables.scss";
.contratar-plano {
  .card-deck {
    align-items: center;
    .card {
      margin-right: 3px !important;
      margin-left: 3px !important;
      .card-footer {
        border: 0;
      }

      h1 {
        color: white !important;
      }
    }
  }
}

@include media(">=desktop") {
  .contratar-plano {
    .card-deck {
      min-height: 500px;
      .card {
        min-height: 470px;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        // &.isPopular {
        //     max-height: 500px;
        //     height: 490px;
        // }
        &:hover {
          max-height: 500px;
          height: 490px;
          margin-right: -10px !important;
          margin-left: -10px !important;
        }
      }
    }
  }
}
@include media("<desktop") {
  .contratar-plano {
    .card-deck {
      .card {
        transition: 0ms;
      }
    }
  }
}
</style>