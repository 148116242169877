<template>
  <div class="container-children simple-form">
    <div class="principal-container">
      <b-container>
        <b-row>
          <b-col md="12" class="mb-3">
            <h2 class=" title text-center text-md-left">{{$t("Interview")}}</h2>
          </b-col>
        </b-row>
        <full-simple-form @saved="goTominhasVagas" @error="error" />
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import FullSimpleForm from '@/views/vagas/partials/FullSimpleForm.vue'
import { VagaEntity } from '@/core/models/Entities';

@Component({
  components: {
    FullSimpleForm
  }
})
export default class FormVagaSimples extends Vue {

  public goTominhasVagas(vaga: VagaEntity) {
    this.$router.push({path: '/my-jobs-openings', query: {lang: this.$i18n.locale, sc: vaga.id+"" }});
  }

  public error() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('Error').toString())
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";

// Media Queries
@include media("<desktop") {
  .title {
    font-size: 2em !important;
  }
}
@include media(">desktop") {
  .title {
    font-size: 2em !important;
  }
  .box-data,
  .box-questions {
    font-size: 0.9em;
  }
}
@include media(">large") {
  .box-data {
    font-size: 1em;
  }
}
</style>

