<template>
<transition name="fade">
  <div class="row pt-2" ref="entrevistaRoom">
       <b-col md="5" offset-md="1">
          <b-card border-variant="light" no-body class="shadow mt-3" >
            <b-card-body >
              <h4 class="text-center">
                {{$t("Live interview")}}
              </h4>
              <p class="text-center">
                {{$t("Create a room and invite your candidate")}}
              </p>
              <b-form @submit="criarSala">
                <text-input
                  variant="gray-bordered"
                  class="mt-4"
                  :required="true"
                  :label="$t('Name of Room')"
                  :placeholder="$t('Interview')"
                  v-model="label"
                  maxlength="50"
                /> 
                
                <b-form-group 
                  :label="$t('Job')" 
                  label-for="vagaSelect"
                  class="vaga-select-list">
                      <multi-select 
                        class="searh-job-input"
                        v-model="selectedVaga" 
                        :options="vagaOptions" 
                        :multiple="false"
                        label="text" 
                        track-by="text"
                        :custom-labels="customLabel"
                        :show-labels="false"
                        :placeholder="$t('Select')">
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">{{ $t(props.option.text) }}</div>
                          </template>
                      </multi-select>
                </b-form-group>             
                <b-list-group >
                  <b-list-group-item class="flex-fill" v-if="!forceAnonimous && permitBlind">
                    <div class="d-flex justify-content-between">
                      <label for="checkbox_blind" class="m-0"> 
                        {{$t('Anonymous Interview')}} 
                        <b-button id="button-blind" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                        <b-tooltip target="button-blind" placement="top">
                          <p> {{$t('All candidate information is hidden, only the sound of the interview can be evaluated')}}</p>   
                        </b-tooltip>
                      </label>
                      <b-form-checkbox id="checkbox_blind" v-model="blind" @input="() => blindCV = blind" :value="true" switch :unchecked-value="false" v-if="permitBlind">
                      </b-form-checkbox>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item class="flex-fill">
                    <div class="d-flex justify-content-between">
                      <label for="checkbox_blind_cv" class="m-0"> 
                        {{$t('Anonymous Resume')}} 
                        <b-button id="button-blind_cv" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                        <b-tooltip target="button-blind_cv" placement="top">
                          <p> {{$t('Enable hidden CV so candidate can share information for your hidden review')}}</p>   
                        </b-tooltip>
                      </label>
                      <b-form-checkbox id="checkbox_blind_cv" :disabled="!blind" v-model="blindCV" :value="true" switch :unchecked-value="false" v-if="permitBlind">
                      </b-form-checkbox>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item class="flex-fill" v-if="permitBlockApprove">
                    <div class="d-flex justify-content-between">
                      <label for="checkbox_block_approve" class="m-0"> 
                        {{$t('Prevent talent revelation')}} 
                        <b-button id="button-block_approve" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                        <b-tooltip target="button-block_approve" placement="top">
                          <p> {{$t('Blocks the talent revelation functionality in the room')}}</p>   
                        </b-tooltip>
                      </label>
                      <b-form-checkbox id="checkbox_block_approve" v-model="blockApprove" :value="true" switch :unchecked-value="false" v-if="permitBlind">
                      </b-form-checkbox>
                    </div>
                  </b-list-group-item>
                </b-list-group>

                
                <button-collapse :question="$t('Schedule Live Interview')" class="mt-4">
                  <date-input
                    :label="$t('Interview Date')"
                    :id="'input-date-end'"
                    v-model="dtRealiza"
                    :placeholder="'dd/mm/yyyy'"
                    :format="'DD/MM/YYYY'"
                    :typeable="true"
                    :disabled-dates="{ to: yesterday}"
                    variant="dark"
                    :bootstrap-styling="true"
                  />    
                  <select-input 
                    :label="$t('Start Time')" 
                    :optionList="timeList"
                    v-model="horaInicio"
                    class="initial-time"
                  />              
                  <select-input 
                    :label="$t('Timezone')" 
                    :optionList="tzlist"
                    v-model="timezone"
                    class="initial-time"
                  />    


                  <div class="d-flex justify-content-between">
                    <label for="checkbox_send_email" class="m-0"> 
                      {{$t('Send invitation by email')}} 
                      <b-button id="button-send_email" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
                      <b-tooltip target="button-send_email" placement="top">
                        <p> {{$t('If enabled, you will receive an email invitation to book the interview in your calendar!')}}</p>
                      </b-tooltip>
                    </label>
                    <b-form-checkbox id="checkbox_send_email" v-model="sendEmail" :value="true" switch :unchecked-value="false">
                    </b-form-checkbox>
                  </div>

                </button-collapse>

                <div class="d-flex justify-content-center mt-4">
                    <b-button id="button-criarSala" variant="outline-primary" :disabled="!label" @click="criarSala" v-promise-btn="{promise:promiseCreate}">
                      <i class="fa fa-plus" /> {{$t('Create Room')}}
                    </b-button>
                </div>
              </b-form>
            </b-card-body>
          </b-card>
       </b-col>
      <b-col md="4" v-if="series.length > 0 && empresa && !loadingGraph">
        <h4 class="text-center mt-5" > {{$t('Interview Room Usage')}}  
          <div v-if="!isLimited"><small>({{$t('minutes')}})</small></div>  
          <div v-if="isLimited"><small>({{$t('number of rooms')}})</small></div>
        </h4>
        <apexchart type="pie" height="270" :options="chartOptions" :series="series"></apexchart>
      </b-col>
      <b-col md="4" class="d-flex align-items-center justify-content-center" v-else-if="loadingGraph">
        <div class="mb-3">
          <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="primary"></b-spinner>
        </div>
      </b-col>
      
    <b-modal :id="'modalRoomCalendarEvent'" size="sm" :header-class="'checkHeader'" >
      <template v-slot:modal-footer>
        <div class="w-100 "   >
          <b-button variant="primary" size="lg" block @click="goToRoom"> 
            <i class="fa fa-share"></i>  {{$t("Go to the Room")}} 
          </b-button> 

          <b-button variant="success" size="lg"  block @click="goToEntrevistas"> 
            <i class="far fa-copy"></i> {{$t("Go to details")}} 
          </b-button> 
          
        </div>
      </template>

      <div class="modal-body-m p-2 checkBody" >
        <div class="w-100" >
          <strong>{{$t("Room successfully created!")}}</strong>
        </div>
      </div>
    </b-modal>
  </div>
</transition>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";
import RoomService from "@/core/Services/Video/Room";
import { siteCandidateEndPoint } from "@/core/endpoint";
import { EmpresaEntity } from '@/core/models/Entities';
import { ApexOptions } from 'apexcharts';
import TimezoneHelper from "@/core/helper/TimezoneHelper";
import ButtonCollapse from '@/components/button-icon/ButtonCollapse.vue';
import Multiselect from 'vue-multiselect'
import Dashboard from '@/core/Services/Empresa/Dashboard';

const remCities = ["Europe/Kaliningrad", 
"Europe/Moscow",
"Europe/Samara", 
"Asia/Yekaterinburg", 
"Asia/Omsk", 
"Asia/Krasnoyarsk", 
"Asia/Novosibirsk", 
"Asia/Irkutsk", 
"Asia/Chita", 
"Asia/Vladivostok", 
"Asia/Magadan", 
"Asia/Srednekolymsk", 
"Asia/Anadyr"];

@Component<EntrevistaRoom>({
  components:{
    'button-collapse': ButtonCollapse,
    'multi-select': Multiselect,
  }
})
export default class EntrevistaRoom extends Vue {
  public room: RoomService;
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public currentKey: string = "";
  public label: string = "";
  public blind: boolean = true;
  public blindCV: boolean = true;
  public blockApprove: boolean = false;
  public sendEmail: boolean = true;
  public currentBlind: boolean = false;
  public promiseCreate: Promise<any>|null = null;
  public promiseSchedule: Promise<any>|null = null;
  public series: number[] =  []
  public loadingGraph: boolean = true;
  public dtRealiza: Date | null = null;

  public selectedVaga: any = {};
  public timeList: any[] = [];
  public horaInicio: string = '';
  
  public tzlist: any = null;
  public timezone: string = "";

  public yesterday: Date = new Date();
  public service: Dashboard;
  public vagaOptions: any[] = [];
  public chartOptions: ApexOptions = {
            chart: {
              width: 250,
              type: 'pie',
            },
            labels: ['Usado', 'Saldo'],
            colors: ['#ba2c73', '#473198'],
            legend: {
              position: 'bottom'
            },
            dataLabels: {
              enabled: false
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                }
              }
            }]
          };
    
  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  constructor() {
    super();
    this.service = new Dashboard();
    this.room = new RoomService();
  }

  
  public async loadJobs() {
    const data: any[] = await this.service.fetchJobsList({ status: 'ATIVO' });
    this.vagaOptions.push({value: '', text: this.$t('Select')})
    data.forEach(vagaOptin => {
      this.vagaOptions.push(vagaOptin)
    });
  }

  public async mounted() {
    this.tzlist = (await TimezoneHelper.tzlist()).timezones;
    this.timezone = await TimezoneHelper.GuessTimezone();
  
    this.loadJobs();

    for (let index = 1; index <= 24; index++) {
      this.timeList.push(
        {
          text: `${index}:00`,
          value: `${index}:00`
        },
        {
          text: `${index}:30`,
          value: `${index}:30`
        }
      )
    }

    this.dtRealiza = new Date();
    this.yesterday.setDate(this.dtRealiza.getDate() -1 );
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
    }

    try {
      this.loadingGraph= true;
      const dataList: any = await this.room.fetchAll({only_usage:true})
      const totalMonthly = (this.empresa?.configuracoes['ROOM_MONTHLY_MIN'] as number);
      let usage: number = 0;
      let total: number = 0;
      if(this.isLimited){
        usage = (dataList.totalCountRooms as number)||0;
        total = (this.limitOfRooms-usage);
        this.chartOptions.labels = [this.$t('Usage').toString(), this.$t('Balance').toString()]
      }else {
        usage = (dataList.monthlyUsage as number)||0;
        total = (totalMonthly-usage);
        this.chartOptions.labels = [this.$t('Used (min)').toString(), this.$t('Remaining (min)').toString()]
      }
      this.series = [usage,  total];
      console.log(this.series)
    }catch(e) {
      console.error(e);
    }finally {
      this.loadingGraph= false;
    }
  }

  get forceAnonimous () {
    return (this.empresa?.configuracoes?.ENABLE_FORCE_ANONIMOUS == true);
  }
  
  get permitBlind () {
    return (this.empresa?.configuracoes?.BLIND_ROOM_INTERVIEW == true);
  }
  
  get permitBlockApprove () {
    return (this.empresa?.configuracoes?.ENABLE_CLOSE_APPROVEMENT == true);
  }

  get isLimited () {
    return (parseInt(this.empresa?.configuracoes?.NUMBER_TOTAL_ROOMS) > 0);
  }

  get limitOfRooms () {
    return parseInt(this.empresa?.configuracoes?.NUMBER_TOTAL_ROOMS);
  }

  public async criarSala() {
    this.label = this.label.substring(0,50);
    let vagaGuid:any = this.selectedVaga?.value;
    let blockApprove: boolean|null = this.blockApprove;
    const dtAgenda: string =  this.$dateUTCDB((this.dtRealiza || (new Date)), this.horaInicio);
    this.promiseCreate = this.room.createLiveInterview(2, undefined, this.label, this.blind, this.blindCV, dtAgenda, this.timezone, this.sendEmail, vagaGuid, blockApprove )
    .then((data) => {
      this.label = '';
      this.currentBlind = this.blind;
      this.blind = false;
      if (data.id) {
        this.currentKey = data.key;
        this.$bvModal.show('modalRoomCalendarEvent');
      }
    }).catch((err) => {
      this.label = '';
      this.currentBlind = this.blind;
      this.blind = false;
      console.log(err);
    });
  }

  public async agendarSala() {
    this.label = this.label.substring(0,50);
    this.promiseSchedule = this.room.createLiveInterview(2, undefined, this.label, this.blind, undefined, this.$dateUTCDB((this.dtRealiza || (new Date)), this.horaInicio), this.timezone, this.sendEmail ).then((data) => {
      if (data.id) {
        this.currentKey = data.key;
        this.currentBlind = this.blind;
        this.$bvModal.show('modalRoomCalendarEvent');
      }
      this.label = '';
      this.blind = false;
    }).catch((err) => {
      this.label = '';
      this.blind = false;
      console.log(err);
    });
  }

  public copyAccessLink(event: any) {
    this.$copyText(this.accessLink(this.currentKey),event.target).then(this.onCopy).catch(this.onError)
  }
  public copyCandidateLink(event: any) {
    this.$copyText(this.candidateLink(this.currentKey),event.target).then(this.onCopy).catch(this.onError)
  }
  public copyManagerLink(event: any) {
    this.$copyText(this.managerLink(this.currentKey),event.target).then(this.onCopy).catch(this.onError)
  }

  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
  }

  public goToRoom() {
    const x: any = this.$router.resolve({ name: 'RoomManagerFull',params: { sid: this.currentKey }, query:  { b: this.currentBlind + "", lang: this.$i18n.locale }})
    window.open(x.href, '_blank');
  }
  public goToEntrevistas() {
    this.$emit('change',2)
    this.$bvModal.hide('modalRoomCalendarEvent');
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }
    
  public candidateLink(key: string) {
    return this.URLcandidato + "/sala-entrevista/" + key;
  }
  public managerLink(key: string) {
    return this.URL + "/emp/sala-entrevista/" + key;
  }
  public accessLink(key: string) {
    return this.URL + "/room/" + key;
  }

  public async enterRoom(key: any) {
    this.$router.push({ name: 'RoomManager',params: { sid: key}, query:  { lang: this.$i18n.locale }})
  }
  
  public customLabel({text,value}) {
    return ""+this.$t(text)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.room-card {
  background-color: white;
  padding: 1.5em;
  width: 100%;
  border-radius: 5px;
  .dropdown-text {
    font-size: 0.8em;
  }
  .list {
    .btn-sm {
      line-height: 1;
    }
    h1 {
      font-size: 2.5em;
    }
    &__vagas {
      margin-bottom: 1em;
    }
  }
}
</style>