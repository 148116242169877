<template>
  <div class="box-saldo-jobcoins">
    <div class="row">
      <div class="col-md-12">
        <p>{{$t("This is your balance in interviews available. Your balance will be renewed on the x day of the next month.")}}</p>
      </div>
      <div class="col-md-12 jobecoins">
        <div class="jobecoins__range-col p-2">
            <input type="range" class="input-range" disabled step="1" :min="0" :max="getTotalCreditos()" :value="getSaldoCreditos()"/>
            <div class="col-12 mt-1 pr-0 pl-0 range_scope">
              <div class="range_min">{{0}}</div>
              <div class="range_value" :style="getPosition">{{getSaldoCreditos()}}</div>
              <div class="range_max">{{getTotalCreditos()}}</div>
            </div>
        </div>
      </div>
      <!-- <div class="col-md-6 text-md-center">
        <b-button
          :to="{ name: 'PlanBuy', query: {lang: $i18n.locale } }"
          pill
          class="btn-info text-uppercase"
        >{{$t('Adquirir novo plano')}}</b-button>
      </div> -->
      <div class="col-md-6 text-md-center">
        <b-button
            :to="{ name: 'PlanoCustomizado', query: { lang: $i18n.locale } }"
            pill
            class="text-uppercase"
            variant="pink"
          >{{$t("I want a custom one")}}</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import GestaoHCService from "@/core/Services/Empresa/GestaoCredito";
import Assinatura from '@/core/Services/Payments/Assinatura';
import { EmpresaEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";

@Component
export default class SaldoAtual extends Vue {
  public service: Assinatura;

  @Action("loadEmpresaInfo", { namespace: 'profile' })
  public loadEmpresaInfo: any;

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;
  
  public historicoCreditoService: GestaoHCService = new GestaoHCService();
  // MEU SALDO GERAL
  public saldo: number = 0;
  public usado: number = 0;
  public total: number = 0;

  constructor() {
    super();
    this.service = new Assinatura();
  }

  public async created() {
    if (!this.empresa) {
      await this.loadEmpresaInfo();
    }
    
    this.historicoCreditoService = new GestaoHCService();
    this.historicoCreditoService.fetchAll({saldOnly:true}).then((data: any) => {
      this.saldo = data.saldo
      this.usado = data.usado
      this.total = data.total
    }).catch((err) => {
      console.log(err)
    })
  }

  public getTotalCreditos() {
    return this.total;
  }

  public getSaldoCreditos() {
    return this.saldo;
  }

  get getPosition() {
    const percent = ((100*(this.saldo||0))/this.total);
    return this.saldo >= 1 ? ( (percent >= 90 ) ? `left:90%` : `left:${percent}%`) : 'left:0%';
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.box-saldo-jobcoins {
  background-color: $card-back-color;
  padding: 1em;
  .btn {
    font-size: 0.85em;
    width: 13.5rem;
    padding: 7px 10.5px;
  }
}
.jobecoins {
  // border-top-right-radius: 8px;
  margin: 25px 0;
  label {
    font-size: 0.65em;
    line-height: 1em;
    margin: 0;
  }

  .fa-plus{
    vertical-align: text-bottom;
  }

  &__button-col {
    padding: 0;
    text-align: center;
  }

  &__range-col {
    padding-left: 0;
  }

  .range_scope {
    position: relative;
    .range_min {
      position:absolute;
      left: 0;
      color: gray;
    }
    .range_max {
      position: absolute;
      right: 0;
      text-align: right;
      color: gray;
    }
    .range_value {
      position: absolute;
      top: -60px;
      font-size: 1.5em;
      color: green;
    }
  }
  
}
</style>