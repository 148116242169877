<template>
  <transition name="slide">
    <div :class="rowClass" v-if="value">
      <div class="d-flex justify-content-between p-3 w-100 body">
        <!-- Title -->
        <vaga-title v-model="value"/>

        <!-- Adicionar Jobcoins -->
        <add-jobcoins
          v-model="value"
          :saldo-total="saldoTotal"
        />
      </div>
    
      <!-- Footer -->
      <vaga-footer 
        v-model="value"
        :hasRSToken="hasRSToken"
        :showCopyFor="showCopyFor"
        :saldo-total="saldoTotal"
        @modalVagaShare="compartilharMassa"
        @encerrada="encerrarEmit"
        @excluida="excluirEmit"
        @publicada="publicarEmit"
        @impulsionada="impulsionadaEmit"
        @inativada="inativarEmit"
        @showModalFeedback="showModalFeedbackEmit"
        @showJobcoinModal="showModalEmit"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { VagaEntity } from "@/core/models/Entities";
import AdicionarJobcoins from '@/views/vagas/AdicionarJobcoins.vue'
import VagaFooter from '@/views/vagas/VagaFooter.vue';
import VagaStatus from '@/views/vagas/VagaStatus.vue';
import VagaTitle from '@/views/vagas/VagaTitle.vue';

@Component({
  components: {
    'vaga-status': VagaStatus,
    'add-jobcoins': AdicionarJobcoins,
    'vaga-footer': VagaFooter,
    'vaga-title': VagaTitle,
  },
})
export default class Vaga extends Vue{

  @Prop()
  public value!: VagaEntity;

  @Prop({ default: 0 })
  public saldoTotal!: number;
  
  @Prop({ default: false })
  public hasRSToken!: boolean;
  
  @Prop({ default: null })
  public showCopyFor!: any;
  
  get rowClass() {
    return `row vaga-wrapper`;
  }

  public encerrarEmit() {
    this.$emit('encerrada', this.value.id)
  }

  public excluirEmit() {
    this.$emit('excluida', this.value.id)
  }

  public publicarEmit() {
    this.$emit('publicada', this.value.id)
  }
  
  public impulsionadaEmit(status: string) {
    this.$emit('impulsionada', this.value.id, status)
  }

  public inativarEmit() {
    this.$emit('inativada', this.value.id)
  }

  public compartilharMassa() {
    // chamar modal
    // this.currentVagaContext = vaga
    this.$emit('modalVagaShare', this.value)
  }

  public showModalEmit() {
    this.$emit('showModalAddJobcoins', this.value)
  }

  public showModalFeedbackEmit() {
    this.$emit('showModalFeedback')
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  .title,
  .vaga-footer,
  .status,
  .jobecoins {
    .btn-add-jobecoin {
      padding-bottom: 1px;
    }
  }
  .title {
    border-top-left-radius: 8px;
  }
  .status {
    border-left: 0;
    border-right: 0;
  }
  .jobecoins {
    border-top-right-radius: 8px;
  }
  .vaga-wrapper {
    border-radius: 8px;
    border: 1px solid $border-gray;
    box-shadow: #e8e8e8 2px 2px 2px;
  }
  .border-ativo {
    border-left: 5px solid $success;
  }
  .border-inativo {
    border-left: 5px solid $warning;
  }
  .border-encerrada {
    border-left: 5px solid $border-gray;
  }
  .vaga-wrapper:hover {
    box-shadow: #ccc 1px 1px 10px;
  }
  .vaga-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid $border-gray;
    background: #f8f8f8;
  }

  @include media("<desktop") {
    .title,
    .status,
    .jobecoins {
      border: 0 !important;
    }
    .status {
      padding: 1.2em;
      font-size: 0.9em;
    }
    .vagas-list {
      background-color: transparent;
      .vaga {
        margin-bottom: 1em;
        background-color: white;
      }
    }
    .vaga-wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  @include media(">desktop", "<=large") {
    
  }

  @include media(">large") {
    
  }

  @media screen and (max-width: 1070px) {
    .vaga-wrapper {
      .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .title {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
      }

      .jobecoins {
        width: 100%;
      }
    }
  }
</style>