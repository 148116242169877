import { VagaEntity } from '@/core/models/Entities';
import { Vue, Component, Watch } from 'vue-property-decorator';


@Component
export default class VagasSearchMixin extends Vue {
  public service: any;
  public list: any[]|null = null;
  public numberActives: number = 0;
  public numberInactives: number = 0;
  public numberCloseds: number  = 0;
  public numberConfidencials: number = 0;
  public numberBlinds: number = 0;
  public numberRestricteds: number  = 0;
  public limit: number = 20;
  public mini!: boolean;
  public search: string = "";
  public loaded: boolean = false;
  public page: number = 1;
  public loading: boolean = true;
  public totalCount: number = 0;
  public keywords: string = '';
  public showFilter: boolean = false;
  public status: any[] = ['ATIVO'];
  public responseKey: string = '';
  public confidencial: number = 0;
  public restrita: number = 0;
  public cega: number = 0;
  public showCopyFor: string = '';
  public hasRSToken: boolean = false;
  public nothingFound: boolean = false;
  private hasMore = true;

  private get currLimit() {
    return this.limit ? this.limit : 8;
  }

  public clearSearchVar() {
    this.search = '';
  }

  public mounted() {
    this.search = this.$route.query.s ? this.$route.query.s as string : '';
    this.showCopyFor = (this.$route.query.sc||"")+"";
 
    this.filterResults();
  }

  public toggleStatus(s: string) {
    // Se 'ALL' refresh filters
    if (s === 'ALL') {
      this.status = [];
      this.restrita = this.confidencial = this.cega = 0;
    } else {
      const index = this.status.indexOf(s);
      if ( index > -1 ) {
        this.status.splice(index, 1);
      } else {
        this.status.push(s);
      }
    }

    this.reloadSearch();
  }

  public reloadSearch(callback?: any): void {
    console.log('reload.search');
    this.list = [];
    this.filterResults(1);

    if (callback)
      callback();
  }

  get forAll() {
    return this.status.length === 0;
  }

  get hasActive() {
    const index = this.status.indexOf('ATIVO');
    if ( index > -1 )
      return true;

    return false;
  }

  get hasInactive() {
    const index = this.status.indexOf('INATIVO');
    if ( index > -1 )
      return true;

    return false;
  }

  get hasClosed() {
    const index = this.status.indexOf('ENCERRADA');
    if ( index > -1 )
      return true;

    return false;
  }


  @Watch('$route.query.s')
  public globalSearchChanged(value: string) {
    this.search = value;
    this.handleFilterVagas({});
  }

  public handleFilterVagas(filter: any) {
    this.list = [];
    this.page = 1;

    this.filterResults(1);
  }

  public reloadSingle(id: any) {
    console.log('reloaddata',id)
    let index = this.list?.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    console.log(index)

    this.service.fetch(id).then((data: any) => {
      if(this.list && index !==undefined && index > -1) {
        Vue.set(this.list,index,(data as VagaEntity))
        console.log(data.historicoCredito)
      }
    }).catch((err: any) => {
        console.error(err);
        throw new Error('Coun\'d load vaga');
    });

  }

  public filterResults(page?: number) {
    this.loading = true;
    this.hasMore = true;
    this.page = page ? page : this.page;
    const status = this.status ? this.status : [];
    const l = this.currLimit;
    const s = this.search;
    const confidencial = this.confidencial;
    const restrita = this.restrita;
    const cegas = this.cega;
    this.service
      .fetchAll({
        page: this.page,
        limit: l,
        s,
        status,
        confidencial,
        restrita,
        cegas,
      })
      .then((data: any) => {
        if (data) {
          this.nothingFound = false;
          if (!this.list) {
            this.list = [];
          }
          const itens = data[this.responseKey];
          this.numberActives = data['ativas'] || 0;
          this.numberInactives = data['inativas'] || 0;
          this.numberCloseds = data['encerradas'] || 0;
          this.numberConfidencials = data['confidencial'] || 0;
          this.numberRestricteds = data['restrita'] || 0;
          this.numberBlinds = data['cegas'] || 0;
          this.hasRSToken = data['hasRSToken'] || false;

          let nextIndex = this.list.length;
          itens.forEach((vaga: any) => {
            if(this.list)
              Vue.set(this.list,nextIndex,vaga)
            nextIndex++;
          });
          // tslint:disable-next-line: radix
          this.totalCount = parseInt(data['size']);

          if (!itens.length || this.currLimit > itens.length) {
            this.hasMore = false;
          }
          if(this.search && !itens.length) {
            this.nothingFound = true;
          }
        }
        this.loading = false;
      })
      .catch((err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  public showMore() {
    this.filterResults(++this.page);
  }
}
