<template>
  <div class="row">
      <div class="col-md-12">
        <div class="box-historico-pagamento">
          <h5 class="my-3">{{$t('Payment History')}}</h5>
          <b-table small striped :items="items" :fields="fields">
            <template v-slot:cell(arrow)="data">
              <i v-if="data.value" class="fas fa-chevron-up text-success"></i>
              <i v-else class="fas fa-chevron-down text-danger"></i>
            </template>
             <template v-slot:cell(identificacao_fatura)="data">
              <a :href="data.value" :title="$t('Your Invoice Details')" target="_blank">{{ data.value }}</a>
            </template>
          </b-table>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-secondary btn-more text-uppercase rounded-pill">{{$t('See More Releases')}}</button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import Fatura from '@/core/Services/Payments/Fatura';
import { FaturaEntity } from '@/core/models/Entities';
import {i18n} from '@/plugins/i18n'

@Component
export default class HistoricoFaturas extends Vue {
  public service: Fatura = new Fatura();
  public list: FaturaEntity[] = []
  public items: any[] = [];
  
  // TODO show details of faturas
  public valueRange: number = 50;
  public fields = [
    { key: "id", label: "ID" },
    { key: "tipo_de_plano", label: i18n.t("TIPO DE PLANO").toString() },
    { key: "data_pagamento", label: i18n.t("DATA DO PAGAMENTO").toString() },
    { key: "vencimento", label: i18n.t("VENCIMENTO").toString() },
    { key: "status", label: "STATUS" },
    { key: "subtotal", label: "SUBTOTAL" },
    { key: "identificacao_fatura", label: i18n.t("IDENTIFICAÇÃO DA FATURA").toString() }
  ];

  public created() {
    this.service.fetchAll().then((data: any) => {
      this.list = data['faturas']
      // https://faturas.iugu.com/c3ae4dfe-d170-48df-93f7-2163f47a043d-5953
      this.items = []
      this.list.forEach((el: FaturaEntity,index: number) => {
        this.items.push({
          id: el.id,
          tipo_de_plano: el.assinaturaPagamento!.planoCredito!.nome+"",
          data_pagamento: (el.paidAt ? el.paidAt.date : ""),
          vencimento: (el.paidAt ? el.paidAt.date : ""),
          status: el.status+"",
          subtotal: el.currency! + " " + ((el.totalCents) ? (el.totalCents/100) : 0)+"",
          identificacao_fatura: "https://faturas.iugu.com/"+el.secureId+""
        })
      })
    })
  }


}
</script>
<style lang="scss" scoped>
  .btn-more {
    font-size: 0.95rem;
  }
</style>