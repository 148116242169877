<template>
  <modal :title="$t('Send message')" :show="show" :id="id" :size="'md'">
    <b-form-input 
      class="mb-3" 
      :placeholder="$t('Message title')"
      v-model="inputValueMessageTitle"
    ></b-form-input>

    <b-form-textarea
      v-model="inputValueMessage"
      rows="3"
      max-rows="10"
      :placeholder="$t('Message')"
    ></b-form-textarea>

    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="sendMessage"
          v-promise-btn="{promise:currentPromise}"
        >
          {{$t("Send")}}
        </b-button>

        <b-button
          size="md"
          class="float-right mr-2"
          @click="$bvModal.hide(id)"
        >
          {{$t("Cancel")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity, CandidatoEntity } from "@/core/models/Entities";
import MensagemService from "@/core/Services/Basic/MessageJob";
import Modal from '@/components/modal/Modal.vue'
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    Modal
  }
})
export default class ModalSendMessage extends Vue {
  // public candidato!: CandidatoEntity
  public mensagemService!: MensagemService
  public inputValueMessageTitle: string = ''
  public inputValueMessage: string = ''
  public currentPromise: any|null = null;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({default: () => { return new CandidatoEntity()}})
  public candidato!: CandidatoEntity;

  @Prop({default: false})
  public single!: boolean;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({default: () => { return []}})
  public multipleSelection!: any[];

  public created() {
    this.mensagemService = new MensagemService()
  }
  
  public sendMessage() {
    let mensagem: string = this.inputValueMessage
    let titulo: string = this.inputValueMessageTitle

    if (!mensagem && !titulo) {
      this.errorAlert()
      return;
    }

    let ids: any[] = [];
    let data = {};

    if (this.single && this.candidato && this.candidato.id) {
      ids.push(this.candidato.id)
      console.log('candidato')
    } else {
      if (this.single && this.candidatura && this.candidatura.candidato.id) {
        console.log('candidatura')
        ids.push(this.candidatura.candidato.id)
      }
      if (!this.single && this.multipleSelection) {
        console.log('multi')
        this.multipleSelection.forEach((id: any,index: number) => {
          ids.push(id);
        });
      }
    }
  
    if (ids.length == 0) {
      this.emptySelectedMessage()
      return;
    }

    data = {
      idsDestinatarios: ids,
      candidatoId: ids[0],
      mass: true,
      titulo: titulo, 
      assunto: titulo, 
      mensagem : mensagem, 
    };

    if (this.vaga) {
      data["vagaId"] = this.vaga.id
    }

    this.currentPromise = this.mensagemService.create(data)
    .then((data: any) => {
      this.successAlert()
    }).catch((err) => {
      console.log(err)
    });
    this.$bvModal.hide(this.id)
    this.inputValueMessage = ''
    this.inputValueMessageTitle = ''
  }

  public successAlert() {
    this.$alert.title = this.$t('Message sent successfully!')
    this.$alert.addMsg("");
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public emptySelectedMessage() {
    this.$alert.addMsg(this.$t('No reported recipient'))
    this.$alert.callBoxWarn()
  }

  public errorAlert() {
    this.$alert.addMsg(this.$t('Message and Title should be informed'))
    this.$alert.callBoxWarn()
  }
}
</script>

<style>

</style>