export default class ExportTableCustom {
  public static tmpColDelim: string = String.fromCharCode(11);
  public static tmpRowDelim: string = String.fromCharCode(0);
  public static colDelim: string = ';';
  public static rowDelim: string = '\r\n';

  public static formatRows(rows) {
    return rows.join(ExportTableCustom.tmpRowDelim)
        .split(ExportTableCustom.tmpRowDelim).join(ExportTableCustom.rowDelim)
        .split(ExportTableCustom.tmpColDelim).join(ExportTableCustom.colDelim);
  }
  public static grabRow(row: HTMLElement, i: number) {
    let $cols: any = row.querySelectorAll('td:not(.platform-only)');
    if (!$cols.length)
      $cols = row.querySelectorAll('th:not(.platform-only)');
    return [...$cols].map(ExportTableCustom.grabCol).join(ExportTableCustom.tmpColDelim);
  }
  public static grabCol(col: HTMLElement, i: number) {
      if(col.children.length >= 1) {
        return col.children.item(0)?.innerHTML.trim()
      }
      return col.innerHTML.trim(); // escape double quotes
  }

  public $headers: any|null = null;
  public $rows: any|null = null;
  public csv: string = '';

  constructor($table: any, filename: string, a: any) {
    this.$headers = $table.querySelectorAll('thead tr');
    this.$rows = $table.querySelectorAll('tbody tr');

    // Grab text from table into CSV formatted string
    this.csv = '';
    this.csv += ExportTableCustom.formatRows([...this.$headers].map(ExportTableCustom.grabRow));
    this.csv += ExportTableCustom.rowDelim;
    this.csv += ExportTableCustom.formatRows([...this.$rows].map(ExportTableCustom.grabRow)) + '';

    // Data URI
    const BOM = '\uFEFF';
    const csvData = 'data:application/csv;charset=utf-8,' + BOM + encodeURIComponent(this.csv);

    a.setAttribute('download', filename);
    a.setAttribute('href', csvData);
  }
}