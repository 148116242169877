
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class GestaoCredito extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/gestao-credito', method: 'POST', headers: {} },
                fetch: { url: '/v1/gestao-credito/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/gestao-credito', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/gestao-credito/{id}', method: 'PUT', headers: {} }}));
    }
}
