<template>
  <div class="box-container d-flex flex-column mb-5">
    <plural-header/>
    <div class="row">
      <div class="col-12">
        <ul class="nav justify-content-center">
          <li class="nav-item" :class="{ 'active' : activeNav == 0}">
            <a class="nav-link" @click="setActiveNav(0)">{{$t("Featured")}}</a>
          </li>
          <li class="nav-item" :class="{ 'active' : activeNav == 1}">
            <a class="nav-link" @click="setActiveNav(1)">{{$t("Courses")}}</a>
          </li>
          <li class="nav-item" :class="{ 'active' : activeNav == 2}">
            <a class="nav-link" @click="setActiveNav(2)">{{$t("Archives")}}</a>
          </li>
          <li class="nav-item" :class="{ 'active' : activeNav == 3}">
            <a class="nav-link" @click="setActiveNav(3)">{{$t("Videos")}}</a>
          </li>
          <li class="nav-item" :class="{ 'active' : activeNav == 4}">
            <a class="nav-link" @click="setActiveNav(4)">{{$t("Partners")}}</a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="activeNav == 0">
      <plural-inical />
    </div>

    <div v-if="activeNav == 1">
      <plural-cursos />
    </div>

    <div v-if="activeNav == 2">
      <plural-artigos />
    </div>

    <div v-if="activeNav == 3">
      <plural-videos />
    </div>

    <div v-if="activeNav == 4">
      <plural-parceiros />
    </div>

  </div>
</template>

<script lang="ts">
import PluralArtigos from '@/views/plural/PluralArtigos.vue';
import PluralCursos from '@/views/plural/PluralCursos.vue';
import PluralHeader from '@/views/plural/PluralHeader.vue';
import PluralInical from '@/views/plural/PluralInical.vue';
import PluralParceiros from '@/views/plural/PluralParceiros.vue';
import PluralVideos from '@/views/plural/PluralVideos.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { EmpresaEntity, RecrutadorEntity } from '../../core/models/Entities';
const namespace: string = "profile";

@Component<HomePlural>({
  components: {
    'plural-inical' : PluralInical,
    'plural-cursos' : PluralCursos,
    'plural-artigos' : PluralArtigos,
    'plural-videos' : PluralVideos,
    'plural-parceiros' : PluralParceiros,
    'plural-header' : PluralHeader
  }
})
export default class HomePlural extends Vue {

  public activeNav = 0;

  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('recruiterInfo',{ namespace })
  public recrutador!: RecrutadorEntity;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  public created() {
    const x = location.hash.substring(3,location.hash.length);
    if (x && !isNaN(parseInt(x))) {
      this.activeNav = parseInt(x);
    }
  }

  public async mounted() {
    if (!this.recrutador && !this.empresa) {
      await this.fetchEmpresaInfo();
    }
  }

  
  public setActiveNav(nav: number) {
      this.activeNav = nav;
      history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'')  + '#pr' + nav )
  }
  

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.box-container {
  min-height: auto;
  .nav {
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;

    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>