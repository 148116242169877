<template>
<transition name="fade" appear>
  <div id="plural">
    <!-- <br/> -->
    <b-row class="align-content-stretch flex-wrap mt-2">
      <b-col class="mb-2" sm="12" md="12" lg="4" v-for="(post, i) in posts" :key="i">
        <b-card class="card-post" @click="view(post.tituloUrl,post.tipo)">

          <div class="card-post__content" v-if="post.tipo!='VIDEO'">
            <img :src="post.arquivo" alt="Imagem posts"/>
          </div>

          <!-- content -->
          <div class="card-video__content" v-else>
            <iframe 
              width="100%" height="280" :src="getURL(post.videoUrl)"
              frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen 
              >
            </iframe>
          </div>

          <div class="card-post__header"> 
            <b-col>
              <div class="card-post__header-title">
                <p>{{post.titulo}}</p>
              </div>
            </b-col>
          </div>


        </b-card>
      </b-col>
    </b-row>

  </div>
</transition>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import { PluralPostEntity } from '@/core/models/Entities';
import Vue from "vue";
import Component from "vue-class-component";

@Component<PluralInicial>({})
export default class PluralInicial extends Vue {
  public service: PluralPostService;
  public posts: PluralPostEntity[]|null = null;
  public loadingPage:boolean = false;
  public smallText: string = "";

  constructor() {
    super();
    this.service = new PluralPostService(); 
  }

  public mounted() {
    this.loadingPage = true;

    this.service.fetchAll().then((data: any) => {
      console.log(data);
      this.posts = data['pluralPosts'].reverse().slice(0,6);
      // this.posts?.;
    }).catch((err) => { 
      console.log(err) 
    }).finally(()=> {
      this.loadingPage = false;
    })
  }

  public getSmallText(Text: string){
    return Text.slice(0,150);
  }

  public view(url:any, tipo: any) {
    if(tipo == 'CURSO'){
      this.$router.push({ 
        name: 'PluralCursoView', 
        params: { 
          url: url
        }, 
        query: {lang: this.$i18n.locale} 
      });
    }else if(tipo == 'ARTIGO'){
      this.$router.push({ 
        name: 'PluralArtigoView', 
        params: { 
          url: url
        }, 
        query: {lang: this.$i18n.locale} 
      });
    }

    
  }
  

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }

}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

  $card-transition: all ease-in-out 0.24s;

  iframe {
    border-radius: 4px;
  }


   .card-post {
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

    &__header {
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      color: $blue;
      border-bottom: $blue 1px dashed;
      text-align: center;
      /* height: 100%; */
    }
    &__content {
      overflow: hidden;
      img {
        height: 200px;
      }
    }
  } 

</style>