<template>
  <div class="form-container">
    <h1 class="h3">{{ $t('Account confirmation') }}</h1>
    <p class="text-primary my-0">{{ $t("We've sent you an email with your account confirmation code. Enter it in the field below") }}</p>

    <b-form
      @submit.prevent="submitForm"
      v-promise-btn="{ action: 'submit' }"
      id="confirmation-form"
      novalidate
    >
      <div class="inputs">
        <text-input
          id="code"
          name="code"
          v-model="$v.code.$model"
          type="text"
          :label="$t('Confirmation code')"
          :required="true"
          :state="$v.code.$dirty ? !$v.code.$error : null"
          :invalid-feedback="$t('The code has to be 6 characters long')"
          variant="dark"
        />
      </div>

      <b-button
        type="submit"
        variant="blue"
        v-promise-btn="{ promise: confirmationPromise }"
      >
        {{ $t('Access my dashboard') }}
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import TextInput from '@/components/form/TextInput.vue';
import TelInput from '@/components/form/TelInput.vue';
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import Alert from '@/components/alerts/Alert.vue';

import AccountConfirmation from '@/core/Services/Empresa/AccountCofirmation';

@Component({
  components: {
    TextInput,
    TelInput,
    CheckboxInput,
  },
})
export default class ConfirmationForm extends Vue {
  @Prop({ default: undefined })
  public email?: string;

  @Validate({
    required,
    minLength: minLength(6),
    maxLength: maxLength(6)
  })
  private code: string = '';

  public confirmationPromise: Promise<any> | null = null;

  private accountConfirmationService = new AccountConfirmation();
  private alert: Alert = new Alert();

  public async submitForm() {
    this.$v.$touch();

    if (this.$v.$invalid) return;

    if (!this.code.trim() || !this.email) return;

    this.confirmationPromise = this.accountConfirmationService.create({
      email: this.email,
      code: this.code
    });

    try {
      const data = await this.confirmationPromise;

      this.$emit('success', data);
    } catch (error) {
      this.alert.addMsg(this.$t('Entered code is invalid'));
      this.alert.callBoxWarn();

      this.$emit('error', error);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  div.form-container {
    h1, p {
      text-align: center;
    }

    h1 {
      font-weight: bold;
      color: $dark-gray;
    }

    form {
      width: 100%;
      max-height: 80%;

      display: flex;
      flex-direction: column;
      align-items: center;

      div.inputs {
        margin: 24px 0px;
      }

      button {
        width: 80%;
        padding: 8px 0px;
      }
    }
  }
</style>
