<template>
  <transition appear name="fade">
  <div class="dashboard">

    <b-row class="mb-3">
      <b-col md="12">
        <h1 class="text-center text-md-left">{{$t("Dashboard")}} - {{vagaLabel}}</h1>
      </b-col>
      <b-col md="12">
        <report-table @done="tableDone" />
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <b-card class="card-qt" no-body>
          <b-card-body class="card-qt__body text-center">
            <!-- Title header -->

            <b-row>
              <b-col md="4" offset-md="2" class="d-flex justify-content-center border-right">
                <dash-donut-chart  
                  v-if="dados" 
                  :labels="[$t('Pendings'), $t('Analysis'), $t('Approveds')]" 
                  :colors="[ '#FECC6C', '#5CB7FC','#28a745']" 
                  :series="seriesEntrevistas" 
                  :label="$t('Interviews')" 
                  :icon="'camera'"
                  bg="white" />
              </b-col>
              <b-col md="4" class="d-flex justify-content-center">
                <dash-donut-chart  
                  v-if="dados" 
                  :labels="[$t('Open days')]" 
                  :colors="['#28a745']" 
                  :series="seriesTempoMedio" 
                  :label="$t('Open days')" 
                  :icon="'graficos'"
                  bg="white"
                   />
              </b-col>
              <b-col md="2" sm="12"  >
                <div class="d-flex justify-content-center py-5" v-if="!isReady">
                    <b-spinner style="width: 5rem; height: 5rem;" :label="$t('Loading Complete Report')" variant="primary" ></b-spinner>
                </div>
                <a 
                  v-b-tooltip.hover
                  :title="'Download ' + $t('Job Report')"
                  id="downloadLink" href="javascript:void(0)" 
                  class="btn btn-primary float-right" @click="downloadReport"  v-if="isReady" >  
                  <span><i class="fas fa-download fa-2x"></i>   </span>
                </a>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    

    <!-- DIVERSIDADE -->
    <b-row  class="mb-3">
      <b-col md="12">
        <b-card class="card-qt2" no-body>            


          <b-card-body class="card-qt2__body text-center">

            <b-row  class="mb-10 text-md-left">
              <b-col md="6">
                <h1>{{$t("Diversity")}}</h1>
              </b-col>              
              <b-col md="6">
                <div align="right">
                  <b-button
                    @click="details = !details"
                    size="sm"
                    id="btdetails"
                    variant="blue" 
                  >
                    <b-icon :icon="details?'arrow-up':'arrow-down'" ></b-icon>
                  </b-button>
                  <b-tooltip target="btdetails" triggers="hover">
                    {{details?$t('Hide subtitles'):$t('Show subtitles')}}
                  </b-tooltip>
                </div> 
              </b-col>              
            </b-row>              


            <b-row>
              <b-col md="3" class="d-flex justify-content-center border-right">
                <dash-donut-chart  
                  v-if="dados"
                  :labels="[$t('Male'), $t('Female'), $t('Man Trans'), $t('Woman Trans'), $t('Non-binary'), $t('Others')]" 
                  :colors="['#E3BAC6', '#FECC6C','#5CEDBC','#A897E1','#5CB7FC','#FB8495','#28a745','#00698A']" 
                  :series="seriesGenero"
                  :label="$t('Gender')" 
                  :placeLegend="'bottom'"
                  :legend="details"
                  bg="white" />
              </b-col>
              <b-col md="3" class="d-flex justify-content-center border-right">
                <dash-donut-chart  
                  v-if="dados"
                  :labels="[ $t('White'), $t('Black'), $t('Brown'), $t('Yellow'), $t('Indigenous'), $t('Asian'), $t('Hispanic'), $t('Others')]" 
                  :colors="['#E3BAC6', '#FECC6C','#5CEDBC','#A897E1','#5CB7FC','#FB8495','#28a745','#00698A']" 
                  :series="seriesRaca"
                  :label="$t('Race/Ethnicity')" 
                  :placeLegend="'bottom'"
                  :legend="details"
                  bg="white" />
              </b-col>
              <b-col md="3" class="d-flex justify-content-center  border-right">
                <dash-donut-chart  
                  v-if="dados"
                  :labels="[$t('Hearing'), $t('Speech'), $t('Physical'), $t('Intellectual / Mental'), $t('Visual')]" 
                  :colors="['#E3BAC6', '#FECC6C','#5CEDBC','#A897E1','#5CB7FC','#FB8495','#28a745','#00698A']" 
                  :series="seriesPCD"
                  :label="$t('People with disabilities')" 
                  :placeLegend="'bottom'"
                  :legend="details"
                  bg="white"
                   />
              </b-col>
              <b-col md="3" class="d-flex justify-content-center">
                <dash-donut-chart  
                  v-if="dados"
                  :labels="['LGBTQIA+', $t('Indigenous'), $t('Refugee and Immigrants'), $t('Afro-descendants Community'), $t('Womens Movement'), $t('PWD Group'), $t('Senior Citizens')]" 
                  :colors="['#E3BAC6', '#FECC6C','#5CEDBC','#A897E1','#5CB7FC','#FB8495','#28a745']" 
                  :series="seriesDiversidade"
                  :label="$t('Belonging Groups')" 
                  :placeLegend="'bottom'"
                  :legend="details"
                  bg="white"
                   />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>   
    </b-row>
  </div>
  </transition>
</template>

<script lang="ts">
import DonutChart from '@/components/chart/DonutChart.vue';
import Report from '@/core/Services/Empresa/Dashboard';
import { DashboardEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import DashDonutChart from './components/DashDonutChart.vue';
import ExportTable from './components/ExportTable';
import ReportTable from './components/ReportTable.vue';

@Component({
  components: {
    DonutChart,
    DashDonutChart,
    ReportTable,
  }
})
export default class DashboardByJob extends Vue {
  public service: Report;
  public dados: DashboardEntity| null = null;
  public details: Boolean = false;
  public isReady: boolean = false;
  // public GenNaoCad: number = 0;
  // public RacaNaoCad: number = 0;
  // public DivNaoCad: number = 0;
  // public PCDNaoCad: number = 0;
  constructor() {
    super();
    this.service = new Report();
  }

  public downloadReport() {
    const table: any = document.querySelector('#completeRep');
    const fName: string = 'report_'+(new Date().getTime())+'.csv';
    const x = new ExportTable(table,fName,document.querySelector('#downloadLink'))
  }

  public tableDone(x: boolean) {
    this.isReady = x;
  }

  get labelVagas() {
    return (this.dados) ? [this.$t('Actives'), this.$t('Inactives')] : []
  }
  
  get vagaLabel() {
    return this.dados?.label
  }

  get seriesVagas() {
    return (this.dados) ? [this.dados.vagasAtivas, this.dados.vagasInativas] : []
  }

  get seriesTempoMedio() {
    return (this.dados) ? [this.dados.tempoMedio] : [1]
  }

  get seriesEntrevistas() {
    return (this.dados) ? [ this.dados.pendentes, this.dados.analise, this.dados.aprovados] : []
  }

  get seriesSaldo() {
    return (this.dados) ? [this.dados.creditos] : []
  }

  // -- GENERO
  get seriesGenero() {
    return (this.dados) ? [this.dados.genMasc,this.dados.genFem, this.dados.genHT, this.dados.genMT, this.dados.genNB, this.dados.genOutros] : []
  }

  // -- RACA
  get seriesRaca() {
    return (this.dados) ? [this.dados.corBran, this.dados.corPre, this.dados.corPar, this.dados.corAma, this.dados.corInd, this.dados.corAsi, this.dados.corHis, this.dados.corOut] : []
  }  

  // -- PCD
  get seriesPCD() {
      return (this.dados)?[this.dados.PCDAud, this.dados.PCDFal, this.dados.PCDFis, this.dados.PCDInt, this.dados.PCDVis] : [];
  }

  // -- GRUPO PERTENCENTE
  get seriesDiversidade() {
    return (this.dados) ? [this.dados.grpLgbt, this.dados.grpIndi, this.dados.grpRefu, this.dados.grpBlack, this.dados.grpFemi, this.dados.grpPcd, this.dados.grp3Ida] : []
  }


  public mounted() {
    const vuid = this.$route.params.vguid;
    this.service.fetchAll({vuid}).then((data: any) => {
      this.dados = data['reportData'];
    })
  }

  public gotoInvite() {
    this.$router.push({path:'/invite', query: {lang: this.$i18n.locale }});
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

$card-qt-border-color: #f1f1f1;

.dashboard {
  margin: 2em;
  .jobecoins {
    &--btn {
      // button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%));
      @include button-variant($orange, $orange);
      font-size: 12px;
    }
  }
  .card-qt2 {
    border: 0.13em solid $card-qt-border-color;
    border-radius: 1.25em;
    min-height: 250px;
    &__body {

      &__content {
        border-left: 0.1em solid $card-qt-border-color;
        width: 100%;
        text-align: center;

        &:first-child {
          border-left: none;
        }

        &__qt {
          font-size: 2.7em;
        }
      }
    }
  }
  .card-qt {
    border: 0.13em solid $card-qt-border-color;
    border-radius: 1.25em;
    min-height: 250px;
    &__body {

      &__content {
        border-left: 0.1em solid $card-qt-border-color;
        width: 100%;
        text-align: center;

        &:first-child {
          border-left: none;
        }

        &__qt {
          font-size: 2.7em;
        }
      }
    }
  }
  .card-blue-background {
    // background: linear-gradient(rgba(0, 171, 204, 0.8) 8%, rgba(6, 111, 182, 0.8) 64%);
    position: relative;
    overflow: hidden;
    &-body {
      background: rgba(0, 171, 204, 0.8);
      position:absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>