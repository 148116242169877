<template>
  <transition name="fade">
    <div class="container-children flex-column position-relative m-h-400" ref="containerLoader">
      <div class="principal-container" >
        <b-container v-if="progress && vaga">
          <b-row>
            <b-col md="12">
              <h1 class="title" v-if="!vaga.id">{{$t('Create new job')}}</h1>
              <h1 class="title" v-else>{{$t('Edit job')}}</h1>
            </b-col>
          </b-row>
          <section-progress :progress="progress" @current="changeCurrentStep" :canChange="canChange" />
          <div v-if="progress.currentSteps == 1">
            <form-vaga-dados ref="dadosVaga" v-model="vaga" @saved="saveDadosVaga" @error="error"></form-vaga-dados>
          </div>
          <div v-if="progress.currentSteps == 2">
            <form-vaga-competencias
              v-model="vaga"
              @NextStep="saveHabilities"
              @PrevStep="currentStepDecrement"
            ></form-vaga-competencias>
          </div>
          <div v-if="progress.currentSteps == 3">
            <form-vaga-perguntas
              v-model="vaga"
              @NextStep="saveQuestions"
              @PrevStep="currentStepDecrement"
            ></form-vaga-perguntas>
          </div>
          <div v-if="progress.currentSteps == 4">
            <form-vaga-jobcoins :vaga="vaga" @NextStep="saveJobcoins" @PrevStep="currentStepDecrement"></form-vaga-jobcoins>
          </div>
        </b-container>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SectionProgress from "@/components/sections/SectionProgress.vue";
import TextArea from "@/components/form/TextArea.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import FormVagaDados from "@/views/vagas/partials/FormVagaDados.vue";
import FormVagaPerguntas from "@/views/vagas/partials/FormVagaPerguntas.vue";
import FormVagaCompetencias from "@/views/vagas/partials/FormVagaCompetencias.vue";
import FormVagaJobcoins from "@/views/vagas/partials/FormVagaJobcoins.vue";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import { VagaEntity, VagaPerguntaCorteEntity } from "@/core/models/Entities";

@Component({
  components: {
    SectionProgress,
    CheckboxInput,
    SelectInput,
    TextArea,
    "form-vaga-dados": FormVagaDados,
    "form-vaga-perguntas": FormVagaPerguntas,
    "form-vaga-competencias": FormVagaCompetencias,
    "form-vaga-jobcoins": FormVagaJobcoins
  }
})
export default class FormVaga extends Vue {
  public service!: GestaoVaga;
  public vaga: VagaEntity|null = null;

  public progress: any|null = null;

  constructor() {
    super();
    this.service = new GestaoVaga();
  }

  get canChange() {
    return (this.vaga?.id||0 > 0);
  }

  public async mounted() {
    this.progress = {
        currentSteps: 1,
        countSteps: 4,
        steps: [
          this.$t("Job data").toString(),
          this.$t("Qualifying Skills").toString(),
          this.$t("Questions and transcription").toString(),
          this.$t("Interviews").toString()
        ]
      }
    const vid: number = parseInt(this.$route.params.id);
    if (vid && !isNaN(vid)) {
      
      const _: any = this;
      _.openLoading(this.$refs.containerLoader);
      await this.service.loadVaga(vid);
      this.vaga = this.service.entidade;
      const x = location.hash.substring(3,location.hash.length);
      if (x && !isNaN(parseInt(x))) {
        this.progress.currentSteps = parseInt(x);
      }
      _.closeLoading();
    } else {
      this.vaga = new VagaEntity();
    }
  }

  public error() {
    console.log("DEU ERRO");
  }

  public saveDadosVaga() {
    
    const vid: number = parseInt(this.$route.params.id);
    if (!vid && this.vaga) {
      this.$router.replace({
        name: "JobsOpeningsEdit",
        params: {id: this.vaga.id+'' } ,
        query: { lang: this.$i18n.locale }
      });
      
      //inicializa os arrays
    }

    if (this.vaga && !this.vaga.PerguntasCorte)
      this.$set(this.vaga,'PerguntasCorte',[]);
    
    if (this.vaga && !this.vaga.vagaHabilidades)
      this.$set(this.vaga,'vagaHabilidades',[]);

    this.currentStepIncrement();
  }

  public saveQuestions() {
    this.currentStepIncrement();
  }
  
  public saveHabilities() {
    this.currentStepIncrement();
  }
  
  public saveJobcoins() {
    this.currentStepDecrement();
  }

  public currentStepIncrement() {
    this.progress.currentSteps++;
    history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'')  + '#ev' + this.progress.currentSteps )
  }

  public currentStepDecrement() {
    this.progress.currentSteps--;
    history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'')  + '#ev' + this.progress.currentSteps )
  }

  public changeCurrentStep(step: any) {
    if(this.canChange) {
      this.progress.currentSteps = step; 
    }
    history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'')  + '#ev' + this.progress.currentSteps )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";

// Media Queries
@include media("<desktop") {
  .title {
    font-size: 2em !important;
  }
}
@include media(">desktop") {
  .title {
    font-size: 2em !important;
  }
  .box-data,
  .box-questions {
    font-size: 0.95em;
  }
}
@include media(">large") {
  .box-data {
    font-size: 1em;
  }
}
</style>



<style lang="scss">
.box-questions {
  .pergunta {
    legend,
    label {
      font-size: 1em !important;
    }
    label {
      margin: 0;
    }
  }
}
</style>

