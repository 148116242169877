
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class Payment extends Repository {

  constructor() {
    super(new ApiRouter({
        create: { url: '/v1/payment', method: 'POST', headers: {} },
        fetch: { url: '/v1/payment/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/payment', method: 'GET', headers: {} }, // tem parametro de Busca 's'
        update: { url: '/v1/payment/{id}', method: 'PUT', headers: {} }}));
  }

}
