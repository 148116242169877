import { EStatus } from '../Enums/EStatus';
import { EVideoSupplier } from '../Enums/EVideoSupplier';

// tslint:disable
export class CandidatoEntity {
  id?: number;
  guid: string | undefined;
  idMD5: string | undefined;
  primeironome: string = "";
  sobrenome: string = "";
  email: string = "";
  nomeUrl: string | undefined;
  userFacebook: string | undefined;
  userLinkedin: string | undefined;
  newsletter: string | undefined;
  sexo: string | undefined;
  idRacaCor: string | undefined;
  lingua: string | undefined;
  dataCadastro: string | undefined;
  dataAlteracao: string | undefined;
  fotoAvatar: string | undefined;
  backgroundAvatar: string | undefined;
  cpf: string | undefined;
  perfil: PerfilCandidatoEntity;
  perfilcandidato?: PerfilCandidatoEntity | undefined;
  completionMissing?: any;
  notification?: any;
  notif_acompanhamento?: number;
  notif_indicacao?: number;
  notifi_novidades?: number;
  lastQuestionaryDate?: number;
  notifAcompanhamento: number = 0;
  notifIndicacao: number = 0;
  notifNovidades: number = 0;
  completionPercentage: number = 0;
  matchCompany: any | null = null;

  constructor() {
    this.perfil = new PerfilCandidatoEntity();
  }
}
export class PerfilCandidatoEntity {
  id?: number;
  apresentacao: string | undefined;
  profissao: string | undefined;
  localidade: string | undefined;
  curriculum: string | undefined;
  videoapresentacao: string | undefined;
  idioma: IdiomaEntity | undefined;
  plano: string | undefined;
  areaAtuacao: AreaAtuacaoEntity | undefined;
  escolaridade: NivelEscolaridadeEntity | undefined;
  dataNasc: string | undefined;
  cep: string | undefined;
  rua: string | undefined;
  numero: string | undefined;
  complemento: string | undefined;
  bairro: string | undefined;
  cidade: string | undefined;
  estado: string | undefined;
  pais: string | undefined;
  telefone: string | undefined;
  cadastroConcluido: string | undefined;
  profissao2: string | undefined;
  dataCancela: string | undefined;
  dataAdesao: string | undefined;
  dataFinal: string | undefined;
  experiencias: Array<ExperienciaEntity> | undefined;
  formacoes: Array<FormacaoEntity> | undefined;
  idiomas: Array<IdiomasCandidatoEntity> | undefined;
  cidadeId: any | null;
  estadoId: any | null;
  areaatuacaoId: number | undefined;
  escolaridadeId: number | undefined;
  nomeEstado: string | undefined;
  nomeCidade: string | undefined;
  thumbVideo: string | undefined;
  videoapresentacaoMP4: string | undefined;
  recordObject?: string;
  recordId?: string;
  habilidades: HabilidadeCandidatoEntity[] = [];
  linkFacebook: string = "";
  linkLinkedin: string = "";
  linkInstagram: string = "";
  idiomaId?: number;
  dataNascimento?: Date = undefined;
  pcd: boolean = false;
  pcdDescricao: string = "";
  grupoLgbt: boolean = false;
  grupoIndiginas: boolean = false;
  grupoRefugiados: boolean = false;
  grupoMovBlack: boolean = false;
  grupoPCD: boolean = false;
  grupoEmpoderaFeminino: boolean = false;
  grupo3Idade: boolean = false;
  aceiteDadosSensiveis: boolean | null = null;
  temExperiencia: boolean | null = null;
  temFormacao: boolean | null = null;


  constructor() {
    this.idioma = new IdiomaEntity();
    this.areaAtuacao = new AreaAtuacaoEntity();
    this.escolaridade = new NivelEscolaridadeEntity();
    this.idiomas = [];
    this.formacoes = [];
    this.experiencias = [];
  }
}
export class IdiomasCandidatoEntity {
  id?: number;
  nivel: string | undefined;
  idiomaId: any | null;
  idioma: IdiomaEntity | undefined;
}
export class IdiomaEntity {
  id?: number;
  idioma: string | undefined;
  legenda: any | undefined;
}
export class PlanoCandidatoEntity {
  id?: number;
  plano: string | undefined;
  preco: string | undefined;
  periodo: string | undefined;
  tempoVideocv: string | undefined;
  compartilhaVideocv: string | undefined;
  quantidadeCandidaturas: string | undefined;
  destaquePerfil: string | undefined;
  quantidadeMensagens: string | undefined;
  popular: string | undefined;
  free: string | undefined;
  mostrar: any | undefined;
}
export class AreaAtuacaoEntity {
  id?: number;
  descricao: any | undefined;
}
export class NivelEscolaridadeEntity {
  id?: number;
  descricao: string | undefined;
  ordenar: any | undefined;
}
export class PaisEntity {
  id?: number;
  nome: string | undefined;
  sigla: string | undefined;
  idioma: any | undefined;
}
export class EstadoEntity {
  id?: number;
  ufNome: string | undefined;
  ufAbrev: string | undefined;
  pais: any | undefined;
}
export class CidadeEntity {
  id?: number;
  cidNome: string | undefined;
  cidCod: string | undefined;
  uf: any | undefined;
  ufId?: number | undefined;
  ufAbrev?: string | undefined;
  completoNome?: string | undefined;
}
export class FormacaoEntity {
  id?: number;
  nome: string | undefined;
  dataEntrada: string | undefined;
  dataSaida: string | undefined;
  atividade: string | undefined;
  localidade: string | undefined;
  descricaoAtividade: string | undefined;
  atual: string | undefined;
  perfilCandidato: string | undefined;
  mesInicio: string | undefined;
  anoInicio: string | undefined;
  mesFim: string | undefined;
  anoFim: string | undefined
  tipo: any | undefined;
  status: string | undefined;
  total: any | undefined;
  atual_: number = 1;
}
export class ExperienciaEntity {
  id?: number;
  nome: string | undefined;
  dataEntrada: string | undefined;
  dataSaida: string | undefined;
  atividade: string | undefined;
  localidade: string | undefined;
  descricaoAtividade: string | undefined;
  atual: string | undefined;
  perfilCandidato: string | undefined;
  mesInicio: string | undefined;
  anoInicio: string | undefined;
  mesFim: string | undefined;
  anoFim: string | undefined;
  total: any | undefined;
  segmento: any | undefined;
  atual_: number = 1;
}

export class ConfiguracaoEntity {
  id?: number;
  key: string = "";
  value: string = "";
  empresa?: EmpresaEntity | undefined;
}
export class VagaEntity {
  id?: number;
  guid: string | undefined;
  nomeUrl: string = "";
  posicao: string = "";
  salario: string = "";
  descricao: string = "";
  descricaoSemHtml: string = "";
  nivel: string = "";
  dataPublicacao: DateEntity | string | null = null;
  dataCadastro: DateEntity | string | null = null;
  dataAtualiza: DateEntity | string | null = null;
  dataEncerra: DateEntity | string | null = null;
  dataFinaliza: DateEntity | string | null = null;
  status: string = "INATIVO";
  localidade: string = "";
  zipcode: string = "";
  requisitos: string = "";
  competencias: string = "";
  confidencial: string = "";
  restrita: string = "NAO";
  transcricao: number = 1;
  creditoQtd: number = 0;
  periodoTrabalho: string = "";
  category: string = "";
  contractType: string = "";
  perguntas: Array<VagaPerguntaEntity>;
  candidaturas?: CandidaturaEntity | any | undefined;
  empresa?: EmpresaEntity | undefined;
  historicoCredito?: any | undefined;
  cidade?: CidadeEntity | undefined;
  cidadeId?: any | null;
  isCandidatado?: boolean;
  isPendente?: boolean;
  blind: number;
  mostrarCurriculoOculto: boolean;
  PerguntasCorte: VagaPerguntaCorteEntity[] = [];
  isSaved: boolean = false;
  isAllowed: boolean = true;
  videoSupplier: EVideoSupplier | number | null = EVideoSupplier.Openvidu;
  vagaHabilidades: VagasHabilidadesEntity[] = [];
  limited: number = 0;
  hideMap: boolean = false;
  applyButtonText: string | null = null;
  salaryMax: number | null = null;
  salaryMin: number | null = null;
  salaryNumber: number | null = null;
  hideSalary: boolean = false;
  beneficios: string[] | null = null;
  nomeEmpresa: string | null = null;
  requireVideoCurriculum: boolean = false;
  requireProfile: boolean = false;
  sendMessageAfterClosing: boolean = false;
  statusImpulsao: string = '';
  referenceId: string = '';
  gpReferenceId: string | null = null;
  ghReferenceId: string | null = null;
  numeroPerguntas?: number;
  multiplicadorJobcoins?: number;
  qtdResets?: number;
  simplificada: boolean = false;
  daysToRecord: number = 0;
  questionaries: any[] = [];
  blockApprove: boolean = false;
  equipesVaga: any[] | null | undefined = null;
  equipes: number[] | null | undefined = null;
  interna: boolean = false;
  cargo: string | null = null;


  constructor() {
    this.perguntas = [];
    this.PerguntasCorte = [];
    this.vagaHabilidades = [];
    this.salario = "";
    this.blind = 1;
    this.limited = 0;
    this.interna = false;
    this.mostrarCurriculoOculto = true;
    this.hideSalary = true;
    this.requireProfile = false;
  }
}

export class DateEntity {
  date?: string;
  timezone_type?: number;
  timezone?: string;
}

export class VagaPerguntaEntity {
  id: number = 0;
  numeroPergunta: number = 0;
  pergunta: string | undefined;
  tempo: number = 1;
  datacadastro: any | undefined;
  idioma: string | undefined;
  answered: boolean | undefined;
  tags!: Array<TagTranscricaoEntity>;
  currentTag: string | null = null;
  constructor() {
    this.numeroPergunta = 1;
    this.tags = [];
  }
}

export class TagTranscricaoEntity {
  id: number = 0;
  tag: string = "";
  idvaga: number | null = null;
  idpergunta: number | null = null;
  constructor(tag: string, idp?: number, idv?: number) {
    this.tag = tag;
    this.idvaga = idv || null;
    this.idpergunta = idp || null;
  }
}

export class VagaVideoRespostaEntity {
  pergunta?: VagaPerguntaEntity;
  candidato?: CandidatoEntity;
  candidatura?: CandidaturaEntity;
  video!: string;
  status!: string;
  pontostranscricao?: number;
  recordObject?: string;
  recordId?: string;
}

export class RecrutadorEntity {
  id?: number;
  guid: string | undefined;
  nome: string | undefined;
  email: string | undefined;
  fotoAvatar: string | undefined;
  nomeUrl: string | undefined;
  cargo: string | undefined;
  status: string | undefined;
  sexo: any | undefined;
  permissaoAdm: string | undefined;
  senha?: string | undefined;
}

export class EmpresaEntity {
  id?: number;
  guid: string | undefined;
  primeironome: string | undefined;
  sobrenome: string | undefined;
  email: string | undefined;
  nomeUrl: string | undefined;
  fotoAvatar: string | undefined;
  backgroundAvatar: string | undefined;
  status: string | undefined;
  sexo: string | undefined;
  lingua: string | undefined;
  relativePath: string | undefined;
  statusCadastro: EStatus | undefined;
  statusPagamento: EStatus | undefined;
  perfil: PerfilEmpresaEntity | undefined;
  perfilempresa: PerfilEmpresaEntity | undefined;
  nomeEmpresa?: string;
  logoEmpresa?: string;
  background?: string;
  cnpj?: string;
  // TODO: Implement Entity Match in Here
  matchCandidate?: any;
  seguindo: boolean = false;
  configuracoes: any | null = null;
  senha?: string;

  constructor() {
    this.perfil = new PerfilEmpresaEntity();
    this.perfilempresa = new PerfilEmpresaEntity();
  }
}
export class PerfilEmpresaEntity {
  id?: number;
  nomeEmpresa: string | undefined;
  razaosocial: string | undefined;
  endereco: string | undefined;
  numero: string | undefined;
  telefones: string | undefined;
  nomeCidade: string | undefined;
  bairro: string | undefined;
  uf: string | undefined;
  pais: string | undefined;
  logoempresa: string | undefined;
  background: string | undefined;
  descricao: string | undefined;
  especializacao: string | undefined;
  setor: string | undefined;
  tamanho: string | undefined;
  fundada: string | undefined;
  tipo: string | undefined;
  site: string | undefined;
  complemento: string | undefined;
  cep: string | undefined;
  cidade?: any | null;
  cidadeId?: any | null;
  ufId?: any | null;
  cnpj?: string | null;
  celularContato: string = "";
  videoApresentacao?: string | null;
  dtFinalPlano?: dtFinalPlanoEntity
}
export class dtFinalPlanoEntity {
  date?: string
  timezone?: string
  timezone_type?: number
}
export class CandidaturaEntity {
  id?: number;
  vaga: VagaEntity;
  candidato: CandidatoEntity;
  status: string | undefined;
  canAnswer: boolean | undefined;
  comentarioRecrutador: string | undefined;
  ratingRecrutador: number | undefined;
  comentarioGestor: string | undefined;
  ratingGestor: number | undefined;
  organica: string | undefined;
  dataCadastro: DateEntity | undefined;
  dataAtualiza: DateEntity | undefined;
  idvaga: string | undefined;
  posicao: string | undefined;
  idcandidato: any | undefined;
  videosRespostas?: Array<VagaVideoRespostaEntity>;
  score?: number;
  avgRating?: number;
  vagaHabilidades?: any[];
  views: any = 0;
  checked: boolean | undefined;
  matchCompany: any | undefined;
  lastLoadingTime: Date | undefined;
  anonymousName: string | undefined;

  constructor() {
    this.candidato = new CandidatoEntity();
    this.vaga = new VagaEntity();
    this.vagaHabilidades = [];
    this.checked = false;
  }

  get classeStatus() {
    switch (this.status) {
      case "VIDEOSPENDENTES":
        return "bg-warning";
      case "EMANALISE":
        return "bg-info";
      case "APROVADO":
        return "bg-info";
      case "REPROVADO":
        return "bg-danger";
      case "VIDEOSEMPROCESSAMENTO":
        return "bg-secondary";
    }
    return "bg-info";
  }
}
export class PlanoCreditoEntity {
  id: number | undefined;
  nome: string | undefined;
  credito_qtd: string | undefined;
  credito_adicional: string | undefined;
  tipo: string | undefined;
  preco?: number;
  preco_usr?: number;
  recomendado?: number;
  free: boolean = false;
  period?: number;
  ordem: number = 0;
  avaliableList?: any
}

export class QuestionaryJobEntity {
  public id: number = 0;
  public vagaId: number | null = null;
  public name: string = '';
  public questionaryId: number | null = null;
  public empresaId: number | null = null;
  public recrutadorId: number | null = null;
  public notaCorte: number = 0;
  public tempoMaximo: number = 0;
  public createdAt: DateEntity | null = null;
}

export class PagamentoCreditoEntity {
  id: number | undefined;
  valor: number | undefined;
  dataPagamento: string | undefined;
  dataCriacao: string | undefined;
  status: string | undefined;
  correlationid?: number;
  payerid?: number;
  token: string | undefined;
  transactionid?: number;
  expressCheckout: string | undefined;
  expressCheckoutDetails: string | undefined;
  recurringPaymentsProfile: string | undefined;
  candidato: CandidatoEntity | undefined;
  empresa: EmpresaEntity | undefined;
  planoCredito: PlanoCreditoEntity | undefined;
  moduloPagamentoId: number | undefined;
}

export class QuestionaryEntity {
  id: number = 0;
  name: string = '';
  isAssessment: boolean = false;
  dateCreated: string | DateEntity = '';
  isOcai: boolean = false;
  isEssay: boolean = false;
  questions: QuestionEntity[];
  removedQuestions: QuestionEntity[];
  status: number = 1;

  constructor() {
    this.questions = [];
    this.removedQuestions = [];
  }
}
export class QuestionEntity {
  id: number = 0;
  questionaryId: number = 0;
  questionTypeId: number = 0;
  answers: AnswerEntity[];
  removedAnswers: AnswerEntity[];
  description: string = '';
  points: number = 0;
  dateCreated: string | DateEntity = '';
  dimensionId: number = 0;
  status: number = 1;
  images?: { id: number, url: string }[] = [];

  constructor() {
    this.answers = [new AnswerEntity()];
    this.removedAnswers = [];
  }
}

export class AnswerEntity {
  id: number = 0;
  description: string = '';
  dateCreated: string | DateEntity = '';
  correct: boolean = false;
  points: number = 0;
  rate: number | null = null;
  status: number = 1;
  images?: { id: number, url: string }[] = [];
}

export class QuestionaryCandidatoEntity {
  id: number | undefined;
  answered: boolean | undefined;
  dateStart: any | undefined;
  dateEnd: any | undefined;
  candidato: CandidatoEntity | undefined;
  questionary: QuestionaryEntity | undefined;
  questionaryAnswerCandidato: QuestionaryAnswerCandidatoEntity[] | undefined;
  questionaryId: number | undefined;
  candidatoId: number | undefined;
  answers: any | undefined;
  score: number | null = null;

  constructor() {
    this.answers = {};
  }
}

export class QuestionaryEmpresaEntity {
  id: number | undefined;
  answered: boolean | undefined;
  dateStart: any | undefined;
  dateEnd: any | undefined;
  empresa: EmpresaEntity | undefined;
  questionary: QuestionaryEntity | undefined;
  questionaryAnswerEmpresa: QuestionaryAnswerEmpresaEntity[] | undefined;
  questionaryId: number | undefined;
  empresaId: number | undefined;
  answers: any | undefined;
  seguindo?: boolean;

  constructor() {
    this.answers = {};
  }
}

export class QCAnswerData {
  answerId!: number;
  rate: number | null = null;
  questionId: number = 0;

  constructor(answerId: number, rate: number) {
    this.answerId = answerId;
    this.rate = rate;
  }
}

export class QuestionaryAnswerCandidatoEntity {
  id: number | undefined;
  rate: number;
  description: string | undefined;
  questionaryCandidato: QuestionaryCandidatoEntity | undefined;
  answer: AnswerEntity | undefined;
  questionaryCandidatoId: number | undefined;
  answerId: number | undefined;

  constructor() {
    this.rate = 0;
  }
}

export class QuestionaryAnswerEmpresaEntity {
  id: number | undefined;
  rate: number;
  description: string | undefined;
  questionaryEmpresa: QuestionaryEmpresaEntity | undefined;
  answer: AnswerEntity | undefined;
  questionaryEmpresaId: number | undefined;
  answerId: number | undefined;

  constructor() {
    this.rate = 0;
  }
}

// Payments

export class AssinaturaEntity {
  id: number | undefined;
  token: string | undefined;
  priceCents: number | undefined;
  currency: string | undefined;
  active: number | undefined;
  suspended: number | undefined;
  trial: number | undefined | null;
  candidato: CandidatoEntity | undefined | null;
  empresa: EmpresaEntity | undefined | null;
  planoCredito: PlanoCreditoEntity | undefined | null;
  createdAt: any | undefined; // date
  updatedAt: any | undefined | null;
  expiresAt: any | undefined | null;
  constructor() {
    this.planoCredito = new PlanoCreditoEntity();
  }
}

export class MetodoPagamentoEntity {
  id: number | undefined;
  description: string | undefined | null;
  itemType: string | undefined | null;
  holderName: string | undefined | null;
  displayNumber: string | undefined | null;
  brand: string | undefined | null;
  validationMonth: number | undefined | null;
  validationYear: number | undefined | null;
  tipo: number | undefined | null;
  default: number | undefined | null;
  candidato: CandidatoEntity | undefined | null;
  empresa: EmpresaEntity | undefined | null;
  createdAt: any | undefined | null;
}

export class FaturaEntity {
  id: number = 0;
  token: string | undefined | null;
  currency: string | undefined | null;
  paidTaxesCents: number | undefined | null;
  totalPaidCents: number | undefined | null;
  totalCents: number | undefined | null;
  status: string | undefined | null;
  secureId: string | undefined | null;
  tipo: number | undefined | null;
  invoiceData: string | undefined | null;
  candidato: CandidatoEntity | undefined | null;
  empresa: EmpresaEntity | undefined | null;
  assinaturaPagamento: AssinaturaEntity | undefined | null;
  createdAt: any | undefined | null;
  paidAt: any | undefined | null;
  dataVencimento: any | undefined | null;
  constructor() {
    this.paidAt = {};
    this.empresa = new EmpresaEntity();
    this.dataVencimento = {};
    this.assinaturaPagamento = new AssinaturaEntity();
  }
}

export interface Endereco {
  rua?: string;
  endereco?: string;
  numero?: number;
  complemento?: string;
  bairro?: string;
  nomeCidade?: string;
  nomeEstado?: string;
  cep?: string;
  pais?: string;
}

export class CompaniesFilterEntity {
  nome: string = "";
  setor!: any;
  pais!: any;
  estado!: any;
  cidade!: any;
  localidade!: any;
}
export class HabilidadeEntity {
  id: number | undefined;
  name: string | undefined | null;
  cod: string | undefined | null;
  idioma: IdiomaEntity | undefined | null;
}

export class HabilidadeCandidatoEntity {
  id: number | undefined;
  candidatoId: number | undefined | null;
  habilidadeId: number | undefined | null;
  nivel: number | undefined | null;
  habilidade?: HabilidadeEntity;
}
export class VagasHabilidadesEntity {
  id: number | undefined;
  vagaId: number | undefined | null;
  habilidadeId: number | undefined | null;
  nivel: number = 0;
  name: string | undefined | null;
  habilidade?: HabilidadeEntity | null;
  applyOnProfile: boolean | null = false;
  // constructor(hab?: HabilidadeEntity, n?: number, vid?: number) {
  //   this.habilidade = hab || null;
  //   this.nivel = n || 1;
  //   this.habilidadeId = hab!.id || 0;
  //   this.vagaId = vid || 0;
  // }
}

export class MessageJobEntity {
  id?: number;
  status: string | undefined;
  assunto: string | undefined;
  mensagem: string | undefined;
  remetente: string | undefined;
  candidatoId: number | undefined;
  empresaId: number | undefined;
  paiId: number | undefined;
  empresa: EmpresaEntity | undefined;
  candidato: CandidatoEntity | undefined;
}

export class VagaPerguntaCorteEntity {
  id: number = 0;
  vaga: VagaEntity | null = null;
  pergunta: string = "";
  eliminatoria: number = 1;
}

export class CandidatoCorteEntity {
  pergunta: number | null;
  resposta: number | null;

  constructor(p: number | null = null, r: number | null = null) {
    this.pergunta = p;
    this.resposta = r;
  }
}
export class RoomEntity {
  id: number = 0;
  label: string = "";
  key: string = "";
  status: string = "";
  purpose: number = 0;
  supplier: number = 0;
  createdAt: string = "";
  createdAtDT: DateEntity | string | null = null;
  blind: boolean = false;
  owner!: any;
  participants!: any[];
  recordings!: any[];
  duration!: number | null;
  blockApprove: boolean = false;
}
export class RoomParticipantEntity {
  id: number = 0;
  key: string = "";
  coonectionId: string = "";
  approved: boolean = false;
  candidatoId: number | null = null;
  empresaId: number | null = null;
  recrutadorId: number | null = null;
  blind: boolean = false;
  owner: boolean = false;
  recordings!: any[];
}

export class ListVagas {
  a: VagaEntity[] = []; // todas as vagas
  p: VagaEntity[] = []; // vagas ativas
  np: VagaEntity[] = []; // vagas inativas
  f: VagaEntity[] = []; // vagas encerradas
}

export class JobApplicationFilter {
  vaid: number = 0;
  codeUrl?: string;
  s?: string;
  aa?: string;
  esc?: string;
  co?: string;
  es?: string;
  ci?: string;

  gamb?: string; //-- grp_afin_mov_black
  galg?: string; //-- grp_afin_lgbt
  gaci?: string; //-- grp_afin_comu_indi
  gari?: string; //-- grp_afin_refu_imig
  gapc?: string; //-- grp_afin_pcd
  gaef?: string; //-- grp_afin_empo_femi
  ga3i?: string; //-- grp_afin_3_idade

  idm?: string;
  page: number = 1;
  limit: number = 10;
  status: string[] | null = null;

  constructor(statuses: string[] | null, page: number) {
    this.status = statuses;
    this.page = page;
  }
}

export class CandidaturasViewModel {
  key: string = "";
  filter: JobApplicationFilter;
  candidaturas: CandidaturaEntity[] = [];
  vaga: VagaEntity | null = null;
  qtd: number = 0;

  constructor(key: string, statuses: string[]) {
    this.key = key;
    this.filter = new JobApplicationFilter(statuses, 1);
    this.candidaturas = [];
  }
}

export class FaqEntity {
  id: number = 0;
  titulo: string = '';
  descricao: string = '';
  lang: string = '';
  tipo: number = 0;
}

export class DashboardEntity {
  creditos: number = 0;
  candidaturas: number = 0;
  aprovados: number = 0;
  pendentes: number = 0;
  analise: number = 0;
  reprovados: number = 0;
  emregistro: number = 0;
  repescagem: number = 0;
  vagasAtivas: number = 0;
  vagasInativas: number = 0;
  vagasEncerradas: number = 0;
  vagasExcluidas: number = 0;
  tempoMedio: number = 0;
  seguidores: number = 0;
  // -- GENERO
  genMasc: string = '0';
  genFem: string = '0';
  genMT: string = '0';
  genHT: string = '0';
  genNB: string = '0';
  genOutros: string = '0';
  // -- PCD
  PCDVis: string = '0';
  PCDAud: string = '0';
  PCDFal: string = '0';
  PCDFis: string = '0';
  PCDInt: string = '0';
  // -- GRUPO PERTENCENTE
  grpBlack: string = '0';
  grpLgbt: string = '0';
  grpIndi: string = '0';
  grpRefu: string = '0';
  grpPcd: string = '0';
  grpFemi: string = '0';
  grp3Ida: string = '0';
  // -- RACA
  corBran: string = '0';
  corPre: string = '0';
  corPar: string = '0';
  corAma: string = '0';
  corInd: string = '0';
  corAsi: string = '0';
  corHis: string = '0';
  corOut: string = '0';
  label?: string;
}


export class AggDiversityEntity {
  age_between_18_24: number = 0;
  age_between_25_30: number = 0;
  age_between_31_40: number = 0;
  age_between_41_49: number = 0;
  age_bte_50: number = 0;
  age_lt_18: number = 0;
  gen_feminino: number = 0;
  gen_homem_trans: number = 0;
  gen_masculino: number = 0;
  gen_mulher_trans: number = 0;
  gen_nao_binario: number = 0;
  gen_outros: number = 0;
  grp_3idade: number = 0;
  grp_feminino: number = 0;
  grp_imigrante: number = 0;
  grp_indigina: number = 0;
  grp_lgbt: number = 0;
  grp_mov_black: number = 0;
  grp_pcd: number = 0;
  idempresa: number = 0;
  pcd: number = 0;
  pcd_auditiva: number = 0;
  pcd_fala: number = 0;
  pcd_fisica: number = 0;
  pcd_intelectual: number = 0;
  pcd_visual: number = 0;
  raca_amarelo: number = 0;
  raca_asiatico: number = 0;
  raca_branco: number = 0;
  raca_hispanico: number = 0;
  raca_indigena: number = 0;
  raca_outros: number = 0;
  raca_pardo: number = 0;
  raca_preto: number = 0;
  total_diversidade: number = 0;
  total_geral: number = 0;
}
export class DashboardMonthlyDataEntity {
  m: number | null = null;
  y: number | null = null;
  value: number | null = null;
}

export class CandidatosFilterObject {
  s: string | null = null;
  areaAtuacao: number | null = null;
  escolaridade: number | null = null;
  idioma: number | null = null;
  idiomaNivel: string | null = null;
  habilidade: number | null = null;
  habilidades: number[] | null = null;
  habilidadeNivel: number | null = null;
  localidade: string | null = null;
  limit: number = 8;
  page: number = 0;

  gamb?: string; //-- grp_afin_mov_black
  galg?: string; //-- grp_afin_lgbt
  gaci?: string; //-- grp_afin_comu_indi
  gari?: string; //-- grp_afin_refu_imig
  gapc?: string; //-- grp_afin_pcd
  gaef?: string; //-- grp_afin_empo_femi
  ga3i?: string; //-- grp_afin_3_idade

  normalized() {
    return {
      s: this.s||"",
      areaAtuacao: this.areaAtuacao||"",
      escolaridade: this.escolaridade||"",
      idioma: this.idioma||"",
      idiomaNivel: this.idiomaNivel||"",
      habilidade: this.habilidade||"",
      habilidades: this.habilidades||"",
      habilidadeNivel: this.habilidadeNivel||"",
      localidade: this.localidade||"",
      limit: this.limit||8,
      page: this.page||0,
    
      gamb: this.gamb||"",
      galg: this.galg||"",
      gaci: this.gaci||"",
      gari: this.gari||"",
      gapc: this.gapc||"",
      gaef: this.gaef||"",
      ga3i: this.ga3i||""
    }
  }
}

export class RepescagemEntity {
  id: number = 0;
  vagaId: number = 0;
  candidatoId: number = 0;
  candidato: CandidatoEntity | null = null;
  status: string = "";
  respostas: {
    resposta: number,
    pergunta: string,
    elimitatoria: string | number
  }[] | undefined;
  habilidades: {
    nivel: number,
    name: string,
    nivelVaga: number
  }[] | undefined;
}

export class SugestaoTagEntity {
  id: number = 0;
  descricao: string = "";
  tags: string[] = [];
}

export class AgendaEntrevistaEntity {
  id: number = 0;
  dataInicio?: DateEntity;
  dataFim: DateEntity | null = null;
  obs: string = "";
  status: string = "";
  vagaId: string = "";
  candidatoId: string = "";
  nomeCandidato: string = "";
  nomeEmpresa: string = "";
  posicao: string = "";
  roomId: number = 0;
  publicManagerLink: string = "";
  candidateLink: string = "";
  roomToken: string = "";
  registros: any[] = [];
}

export class PaginaInstitucionalEntity {
  id: number = 0;
  menu: string | null = null;
  titulo: string | null = null;
  corpo: string | null = null;
  habilitado: boolean = true;
  empresa: string | null = null;
  empresaId: number | null = null;
}


export class PluralPostEntity {
  id: number | null = null;
  tituloUrl: string | null = null;
  titulo: string | null = null;
  texto: string | null = null;
  arquivo: string | null = null;
  tipo: string | null = null;
  videoUrl: string | null = null;
  status: string | null = null;
}
export class ConviteEmpresaEntity {
  id: number = 0;
  nomeConvidado: string | null = null;
  emailConvidado: string | null = null;
  convidadoPor: string | null = null;
  sendedAt: DateEntity | null = null;
  acceptedAt: DateEntity | null = null;
  sended: boolean = false;
  error: boolean = false;
  sending: boolean = false;
}

export class ConviteVagaEntity {
  id: number = 0;
  email: string | null = null;
  invites: string[] = [];
  gestores: GrupoAvaliacaoGestorEntity[] = [];
  accepted: boolean = false;
  sended: boolean = false;
  error: boolean = false;
  sending: boolean = false;

  constructor() {
    //push the first
    this.invites.push("");
    this.gestores.push(new GrupoAvaliacaoGestorEntity())
  }

}

export class AvaliaProfissionalEntity {
  guid: string | null = null;
  nomeAvaliador: string | null = null;
  emailAvaliador: string | null = null;
  sessionId: string | null = null;
  status: string | null = null;
  nota: number | null = null;
  corpo: string | null = null;
  reUID: string | null = null;
  coUID: string | null = null;
  caUID: string | null = null;
  vagaURL: string | null = null;
  vagaPosicao: string | null = null;
  updatedAt: DateEntity | null = null;
}

export class MailTemplateEntity {
  id: number | null = null;
  titleTemplate: string | null = null;
  bodyTemplate: string | null = null;
  languageCode: string | null = null;
  configCode: string | null = null;
  code: string | null = null;
  cguid: string | null = null;
}

export class GrupoAvaliacaoGestorEntity {
  guid: string | null = null;
  nomeGestor: string | null = null;
  emailGestor: string | null = null;
  // only small portion of it
  accessCode: string | null = null;
}

export class GrupoAvaliacaoEntity {
  guid: string | null = null;
  title: string | null = null;
  description: string | null = null;
  canExpires: boolean = true;
  daysToExpire: number = 15;
  gestores: GrupoAvaliacaoGestorEntity[] = [];
  removedGestores: string[] = [];
  candidaturas: number[] = [];
  entrevista: any | null = null;
  removed: number[] = [];

  constructor() {
    this.canExpires = true;
    this.daysToExpire = 15;
  }
}


export class ReportFilters {
  code: number = 0;
  title: string | null = null;
  description: string | null = null;
  filters: any = null;
}

export class EquipeRecrutadoreEntity {
  id: number | null = null;
  rid: number | null = null;
  name: string | null = null;
  rids: number[] | null | undefined = [];
}

export class EquipeEntity {
  id: number | null = null;
  title: string | null = null;
  rids: number[] | null | undefined = [];
  recrutadores: EquipeRecrutadoreEntity[] = [];
}

export class EmpresaTrialAccount {
  nomeEmpresa: string | undefined;
  email: string | undefined;
  telefone: string | undefined;
  nomeResponsavel: string | undefined;
  lang: string | undefined;
  ebook: boolean | undefined;
}
