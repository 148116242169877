<template>
<b-form-group 
    :id="'input-group-' + scopeID" 
    :class="'date-form-group ' + variant"
    :label="label+(required?'*':'')" 
    :label-for="'input-' + scopeID">
    <datepicker :id="'input-' + scopeID"
        @input="handleInput($event)"
        @blur="handleBlur($event)"
        :value="value ? value : ''"
        :placeholder="placeholder"
        :required="required"
        :format="format" 
        :language="getDateLang"
        :disabled-dates="disabledDates"
        :input-class="'form-control '+variant"></datepicker>
</b-form-group>
</template>

<script lang="ts">
import Datepicker from "@hokify/vuejs-datepicker";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<DateInput>({
    components: {
        Datepicker
    },
})
export default class DateInput extends Vue {
    @Prop({default: ''}) public label!: string;
    @Prop({default: 'text'}) public type!: string;
    @Prop({default: ''}) public value!: string;
    @Prop({default: false}) public required!: boolean;
    @Prop({default: ''}) public placeholder!: string;
    @Prop({default: 'on'}) public autocomplete!: string;
    @Prop({default: ''}) public variant!: string;
    @Prop({default: null}) public state!: null|boolean|string;
    @Prop({default: ''}) public invalidFeedback!: string;
    @Prop({default: 'dd/MM/yyyy'}) public format!: string;
    @Prop({default: ''}) public minimumView!: string;
    @Prop({default: ''}) public maximumView!: string;
    @Prop({default: ''}) public initialView!: string;
    @Prop({default: () => { return {}}}) public disabledDates!: any;

    public scopeID: string;
    
    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput(v: any) {
        this.$emit('input', v);
        this.$emit('change', v);
    }

    public handleBlur(v: any) {
        this.$emit('input', v);
        this.$emit('blur', v);
    }

    public mounted() {
        const ele: any = document.querySelector('#input-' + this.scopeID );
        ele.removeAttribute('readOnly')
    }

    get getDateLang() {
        return this.$i18n.locale
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/_variables";

.date-form-group {
  input {
    border: none;
    border-radius: 0 !important;
    background: transparent;
    padding: 0;
    border-bottom: 1.5px solid $light-gray !important;
  }

  input:focus {
    box-shadow: none !important;
    background-color: transparent;
    border-bottom: 1.5px solid white !important;
  }
  input:valid {
    border-bottom: 1.5px solid white !important;
  }

  .form-group label,
  .form-group:valid label {
    color: white !important;
  }

  .form-control::placeholder {
    color: rgba(140,140,140,0.65) !important;
  }

  input.dark {
    border-bottom-color: $light-gray !important;
    color: $blue;
  }
  input.dark:focus {
    color: $blue;
    border-bottom-color: $blue !important;
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .form-control.is-invalid:focus {
    border-bottom-color: #dc3545 !important;
  }
}
  .date-form-group label {
    font-size: 1em;
    padding: 0;
    margin: 0;
    color: $light-gray;
  }
  .date-form-group:valid label {
    color: white;
  }

  .date-form-group.dark label {
    color: #000;
  }
  .date-form-group.dark:valid label {
    color: #000;
  }
  .date-form-group.dark:invalid label {
    color: red;
  }
</style>