<template>
  <div class="container-main">
    <div class="content-principal">
      <div class="left-image">
        <div class="img" alt="login_image">
          <h1>{{ $t('Try a new way to hire') }}</h1>
        </div>
      </div>
      <div class="right-form">
        <div class="box-login">
          <!-- Tab de Empresas -->
          <!-- <b-alert v-model="showDismissibleAlert" :variant="alertVariant" dismissible>
            <div v-html="serverMessage"></div>
          </b-alert> -->
          <div class="titles">
            <b>
              {{ $t('Enter the authentication code') }}
            </b>
          </div>
          <form>
            <div>
              <text-input
                class="text-uppercase"
                variant="dark"
                :label="$t('Authentication Code')"
                :required="true"
                :type="'text'"
                v-model="secFactorCode"
              />
            </div>
            
            <div class="buttons">
              <button class="btn btn-info entrar" @click.prevent="continueAuth" v-promise-btn="{promise:currentPromise}">{{$t('Authenticate')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import AccountConfirmation from '@/core/Services/Empresa/AccountCofirmation';
import { siteLandingEndPoint } from "@/core/endpoint";
import { Component, Vue } from 'vue-property-decorator';
import { Action } from "vuex-class";

const namespace: string = "profile";
let PIDINTERVAL: any = 0;

@Component
export default class SecondFactor extends Vue {
  public currentPromise: any | null = null;
  public service: AccountConfirmation;
  public secFactorCode: string = '';
  public token: any = '';
  public landingURL: string = siteLandingEndPoint;

  
  @Action("companyRequest", { namespace }) public companyRequest: any;
  public contentText: string = "";

  constructor() {
    super()
    this.service = new AccountConfirmation();
  }

  public created() {
    this.token = this.$route.query.tkn
  }
  public removeItensFromStorage() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public mounted() {
    this.removeItensFromStorage()
  }

  public continueAuth() {
    const dt: any = {
      token: this.token,
      sfCode: this.secFactorCode
    }
    this.currentPromise = this.service.update(dt)
    .then((d: any) => {
      if (d.accessToken) {
        sessionStorage.setItem("access_token",d.accessToken + "");
        let profile_type = d.profile_type || "company";
        sessionStorage.setItem("token_type", "Bearer");
        sessionStorage.setItem("profile_type", ""+profile_type);
        this.companyRequest()
          .then(() => {
            let rurl: any =
              this.$route.query.redirectTo ||
              sessionStorage.getItem("redirectTo") ||
              "/";
            setTimeout(() => {
              this.$router.push({
                path: rurl,
                query: { lang: this.$i18n.locale }
              });
            }, 500);
          })
          .catch(() => {
            this.removeItensFromStorage();
            this.errorAlert();
          });
      }
    })
  }

  public successAlert() {
    this.$alert.addMsg("" + this.$t("Successful authentication"));
    this.$alert.callBoxCheck(this.$t("OK").toString());
  }

  public errorAlert() {
    this.$alert.addMsg(this.$t('Authentication failure') )
    this.$alert.callBoxWarn()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/main.scss";
@import "@/assets/scss/_variables";

.container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa !important;
  font-family: nerislight;
  .content-principal {
    display: flex;
    margin: 0;
    max-width: 100%;
    height: calc(100vh - 8em);
    width: calc(100% - 15em);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    .left-image {
      display: flex;
      width: 55%;
      .img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-image: url("https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/svg/login_empresa.svg");
        h1 {
          font-size: 3em;
          color: white;
          text-align: center;
          font-weight: bold;
          margin: 0.5em 1em;
        }
      }
    }
    .right-form {
      width: 45%;
      background-color: #f3f3f3;
      padding: 2em;
      height: 100%;
      .box-login {
        // font-size: .8em;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 1.2em 1.7em;
        justify-content: center;
        form {
          font-size: 1.3em;
          label {
            font-size: 0.65em;
            margin: 0;
          }
        }
        .titles {
          text-align: center;
          font-size: 1.5em;
          margin: 1.5em;
          p,
          b {
            margin-bottom: 0;
            line-height: 1;
            font-size: 1.5em;
          }
          b span {
            color: $blue;
          }
        }
        .buttons {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          text-align: center;
          a,
          span {
            margin: 0.5rem 0;
          }
          .btn {
            height: 2.5em;
            font-size: 1.25em;
            margin: 0.1em 4em;
            &.entrar {
              background-color: $green-light !important;
              border-color: $green-light !important;
            }
            i {
              font-size: 1.5em;
              position: absolute;
              left: 4em;
            }
          }
        }
        .info-captcha {
          p {
            text-align: right;
          }
        }
      }
    }
  }
}
@include media(">desktop") {
  .container-main {
    font-size: 0.8em;
  }
  .info-captcha {
    p {
      font-size: 0.7em;
    }
  }
}
@include media("<desktop") {
  .container-main {
    .content-principal {
      height: 100%;
      width: 100%;
      .left-image {
        display: none !important;
      }
      .right-form {
        width: 100%;
        padding: 0em;
        .box-login {
          padding: 1em;
          form {
            font-size: 0.7em;
          }
          .buttons {
            .btn {
              height: 3em;
              font-size: 1.5em;
              margin: 0;
              margin-bottom: 0.3em;
              font-weight: bold;
              i {
                left: 2em;
              }
            }
            a.esqueci-senha,
            span {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
}
</style>