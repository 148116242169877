<template>
  
    <div class="container mt-4 recruiter position-relative m-h-400" ref="containerLoader">
      <div class="row">
        <div class="col-md-6">
          <h5 class="m-0"><strong>{{ registredRecruiters }} / {{ numberOfRecruiters }}</strong> {{$t('Recruiters')}} </h5>
          <b-progress :max="numberOfRecruiters" height="2rem" show-value :variant="currentVariant">
            <b-progress-bar  :value="registredRecruiters">
            </b-progress-bar>
          </b-progress>
        </div>
        <div class="col-md-6 d-flex justify-content-end align-items-end">
          <b-button variant="blue" pill
          to="/recruiters/new"
          >
            <i class="fa fa-plus mr-2"></i>
            {{$t("New recruiting person")}}
          </b-button>
        </div>
      </div>
      <div class="container mt-4 text-center recruiter__no-recruiter" v-if="!recrutadores && !loadingEvent">
          <img src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/recruit-img.png" alt="">
          <div>
            <p>{{$t("No recruiter registered.")}}</p>
          </div>
      </div>
      <div class="row mt-4 " v-if="recrutadores && !loadingEvent">
        <transition name="fade">
          <div class="col-md-12 list-cards d-flex flex-wrap align-items-between justify-content-between">
            <card-recrutador v-for="(recrutador,i) in recrutadores" :recrutador="recrutador" v-bind:key="recrutador.id" @removed="remove(i)" />
          </div>
        </transition>
      </div>
    </div>
</template>

<script lang="ts">
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';
import { EmpresaEntity, RecrutadorEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import CardRecrutador from './CardRecrutador.vue';

@Component<Recrutadores>({
  components:{
    CardRecrutador
  }
})
export default class Recrutadores extends Vue {
  public service: GestaoRecrutador;
  public recrutadores: RecrutadorEntity[]|null = null;
  public numberOfRecruiters: number = 0;
  public registredRecruiters: number = 0;
  public currentVariant: string = 'info';

  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  constructor() {
    super();
    this.service = new GestaoRecrutador(); 
  }

  public mounted() {
    
    const _: any = this;
    _.openLoading(this.$refs.containerLoader);
    this.service.fetchAll().then((data: any) => {
      this.recrutadores = data['recrutadores'];     
    }).catch((err) => { 
      console.log(err) 
    }).finally(()=> {
      _.closeLoading();
      this.numberOfRecruiters = (this.empresa!.configuracoes!['RECRUITERS_NUMBER'] as number)||0;
      this.registredRecruiters = this.recrutadores!.length;
      this.changeVariant()
    })
  }

  public remove(index: number) {
    this.recrutadores!.splice(index,1);
  }
  public changeVariant() {
    this.currentVariant = 'success';
    if (this.numberOfRecruiters > 0 && this.registredRecruiters >0) {
      const v: number = this.registredRecruiters/this.numberOfRecruiters;
      if ( v > 0.6 && v < 0.8) {
        this.currentVariant = 'warning'
      } else if ( v > 0.8 ) {
        this.currentVariant = 'danger'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
.recruiter {
  &__no-recruiter {
      min-height: 200px;
    img {
      width: 600px;
    }
    p {
      font-size: 24px;
      color: cornflowerblue;
    }
  }
}


@include media("<desktop") {
  .recruiter {
    &__top {
      button {
        width: 100%
      }
    }
    &__no-recruiter {
      min-height: 200px;
      img {
        width: 100%;
      }
      p {
        font-size: 20px;
        color: cornflowerblue;
      }

    }
  }
}

</style>