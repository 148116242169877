<template>
  <b-form @submit.prevent="submitForm">
    <div class="container-fluid not-gutters">

      <b-row class="pt-4">
        <b-col md="4">
          <text-input 
            variant="dark"
            v-model="filtros.s" 
            placeholder="..."
            :label="$t('General Search')"
          />
        </b-col>
        <b-col md="4">
          <select-input
            :optionList="areaAtuacaoSRV.optionList"
            variant="dark"
            :label="$t('Field of Work')"
            v-model="filtros.areaAtuacao"
          />
        </b-col>
        <b-col md="4">
          <select-input
            :optionList="escolaridadeSRV.optionList"
            variant="dark"
            :label="$t('Education')"
            v-model="filtros.escolaridade"
          />
        </b-col>
        <b-col md="4">
          <select-input
            :optionList="idiomaSRV.optionFullList"
            variant="dark"
            :label="$t('Languages')"
            v-model="filtros.idioma"
          />
        </b-col>
        <b-col md="4">
          <select-input
            :optionList="idiomaSRV.optionNivelList"
            variant="dark"
            :label="$t('Language Level')"
            v-model="filtros.idiomaNivel"
          />
        </b-col>
        
        <b-col md="4">
          <text-input 
            variant="dark"
            v-model="filtros.localidade" 
            placeholder="..."
            :label="$t('Location')"
          />
        </b-col>

        <div class="col-md-12">
          <b-form-group :label="$t('Filtering by belonging group')">
              <b-row class="px-2">
                <b-col md="2">                  
                </b-col>

                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoLgbt"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                  >
                  </checkbox-input>
                  <img class="image" id="lgbt" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_lgbt%2B.png'" />
                  <b-tooltip target="lgbt" placement="bottom">
                    LGBTQIA+
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoIndiginas"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                  >
                  </checkbox-input>
                  <img class="image" id="indigena" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_comunidade_indigena.png'" />
                  <b-tooltip target="indigena" placement="bottom">
                    {{$t('Indigenous Community')}}
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoRefugiados"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                    :label="$t('')"
                  >
                  </checkbox-input>
                  <img class="image" id="imigrante" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_refugiados_imigrantes.png'" />
                  <b-tooltip target="imigrante" placement="bottom">
                    {{$t('Refugee and Immigrants')}}
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoMovBlack"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                    :label="$t('')"
                  >
                  </checkbox-input>
                  <img class="image" id="black" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_movimento_black.png'" />
                  <b-tooltip target="black" placement="bottom">
                    {{$t('Afro-descendants Community')}}
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoEmpoderaFeminino"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                    :label="$t('')"
                  >
                  </checkbox-input>
                  <img class="image" id="feminino" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_empoderamento_feminino.png'" />
                  <b-tooltip target="feminino" placement="bottom">
                    {{$t('Womens Movement')}}
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupoPCD"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                    :label="$t('')"
                  >
                  </checkbox-input>
                  <img class="image" id="pcd" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_pcd.png'" />
                  <b-tooltip target="pcd" placement="bottom">
                    {{$t('PWD Group')}}
                  </b-tooltip>
                </b-col>
                <b-col md="1" style="text-align: center">
                  <checkbox-input
                    class="checkbox-de"
                    v-model="grupo3Idade"
                    :accepted="true"
                    :not_accepted="false"
                    :switch_type="true"
                    :input_size="'lg'"
                    :label="$t('')"
                  >
                  </checkbox-input>
                  <img class="image" id="3idade" :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/bandeiras/bandeira_3idade.png'" />
                  <b-tooltip target="3idade" placement="bottom">
                    {{$t('Senior citizen group')}}
                  </b-tooltip>
                </b-col>
              </b-row>
  
              
          </b-form-group>
        </div>    





        <b-col md="6" class="mx-auto my-2">
          <b-button 
            class="filter-button w-100"
            type="submit"
            variant="outline-primary"
            v-promise-btn="{promise:currentPromise}"
          >
           <i class="fa fa-search"></i> {{$t("Filter")}}
          </b-button>
        </b-col>
      </b-row>






    </div>
  </b-form>
</template>

<script lang="ts">
import AreaAtuacaoService from "@/core/Services/AreaAtuacao";
import IdiomaService from "@/core/Services/Basic/Idioma";
import CandidatoService from "@/core/Services/Candidato/Candidato";
import HabilidadeService from "@/core/Services/Habilidade";
import NivelEscolaridadeService from "@/core/Services/NivelEscolaridade";
import { CandidatoEntity, CandidatosFilterObject, HabilidadeEntity } from '@/core/models/Entities';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
    'auto-complete': Autocomplete
  },
})
export default class CandidatoFilterForm extends Vue {
  public candidatos: CandidatoEntity[] = [];
  public candidatoSRV: CandidatoService;
  public areaAtuacaoSRV: AreaAtuacaoService;
  public escolaridadeSRV: NivelEscolaridadeService;
  public idiomaSRV: IdiomaService;
  public habilidadeSRV: HabilidadeService
  public areaAtuacaoList: Array<any> = [];
  public escolaridadeList: Array<any> = [];
  public idiomaList: Array<any> = [];
  public habilidadeList: Array<any> = [];
  public timeout: any;
  public skillArr: any[] = []
  public habNivel: any[] = []
  public hashHabilidades: Map<number,number>|null = null;
  public loading: boolean = true;
  public list: any[]|null = null;
  public currentPromise: any|null = null;
  public currentPage: number = 1;
  public page: number = 0;
  public limit!: number;
  public totalCount: number = 0;
  public habilidades: HabilidadeEntity[] = [];
  public habilidadeSelecionada!: HabilidadeEntity|null;
  public buscaHabilidades: string = '';
  public habID: string = ''

  
  public grupoPCD: boolean = false;
  public grupoEmpoderaFeminino: boolean = false;
  public grupoMovBlack: boolean = false;
  public grupoRefugiados: boolean = false;
  public grupoIndiginas: boolean = false;
  public grupoLgbt: boolean = false;
  public grupo3Idade: boolean = false;

  public filtros: any = {
    s: '',
    areaAtuacao: null,
    escolaridade: null,
    idioma: null,
    idiomaNivel: '',
    habilidade: {name:'', cod:null},
    habilidadeNivel: null,
    localidade: ''
  };

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.areaAtuacaoSRV = new AreaAtuacaoService();
    this.escolaridadeSRV = new NivelEscolaridadeService();
    this.idiomaSRV = new IdiomaService();
    this.habilidadeSRV = new HabilidadeService();
    this.idiomaSRV.loadOptions();
    this.areaAtuacaoSRV.loadOptions()
    this.escolaridadeSRV.loadOptions()
    this.candidatos = [];
    this.habilidades = []
  }

  public searchHabilities(v1: string) {
    if (v1.length < 1) { return [] }
     return new Promise((resolve: (v: any[]) => void) => {
       clearTimeout(this.timeout);

       if(!v1)
        return;
        
        this.timeout = setTimeout(async (currentValue: string) => {
          this.currentPromise = this.habilidadeSRV.fetchAll({s: currentValue}).then((data) => {
            this.updateHabilidadesList(data.habilidades)
            if(data && data.habilidades[0])
              this.habID = data.habilidades[0].cod;
            const arrFilter: any[] = this.habilidades.filter((hb: HabilidadeEntity) => {  
                if(hb && hb.name)
                  return (hb.name.toLowerCase().indexOf(currentValue.toLowerCase()) > -1) 

                return false;
              })
            resolve(arrFilter)
          })
        }, 500, v1);
      })
  }

  public mounted() {
    this.habNivel = [1, 2, 3, 4, 5]
    
    this.grupoPCD = false;
    this.grupoEmpoderaFeminino = false;
    this.grupoMovBlack = false;
    this.grupoRefugiados = false;
    this.grupoIndiginas = false;
    this.grupoLgbt = false;
    this.grupo3Idade = false;
  }

  public updateHabilidadesList(habs: any[]) {
    if (this.hashHabilidades == null) {
        this.hashHabilidades = new Map<number,number>();
    }
    habs.forEach((value: any,index: number) => {
      if (!this.hashHabilidades!.has(value.id!)) {
        this.habilidades.push(value);
        this.hashHabilidades!.set(value.id!,index);
      }
    })
  }

  public submitForm() {



    if (this.habID == '1')
      this.habID = '';
    let filter: CandidatosFilterObject = Object.assign(new CandidatosFilterObject(),{
      s: this.filtros.s,
      areaAtuacao: this.filtros.areaAtuacao,
      escolaridade: this.filtros.escolaridade,
      idioma: this.filtros.idioma,
      idiomaNivel: this.filtros.idiomaNivel,
      habilidade: this.habID,
      habilidadeNivel: this.filtros.habilidadeNivel,
      localidade: this.filtros.localidade,
      gamb: this.grupoMovBlack?'1':'', //-- grp_afin_mov_black
      galg: this.grupoLgbt?'1':'', //-- grp_afin_lgbt
      gaci: this.grupoIndiginas?'1':'', //-- grp_afin_comu_indi
      gari: this.grupoRefugiados?'1':'', //-- grp_afin_refu_imig
      gapc: this.grupoPCD?'1':'', //-- grp_afin_pcd
      gaef: this.grupoEmpoderaFeminino?'1':'', //-- grp_afin_empo_femi
      ga3i: this.grupo3Idade?'1':'', //-- grp_afin_3_idade
    });
    
    this.$emit('submitFilter', filter)
    return false;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

  .form-auto-complete {
    label {
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
    }
    input {
      border: none;
      border-radius: 0 !important;
      background: transparent;
      padding: 0;

      color: white;
      border-bottom: 1.5px solid $light-gray !important;
    }

    input:focus {
      box-shadow: none !important;
      background-color: transparent;

      color: white;
      border-bottom: 1.5px solid white !important;
    }
    // input:valid {
    //   border-bottom: 1.5px solid white !important;
    // }

    .form-group .col-form-label,
    .form-group:valid .col-form-label {
      color: white !important;
    }

    .form-control::placeholder {
      color: rgba(140,140,140,0.65) !important;
    }

    input {
      border-bottom-color: $light-gray !important;
      color: $blue;
    }
    input:focus {
      color: $blue;
      border-bottom-color: $blue !important;
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .form-control.is-invalid:focus {
      border-bottom-color: #dc3545 !important;
    }
  }

</style>