<template>
  <b-sidebar
      :id="id"
      :aria-controls="id"
      :aria-expanded="value?'true':'false'"
      :title="$t('Evaluation List')"
      width="400px"
      shadow
      v-model="value"
      @shown="shown"
      @hidden="hidden"
      lazy
      left 
    >
    <b-row>
      <b-col md="12" class="text-right mb-3">
        <b-button :variant="!createNew ? 'primary' : 'danger'" @click="toggleCreateNew()" class="px-3 mx-3 ">
            {{ createNew ? $t('Cancel') : $t('Create a new list')}}
        </b-button>
      </b-col>
      <b-col md="12">
        <div>
          <lista-grupo-avaliacao class="border" v-if="!createNew" :vaga="vaga" @selectedApplication="selectedApplication"> </lista-grupo-avaliacao>
          <form-grupo-avaliacao v-if="createNew" :candidaturas="candidaturas" :vaga="vaga" @saved="saved"> </form-grupo-avaliacao>
        </div>
      </b-col>
    </b-row>
  </b-sidebar>
</template>

<script lang="ts">
import { CandidatoEntity, CandidaturaEntity, VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormGrupoAvaliacao from '../partials/FormGrupoAvaliacao.vue';
import ListaGrupoAvaliacao from '../partials/ListaGrupoAvaliacao.vue';

@Component({
  components:{
    ListaGrupoAvaliacao,
    FormGrupoAvaliacao
  }
})
export default class SidebarGrupoAvaliacao extends Vue {

  @Prop({default: null})
  public id!: string;

  @Prop({default: false})
  public value!: boolean;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
  @Prop({ default: null })
  public candidaturas!: CandidaturaEntity[];

  public createNew: boolean = false;

  public mounted() {
    this.createNew = false;
  }

  public saved() {
    this.$emit('done')
    this.$emit('input',false)
  }

  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }

  public selectedApplication(cid: any) {
    this.$emit('selectedApplication',cid)
  }
  public toggleCreateNew() {
    this.createNew = !this.createNew
  }
  
}
</script>
