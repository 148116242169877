<template>
  <transition appear name="fade">
    <b-row> 
      <b-col md="1" class="text-center">
        <span v-if="value.emailConvidado && value.nomeConvidado">
          <i class="fa fa-angle-right"></i>
        </span>
      </b-col>
      <b-col md="5">
        <text-input
          variant="white"
          v-model="value.nomeConvidado"
          @input="handleInput($event)"
          @blur="handleBlur($event)"
        />        
      </b-col>
      <b-col md="5">
        <text-input
          variant="white"
          v-model="value.emailConvidado"
          @input="handleInput($event)"
          @blur="handleBlur($event)"
        />      
      </b-col>
      <b-col md="1" class="text-center">
        <span v-if="value.emailConvidado && value.nomeConvidado">
          
          <b-spinner variant="primary" small  label="Sending" v-if="value.sending && !value.error && !value.sended"></b-spinner>
          <i :class="{
            'fa': true, 
            'fa-2x': true, 
            'fa-minus-circle text-white': !value.sended && !value.error,
            'fa-check-circle text-white': value.sended && !value.error,
            'fa-times-circle text-warning': value.error
             }"></i>
        </span>
      </b-col>
    </b-row>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConviteEmpresaEntity } from '@/core/models/Entities';

@Component
export default class NewInvite extends Vue {
  @Prop({ default: () => { return new ConviteEmpresaEntity() }})
  public value!: ConviteEmpresaEntity;
  
  public handleInput(v: any) {
    this.$emit('input', this.value);
  }

  public handleBlur(v: any) {
    this.$emit('blur', this.value);
  }
}
</script>

<style>

</style>