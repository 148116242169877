<template>
  <transition appear name="slide">
  <b-row class="mb-1 mt-3">
    <b-col md="6">
      <h2> {{$t('Teams')}} </h2>
      <b-form-group 
        label-for="teamsSelect"
        class="vaga-select-list">
        <multi-select 
          id="teamsSelect"
          class="multiselect-dark-theme"
          @input="handleChange"
          v-model="selectedTeams" 
          :options="teamsOptions" 
          :multiple="true"
          label="title" 
          track-by="title"
          :show-labels="false"
          :placeholder="$t('Select')">
            <template slot="option" slot-scope="props">
              <div class="option__desc">{{ $t(props.option.title) }}</div>
            </template>
        </multi-select>
      </b-form-group>
    </b-col>
    <b-col md="6" v-if="equipesVaga && equipesVaga.length > 0"  >
      <h2> {{$t('Current team')}} </h2>
      <div class="justify-content-start d-flex align-items-center flex-wrap">
          <span class="mr-1 bg-white px-3 py-2 border border-light cursor-pointer rounded-pill shadow-sm shadow-sm-hover" 
            v-for="(h,i) in equipesVaga" :key="i"> 
            {{h.title}} 
            <b-button size="sm" variant="outline-danger" class="ml-1" pill @click="deleteEquipe(h.id)">
              <i class="fa fa-times"></i>
            </b-button>
          </span>  
      </div>
    </b-col>
  </b-row>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import Multiselect from 'vue-multiselect';
import Equipe from "@/core/Services/Equipes/Equipe";
import EquipeVaga from "@/core/Services/Equipes/EquipeVaga";


@Component({
  components: {
    'multi-select': Multiselect,
  }
})
export default class FormVagaEquipes extends Vue {
  public serviceTeam: Equipe;
  public serviceTeamJob: EquipeVaga;
  public teamsOptions: any = []; 
  public selectedTeams: any = []; 
  
  @Prop({ default: [] })
  public value!: any[];
  
  @Prop({ default: [] })
  public equipesVaga!: any[];

  constructor() {
    super();
    this.serviceTeam = new Equipe();
    this.serviceTeamJob = new EquipeVaga();
  }

  public loadTeams() {
    this.serviceTeam.fetchAll().then((data) => {
      this.teamsOptions = data['results'];
    })
  }

  public mounted() {
    this.loadTeams()
  }

  public handleChange() {
    this.$emit('input',this.selectedTeams)
  }
  
  public deleteEquipe(id: number) {
    const _: any = this;
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.cancelTitle = 'No';
    this.$confirm.oktitle = 'Yes';
    this.$confirm.addMsg(this.$t('Are you sure you want to remove this team?'))
    this.$confirm.callBoxDanger().then((v:boolean) => { 
      if(v) {
        _.openLoading(this.$refs.containerLoader);
        this.serviceTeamJob.delete({id}).then(() => {
          this.$toast.variant = 'success';
          this.$toast.addMsg(this.$t('Deleted Successfully'))
          this.$toast.addTitle(this.$t('-').toString());
          this.$toast.open();
          const index: number|undefined = this.equipesVaga?.findIndex(_ => { return _.id == id})
          console.log('index',index)
          if(index >= 0)
            this.equipesVaga?.splice(index,1)

          this.$emit('removed',this.equipesVaga)
        }).catch(() => {
          this.$toast.variant = 'danger';
          this.$toast.addMsg(this.$t('Cannot delete because it is in use'))
          this.$toast.addTitle(this.$t('Oops').toString());
          this.$toast.open();
        }).finally(() => {
          _.closeLoading();
        })
      }
    });  
  }
}
</script>