<template>
  <b-navbar id="liveRoomTopNavBar" type="dark" variant="dark">
    <b-navbar-toggle target="nav-live-room-top-bar-collapse"></b-navbar-toggle>

    <b-navbar-brand>
      <logo :inverse="true" :small="true"/>
    </b-navbar-brand>

    <b-collapse id="nav-live-room-top-bar-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-text>          
          <b-button 
            @click="showManual1Visible" 
            variant="info"
            v-b-tooltip.hover
            :title="$t('About the Interview Room')"   >
            <i class="fa fa-info"></i>
          </b-button>
        </b-nav-text>
        <b-nav-text>          
          <b-button :href="'/?lang='+$i18n.locale" target="_blank"  
            class="text-white"
              v-b-tooltip.hover
              :title="$t('Company Dashboard')"   >
            <i class="fa fa-home"></i>
          </b-button>
        </b-nav-text>
        <b-nav-text>
          <b-button 
             @click="goHelp()" 
              class="text-white"
              v-b-tooltip.hover
              :title="$t('Support')"   >
              <i class="fa fa-question-circle"></i>         
          </b-button>
        </b-nav-text>
        
        <!-- CONFIGURAÇÕES DE IMAGEM DE FUNDO -->
        <b-nav-text>          
          <b-button 
            @click="toggleBackgroundSidebar" 
            variant="info"
            v-b-tooltip.hover
            v-if="enableCustomBack"
            :title="$t('Configuring background image')"   >
            <i class="fas fa-magic"></i>
          </b-button>
        </b-nav-text>

        <!-- CONFIGURAÇÕES DE CAMERA -->
        <b-nav-text>
          <b-button class="text-white" id="pr-config-devices" ref="button" 
              v-b-tooltip.hover
              :title="$t('Device Settings')">
            <i class="fa fa-cog"></i>
          </b-button>
          <b-popover
            :show.sync="showPopoverConfig"
            target="pr-config-devices"
            triggers="click"
            placement="bottom"
            container="liveRoomTopNavBar"
            ref="popover"
            @show="onShow"
            @shown="onShown"
            @hidden="onHidden"

          >
            <template v-slot:title>
              <b-button  @click="onClose"  class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              {{$t('Device Settings')}}
            </template>
          <div >
            <!-- SELECT DO DEVICE DE VIDEO -->
            <b-form-group :label="$t('Select your camera')" label-for="video-device-select" >
              <b-form-select id="video-device-select" v-model="currentVideoDeviceId" >
                <b-form-select-option :value="vDevice.deviceId" v-for="(vDevice,index) in videoDevices" :key="index">
                  {{vDevice.label}}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <!-- SELECT DO DEVICE DE AUDIO -->
            <b-form-group :label="$t('Select your microphone')" label-for="audio-device-select" >
              <b-form-select id="audio-device-select" v-model="currentAudioDeviceId" >
                <b-form-select-option :value="vDevice.deviceId" v-for="(vDevice,index) in audioDevices" :key="index">
                  {{vDevice.label}}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          
          <div class="mt-1">
            <b-button class="px-1" variant="primary" @click="doCallToggleDevices()">
              {{$t('Save')}}
            </b-button>
          </div>
        </b-popover>
      </b-nav-text>
      <b-nav-item-dropdown right>
        <template v-slot:button-content>
          <b-button class="text-white" v-b-tooltip.hover :title="$t('Participants') + '('+subscribers.length+')'">
            <i class="fa fa-user" /> 
          </b-button>
        </template>
        
        <b-dropdown-item v-for="(sub,i) in subscribersList" :key="i"  >
          <b-button size="sm" 
            v-b-tooltip.hover
            :title="sub.userType == 'candidate' ? $t('Person') : $t('Management')" 
            variant="light">
            <i :class="{'fa': true,  'fa-restroom': sub.userType == 'candidate', 'fa-user-friends': sub.userType != 'candidate' }" />
          </b-button>
          <span class="text-black"  v-if="sub.userType == 'candidate' && sub.blinded">{{sub.nickname}}</span>
          <span class="text-black"  v-else>{{sub.nickname}}</span>
          
          <b-button size="sm" variant="light" class="mx-1" style="width:32px;">
           <i class="fa fa-volume-up text-primary" v-if="sub.speaking" />
           <i class="fa fa-volume-off text-secondary" v-else />
          </b-button>

          <b-button variant="primary"
            v-b-tooltip.hover
            :title="$t('Reveal Talent')"
             size="sm" class="mx-1" pill @click="showSubscriber(sub)" v-if="canApprove && sub.userType == 'candidate' && sub.blinded">
            <i class="fa fa-eye" />
          </b-button>

          <b-button variant="info" size="sm" pill class="mx-1 text-uppercase border-white"  
            v-if="sub.userType == 'candidate' && sub.userGuid"
            @click="openHiddenProfile(sub.userGuid,sub.nickname)"
            v-b-tooltip.hover
            :title="$t('Anonymous Resume')"
            >
            <i class="fa fa-user-secret"></i>
          </b-button>
                    
          <b-button variant="outline-green" size="sm" class=" mx-1" pill @click="showEvalModal(sub)" v-if="sub.userType == 'candidate' && !!sub.userGuid"
            v-b-tooltip.hover
            :title="$t('Evaluate Talent')">
            <i class="fa fa-tasks" />
          </b-button>

          <b-button variant="outline-danger" size="sm" class="mx-1" pill  @click="muteSubscriber(sub)" v-if="sub.isAudioActive()" 
            v-b-tooltip.hover
            :title="$t('Mute/Unmute Mic')">
            <i class="fa fa-microphone-slash" />
          </b-button>
<!--           
          <b-dropdown dropup no-caret variant="light" size="sm" class="float-right mr-1 rounded-pill" right v-if="sub.userType == 'candidate' && sub.blinded ">
            <template v-slot:button-content>
            <i class="fa fa-filter"></i>
            </template>
            <b-dropdown-item v-for="i in [1,2,3,4]" :key="'a'+i"  @click="changeSubscriberAudoFilter(sub, i)"> {{$t('Filtro de Audio')}} ({{i}}) </b-dropdown-item>             
          </b-dropdown> -->

          <b-button 
            v-b-tooltip.hover
            variant="danger" pill
            :title="$t('Remove participant')"
            class="mr-1" size="sm" @click="removeParticipant(sub)">
            <i class="fas fa-times-circle"></i>
          </b-button>

        </b-dropdown-item>
      </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>


<script lang="ts">
import ModalCreateAvaliacao from '@/components/avaliacao/modal/ModalCreateAvaliacao.vue';
import Logo from '@/components/logo/Logo.vue';
import { siteCandidateEndPoint } from "@/core/endpoint";
import { CandidatoEntity } from '@/core/models/Entities';
import UserModel from '@/core/recording/UserModel';
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({
  components: {
    ModalCreateAvaliacao,
    Logo
  }
})
export default class TopNavBar extends Vue {

  public show: boolean = false;
    
  @Prop({default: () => { return [] }})
  public subscribers!: UserModel[];

  @Prop({default: null})
  public canApprove!: boolean;

  @Prop({default: false })
  public blindEnabled!: boolean;

  @Prop({default: '' })
  public currentRAToken!: string;

  public selectedCandidato: CandidatoEntity|null = null;
  
  public recordingPromise: Promise<any>|null = null;

  @Prop({default: () => {return new UserModel()}})
  public value!: UserModel;

  @Prop({default: false })
  public showNotification!: boolean;

  @Prop({default: false })
  public enableCustomBack!: boolean;

  @Prop({default: false })
  private isAuthenticatedUser!: boolean;

  @Prop({default: [] })
  public audioDevices!: any[];

  @Prop({default: [] })
  public videoDevices!: any[];

  @Prop({default: "" })
  private sessionId!: string;

  @Prop({default: false })
  public fullscreen!: boolean;

  @Prop({default: false })
  public sharing!: boolean;

  @Prop({default: false })
  private recordingEnabled!: boolean;

  @Prop({default: false })
  public permitRecord!: boolean;
  
  get subscribersList() {
    return this.subscribers;
  }

  get isProductionEnv() {
     return (process.env.NODE_ENV == 'production');
  }

  get isAuthenticated() {
    return this.isAuthenticatedUser;
  }

  get isSharing() {
    return this.sharing;
  }

  get micIcon() {
    return this.value.isAudioActive() ? 'fa fa-microphone' : 'fa fa-microphone-slash';
  }

  get videoIcon() {
    return this.value.isVideoActive() ? 'fa fa-video' : 'fa fa-video-slash';
  }
  
  get fullScreenIcon() {
    return this.fullscreen ? 'fa fa-compress' : 'fa fa-expand';
  }

  get screenIcon() {
    return this.value.isScreenShareActive() ? 'fa fa-window-close' : 'fa fa-window-restore';
  }

  get candidateLink() {
    return this.URLcandidato + "/sala-entrevista/" + this.$route.params.sid;
  }
  get managerLink() {
    return this.URL + "/emp/sala-entrevista/" + this.$route.params.sid +"?b="+this.blindEnabled;
  }
  
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public recName: string = '';
  public showPopover: boolean = false;
  public showPopoveRec: boolean = false;
  public showPopoverConfig: boolean = false;
  public pitch: number = 1;
  public rate: number = 1;
  public tempo: number = 1;

  private currentAudioDeviceId: string = "";
  private currentVideoDeviceId: string = "";


  public micStatusChanged(event: any) {
    this.$emit('micStatusChanged', event);
  }

  public camStatusChanged(event: any) {
    this.$emit('camStatusChanged', event);
  }

  public screenShare(event): any {
   this.$emit('screenShare', event);
  }

  public leaveSession(event: any) {
    this.$emit('leaveSession', event);
  }

  public toggleFullscreen(event: any) {
    this.$emit('toggleFullscreen', event);
  }

  public toggleBackgroundSidebar(event: any) {
    this.$emit('toggleBackgroundSidebar', event);
  }

  public stopScreenShare(event) {
    this.$emit('stopScreenShare', event);
  }

  public showManual1Visible(event) {
    this.$emit('showManual1Visible', event);
  }

  public showFixedAlert(event) {
    this.$emit('showFixedAlert', event);
  }

  public toggleChat(event: any) {
    const property: string = this.showNotification ? 'block' : 'none';
    this.$emit('toggleChat', property)
  }

  public audioFilter(v: any) {
    this.$emit('audioFilter', v)
  }

  public audioCustomFilter(v: any) {
    this.$emit('audioCustomFilter', this.pitch,this.rate, this.tempo);
    this.showPopover = false;
  }
  
  public removeFilter(v: any) {
    this.$emit('removeFilter', v)
  }

  public videoFilter(v: any) {
    this.$emit('videoFilter', v)
  }

  public doCallStartRecordingWithName() {
    this.$emit('startRecording', this.recName, this.recordingPromise)
    this.showPopoveRec = false;
  }
  
  public goHelp() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/2`;
    window.open(fc, '_blank');
    //this.$router.push({path: '/help', query: {lang: this.$i18n.locale }});
  }

  public startRecording(v: any) {
    this.showPopoveRec = true;
  }

  public stopRecording(v: any) {
    this.$emit('stopRecording', v, this.recordingPromise)
  }

  onClose() {
    this.showPopover = false
    this.showPopoveRec = false
    this.showPopoverConfig = false
  }
  onOk() {
  }
  onShow() {
    // This is called just before the popover is shown
    // Reset our popover form variables
  }
  onShown() {
    // Called just after the popover has been shown
    // Transfer focus to the first input
  }
  onHidden() {
    // Called just after the popover has finished hiding
    // Bring focus back to the button
  }
 
  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
    this.$bvModal.hide('modalCopyLink')
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }

  public doCallToggleDevices() {
    this.$emit('toggleDevices',this.currentVideoDeviceId,this.currentAudioDeviceId)
  }
  
  public showSubscriber(u: UserModel) {
    let msg: any = this.$t('Are you sure you want to reveal the person?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('showSubscriber',u)
      }
    });
  }

  public removeParticipant(u: UserModel) {
    let msg: any = this.$t('Are you Sure?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('removeParticipant',u)
      }
    });
  }

  public showEvalModal(u: UserModel) {
    this.$emit('showEvalSidebar', u)
  }
  
  public muteSubscriber(u: UserModel) {
    let msg: any = this.$t('Are you Sure?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('muteSubscriber',u)
      }
    });
  }

  public async changeSubscriberAudoFilter(u: UserModel, n: number) {
    
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value && u.blinded)
      this.$emit('changeSubscriberAudoFilter',u, n)
  }

  public IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  public evaluated() {
    
    this.$toast.addMsg(this.$t('Evaluation sent successfully'));
    this.$toast.variant = 'success';
    this.$toast.open();
    this.selectedCandidato = null;
  }
  
  public openHiddenProfile(guid: string, name:string) {
    let perfil = this.$router.resolve({
      name: "HiddenTalentInfoPage", params: {guid}, query: {lang: this.$i18n.locale, n: btoa(name||"") }
    });
    window.open(perfil.href, '_blank')
  }
  
}
</script>

<style lang="scss" src="@/assets/css/live-interview/top-nav-bar.scss"></style>