<template>
<transition appear name="bounce">
  <b-card class="alert-fixed-content shadow" v-show="value">
    <b-card-title >{{$t('LIVE INTERVIEW')}} - Jobecam</b-card-title>

    <b-row class="my-3">
      <b-col md="2" class="d-flex text-info align-items-center flex-wrap justify-content-center">
        <i class="fas fa-user fa-2x"></i>
      </b-col>
      <b-col md="10" class="border-left">
        <b-card-text class="text-secondary" >
          {{$t('Invite Talents Sharing this link')}}

          <div class="small text-muted d-flex justify-content-between">
            <b-button
              variant="primary"
              pill
              class="border w-100" 
              v-b-tooltip.hover
              :title="$t('Copy talent`s link')"
              v-clipboard:copy="candidateLink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <span class="mr-2">{{ $t('Copy talent`s link') }}</span>
              <i class="far fa-copy" />
            </b-button>
          </div>
        </b-card-text>
      </b-col>
    </b-row>

    <b-row class="my-3">
      <b-col md="2" class="d-flex text-primary align-items-center flex-wrap justify-content-center">
        <i class="fas fa-user-tie fa-2x"></i>
      </b-col>
      <b-col md="10" class="border-left">
        <b-card-text class="text-secondary">
          {{$t('Invite evaluators sharing this link')}}

          <div class="small text-muted py-2 d-flex justify-content-between">
            <b-button
              variant="primary"
              class="border w-100"
              v-b-tooltip.hover
              pill
              :title="$t('Copy manager`s link')"
              v-clipboard:copy="managerLink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <span class="mr-2">{{ $t('Copy manager`s link') }}</span>
              <i class="far fa-copy" />
            </b-button>
          </div>
        </b-card-text>
      </b-col>
    </b-row>

    
    <b-card-text class="small text-muted">
      <div class="d-flex justify-content-center">
         
        <b-button
          pill
          class="w-100"
          href="javascript:void(0)"
          variant="outline-primary"
          @click="closeAlert"
        >
          {{$t('Close')}}
        </b-button>
      </div>
    </b-card-text>
    
  </b-card>
</transition>
</template>


<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { siteCandidateEndPoint } from "@/core/endpoint";

@Component
export default class AlertFixedContent extends Vue {

  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public cCrop: boolean = true;
  public mCrop: boolean = true;

  @Prop({default: false })
  public blindEnabled!: boolean;

  @Prop({default: false })
  public value!: boolean;

  get candidateLink() {
    return this.URLcandidato + "/sala-entrevista/" + this.$route.params.sid+"?b="+this.blindEnabled;
  }
  get candidateCroppedLink() {
    return this.URLcandidato + "...";
  }
  get managerLink() {
    return this.URL + "/emp/sala-entrevista/" + this.$route.params.sid +"?b="+this.blindEnabled;
  }

  public onCopy(object: any) {
    const msg: string = this.$t('Link copied successfully!').toString() + ' ' + object.text;
    this.$toast.solid = true;
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.solid = true;
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public closeAlert() {
    this.cCrop = true;
    this.mCrop = true;
    this.$emit('input',false)
  }

}
</script>

<style lang="scss" scoped>

.alert-fixed-content {
  position: fixed;
  top: 7%;
  left: 3%;
  z-index: 9;
  min-width: 380px;
}

</style>