import { render, staticRenderFns } from "./ModalCreateAvaliacao.vue?vue&type=template&id=21e4c3ae&scoped=true"
import script from "./ModalCreateAvaliacao.vue?vue&type=script&lang=ts"
export * from "./ModalCreateAvaliacao.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e4c3ae",
  null
  
)

export default component.exports