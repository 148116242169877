import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';


export default class PublicRoom extends Repository {
    public optionList: any[];
    public entidade!: any;

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/public-room', method: 'POST', headers: {} },
            fetch: { url: '/v1/public-room/{id}', method: 'GET', headers: {} }}));
        this.optionList = [];
    }

    public createLink(rid: number): Promise<any> {
        return super.create({rid});
    }

    public fetchPublicManagerRoom(tkn: string): Promise<any> {
        const data: any = {id: 0, tkn};
        return new Promise((resolve: any, reject: any) => {
            (new ClientAuthentication()).auth().then((rtoken: any) => {
                const dta: any = {
                    rtoken,
                    ...data
                };
                const cdta = CB.genC(dta);
                super.fetchWithData(cdta).then(resolve).catch(reject);
            }).catch((resp) => {
                reject(resp);
            });
        });
    }
    
    public fetchPublicRoomInfo(key: string): Promise<any> {
        const data: any = {id: 0, tp: "data", key};
        return new Promise((resolve: any, reject: any) => {
            (new ClientAuthentication()).auth().then((rtoken: any) => {
                const dta: any = {
                    rtoken,
                    ...data
                };
                const cdta = CB.genC(dta);
                super.fetchWithData(cdta,false).then(resolve)
                .catch(async () => {
                    await this.sleep(2000);
                    this.fetchPublicRoomInfo(key).then(resolve).catch(reject)
                });
            }).catch((resp) => {
                reject(resp);
            });
        });
    }

}


