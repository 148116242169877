<template>
  <transition appear name="slide">
    <div class="v-card v-sheet theme--light" >
      <div class="v-card__title text-base font-weight-semibold" v-if="!noTitle">
        <p class="mb-2"> {{$t(title)}} </p>
      </div>
      <div class="v-card__text" align="center">
        <slot></slot>       
      </div>
    </div>
  </transition>
</template>



<script lang="ts">
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CardImageTotal extends Vue {

  @Prop({default: ''})
  public title!: string;

  @Prop({default: false})
  public noTitle!: boolean;

}
</script>