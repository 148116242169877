import { render, staticRenderFns } from "./Pagamentos.vue?vue&type=template&id=4f642df8&scoped=true"
import script from "./Pagamentos.vue?vue&type=script&lang=ts"
export * from "./Pagamentos.vue?vue&type=script&lang=ts"
import style0 from "./Pagamentos.vue?vue&type=style&index=0&id=4f642df8&prod&lang=scss&scoped=true"
import style1 from "./Pagamentos.vue?vue&type=style&index=1&id=4f642df8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f642df8",
  null
  
)

export default component.exports