<template>
  <div class="box-container d-flex flex-column">
    <div class="row">
      <div class="col-12 col-md-12">
        <h1 class="text-md-left text-center">{{$t('Payment Method')}}</h1>
      </div>
    </div>
    <b-row class="mt-4">
      <!-- Card de Plano e Cancelamento -->
      <b-col md="4"  v-if="serviceMetodo.entidade.id">
        <p class="text-md-left text-center">{{$t('Your registered card currently is this here')}}</p>
        <credit-card class="mx-auto"  :metodo="serviceMetodo.entidade" />
      </b-col>
      <!-- Saldo de Jobcoins e Extrato -->
      <b-col md="8" class="mx-auto">
         <b-card class="payment-card">
            <b-container slot="header">
              <b-row>
                <b-col md="12" class="payment-option cartao" >
                  {{ $t('Credit Card') }}
                </b-col>
              </b-row>
            </b-container>
            <credit-card-form  />
          </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import MetodoPagamento from '@/core/Services/Payments/MetodoPagamento';
import { Component, Vue } from 'vue-property-decorator';
import CreditCardForm from './partials/CreditCardForm.vue';

@Component({
  components: {
    CreditCardForm
  }
})
export default class SubstituirCartao extends Vue {
  public serviceMetodo: MetodoPagamento = new MetodoPagamento();
  
   public async created() {
    // starting async fetching the payment method
    await this.serviceMetodo.fetchCurrent();
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.payment-card {
    height: 100%;

    .card-header {
      padding: 0;

      .payment-option {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        padding: 2%;
        cursor: pointer;

        .icon-img {
          margin-right: 7%;
        }

        &.cartao {
          background-color: $green-light;
          .icon-img {
            width: 39px;
          }
        }
        &.paypal {
          background-color: $gray;
          .icon-img {
            width: 27px;
          }
        }
      }
    }

    .btn-realizar {
      display: block;
      margin: 0 auto;
      padding: 1.3% 9%;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
</style>
