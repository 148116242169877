<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  public mounted() {
    try {
      setTimeout(() => {
        const recaptchaBadges = document.querySelectorAll('.grecaptcha-badge');
        if(recaptchaBadges)
        for (let index = 0; index < recaptchaBadges.length; index++) {
          const element: any = recaptchaBadges[index];
          element.style.visibility = 'hidden'
        }
      },1000)
    } catch(e) {
      console.log(e)
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/main";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "@/assets/scss/_variables";

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f7f7fa;
}

html {
  font-size: 14px;
}

#app {
  font-family: Helvetica;
}

@include media("<desktop") {
  .root-wrapper {
    font-size: $default-font-size-sm;
  }
}
@include media(">desktop", "<=large") {
  .root-wrapper {
    font-size: $default-font-size-md;
  }
}

@include media(">large") {
  .root-wrapper {
    font-size: $default-font-size-lg;
  }
}
</style>
