
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class PlanoCredito extends Repository {

  constructor() {
    super(new ApiRouter({
        fetch: { url: '/v1/plano-credito/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/plano-credito', method: 'GET', headers: {} }}));
  }

}
