<template>
  <div class="box-container d-flex flex-column">
    <b-row>
      <b-col md="12">
        <h3 class="text-md-left text-center">
          {{$t('Reports')}}
        </h3>
      </b-col>
    </b-row>
    <b-row class="listOfReports_" ref="listOfReports">
      <b-col 
        v-for="report in reportList"
        :key="report.code"
        md="4" class="pt-5">
      
          <b-card
            border-variant="light"
            :header="$t(report.title)"
            header-bg-variant="light"
            header-text-variant="info"
            align="center"
            class="shadow"
          >
            <b-card-text>{{$t(report.description)}}</b-card-text>
            <b-card-text>
              <b-button 
              variant="primary" 
              :to="{name:'ReportTablePage', params: { rid: report.code }, query: {lang: $i18n.locale}}"  class="shadow"> 
              {{$t('Open')}} </b-button>
            </b-card-text>
          </b-card>
        
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import ReportFilter from '@/core/Services/Reports/ReportFilter'
import { EmpresaEntity, ReportFilters } from '@/core/models/Entities';

@Component
export default class ReportListPage extends Vue {

  // <!-- #region attributes -->
  public reportList: ReportFilters[] = [];

  private service: ReportFilter = new ReportFilter()

  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;
  
  
  // <!-- #endregion -->

  // <!-- #region LifecycleHooks -->
  
  public mounted() {
    
    let loader: any = this.$loading.show({
      // Optional parameters
      container: this.$refs.listOfReports,
      isFullPage: false,
      canCancel: false,
      loader: 'dots'
    });
    this.service?.fetchAll()
    .then((data: any) => {
      this.reportList = data;
    })
    .catch((err:  any) => {
      console.error(err);
    }).finally(() => {
      loader.hide();
    })
  }

  // <!-- #endregion -->

  public getHeadClass(code: number) {
    return (code%2 == 0 ) ? 'primary' : 'secondary'
  }
  
  public getTextClass(code: number) {
    return (code%2 == 0 ) ? 'white' : 'white'
  }

}
</script>

<style lang="scss" scoped>
.listOfReports_ {
  position: relative;
  min-height: 400px;
}
</style>