<template>
  <div class="form-container">
    <h1 class="h3">{{ $t('Register your company') }}</h1>
    <p class="text-blue">{{ $t('Try for free') }}</p>

    <b-form
      @submit.prevent="submitForm"
      v-promise-btn="{ action: 'submit' }"
      id="register-form"
      novalidate
    >
      <div class="inputs">
        <div class="inputs-row">
          <text-input
            id="personName"
            name="personName"
            :label="$t('Full name') + ' *'"
            :placeholder="$t('Full name')"
            :invalid-feedback="$t('Fill this field')"
            v-model="$v.personName.$model"
            :state="$v.personName.$dirty && submited ? !$v.personName.$error : null"
            variant="dark"
          />

          <text-input
            id="companyName"
            name="companyName"
            :label="$t('Company name') + ' *'"
            :placeholder="$t('Company name')"
            :invalid-feedback="$t('Fill this field')"
            v-model="$v.companyName.$model"
            :state="$v.companyName.$dirty && submited ? !$v.companyName.$error : null"
            variant="dark"
          />
        </div>

        <div class="inputs-row">
          <text-input
            id="email"
            name="email"
            :label="$t('E-mail') + ' *'"
            :placeholder="$t('E-mail')"
            :invalid-feedback="$t('Fill with a valid corporate email address')"
            v-model="$v.email.$model"
            :state="$v.email.$dirty && submited ? !$v.email.$error : null"
            variant="dark"
          />

          <tel-input
            id="telephone"
            name="telephone"
            type="tel"
            :label="$t('Enter your phone number')"
            :invalid-feedback="$t('Fill this field')"
            v-model="$v.telephone.$model"
            :state="$v.telephone.$dirty && submited ? !$v.telephone.$error : null"
            variant="dark"
          />
        </div>

        <div class="inputs-row">
          <text-input
            id="password"
            name="password"
            type="password"
            :label="$t('Enter your password') + ' *'"
            :state="submited ? passwordIsValid() : null"
            v-model="passwordStrength.password"
            variant="dark"
          >
            <template #invalidFeedback>
              <b-form-invalid-feedback>
                <ul class="errors-list">
                  <li v-if="!passwordStrength.hasOneLower">
                    {{ $t("At least 1 lowercase alphabetical character") }}
                  </li>
                  <li  v-if="!passwordStrength.hasOneUpper" >
                    {{ $t("At least 1 uppercase alphabetical character") }}
                  </li>
                  <li v-if="!passwordStrength.hasOneNumber">
                    {{ $t("At least 1 numeric character") }}
                  </li>
                  <li v-if="!passwordStrength.hasSpecialChars">
                    {{ $t("At least 1 special character") }}
                  </li>
                  <li v-if="!passwordStrength.hasEightChars">
                    {{ $t("At least 8 characters or longer") }}
                  </li>
                  <li v-if="!passwordStrength.hasSequence">
                    {{ $t("Cannot contain sequences. Ex.: 123, abc") }} 
                  </li>
                </ul>
              </b-form-invalid-feedback>
            </template>
          </text-input>

          <text-input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            :label="$t('Repeat password') + ' *'"
            v-model="passwordConfirmation"
            :state="submited ? (passwordConfirmation === passwordStrength.password && !!passwordConfirmation) : null"
            :invalid-feedback="$t('Passwords are not the same')"
            variant="dark"
          />
        </div>

        <checkbox
          class="mt-3"
          v-model="$v.acceptedTerms.$model"
          :state="$v.acceptedTerms.$dirty ? !$v.acceptedTerms.$error : null"
          :invalid-feedback="$t('Terms must be accepted')">
          {{ $t("I accept the") }} <a href="#" target="_blank">{{$t("Terms of Use Liability")}}</a>.
        </checkbox>
      </div>

      <b-button
        type="submit"
        variant="blue"
        v-promise-btn="{ promise: registerPromise }"
      >
        {{ $t('Register') }}
      </b-button>
      <b-button
        type="button"
        variant="outline-blue"
        @click="goToLoginPage"
      >
        {{ $t('Back to Login') }}
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, sameAs, email } from 'vuelidate/lib/validators';

import notFreeMail from '@/core/validations/notFreeMail';
import { PasswordStrengh } from '@/core/util'

import TextInput from '@/components/form/TextInput.vue';
import TelInput from '@/components/form/TelInput.vue';
import Alert from '@/components/alerts/Alert.vue';
import Checkbox from './Checkbox.vue';

import { EmpresaEntity, EmpresaTrialAccount } from '@/core/models/Entities';
import EmpresaService from '@/core/Services/Empresa/Empresa';

@Component({
  components: {
    TextInput,
    TelInput,
    Checkbox,
  },
})
export default class RegisterForm extends Vue {
  @Validate({ required })
  private companyName: string = '';
  
  @Validate({ required, email, notFreeMail })
  private email: string = '';
  
  @Validate({ required })
  private telephone: string = '';
  
  @Validate({ required })
  private personName: string = '';

  @Validate({ checked: sameAs( () => true ) })
  private acceptedTerms: boolean = false;

  public passwordConfirmation: string = '';
  
  private company = new EmpresaEntity();
  private companyService = new EmpresaService();
  private trialAccount: EmpresaTrialAccount | null = null;
  private alert = new Alert();
  public submited = false;

  public registerPromise: Promise<any> | null = null;

  public passwordStrength = new PasswordStrengh();

  public goToLoginPage() {
    this.$router.push(`/login?lang=${this.$i18n.locale}`);
  }

  private async registerCompany() {
    this.registerPromise = this.companyService.create(this.company);
  
    const response = await this.registerPromise as any;

    sessionStorage.setItem('access_token', response['accessToken']);
    sessionStorage.setItem('token_type', 'Bearer');
    sessionStorage.setItem('profile_type', 'company');

    this.trialAccount = {
      nomeEmpresa: this.companyName,
      email: this.email,
      telefone: this.telephone,
      nomeResponsavel: this.personName,
      lang: this.$i18n.locale,
      ebook: false,
    };

    sessionStorage.setItem('dadosEmpresa', JSON.stringify(response));
    sessionStorage.setItem('registroEmpresa', JSON.stringify(this.trialAccount));

    // if(window.gtag) {
    //   window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'G-V2D4Z1PD6G' });
    //   window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'UA-93012005-1' });
    //   window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'AW-873758741' });

    //   window.gtag('event','sign_up', { method: 'Empresa', send_to: 'G-V2D4Z1PD6G' });
    //   window.gtag('event','sign_up', { method: 'Empresa', send_to: 'AW-873758741' });
    //   window.gtag('event','sign_up', { method: 'Empresa', send_to: 'UA-93012005-1' });
    // }
    
    // if(window.fbq) {
    //   window.fbq('track', 'Lead', { 
    //     content_category: "Lead",
    //     content_name: "Company",
    //     value: this.companyName,
    //   });
    // }

    response['email'] = this.email;
    return response;
  }

  public passwordIsValid() {
    return (
      this.passwordStrength.hasOneLower &&
      this.passwordStrength.hasOneUpper &&
      this.passwordStrength.hasEightChars &&
      this.passwordStrength.hasOneNumber &&
      this.passwordStrength.hasSpecialChars &&
      this.passwordStrength.hasSequence
    );
  }

  public async submitForm() {
    this.$v.$touch();
    this.submited = true;

    if (
      this.$v.$invalid ||
      !this.passwordIsValid() ||
      this.passwordConfirmation !== this.passwordStrength.password
    ) {
      return;
    }

    this.company.primeironome = this.personName;
    this.company.perfilempresa!.telefones = this.telephone;
    this.company.email = this.email;

    this.company.perfilempresa!.nomeEmpresa = this.companyName;
    this.company.senha = this.passwordStrength.password;
    this.company.lingua = this.$i18n.locale;

    let tel = "" + this.company.perfilempresa!.telefones;

    const telephoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    const telephoneIsInvalid = !telephoneRegex.test(tel) || tel.replace(/([^\d])+/gim, '').length < 10;

    if(telephoneIsInvalid){
      this.alert.title = ""+this.$t('Alert')
      this.alert.addMsg(this.$t('Invalid phone'));
      this.alert.callBoxDanger();

      return;
    }

    try {
      const companyData = await this.registerCompany();

      this.$emit('success', companyData);
    } catch (error) {
      this.alert.title = this.$t('Alert');
      this.alert.addMsg(this.$t('Could not create your account, an error occurred'));
      this.alert.callBoxWarn();

      this.$emit('error', error);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  div.form-container {
    h1, p {
      text-align: center;
    }

    h1 {
      font-weight: bold;
      color: $dark-gray;
    }

    form {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      div.inputs {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 80%;
        margin: 16px 0px;

        fieldset {
          width: 100%;
        }
      }

      div.inputs-row {
        display: flex;

        fieldset {
          flex: 1;
        }

        fieldset + fieldset {
          margin-left: 16px;
        }

        width: 100%;

        @media only screen and (max-width: 750px) {
          flex-direction: column;
          
          fieldset + fieldset {
            margin-left: 0px;
          }
        }
      }

      button {
        width: 80%;
        padding: 8px 0px;
      }

      button + button {
        margin-top: 4px;
      }
    }
  }
</style>
