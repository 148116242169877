import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import PublicRepository from '@/core/Services/PublicRepository';

export default class PublicCandidatura extends PublicRepository {

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/public-interview', method: 'POST', headers: {} },
            fetch: { url: '/v1/public-interview/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/public-interview', method: 'GET', headers: {} },
            update: { url: '/v1/public-interview/{id}', method: 'PUT', headers: {} }
        }));
    }
    
}
