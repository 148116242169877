<template>
  <div class="row"  v-if="helpers">
    <div class="col-4 col-md-4 px-md-0 d-flex my-2 align-items-center" v-for="(item, key) in helpers" :key="key">
      
      <button-icon-rounded
        :color="item.color"
        :border="item.color"
        :backHover="item.color"
        fontSize="0.8em"
        class="mr-1 btn-icon-status"
      >
        <span :class="item.btnIcon"></span>
      </button-icon-rounded>
      <label
        class="text-uppercase m-0 cursor-pointer"
        :style="{ color: '#aaaaaa', lineHeight: '1em', '--color-hover': item.color }"
      >
        <strong>{{ (totals[item.key]) ? totals[item.key].padStart(2, '0') : '00' }}</strong>
        <br />{{ $t(item.text) }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VagaStatus extends Vue {
  
  @Prop() 
  public totals!: any;
  public helpers: any|null = null;

  public mounted() {
    this.$nextTick(() => {
      this.helpers = [
        {
          key: 'total',
          text: 'Applications',
          color: 'rgba(125,162,249,1)',
          btnIcon: 'icon icon-user',
        },
        {
          key: 'videospendentes',
          text: 'Pendings',
          color: 'rgba(249, 125, 125)',
          btnIcon: 'icon icon-interrogao',
        },
        {
          key: 'emanalise',
          text: 'Analysis',
          color: 'rgba(249,163,125,1)',
          btnIcon: 'icon icon-analise',
        },
        {
          key: 'aprovados',
          text: 'Approveds',
          color: 'rgba(151,201,130,1)',
          btnIcon: 'icon icon-aprovados',
        },
        {
          key: 'reprovados',
          text: 'Reproveds',
          color: 'rgba(191,208,20,1)',
          btnIcon: 'icon icon-reprovados',
        },
        {
          key: 'repescagem',
          text: 'Waiting list',
          color: 'rgba(137,125,249,1)',
          btnIcon: 'icon icon-trocar',
        },
      ];
    });
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

  label {
    font-size: 0.6em;
  }
  .btn-icon-status{
    padding: 0;
  }

  .icon:before {
    vertical-align: middle;
  }

  label:hover {
    color: var(--color-hover) !important;
  }

  
@include media("<desktop") {
  label {
    font-size: $status-font-size-sm;
    padding: 0em;
  }
}
@include media(">desktop", "<=large") {
  label {
    font-size: $status-font-size-md;
  }
}

@include media(">large") {
  label {
    font-size: $status-font-size-lg;
  }
}
</style>