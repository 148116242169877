<template>
  <transition>
    <b-row>
        <b-col md="12">
          <b-row no-gutters>
            <div class="col-md-12 d-flex justify-content-center foto-perfil" v-if="recrutador.id">
              <img
                :src="recrutador.fotoAvatar"
                alt="cover"
                v-if="recrutador.fotoAvatar"
              />
              <img src="/static/img/avatar.png" alt="cover" v-else />
            </div>
            <div  v-if="recrutador.id" class="col-md-12 my-4 d-flex justify-content-center">
              <file-button-icon @change="handleAvatarChange" :btnClass="'btn  btn-info btn-change-user-picture text-uppercase rounded-pill'">
                {{$t('Replace user photo')}}
              </file-button-icon>
            </div>
            <b-col md="12">
              <text-input
                variant="dark"
                class="text-uppercase"
                :required="true"
                :label="$t('Recruiter name')"
                :placeholder="'José da silva'"
                v-model="recrutador.nome"
              />
            </b-col>
            <b-col md="12">
              <text-input
                variant="dark"
                class="text-uppercase"
                :required="true"
                :label="'E-mail'"
                :placeholder="'josedasilva@jobecam.com'"
                v-model="recrutador.email"
              />
            </b-col>
            <b-col md="12">
              <text-input
                variant="dark"
                class="text-uppercase"
                :required="true"
                :label="$t('Post')"
                :placeholder="'...'"
                v-model="recrutador.cargo"
              />
            </b-col>
            <b-col md="6" v-if="!selfUpdate">
              <b-button id="button-2" variant="default" class="float-right mt-3" pill size="sm" > <i class="icon-ajuda" /> </b-button>
              <text-input
                variant="dark"
                class="text-uppercase"
                :required="true"
                :type="'password'"
                :label="$t('Enter a password')"
                :placeholder="'*********'"
                v-model="passwordStrengh.password"
              />
              <b-tooltip target="button-2" placement="bottom">
                <ul class="list-unstyled">
                  <li>
                  <i v-if="!passwordStrengh.hasOneLower" class="fa fa-times text-danger"></i>
                  <i v-if="passwordStrengh.hasOneLower" class="fa fa-check text-success"></i>
                  {{ $t("At least 1 lowercase alphabetical character") }}
                  </li>
                  <li>
                    <i v-if="!passwordStrengh.hasOneUpper" class="fa fa-times text-danger"></i>
                    <i v-if="passwordStrengh.hasOneUpper" class="fa fa-check text-success"></i>
                    {{ $t("At least 1 uppercase alphabetical character") }}
                  </li>
                  <li>
                    <i v-if="!passwordStrengh.hasOneNumber" class="fa fa-times text-danger"></i>
                    <i v-if="passwordStrengh.hasOneNumber" class="fa fa-check text-success"></i>
                    {{ $t("At least 1 numeric character") }}
                  </li>
                  <li>
                    <i v-if="!passwordStrengh.hasEightChars" class="fa fa-times text-danger"></i>
                    <i v-if="passwordStrengh.hasEightChars" class="fa fa-check text-success"></i>
                    {{ $t("At least 8 characters or longer") }}
                  </li>
                  <li>
                    <i v-if="!passwordStrengh.hasSpecialChars" class="fa fa-times text-danger"></i>
                    <i v-if="passwordStrengh.hasSpecialChars" class="fa fa-check text-success"></i>
                    {{ $t("At least 1 special character") }}
                  </li>
                  <li>
                    <i v-if="!passwordStrengh.hasSequence" class="fa fa-times text-danger"></i>
                    <i v-if="passwordStrengh.hasSequence" class="fa fa-check text-success"></i>
                    {{ $t("Cannot contain sequences. Ex.: 123, abc") }} 
                  </li>
                </ul>
              </b-tooltip>
            </b-col>
            <b-col md="6" v-if="!selfUpdate">
              <text-input
                variant="dark"
                class="text-uppercase"
                :required="true"
                :type="'password'"
                :label="$t('Repeat password')"
                :placeholder="'********'"
                v-model="confirmSenha"
              />
            </b-col>
            <div v-if="isADM">
              <b-col md="12">
                <checkbox-input :accepted="'Y'" :not_accepted="'N'" v-model="recrutador.permissaoAdm">{{ $t("Administrator Permission") }}</checkbox-input>
              </b-col>
              <b-col md="12">
                <select-input
                  :optionList="idiomaService.optionSiglaList"
                  class="text-uppercase"
                  variant="dark"
                  :required="true"
                  :label="$t('Language')"
                  v-model="recrutador.lingua"
                />
              </b-col>
            </div>
          </b-row>
        </b-col>
        <div class="col-12 my-md-5 my-2 d-flex align-items-center justify-content-center">
          <button type="button" class="btn btn-info btn-save-recruiter" @click="save"
            v-promise-btn="{promise}" >
            <i class="fas fa-check mr-2"></i>{{$t("Save Recruiter")}}
          </button>
        </div>
      </b-row>
  </transition>
</template>

<script lang="ts">
import FileButtonIcon from '@/components/button-icon/FileButtonIcon.vue';
import IdiomaService from '@/core/Services/Basic/Idioma';
import GestaoRecrutador from '@/core/Services/Empresa/GestaoRecrutador';
import { RecrutadorEntity } from '@/core/models/Entities';
import { PasswordStrengh } from '@/core/util';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'file-button-icon': FileButtonIcon
  }
})
export default class NovoRecrutador extends Vue {
  
  @Prop()
  public promise!: any;
  
  @Prop({ default: false })
  public selfUpdate!: boolean;

  public service: GestaoRecrutador = new GestaoRecrutador();
  public recrutador:RecrutadorEntity = new RecrutadorEntity();
  public avatar: any = "";
  public confirmSenha = "";
  public idiomaService: IdiomaService;
  public passwordStrengh: PasswordStrengh|null = null;
  public isADM: boolean = false;

  constructor() {
    super();
    this.idiomaService = new IdiomaService();
    this.passwordStrengh = new PasswordStrengh();
  }

  public setRecrutador(r: RecrutadorEntity) : void {
    this.recrutador = r;
    this.passwordStrengh!.password = "";
  }

  get IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  public async created() {
    if (this.IsRecruiter) {
      this.isADM = await this.service.isAdm();
    } else {
      this.isADM = true;
    }
  
  }
  
  public isValidForm(midia: boolean) {
    let valid: boolean = true;

    if (!midia) {
        if (!this.recrutador.nome) {
          valid = false;
          this.$alert.addMsg('- '+this.$t('Name'));
        }

        if (!this.recrutador.email) {
            valid = false;
            this.$alert.addMsg('- '+this.$t('E-mail'));
        } else if ( this.recrutador.email.match(/[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/) == null ) {
            valid = false;
            this.$alert.addMsg('- '+this.$t('Invalid email format'));
        }

        if (!this.recrutador.cargo) {
            valid = false;
            this.$alert.addMsg('- '+this.$t('Post'));
        }

        this.recrutador.senha = this.passwordStrengh!.password;
        if (!this.recrutador.id || this.recrutador.senha !== "") {
          if (!this.recrutador.senha) {
              valid = false;
              this.$alert.addMsg('- '+this.$t('Password'));
          }

          if (this.recrutador.senha != this.confirmSenha) {
              valid = false;
              this.$alert.addMsg('- '+this.$t('Passwords entered in the fields (New Password and Confirm New Password) must be the same.'));
          }

          if (!this.passwordStrengh!.validatePassword()) {
              valid = false;
              this.$alert.addMsg('- '+this.$t('Your password has not passed the security requirements'));
          }
        }
    }

    if (!valid) {
        this.$alert.title = this.$t('Please inform')
        this.$alert.callBoxDanger()
        .catch(err => {
            console.log(err)
        })
    }
    return valid;
  }

  public handleAvatarChange(file: any) {
    if (file) {
      this.service.entidade.id = this.recrutador.id;
      this.service.saveAvatar(file)
      .then((data: any) => {
        this.$emit('avatarSaved',true);
        // this.alert.addTitle(this.$t('Aviso').toString())
        this.$alert.addMsg(this.$t('Saved successfully'))
        this.$alert.callBoxCheck('Ok');
      })
    }
  }

  public save() {
    let valid: boolean = this.isValidForm(false);

    if (!valid) {
        return;
    }

    this.$emit('save', this.recrutador);
  } 
     
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
#button-2 {
  padding: 2px 5px;
  margin-right: 2px;
}
.box-container {
  min-height: calc(100vh - 55px - 32px);
  .box-formulario {
    > div {
      width: calc(50% - 1em);
    }
    .foto-perfil {
      img {
        width: 6em;
        border-radius: 50%;
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>