<template>
  <transition>
    <div class="loader-wrapper" v-show="show" :style="'width:'+width+'px;height:'+height+'px'"> 
      <div id="lt-loader" class="lottie-loader"></div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import lottie from 'lottie-web';

@Component
export default class LootieAnimation extends Vue {
  
  
  @Prop({default: null})
  public value!: any;

  @Prop({default: false})
  public show!: boolean;
  
  @Prop({default: false})
  public loop!: boolean;

  @Prop({default: false})
  public autoplay!: boolean;

  @Prop({default: ''})
  public animation!: string;

  @Prop({default: 100})
  public width!: number;

  @Prop({default: 100})
  public height!: number;

  public loader: any = lottie;

  public mounted() {
    this.start()
    this.$emit('input',this.loader)
  }

  public destroy() {
    if(this.loader)
      this.loader.destroy();
  }
  public start() {
    if(this.loader) {
      this.loader.loadAnimation({
        container: document.getElementById('lt-loader')!, // the dom element that will contain the animation
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        path: `/static/lootie/${this.animation}.json` // the path to the animation json
      })
    }
  }

}
</script>

