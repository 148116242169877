<template>
  <b-container class="mt-5 position-relative m-h-400" ref="containerLoader">
    <b-row>
      <b-col md="12" class="mb-4 d-flex justify-content-end">
        <b-button  v-b-toggle.teamForm variant="primary" pill>
          <i class="fa fa-plus-circle mr-2"></i>
          {{$t("New team")}}
        </b-button>
      </b-col>
      <b-col md="12">
        <b-table id="equipes" hover responsive :items="items" :fields="fields" :busy="isBusy" >
          <template v-slot:head()="data">
            {{ $t(data.label) }}
          </template>
          <template #cell(recrutadores)="row">
            <div class="justify-content-center d-flex ">
              <span class="mr-1" v-for="(h,i) in row.item.recrutadores" :key="i">
                {{h.name}}<small v-if="i !== (row.item.recrutadores.length-1)">,</small> 
              </span>
            </div>            
          </template>
          <template #cell(action)="row">
            <div class="platform-only">
              <b-button 
                variant="outline-primary"
                v-b-toggle.teamForm
                @click="editTeam(row.item.action)" class="mr-1">
                <i class="fa fa-pen "></i>
                <span class="sr-only">{{$t('Edit')}}</span>
              </b-button>
              <b-button 
                variant="outline-danger"
                @click="deleteEquipe(row.item.action)" >
                <i class="fa fa-times"></i>
                <span class="sr-only">{{$t('Delete')}}</span>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <sidebar-equipe ref="teamFormSD" id="teamForm" @done="doneAction" @hidden="closeSidebar" v-model="sidebarForm"  />
  </b-container>
</template>

<script lang="ts">
// List Company evaluations
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";
import Equipe from '@/core/Services/Equipes/Equipe';
import SidebarEquipe from "@/components/sidebar/SidebarEquipe.vue";
import { EquipeEntity, EmpresaEntity } from '@/core/models/Entities';


@Component({
  components: {
    SidebarEquipe
  }
})
export default class Equipes extends Vue {
  public service: Equipe;
  public list: EquipeEntity[];
  public page: number = 1;
  public limit: number = 10; 
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;
  public sidebarForm: boolean = false;

  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  constructor() {
    super();
    this.service = new Equipe();
    this.list = []
  }

  
  public loadSidebar() {
    switch (this.$route.name) {
      case "EditTeam":
        this.sidebarForm = true;
        break;
      default:
        break;
    }
  }

  public created() {
    this.loadSidebar();
  }

  public doneAction() {
    this.sidebarForm = false;
    this.loadTable()
    this.$router.replace({
      name: "Teams",
      query: { lang: this.$i18n.locale }
    })
  }

  public async mounted() {
    const _: any = this;
    if (!this.empresa) {
      _.openLoading(this.$refs.containerLoader);
      await this.fetchEmpresaInfo();
    }
    
    this.loadTable()
  }

  public loadTable() {
    const _: any = this;
    this.fields = [     
      { key: 'title', label: this.$t('Team'), class: 'text-center' },
      { key: 'recrutadores', label: this.$t('Recruiters'), class: 'text-center' },
      { key: 'action', label: this.$t('Actions'), class: 'text-center' },
    ];
    this.items = [];
      _.openLoading(this.$refs.containerLoader);
    this.service.fetchAll({
      page: this.page,
      limit: this.limit
    }).then((data) => {
      this.list = data['results'];
      this.list.forEach((item: any) => {
        this.items!.push({
          title: item.title,
          recrutadores: item.recrutadores,
          action: item.id
        })
      })
    }).finally(() => {
      _.closeLoading();
    })
  }

  public deleteEquipe(id: number) {
    const _: any = this;
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.cancelTitle = 'No';
    this.$confirm.oktitle = 'Yes';
    this.$confirm.addMsg(this.$t('Are you sure you want to remove this team?'))
    this.$confirm.callBoxDanger().then((v:boolean) => { 
      if(v) {
        _.openLoading(this.$refs.containerLoader);
        this.service.delete({id}).then(() => {
          this.$toast.variant = 'success';
          this.$toast.addMsg(this.$t('Deleted Successfully'))
          this.$toast.addTitle(this.$t('-').toString());
          this.$toast.open();
          const index: number = this.items!.findIndex(_ => { return _.action == id})
          this.items!.splice(index,1)
        }).catch(() => {
          this.$toast.variant = 'danger';
          this.$toast.addMsg(this.$t('Cannot delete because it is in use'))
          this.$toast.addTitle(this.$t('Oops').toString());
          this.$toast.open();
        }).finally(() => {
          _.closeLoading();
        })
      }
    });  
  }

  public closeSidebar() {
    if(this.$route.name != "Teams")
      this.$router.push({ name: 'Teams',  query: { lang: this.$i18n.locale} }).catch(() => {})
    this.sidebarForm = false
  }

  public editTeam(eid: string) {
    if(this.$route.name != "EditTeam")
      this.$router.push({ name: 'EditTeam',  params: { eid },  query: { lang: this.$i18n.locale} }).catch(() => {})
    this.sidebarForm = true;
  }
}
</script>

<style lang="scss">

</style>