<template>
  <div class="box-habilities">
    <!-- Colocar aqui os filtros -->
    <form-vaga-habilidades v-model="value.vagaHabilidades" @removed="delHabilidade" />
    <form-vaga-pergunta-corte v-model="value.PerguntasCorte"  @removed="deletePerguntaCorte" />
    <form-vaga-exames v-if="permitTests" :vagaId="value.id" v-model="value.questionaries" />
    <b-row>
      <b-col md="12" class="mt-2 d-flex justify-content-between">
        <button
          class="btn btn-outline-secondary text-uppercase rounded-pill"
          @click.prevent="backQuestions"
          v-promise-btn="{promise:currentPromise}"
        >{{$t('Back')}}</button>
        <button
          class="btn btn-info btn-salvar text-uppercase"
          @click.prevent="saveHabilities"
          v-promise-btn="{promise:currentPromise}"
        >
          <i class="icon-check"></i>
          {{$t('Save and Proceed')}}
        </button>
      </b-col>
    </b-row>
  </div>
</template>


<script lang="ts">
import CircleRate from "@/components/rate/CircleRate.vue";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import VagaPerguntaCorte from "@/core/Services/Vaga/VagaPerguntaCorte";
import VagaHabilidades from "@/core/Services/Vaga/VagasHabilidades";
import {
EmpresaEntity,
HabilidadeEntity,
VagaEntity
} from "@/core/models/Entities";
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import FormVagaExames from './FormVagaExames.vue';
import FormVagaHabilidades from './FormVagaHabilidades.vue';
import FormVagaPerguntaCorte from './FormVagaPerguntaCorte.vue';

const namespace: string = "profile";

@Component<FormVagaCompetencias>({
  components: {
    CircleRate,
    'form-vaga-exames': FormVagaExames,
    'auto-complete': Autocomplete,
    FormVagaHabilidades,
    FormVagaPerguntaCorte
  }
})
export default class FormVagaCompetencias extends Vue {
  public serviceVaga: GestaoVaga;
  public serviceVagaHab: VagaHabilidades;
  public serviceVagaPergCorte: VagaPerguntaCorte;

  public habilidades: HabilidadeEntity[] = [];
  public habilidadeSelecionada: HabilidadeEntity | null = null;

  public timeout: any;
  public currentPromise: any | null = null;

  @Getter("companyInfo", { namespace })
  public empresa?: EmpresaEntity;

  @Prop({default: _ => new VagaEntity()})
  public value!: VagaEntity;

  constructor() {
    super();
    this.serviceVaga = new GestaoVaga();
    this.serviceVagaHab = new VagaHabilidades();
    this.serviceVagaPergCorte = new VagaPerguntaCorte();
  }
  
  get permitTests() {
    return this.empresa?.configuracoes?.ENABLE_QUESTIONARY == true
  }


  public deletePerguntaCorte(pergunta: any) {
    if(pergunta?.id > 0)
      this.serviceVagaPergCorte
        .delete({ id: pergunta.id })
        .then((data: any) => {
          this.$emit('input',this.value);
        }).catch((error: any) => {
          this.$alert.addMsg(this.$t("It was not possible to erase, there are already answers to this question!"));
          this.$alert.callBoxWarn();
        });
  }


  public mounted() {
    this.value.vagaHabilidades = !this.value.vagaHabilidades ? [] : this.value.vagaHabilidades;
    this.value.PerguntasCorte = !this.value.PerguntasCorte ? [] : this.value.PerguntasCorte;
  }

  public backQuestions() {
    this.$emit("PrevStep", true);
  }

  public saveHabilities() {
    let valid = true;
    this.value.PerguntasCorte.forEach(element => {
      if (element.pergunta === "") {
        valid = false;
        return;
      }
    });

    if (!valid) {
      this.$alert.addMsg(
        this.$t(
          "Please make sure the question and expected answer fields are filled in."
        )
      );
      this.$alert.callBoxWarn();
      return;
    }

    this.serviceVaga.entidade = this.value;
    this.currentPromise = this.serviceVaga
    .save()
    .then((data: VagaEntity) => {
      if (data && data.perguntas) {
        this.value.perguntas = data.perguntas
        this.$emit('input', this.value);
        this.$emit("NextStep", true);
      }
    })
    .catch(err => {
      console.log(err);
      this.$emit("error");
    });
  }

  public delHabilidade(hab: any) {
    if(hab?.id > 0)
      this.serviceVagaHab
        .delete({ id: hab.id })
        .then((data: any) => {
          this.$emit('input',this.value);
        })
        .catch((err: any) => {
          console.log(err);
        });
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";
@import "@/assets/scss/_variables";

.principal-container {

  .box-habilities {
    padding-top: 1em;
    > .row {
      padding: 1.5em;
    }
    // .search {
    //   padding: 0 6em;
    // }
    .conteudo {
      padding: 1em;
      border-radius: 8px;
      border: 2px solid #ececec;
    }
    h3 {
      font-size: 1.5em;
      margin: 0;
    }
    .questions {
      h3 {
        margin: 0;
      }
      .list-questions {
        display: flex;
        flex-direction: column;
        .question {
          font-size: 1rem;
          padding: 1em 1.5em;
          border-radius: 8px;
          border: 2px solid #ececec;
          &:not(:last-child) {
            margin-bottom: 1em;
          }
          .btn-remove {
            font-size: 1em;
            /* height: 3em; */
          }
          &--expected-answer {
            label {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    .search-title,
    .search-box-input,
    .competencias-title {
      padding: 0 6em;
    }
    .lista {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 9em;
      .card-competencia {
        display: flex;
        border: 2px solid #ececec;
        height: 4.5em;
        border-radius: 8px;
        margin-bottom: 0.8em;
        &.active {
          .minima {
            background-color: $blue;
            color: white;
            font-weight: bold;
            // TODO => Remover do scopo
            .custom-control-input:not(:disabled):active
              ~ .custom-control-label::before {
              color: $white;
              background-color: $blue-light;
              border-color: $blue-light;
            }

            .custom-control-input:not(:disabled):active
              ~ .custom-control-label::before {
              color: $white;
              background-color: $blue-light;
              border-color: $blue-light;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
              color: $white;
              border-color: $blue;
              background-color: $blue;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
              color: $white;
              border-color: $blue;
              background-color: $blue;
            }
          }
        }
        .minima {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 15%;
          background-color: #ececec;
          /* border-radius: 8px; */
          padding: 5px;
          text-align: center;

          .form-group {
            position: relative;
            left: 0.4em;
          }
          fieldset,
          label {
            margin: 0;
          }
          label {
            font-size: 0.8em;
          }
        }
        /* .nivel-hab {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          width: 75%;
          padding: 0.5em 1em;
          label {
            font-size: 0.9rem;
          }
        } */
      }
      .progress {
        background-color: $white;
        height: 0.7em;
        .progress-bar {
          background-color: $white;
          &.active {
            background-color: #6adad7;
          }
          &:hover {
            background-color: #6adad7;
          }
        }
        .progress-bar:first-child {
          margin-right: 0.2em;
        }
        .progress-bar:last-child {
          margin-left: 0.2em;
        }
        .progress-bar:not(:first-child):not(:last-child) {
          margin: 0 0.2em;
        }
      }
    }
    .check-competencias-minimas {
      display: flex;
      padding: 0 5em;
      margin: 2em 0;
      .form-group {
        margin: 0;
      }
    }
    .buttons-footer {
      margin: 4em 0 1em;
    }
  }
}

// Media Queries
@include media("<desktop") {
  .title {
    font-size: 2em !important;
  }
}
@include media(">desktop") {
  .title {
    font-size: 2em !important;
  }
  .box-data,
  .box-questions {
    font-size: 0.8em;
  }
}
@include media(">large") {
  .box-data {
    font-size: 1.4em;
  }
}
</style>

<style lang="scss">
  .nivel-hab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 75%;
    padding: 0.5em 1em;
    label {
      font-size: 0.9rem !important;
      margin-bottom: 0.4rem;
    }
  }
</style>

