
<template>
  <transition name="fade">
  <div class="container-children flex-column">
    <!-- Mobile header -->
    <div class="vagas-list-header-mobile d-md-none">
      <div class="row">
        <div class="col-md-12 p-4 d-flex flex-column align-items-start justify-content-center">
          <label>{{$t("Show only")}}:</label>
          <b-button-group size="sm" class="bg-white">
            <button type="button" @click="toggleStatus('ALL')" :class="{
                                      'btn': true,
                                      'btn-info': forAll,
                                      'btn-outline-secondary': !forAll
                                    }">{{$t("All")}}</button>
            <button type="button" @click="toggleStatus('ATIVO')" :class="{
                                      'btn': true,
                                      'btn-info': hasActive,
                                      'btn-outline-secondary': !hasActive
                                    }">{{$t("Actives")}} ({{numberActives}})</button>
            <button type="button" @click="toggleStatus('INATIVO')" :class="{
                                      'btn': true,
                                      'btn-info': hasInactive,
                                      'btn-outline-secondary': !hasInactive
                                    }">{{$t("Inactives")}} ({{numberInactives}})</button>
            <button type="button" @click="toggleStatus('ENCERRADA')" :class="{
                                      'btn': true,
                                      'btn-info': hasClosed,
                                      'btn-outline-secondary': !hasClosed
                                    }">{{$t("In Closure")}} ({{numberCloseds}})</button>
            <b-dropdown size="sm" right variant="outline-secondary" no-caret>
              <template v-slot:button-content>&#9679;&#9679;&#9679;</template>
              <b-dropdown-form>
                <checkbox-input :accepted="1" :not_accepted="0" v-model="confidencial" class="dropdown-text">
                  {{$t("Confidentials")}} ({{numberConfidencials}})
                </checkbox-input>               
                <checkbox-input :accepted="1" :not_accepted="0" v-model="restrita" class="dropdown-text">{{$t("Restricteds")}} ({{numberRestricteds}})</checkbox-input>
                <checkbox-input :accepted="1" :not_accepted="0" v-model="cega" class="dropdown-text">{{$t("Anonymous")}} ({{numberBlinds}})</checkbox-input>
                <b-button variant="primary" size="sm" @click="reloadSearch(dropdownForm);">Ok</b-button>
              </b-dropdown-form>
            </b-dropdown>
          </b-button-group>
        </div>
      </div>
    </div>
    <div class="vagas-list p-0 p-md-5">
      <div class="list">
        <!-- Title header -->
        <div class="row d-none d-md-flex mb-3">
          <div class="col-md-12 py-3 py-md-0 px-0 mb-4">
            <h1 class="text-center text-md-left">{{$t("My interviews")}}</h1>
          </div>
          <div class="col-md-4 px-0">
            <b-form @submit.prevent="reloadSearch">
              <b-input-group size="sm">
                <template v-slot:prepend>
                  <b-input-group-text>
                      <i class="fa fa-search"></i>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="inline-form-input-search"
                  :placeholder="$t('Position')"
                  v-model="search"
                ></b-form-input>
                <template v-slot:append>
                    <b-button variant="blue" type="submit">
                      {{$t('Search')}}
                    </b-button>
                </template>
              </b-input-group>
            </b-form>
          </div>
          <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end align-items-center p-0"> 
            <b-button-group size="sm">
              <b-button variant="none" @click="toggleStatus('ALL')" :class="{
                                      'btn-info': forAll,
                                      'btn-outline-secondary': !forAll
                                    }">{{$t("All")}} </b-button>
              <b-button variant="none" @click="toggleStatus('ATIVO')" :class="{
                                      'btn-info': hasActive,
                                      'btn-outline-secondary': !hasActive
                                    }">{{$t("Actives")}} ({{numberActives}})</b-button>
              <b-button variant="none" @click="toggleStatus('INATIVO')" :class="{
                                      'btn-info': hasInactive,
                                      'btn-outline-secondary': !hasInactive
                                    }">{{$t("Inactives")}} ({{numberInactives}})</b-button>
              <b-button variant="none" @click="toggleStatus('ENCERRADA')" :class="{
                                      'btn-info': hasClosed,
                                      'btn-outline-secondary': !hasClosed
                                    }">{{$t("In Closure")}} ({{numberCloseds}})</b-button>
              <b-dropdown size="sm" right variant="outline-secondary" no-caret ref="dropdownLarge">
                <template v-slot:button-content>&#9679;&#9679;&#9679;</template>
                <b-dropdown-form>
                  <checkbox-input :accepted="1" :not_accepted="0" v-model="confidencial" class="dropdown-text">
                    {{$t("Confidentials")}} ({{numberConfidencials}})
                  </checkbox-input>               
                  <checkbox-input :accepted="1" :not_accepted="0" v-model="restrita" class="dropdown-text">{{$t("Restricteds")}} ({{numberRestricteds}})</checkbox-input>
                  <checkbox-input :accepted="1" :not_accepted="0" v-model="cega" class="dropdown-text">{{$t("Anonymous")}} ({{numberBlinds}})</checkbox-input>
                  <b-button variant="primary" size="sm" @click="reloadSearch(dropdownForm);">Ok</b-button>
                </b-dropdown-form>
              </b-dropdown>

              <b-dropdown :text="$t('Create Job')" variant="blue" class="text-uppercase my-3 my-md-0 ml-3">
                <b-dropdown-item v-if="!simpleJobOnly" :to="{ path:'/my-jobs-openings/new', hash: '#new-jobs', query: {lang: $i18n.locale} }">
                    <i class="fas fa-plus-square mr-2"></i>
                    {{$t("Complete")}}
                </b-dropdown-item>
                <b-dropdown-item :to="{ path:'/my-jobs-openings/new-simple', hash: '#new-jobs-simple', query: {lang: $i18n.locale} }">
                    <i class="fas fa-plus-square mr-2"></i>
                    {{$t("Simplified")}}</b-dropdown-item>
              </b-dropdown>

             
              <b-button @click="$bvModal.show('modalImportJobsGupy')" 
                v-if="hasImportFromGupy"
                variant="pink"
                v-b-tooltip.hover
                :title="$t('Import Job') + ' Gupy'" >
                <i class="fas fa-file-import"></i>
              </b-button>
              <b-button @click="$bvModal.show('modalImportJobsGreenhouse')" 
                v-if="hasImportFromGreenhouse"
                variant="outline-green"
                v-b-tooltip.hover
                :title="$t('Import Job') + ' Greenhouse'" >
                <i class="fas fa-file-import"></i>
              </b-button>
              <b-button @click="$bvModal.show('modalImportJobsSAP')" 
                v-if="hasImportFromSAP"
                variant="outline-primary"
                v-b-tooltip.hover
                :title="$t('Import Job') + ' SAP'" >
                <i class="fas fa-file-import"></i>
              </b-button>
            </b-button-group>
          </div>
        </div>
        <template>
          <transition-group name="slide-fade">
            <div class="list__vagas" v-for="(v, key) in list" :key="'myjobboard_'+key">
              <vaga 
                v-model="list[key]"
                :hasRSToken="hasRSToken"
                :saldo-total="saldo"
                :showCopyFor="showCopyFor" 
                @ativada="ativaCallback"
                @encerrada="encerraCallback"
                @excluida="excluiCallback"
                @publicada="publicaCallback"
                @impulsionada="impulsionadaCallback"
                @inativada="inativaCallback"
                @modalVagaShare="showModalVagaShare"
                @showModalAddJobcoins="showModalAddJobcoins"
                @showModalFeedback="showModalFeedback"
              ></vaga>
            </div>
          </transition-group>
        </template>
        
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Large Spinner" type="grow" class="mt-5" variant="primary"></b-spinner>
        </div>
        <!-- nenhuma vaga encontrada -->
        <div class="img-no-jobs container text-center" v-show="(!list || list.length == 0 || nothingFound) && !loading ">
          <router-link
            :to="{
              path: simpleJobOnly ? '/my-jobs-openings/new-simple' : '/my-jobs-openings/new',
              hash: '#new-jobs',
              query: {lang: $i18n.locale}
            }"
          >
            <img src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/job-img.png" alt="">
            <div><p>{{$t("Create new job")}}</p></div>
          </router-link>
        </div>

        <b-row v-if="!loading && list.length>0 && hasMore">
          <b-col class="text-center">
            <b-button
              v-if="hasMore"
              pill
              variant="blue"
              class="mt-4 px-5 py-2 mb-5 button-bottom"
              large
              @click="showMore"
              :disabled="loading"
              >
                {{ $t("Show more") }} <i class="fa fa-angle-double-down"></i>
            </b-button>
          </b-col>
      </b-row>
      <b-row v-if="!loading && list.length>0 && !hasMore && page > 1">
          <b-col class="text-center">
            <p class="text-muted "> 
              {{$t("Sorry, this is all we got")}}
            </p>
          </b-col>
      </b-row>
      </div>
    </div>
    <!-- Modal de compartilhamento em massa -->
    <vaga-share 
      :id="'modalVagaShare'" 
      :job="currentVagaContext" 
      @vagaShare="hideModalVagaShare"
    ></vaga-share>

    <!-- Modal adicionar jobcoins -->
    <modal-add-jobcoins
      id="modalAddJobcoins"
      :vaga="currentVagaContext"
      @hideModalAddJobcoins="hideModalAddJobcoins"/>

    <!-- Modal Feedback da Vaga -->
    <modal-feedback id="modalFeedback"/>
    <modal-import-jobs-gupy />
    <modal-import-jobs-greenhouse />
    <modal-import-jobs-sap />
    
    
  </div>
  </transition>
</template>
<script lang="ts">
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import ModalAddJobcoins from '@/components/modal/ModalAddJobcoins.vue';
import ModalFeedback from "@/components/modal/ModalFeedback.vue";
import ModalImportJobsGreenhouse from "@/components/modal/ModalImportJobsGreenhouse.vue";
import ModalImportJobsGupy from "@/components/modal/ModalImportJobsGupy.vue";
import ModalImportJobsSAP from "@/components/modal/ModalImportJobsSAP.vue";
import ModalVagaShare from '@/components/modal/ModalVagaShare.vue';
import VagaShareService from "@/core/Services/Basic/VagaShare";
import GestaoHCService from "@/core/Services/Empresa/GestaoCredito";
import GestaoVagaService from "@/core/Services/Empresa/GestaoVaga";
import { EmpresaEntity, VagaEntity } from "@/core/models/Entities";
import Vaga from '@/views/vagas/Vaga.vue';
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import VagasSearchMixin from './VagasSearchMixin';


@Component({
  components: {
    'button-icon': ButtonIcon,
    'vaga-share': ModalVagaShare,
    'modal-add-jobcoins': ModalAddJobcoins,
    'modal-feedback': ModalFeedback,
    ModalImportJobsGupy,
    ModalImportJobsGreenhouse,
    'modal-import-jobs-sap': ModalImportJobsSAP,
    Vaga,
  },
  mixins: [VagasSearchMixin]
})
export default class MinhasVagas extends Vue {
  public service: GestaoVagaService = new GestaoVagaService();
  public list: VagaEntity[] = [];
  public responseKey: string = 'vagas';
  public status: any[] = ['ATIVO'];
  public currentVagaContext: VagaEntity = new VagaEntity()
  public vagaShareService = new VagaShareService();
  public historicoCreditoService = new GestaoHCService();
  public saldo: number = 0;
  public vagas : any = []

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  get simpleJobOnly () {
    return (this.empresa?.configuracoes?.ENABLE_SIMPLE_JOB_ONLY == true);
  }

  get hasImportFromGupy () {
    return (this.empresa?.configuracoes?.GUPY_AUTH_KEY == true);
  }

  get hasImportFromGreenhouse () {
    return (this.empresa?.configuracoes?.GREENHOUSE_JOB_BOARD_ENABLED == true);
  }

  get hasImportFromSAP () {
    return (this.empresa?.configuracoes?.ENABLE_SF_JOB_IMPORT == true);
  }

  public preventDefault(e: Event) {
    e.preventDefault();
  }

  public mounted() {
    this.historicoCreditoService = new GestaoHCService();
    this.historicoCreditoService
      .fetchAll({ saldOnly: true })
      .then((data: any) => {
        this.saldo = data.saldo;
      })
      .catch(err => {
        console.log(err);
      });
  }

  public dropdownForm() {
    (<any>this.$refs.dropdownLarge).hide(true);
  }

  public ativaCallback(vaga: any) {
    // alter vaga status
  }

  public encerraCallback(id: number) {
    let index = this.list.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    if (this.status.indexOf('ENCERRADA') === -1) {
      if (index>-1)
      this.list.splice(index,1)
    } else {
      this.list[index].status = 'ENCERRADA';
    }
  }

  public excluiCallback(id: any) {
    let index = this.list.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    if (this.status.indexOf('EXCLUIDA') === -1) {
      if (index>-1)
      this.list.splice(index,1)
    } else {
      this.list[index].status = 'EXCLUIDA';
    }
  }

  public publicaCallback(id: number) {
    let index = this.list.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    if (this.status.indexOf('ATIVO') === -1) {
      if (index>-1)
      this.list.splice(index,1)
    } else {
      this.list[index].status = 'ATIVO';
    }
  }

  public impulsionadaCallback(id: number, status) {
    let index = this.list.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    this.list[index].statusImpulsao = (status == 'a' ? 'ATIVO' : 'INATIVO');
  }

  public inativaCallback(id: number) {
    let index = this.list.findIndex((vaga: VagaEntity) => {
      return vaga.id == id;
    });
    if (this.status.indexOf('INATIVO') === -1) {
      if (index>-1)
      this.list.splice(index,1)
    } else {
      this.list[index].status = 'INATIVO';
    }
  }

  public showModalFeedback() {
    this.$bvModal.show('modalFeedback');
  }

  public showModalVagaShare(vaga: any) {
    this.currentVagaContext = vaga
    this.$bvModal.show('modalVagaShare')
  }

  public hideModalVagaShare() {
    this.$bvModal.hide('modalVagaShare')
  }

  public showModalAddJobcoins(vaga: any) {
    this.currentVagaContext = vaga
    this.$bvModal.show('modalAddJobcoins')
  }

  public hideModalAddJobcoins(data: any) {
    const _this: any = this;
    _this.reloadSingle(this.currentVagaContext.id);
    this.$bvModal.hide('modalAddJobcoins')
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.vagas-list {
  background-color: white;
  padding: 1.5em;
  width: 100%;
  border-radius: 5px;
  .dropdown-text {
    font-size: 0.8em;
  }
  .list {
    .btn-sm {
      line-height: 1;
    }
    h1 {
      font-size: 2.5em;
    }
    &__vagas {
      margin-bottom: 1em;
    }
  }
}

.img-no-jobs {
  img {
    width: 600px;
  }
  p {
    font-size: 24px;
    color: cornflowerblue;
  }
}
</style>