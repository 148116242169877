import { render, staticRenderFns } from "./RootPublicCleanArea.vue?vue&type=template&id=2819c23f&scoped=true"
import script from "./RootPublicCleanArea.vue?vue&type=script&lang=ts"
export * from "./RootPublicCleanArea.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2819c23f",
  null
  
)

export default component.exports