<template>
  <aside
    class="sidebar"
    v-bind:style="{ backgroundImage: getBackgroundImage }"
  >
    <header class="sidebar__header"  @click="gotoHome">
      <logo-jobcam :inverse="true"></logo-jobcam>
    </header>
    <nav class="sidebar__nav">
      <slot></slot>
    </nav>
    <footer class="sidebar__footer">
      <small>JOBECAM © 2019</small>
    </footer>
  </aside>
</template>

<script lang="ts">
import Logo from '@/components/logo/Logo.vue';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    'logo-jobcam': Logo,
  },
})
export default class TheSidebar extends Vue {
  @Prop({ default: null }) public backgroundAvatar!: string;

  get getBackgroundImage() {
    return 'linear-gradient(180deg, rgb(15, 217, 251, 1) 8%, rgb(0, 111, 243, 1) 64%)';
  }

  public gotoHome() {
    this.$router.push({path:'/', query: {lang: this.$i18n.locale }});
  }
}
</script>

<style lang="scss" scoped>

$sidebar-width-size: 15.5em;

%sidebar-config-defaults {
  padding: 16px 16px 5px 16px;
  flex: 0;
  align-self: center;
  text-align: center;
}

/**
** Sidebar component
**/
.sidebar {
  position: absolute;
  left: -$sidebar-width-size;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: $sidebar-width-size;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  color: var(--white);

  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: left 0.5s ease-in-out;

  @media (min-width: 992px) {
    position: relative;
    left: 0 !important;
  }

  &__header {
    @extend %sidebar-config-defaults;
    cursor: pointer;
  }

  &__nav {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    @extend %sidebar-config-defaults;
  }
}
</style>