<template>
  <contact-for-plan></contact-for-plan>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PlanCard from "@/components/PlanCard.vue";
import ExtratoAtual from './partials/ExtratoAtual.vue';
import SaldoAtual from './partials/SaldoAtual.vue';
import PlanoAtual from './partials/PlanoAtual.vue';
import ContactForPlan from './../../components/expiredPlan/ContactForPlan.vue'

@Component({
  components: {
    ExtratoAtual,
    SaldoAtual,
    PlanoAtual,
    ContactForPlan
  }
})
export default class Extrato extends Vue {

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
</style>
