<template>
  <div class="jobecoins">
    <header class="d-flex align-items-center justify-content-center mb-4">
      <div class="tabs-wrapper">
        <button
          v-for="(title, page) of carouselPagesTitles"
          class="tab"
          :class="{ 'selected': page === carouselCurrentPage }"
          @click="goToCarouselPage(page)"
        >
          {{ $t(title) }}
        </button>
      </div>
    </header>

    <div class="carousel-values d-flex flex-row align-items-center" v-if="carouselCurrentPage === 0">
      <div class="carousel-value w-50 mr-2">
        <p>{{ $t('Recorded') }}</p>
        <span>{{ totalJobcoinsDebitados || 0 }}</span>
      </div>

      <div class="carousel-value w-50 m-0">
        <p>{{ $t('Resets') }}</p>
        <span>{{ value.qtdResets || 0 }}</span>
      </div>
    </div>

    <div
      class="carousel-values d-flex align-items-center justify-content-between"
      v-if="carouselCurrentPage === 1"
    >

      <div
        v-for="(applications,i) in applicationsGroups"
        :key="i"
        class="d-flex flex-column carousel-value-wrapper"
      >
        <div
          v-for="({ key, title, colorTheme }) in applications"
          class="carousel-value"
          :class="{ [colorTheme]: true }"
        >
          <p>{{ $t(title) }}</p>

          <span>
            {{ value.candidaturas[key] ? value.candidaturas[key].padStart(2, '0') : '00' }}
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VagaEntity } from "@/core/models/Entities";
import GestaoVagaService from "@/core/Services/Empresa/GestaoVaga";
import { ApexOptions } from 'apexcharts';

@Component
export default class AdicionarJobcoins extends Vue {
  public id: string = '';

  @Prop()
  public value!: VagaEntity;
  
  @Prop({ default: 0 })
  public saldoTotal!: number;

  public minCoins: number = 0;
  // public saldoVaga: number = 0
  public jobcoinsUsed: number|null = null;
  public vagasService = new GestaoVagaService();
  public creditVaga: any = new Map<string,number>();
  public series: any[] = [];

  public carouselPagesTitles = ['Interviews', 'Applications'];
  public carouselCurrentPage = 0;

  public chartOptions: any = {};

  public applicationsGroups = [
    [
      { key: 'total', title: 'Applications', colorTheme: 'primary' },
      { key: 'aprovados', title: 'Approveds', colorTheme: 'green' },
    ],
    [
      { key: 'videospendentes', title: 'Pendings', colorTheme: 'orange-light' },
      { key: 'reprovados', title: 'Reproveds', colorTheme: 'gray' },
    ],
    [
      { key: 'emanalise', title: 'Analysis', colorTheme: 'dark-info' },
      { key: 'repescagem', title: 'Waiting list', colorTheme: 'pink' },
    ],
  ]

  constructor() {
    super();
    this.id = 'chartjs'+(new Date()).getTime()+''+(Math.random()*1000);
  }

  @Watch('value.historicoCredito')
  public reloadChart() {
    console.log('reeloadvalues')
    //this.series = [this.getPositionPercentage];
    
    this.series = [
      { name: this.$t('Balance'), data: [ this.trueValue||0 ] }, 
      { name: this.$t('Recorded'), data: [ this.totalJobcoinsDebitados||0 ] }, 
      { name: this.$t('Resets'), data: [ this.value.qtdResets||0 ] }
    ];

  }

  public mounted() {
     this.chartOptions = {
            chart: {
              toolbar: {
                show: false,
              },
              type: 'bar',
              width: 350,
              height: 100,
              stacked: true,
              events: {
                  dataPointMouseEnter: function (a, b) {
                    document.getElementById(a.target.instance.node.id)?.setAttribute('fill-opacity', '0.2')
                  },
                  dataPointMouseLeave: function (a, b) {
                    document.getElementById(a.target.instance.node.id)?.setAttribute('fill-opacity', '1')
                  }
                },
            },
            grid: {
              show: false,
              // borderColor: '#fff',
              xaxis: {
                  lines: {
                      show: false,
                  }
              },                 
              yaxis: {
                  lines: {
                      show: false,
                  }
              },                
            },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '300px'
              },
            },
            title: {
              text: this.$i18n.t('Interviews'),
              align: 'center',
              style: {
                fontSize:  '14px',
                fontWeight:  'normal',
                fontFamily:  undefined,
                color:  '#263238'
              },              
            },            
            stroke: {
              width: 2,
              colors: ['#fff']
            },
            xaxis: {
              categories: [this.$i18n.t('Interviews')],
              show: false,
              labels: {
                show: false,
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },              
            },
            yaxis: {
              show: false,
              labels: {
                show: false,
              },
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              fixed: {
                  enabled: true,
                  position: 'bottomRight',
                  offsetX: -50,
                  offsetY: -30,
              },
            },
      };
    this.reloadChart()
  }


  
  public showModalEmit() {
    this.$emit('showModal', this.value)
  }

  get getPosition() {
    if (!this.trueValue)
      return 'left:0%'
    const percent = ((100*(this.trueValue)||0)/this.getTotalCreditosVaga(this.value));
    return ( (percent >= 90 ) ? `left:90%` : `left:${percent}%`);
  }
  
  get getTotalEntrevistas() {
      return  this.saldoTotal> 0 ? Math.round(this.saldoTotal/(this.value.multiplicadorJobcoins||1)) : 0;
  }

  
  get getPositionPercentage() {
    if (!this.trueValue)
      return 0;

    const percent = ((100*(this.trueValue)||0)/this.getTotalCreditosVaga(this.value));
    return percent;
  }  

  get limited(): boolean {
    return this.value.limited == 1 || (this.getSaldoCreditosVaga(this.value) > 0);
  }

  get totalJobcoinsDebitados() {
     let total = 0
    this.value.historicoCredito.forEach((element: any,index: number) => {
      total = ((element.tipo == "S") ? total-element.creditoQtd : total );
      
    });
    return parseInt(((-1)*total)+"");
  }
  get saldoRestanteJobcoinsDebitados() {
     let total = 0
    this.value.historicoCredito.forEach((element: any,index: number) => {
      total = (element.tipo == "E") ? total+element.creditoQtd : ((element.tipo == "S") ? total-element.creditoQtd : total );
    });
    return parseInt(""+total);
  }

  public getTotalCreditosVaga(vaga: VagaEntity) {
    let total = 0;
    if (vaga.limited==1 || (this.getSaldoCreditosVaga(this.value) > 0)) {
      vaga.historicoCredito.forEach((element: any,index: number) => {
        if (element.tipo == "E") {
          total+=element.creditoQtd;
        }
      });
    } else if (this.getSaldoCreditosVaga(vaga) <= 0)  {
      total = parseInt(""+this.saldoTotal)+Math.abs(this.getSaldoCreditosVaga(vaga));
    } else {
      total = this.getSaldoCreditosVaga(vaga);
    }

    if (this.getDebitosVaga(vaga) < 0 && Math.abs(this.getDebitosVaga(vaga)) > total) {
      total = Math.abs(this.getDebitosVaga(vaga));
    }

    return total;
  }

  get trueValue() {
    if (this.value.limited == 0 && (this.getSaldoCreditosVaga(this.value) < 0)) {
      return this.saldoTotal;
    }
    let v: number = (this.getSaldoCreditosVaga(this.value) > 0 ) ? this.getSaldoCreditosVaga(this.value) : this.getDebitosVaga(this.value);
    return v < 0 ? 0 : v;
  }

  // Saldo = total debitado
  public getSaldoCreditosVaga(vaga: VagaEntity) {
    let total = 0
    vaga.historicoCredito.forEach((element: any,index: number) => {
      total = (element.tipo == "E") ? total+element.creditoQtd : ((element.tipo == "S") ? total-element.creditoQtd : total );
    });
    return parseInt(""+total);
  }

  public getDebitosVaga(vaga: VagaEntity) {
    let total = 0;
    vaga.historicoCredito.forEach((element: any,index: number) => {
      total = parseInt(""+((element.tipo == "S") ? total-element.creditoQtd : total ));
    });
    if (vaga.limited == 0 && this.getSaldoCreditosVaga(vaga) <= 0) {
      total = parseInt(""+this.saldoTotal)+ total;
    }
    return total;
  }

  public goToCarouselPage(page: number) {
    this.carouselCurrentPage = page;
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.jobecoins {
  // border-top-right-radius: 8px;
  width: 50%;
  max-width: 600px;

  @media screen and (max-width: 1260px) {
    width: 55%;
  }

  @media screen and (max-width: 1170px) {
    width: 62%;
    max-width: 1000px;
  }

  label {
    font-size: 1em;
    line-height: 1em;
    margin: 0;
  }

  .fa-plus{
    vertical-align: text-bottom;
  }

  &__button-col {
    padding: 0;
    text-align: center;
  }

  &__range-col {
    padding-left: 0;
  }

  .range_scope {
    position: relative;
  }
  .range_min {
    position:absolute;
    left: 0;
    color: gray;
  }
  .range_max {
    position: absolute;
    right: 0;
    text-align: right;
    color: gray;
  }
  .range_value {
    position: absolute;
    right: 0;
    top: -60px;
    font-size: 1.5em;
    color: green;
  }
}

@include media("<desktop") {
  .jobecoins {
  // border-top-right-radius: 8px;
    label {
      font-size: 0.9em;
    }
  }
}
@include media(">desktop", "<=large") {
  .jobecoins {
  // border-top-right-radius: 8px;
    label {
      font-size: 0.8em;
    }
  }
}

@include media(">large") {
  
  // .jobecoins {
  // // border-top-right-radius: 8px;
  //   label {
  //     // font-size: 0.6em;
  //   }
  // }
}

header {
  h1 {
    font-size: 1.2em;
    color: $dark;
  }

  .circle-button {
    border-radius: 100%;
    padding: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    font-size: 0.6em;
    width: 20px;
    height: 20px;
  }

  .tabs-wrapper {
    background: $blue;
    border-radius: 50rem;
  }

  button.tab {
    padding: 0 16px;
    background: darken($blue, 15);

    border: 3px $blue solid;
    border-radius: 50rem;
    color: $white;

    &.selected {
      background: $white;
      color: $blue;
    }
  }

  @media screen and (max-width: 1070px) {
    margin-bottom: 8px !important;
  }
}

.carousel-values .carousel-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $blue;
  border: $blue 1px solid;
  color: $white;
  border-radius: 50rem;
  padding: 4px 14px;

  p {
    margin: 0 4px 0 0;
  }

  span {
    background: $white;
    color: $dark;
    border-radius: 50rem;
    padding: 0 14px;
  }

  $carouselColorThemes: (
    'primary': $blue,
    'danger': $danger,
    'green': $green,
    'orange-light': $orange-light,
    'green-light': $green-light,
    'pink': $pink,
    'dark-info': $dark-info,
    'gray': $gray,
  );

  @each $colorTheme, $color in $carouselColorThemes {
    &.#{$colorTheme} {
      background: #{$color};
      border: #{$color} 1px solid;


      &.outline {
        color: #{$color};
        background: none;

        span {
          background: #{$color};
          color: $white;
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.carousel-values {
  .carousel-value {
    animation: fadein 0.5s;
  }

  .carousel-value + .carousel-value {
    margin-top: 4px;
  }

  .carousel-value-wrapper + .carousel-value-wrapper {
    margin-left: 4px;
  }

  .carousel-value-wrapper {
    display: flex;
    flex: 1;
  }
}

.pill {
  border-radius: 50rem;
}
</style>