<template>
  <div :class="{'candidato-in-list row':true, 'seen': seenCandidate, 'selectedCandidate': currentSelected}">
    <div class="col-1 col-md-1 d-flex justify-content-center align-items-center candidato-in-list__check">
      <b-form-checkbox 
        v-model="value.checked" 
        @input="checkbox"></b-form-checkbox>
    </div>
    <div class="col-2 col-md-2 d-flex justify-content-center align-items-center candidato-in-list__image" @click.prevent="selected">
      <img :src="value.candidato.fotoAvatar" v-if="value.candidato.fotoAvatar && value.candidato.fotoAvatar.indexOf('media.licdn.com')==-1"/>
      <img src="/static/img/avatar.png" alt v-else/>
      <div class="candidato-in-list__image--status-btn" 
          v-b-tooltip.hover
          :title="$t(tooltipTitle)"
          v-if="value.status">
        <button-icon-rounded
          color="white"
          :border="btnStatusColor"
          fontSize="0.7rem"
          :back="btnStatusColor"
          :backHover="btnStatusColor"
          class=""
        >
          <span :class="iconType"></span>
        </button-icon-rounded>
      </div>
    </div>
    <div class="candidato-in-list__stars col-7 col-md-7 d-flex flex-column " @click.prevent="selected">
      <div class="text-truncate" v-if="nomeCandidatoAnonimo">
        {{nomeCandidatoAnonimo}}
      </div>
      <div class="text-truncate" v-else-if="nomeCandidato">{{nomeCandidato}}</div>
      <span class="text-gray m-0 d-flex justify-content-between">
        <i v-if="nomeCandidatoAnonimo">{{ value.id }}</i> 
        <i class="text-truncate" v-else>{{ value.candidato.email }}</i> 
        <div class="icon-warnings d-flex justify-content-end align-items-center">
          <div class="icon-warnings-score" v-if="value.avgRating" v-b-tooltip.hover :title=" $t('Score') +' '+value.avgRating">
            <small><i class="fas fa-star text-warning " v-for="item in avgArr" :key="item" :style="'left:'+(item*6)+'px;z-index:'+item"></i></small>
          </div>
          <div class="icon-warnings-match d-flex align-items-center" v-if="value.matchCompany && value.matchCompany.percentage" v-b-tooltip.hover :title="$t('{percentage}% of Match',{ percentage: value.matchCompany.percentage})">
            <small>{{value.matchCompany.percentage}}
              <i :class="'fas fa-percentage mr-1 text-'+currentVariant" ></i>
            </small>
            
          </div>
          <div class="icon-warnings-last-eval" v-if="viewsObject && viewsLastEval">
            <small><i class="fas fa-eye text-info"  v-b-tooltip.hover :title="$t('Evaluator')+ ': ' +viewsLastEval"></i></small>
          </div>
        </div>
      </span>
    </div>
    <div class="candidato-in-list__score col-2 col-md-2 d-flex flex-column justify-content-center align-items-center">
      <span>{{value.score||0}}</span>
      <label class="text-uppercase" >score</label>
    </div>

    <div class="bottom-info" >
      <!-- <div class="bottom-info__item" v-if="value.dataAtualiza">
        {{ dateOnly(value.dataAtualiza) }}
      </div> -->
    </div>    
  </div>
</template>

<script lang="ts">
import { CandidaturaEntity, EmpresaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class CandidatoInList extends Vue {

  private btnStatusColor: string = '';
  private iconType: string = '';
  private tooltipTitle: string = '';

  public checkedValues: [] = []
  public checked: boolean = false;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public value!: CandidaturaEntity;

  @Prop({default: false})
  public currentSelected!: boolean;
  
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;

  @Watch('value')
  public handleChange() {
    this.statusCandidato()
  }

  get showGP() {
    return (!this.empresa?.configuracoes?.HIDE_DIVERSITY_MODAL);
  }

  get avgArr() {
    return [...Array(+(this.value.avgRating||0)).keys()]
  }

  get gruposPertencimento() {
    let str: string = "";

    if (this.value.candidato.perfil.grupoLgbt && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += 'LGBTQIA+ ';
    if (this.value.candidato.perfil.grupoIndiginas && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('Indigenous Community').toString()+ ' ';
    if (this.value.candidato.perfil.grupoRefugiados && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('Refugee and Immigrants').toString()+ ' ';
    if (this.value.candidato.perfil.grupoMovBlack && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('Afro-descendants Community').toString()+ ' ';
    if (this.value.candidato.perfil.grupoPCD && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('PWD Group').toString()+ ' ';
    if (this.value.candidato.perfil.grupoEmpoderaFeminino && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('Womens Movement').toString()+ ' ';
    if (this.value.candidato.perfil.grupo3Idade && this.value.candidato.perfil.aceiteDadosSensiveis)
      str += this.$t('Senior citizen group').toString()+ ' ';

    return str;
  }

  public checkbox() {
    this.$emit('checkbox', this.value.id, this.value.checked)
  }

  public selected() {
    this.$emit('selected', this.value, false);
  }

  public mounted() {
    this.$set(this.value, 'checked', false);
    this.statusCandidato()
  }

  get nomeCandidato() {
    return (this.value.candidato.primeironome) ? (this.value.candidato.primeironome+' '+this.value.candidato.sobrenome) : this.value.id;
  }

  get nomeCandidatoAnonimo() {
    return (this.value.status == 'APROVADO') ? null : (this.value.anonymousName ? this.$t(this.value.anonymousName) : null);
  }

  get currentVariant() {
    let variant: string = 'success'
    if (this.value.matchCompany && this.value.matchCompany.percentage) {
      const v: number = this.value.matchCompany.percentage;
      if ( v > 60 && v < 80) {
        variant = 'info'
      } else if ( v > 80 ) {
        variant = 'primary'
      } else if ( v < 40 ) {
        variant = 'warning'
      }
    }
    return variant;
  }

  get seenCandidate() {
    return (this.value.status == "EMANALISE" && (typeof this.value.views == 'number') ? this.value.views > 0 : this.value.views?.length)
  }

  get viewsObject() {
    return (typeof this.value.views == 'object')
  }

  get viewsLastEval() {
    return (this.viewsObject && this.value.views != null) ? this.value.views[this.value.views?.length-1]?.who : ''
  }
  public statusCandidato() {
    if(this.value.checked === undefined) {
      this.value.checked = false;
    }
    const status = this.value.status;
    if (status == "REPROVADO") {
      this.btnStatusColor = "#dc3545" 
      this.iconType = "icon icon-reprovados"
      this.tooltipTitle = 'Reproved'
    }

    if (status == "APROVADO") {
      this.btnStatusColor = "#1e7e34"
      this.iconType = "icon icon-aprovados"
      this.tooltipTitle = 'Approved'
    }

    if (status == "EMANALISE") {
      this.btnStatusColor = "#007bff"
      this.iconType = "icon icon-analise"
      this.tooltipTitle = 'In Analysis'
    }

    if (status == "VIDEOSPENDENTES") {
      this.btnStatusColor = "#e83e8c"
      this.iconType = "icon icon-interrogao"
      this.tooltipTitle = 'Pending'
    }

    if (status == "VIDEOSEMPROCESSAMENTO") {
      this.btnStatusColor = "rgba(137,125,249,1)"
      this.iconType = "icon icon-trocar"
      this.tooltipTitle = 'Processing'
    }

    if (status == "EXAMESPENDENTES") {
      this.btnStatusColor = "#e83e8c"
      this.iconType = "fas fa-tasks"
      this.tooltipTitle = 'Pendant test'
    }
    
    if (status == "EXAMESEMANALISE") {
      this.btnStatusColor = "#007bff"
      this.iconType = "fas fa-tasks"
      this.tooltipTitle = 'Analysis Test'
    }
    
    if (status == "EXAMESREPROVADO") {
      this.btnStatusColor = "#dc3545" 
      this.iconType = "fas fa-tasks"
      this.tooltipTitle = 'Reproved in Test'
    }
  }
}
</script>

<style lang="scss" scoped>
  .candidato-in-list {
    border-radius: 10px;
    padding: 1em 0;
    margin: 0;
    &__check,
    &__image,
    &__stars,
    &__score {
      padding: 0 !important;
    }
    &__image {
      position: relative;
      display: flex;
      // object-fit: cover;
      border-radius: 200px;
      cursor: pointer;
      max-width: 85px;
      height: 48px;
      img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 48px;
        height: 48px;
      }
      &--status-btn {
        position: absolute;
        bottom: -3px;
        left: 7px;

        button {
          padding: 0;

          .icon:before {
            vertical-align: middle;
          }
        }
      }
    }
    &__stars {
      span {
        font-size: 0.7rem;
        margin: 5px;
      }
    }
    &__score {
      span {
        font-size: 1.2rem;
      }
      label {
        font-size: 0.5rem;
        margin: 0;
      }
    }
    &:hover {
      background: #dcdcdc90;
      cursor: pointer;
    }
  }
  .seen {
      background: #dcdcdc80;
      border: 1px solid #fff;
  }
  .selectedCandidate {
      background: #dcdcdc90;
      border: 1px solid var(--primary);
  }
  .icon-warnings {
    font-size: 0.9rem !important;
    &-score {
      width: 35px;
      height: 13px;
      position: relative;
      text-shadow: 0px 0px 1px black;
      i {
        position: absolute;
      }
    }
  }
  .bottom-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.8rem;
    &__item {
      text-align: right;
    }
  }
</style>
<style lang="scss">
  .candidato-in-list .info, .rating-value {
    font-size: 14px !important;
  }
  .star-rate .rating {
    font-size: 12px;
  }

  .star-rate label {
    cursor: pointer;
  }
</style>