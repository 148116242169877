<template>
  <div class="info-header">
    <b-row v-if="vaga">
      <b-col md="12" class="info-header__posicao">
        <h1>{{vaga.posicao}}</h1>
      </b-col>
      <b-col md="12" class="info-header__first-col d-none d-md-block">
        <p v-if="vaga.salario" class="info-header__first-col--salary mb-1">{{$t("Salary")}}: {{vaga.salario}}</p>
        <p v-if="vaga.localidade" class="info-header__first-col--location">{{vaga.localidade}}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VagaEntity } from "@/core/models/Entities";

@Component({
  components: {
  },
})
export default class VagaDetailHeader extends Vue {
  @Prop({default: null})
  public vaga!: VagaEntity;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.info-header {
  padding: 1em 0;
  border-bottom: 1px solid #dcdcdc;
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }
}

</style>