<template>
  <transition appear name="fade">
    <div class="dashboard_diversity">
      <!-- INICIO FILTROS  -->
      <b-row class="mb-4" v-if="!hasGuid">
        <b-col md="12">
          <card-common :no-title="true" id="filters-card">
            <template>
              <b-row class="pt-4">
                <b-col class="d-flex justify-content-between px-4">
                  <div class="report-buttons-wrapper">
                    <b-button
                      variant="green"
                      pill
                      class="mr-2"
                      @click="$bvModal.show('complete-report')"
                      v-b-tooltip.hover
                      :title="$t('Jobs Complete Report')"
                    >
                      {{ $t('Generate complete report') }}
                    </b-button>

                    <b-button
                      variant="green"
                      pill
                      @click="$bvModal.show('simple-report')"
                      v-b-tooltip.hover
                      :title="$t('Jobs Simplified Report')"
                    >
                      {{ $t('Generate simplified report') }}
                    </b-button>
                  </div>

                  <b-dropdown
                    variant="green"
                    id="report-dropdown-button"
                    :text="$t('Generate report')"
                    v-b-tooltip.hover
                    :title="$t('Generate report')"
                  >
                    <b-dropdown-item-button
                      @click="$bvModal.show('complete-report')"
                    >
                      {{ $t('Generate complete report') }}
                    </b-dropdown-item-button>

                    <b-dropdown-item-button
                      @click="$bvModal.show('simple-report')"
                    >
                      {{ $t('Generate simplified report') }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
          </card-common>
        </b-col>
      </b-row>

      <b-row class="mb-4" v-if="!hasGuid">
        <b-col md="12">
          <card-common :no-title="true" id="filters-card">
            <template>
              <b-row class="pt-4">
                <b-col class="d-flex justify-content-end px-4">
                  <b-button
                    variant="pink"
                    pill
                    @click="enableFilter = !enableFilter"
                    v-b-tooltip.hover
                    :title="!enableFilter ? $t('Show Filters') : $t('Hide Filters')"
                  >
                    <span v-if="!enableFilter">{{ $t('Show Filters') }}</span>
                    <span v-else>{{ $t('Hide Filters') }}</span>
                  </b-button>
                </b-col>
              </b-row>

              <transition appear name="slide">
                <div class="mt-4" v-if="enableFilter">
                  <b-row class="mb-3 text-left">
                    <b-col class="d-flex flex-column px-4">
                      <b-form-group
                        label-size="md"
                        :label="$t('Job')"
                        label-for="vagaSelect"
                        class="mb-0"
                      >
                        <auto-complete
                          name="vagaSelect"
                          class="text-uppercase"
                          :label="$t('vagaSelect')"
                          :get-result-value="(s) => s.text"
                          :search="search"
                          @submit="vagaId = $event.value"
                          variant="dark"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="d-flex justify-content-between align-items-end px-4" id="filter-by-date-wrapper">
                      <div>
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <label for="filterByDate" class="mr-2 mb-0 text-left">
                            {{ $t("Filter by Application Date") }}
                          </label>

                          <b-form-checkbox
                            id="filterByDate"
                            v-model="filterByDate"
                            name="check-button"
                            switch
                          >
                            <small><b>({{ filterByDate ? $t("On") : $t("Off") }})</b></small>
                          </b-form-checkbox>
                        </div>

                        <div class="d-flex date-inputs-wrapper">
                          <div class="date-input d-flex align-items-center">
                            <label class="mr-2 my-0 d-flex align-items-center">
                              {{ $t("From") }}
                            </label>
  
                            <b-form-datepicker
                              :disabled="enableFilter && !filterByDate"
                              id="input-date-pub"
                              class="input-date-pub"
                              v-model="dtIni"
                              value-as-date
                              :locale="$i18n.locale"
                              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            />
                          </div>

                          <div class="date-input d-flex align-items-center">
                            <label class="mx-2 my-0 d-flex align-items-center">
                              {{ $t("Until") }}
                            </label>

                            <b-form-datepicker
                              :disabled="enableFilter && !filterByDate"
                              id="input-date-pub-end"
                              class="input-date-pub-end"
                              v-model="dtFim"
                              value-as-date
                              :locale="$i18n.locale"
                              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            />
                          </div>
                        </div>
                      </div>

                      <div id="filters-buttons-wrapper">
                        <b-button
                          variant="outline-primary"
                          class="mr-2"
                          pill
                          @click="cleanFilter"
                        >
                          {{ $t("Clear filters") }}
                        </b-button>

                        <b-button
                          variant="primary"
                          pill
                          @click="loadAllData"
                          v-promise-btn="{ promise }"
                        >
                          {{ $t("Update Dashboard") }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </transition>
            </template>
          </card-common>
        </b-col>
      </b-row>
      <!-- FIM FILTROS  -->

      <!-- INICIO TOTALIZADORES GERAIS  -->
      <b-row class="mb-4" v-if="showDiversity">
        <b-col md="6" sm="12">
          <card-image-total
            v-model="dados.total_geral"
            title="General talents"
            srcImg="/static/img/dashboard/avatar1.png"
          />
        </b-col>

        <b-col md="6" sm="12">
          <card-image-total
            v-model="dados.total_diversidade"
            title="Diversity talent"
            srcImg="/static/img/dashboard/avatar4.png"
          />
        </b-col>
      </b-row>
      <!-- FIM TOTALIZADORES GERAIS  -->

      <!-- DESTAQUES INICIO -->
      <b-row class="mb-4" v-if="showDiversity">
        <b-col md="6" sm="12" class="d-flex">
          <card-calc-selo :dados="dados" class="w-100" />
        </b-col>
        <b-col md="6" sm="12" class="d-flex">
          <card-common title="Diversity badges" class="w-100">
            <div class="d-flex justify-content-around">
              <div v-for="item in destaques" v-bind:key="item.id">
                <img
                  class="tagrank"
                  style="height: 110px; width: 110px"
                  :id="item.id"
                  :src="'/static/img/dashboard/' + item.name"
                />
                <b-tooltip :target="item.id" placement="top">
                  {{ $t("Candidate") +' '+ $t(item.tag) + ' ('+item.value+')' }}
                </b-tooltip>
              </div>
            </div>
          </card-common>
        </b-col>
      </b-row>
      <!-- DESTAQUES FIM -->

      <!-- INICIO POR GRUPO DE PERTENCIMENTO -->
      <b-row class="mb-4"  v-if="showDiversity">
        <b-col md="12">
          <card-grupos-pertencimento :dados="dados" />
        </b-col>
      </b-row>
      <!-- FIM POR GRUPO DE PERTENCIMENTO -->

      <!-- GRAFICOS DE AERA/PIE INICIO -->
      <b-row class="mb-4"  v-if="showDiversity">
        <b-col md="6" sm="12" class="d-flex">
          <card-donut-chart class="w-100"
            title="Result by group of belonging"
            v-model="chartGDPOpts"
          />
        </b-col>
        <b-col md="6" sm="12">
          <card-bar-chart
            chartId="bar_chart_pcd"
            title="Talents by people with disabilities"
            :total="dados.pcd"
            v-model="chartPCDOpts"
          />
        </b-col>
      </b-row>
      <!-- GRAFICOS DE AERA/PIE FIM-->

      <!-- GRAFICOS DE AERA/PIE INICIO -->
      <b-row class="mb-4"  v-if="showDiversity">
        <b-col md="6" sm="12">
          <card-bar-chart
            chartId="bar_chart_gen"
            title="Talent by Gender"
            :total="generos"
            v-model="chartGenOptions"
          />
        </b-col>
        <b-col md="6" sm="12">
          <card-bar-chart
            chartId="bar_chart_raca"
            title="Talents by race / color"
            :total="racas"
            v-model="chartRacaOpts"
          />
        </b-col>
      </b-row>
      <!-- GRAFICOS DE AERA/PIE FIM-->

      <!-- GRAFICOS DE AERA/PIE INICIO -->
      <b-row class="mb-4" v-if="showDiversity">
        <b-col md="6" sm="12">
          <card-bar-chart
            chartId="bar_chart_eta"
            title="Talents by age group"
            :total="etarias"
            v-model="chartEtariaOpts"
          />
        </b-col>
        <b-col md="6" sm="12">
          <card-bar-chart
            chartId="bar_chart_out"
            title="Other talent"
            :total="dados.grp_lgbt + dados.grp_imigrante"
            v-model="chartOutrosOpts"
          />
        </b-col>
      </b-row>
      <!-- GRAFICOS DE AERA/PIE FIM-->

      <b-row class="mb-4">
        <b-col md="12">
          <card-common :no-title="true" > 
            <h1 class="text-center text-md-left pt-4">{{$t("Efficiency")}}</h1>
          </card-common>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col md="4" sm="12">
          <card-donut-chart title="Job Status" v-model="chartStatusOpts" />
        </b-col>
        <b-col md="4" sm="12">
          <card-donut-chart title="Interviews" v-model="chartEntrevistasOpts" />
        </b-col>
        <b-col md="4" sm="12">
          <card-donut-chart title="Average closing time" v-model="chartTMFOpts" />
        </b-col>
      </b-row>

      <b-row class="mb-4" v-if="!hasGuid">
        <b-col md="6" sm="12" v-if="montlhyApplicationSeries">
          <card-common title="Applications">
            <apexchart
              type="bar"
              height="300"
              :options="chartOptionsApplication"
              :series="montlhyApplicationSeries"
            ></apexchart>
            <small>** {{ $t("Last 12 months") }}</small>
          </card-common>
        </b-col>
        <b-col md="6" sm="12" v-if="montlhyJobsSeries">
          <card-common title="Jobs Created">
            <apexchart
              type="bar"
              height="300"
              :options="chartOptionsJobs"
              :series="montlhyJobsSeries"
            ></apexchart>
            <small>** {{ $t("Last 12 months") }}</small>
          </card-common>
        </b-col>
      </b-row>

      <b-modal
        id="complete-report"
        :hide-footer="true"
        :hide-header="true"
        size="md"
        :lazy="true"
        body-class="p-0"
      >
        <report-table-complete />
      </b-modal>


      <b-modal
        id="simple-report"
        :hide-footer="true"
        size="md"
        body-class="p-0"
        :title="$t('Simplified Report')"  
      >
        <report-table-simple />
      </b-modal>


    </div>
  </transition>
</template>

<script lang="ts">
import DonutChart from '@/components/chart/DonutChart.vue';
import Report from '@/core/Services/Empresa/Dashboard';
import { AggDiversityEntity, DashboardEntity, DashboardMonthlyDataEntity, EmpresaEntity } from '@/core/models/Entities';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import DashDonutChart from './components/DashDonutChart.vue';
import ReportTableComplete from './components/ReportTableComplete.vue';
import ReportTableSimple from './components/ReportTableSimple.vue';
import CardBarChart from './partials/CardBarChart.vue';
import CardCalcSelo from './partials/CardCalcSelo.vue';
import CardCommon from './partials/CardCommon.vue';
import CardDonutChart from './partials/CardDonutChart.vue';
import CardGruposPertencimento from './partials/CardGruposPertencimento.vue';
import CardImageTotal from './partials/CardImageTotal.vue';

@Component({
  components: {
    DonutChart,
    DashDonutChart,
    'auto-complete': Autocomplete,
    CardImageTotal,
    CardDonutChart,
    CardBarChart,
    CardCalcSelo,
    CardCommon,
    CardGruposPertencimento,
    ReportTableComplete,
    ReportTableSimple
  }
})
export default class DashboardDiversidade extends Vue {
  public service: Report;
  public promise: Promise<any>|null = null;
  public commonData: DashboardEntity| null = null;
  public montlhyApplicationSeries: any[]|null = null;
  public montlhyJobsSeries: any[]|null = null;
  public chartOptionsApplication: any = {};
  public chartOptionsJobs: any = {};
  public dados: AggDiversityEntity = new AggDiversityEntity();
  public data: any[] = [];
  public dtIni: Date|null = null;
  public dtFim: Date|null = null;
  public timeout: any = 0;
  public vagaOptions: any[] = [];
  public enableFilter: boolean = false;

  public chartRacaOpts: any = {};
  public chartEtariaOpts: any = {};
  public chartPCDOpts: any = {};
  public chartGenOptions: any = {};
  public chartOutrosOpts: any = {};
  public chartGDPOpts: any = {};
  
  public generos: number = 0;
  public racas: number = 0;
  public etarias: number = 0;

  public destaques: any[] = [];

  public nivel: number = 0;
  public strNivel: string = '';
  public vagaId: string = '';
  public vagaTexto: string = '';
  public filterByDate: boolean = false;
  
  @Getter('companyInfo',{ namespace: 'profile' })
  public empresa!: EmpresaEntity;

  get showDiversity () {
    return (this.empresa?.configuracoes?.HIDE_DIVERSITY_MODAL != true);
  }

  /* #region  ChartOptions */
  get chartStatusOpts() {
    if(this.commonData) {
      return {
        series: [this.commonData.vagasAtivas, this.commonData.vagasInativas],
        colors: ['#28a745', '#a3b3e3'],
        chart: {
            type: 'donut',
            redrawOnParentResize: true
        },
        labels: [this.$t('Actives'), this.$t('Inactives')],
        legend: {
          position: "bottom"
        },
        fill: {
            // type: "gradient"
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: "bottom"
                }
            }
        }]
      }
    } else {
      return null
    }
  }

  get chartEntrevistasOpts() {
    if(this.commonData) {
      return {
        series: [ this.commonData.pendentes, this.commonData.analise, this.commonData.aprovados],
        colors: ['#FECC6C', '#5CB7FC', '#28a745'],
        chart: {
            type: 'donut',
            redrawOnParentResize: true
        },
        labels: [this.$t('Pendings'), this.$t('Analysis'), this.$t('Approveds')],
        legend: {
          position: "bottom"
        },
        fill: {
            // type: "gradient"
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: "bottom"
                }
            }
        }]
      }
    } else {
      return null
    }
  }
  get chartTMFOpts() {
    if(this.commonData) {
      return {
        series: [this.commonData.tempoMedio],
        colors: ['#28a745'],
        chart: {
            type: 'donut',
            redrawOnParentResize: true
        },
        labels: [this.$t('Average Time (days)')],
        legend: {
          show:false,
          position: "bottom"
        },
        dataLabels: {
          enabled: false,
        }, 
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            }, 
            donut: {
              size: '65%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '13px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: 'black',
                  offsetY: -10,
                  formatter: function (val) {
                    return val
                  }
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                }
              }
            },      
          }
        },
        fill: {
            // type: "gradient"
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: "bottom"
                }
            }
        }]
      }
    } else {
      return null
    }
  }
  /* #endregion */
  
  get seriesSaldo() {
    return (this.commonData) ? [this.commonData.creditos] : []
  }

  // -- GENERO
  get seriesGenero() {
    return (this.commonData) ? [this.commonData.genMasc,this.commonData.genFem, this.commonData.genHT, this.commonData.genMT, this.commonData.genNB, this.commonData.genOutros] : []
  }

  // -- RACA
  get seriesRaca() {
    return (this.commonData) ? [this.commonData.corBran, this.commonData.corPre, this.commonData.corPar, this.commonData.corAma, this.commonData.corInd, this.commonData.corAsi, this.commonData.corHis, this.commonData.corOut] : []
  }  

  public filterBy(){
    if(this.filterByDate){
      this.vagaId = '';
    }else{
      this.dtIni = null;
      this.dtFim = null;
    }
  }

  public async cleanFilter() {
    this.vagaId = '';
    this.dtIni = null;
    this.dtFim = null;
    await this.loadData()
  }


  public loadAllData() {
    this.loadData();
    this.loadCommonData()
  }

  get hasGuid() {
    return !!this.$route.params.vguid;
  }

  public async mounted() {
    const vguid = this.$route.params.vguid;
    if(vguid != null){
      this.vagaId = vguid;
    }


    await this.loadJobs();
    this.loadAllData();
  }


  public async loadJobs() {
    const data: any[] = await this.service.fetchJobsList();
    this.vagaOptions.push({value: '', text: this.$t('All')})
    data.forEach(vagaOptin => {
      this.vagaOptions.push(vagaOptin)
    });
  }
  
  public search(v1: string) {
    return new Promise((resolve: (v: any[]) => void) => {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(async (currentValue: string) => {
        const arrFilter: any[] = this.vagaOptions.filter((hb: any) => {  
          if(hb)
            return (hb.text.toLowerCase().indexOf(currentValue.toLowerCase()) > -1) 

            return false;
          })
        resolve(arrFilter)
      }, 500, v1);
    })
  }
  
  public loadData() {
    this.promise = new Promise(async (resolve,reject) => {
      try {

        const _: any = this;
        const dt = {
          dti: _.getDateToDBFormat(this.dtIni),
          dtf: _.getDateToDBFormat(this.dtFim),
          vguid: this.vagaId
        };
        await this.service.fetchAggDiversity(dt).then((data: any) => {
          console.log(data)
          if(data['report'] == null || data['report'] == ''){
            this.dados = new AggDiversityEntity();
            this.setData();
          }else{
            this.data = data['report']['0'];
            //this.dados = [''];
            
            const keys = Object.keys(this.data);
            keys.forEach((key) => {
              this.dados[key] = parseInt( (this.data[key] == null?0:this.data[key]) );
            })
            window['dados'] = this.dados;
            this.setData();
          }
        })
        
        resolve(true)
      } catch (error) {
        reject(false)
      }
    })
  }
  
  public loadCommonData() {
    this.promise = new Promise(async (resolve,reject) => {
      try {
        const _: any = this;
        const dt = {
          ini: _.getDateToDBFormat(this.dtIni),
          end: _.getDateToDBFormat(this.dtFim),
          vuid: this.vagaId
        };
        await this.service.fetchKpis(dt).then((data: any) => {
          this.commonData = data['reportData'];
        })
        await this.service.fetchMonthlyKpis(dt).then(this.initCharts) 
        resolve(true)
      } catch (error) {
        reject(false)
      }
    })
  }
  
  public initCharts(data: any) {
    if(data) {
      let cSeries: number[] = [];
      let cLabels: string[] = [];
      let jSeries: number[] = [];
      let jLabels: string[] = [];
      let indexFinder: any = {};

      
      var d = new Date();
      d.setDate(1);
      let ind: number = 0;
      let month:number = 0; 
      for (let i=1; i<=13; i++) {
          ind = 13-i;
          indexFinder[(d.getMonth()+1).toString().padStart(2,"00")+'_'+d.getFullYear()] = ind;
          cSeries[ind] = 0;
          jSeries[ind] = 0;
          cLabels[ind] = `${this.$t("mes.small."+(d.getMonth()+1).toString().padStart(2,"00"))}/${d.getFullYear()}`
          jLabels[ind] = cLabels[ind]
          d.setMonth(d.getMonth() - 1);
      }
      ind = 12;
      data.monthlyApplications?.forEach((ma: DashboardMonthlyDataEntity) => {
        ind = indexFinder[ma.m?.toString().padStart(2,"00")+'_'+ma.y];
        cSeries[ind] = ma.value||0;
      })
      ind = 12;    
      data.monthlyJobs?.forEach((ma: DashboardMonthlyDataEntity) => {
        ind = indexFinder[ma.m?.toString().padStart(2,"00")+'_'+ma.y];
        jSeries[ind] = ma.value||0;
      })    
      this.montlhyApplicationSeries = [{
        name: this.$t('Applications').toString(),
        data: cSeries
      }];
      this.chartOptionsApplication = {
        chart: {
          height: 350,
          type: 'bar',
          redrawOnParentResize: true
        },
        colors: ["#6f42c1"],
        plotOptions: {
          bar: {
            borderRadius: 3,
            colors: {
              backgroundBarColors: [  ]
            },
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: cLabels,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true
          }
        
        }
      }
      this.montlhyJobsSeries = [{
          name: this.$t('Jobs').toString(),
          data: jSeries
        }];
      this.chartOptionsJobs = {
        chart: {
          height: 350,
          type: 'bar',
          redrawOnParentResize: true
        },
        colors: ["#20c997"],
        plotOptions: {
          bar: {
            borderRadius: 3,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: jLabels,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true
          }
        
        }
      }
    }
  }

  constructor() {
    super();
    this.service = new Report(); 
  }


  public setData(){

    // this.generos = (this.dados.gen_feminino +  this.dados.gen_homem_trans +  this.dados.gen_masculino +  this.dados.gen_mulher_trans +  this.dados.gen_nao_binario +  this.dados.gen_outros);
    this.generos = (this.dados.grp_feminino +  this.dados.gen_homem_trans +  this.dados.gen_masculino +  this.dados.gen_mulher_trans +  this.dados.gen_nao_binario +  this.dados.gen_outros);
    // this.racas = (this.dados.raca_amarelo + this.dados.raca_asiatico + this.dados.raca_branco + this.dados.raca_hispanico + this.dados.raca_indigena + this.dados.raca_outros + this.dados.raca_pardo + this.dados.raca_preto);
    this.racas = (this.dados.raca_amarelo + this.dados.raca_asiatico + this.dados.raca_branco + this.dados.raca_hispanico + this.dados.raca_indigena + this.dados.raca_outros + this.dados.raca_pardo + this.dados.grp_mov_black);
    // this.etarias = (this.dados.age_between_18_24 +  this.dados.age_between_25_30 +  this.dados.age_between_31_40 +  this.dados.age_between_41_49 +  this.dados.age_bte_50 +  this.dados.age_lt_18);
    this.etarias = (this.dados.age_between_18_24 +  this.dados.age_between_25_30 +  this.dados.age_between_31_40 +  this.dados.age_between_41_49 +  this.dados.grp_3idade +  this.dados.age_lt_18);
  

    const Grupos: any[] = [
      { id: "1", name: 'selo_diversidade_feminino.png', value: this.dados.grp_feminino, tag: 'Female', },
      { id: "2", name: 'selo_diversidade_grupo_black.png', value: this.dados.grp_mov_black, tag: 'Black', },
      { id: "3", name: 'selo_diversidade_50_+.png', value: this.dados.grp_3idade, tag: '50+', },
      { id: "4", name: 'selo_diversidade_pcd.png', value: this.dados.grp_pcd, tag: 'People with disabilities', },
      { id: "5", name: 'selo_diversidade_lgbtqia+.png', value: this.dados.grp_lgbt, tag: 'LGBTQIA+', },
      { id: "6", name: 'selo_diversidade_refugiados.png', value: this.dados.grp_imigrante, tag: 'Refugees', },
    ];

    // console.log(this.dados);
    this.destaques = [];
    this.destaques = Grupos.sort((obj1, obj2) => {
        if (obj1.value > obj2.value) {
            return -1;
        }
        if (obj1.value < obj2.value) {
            return 1;
        }
        return 0;
    });

    this.destaques = this.destaques.slice(0,3);

    const Etaria: any[] = [
      { name: this.$t("at least 18").toString().toUpperCase(), value: this.dados.age_lt_18 },
      { name: this.$t("18-24").toString().toUpperCase(), value: this.dados.age_between_18_24 },
      { name: this.$t("25-30").toString().toUpperCase(), value: this.dados.age_between_25_30 },
      { name: this.$t("31-40").toString().toUpperCase(), value: this.dados.age_between_31_40 },
      { name: this.$t("41-49").toString().toUpperCase(), value: this.dados.age_between_41_49 },
      // { name: this.$t("50 Anos mais").toString().toUpperCase(), value: this.dados.age_bte_50 },
      { name: this.$t("50+").toString().toUpperCase(), value: this.dados.grp_3idade },
    ];

    const PCD: any[] = [
      { name: this.$t('Hearing').toString().toUpperCase(),  value: this.dados.pcd_auditiva },
      { name: this.$t('Speech').toString().toUpperCase(), value: this.dados.pcd_fala },
      { name: this.$t('Physical').toString().toUpperCase(), value: this.dados.pcd_fisica },
      { name: this.$t('Intellectual / Mental').toString().toUpperCase(), value: this.dados.pcd_intelectual },
      { name: this.$t('Visual').toString().toUpperCase(), value: this.dados.pcd_visual },
      { name: this.$t('Not Informed').toString().toUpperCase(), value: (this.dados.pcd - (this.dados.pcd_auditiva + this.dados.pcd_fala + this.dados.pcd_fisica + this.dados.pcd_intelectual + this.dados.pcd_visual) ) },
    ];

    const Genero: any[] = [
      // { name: this.$t("Feminino").toString().toUpperCase(), value: this.dados.gen_feminino },
      { name: this.$t("Female").toString().toUpperCase(), value: this.dados.grp_feminino },
      { name: this.$t("Male").toString().toUpperCase(), value: this.dados.gen_masculino },
      { name: this.$t("Man Trans").toString().toUpperCase(), value: this.dados.gen_homem_trans },
      { name: this.$t("Woman Trans").toString().toUpperCase(), value: this.dados.gen_mulher_trans },
      { name: this.$t("Non-binary").toString().toUpperCase(), value: this.dados.gen_nao_binario },
      { name: this.$t("Others").toString().toUpperCase(), value: this.dados.gen_outros },
    ];

    const Raca: any[] = [
      // { name: this.$t("Preto").toString().toUpperCase(), value: this.dados.raca_preto },
      { name: this.$t("Afro-descendants").toString().toUpperCase(), value: this.dados.grp_mov_black },
      { name: this.$t("White").toString().toUpperCase(), value: this.dados.raca_branco },
      { name: this.$t("Brown").toString().toUpperCase(), value: this.dados.raca_pardo },
      { name: this.$t("Yellow").toString().toUpperCase(), value: this.dados.raca_amarelo },
      { name: this.$t("Indigenous").toString().toUpperCase(), value: this.dados.raca_indigena },
      { name: this.$t("Asian").toString().toUpperCase(), value: this.dados.raca_asiatico },
      { name: this.$t("Hispanic").toString().toUpperCase(), value: this.dados.raca_hispanico },
      { name: this.$t("Others").toString().toUpperCase(), value: this.dados.raca_outros },
    ];

    const Outros: any[] = [
      {
        name: "LGBTQIA+",
        value: this.dados.grp_lgbt,
      },
      {
        name: this.$t("Refugees"),
        value: this.dados.grp_imigrante,
      },
    ];

    this.chartEtariaOpts = {
        series: [{
            data: this.getSortedDados(Etaria,'value')
        }],
        grid : {
          show : false,
        },
        colors: ['#FDE160'],
        chart: {
            type: 'bar',
            height: 350,
            redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'top' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#303030']
          },
          offsetX: 25, // play with this value
        },  
        xaxis: {
          // categories: ['MENOS 18', '18-24 ANOS', '25-30 ANOS', '31-40 ANOS', '41-49 ANOS','50 ANOS MAIS'],
          categories: this.getSortedDados(Etaria,'name'),
          axisBorder: {
            show: false
          },
          labels: {
            show: false
          }
        }
    };

    this.chartPCDOpts = {
        series: [{
            data: this.getSortedDados(PCD,'value'),
        }],
        grid : {
          show : false,
        },
        colors: ['#05CDAE'],
        chart: {
            type: 'bar',
            height: 350,
            redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'top' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#303030']
          },
          offsetX: 25, // play with this value
        },  
        xaxis: {
          categories: this.getSortedDados(PCD,'name'), 
          axisBorder: {
            show: false
          },
          labels: {
            show: false
          }         
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                }
            }
        }]
    };

    this.chartGenOptions = {
        series: [{
            data: this.getSortedDados(Genero,'value'),
        }],
        grid : {
          show : false,
        },
        colors: ['#FF9DE0'],
        chart: {
            type: 'bar',
            height: 350,
            redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'top' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#303030']
          },
          offsetX: 25, // play with this value
        },  
        xaxis: {
          categories: this.getSortedDados(Genero,'name'),
          axisBorder: {
            show: false
          },
          labels: {
            show: false
          }    
            
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                }
            }
        }]
    };

    this.chartRacaOpts = {
        series: [{
            data: this.getSortedDados(Raca,'value'),
        }],
        grid : {
          show : false,
        },
        colors: ['#A6A6A6'],
        chart: {
            type: 'bar',
            height: 350,
            redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'top' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#303030']
          },
          offsetX: 25, // play with this value
        },
        xaxis: {
          categories: this.getSortedDados(Raca,'name'),
          axisBorder: {
            show: false
          },
          labels: {
            show: false
          }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                }
            }
        }]
    };

    this.chartOutrosOpts = {
        series: [{
            data: this.getSortedDados(Outros,'value'),
        }],
        grid : {
          show : false,
        },
        colors: ['#D1B2FF'],
        chart: {
            type: 'bar',
            height: 350,
            redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'top' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#303030']
          },
          offsetX: 25, // play with this value
        },        
        xaxis: {
          categories: this.getSortedDados(Outros,'name'),
          axisBorder: {
            show: false
          },
          labels: {
            show: false
          }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                }
            }
        }]
    };

    this.chartGDPOpts = {
        series: [
          this.dados.grp_feminino,
          this.dados.grp_mov_black,
          this.dados.grp_3idade,
          this.dados.grp_pcd,
          this.dados.grp_lgbt,
          this.dados.grp_imigrante,
        ],
        colors: ['#FF9DE0', '#6A6A6A', '#FDE160', '#05CDAE', '#D1B2FF', '#00C2D8'],
        chart: {
            type: 'donut',
            redrawOnParentResize: true
        },
        labels: [
          this.$t("Female").toString().toUpperCase(),
          this.$t("Afro-descendants").toString().toUpperCase(),
          this.$t("50+").toString().toUpperCase(),
          this.$t("People with disabilities").toString().toUpperCase(),
          "LGBTQIA+".toUpperCase(),
          this.$t("Refugees").toString().toUpperCase()],
        fill: {
            // type: "gradient"
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: "bottom"
                }
            }
        }]
    };

  }


  public getSortedDados(dados:any[],k:string){
    var sortedArray = dados.sort((obj1, obj2) => {
        if (obj1.value > obj2.value) {
            return -1;
        }
        if (obj1.value < obj2.value) {
            return 1;
        }
        return 0;
    });

    var arr: any[] = [];
    Object.keys(sortedArray).map(function(key:any){  
      arr.push(sortedArray[key][k]);
    });  
    return arr;
  } 

}
</script>

<style lang="scss">
@import "@/assets/scss/diversity_dash";
</style>