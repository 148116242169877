
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';


export default class PaginaInstitucional extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/pagina-institucional', method: 'POST', headers: {} },
                fetch: { url: '/v1/pagina-institucional/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/pagina-institucional', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/pagina-institucional/{id}', method: 'PUT', headers: {} },
                delete: { url: '/v1/pagina-institucional/{id}', method: 'DELETE', headers: {} }
            }));
        this.optionList = [];
    }

    public call(key: string, data: any, ctype: string): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call(key, data, ctype).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call(key, cdta, ctype).then(resolve).catch(reject);
                });
            }
        });
    }

}


