<template>
  <div :class="listType+' row'" >
    <slot></slot> 
    <div v-if="hasButton" class="col-12 col-md-12 my-1 buttons-separators">
      <button type="button" 
          :class="listType+' btn-list-candidatura btn btn-success btn-block d-flex justify-content-start flex-wrap align-items-center text-uppercase'"
          :aria-controls="'collapse-'+listType"
          @click="visible = !visible"
          >
        <i :class="{
          'fa': true,
          'fa-angle-right': !visible,
          'fa-angle-down': visible,
          'mr-2': true
        }"></i>
        <b-form-checkbox 
          v-model="checked" 
          @input="checkboxAll"></b-form-checkbox>
        <i :class="'icon-'+listTypeIcon+' mr-2'"></i>
        {{$t(buttonText)}} ({{candidaturasQTD}})
      </button>
    </div>
    <b-collapse :id="'collapse-candidato-'+listType" v-model="visible" class="w-100 mt-2">
      <div class="col-12 col-md-12 candidaturas-list__list__all" infinite-wrapper>
        <div class="candidato-list__list__all__candidate candidate" v-for="(c, index) in getCandidaturas" :key="index">
          <candidato 
            v-model="value[index]"
            :candidatura="c"
            :currentSelected="currentid == c.id"
            @selected="selected"
            @checkbox="checkbox"
          />
        </div>
          <div class="d-flex justify-content-center my-3" v-if="loadingEvent">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <b-button pill variant="default" class="mb-4 mt-2 border-light" v-if="hasMore"
            size="sm" block
            @click="infiniteHandler"
            >
              {{ $t("Show more") }} <i class="fa fa-angle-double-down"></i>
          </b-button>
      </div>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CandidaturaEntity } from "@/core/models/Entities";
import CandidatoInList from "@/views/candidatos/CandidatoInList.vue";
import InfiniteLoading from "vue-infinite-loading"

@Component({
  components: {
    "candidato": CandidatoInList,
    'infinite-loading': InfiniteLoading
  },
})
export default class CandidaturasList extends Vue {

  @Prop({default: () => { return []}})
  public value!: CandidaturaEntity[];

  @Prop({default: ''})
  public listType!: string;

  @Prop({default: ''})
  public listTypeIcon!: string;

  @Prop({default: ''})
  public candidaturasQTD!: string;

  @Prop({default: false})
  public hasButton!: boolean;

  @Prop({default: 'Candidates'})
  public buttonText!: string;

  @Prop({default: null})
  public currentid!: number;

  public checked: boolean = false;
  public visible: boolean = false;

  public infiniteId: number = (new Date().getTime());
  public loadingEvent: boolean = false;  

  get hasMore() {
    return parseInt(this.candidaturasQTD) > this.value!.length;
  }

  get getCandidaturas() {
    return this.value;
  }

  public mounted() {
    if(this.listType == 'analise' && this.value?.length > 0 )
      this.visible = true

    for (let i=0 ; i < this.value.length ; i++) {
      this.$set(this.value[i], 'checked', false);
      // this.$emit('checkbox', this.value[i].id, this.checked);
    }
  }

  public selected(c: CandidaturaEntity) {
    this.$emit('selected', c);
  }
  
  public infiniteHandler($state: any) {
    this.$emit('infinite', $state)
    const _this: any = this;
    _this.fakeLoading()
  }

  public checkbox(cid: number, checked: boolean) {
    this.$emit('checkbox', cid, checked)
  }

  public checkboxAll() {
    const l: number = this.value.length;
    let i: number = 0
    for ( i=0 ; i < l ; i++) {
      this.$set(this.value[i], 'checked', this.checked);
      // this.$emit('checkbox', this.value[i].id, this.checked);
    }
  }
}
</script>

<style lang="scss" scoped>
.candidaturas-list__list__all {
  max-height: 380px;
  overflow-y: auto;
}
.btn-list-candidatura {
  border-radius: 10px;
}
</style>