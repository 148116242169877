<template>
  <div ref="videoWrapper" class="videoWrapper" style="width:100%; position:relative">   
    <video loop ref="blindVideoLayer" id="blindVideoLayer" @click="eventToggle" @ontouchstart="eventToggle" v-show="blind && this.isAuth" src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/video-blind.mp4" class="blindLayer"></video>
    <button type="button" class="btn btn-info btn-sm replay-button" @click="eventReplay" @ontouchstart="eventReplay"><i class="fas fa-undo-alt"></i>Replay</button>
    <div class="videoPlayerWrapper" :class="'video-js vjs-16-9 vjs-big-play-centered overflow-hidden ' + ((blind)?'blur':'')">
      <video disablePictureInPicture id="videoPlayer" ref="videoPlayer"  :muted="(distorced && blind ? true : false)" ></video>
    </div>

    <audio ref="audioPlayer" id="audioPlayer" class="audio" v-if="distorced && blind" v-show="showAudio"
      controls
      :muted="!blind"
      :src="distorced">
        Your browser does not support the
        <code>audio</code> element.
    </audio>
  </div>
</template>

<script lang="ts">
import videojs from 'video.js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class VideoPlayer extends Vue {
  public player: any = null;
  public src: string|null = null;

  @Prop({ default: {} })
  public options!: any ;

  @Prop({ default: false })
  public blind!: boolean ;

  public pause: any = {};
  public PID: any = 0;
  public audioEnable:boolean = false;

  get showAudio () {
    return !this.isAuth && this.audioEnable
  }
  get distorced() {
    return this.options != undefined ? this.options.audioDistorcedURLWAV||'' : '';
  }

  @Watch('options')
  public handleChange() {
    console.log('vp- updated- options')
    this.loadPlayer();
    if (this.audioElement && this.blind)
      this.audioElement.load()
  }
  
  @Watch('blind')
  public handleChangeBlind(newValue: boolean ,oldValue: boolean) {
    if (oldValue === true && newValue === false) {
      const vid: HTMLVideoElement|null = document.querySelector(".videoPlayerWrapper video")
      vid!.className = 'vjs-tech';
      if (this.distorced) {
        this.showElements(document.querySelectorAll(".vjs-volume-panel"));
        
        if (this.audioElement)
          this.audioElement.pause()
        
        if (this.player)
          this.player.muted(false)
      }
    }
  }

  get isAuth() {
    return !!sessionStorage.getItem('access_token');
  }

  public loadPlayer() {
    if (this.options) {
      this.src = this.options!.videoURLMP4;
      this.videoElement.src = this.src+'';
      this.videoElement.load();
    }
    if (!this.isAuth && this.blind) {
      try {
        const videoBlind: string = 'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/video-blind.mp4';
        this.options.sources[0].src = videoBlind;
        this.options.videoURLMP4 = videoBlind;
        this.src = this.options?.videoURLMP4;
        this.videoElement.src = this.src+'';
        this.videoElement.load();
      } catch (e) {
          console.log(e)
      }
    }
    if (this.src)
      this.player = videojs(this.videoElement, this.options, () => {
        this.updateElementsAction()
      })
  }

  public updateElementsAction() {
     if (this.blind) {
        this.addClickEventToElements(this.eventPlay, document.querySelectorAll(".vjs-big-play-button"));
        // this.addClickEventToElements(this.eventToggle, document.querySelectorAll(".video-js"));
        this.addClickEventToElements(this.eventToggle, document.querySelectorAll(".vjs-play-control"));
        this.hideElements(document.querySelectorAll(".vjs-fullscreen-control"));
        this.hideElements(document.querySelectorAll(".vjs-progress-control"));
        // this.showElements(document.querySelectorAll(".videoPlayerWrapper .vjs-control-bar"));
       
        if (this.distorced) {
          this.hideElements(document.querySelectorAll(".vjs-volume-panel"));
          this.videoElement.muted = true;
        }
        if (!this.isAuth) {
          this.hideElements(document.querySelectorAll(".vjs-remaining-time"));
          this.hideElements(document.querySelectorAll(".vjs-play-control"));
        }
      }
  }

  public hideElements(elements: any) {
    elements.forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });
  }
  public showElements(elements: any) {
    elements.forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
  public addClickEventToElements(event: any,elements: any) {
    elements.forEach((element: HTMLElement) => {
      element.onclick = event;
      element.ontouchstart = event;
    });
  }
      
  public mounted() {
    this.loadPlayer()
  }
  

  get videoElement(): HTMLVideoElement 
  {
    const x: HTMLElement|null = document.querySelector('#videoPlayer');

    if (x && x.tagName == 'DIV') {
      return (document.querySelector('#videoPlayer video') as HTMLVideoElement);
    } 

    return (x as HTMLVideoElement);
  }

  get videoBlindElement(): HTMLVideoElement 
  {
    return (document.querySelector('#blindVideoLayer') as HTMLVideoElement);
  }

  get audioElement(): HTMLAudioElement|null 
  {
    return (document.querySelector('#audioPlayer'));
  }

  public eventReplay() {
      this.videoElement.currentTime = 0;
      if (this.player.paused()) { 
        this.videoElement.play();
      }
      if (this.distorced && this.blind) {
        this.audioElement!.currentTime = 0;
        if (this.player.paused()) {
          this.audioElement!.play();
        }
      }
    if (this.blind) {
      this.videoBlindElement.currentTime = 0;
      if (this.player.paused())
        this.videoBlindElement.play();
    }
  }
  public eventPlay() {
    this.audioEnable = true;
    if (this.distorced && this.blind) {
      this.audioElement!.play();
    }
    if (this.blind)
      this.videoBlindElement.play();
  }
  public eventToggle() {
    console.log('entrou no evento de toggle com blind')
    if (this.player.paused()) {
      if (this.distorced && this.blind)
        this.audioElement!.pause();
      
      if (this.blind)
        this.videoBlindElement.pause();
    }
    else if (!this.player.paused()) {
      if (this.distorced && this.blind)
        this.audioElement!.play();

      if (this.blind)
        this.videoBlindElement.play();
    }
  }
  public beforeDestroy() {

    if(this.videoElement)
      this.videoElement.pause();

    if(this.audioElement)
      this.audioElement.pause();

    if (this.player) {
      this.player.dispose()
    }
    
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
  
  
  .videoWrapper  {
    overflow: hidden;
    min-height: 400px;
    .vjs-big-play-button, .vjs-control-bar {
      z-index: 9;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }

  }

  .videoWrapper:hover {
    .vjs-control-bar {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }
  
@include media("<desktop") {
  .videoWrapper  {
    overflow: hidden;
    min-height: 200px;
    .vjs-big-play-button, .vjs-control-bar {
      z-index: 9;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
  }
}
</style>

<style lang="sass" scoped>
  .replay-button
    position: absolute;
    z-index: 9;
    right: 0;

  .fa-undo-alt
    margin-right: 5px
  .videoWrapper  
    .blindLayer
      background-color: black
      position: absolute
      top: 0px
      bottom: 0px
      left: 0px
      right: 0px
      z-index: 8
      width: 100%
      heigth: 100%
      
</style>