<template>
  <b-form-group
    :label="label+(required?' *':'')"
    class="jb-text-form-group"
    :class="variant"
    :label-cols-sm="labelColsSm"
    :label-cols-md="labelColsMd"
    :label-cols-lg="labelColsLg"
    :label-class="labelClass"
    :state="state">
    <b-form-input
      @input="handleInput($event)"
      @blur="handleBlur($event)"
      :type="type"
      :required="required"
      :placeholder="placeholder"
      :value="value ? value : ''"
      :class="variant"
      :autocomplete="autocomplete"
      :step="step"
      :maxlength="maxlength"
      :state="state"
      :max="maxNumber">
    </b-form-input>
    <b-form-invalid-feedback v-if="invalidFeedback">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
    <slot v-else name="invalidFeedback" />
  </b-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: 'text'}) public type!: string;
  @Prop({default: 'text'}) public step!: string;
  @Prop({default: ''}) public value!: string;
  @Prop({default: ''}) public maxlength!: string;
  @Prop({default: null}) public labelColsSm!: string;
  @Prop({default: null}) public labelColsMd!: string;
  @Prop({default: null}) public labelColsLg!: string;
  @Prop({default: null}) public labelClass!: string;
  @Prop() public maxNumber!: number;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: 'on'}) public autocomplete!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/_variables";

  input {
    border: none;
    border-radius: 0 !important;
    background: transparent;
    padding: 0;

    color: white;
    border-bottom: 1.5px solid $light-gray !important;
  }

  input:focus {
    box-shadow: none !important;
    background-color: transparent;

    color: white;
    border-bottom: 1.5px solid white !important;
  }
  input:valid {
    border-bottom: 1.5px solid white !important;
  }

  .form-group .col-form-label,
  .form-group:valid .col-form-label {
    color: white !important;
  }

  .form-control::placeholder {
    color: rgba(140,140,140,0.65) !important;
  }

  input.dark {
    border-bottom-color: $light-gray !important;
    color: $blue;
    padding-left: 5px;
  }
  input.dark:focus {
    color: $blue;
    border-bottom-color: $blue !important;
  }
  input.gray-bordered {
    border: 1.5px solid $light-gray !important;
    border-radius: 0 !important;
    background: transparent;
    padding-left: 5px;
    padding-right: 5px !important;
    box-shadow: 1px 1px 2px #ccc;
    color: $light-gray;
  }
  input.gray-bordered:focus {
    color: $blue;
    border-color: $blue !important;
  }
  input.white {
    border: 1px solid white !important;
    color: white;
    padding-left: 5px;
  }
  input.white:focus {
    color: #888;
    background: white;
    border: 1px solid $blue !important;
  }
  input.light {
    border: 1px solid #aaa !important;
    color: #777;
    padding-left: 5px;
  }
  input.light:focus {
    color: #888;
    background: white;
    border: 1px solid $blue !important;
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .form-control.is-invalid:focus {
    border-bottom-color: #dc3545 !important;
  }
  .gray-bordered.is-invalid,
  .gray-bordered.is-invalid:focus {
    border-color: #dc3545 !important;
    color:#dc3545;
    box-shadow: none;
  }
</style>
<style lang="scss">
@import "@/assets/scss/_variables";

  .jb-text-form-group .col-form-label {
    font-size: 1em;
    padding: 0;
    color: $light-gray;
  }
  .jb-text-form-group:valid .col-form-label {
    color: white;
  }
  
  .jb-text-form-group.gray-bordered .col-form-label {
    color: $dark-gray;
  }

  .jb-text-form-group.light .col-form-label,
  .contato-form-group.dark .col-form-label {
    color: #000;
  }
  
  .jb-text-form-group.light:valid .col-form-label,
  .jb-text-form-group.dark:valid .col-form-label {
    color: #000;
  }
  .jb-text-form-group.light:invalid .col-form-label,
  .jb-text-form-group.gray-bordered:invalid .col-form-label,
  .jb-text-form-group.dark:invalid .col-form-label,
  .jb-text-form-group.dark.is-invalid .col-form-label {
    color: $red;
  }
</style>

