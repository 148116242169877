<template>
  <transition appear name="slide-fade">
    <div class="OT_widget-container"
    @mouseover="enterMouse"
    @mouseleave="leaveMouse">
      <div class="pointer nickname" v-if="!hideVideo">
        <div id="nicknameForm"  v-if="showForm">
          <b-input-group prepend="@" class="mt-3">
            <b-form-input v-model="nickname"></b-form-input>
            <b-input-group-append>
              <b-button type="button" variant="info" @click="handleChange">
                <span class="fa fa-save"></span> {{$t('Save')}}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div @click="toggleNicknameForm"  v-else>
          <span class="fa fa-image" v-show="shareActive"> </span> 
            <span id="nickname" v-if="blinded"> {{ $t(userNick) }}  </span>
            <span id="nickname" v-else> {{ userNick }}  </span>
          <span v-if="isLocal && !blinded"> (edit)</span>
        </div>
      </div>
      <div :class="{'streamComponent':true}" >
          <SpeakingBox :user="value" v-if="!hideVideo" />
        <video
          :style="{
                  'display': hideVideo ? 'none' : 'block'
                }"
            autoplay
            :id="videoId"
            ref="videoRef"
            :muted="!audioActive && !isLocal"
            :class="{'sharing': shareActive}"
          />
        <div id="statusIcons" v-if="!hideVideo">
          <div id="camIcon" v-show="!videoActive">
            <i class="fa fa-video-slash" />
          </div>
          <div id="micIcon" v-show="!audioActive">
            <i class="fa fa-microphone-slash" />
          </div>
        </div>
        <div v-if="!isLocal">
          <b-button variant="default" @click="toggleSound" >
            <i :class="iconMute"> </i>
          </b-button>
        </div>
        <div class="camoffset" v-show="(!videoActive && !value.fmEnabled) || hideVideo">
          <div :class="'blindoffset '+randomImg" v-if="blinded && !hideVideo"></div>
          <div class="camoffsetNamed" v-show="!blinded && !hideVideo">{{userNick2}}</div>
          <div class="camoffsetNamed border-rotate" v-if="hideVideo">+{{numberOfHiddenParticipants}} <i class="fa fa-users" /></div>
        </div>
        <div class="actions-subscribers d-flex justify-content-center"  v-if="show && !value.isLocal() && !hideVideo">

          <b-button 
            v-b-tooltip.hover
            :title="$t('Audio Filter')"
            class="mr-1" size="sm" :id="'popover-reactive-'+streamId" ref="button"
            v-if="value.userType == 'candidate' && value.blinded">
            <i class="fa fa-filter"></i>
          </b-button>

            <b-popover
              :target="'popover-reactive-'+streamId"
              triggers="click"
              placement="bottom"
              container="OT_widget-container"
              :show.sync="showPopoverFilter"
              @show="onShow"
              @shown="onShown"
              @hidden="onHidden"

            >
              <template v-slot:title>
                <b-button  @click="onClose"  class="close" aria-label="Close">
                  <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
                {{$t('Audio Filter')}}
              </template>

              <div  v-for="i in filtersArray"  :key="'a'+i" >
               <a href="javascript:void(0)" @click="changeSubscriberAudoFilter(i)"> {{$t('Audio Filter')}} ({{i}}) </a>
              </div>
          </b-popover>

          <b-button 
            v-b-tooltip.hover
            :title="$t('Evaluate Talent')"
            class="mr-1" size="sm" @click="showEvalSidebar" v-if="value.userType == 'candidate' && !!value.userGuid">
            <i class="fa fa-tasks" />
          </b-button>
               
          <b-button 
            v-b-tooltip.hover
            :title="$t('Reveal Talent')"
            class="mr-1" size="sm" @click="showSubscriber" v-if="value.userType == 'candidate' && value.blinded && canApprove">
            <i class="fa fa-eye" />
          </b-button>
          
          <b-button 
            v-b-tooltip.hover
            :title="$t('Mute/Unmute Mic')"
            class="mr-1" size="sm"  @click="muteSubscriber" :disabled="!value.isAudioActive()" >
            <i class="fa fa-microphone-slash" />
          </b-button>
          
          
          <b-button class="mr-1" size="sm" 
            v-if="value.userType == 'candidate' && value.userGuid"
            @click="openHiddenProfile(value.userGuid)"
            v-b-tooltip.hover
            :title="$t('Anonymous Resume')"
            >
            <i class="fa fa-user-secret"></i>
          </b-button>
          
          <b-button 
            v-b-tooltip.hover
            variant="danger"
            :title="$t('Remove participant')"
            class="mr-1" size="sm" @click="removeParticipant">
            <i class="fas fa-times-circle"></i>
          </b-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import SpeakingBox from './SpeakingBox.vue'

@Component<Stream>({
  components: {
    ButtonIcon,
    SpeakingBox
  }
})
export default class Stream extends Vue {
  public nickname: string = "";
  public showForm: boolean = false;
  public show: boolean = true;
  public mutedSound: boolean = false;
  public showPopoverFilter: boolean = false;
  public filterArray: number[] = [1,2,3,4];

  @Prop({default: () => { return new UserModel() }})
  public value!: UserModel;

  @Prop({default: null})
  public canApprove!: boolean;

  @Prop({default: null})
  public isAuthenticatedUser!: boolean;
  
  @Prop({default: null})
  public showMoreFilters!: boolean;
  
  @Prop({default: 8})
  public maxParticipants!: number;
  
  @Prop({default: 8})
  public numberOfHiddenParticipants!: number;
  
  @Prop({default: 0})
  public index!: number;

  get userNick() {
    return this.value.getNickname();
  }

  get hideVideo() {
    return  (this.index > (this.maxParticipants -2)) && this.numberOfHiddenParticipants > 1
  }

  get filtersArray() {
    if(this.showMoreFilters)
      return this.filterArray.concat([5,6,7,8]);
      
    return this.filterArray;
  }

  get userType() {
    return this.value.userType;
  }
  get userNick2() {
    return this.value.getNickname().substring(0,2);
  }

  get speaking() {
    return this.value.speaking;
  }

  get blinded() {
    return this.value.blinded && this.value.userType == 'candidate';
  }

  get isLocal() {
    return this.value.isLocal();
  }
  
  @Watch('value.streamManager.stream.streamId')
  public setVideo() {
    console.log('setVideo')
    if(!this.value.getStreamManager()?.stream?.streamId) return;
    this.value.getStreamManager().addVideoElement(this.$refs.videoRef);
    if((this.$refs?.videoRef as any)?.paused)
      (this.$refs?.videoRef as any)?.play()
  }

  get videoActive() {
    return this.value.isVideoActive();
  }
  get shareActive() {
    return this.value.isScreenShareActive();
  }

  get randomImg() {
    const x: string = ""+this.value.random;
    return 'blindoffset-img'+ x.padStart(2,'00');
  }
  
  get audioActive() {
    return this.value.isAudioActive();
  }
  get videoId() {
    return 'video-' + (this.streamId || 'local' );
  }

  get streamId() {
    return (this.value.getStreamManager()) ? this.value.getStreamManager().stream.streamId : null;
  }

  get iconMute() {
    return this.mutedSound ? "fa fa-volume-up" : "fa fa-volume-mute";
  }

  public enterMouse() {
    this.show = true;
  }

  public leaveMouse() {
    if(!this.showPopoverFilter) {
      this.show = false;
    }
  }

  public playPID: any = 0;
  public mounted() {
    this.nickname = this.value.getNickname();
    if (this.value && this.value.streamManager && !!this.$refs.videoRef) {
        this.value.getStreamManager().addVideoElement(this.$refs.videoRef);
    }

    // if (this.value && this.value.streamManager && this.value.streamManager.session && !!this.$refs.videoRef) {
    //   this.value.streamManager.session.on('signal:userChanged', (event) => {
    //       const data = JSON.parse(event.data);
    //       if (data.isScreenShareActive) {
    //           this.value.getStreamManager().addVideoElement(this.$refs.videoRef);             
    //       }
    //   });
    // }

    this.playPID = setTimeout(() => {
      try {
        if((this.$refs?.videoRef as any)?.paused)
          (this.$refs?.videoRef as any)?.play()
      } catch(e) {
      }
    },4000)
  }

  public beforeDestroy() {
   clearTimeout(this.playPID)
  }

  public toggleSound() {
    this.mutedSound = !this.mutedSound;
  }

  public handleChange() {
    this.$emit('handleNickname', this.nickname);
    this.showForm = false;
  }

  public toggleNicknameForm() {
    if (this.value.isLocal()) {
        this.nickname = this.value.getNickname();
        this.showForm = true;
    }
  }

  public onClose() {
    this.showPopoverFilter = false;
  }
  public onShow() {
    this.showPopoverFilter = true;
  }
  public onShown() {
    this.showPopoverFilter = true;
  }
  public onHidden() {
    // Called just after the popover has finished hiding
    // Bring focus back to the button
  }

  public showSubscriber() {
    let msg: any = this.$t('Are you sure you want to reveal the person?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('showSubscriber',this.value)
      }
    });
  }

  public removeParticipant() {
    let msg: any = this.$t('Are you Sure?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('removeParticipant',this.value)
      }
    });
  }

  public showEvalSidebar() {
    this.$emit('showEvalSidebar',this.value)
  }
  
  public muteSubscriber() {
    if(this.value.isAudioActive()) {
      let msg: any = this.$t('Are you Sure?')
      this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
        if (v) {
          this.$emit('muteSubscriber',this.value)
        }
      });
    }
  }

  public async changeSubscriberAudoFilter(n: number) {
    this.showPopoverFilter = false;
    
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value && this.value.blinded)
      this.$emit('changeSubscriberAudoFilter',this.value, n)
  }
  
  public openHiddenProfile(guid: string) {
    let perfil = this.$router.resolve({
      name: "HiddenTalentInfoPage", params: {guid}, query: {lang: this.$i18n.locale, n: btoa(this.userNick||"") }
    });
    window.open(perfil.href, '_blank')
  }
}
</script>

<style lang="css" src="@/assets/css/live-interview/stream.css"></style>