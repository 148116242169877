// tslint:disable

import { EVideoPurpose } from "@/core/Enums/EVideoPurpose";
import Room from "@/core/Services/Video/Room";
import RoomAccess from "@/core/Services/Video/RoomAccess";
import UserModel from "@/core/recording/UserModel";
import AlertHeadline from "@/plugins/AlerHeadline";
import { i18n } from '@/plugins/i18n';
import { LayoutClass } from "@/views/live-interview/mixins/layout-type";
import { OpenViduLayoutOptions } from "@/views/live-interview/mixins/openvidu-layout";
import { Connection, Event, EventDispatcher, LocalRecorder, OpenVidu, Publisher, PublisherProperties, Session, SignalEvent, SignalOptions, Stream } from "openvidu-browser";
import { EApplication } from '../Enums/EApplication';
import PlatformUtil from './PlatformUtil';

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};
export default class OpenViduApp {

  /* #region  Attributes */
  public OV: OpenVidu;
  public OVShare: OpenVidu | null = null;
  public session: Session | null;
  public sessionShare: Session | null = null;
  public publisher: Publisher | null = null;
  public screenSharePublisher: Publisher | null = null;
  public sessionId: string | null = null;
  public purpose: EVideoPurpose | null = null;
  public audioEnabled: boolean = true;
  public videoEnabled: boolean = true;
  public recordingEnabled: boolean = false;
  public numOfVideos: number = 0;
  public leaveRoomCallback?: () => void;
  public subscribersList: any[] = [];
  public roomCall: boolean = false;
  public enableFilter: boolean = false;
  public blindApproved: boolean = false;
  public nickname: string = '';
  public userEmail: string = '';
  public mediaStream: MediaStream | null = null;
  public canvaMediaStream: MediaStreamTrack | null = null;
  public localUser: UserModel = new UserModel();
  public screenShareUser: UserModel | null = null;
  public appType: EApplication | null = null;
  public audioThreadSold: number = -50;
  public hasPublisher: boolean = false;
  public messageList: any = [];
  public intervalPID: any;
  public alertHeadline: AlertHeadline | null = null;
  public audioDevices: { kind: string | null, deviceId: string | null, label: string | null }[] = [];
  public videoDevices: { kind: string | null, deviceId: string | null, label: string | null }[] = [];
  public currentVideoDeviceId: string | null | undefined = undefined;
  public currentAudioDeviceId: string | null | undefined = undefined;
  public blindFilterEnabled: boolean = false;
  public networkDisconnected: boolean = false;
  public networkReconnected: boolean = false;
  public tryingToReconnect: boolean = false;
  public checkingVideoTransmission: boolean = false;
  public maxCheckingVideoTransmissionTimes: number = 0;
  public checkingVideoTransmissionTimeout: number = 10000;

  public platform: PlatformUtil = PlatformUtil.getInstance();
  public localRecorder: LocalRecorder | null = null;
  public localRecordMimeType: string | null = null;
  public reconnectTimer: number = 0;
  public fastReconnectTimer: number = 0;
  public approveCandidateCallback: any = null;
  
  public currentRAToken: string|null = null;
  public block10MinEval: boolean | null = null;
  public blockApprove: boolean | null = null;
  public enableFaceMesh: boolean | null = null;
  public enableCustomBack: boolean | null = null;
  public showMoreFilters: boolean | null = null;
  
  //ordering
  public statsSubscribersOrderingStarted: boolean = false;
  public MAX_PARTICIPANTS_IN_ROOM: number = 8;
  public numberOfSpeakers: number = 0;
  public nextChange: number = 0;

  //Stats Check
  public statsCheckPID: any;
  public statsCheckStarted: boolean = false;
  /* #endregion */
  //EVENTS CALLBACK
  public signalUserChangedCallback?: () => any;
  public sessionDestroyedCallback?: () => any;
  public streamPlayingCallback?: () => any;
  public joinCallback?: () => any;
  public beforeReconnectCallback?: () => any;
  public errorSendingVideoCallback?: () => Promise<any>;
  public receivedCCCallback?: (a: any) => any;
  public toggleCameraCallback?: () => any;

  constructor(sessionId: any, purpose?: EVideoPurpose) {
    this.sessionId = sessionId;
    this.purpose = purpose || null;
    this.OV = new OpenVidu();
    this.OV.setAdvancedConfiguration({
      publisherSpeakingEventsOptions: {
        interval: 100,   // Frequency of the polling of audio streams in ms (default 100)
        threshold: this.audioThreadSold  // Threshold volume in dB (default -50)
      },
      forceMediaReconnectionAfterNetworkDrop: true
    });
    this.OV.enableProdMode();
    this.session = this.OV.initSession();
    if (this.sessionId) {
      console.log("Joining to room " + this.sessionId);
      this.showSessionHideJoin();
      this.createPublisherAndJoin('videos')
    } else {
      this.showJoinHideSession();
    }
    let me: OpenViduApp = this;
    window.addEventListener("beforeunload", function () {
      if (me.session)
        me.session.disconnect();
    });
    this.initDevices();
    window["OV"] = this;
  }

  public getCodeFrom(str: string) {
    const n: string = str.substring(str.length - 1, str.length)
    const t: string = str.substring(0, str.length - 1)
    let x: number = parseInt(n);
    let resultStr = t;
    if (!isNaN(x) && x >= 0) {
      x++;
      for (let index = 0; index < x; index++) {
        resultStr = atob(resultStr)
      }
    }
    return resultStr;
  }

  public createPublisherAndJoin(container: string) {
    this.createPublisher('videos', undefined, () => { this.joinRoom(); })
  }

  public async openCamera() {
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      try {
        let me = this;
        me.mediaStream = null;
        const FRAME_RATE: number = 30;
        let resolutionString: string = "640x360";

        if (this.purpose == EVideoPurpose.LiveInterview)
          resolutionString = "800x600";
        
        if (this.platform.isMobileDevice())
          resolutionString = "480x640";

        if (me.mediaStream == null) {
          const um: any = {
            videoSource: this.currentVideoDeviceId || undefined,
            audioSouce: this.currentAudioDeviceId || undefined,
            resolution: resolutionString
          }
          me.mediaStream = await me.OV!.getUserMedia(um);
        } 
        resolve(me.mediaStream);
      } catch (e) {
        console.log(e)
        reject(e)
      }
    });
  }

  public hasMediaStreamTrack() : boolean {
    return (this.mediaStream && this.mediaStream.getVideoTracks()
          && this.mediaStream.getVideoTracks().length > 0
          && this.mediaStream.getAudioTracks()
          && this.mediaStream.getAudioTracks().length > 0) == true;
  }

  public async createPublisher(container?: any,
    accessDenied?: (event: Event) => void,
    videoElementCreated?: (event: Event) => void,
    videoElementDestroyed?: (event: Event) => void,
    secondAttempt?: boolean) {
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      try {
        let me = this;
        me.mediaStream = null;
        const FRAME_RATE: number = 25;
        let resolutionString: string = "640x360";

        if (this.purpose == EVideoPurpose.LiveInterview)
          resolutionString = "800x600";
        
        if (this.platform.isMobileDevice())
          resolutionString = "480x640";

        let um: any = {
          videoSource: this.currentVideoDeviceId || undefined,
          audioSouce: this.currentAudioDeviceId || undefined,
          resolution: resolutionString
        }
        if(me.mediaStream == null) {
          if (secondAttempt) {
            delete um.resolution;
          }
          me.mediaStream = await me.OV!.getUserMedia(um)
        }

        let videoTrack: MediaStreamTrack | string | boolean | undefined;
        let audioTracks: MediaStreamTrack | string | boolean | undefined;

        if (!this.hasMediaStreamTrack()) {
          if (secondAttempt === undefined) {
            me.createPublisher(container, accessDenied, videoElementCreated, videoElementDestroyed, true)
            return;
          } else {
            me.hasPublisher = false;
            me.removePublisher();
            throw new Error('could not get media stream');
          }
        }
        audioTracks = me.mediaStream.getAudioTracks()[0];
        videoTrack = me.mediaStream.getVideoTracks()[0];

        if(this.canvaMediaStream != null) {
          videoTrack = this.canvaMediaStream;
        }

        if (videoTrack && audioTracks) {

          if(audioTracks instanceof MediaStreamTrack)
            audioTracks.enabled = true;

          let pubData: PublisherProperties = {
            audioSource: audioTracks,
            videoSource: videoTrack,
            publishAudio: this.localUser.isAudioActive(),
            publishVideo: this.localUser.isVideoActive(),
            insertMode: "APPEND",
            mirror: false,
            resolution: resolutionString
          };

          me.publisher = me.OV!.initPublisher(container, pubData);
          me.localUser.setScreenShareActive(false);
          me.localUser.setStreamManager(me.publisher);
          window['publisher'] = me.publisher;
          // When our HTML video has been added to DOM...
          me.publisher.on("videoElementCreated", function (event: any) {
            me.numOfVideos++;
            // me.updateLayout();
            if (event.element) {
              // event.element.style.width = '100%'
              // event.element.muted = true
              event.element.classList.add('recorder-video');
            }
            if (videoElementCreated)
              videoElementCreated(event)
          });

          if (accessDenied)
            me.publisher.on('accessDenied', accessDenied);

          if (videoElementDestroyed)
            me.publisher.on('accessDenied', videoElementDestroyed);

          me.hasPublisher = true;
          resolve(me.publisher);
        } else {
          throw new Error('could not get media stream');
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  public applyFaceOverLayFilterIMG() {
    if (this.publisher && this.publisher.stream) {
      this.publisher.stream.removeFilter();
      this.publisher.stream.applyFilter("FaceOverlayFilter", {})
        .then(filter => {
          filter.execMethod(
            "setOverlayedImage",
            {
              "uri": "https://cdn.pixabay.com/photo/2013/07/12/14/14/derby-148046_960_720.png",
              "offsetXPercent": "-0.2F",
              "offsetYPercent": "-0.8F",
              "widthPercent": "1.3F",
              "heightPercent": "1.0F"
            });
        });
    }
  }

  public applyFilterCommand(command: string) {
    if (this.publisher && this.publisher.stream) {
      if (this.publisher.stream.filter != null)
        this.publisher.stream.removeFilter();
      this.publisher.stream.applyFilter("GStreamerFilter", { "command": command })
    }
  }

  public applyVideoFilter(c: any) {
    switch (c) {
      case 1:
        this.applyFaceOverLayFilterIMG()
        break;
      case 2:
        this.applyFilterCommand('dicetv')
        break;
      case 3:
        this.applyFilterCommand('radioactv')
        break;
      case 4:
        this.applyFilterCommand('quarktv')
        break;
      case 5:
        this.applyFilterCommand('optv')
        break;
      case 6:
        this.applyFilterCommand('rippletv')
        break;
      case 7:
        this.applyFilterCommand('edgetv')
        break;
      default:
        if (this.publisher && this.publisher.stream)
          this.publisher.stream.removeFilter();
        break;
    }
  }

  public removeFilter() {
    if (this.publisher && this.publisher.stream) {
      this.publisher.stream.removeFilter();
    }
  }

  public applyDynamicAudioFilter(p: string, r: string, t: string) {
    this.applyFilterCommand(`pitch pitch=${p} rate=${r} tempo=${t}`)
  }

  public async applyAudioFilter(c: any) {
    switch (c) {
      case 1:
        return await this.applyFilterCommand('pitch pitch=0.74 rate=1.12')
      case 2:
        return await this.applyFilterCommand('pitch pitch=1.42 rate=1.12')
      case 3:
        return await this.applyFilterCommand('pitch pitch=0.75')
      case 4:
        return await this.applyFilterCommand('pitch pitch=1.25 rate=1.1')
      case 5:
        return await this.applyFilterCommand('pitch pitch=0.68 rate=1.62 tempo=0.68')
      case 6:
        return await this.applyFilterCommand('pitch pitch=1.80 rate=0.35 tempo=2.45')
      case 7:
        return await this.applyFilterCommand('pitch pitch=1.2 rate=0.95 tempo=1.1')
      case 8:
        return await this.applyFilterCommand('pitch pitch=1.25 rate=1.25 tempo=0.95')
      default:
        return await this.applyFilterCommand('pitch pitch=0.75')
    }
    return await 0;
  }

  public applyGStreamerFilterAudio() {
    if (this.publisher && this.publisher.stream) {
      this.publisher.stream.removeFilter();
      this.publisher.stream.applyFilter("GStreamerFilter", { "command": "pitch pitch=0.8 rate=0.8 tempo=0.99" })
    }
  }

  public async connectWebCam() {
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      try {
        console.log('creating publisher')
        await this.createPublisher();
        if (this.session!.capabilities.publish && this.publisher) {
          this.session!.publish(this.publisher).then(async (c) => {
            console.log('alright its published');
            this.localUser.setConnectionId(this.session!.connection.connectionId);
            this.blindCheckIn();
            this.publisher!.on('streamAudioVolumeChange', (event: any) => {
              //se tem alguem falando
              this.localUser.audioVolume = event.value.newValue;
              this.localUser.setSpeaking((event.value.newValue > this.audioThreadSold));
            });
            this.subscribeToUserChanged();
            this.recordingStatusChanged();
            this.subscribeToStreamDestroyed();
            this.sendSignalUserChanged({ isScreenShareActive: this.localUser.isScreenShareActive() });
            this.iamAlive();
            this.publisher!.on('streamPlaying', (e) => {
              if (this.streamPlayingCallback)
                this.streamPlayingCallback();
              this.publisher!.videos[0].video.parentElement!.classList.remove('custom-class');

            });
            resolve();
          }).catch(reject);
        }
      } catch (e) {
        console.log(e)
        console.log('erro ao se juntar com camera')
        reject(e);
      }
    });
  }

  public iamAlive() {
    clearInterval(this.intervalPID)
    this.intervalPID = setInterval(() => {
      this.sendSignalUserChanged({
        iamAlive: true,
        isAudioActive: this.localUser.isAudioActive(),
        isVideoActive: this.localUser.isVideoActive()
      })
    }, 20000)
  }

  public blindCheckIn() {
    console.log('blindCheckIn enter')
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      try {
        const candidateBlindProcess: boolean = (this.appType == EApplication.Candidate && this.enableFilter == true);
        console.log('blindCheckIn ? ', candidateBlindProcess && this.publisher && !this.blindFilterEnabled)
        if (candidateBlindProcess && this.publisher && !this.blindFilterEnabled) {
          this.sendSignalUserChanged({ isAudioActive: false, isVideoActive: false, firstEntry: true });
          this.publisher.publishVideo(false);
          this.publisher.publishAudio(false);

          await sleep(2000);
          let filterapplyed: boolean = false;
          let i: number = 0;
          while (i <= 60) {
            console.log('chamando fil')
            filterapplyed = await this.addBlindFilter();
            console.log('durmindo por 2 segundos', filterapplyed)
            await sleep(2000);
            if (filterapplyed)
              break;

            if (i == 60) {
              throw new Error('Após 60 tentativas não foi possivel adicional o filtro ao stream')
            }

            i++;
          }
          this.blindFilterEnabled = true;
          // setTimeout(() => {
          //   console.log('setTimeoutEnter')
          //     this.addBlindFilter()
          // }, 1500)
          console.log('PASSOU da fase de filtro');
          this.publisher.publishAudio(true);
          this.localUser.setAudioActive(true);
          resolve(true);
        } else {
          this.localUser.blinded = false;
          resolve(true);
        }
      } catch (e) {
        console.error(e)
        this.publisher!.publishAudio(false);
        this.localUser.setAudioActive(false);
        this.localUser.blinded = true; // must be true to send error to manager
        this.blindApproved = false;
        this.blindFilterEnabled = false;
        this.sendSignalUserChanged({ isAudioActive: true, isVideoActive: false, errorOnLoad: true });
        reject(false);
      }
    });
  }

  public async addBlindFilter(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      if (this.publisher && this.publisher.stream) {
        if (!this.publisher.stream.filter) {
          console.log('applying filter')
          await this.applyAudioFilter(4)
            .then((data) => {
              /* console.log("ENTRANDO NA SALA") */
              /* console.log("blind Process") */
              this.sendSignalUserChanged({ isAudioActive: true, isVideoActive: false, blinded: true, fmEnabled: this.localUser.fmEnabled, nickname: this.localUser.nickname });
              if(!this.localUser.fmEnabled) {
                this.publisher!.publishVideo(false);
                this.localUser.setVideoActive(false);
              } else {
                this.publisher!.publishVideo(true);
                this.localUser.setVideoActive(true);
              }
              this.localUser.blinded = true;
              

              this.localUser.setNickname(this.localUser.nickname);
              /* console.log("====removendo video") */
              this.localUser.setAudioActive(true);
              this.publisher!.publishAudio(true);
              console.log('filter applyed')
              resolve(true)
            }).catch(async (error) => {
              console.log(error)
              console.log('filter not applyed')
              // FORCE FILTER APPLICATION
              resolve(false)
              /* console.log(error) */
            })
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    });
  }

  


  public recordingStatusChanged() {
    if (this.session) {
      this.session.on('signal:recordingEvent', (event: any) => {
        const data: any = JSON.parse(event.data);
        if (data.status == 'started') {
          this.recordingEnabled = true;
        } else {
          this.recordingEnabled = false;
        }
      })
    }
  }

  public zeroBytesFromAudioAndVideoObj: any = {}
  public subscribeToUserChanged() {
    if (this.session) {
      this.session.off('signal:userChanged');
      this.session.on('signal:userChanged', (event: SignalEvent) => {

        if (event.from?.connectionId == this.session!.connection.connectionId)
          return;

        if (!event.data)
          return;

        const data = JSON.parse(event.data!);
        // console.log('EVENTO REMOTE: ', event);
        let userExists: boolean = false;
        const len: number = this.subscribersList.length;
        for (let index = 0; index < len; index++) {

          if (!this.subscribersList[index])
            continue;
          
          if(event.from?.connectionId && this.zeroBytesFromAudioAndVideoObj[event.from?.connectionId] === undefined)
            this.zeroBytesFromAudioAndVideoObj[event.from?.connectionId] = false;
  

          const user: UserModel = this.subscribersList[index];
          if (user.getConnectionId() === event.from?.connectionId) {
            if (data.isAudioActive !== undefined) {
              user.setAudioActive(data.isAudioActive);
            }
            if (data.isVideoActive !== undefined) {
              user.setVideoActive(data.isVideoActive);
            }
            if (data.nickname) {
              user.setNickname(data.nickname);
            }
            if (data.random !== undefined) {
              user.random = data.random;
            }
            if (data.isScreenShareActive !== undefined) {
              user.setScreenShareActive(data.isScreenShareActive);
            }
            if (data.appFrom !== undefined) {
              user.setFrom(data.appFrom);
            }
            if (data.role !== undefined) {
              user.setRole(data.role);
            }
            if (data.userType !== undefined) {
              user.userType = data.userType;
            }
            if (data.userId !== undefined) {
              user.userId = data.userId;
            }
            if (data.userGuid !== undefined) {
              user.userGuid = data.userGuid;
            }
            if (data.blinded !== undefined) {
              user.blinded = data.blinded;
            }
            if (data.fmEnabled !== undefined) {
              if(!user.fmEnabled && data.fmEnabled) {
                setTimeout(() => {
                  user.getStreamManager().stream.initWebRtcPeerReceive(true);
                },60000)
              }
              user.fmEnabled = data.fmEnabled;
            }

            if(data.zeroBytesFromAudioAndVideo) {
              this.zeroBytesFromAudioAndVideoObj[event.from?.connectionId] = true
              console.warn('received zeroBytesFromAudioAndVideo from', event.from?.connectionId)
            }
            
            break;
          }
        }

        if (data.iamAlive) {
          return;
        }

        if (data.connectionToShowId !== undefined && this.localUser.getConnectionId() == data.connectionToShowId && data.a == 1) {
          this.blindToNonBlind();
        }

        if (data.connectionToShowId !== undefined && this.localUser.getConnectionId() == data.connectionToShowId && data.a == 2) {
          this.micStatusChanged(false);
        }

        if (data.connectionToShowId !== undefined && this.localUser.getConnectionId() == data.connectionToShowId && data.a == 3) {
          this.applyAudioFilter(data.filter);
        }

        /// packets are beeing lost
        if (data.somethingSeemsWrongInAudio) {          
          if (this.localUser.errCounter >= 100) {
            this.reconnectUser().then(() => {
              this.localUser.errCounter = 0;
            })
          } else if (this.localUser.errCounter >= 50) {
            this.tryFastReconnect(); 
          }

          this.localUser.errCounter++;
        }

        // if (!userExists) {
        //   console.log("Existem usuários conectados que não estão sendo exibidos")
        // }

        if (this.signalUserChangedCallback)
          this.signalUserChangedCallback();
      });
    }
  }


  private blindToNonBlind() {
    console.log('blindToNonBlind') 
    if (this.publisher != null && this.localUser.blinded) {
      if (this.publisher.stream && this.publisher.stream.filter) {
        this.publisher.stream.removeFilter();
      }
      setTimeout(() => {
        this.localUser.blinded = false;
        this.localUser.fmEnabled = false;
        this.localUser.nickname = this.nickname;
        // this.canvaMediaStream = null;
        this.localUser.fmEnabled = false;
        this.publisher!.publishVideo(true);
        this.publisher!.publishAudio(true);
        this.localUser.setVideoActive(true);
        this.localUser.setAudioActive(true);
        this.blindApproved = true;
        this.blindFilterEnabled = false;

        if(this.approveCandidateCallback)
          this.approveCandidateCallback();

        this.sendSignalUserChanged({ isAudioActive: true, isVideoActive: true, nickname: this.nickname, blinded: false, fmEnabled: false });
      }, 1500)
    }
  }

  public subscribeToStreamDestroyed() {
    // On every Stream destroyed...
    if (this.session) {
      this.session.on('streamDestroyed', (event: any) => {
        console.log('streamDestroyed',event.stream)
        if(this.localUser.getStreamManager().stream.streamId == event.stream?.streamId) {
          this.leaveRoom()
        } else {
          this.deleteSubscriber(event.stream)
        }
        event.preventDefault();
      });
      this.session.on("connectionDestroyed", (event: any) => {
        console.log('connectionDestroyed',event.stream)
        this.deleteSubscriber(event.stream)
        event.preventDefault();
      });
    }
  }

  public checkSomeoneShareScreen(): OpenViduLayoutOptions {
    let isScreenShared: boolean = false;
    // return true if at least one passes the test
    // isScreenShared = this.subscribersList.some((user) => user.isScreenShareActive()) || this.localUser.isScreenShareActive();
    //    console.log('someone is sharing = ', isScreenShared);
    const openviduLayoutOptions = {
      maxRatio: 1, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false /* If this is true then the aspect ratio of the video is maintained
      and minRatio and maxRatio are ignored (default false) */,
      bigClass: LayoutClass.BIG_ELEMENT, // The class to add to elements that should be sized bigger
      smallClass: LayoutClass.SMALL_ELEMENT,
      bigPercentage: 0.85, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 1, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: true // Whether you want to animate the transitions. Invalid property, to disable it remove   transition: all .1s linear;
    };
    return openviduLayoutOptions;
  }

  public deleteSubscriber(stream: any) {
    const remoteUsers: UserModel[] = this.subscribersList;
    const userStream: any = remoteUsers.filter((user: UserModel) => user.getStreamManager().stream === stream)[0];
    let index = remoteUsers.indexOf(userStream, 0);

    if (index > -1) {
      try {
        if (stream.getWebRtcPeer())
          stream.disposeWebRtcPeer();

        stream.disposeMediaStream();
        
        if (stream.streamManager && stream.streamManager.videos.length
          && stream.streamManager.videos[0].video.parentNode) {
          stream.streamManager.removeAllVideos();
        }
      } catch (e) {
        console.log('erro ao fazer o dispose', e)
      }
      
      this.subscribersList.splice(index, 1);
    }

    if(this.sessionDestroyedCallback)
      this.sessionDestroyedCallback()
  }


  public onVolumeChange(callback: (event: Event) => void): EventDispatcher {
    return this.publisher!.on('streamAudioVolumeChange', callback);
  }

  public removePublisher() {
    if (this.publisher) {
      try {
        this.session?.unpublish(this.publisher);
        
        let stream = this.publisher.stream;

        if (stream.streamManager && stream.streamManager.videos.length) 
          stream.streamManager.removeAllVideos();
        
      } catch (e) {
        console.log('erro ao fazer o dispose')
      }
      this.hasPublisher = false;
      this.publisher = null;
    }
  }


  public joinRoom(): Promise<any> {
    console.log('joining room');
    let me: OpenViduApp = this;
    this.session = this.OV.initSession();

    this.disconnectionEventsTriggers();
    this.exceptionEventsTriggers();

    this.subscribeToStreamCreated();
    this.subscribeToStreamDestroyed();

    if (this.purpose == EVideoPurpose.LiveInterview) {
      //check every subscriber for errors
      this.initStatsCheck();

    }

    return this.connectToSession()
  }

  public exceptionEventsTriggersTimers: number = 1000;
  public exceptionEventsTriggers() {
    if (this.session) {
      this.session.on('exception', (ex: any) => {

        //ex.origin = =Session
        if (ex.name === 'ICE_CANDIDATE_ERROR') {
          console.warn('Error connection to Jobecam Session ' + ex.origin.sessionId + '!');
          console.warn('Reconnection process may not be possible');
          const strAlert: string = "Oops! There is a problem within your network that failed to get your video and audio data.";
          this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString());
          this.alertHeadline.setHidTimeout(10000);
          setTimeout(() => {
            this.reconnectUser()
            this.exceptionEventsTriggersTimers += 10000;
          }, this.exceptionEventsTriggersTimers);
          return;
        }
        let stream: Stream | null = null;
        //ex.origin = =Subscriber
        if (ex.name === 'NO_STREAM_PLAYING_EVENT') {
          console.warn('Error NO_STREAM_PLAYING_EVENT ' + ex.origin.stream.streamId + '!');
          stream = ex.origin.stream;
          // element should play
          setTimeout(async () => {
            try {
              if(!stream) return;
              const remoteUsers: UserModel[] = this.subscribersList;
              const userStream: UserModel|null = remoteUsers.filter((user: UserModel) => user.getStreamManager().stream === stream)[0];
              userStream.getStreamManager().stream.initWebRtcPeerReceive(true);
              await sleep(1000);
              userStream.getStreamManager().addVideoElement(document.getElementById('video-'+stream.streamId))
            } catch (e) {
              //
            }
          },5000)
        } else {
          stream = ex.origin;
        }

        if (stream !== null) {
          if (ex.name === 'ICE_CONNECTION_FAILED') {
            console.warn('Stream ' + stream.streamId + ' broke!');
            console.warn('Reconnection process automatically started');
            if (this.localUser.getStreamManager().stream.streamId == stream.streamId) {
              const strAlert: string = "Oops! There is a problem within your network that failed to get your video and audio data.";
              this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString());
              this.alertHeadline.setHidTimeout(10000)
            }
          }
          if (ex.name === 'ICE_CONNECTION_DISCONNECTED') {
            console.warn('Stream ' + stream.streamId + ' disconnected!');
            console.warn('Giving it some time to be restored. If not possible, reconnection process will start');
            if (this.localUser.getStreamManager().stream.streamId == stream.streamId) {
              setTimeout(() => {
                if(this.errorSendingVideoCallback)
                  this.errorSendingVideoCallback()
                  this.exceptionEventsTriggersTimers += 10000;
              }, this.exceptionEventsTriggersTimers);
              const strAlert: string = "Oops! Problems in your connection, we are trying to reconnect you the room ...";
              this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString());
              this.alertHeadline.setHidTimeout(10000)
            }
          }
        }
        console.log('ExceptionName>>', ex.name);
        console.log('exception>>', ex);
      })
    }
  }

  public tryFastReconnect() {
    console.log('tryFastReconnect')
    return new Promise(async (resolve: any, reject: any): Promise<void> => {
      const now: number = (new Date).getTime();
      if ((now - this.fastReconnectTimer) > 60000) {
        this.fastReconnectTimer = now;
        const isAudioActive: boolean = this.localUser.isAudioActive()
        this.micStatusChanged(false)
        try {
          let hasFilter = false;
          if(this.publisher?.stream.filter) {
            hasFilter = true;
            await this.publisher?.stream.removeFilter();
          }
          await this.publisher?.stream.reconnect();
          
          if(hasFilter)
            await this.applyAudioFilter(4);
        } catch (e) {

        }
        this.micStatusChanged(isAudioActive)
      } else {
        reject(new Error('Necessário aguardar 10s'));
      }
    })
  }

  public reconnectUser() {
    console.log('reconnectUser')
    return new Promise(async (resolve: any, reject: any): Promise<void> => {
      const now: number = (new Date).getTime();
      if ((now - this.reconnectTimer) > 10000) {
        this.reconnectTimer = now;
        this.fastReconnectTimer = now + 28000;
        if (!this.tryingToReconnect) {
          this.tryingToReconnect = true;
          const currentSessionId = this.sessionId;
          const currentLocalUser: UserModel = new UserModel();
          Object.assign(currentLocalUser, this.localUser);
          currentLocalUser.setStreamManager(null);
          
          try {
            clearInterval(this.statsCheckPID)
            this.removePublisher();
            this.session?.disconnect();
            await sleep(100)
          } catch (error) {

          }
          
          this.subscribersList = []
          this.sessionId = currentSessionId;
          this.localUser = currentLocalUser;
          const strAlert: string = "Reconnecting in the room";
          this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString(), 'info');
          const d = await this.joinRoom().then(() => {
            const strAlert: string = "Reconnected in the room";
            this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString(), 'primary');
          }).catch(() => {
            const strAlert: string = "Oops! Problems in your connection, we are trying to reconnect you the room ...";
            this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString(), 'warning');
          })

          if (this.joinCallback)
            this.joinCallback()

          this.tryingToReconnect = false;
          this.alertHeadline?.setHidTimeout(7000)
        } else {
          console.log('Já esta em processo de reconexão')
          reject(new Error('Já esta em processo de reconexão'));
        }
      } else {
        console.log('Já esta em processo de reconexão, 10s waiting')
        reject(new Error('Já esta em processo de reconexão, 10s waiting'));
      }
    })
  }

  public disconnectionEventsTriggers() {
    if (this.session) {
      this.session.on("sessionDisconnected", (event: any) => {
        console.log('SessionDisconnected ->> ', event)
        if (event.reason === 'networkDisconnect') {
          this.networkDisconnected = true;
          this.leaveRoom();
          const strAlert: string = "Oops! You fell from the room, go new to reconnect the room";
          this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString());
          setTimeout(() => {
            console.log('Atualizando a tela, após desconexão')
            this.reconnectUser().then(() => {
              this.networkDisconnected = false;
            });
            //location.reload();
          }, 6000)
        } else if(event.reason == "forceDisconnectByServer") {
            this.leaveRoom();
        } else if(event.reason == "disconnect") {
          // do nothing
        } else {
          if (this.leaveRoomCallback)
            this.leaveRoomCallback();

          clearInterval(this.intervalPID);
        }
      });
      this.session.on('reconnecting', (e) => {
        console.warn('reconnecting', e)
        const strAlert: string = "Oops! Problems in your connection, we are trying to reconnect you the room ...";
        this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString(), 'warning');
      });
      this.session.on('reconnected', (e) => {
        console.warn('ReconnectedEvent ->>', e)

        console.log('Sending signal of reconnected...')
        const strAlert: string = "Reconnected in the room";
        this.alertHeadline = new AlertHeadline(i18n.t(strAlert).toString(), 'primary');
        this.alertHeadline.setHidTimeout(3000)
      });
      

    }
  }

  public subscriberSpeaking() {
    if (this.session)
      this.session.on('publisherStartSpeaking', (event: any) => {
        console.log('Publisher ' + event.connection.connectionId + ' start speaking');
      });
    if (this.session)
      this.session.on('publisherStopSpeaking', (event: any) => {
        console.log('Publisher ' + event.connection.connectionId + ' stop speaking');
      });
  }

  public joinShareRoom(): Promise<any> {
    console.log('joining room');
    this.sessionShare = this.OVShare!.initSession();

    return this.connectToSession(true).then(token => {
      if (this.sessionShare && this.OVShare) {
        this.sessionShare.connect(token, { clientData: this.nickname })
          .then(() => {
            if (this.sessionShare && this.OVShare)
              if(this.OVShare?.iceServers instanceof Array && this.OVShare.advancedConfiguration.iceServers instanceof Array) {
                this.OVShare.advancedConfiguration.iceServers?.push(...this.OVShare?.iceServers)
              }
            console.log('connected to session Share')
          })
          .catch((error) => {
            console.log("There was an error connecting to the session:", error.code, error.message);
          });
      }
    })
  }

  public connectToSession(share?: boolean): Promise<any> {
    return new Promise(async (resolve: any, reject: any): Promise<any> => {
      await this.getToken(this.sessionId).then((token: any) => {
        console.log('connecting to session');
        if (!this.session) {
          throw Error("Session is not defined")
        }
        if (!share) {
          this.session.connect(token, { clientData: this.nickname, appFrom: this.appType, screenShareActive: false })
            .then(() => {
              try {
                if(this.OV?.iceServers instanceof Array && this.OV.advancedConfiguration.iceServers instanceof Array) {
                  this.OV.advancedConfiguration.iceServers?.push(...this.OV?.iceServers)
                }
                if (this.hasPublisher && this.publisher) {
                  this.session!.publish(this.publisher).then((c) => {
                    this.publisher!.publishVideo(true);
                    this.publisher!.publishAudio(true);
                    this.localUser.setStreamManager(this.publisher);
                    this.localUser.setConnectionId(this.session!.connection.connectionId);
                    resolve();
                  }).catch(reject);
                } else {
                  console.log('connect to web cam')
                  this.connectWebCam().then(resolve)
                    .catch(reject);
                }
              } catch {
                reject();
              }
            })
            .catch((error) => {
              console.log("There was an error connecting to the session:", error.code, error.message);
              reject();
            });
        } else {
          resolve(token);
        }
      }).catch((error) => {
        console.log("There was an error connecting to the session:", error.code, error.message);
        //sconsole.log(error.response);
        reject(error);
      });
    });
  }

  private async checkVideoOutboundStats() {
    const currentStats: any = await this.localUser.getStreamManager()?.stream?.webRtcStats?.getCommonStats();

    if(!currentStats) return;
    
    const totalBytesSentBefore = this.localUser.webRtcCommomStats?.outbound?.video?.bytesSent || 0;
    const totalBytesSentNow = currentStats.outbound?.video?.bytesSent || 0;

    console.log('checkVideoOutboundStats',currentStats,this.localUser.webRtcCommomStats)
    
    const bytesSent: number = Math.abs(totalBytesSentNow - totalBytesSentBefore);

    const itsSendingVideoCorrectly = !this.localUser.isVideoActive() || (bytesSent != 0);

    if (!itsSendingVideoCorrectly) {
      this.handleVideoTransmissionError();
    }

    this.localUser.webRtcCommomStats = currentStats;
    
    return itsSendingVideoCorrectly;
  }

  private handleVideoTransmissionError() {
    if (this.tryingToReconnect || this.checkingVideoTransmission || this.maxCheckingVideoTransmissionTimes > 5) return;
    
    console.log('handleVideoTransmissionError')
    
    this.checkingVideoTransmission = true;
    this.maxCheckingVideoTransmissionTimes += 1;
    
    setTimeout(async () => {
      const videoTransmissionStillWorks = await this.checkVideoOutboundStats();
      this.checkingVideoTransmission = false;

      if (videoTransmissionStillWorks || this.tryingToReconnect) return;

      this.checkingVideoTransmissionTimeout += 30000;

      if (this?.errorSendingVideoCallback) {
        await this.errorSendingVideoCallback();
      }
      
    }, this.checkingVideoTransmissionTimeout);
  }

  public subscribeToStreamCreated() {
    if (this.session)
      this.session.on("streamCreated", (event: any) => {
        console.log("streamCreated", event)
        let x: any = undefined;
        let subscriber = this.session!.subscribe(event.stream, x);
        console.log("subscriber", subscriber)
        subscriber.on("videoElementCreated", (event: any) => {
          this.numOfVideos++;
          // me.updateLayout();
        });
        const newUser: UserModel = new UserModel();
        newUser.setStreamManager(subscriber);
        newUser.setConnectionId(event.stream.connection.connectionId);
        newUser.setType('remote');
        //always max
        newUser.speakerSpace = this.MAX_PARTICIPANTS_IN_ROOM;

        subscriber.on('streamAudioVolumeChange', (event: any) => {
          //se tem alguem falando
          newUser.audioVolume = event.value.newValue;
          newUser.setSpeaking((event.value.newValue > this.audioThreadSold));
        });


        try {
          const dataConn: any = JSON.parse(event.stream.connection.data.split('%')[0]);

          newUser.setNickname(dataConn.clientData);
          if (dataConn.candidateBlindProcess !== undefined) {
            newUser.blinded = !!dataConn.candidateBlindProcess;
            newUser.setVideoActive(!dataConn.candidateBlindProcess);
          }

          if (dataConn.appFrom != undefined)
            newUser.appFrom = dataConn.appFrom;

          if (dataConn.screenShareActive != undefined)
            newUser.setScreenShareActive(dataConn.screenShareActive);


          if (dataConn.random != undefined)
            newUser.random = dataConn.random;

        } catch (e) {
          console.log("Erro obtendo o nickname");
        }
        console.log("adding new subscriber to the party", newUser.nickname);
        this.subscribersList.push(newUser);       
        
        if (this.signalUserChangedCallback)
          this.signalUserChangedCallback();

        setTimeout(() => {
          if (this.localUser) {
            console.log('updating data for: user')
            this.sendSignalUserChanged({
              isAudioActive: this.localUser.isAudioActive(),
              isVideoActive: this.localUser.isVideoActive(),
              nickname: this.localUser.getNickname(),
              blinded: this.localUser.blinded,
              fmEnabled: this.localUser.fmEnabled,
              isScreenShareActive: this.localUser.isScreenShareActive(),
            }, [event.stream.connection]);
            if (this.recordingEnabled)
              this.sendRecordingChanced({ status: 'started' })
          }
          if (this.screenShareUser && this.screenShareUser.isScreenShareActive()) {
            this.sendSignalShareUserChanged({
              isAudioActive: this.screenShareUser.isAudioActive(),
              isVideoActive: this.screenShareUser.isVideoActive(),
              nickname: this.screenShareUser.getNickname(),
              blinded: false,
              isScreenShareActive: this.screenShareUser.isScreenShareActive(),
            });
          }
        }, 5000)
      })
  }

  public initSubscribersOrdering() {
    try {
      if(!window['jbprod'])
        console.log('initSubscribersOrdering')
      // if(this.platform.isMobileDevice())
      //   return;

      if (!this.statsSubscribersOrderingStarted) {
        if(!window['jbprod'])
          console.log('initSubscribersOrdering.statsSubscribersOrderingStarted')
        this.statsSubscribersOrderingStarted = true;
        const participantsNumber = this.subscribersList.length;
        if(!window['jbprod'])
          console.log('initSubscribersOrdering.participantsNumber==',participantsNumber,'order=',(participantsNumber > this.MAX_PARTICIPANTS_IN_ROOM))
        if(participantsNumber > this.MAX_PARTICIPANTS_IN_ROOM) {
          let nspeakers: number = 0;
          for (let index = 0; index < this.subscribersList.length; index++) {
            const element = this.subscribersList[index];
            if(element.speaking) {
              nspeakers++;
              if(element.speakerSpace > (this.MAX_PARTICIPANTS_IN_ROOM -2))
                element.speakerSpace = (Math.floor((Math.random() * (this.MAX_PARTICIPANTS_IN_ROOM -2))))
            } else if((!element.speaking && element.speakerSpace <= (this.MAX_PARTICIPANTS_IN_ROOM -2) && this.numberOfSpeakers > this.MAX_PARTICIPANTS_IN_ROOM/2)  || !element.isAudioActive()) {
              if(element.speakerSpace < this.MAX_PARTICIPANTS_IN_ROOM)
                element.speakerSpace = this.MAX_PARTICIPANTS_IN_ROOM;
            }
          }
          this.subscribersList.sort((a: UserModel,b : UserModel) => {
            return (a.speakerSpace - b.speakerSpace)   
          })
          const nn: number  = (new Date()).getTime();
          const differenceInMillis = Math.abs(nn - this.nextChange);
          //  wait at least 4 cycles of initStatCheck
          if (differenceInMillis >= 15000 && this.signalUserChangedCallback) {
            this.nextChange = nn;
            this.signalUserChangedCallback
          }
        }
        this.statsSubscribersOrderingStarted = false;
      }
      
    } catch (error) {
      console.error(error)
    }

  }
  
  public initStatsCheck() {
    try {
      
      // if(this.platform.isMobileDevice())
      //   return;

      clearInterval(this.statsCheckPID)
      this.statsCheckPID = setInterval(() => {
        if (!this.statsCheckStarted) {         
          if(!window['jbprod'])
            console.debug('initStatsCheck', (new Date()).getTime(),this.statsCheckStarted)
          // cant access previus if not started
          this.checkVideoOutboundStats();       
          this.initSubscribersOrdering();

          this.statsCheckStarted = true;
          this.subscribersList.forEach(async (remoteUser: UserModel) => {
            try {
              const currentStats: any = await remoteUser.getStreamManager().stream.webRtcStats.getCommonStats();
              if(remoteUser.webRtcCommomStats?.inbound?.audio?.bytesReceived === 0 && remoteUser.webRtcCommomStats?.inbound?.video?.bytesReceived === 0) {
                this.checkConnection(remoteUser.getStreamManager().stream.connection.connectionId)
                .then(() => {
                  this.sendSignalUserChanged({
                    zeroBytesFromAudioAndVideo: true
                  }, [remoteUser.getStreamManager().stream.connection])
                }).catch(() => {
                  this.deleteSubscriber(remoteUser.getStreamManager().stream)
                })
              }
              if(remoteUser.webRtcCommomStats?.inbound?.audio?.bytesReceived && remoteUser.webRtcCommomStats?.inbound?.video?.bytesReceived &&
                (currentStats.inbound?.audio?.bytesReceived  == remoteUser.webRtcCommomStats?.inbound?.audio?.bytesReceived) &&
                (currentStats.inbound?.video?.bytesReceived  == remoteUser.webRtcCommomStats?.inbound?.video?.bytesReceived)) {
                remoteUser.statCounter++;
                if(remoteUser.statCounter >=5) {
                  // this subscriber is broken
                  this.deleteSubscriber(remoteUser.getStreamManager().stream);
                }
                return;
              }
              if (currentStats !== null) {

                const iceConnectionState = remoteUser.getStreamManager().stream.getRTCPeerConnection().iceConnectionState;
                if (iceConnectionState == 'failed') {
                  console.log('broken ice counter in ',iceConnectionState, remoteUser.getStreamManager().stream.streamId, remoteUser.statCounter )
                  if(remoteUser.getConnectionId() == this.localUser.getConnectionId()) {
                    console.log('I am broken');
                    return;
                  }

                  if (remoteUser.statCounter == 3) {
                    await remoteUser.getStreamManager().stream.initWebRtcPeerReceive(true);
                    return;
                  }
                  remoteUser.statCounter++;
                  return;
                }
                if (remoteUser.webRtcCommomStats != null && currentStats.inbound?.audio?.bytesReceived) {
                  let somethingSeemsWrongInAudio: boolean = remoteUser.webRtcCommomStats?.inbound?.audio?.packetsLost > 10000;
                  let somethingSeemsWrongInVideo: boolean = remoteUser.webRtcCommomStats?.inbound?.video?.pliCount > 5000 && remoteUser.webRtcCommomStats?.inbound?.video?.nackCount > 20;
                  
                  somethingSeemsWrongInAudio = somethingSeemsWrongInAudio || (Math.abs(remoteUser.audioVolume) == Infinity && remoteUser.isAudioActive());

                  if (somethingSeemsWrongInAudio || somethingSeemsWrongInVideo) {
                    console.warn("warn to ", remoteUser.getNickname())
                    console.warn("somethingSeemsWrongInAudio", somethingSeemsWrongInAudio, remoteUser.webRtcCommomStats?.inbound?.audio?.packetsLost)
                    console.warn("somethingSeemsWrongInVideo", somethingSeemsWrongInVideo, remoteUser.webRtcCommomStats?.inbound?.video?.pliCount, remoteUser.webRtcCommomStats?.inbound?.video?.nackCount)
                  }  
                  if(somethingSeemsWrongInAudio) {
                    if (remoteUser.statCounter >= 3) {
                      this.sendSignalUserChanged({
                        somethingSeemsWrongInAudio
                      }, [remoteUser.getStreamManager().stream.connection])
                      remoteUser.statCounter = 0;
                    } else {
                      setTimeout(() => {
                        remoteUser.getStreamManager().stream.initWebRtcPeerReceive(true)
                      }, 2000*remoteUser.statCounter);
                      remoteUser.statCounter++;  
                    }
                  } else if(somethingSeemsWrongInVideo) {
                    await remoteUser.getStreamManager().stream.initWebRtcPeerReceive(true);
                    remoteUser.statCounter = 0;
                  } else {
                    remoteUser.statCounter = 0;
                  }           
                } 
                remoteUser.webRtcCommomStats = currentStats;
              }
            } catch (e) {
              console.error("statsCheckPID Err >> ", e)
            }
          });
          this.statsCheckStarted = false;
        }
      }, 4000)
    
    } catch (error) {
        
    }
  }


  public leaveRoom() {
    
    clearInterval(this.intervalPID)
    clearInterval(this.statsCheckPID)

    if (this.session)
      this.session.disconnect();

    this.session = null;
    this.sessionId = "";
    this.localUser = new UserModel();
    this.subscribersList = [];


    if (this.leaveRoomCallback)
      this.leaveRoomCallback();
  }

  /* AUXILIARY MEHTODS */

  public muteAudio() {
    if (this.publisher) {
      this.audioEnabled = !this.audioEnabled;
      this.publisher.publishAudio(this.audioEnabled);
      const muteBTN: HTMLElement | null = document.getElementById("mute-audio");
      if (muteBTN == null)
        return false;
      if (!this.audioEnabled) {
        muteBTN.classList.remove("btn-primary");
        muteBTN.classList.add("btn-default");
      } else {
        muteBTN.classList.add("btn-primary");
        muteBTN.classList.remove("btn-default");
      }
      return true;
    }
    return false;
  }

  public muteVideo() {
    if (this.publisher) {
      this.videoEnabled = !this.videoEnabled;
      this.publisher!.publishVideo(this.videoEnabled);

      const muteBTN: HTMLElement | null = document.getElementById("mute-video");
      if (muteBTN == null)
        return false;

      if (!this.videoEnabled) {
        muteBTN.classList.remove("btn-primary");
        muteBTN.classList.add("btn-default");
      } else {
        muteBTN.classList.add("btn-primary");
        muteBTN.classList.remove("btn-default");
      }

      return true;
    }
    return false;
  }

  public camStatusChanged(blindIsEnabled?: boolean) {
    // não deve acessar alteração de status da camera se for candidato e esta em blind
    if (this.appType === EApplication.Candidate && blindIsEnabled == true && this.localUser.isVideoActive() == false)
      return;

    this.localUser.setVideoActive(!this.localUser.isVideoActive());
    this.localUser.getStreamManager().publishVideo(this.localUser.isVideoActive());
    this.sendSignalUserChanged({ isVideoActive: this.localUser.isVideoActive() });
  }

  public micStatusChanged(force?: boolean) {
    console.log('entro aqui pra fecha mic', this.localUser.getConnectionId())
    this.localUser.setAudioActive(force || (!this.localUser.isAudioActive()));
    this.localUser.getStreamManager().publishAudio(force || this.localUser.isAudioActive());
    this.sendSignalUserChanged({ isAudioActive: force || this.localUser.isAudioActive() });
  }

  public nicknameChanged(nickname) {
    this.localUser.setNickname(nickname);
    this.sendSignalUserChanged({ nickname: this.localUser.getNickname() });
  }

  public randomString() {
    return Math.random().toString(36).slice(2);
  }

  // 'Session' page
  public showSessionHideJoin() {
    //TODO add changes to layout if nescessary
  }

  // 'Join' page
  public showJoinHideSession() {
    //TODO add changes to layout if nescessary
  }

  // Prepare HTML dynamic elements (URL clipboard input)
  public initializeSessionView() {
    //TODO add changes to layout if nescessary
  }

  // Dynamic layout adjustemnt depending on number of videos


  public getToken(mySessionId: any) {
    let me: OpenViduApp = this;
    if (mySessionId) {
      return me.createToken(mySessionId)
    }
    return this.createSession(mySessionId).then((data) => me.createToken(data.key));
  }

  public removeParticipant(strid: string) {
    let r = new RoomAccess();
    let data: any = { id: 0, sid: this.sessionId, strid };
    return r.delete(data, true);
  }

  public forceRemoveParticipant(cid: string) {
    let r = new RoomAccess();
    let data: any = { id: 0, sid: this.sessionId, cid };
    return r.delete(data, true);
  }
  
  public checkConnection(cid: string) {
    let r = new RoomAccess();
    let data: any = { id: 0, sid: this.sessionId, cid };
    return r.fetchWithData(data, true);
  }

  public createSession(mySessionId: any): Promise<any> {
    let r = new Room();
    if (!this.purpose) {
      this.purpose = 3;
    }

    switch (this.purpose) {
      case EVideoPurpose.LiveInterview:
        return r.createLiveInterview(2, mySessionId).then((data) => { this.sessionId = data.key; return data; });
      case EVideoPurpose.Curriculum:
        return r.createCurriculum(2, mySessionId).then((data) => { this.sessionId = data.key; return data; });
      default:
        return r.createInterview(2, mySessionId).then((data) => { this.sessionId = data.key; return data; });
    }
  }

  public createToken(sId: any) {
    let r = new RoomAccess();
    return new Promise((resolve: any, reject: any): any => {
      let data: any = { sid: sId };
      try {

        console.log('there is filter enabled', this.enableFilter)
        if (this.enableFilter) {
          data.filter = true;
        }

        data.platform = navigator.platform;
        data.userAgent = navigator.userAgent;
        data.appVersion = navigator.appVersion;

        data.userName = this.nickname;
        data.userEmail = this.userEmail;

      } catch (e) {
        console.log('Could not set navigator settings')
      }
      r.create(data, 'application/json', true).then((dt) => {
        this.currentRAToken = dt?.raToken || null;
        this.showMoreFilters = dt?.moreFilters || null;
        resolve(dt?.token)
      }).catch(async (err) => {
        if (err?.response?.data?.detail?.indexOf('Limite no uso da Sala de entrevista para seu plano') > -1) {
          reject(err);
          return;
        }
        await sleep(3);
        r.create(data, 'application/json', true).then((dt1) => {
          this.currentRAToken = dt1?.raToken || null;
          resolve(dt1?.token);
        }).catch(reject)
      });
    });
  }

  public async screenShare() {
    this.OVShare = new OpenVidu()
    if (this.OVShare) {
      const _this: OpenViduApp = this;
      const x: any = undefined;

      this.screenSharePublisher = this.OVShare.initPublisher(
        x,
        {
          videoSource: 'screen',
          publishAudio: false,
          publishVideo: true,
          mirror: false,
        },
        (error) => {
          if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
            // this.setState({ showExtensionDialog: true });
          } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
            alert('Your browser does not support screen sharing');
          } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
            alert('You need to enable screen sharing extension');
          } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
            alert('You need to choose a window or application to share');
          }
        },
      );

      await this.joinShareRoom();

      this.screenSharePublisher.once('accessAllowed', () => {
        setTimeout(() => {
          if (this.screenSharePublisher && this.sessionShare && this.sessionShare.connection) {
            //this.session!.unpublish(this.publisher);
            this.screenShareUser = new UserModel();
            this.screenShareUser.nickname = this.localUser.nickname;
            this.screenShareUser.setStreamManager(this.screenSharePublisher);
            this.screenShareUser.setConnectionId(this.sessionShare!.connection.connectionId);
            this.sessionShare.publish(this.screenShareUser.getStreamManager()).then(() => {
              console.log('share screen is true ');
              this.screenShareUser!.setScreenShareActive(true);
              this.sendSignalShareUserChanged({ isScreenShareActive: true, nickname: this.localUser.getNickname() });
              this.handleStopScreenEvent();
            });
          }
        }, 1500);
      });
      this.screenSharePublisher.on('streamPlaying', () => {
        // this.updateLayout();
        if (this.streamPlayingCallback)
          this.streamPlayingCallback()
        this.screenSharePublisher!.videos[0]!.video!.parentElement!.classList.remove('custom-class');
      });
    }
  }

  public handleStopScreenEvent() {
    this.screenSharePublisher!.stream.getMediaStream().addEventListener('inactive', () => {
      this.stopScreenShare();
    });
    this.screenSharePublisher!.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
      this.stopScreenShare();
    });
  }

  public stopScreenShare() {
    if (this.screenSharePublisher) {
      this.sessionShare!.unpublish(this.screenSharePublisher);
      this.screenSharePublisher = null;
      this.screenShareUser = new UserModel();
      this.sessionShare!.disconnect();
      this.sessionShare = null;
      this.OVShare = null;
    }
  }


  public watchChatChange(watcher: any) {
    this.publisher!.stream.session.on('signal:chat', (event: any) => {
      watcher(event)
    });
  }

  public sendSignalByType(data: any, type: string, conn?: Connection[]) {
    if (this.session) {
      data['role'] = this.session.openvidu.role;
      data['appFrom'] = this.appType;
      // posso usar o local nesse caso - pois quem esta shareando eh o proprio
      data['userType'] = this.localUser.userType;
      data['userId'] = this.localUser.userId;
      data['userGuid'] = this.localUser.userGuid;
      const signalOptions: SignalOptions = {
        data: JSON.stringify(data),
        type: type,
      };

      if (conn && conn.length > 0)
        signalOptions.to = conn;

      this.session.signal(signalOptions);
    }
  }

  public sendRecordingChanced(data: any) {
    if (this.session) {
      this.sendSignalByType(data, 'recordingEvent');
    }
  }

  public sendSignalUserChanged(data: any, conn?: Connection[]) {
    if (this.session) {
      this.sendSignalByType(data, 'userChanged', conn);
    }
  }

  public sendSignalShareUserChanged(data: any) {
    if (this.sessionShare) {
      data['role'] = this.sessionShare.openvidu.role;
      data['appFrom'] = this.appType;
      // posso usar o local nesse caso - pois quem esta shareando eh o proprio
      data['userType'] = this.localUser.userType;
      data['userId'] = this.localUser.userId;
      data['userGuid'] = this.localUser.userGuid;
      const signalOptions = {
        data: JSON.stringify(data),
        type: 'userChanged',
      };
      this.sessionShare.signal(signalOptions);
    }
  }


  /* #region  Devices management */
  public getDevices() {
    return new Promise(function (resolve, reject) {
      navigator.mediaDevices.enumerateDevices().then(function (deviceInfos) {
        let devices: { kind: string | null, deviceId: string | null, label: string | null }[] = [];
        // Rest of platforms
        let cameras = 0;
        let microfones = 0;
        deviceInfos.forEach(function (deviceInfo, index) {
          if (deviceInfo.kind === 'audioinput' || deviceInfo.kind === 'videoinput') {

            if (deviceInfo.kind === 'videoinput')
              cameras++;

            if (deviceInfo.kind === 'audioinput')
              microfones++;

            devices.push({
              kind: deviceInfo.kind,
              deviceId: deviceInfo.deviceId,
              label: (deviceInfo.label || ((deviceInfo.kind == "videoinput") ? "Câmera" + " " + (cameras) : "Microfone" + " " + (microfones)))
            });
          }
        });
        resolve(devices);
      })["catch"](function (error) {
        console.error('Error getting devices', error);
        reject(error);
      });
    });
  }
  public initDevices() {
    this.getDevices().then((devices: any) => {
      console.log('Setting up audio and Video Devices')
      this.audioDevices = devices.filter((device) => device.kind === 'audioinput');
      this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
    });
  }

  public async toggleCamera(videoDeviceId?: string, audioDeviceId?: any) {
    this.currentVideoDeviceId = videoDeviceId || undefined;
    this.currentAudioDeviceId = audioDeviceId || undefined;
    if (this.session && this.publisher) {
      const unpublished = true;
      const hackUN: any = undefined;
      let publishedNew: boolean = false;
      let publisher: Publisher | null = null;
      const currentPublisher: Publisher = this.localUser.getStreamManager();
      try {
        await this.session.unpublish(this.publisher);
        await this.openCamera();
        
        if(this.toggleCameraCallback)
          this.toggleCameraCallback();

        const opt: any = {
          videoSource: (this.canvaMediaStream != null) ? this.canvaMediaStream : this.currentVideoDeviceId,
          audioSource: this.currentAudioDeviceId,
          publishAudio: this.localUser.isAudioActive(),
          publishVideo: this.localUser.isVideoActive(),
          mirror: false
        };
        
        publisher = this.OV.initPublisher(hackUN, opt)
        this.localUser.setStreamManager(null);
        if (publisher) {
          this.localUser.setStreamManager(publisher);
          if (this.session) {
            await this.session.publish(publisher);
            this.publisher = publisher;
            setTimeout(() => {
              this.localUser.getStreamManager().addVideoElement(document.getElementById('video-local') as HTMLVideoElement)
              console.log('sendSignalUserChanged toggleCamera', this.nickname)
              this.sendSignalUserChanged({ 
                isAudioActive: this.localUser.isAudioActive(), 
                isVideoActive: this.localUser.isVideoActive(), 
                nickname: this.localUser.nickname
              });
            },1000)
            publishedNew = true;
          }
        }
      } catch (e) {
        //tenta retornar ao anterior
        if (unpublished)
          this.session.publish(currentPublisher);

        if (publishedNew && publisher)
          this.session.unpublish(publisher);

      }
    }
  }

  /* #endregion */


}
