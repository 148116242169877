<template>
<transition name="fade" appear>
  <div id="plural">
    <!-- <br/> -->
    <!-- <h1>PLURAL ARTIGOS</h1> -->
    <br/>


    <b-row v-if="cursos!=null" class="align-content-stretch flex-wrap">
      <b-col class="mb-2" sm="12" md="12" lg="4" v-for="(curso, i) in cursos" :key="i">
        <b-card class="card-curso" @click="view(curso.tituloUrl)">
          <div class="card-curso__content">
            <img :src="curso.arquivo" alt="Imagem curso"/>
          </div>

          <div class="card-curso__header">
            <b-col>
              <div class="card-curso__header-title">
                <p>{{curso.titulo}}</p>
              </div>
            </b-col>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else class="align-content-stretch flex-wrap">
      <b-col class="mb-2" sm="12" md="12" lg="4">
        <b-card class="card-curso">
          <div class="card-curso__content">
            <img src="/static/img/plural/cursos/curso1.png" alt="Imagem curso 1"/>
          </div>

          <div class="card-curso__header">
            <b-col>
              <div class="card-curso__header-title">
                <p>Viés Inconsciente com Regina Madalozzo</p>
              </div>
            </b-col>
          </div>
        </b-card>
      </b-col>
      <b-col class="mb-2" sm="12" md="12" lg="4">
        <b-card class="card-curso" >
          <div class="card-curso__content">
            <img src="/static/img/plural/cursos/curso2.png" alt="Imagem curso 2"/>
          </div>

          <div class="card-curso__header">
            <b-col>
              <div class="card-curso__header-title">
                <p>Diversidade e Inclusão nas Organizações com Cristina Kerr</p>
              </div>
            </b-col>
          </div>
        </b-card>
      </b-col>
    </b-row>


    <!-- <b-row v-else class="align-content-stretch flex-wrap">
      <b-col class="mb-2" sm="12" md="12" lg="12">
        <img src="/static/img/plural/cursos/embreve.jfif" alt="Em Breve" height="100%" width="100%">
      </b-col>
    </b-row> -->


  </div>
</transition>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import { PluralPostEntity } from '@/core/models/Entities';
import Vue from "vue";
import Component from "vue-class-component";

@Component<PluralCursos>({})
export default class PluralCursos extends Vue {
  public service: PluralPostService;
  public cursos: PluralPostEntity[]|null = null;
  public loadingPage:boolean = false;
  public smallText: string = "";

  constructor() {
    super();
    this.service = new PluralPostService(); 
  }

  public mounted() {
    this.loadingPage = true;

    this.service.fetchAll({g:'CURSO'}).then((data: any) => {
      console.log(data);
      if(data['pluralPosts'].length > 0){
        this.cursos = data['pluralPosts'];
        this.cursos?.reverse();
      }
      
    }).catch((err) => { 
      console.log(err) 
    }).finally(()=> {
      this.loadingPage = false;
    })
  }

  public getSmallText(Text: string){
    return Text.slice(0,150);
  }

  public view(url:any) {
    this.$router.push({ 
      name: 'PluralCursoView', 
      params: { 
        url: url
      }, 
      query: {lang: this.$i18n.locale} });
  }
  
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

  $card-transition: all ease-in-out 0.24s;

  iframe {
    border-radius: 4px;
  }


   .card-curso {
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

    &__header {
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      color: $blue;
      border-bottom: $blue 1px dashed;
      text-align: center;
      /* height: 100%; */
    }
    &__content {
      overflow: hidden;
      img {
        height: 200px;
      }
    }
  } 



</style>