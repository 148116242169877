// tslint:disable
import Vue from "vue";
import { MutationTree } from "vuex";
import { ProfileState } from "./types";
import { CandidatoEntity, EmpresaEntity, RecrutadorEntity } from '@/core/models/Entities';

export const mutations: MutationTree<ProfileState> = {
  authRequest(state: any): any  {
    state.status = "loading";
    state.error = false;
  },
  authSuccess(state: any, resp: any): any {
    state.status = "success";
    state.user = resp;
    state.token = sessionStorage.getItem("access_token");
    state.error = false;
  },
  authError(state: any): any {
    state.status = "error";
    state.error = true;
    state.token = null;
  },
  authLogout(state: any): any {
    state.user = null;
    state.error = false;
    state.token = null;
  },
  userRequest (state: any): any {
    state.status = "loading";
  },
  userSucess(state: any, resp: any): any {
    state.status = "success";
    state.profile = sessionStorage.getItem("profile");
    // vue.set(state, "profile", resp);
  },
  companySucess(state: any, resp: any): any {
    state.status = "success";
    state.profile = resp;
    Vue.set(state, "profile", resp);
  },
  userError(state:ProfileState): any {
    state.status = "error";
  },
  setEmpresaInfo(state: ProfileState, empresa: EmpresaEntity): void {
    Vue.set(state, "company_info", empresa);
    Vue.set(state, "profile_info", empresa.perfil);
  },
  setRecruiterInfo(state: ProfileState, recrutador: RecrutadorEntity): void {
    Vue.set(state, "recruiter_info", recrutador);
  },
  setSaldInfo(state: ProfileState, sald: number): void {
    Vue.set(state, "saldo", sald);
  },

  // Seta os valores passados em 'data' recursivamente no empresa_info
  setEmpresaData(state: ProfileState, data: object): void {
    const setData: any = (obj: object, data: object) => {
      Object.keys(data).forEach((key: string) => {
        const value = (<any> data)[key];

        if (typeof value === 'object') {
          setData((<any> obj)[key], value);
        }
        else {
          (<any> obj)[key] = value;
        }
      });
    }

    Object.keys(data).forEach((key: string) => {
      setData(state.company_info, data);
    });
  }
};