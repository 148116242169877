<template>
  <div :class="containerClasses" id="page-content">
    <div class="">
      <router-view></router-view>
    </div>
    <b-modal id="ModalExpiredPlan" :hide-footer="true" :hide-header="true">
      <div>
        <ExpiredPlan />
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import SearchInput from '@/components/search/Search.vue';
import ExpiredPlan from '@/components/expiredPlan/ExpiredPlan.vue'
import { EmpresaEntity } from '@/core/models/Entities';


@Component({
  components: { ExpiredPlan }
})
export default class Content extends Vue {
  @Getter("isEntrevistaPage", { namespace: 'profile' }) private isEntrevistaPage!: boolean;
  @Getter("companyInfo", { namespace: "profile" })
  public empresa?: EmpresaEntity;
  @Action('fetchEmpresaInfo', { namespace: 'profile' })
  public fetchEmpresaInfo!: any;

  public async created() {
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
      this.verifyPlan()
    }
  }

  public verifyPlan() {
    if (!!this.empresa) {
      const date = this.empresa?.perfil?.dtFinalPlano?.date?.slice(0, 10)
      const datePlan = new Date(date || "")
      const dateNow = new Date()
      if (datePlan <= dateNow)
        this.showModalExpiredPlan()
    }
  }

  public showModalExpiredPlan() {
    this.$bvModal.show('ModalExpiredPlan')
  }

  private get containerClasses() {
    return [
      'content',
      // {
      //   'no-header-height': this.isEntrevistaPage
      // }
    ]
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

/**
** Content component
**/
.content {
  flex: 1;
  height: calc(100vh - 55px); // @Todo - transformar a altura do "Header" em uma variável global
  overflow-y: auto;
  scroll-behavior: smooth;
  will-change: overflow;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  background-color: $back-content-color;
}

.no-header-height {
  height: 100vh;
}

@media (max-width: 575.98px) {
  .content {
    .container-fluid {
      padding: 56px 16px 16px;
    }
  }
}
</style>