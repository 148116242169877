
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { clientId } from '../../endpoint';
import { CandidatoEntity, PerfilCandidatoEntity } from '../../models/Entities';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import { CB } from '@/core/util'
import Repository from '@/core/Services/Repository';


export default class Candidato extends Repository {

  public entidade: CandidatoEntity;

  constructor(i18n?: any) {
    super(new ApiRouter({
        auth: { url: '/v1/oauth', method: 'POST', headers: {} },
        create: { url: '/v1/candidato', method: 'POST', headers: {} },
        fetch: { url: '/v1/candidato/{id}', method: 'GET', headers: {} },
        delete: { url: '/v1/candidato/{id}', method: 'DELETE', headers: {} },
        fetchAll: { url: '/v1/candidato', method: 'GET', headers: {} },
        info: { url: '/v1/my-info', method: 'GET', headers: {} },
        me: { url: '/v1/me', method: 'GET', headers: {} },
        meup: { url: '/v1/me/{id}', method: 'PUT', headers: {} },
        logout: { url: '/v1/me/{id}', method: 'DELETE', headers: {} },
        update: { url: '/v1/candidato/{id}', method: 'PUT', headers: {} },
        "files": { url: 'talent/files', method: 'GET', headers: {} },
        "hidden": { url: 'talent/hidden', method: 'GET', headers: {} },
      }));
    if (i18n) {
      this.$i18n = i18n;
    }
    this.entidade = new CandidatoEntity();
  }

  
  public fetchFiles(data?: any) {
    return this.call('files', (!data ? {} : data), 'application/json');
  }

  public fetchHidden(data?: any) {
    if (sessionStorage.getItem('access_token') !== null) {
      console.log('loginpromise')
      return this.call('hidden', (!data ? {} : data), 'application/json');
    } else {
      console.log('inpromise')
      return new Promise((resolve: any, reject: any) => {
        (new ClientAuthentication()).auth().then((rtoken: any) => {
          const dta: any = {
            rtoken,
            ...data
          };
          const cdta = CB.genC(dta);
          return this.call('hidden', cdta, 'application/json', false, true).then(resolve).catch(reject);
        }).catch((resp) => {
            reject(resp);
        });
      });
    }
  }

  public create(data: any, ctype?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
          reject(resp);
      });
  });
  }

  public loadEntidade(promisse: Promise<Candidato>, callback?: () => void) {
    promisse.then((data: any) => {
      this.entidade = data;
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line:no-console
      console.log('Couldn\'t load my info.', err);
    });
  }

  public loadFromNomeUrl(url: string, callback?: () => void, errCallback?: () => void): void {
    if (sessionStorage.getItem('access_token') !== null) {
        this.loadEntidade(super.call('fetch', { id: 0, urlCode: url }, 'application/json'), callback);
    } else {
      this.publicLoadFromNomeUrl(url, callback, errCallback);
    }
  }

  public publicLoadFromNomeUrl(url: string, callback?: () => void, errCallback?: () => void): void {
    (new ClientAuthentication()).auth().then((token: any) => {
      const bcatch: boolean = !!errCallback;
      this.loadEntidade(super.call('fetch', { id: 0, urlCode: url, rtoken:token }, 'application/json',bcatch).catch(errCallback), callback);
    }).catch((resp) => {
      // tslint:disable-next-line:no-console
      if (errCallback)
        errCallback();
    });
  }

  public whatIsMissing(): string[] {
    let msg: string[] = [];
    const perfil: PerfilCandidatoEntity = this.entidade.perfil!;
    if (perfil) {
      // tslint:disable-next-line:no-console
      console.log(this.$i18n.locale);
      if (!(perfil.areaAtuacao))
        msg.push(this.$i18n.t('Área de Atuação') );

      if (!(perfil.profissao2))
        msg.push(this.$i18n.t('Profissão') );

      if (!(perfil.escolaridade))
        msg.push(this.$i18n.t('Escolaridade') );

      if (!(perfil.telefone))
        msg.push(this.$i18n.t('Telefone') );

      if (!(perfil.cep))
        msg.push(this.$i18n.t('CEP') );

      if (!(perfil.rua))
        msg.push(this.$i18n.t('Rua') );

        if (!(perfil.bairro))
        msg.push(this.$i18n.t('Bairro') );

      if (!(perfil.dataNasc))
        msg.push(this.$i18n.t('Data Nascimento'));
    }
    return msg;
  }

  public isRegistryCompleted(): boolean
  {
    if (this.entidade.perfil && this.entidade.perfil.cadastroConcluido !== "SIM")
      return false;

    return true;
  }

  public checkIfRegistryIsCompleted(): boolean
  {

    if (!this.isRegistryCompleted()) {
      this.openAlert(this.whatIsMissing(),this.$i18n.t("Falta pouco! Termine seu cadastro para continuar").toString())
      this.getRouter().push({path: "/edit", query: { lang: this.getI18n().locale }});
      return false;
    }

    return true;
  }

  public loadMyInfo(callback?: () => void): void {
    super.myInfo().then((data: any) => {
      this.entidade = data.entity;
      this.getI18n().locale = (this.getRouter().currentRoute.query.lang || this.entidade.perfil!.idioma!.idioma || this.entidade.lingua || 'pt-BR');
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line:no-console
      console.log('Couldn\'t load my info.', err);
    });
  }

  public auth(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = 'password';
    try {
      data.platform = navigator.platform;
      data.userAgent = navigator.userAgent;
      data.appVersion = navigator.appVersion;
    } catch (e) {
      console.log('Could not set navigator settings')
    }
    return super.auth(data);
  }

  public me() {
    this.meup();
    return super.me()
  }

  public meup(): Promise<any> {
    let data: any = {};
    try {
      data.id = 0;
      data.platform = navigator.platform;
      data.userAgent = navigator.userAgent;
      data.appVersion = navigator.appVersion;
    } catch (e) {
      console.log('Could not set navigator settings')
    }
    return super.meup(data);
  }

  public logout(): Promise<any> {
    return super.call('logout',  { id: 0 }, 'application/json').finally(() => { super.removeItensFromStorage(); this.pushToLogout(); });
  }

  public async save(): Promise<any> {
    this.cleanObject();

    return super.update(this.entidade)
      .then((data: any) => {
        this.entidade = data;
        return true;
      })
      .catch((err: any) => {
        if (this.entidade.perfilcandidato)
          this.entidade.perfil = this.entidade.perfilcandidato;
          
        return false;
      });
  }

  public saveProfileField(fieldName: string, value: any, candidatoId?: number) {
    const data: any = {};
    data[fieldName] = value;

    candidatoId = candidatoId || this.entidade!.id;

    return super.update({id: candidatoId, perfilcandidato: data });
  }

  public saveField(fieldName: string, value: any, candidatoId?: number) {
    const data: any = {};
    data.id = candidatoId || this.entidade!.id;
    data[fieldName] = value;
    return super.update(data);
  }


  public saveBackgroundAvatar(file: any): Promise<any> {
    return this.saveFile('bgavatar', file);
  }

  public saveAvatar(file: any): Promise<any> {
    return this.saveFile('avatar', file);
  }

  public saveCurriculum(file: any): Promise<any> {
    return this.saveFile('curriculumVitae', file);
  }

  public saveFile(key: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', '' + this.entidade.id);
    formData.append(key, file);
    return super.update(formData, 'multipart/form-data');
  }

  private cleanObject() {
    this.entidade.perfilcandidato = this.entidade.perfil;
    this.entidade.perfilcandidato!.idiomaId = this.entidade.perfil!.idioma!.id;
    delete this.entidade.perfilcandidato.curriculum;
    // delete this.entidade.perfilcandidato.geoJson;
    delete this.entidade.fotoAvatar;
    delete this.entidade.backgroundAvatar;
    delete this.entidade.completionMissing;
    delete this.entidade.dataAlteracao;
    delete this.entidade.dataCadastro;
    delete this.entidade.idMD5;
  }

}


