<template>
  <b-card no-title  class="my-2 py-2 shadow rounded-card" >
    <!-- body -->
    <b-row >
      <b-col md="12" class="mb-3 d-flex justify-content-between alignt-items-end">
        <h3 class="text-info m-0">{{$t('Professional')}}</h3>
        <icon class="text-primary" name="profissional" size="3x"></icon>
      </b-col>
      <b-col md="12" v-if="experiencias && experiencias.length > 0">
        <!-- <div class="table-responsive">
          <b-table striped :fields="fields" :items="items">
          </b-table>
        </div> -->
        
        <b-list-group>
          <b-list-group-item v-for="exp in experiencias" :key="exp.id" href="#"  class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{exp.atividade}}</h5>
              <small>{{periodText(exp.total)}}</small>
            </div>

            <p class="mb-1" v-if="exp.descricaoAtividade">
              {{exp.descricaoAtividade}}
            </p>

            <small>{{exp.segmento}}</small>
          </b-list-group-item>
        </b-list-group>

      </b-col>
      <b-col md="12" v-else-if="!temExperiencia">
        <b-alert
          show
          variant="info"
        >
        <div class="text-center">
        {{$t("I am searching for my first job")}}
        </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import { ExperienciaEntity } from '@/core/models/Entities';

@Component
export default class CardPdfProfessionalInfo extends Vue {

  @Prop()
  public experiencias!: ExperienciaEntity[];

  @Prop()
  public value!: CandidatoEntity;
   
  public items: any[] = [];
  public fields: any[] = [];

  constructor() {
    super();
  }

  get temExperiencia() {
    return this.value?.perfil?.temExperiencia === true;
  }

  public mounted(){
    this.fields = [
      { key: "atividade", label: this.$t('Post')},
      { key: "periodo", label: this.$t('Period') },
      { key: "segmento", label: this.$t('Segment') }
    ];
    // this.loadData()
  }  

  public loadData() {
    this.items = [];
    this.experiencias.forEach(el => {
      this.items.push({
        atividade: el.atividade,
        descricaoAtividade: el.descricaoAtividade,
        periodo: this.periodText(el.total),
        segmento: el.segmento||" - "
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
        text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }

}
</script>
