import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { VagaEntity, VagaPerguntaEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';


export default class GestaoVaga extends Repository {

    public entidade: VagaEntity;

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/gestao-vaga', method: 'POST', headers: {} },
            delete: { url: '/v1/gestao-vaga/{id}', method: 'DELETE', headers: {} },
            fetch: { url: '/v1/gestao-vaga/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/gestao-vaga', method: 'GET', headers: {} }, // tem parametro de Busca 's'
            update: { url: '/v1/gestao-vaga/{id}', method: 'PUT', headers: {} },
            }) );
        this.entidade = new VagaEntity();
    }

    public loadVaga(id: number): Promise<any> {
        return super.fetch(id).then((data: any) => {
            this.entidade = (data as VagaEntity);
        }).catch((err: any) => {
            console.error(err);
            throw new Error('Coun\'d load vaga');
        });
    }

    public loadPerguntaFromObject(perguntas?: VagaPerguntaEntity[]) {
        this.entidade.perguntas = [];
        if (perguntas) {
            perguntas.forEach((pergunta: VagaPerguntaEntity) => {
                this.entidade.perguntas.push(pergunta);
            });
        }
    }

    public save(): Promise<any> {
        if (this.entidade.id && this.entidade.id > 0) {
            return super.update(this.entidade);
        }
        return super.create(this.entidade);
    }
}
