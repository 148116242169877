<template>
  <div class="principal-container">
    <div class="row">
      <div class="col-12 col-md-12 text-center">
        <b-icon icon="check-circle" variant="white" class="rounded-circle bg-success p-1 h1 mb-2"></b-icon>
        <h1 class="title">{{ $t('Job created successfully!') }}</h1>
      </div>
    </div>
    <hr />
    <div class="box-finaliza">
      <div class="row">
        <div class="col-md-6">
          <div class="table-info-data">
            <h3>{{ vaga.posicao }}</h3>
            <div class="info">
              <div class="line">
                <div class="label">{{ $t('Salary') }}</div>
                <div class="value">{{ vaga.salario }}</div>
              </div>
              <div class="line">
                <div class="label"> {{ $t('Location') }} </div>
                <div class="value">{{ vaga.Localidade }}</div>
              </div>
              <div class="line">
                <div class="label"> {{ $t('Job Description') }} </div>
                <div class="value">{{ vaga.descricaoSemHtml }}</div>
              </div>
              <!-- <div class="line">
                            <div class="label">Competencias</div>
                            <div class="value" v-html="vaga.competencias"></div>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h3> {{ $t('Desired skills') }} </h3>
          <div class="row mt-2">
            <div v-for="(hab, i) in vaga.vagaHabilidades" v-bind:key="i" class="col-md-4 lista">
              <div class="card-competencia" style="width: 100%">
                <nivel-bar :label="hab.habilidade.name" :howManyLevels="5" :activeLevels="hab.nivel"></nivel-bar>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h3> {{ $t('Qualify Questions') }} </h3>
          <b-table small striped hover :items="vaga.PerguntasCorte" :fields="fieldsCorte">
            <template v-slot:cell(eliminatoria)="data">
              {{ data.item.eliminatoria == 1 ? 'Sim' : 'Não' }}
            </template>
          </b-table>
        </div>
        <div class="col-md-6">
          <h3> {{ $t('Questions for job') }} </h3>
          <b-table small striped hover :items="vaga.perguntas" :fields="fieldsQuestion">
            <template v-slot:cell(tempo)="data">
              {{ data.item.tempo }} {{ data.item.tempo == 1 ? $t('minute') : $t('minutes') }}
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mb-5" v-if="getTotalCreditosVaga(vaga) > 0">
        <div class="col-md-6 mx-auto">
          <div class="jobcoins-investidos">
            <h3> {{ $t('Invested interviews in the vacancy') }} </h3>
            <div class="points">{{ getTotalCreditosVaga(vaga) }}</div>
          </div>
        </div>
      </div>
      <div class="row buttons-footer">
        <div class="col-12 col-md-6">
          <button class="btn btn-outline-secondary text-uppercase rounded-pill" @click.prevent="voltarEditarVaga()">{{
            $t('Edit job') }}</button>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end">
          <button class="btn btn-outline-secondary text-uppercase rounded-pill" @click.prevent="voltarMinhasVagas()">{{
            $t('Save draft') }}</button>
          <button class="btn btn-info ml-3 btn-salvar text-uppercase rounded-pill" @click.prevent="publicarVaga()">
            <i class="icon-check"></i>
            {{ $t('Publish job') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import GestaoHCService from "@/core/Services/Empresa/GestaoCredito";
import GestaoVaga from '@/core/Services/Empresa/GestaoVaga';
import { VagaEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';


@Component
export default class VagaDetails extends Vue {
  public serviceVaga!: GestaoVaga;
  public vaga!: VagaEntity;
  public fieldsQuestion: any[] = [];

  public fieldsCorte: any[] = [];

  public historicoCreditoService: GestaoHCService = new GestaoHCService();
  // MEU SALDO GERAL
  public saldo: number = 0;

  public creditVaga: any = new Map<string, number>();

  constructor() {
    super();
    this.serviceVaga = new GestaoVaga();
    this.vaga = new VagaEntity();
  }

  mounted() {
    this.fieldsQuestion = [
      {
        key: 'numeroPergunta',
        label: 'Nº'
      },
      {
        key: 'pergunta',
        label: this.$t('Question').toString()
      },
      {
        key: 'tempo',
        label: this.$t('Time').toString()
      }
    ];

    this.fieldsCorte = [
      {
        key: 'pergunta',
        label: this.$t('Question').toString()
      },
      {
        key: 'eliminatoria',
        label: this.$t('Expected Answer').toString()
      }
    ];
  }

  public async created() {
    const vid = this.$route.params.id;
    await this.serviceVaga.loadVaga(parseInt(vid))
    this.vaga = this.serviceVaga.entidade;
    this.historicoCreditoService = new GestaoHCService();
    this.historicoCreditoService.fetchAll({ saldOnly: true }).then((data: any) => {
      this.saldo = data.saldo
    }).catch((err) => {
      console.log(err)
    })
  }

  public getTotalCreditosVaga(vaga: VagaEntity) {
    let total = 0;
    if (vaga.id && !this.creditVaga[vaga.id]) {
      this.creditVaga.set(vaga.id, new Map<string, number>());
      vaga.historicoCredito.forEach((element: any, index: number) => {
        if (element.tipo == "E") {
          total += element.creditoQtd;
        }
      });
      this.creditVaga.get(vaga.id).set('total', total);
    } else if (vaga.id && this.creditVaga.has(vaga.id) && this.creditVaga.get(vaga.id).has('total')) {
      return this.creditVaga.get(vaga.id).get('total')
    }
    return total;
  }

  public getSaldoCreditosVaga(vaga: VagaEntity) {
    let total = 0
    if (vaga.id && !(this.creditVaga.has(vaga.id) && this.creditVaga.get(vaga.id).has('saldo'))) {
      this.creditVaga.set(vaga.id, new Map<string, number>());
      vaga.historicoCredito.forEach((element: any, index: number) => {
        total = (element.tipo == "E") ? total + element.creditoQtd : ((element.tipo == "S") ? total - element.creditoQtd : 0);
      });
      this.creditVaga.get(vaga.id).set('saldo', total);
    } else if (vaga.id && this.creditVaga.has(vaga.id) && this.creditVaga.get(vaga.id).has('saldo')) {
      return this.creditVaga.get(vaga.id).get('saldo')
    }
    return total;
  }


  public voltarEditarVaga() {
    this.$router.push({ path: '/my-jobs-openings/edit/' + this.vaga.id, query: { lang: this.$i18n.locale } });
  }
  public voltarMinhasVagas() {
    this.$router.push({ path: '/my-jobs-openings/', query: { lang: this.$i18n.locale } });
  }
  public publicarVaga() {
    this.$confirm.addTitle(this.$t('Attention').toString())
    this.$confirm.addMsg(this.$t('Are you sure you would like to publish this position?'))
    this.$confirm.callBoxDanger().then((v: boolean) => {
      if (v) {
        this.serviceVaga.update({ status: 'ATIVO', id: this.vaga.id }).then(() => {
          this.vaga.status = 'ATIVO'
          this.$router.push({ path: '/my-jobs-openings/', query: { lang: this.$i18n.locale } });
        }).catch((err: any) => {
          console.log('err')
        });
      }
    });
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";
@import "@/assets/scss/_variables";

.principal-container {

  .box-finaliza {
    padding-top: 1em;

    h3 {
      font-size: 1.5em;
      margin: 1em 0;
      padding: 5px 10px;
      border-bottom: 1px solid #e8e8e8;
    }

    .table-info-data {
      .info {
        width: 100%;
        margin: 1em 0;

        .line {
          display: flex;
          line-height: 2;
          font-size: 1em;

          .label {
            width: 25%;
            text-align: right;
            margin-right: 0.5em;
          }

          .value {
            width: 75%;
            text-align: left;
            margin-left: 0.5em;
          }
        }
      }
    }

    .jobcoins-investidos {
      border-radius: 8px;
      border: 2px solid $blue;
      background-color: $blue;
      padding: 1em;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        margin: 0 0 1em 0;
      }

      .points {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #ffffff;
        border-radius: 2rem !important;
        margin: 0;
        height: 1.5em;
        min-width: 3em;
        font-size: 2em;
      }

      p {
        text-align: center;
        margin: 1em 6em;
      }
    }
  }
}

// Media Queries
@include media("<desktop") {
  .title {
    font-size: 2em !important;
  }
}

@include media(">desktop") {
  .title {
    font-size: 2em !important;
  }

  .box-data,
  .box-questions {
    font-size: 0.8em;
  }
}

@include media(">large") {
  .box-data {
    font-size: 1.4em;
  }
}
</style>

