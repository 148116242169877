<template>
  <div
    class="avatar"
    v-bind:class="{
      'avatar--primary': border === 'primary',
      'avatar--sm': size === 'sm',
      'avatar--md': size === 'md',
      'avatar--lg': size === 'lg'
    }"
  >
    <b-img class="avatar__img" :src="fotoSrc" :alt="alt" rounded="circle"></b-img>
    <button
      class="avatar-share-link d-inline-block"
      @click="copyLink"
      v-b-tooltip.hover
      :title="$t('Share Profile')"
    >
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Avatar extends Vue {
  @Prop({
    default:
      "https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/empty_avatar.png"
  })
  public src!: string;
  @Prop({ default: "" }) public alt!: string;
  @Prop({ default: "white" }) public border!: string;
  @Prop({ default: "lg" }) public size!: string;
  @Prop({ default: "" }) public nomeUrl!: string;

  constructor() {
    super();
  }

  public onCopy() {
    this.$alert.addMsg(""+this.$t("Jobecam profile link copied successfully"));
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public copyLink() {
    this.$copyText(this.nomeUrl).then(this.onCopy).catch(this.onError)
  }

  public onError() {
    console.log("not copied");
  }

  private get fotoSrc() {
    return (
      this.src ||
      "https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/empty_avatar.png"
    );
  }
}
</script>

<style lang="scss" scoped>
$size-sm: 40px;
$size-md: 80px;
$size-lg: 6em;
/**
** Avatar
**/
.avatar {
  position: relative;
  display: flex;
  // object-fit: cover;

  border-radius: 200px;
  box-shadow: inset 0 0 0 4px #fff;

  &--sm {
    width: $size-sm;
    height: $size-sm;
  }

  &--md {
    width: $size-md;
    height: $size-md;
  }

  &--lg {
    width: $size-lg;
    height: $size-lg;
  }

  &-share-link {
    position: absolute;
    bottom: 0;
    right: 16px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 32px;
    border: none;
    color: rgb(6, 111, 182);
  }

  &--primary {
    box-shadow: inset 0 0 0 4px var(--primary);
  }

  &__img {
    position: relative;
    z-index: -1;
    object-fit: cover;
    width: $size-lg;
    height: $size-lg;
  }
}
</style>