import { render, staticRenderFns } from "./ModalCandidateSkills.vue?vue&type=template&id=558949a6&scoped=true"
import script from "./ModalCandidateSkills.vue?vue&type=script&lang=ts"
export * from "./ModalCandidateSkills.vue?vue&type=script&lang=ts"
import style0 from "./ModalCandidateSkills.vue?vue&type=style&index=0&id=558949a6&prod&lang=scss&scoped=true"
import style1 from "./ModalCandidateSkills.vue?vue&type=style&index=1&id=558949a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558949a6",
  null
  
)

export default component.exports