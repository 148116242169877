<template>
  <button
    class="button-icon"
    v-bind:class="{'button-icon--inverse': inverse}"
    @click="clickEvent"
  >
    <icon :name="icon"></icon>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import Icon from '@/components/icon/Icon.vue';

@Component<ButtonIcon>({
  components: {
    'icon': Icon
  },
})
export default class ButtonIcon extends Vue {

  @Prop({default: 'info'}) public iconName!: string;
  @Prop({default: false }) public inverse!: string;

  public clickEvent(event: any) {
    this.$emit('click', event)
  }
  
  get icon() {
    return this.iconName;
  }

}
</script>

<style lang="scss" scoped>
/**
** Button Icon
**/
.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  text-align: center;
  border: 0;
  outline: 0;

  &--inverse {
    color: #ffffff;
  }
}
</style>