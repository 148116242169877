<template>
<b-form-group 
    :id="'input-group-' + scopeID" 
    :label="label+(required?'*':'')" 
    :label-for="'input-' + scopeID">
    <b-form-textarea :id="'input-' + scopeID" 
        @input="handleInput($event)"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value ? value : ''" >
    </b-form-textarea>
</b-form-group>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component<TextareaInput>({
    props: {
        value: String,
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        label: String,
    },
})
export default class TextareaInput extends Vue {
    public value!: any;
    public required!: boolean;
    public disabled!: boolean;
    public placeholder!: any;
    public label!: string;
    public scopeID: string;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput(v: any) {
        this.$emit('input', v);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    input
        border: 1px solid #006FF3 !important
        border-radius: 6px !important

    .form-group label
        color: #006FF3

    ::placeholder
        color: rgba(140,140,140,0.65) !important
</style>

