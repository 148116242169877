<template>
  <transition appear name="bounce">
    <div class="modal_wrapper">
      
      <div class="vld-parent">
          <loading :active="loadingSave" 
          :can-cancel="false" 
          :background-color="'#EEECEC'"
          :opacity="1"
          :is-full-page="true"></loading>
      </div>
    <b-modal  id="modalImportJobsGupy" size="xl" :title="$t('Import Job')">
      

      <b-container>
        <b-row>
          <b-col md="12">
            <h3>{{$t("Filter")}}</h3>
            <b-form inline>
              <label class="sr-only" for="inline-form-input-code">{{$t("Code")}}</label>
              <b-input-group prepend="Cod." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="inline-form-input-code"
                  :placeholder="$t('Code')"
                  v-model="jobCode"
                ></b-form-input>
              </b-input-group>

              <label class="sr-only" for="inline-form-input-ID">ID</label>
              <b-input-group prepend="ID" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="inline-form-input-ID" placeholder="ID" v-model="jobID"></b-form-input>
              </b-input-group>

              
              <label class="sr-only" for="inline-form-input-status">Status</label>
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                
                <b-input-group-prepend is-text>
                  <i class="fa fa-calendar-check"></i>
                </b-input-group-prepend>
                <b-form-select
                  id="inline-form-input-status"
                  v-model="jobStatus"
                  :options="statuses"
                ></b-form-select>
              </b-input-group>

              <b-button variant="primary" @click="loadData">
                <i class="fa fa-search"></i>
              </b-button>
            </b-form>
          </b-col>
          <b-col md="12">
            <b-table striped hover :fields="fields" :items="items" :busy="loading">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner variant="primary" class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(id)="row">
                <b-button variant="primary" class="circle-round" size="sm" @click="importJob(row.item)">
                  <i class="fas fa-file-import"></i>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ImportJob from '@/core/Services/Vaga/ImportJobGupy';
import GestaoVaga from '@/core/Services/Empresa/GestaoVaga';
import { filter } from 'vue/types/umd';
import Loading from 'vue-loading-overlay';


@Component({
  components: {
    Loading
  }
})
export default class ModalImportJobsGupy extends Vue {
  public fields: any[] = [];
  public items: any[] = [];
  public loading: boolean = false;
  public loadingSave: boolean = false;
  public service: ImportJob = new ImportJob();
  public serviceVaga: GestaoVaga = new GestaoVaga();
  public jobID: string = "";
  public jobCode: string = "";
  public jobStatus: string|null = "published";
  public statuses: string[] = ["","draft","waiting_approval","approved","disapproved","published","frozen","closed","canceled"];

  public mounted() {
    this.fields = [
      { key: 'code', label: this.$t('Code'), class: 'text-center' },
      { key: 'name', label: this.$t('Name'), class: 'text-center' },
      { key: 'roleName', label: this.$t('Post'), class: 'text-center' },
      { key: 'id', label:  ' - ', class: 'text-center' },
    ];
    // this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
    //   if(modalId == 'modalImportJobs') {
    //     this.loadData()
    //   }
    // })
    this.items = [];
    this.loading = false;
  }

  public loadData() {
    this.items = [];
    this.loading = true;
    let filters: any = {};
    
    if(this.jobStatus)
      filters.status = this.jobStatus
    
    if(this.jobID) 
      filters.id = this.jobID

    if(this.jobCode)
      filters.code = this.jobCode

    this.service.fetchAll(filters).then((data: any) => {
      this.items = data?.results;
    }).finally(() => {
      this.loading = false;
    })
  }

  public importJob(job: any) {
    let msg: any = undefined;
    
    this.$confirm.msg.push(this.$createElement('div',this.$t('Are you sure you want to import this vacancy?').toString()));
    const c = this.$createElement('strong',"Cod." + (job.code||""))
    const d = this.$createElement('div',[ c ])
    this.$confirm.msg.push(d);
    this.$confirm.open({ okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('Cancel').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        console.log(job)
        const objGupyToJobecam: any = {
          posicao: job.name + " [" + job.roleName+"] ",
          descricao: job.description||" - ",
          localidade: `${job.addressCity||" - "} ${job.addressStateShortName||""}`,
          zipcode: job.addressZipCode||"",
          // requisitos: string = "";
          competencias: job.prerequisites||" - ",
          confidencial: "SIM",
          restrita: "SIM",
          transcricao: 0,
          blind: false,
          limited: 0,
          hideMap: true,
          gpReferenceId: job.id,
          salario: " ",
          salaryMin: job.salary?.startsAt||0,
          salaryMax: job.salary?.endsAt||0,
          nivel: "Outros",
          simplificada: true
        }
        this.serviceVaga.entidade = objGupyToJobecam;
        this.loadingSave = true;
        this.$bvModal.hide('modalImportJobsGupy')
        this.serviceVaga.save().then((data) => {
          this.items = [];
          if(data?.id)
            this.$router.push({
              name: "JobsOpeningsSimpleEdit",
              params: { id: data.id+"" },
              query: { lang: this.$i18n.locale }
            })
        }).finally(() => {
          this.loadingSave = false
        })
        console.log('importar vaga')
      }
    });
  }
}
</script>

<style lang="scss" scoped>
  .circle-round {
    &:hover{
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border-color: #f8f9fa !important;
    }
    border-radius: 50% !important;
    padding: 7px !important;
    border-width: 2px;
    line-height: 1em;
    font-size: 0.875rem;
  }
</style>