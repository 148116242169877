
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class PosicaoTrabalho extends Repository {
  public optionList: any[] = [];
  constructor() {
    super(new ApiRouter({
      fetch: { url: '/v1/posicao-trabalho/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/posicao-trabalho', method: 'GET', headers: {} }
    }));
  }

  public loadOptions() {
    this.optionList = [
      {
        text: this.getI18n().t('Selecione'),
        value: ''
      }
    ];
    for (let i = 1; i <= 49; i++) {
      this.optionList.push({
        text: this.getI18n().t("categoriaTrabalho." + i),
        value: this.getI18n().t("categoriaTrabalho." + i)
      })
    }
  }

}
