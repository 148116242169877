<template>
  <transition></transition>
</template>

<script lang="ts">
import { i18n } from '@/plugins/i18n';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Confirm extends Vue {
  public msg: any[] = [];
  public okvariant: string = 'primary';
  public cancelvariant: string = 'danger';
  public title: any = ' ';
  public headerClass: string = '';
  public footerClass: string = '';
  public bodyClass: string = '';
  public i18n: any;
  public id: string = '';
  public contentMSG: string = '';
  public contentTitle: any[] = [];
  public oktitle: string = 'OK';
  public currentModal: any|null = null;
  public cancelTitle: string = 'Cancelar';


  constructor() {
    super();
    this.id = (new Date()).getTime()+'';
    this.i18n = i18n;
  }

  public created() {
    this.i18n = i18n;
  }

  public callBox() : Promise<any>
  {
      let modal = this.$bvModal.msgBoxConfirm(this.msg, {
        title: this.title,
        size: 'md',
        buttonSize: 'md',
        okVariant:  this.okvariant,
        okTitle: this.i18n.t(this.oktitle).toString(),
        cancelTitle: this.i18n.t(this.cancelTitle).toString(),
        cancelVariant: this.cancelvariant,
        footerClass: this.footerClass,
        headerClass: this.headerClass,
        bodyClass: this.bodyClass,
        hideHeaderClose: true,
        centered: true
      });
      this.msg = [];
      return modal;
  }
  // Modal Atenção
  public callBoxDanger() : Promise<any> 
  {
      this.okvariant = 'success';
      this.cancelvariant = 'danger',
      // Modify modal header with bootstrap class
      this.headerClass = 'p-2 border-bottom border-danger text-danger confirmErrorHeader confirm-header-danger'
      this.footerClass = 'p-2 confirm-footer-danger confirmErrorFooter'
      this.bodyClass = 'confirm-footer-danger confirmErrorBody'
      return this.callBox()
  }

  public callBoxSuccess() : Promise<any> 
  {
      this.okvariant = 'success';
      // Modify modal header with bootstrap class
      this.headerClass = 'p-2 border-bottom border-success text-success'
      this.footerClass = 'p-2 confirm-footer'
      return this.callBox()
  }

  public addTitle(contentTitle: string) {
      this.title = contentTitle;
  }

  public addMsg(msg: any) {
      if (!this.msg) {
          this.msg = [];
      }

      this.msg.push(this.$createElement('div',msg+""));
  }

  
  public callBoxImageDoubleButton(type: string) {
    let modal = this.$bvModal.msgBoxConfirm(this.msg, {
      id: this.id,
      title: this.title,
      size: 'md',
      buttonSize: 'md',
      okVariant: 'success text-uppercase col-md-5 float-right',
      okTitle: this.oktitle,
      cancelVariant: 'danger text-uppercase col-md-5 float-left',
      cancelTitle: this.cancelTitle,
      headerClass: type + 'Header',
      bodyClass: type + 'ConfirmBody text-center',
      hideHeaderClose: true,
      footerClass: type + 'ConfirmFooter border-top-0 d-block',
      contentClass: type + 'ConfirmContent',
      centered: true
    });
    this.msg = [];
    return modal;
  }

  public open(props: { okTitle: string, cancelTitle: string, msg: string}, type:string) {
    if(props.msg)
      this.addMsg(props.msg);
    this.oktitle = props.okTitle || '';
    this.cancelTitle = props.cancelTitle || '';
    return this.callBoxImageDoubleButton(type)
  }


}
</script>


<style lang="sass">

  @media screen and (orientation:landscape)
    .confirmErrorHeader
      height: 120px
      background-size: 90px

  .confirmErrorHeader
    height: 200px
    background-color: #E24E4D
    background-image: url('https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/warn.png')
    background-size: 120px
    position: relative
    background-repeat: no-repeat
    background-position: center

    .modal-title
      position: absolute
      bottom: -37px
      left: 0
      right: 0
      text-align: center


  .confirmErrorBody
    padding: 45px 20px 10px
    text-align: center
    font-size: 14px

  .confirmErrorFooter
    padding: 0 30px 30px 30px
    display: -webkit-box !important
    display: -ms-flexbox !important
    display: flex !important
    -webkit-box-pack: center !important
    -ms-flex-pack: center !important
    justify-content: center !important

    .btn-danger
      background-color: #E24E4D
      border-color: #E24E4D

    .btn-danger:hover
      background-color: #A2110D
      border-color: #A2110D

</style>

<style lang="scss">
  .call-box {
    @media (max-width: 575.98px) {
      width: 95vw;
    }
  }

  .modal-dialog-centered {
    @media (max-width: 575.98px) {
      min-height: 0;
    }
  }

  @media (orientation:landscape) {
    .confirmErrorHeader, .warnHeader {
      height: 120px;
      background-size: 90px;
    }
  }
</style>

