
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { EquipeEntity } from '@/core/models/Entities';


export default class EquipeRecrutador extends Repository {

  public optionList?: any[];

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/equipe', method: 'POST', headers: {} },
      fetch: { url: '/v1/equipe-recrutador/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/equipe', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/equipe-recrutador/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/equipe-recrutador/{id}', method: 'DELETE', headers: {} }
    }));
    this.optionList = [];
  }
  

}


