import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class MultiPublicacao extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/r-s', method: 'POST', headers: {} },
            update: { url: '/v1/r-s/{id}', method: 'PUT', headers: {} },
        }));
    }
}
