<template>
  <div class="radar">
    <canvas  ref="canvas" class="radar-chart circle01"></canvas>  
    <!-- <div class="caption"> {{total}} </div> -->
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Mixins  } from 'vue-property-decorator';
import { Radar } from 'vue-chartjs';

@Component<RadarChart>({
  extends: Radar
})
export default class RadarChart extends Mixins(Radar) {
  @Prop()
  public seriesOne!: any[];

  @Prop()
  public seriesTwo!: any[];

  @Prop()
  public datasets!: any[];

  @Prop({default: true})
  public displayLegend!: boolean;

  @Prop()
  public labels!: string[];

  @Prop({ default: [] })
  public cColors!: string[];

  @Prop({ default: [] })
  public vColors!: string[];

  @Prop({ default: [] })
  public borderColor!: string[];

  public seriesColors: {[key: string]: string} = {};

  public  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets     
    }, 
    {
      responsive: true, 
      maintainAspectRatio: true,
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          fontSize: 14
        }
      },
      scale: {
        ticks: {
            beginAtZero: true,
            max: 5,
            min: 0,
            stepSize: 1
        }
      }
    })
  }
}
</script>

<style lang="scss" scope>
.radar-chart {
  height: 100%;
}
.radar {
  position: relative;
  min-height: 300px
}
  .caption {
    font-family: Lato,sans-serif;
    color: #222;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 225px;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  .radar .circle01 {
    position: absolute;
    top: 0px;
    left: 0px;
  }
</style>