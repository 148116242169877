import { render, staticRenderFns } from "./FormVagaCompetencias.vue?vue&type=template&id=467e6b86&scoped=true"
import script from "./FormVagaCompetencias.vue?vue&type=script&lang=ts"
export * from "./FormVagaCompetencias.vue?vue&type=script&lang=ts"
import style0 from "./FormVagaCompetencias.vue?vue&type=style&index=0&id=467e6b86&prod&lang=scss&scoped=true"
import style1 from "./FormVagaCompetencias.vue?vue&type=style&index=1&id=467e6b86&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467e6b86",
  null
  
)

export default component.exports