<template>
  <b-form
    @submit.prevent="finalizeBuy"
    v-promise-btn="{ action: 'submit' }"
    class="credit-card-form"
    id="credit-card-form"
    novalidate>
    <input name="token" id="tokenIUGU" type="hidden" value>
    <b-row class="mb-4">
      <b-col md="6">
        <text-input
          id="ccNo"
          :required="true"
          :label="$t('Enter card number')"
          placeholder="0000 0000 0000 0000"
          v-model="$v.iugu.ccNo.$model"
          :state="$v.iugu.ccNo.$dirty ? !$v.iugu.ccNo.$error : null"
          :invalid-feedback="$t('Fill this field with a valid credit card number')"
          variant="dark" />
      </b-col>
      <b-col md="6">
        <text-input
          id="firstName"
          :required="true"
          :label="$t('Enter the name on the card')"
          :placeholder="$t('Name on card')"
          v-model="$v.iugu.name.$model"
          :state="$v.iugu.name.$dirty ? !$v.iugu.name.$error : null"
          :invalid-feedback="$t('Fill this field')"
          variant="dark" />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col md="6">
        <text-input
          id="expMonthYear"
          :required="true"
          :label="$t('Enter expiration date')"
          placeholder="00/0000"
          v-model="$v.iugu.expMonthYear.$model"
          :state="$v.iugu.expMonthYear.$dirty ? !$v.iugu.expMonthYear.$error : null"
          :invalid-feedback="$t('Fill this field')"
          variant="dark" />
      </b-col>
      <b-col md="6">
        <text-input
          id="cvv"
          :required="true"
          :label="$t('Enter the security code')"
          placeholder="000"
          v-model="$v.iugu.cvv.$model"
          :state="$v.iugu.cvv.$dirty ? !$v.iugu.cvv.$error : null"
          :invalid-feedback="$t('Fill this field')"
          variant="dark" />
      </b-col>
    </b-row>
    <b-button
      type="submit"
      variant="blue"
      class="btn-realizar">
      {{ $t('Change payment card') }}
    </b-button>
  </b-form>
</template>

<script lang="ts">
import MetodoPagamento from '@/core/Services/Payments/MetodoPagamento';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import JBIugu from '@/core/payment/jb-iugu.js';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';

@Component
export default class CreditCardForm extends Vue {
  @Prop() private plano!: PlanoCreditoEntity;
  public serviceMetodo: MetodoPagamento = new MetodoPagamento();
  
  @Validate({
    name: {
      required,
      fullName: (value: string) => {
        return -1 !== value.trim().indexOf(" ");
      }
    },
    ccNo: { 
      required, 
      cardNumber: (value: any, vm: any) => {
        return Iugu.utils.validateCreditCardNumber(value);
      }
    },
    cvv: {
      required,
      cardCVV: (value: any, vm: any) => {
        const cardBrand = Iugu.utils.getBrandByCreditCardNumber(vm.ccNo);
        return Iugu.utils.validateCVV(value, cardBrand);
      },
    },
    expMonthYear: {
      required,
      cardExpDate: (value: string) => {
        if (value && -1 !== value.indexOf('/')) {
          const [mes, ano] = value.split('/');
          return Iugu.utils.validateExpiration(mes, ano);
        }
        return false;
      },
    },
  })
  private iugu = new JBIugu();

  public async finalizeBuy() {
    const msg: string = this.$t('Do you confirm changing your payment method?').toString()
    const data = await this.$confirm.open({okTitle: this.$t('Ok').toString(), cancelTitle: this.$t('Cancel').toString(), msg },'check');
    
    if (!data)
      return;

    this.$v.$touch();

    if (this.$v.$invalid)
      return;

    //separa o nome do sobrenome
    let fullname = this.iugu.name;
    let index = fullname.indexOf(" ");
    let first_name = fullname.substring(0, index);
    let last_name = fullname.substring(index + 1, fullname.length);
    this.iugu.firstname = first_name;
    this.iugu.lastname = last_name;

    let monthYear = this.iugu.expMonthYear.split("/");
    this.iugu.expMonth = monthYear[0];
    this.iugu.expYear = monthYear[1];

    return new Promise((resolve, reject) => {
      this.iugu.makeTokenRequest()
        .then(() => {
          this.changeOrCreatePaymentMethod()
            .then(() => resolve(true))
            .catch(() => reject());
        })
        .catch(() => reject());
    });
  }

  // this should be only to alter payment method
  public changeOrCreatePaymentMethod() {
    // console.log(this.iugu.tokenResponseObject)
    let data = {
      _tkn: this.iugu.tokenResponseObject.id
    };
    // console.log(data);
    return this.serviceMetodo.create(data).then((data) => {
      this.$alert.addMsg(this.$t('Your payment method has been successfully saved!').toString())
      this.$alert.callBoxCheck(this.$t('Ok').toString())
      this.$emit('changed')
    }).catch((data) => {
      this.$emit('canceled')
    })
  }
}
</script>

<style lang="scss" scoped>
.credit-card-form {
  .btn-realizar {
    display: block;
    margin: 0 auto;
    padding: 1.3% 9%;
    color: #fff;
  }
}
</style>
