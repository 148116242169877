
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Idioma extends Repository {
    public id!: any;
    public nivel!: any;
    public optionList: any[] =[];
    public optionFullList: any[] =[];
    public optionSiglaList: any[] = [
        {
          id: '10',
          locale: 'pt-BR',
          name: 'Português',
          text: 'Português (pt-BR)',
          value: 'pt-BR'
        },
        {
          id: '3',
          locale: 'es',
          name: 'Español',
          text: 'Español (es)',
          value: 'es'
        },
        { id: '2',
          locale: 'en-US',
          name: 'English',
          text: 'English (en)',
          value: 'en'
        }
      ];
    public optionNivelList: any[] =[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/idioma', method: 'POST', headers: {} },
                fetch: { url: '/v1/idioma/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/idioma', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/idioma/{id}', method: 'PUT', headers: {} }}));
    }

    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.idiomas) {
            this.optionList = [];
            data.idiomas.forEach((item: any) => {
                if (['en', 'es', 'pt-BR'].indexOf(item.idioma) > -1) {
                    this.optionList!.push({
                        text: item.legenda,
                        value: item.id,
                    });
                    this.optionSiglaList!.push({
                        text: item.legenda,
                        value: item.idioma,
                    });
                }
                this.optionFullList!.push({
                    text: item.legenda,
                    value: item.id,
                });
            });
            this.optionNivelList = [
                { text: this.getI18n().t('Básico'), value: 'BASICO' },
                { text: this.getI18n().t('Intermediário'), value: 'INTERMEDIARIO' },
                { text: this.getI18n().t('Avançado'), value: 'AVANCADO' },
                { text: this.getI18n().t('Fluente'), value: 'FLUENTE' },
                { text: this.getI18n().t('Nativo'), value: 'NATIVO' },
            ];
        }
    }

}


