import Vue from 'vue';
import Router, { Route } from 'vue-router';
// Dashboard
import DashboardByJob from './views/dashboard/DashboardByJob.vue';
import DashboardDiversidade from './views/dashboard/DashboardDiversidade.vue';
import Home from './views/dashboard/Home.vue';
import ReportTableComplete from './views/dashboard/components/ReportTableComplete.vue';


import LiveRoomManager from '@/views/live-interview/LiveRoomManager.vue';
import ManagerRoomView from '@/views/live-interview/ManagerRoomView.vue';
import Rooms from '@/views/live-interview/Rooms.vue';
import Mensagens from '@/views/mensagens/Mensagens.vue';
import Pagamentos from '@/views/pagamentos/Pagamentos.vue';
import SubstituirCartao from '@/views/pagamentos/SubstituirCartao.vue';
import Institucional from '@/views/perfil/Institucional.vue';
import Perfil from '@/views/perfil/Perfil.vue';
import ComprarPlano from '@/views/plano/ComprarPlano.vue';
import Extrato from '@/views/plano/Extrato.vue';
import PagarPlano from '@/views/plano/PagarPlano.vue';
import PlanoCustomizado from '@/views/plano/PlanoCustomizado.vue';
import EditRecrutador from '@/views/recrutadores/EditRecrutador.vue';
import NovoRecrutador from '@/views/recrutadores/NovoRecrutador.vue';
import Candidaturas from '@/views/vagas/Candidaturas.vue';
import FormVaga from '@/views/vagas/FormVaga.vue';
import FormVagaSimples from '@/views/vagas/FormVagaSimples.vue';
import MinhasVagas from '@/views/vagas/MinhasVagas.vue';
import VagaDetails from '@/views/vagas/VagaDetails.vue';
//import HelpPage from '@/views/help/HelpPage.vue';
import RedirectLogin from '@/views/RedirectLogin.vue';
import EmpresaAssessment from '@/views/assessment/EmpresaAssessment.vue';
import CandidatoFilter from '@/views/filter/CandidatoFilter.vue';
import RootLoggedArea from '@/views/layout/RootLoggedArea.vue';
import RootPublicCleanArea from '@/views/layout/RootPublicCleanArea.vue';
import RootPublicEmptyArea from '@/views/layout/RootPublicEmptyArea.vue';
import CancelarPlano from '@/views/plano/CancelarPlano.vue';

//Access
import ForgotPassword from '@/views/ForgotPassword.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import SecondFactor from '@/views/SecondFactor.vue';
import Register from '@/views/register/Register.vue';

// Tests/Exams
import AddEssayExam from '@/views/exams/AddEssayExam.vue';
import AddExam from '@/views/exams/AddExam.vue';
import EditExam from '@/views/exams/EditExam.vue';
import Exams from '@/views/exams/Exams.vue';

//InviteMember
import NewInvite from '@/views/invite/NewInvite.vue';
import RootInviteMember from '@/views/invite/RootInviteMember.vue';

//MailTemplate
import MailTemplates from '@/views/mail-template/MailTemplates.vue';

//TalentInfo
import HiddenTalentInfoPage from '@/views/candidatos/HiddenTalentInfoPage.vue';

// Avaliacao Public
import ManagerEvaluationView from '@/views/grupoavaliacao/ManagerEvaluationView.vue';
import PublicCandidatura from '@/views/vagas/PublicCandidatura.vue';

// ReportPage
import ReportPage from '@/views/reports/ReportPage.vue';
import ReportListPage from '@/views/reports/partials/ReportListPage.vue';
import ReportTablePage from '@/views/reports/partials/ReportTablePage.vue';

import Empresa from '@/core/Services/Empresa/Empresa';
import ShareSession from '@/plugins/ShareSession';
import { i18n } from '@/plugins/i18n';


//Plural
import HomePlural from '@/views/plural/HomePlural.vue';
import PluralArtigoView from '@/views/plural/PluralArtigoView.vue';
import PluralCursoView from '@/views/plural/PluralCursoView.vue';

// Equipes

Vue.use(Router);

const callLogout = () => {
  const cs: Empresa = new Empresa();
  cs.logout();
  if(sessionStorage.getItem('logoutURL')) {
    const url:string = atob(sessionStorage.getItem('logoutURL')+"");
    if(url.indexOf('http') > -1)
      location.href = url;
  }
};

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};

const redirectToView = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  const uri: string =
  window.document!.location!.protocol + '//' + window.document!.location!.host;
  if (to.query!.tkn!) {
    location.href = uri + '/emp/entrevista/' + to.query.tkn + '?lang=' + i18n.locale;
  }
};
const cleanSession = (to: any, from: any, next: any) => {
  sessionStorage.removeItem('token_type');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('profile_type');
  sessionStorage.removeItem('profile');
  next();
}
const isLogged = async (to: any, from: any, next: any) => {
  if (to.path.indexOf('/emp/') > -1) {
    cleanSession(to,from,next);
    return;
  }

  const c = new ShareSession();
  if (sessionStorage.getItem('access_token') == null) {
    // give it a time to share the session between tabs
    await sleep(500);
  }
  if (sessionStorage.getItem('access_token') == null) {
    next({
      path: '/login',
      query: { lang: to.query.lang, redirectUrl: to.path }
    });
    return;
  }
  next();
};
const routesObject: any = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: { title: 'Forgot password ?' }
    },
    {
      path: '/resetpass',
      name: 'ResetPassword1',
      component: ResetPassword,
      meta: { title: 'Request Password Reset' }
    },
    {
      path: '/reset',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: { title: 'Request Password Reset' }
    },
    {
      path: '/sf',
      name: 'SecondFactor',
      component: SecondFactor,
      meta: { title: 'Authentication Code' }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { title: 'Login' }
    },
    {
      path: '/empresa/public/candidatura/view',
      name: 'EmpresaRedirect',
      beforeEnter: redirectToView,
    },
    {
      path: '/empresa/public/candidaturablind/view',
      name: 'EmpresaRedirect1',
      beforeEnter: redirectToView,
    },
    {
      path: '/public/candidaturablind/view',
      name: 'EmpresaRedirect2',
      beforeEnter: redirectToView,
    },
    {
      path: '/public/candidatura/view',
      name: 'EmpresaRedirect3',
      beforeEnter: redirectToView,
    },
    {
      path: '/redirectLogin',
      name: 'Redirect Login',
      component: RedirectLogin
      // beforeEnter: setLanguage
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login,
      beforeEnter: callLogout,
      meta: { title: 'Logout' }
    },
    {
      path: '/room/:sid',
      name: 'RoomManagerFull',
      component: LiveRoomManager,
      beforeEnter: isLogged,
      meta: { title: 'Interview Room' }
    },
    {
      path: '/',
      component: RootLoggedArea,
      beforeEnter: isLogged,
      children: [
        {
          path: '/',
          name: 'DashboardDiversidade',
          component: DashboardDiversidade,
          meta: { title: 'Dashboard of diversity' }
        },
        {
          path: '/Home',
          name: 'Home',
          component: Home,
          meta: { title: 'Home' }
        },
        {
          path: '/dashboard/:vguid',
          name: 'DashboardByJob',
          component: DashboardByJob,
          meta: { title: 'Dashboard' }
        },
        {
          path: '/diversidade/:vguid',
          name: 'DashboardJobDiversidade',
          component: DashboardDiversidade,
          meta: { title: 'Dashboard of diversity' }
        },
        {
          path: '/report',
          name: 'ReportTableComplete',
          component: ReportTableComplete,
          meta: { title: 'Reports' }
        },
        {
          path: '/profile',
          name: 'Perfil',
          component: Perfil,
          meta: { title: 'Profile' }
        },
        {
          path: '/recruiters',
          name: 'Recruiters',
          component: Perfil,
          meta: { title: 'Recruiters' }
        },
        {
          path: '/company-info',
          name: 'Institucional',
          component: Institucional,
          meta: { title: 'Company Page' }
        },
        {
          path: '/mail-template',
          name: 'MailTemplates',
          component: MailTemplates,
          meta: { title: 'E-mail Templates' }
        },
        {
          path: '/messages',
          name: 'Messages',
          component: Mensagens,
          meta: { title: 'Messages' }
        },
        {
          path: '/recruiters/new',
          name: 'RecruitersNew',
          component: NovoRecrutador,
          meta: { title: 'Recruiters' }
        },
        {
          path: '/recruiters/edit/:id',
          name: 'RecruitersEdit',
          component: EditRecrutador,
          meta: { title: 'Recruiters' }
        },
        {
          path: '/my-jobs-openings',
          name: 'MyJobsOpenings',
          component: MinhasVagas,
          meta: { title: 'My Jobs' }
        },
        {
          path: '/my-jobs-openings/candidate/:id',
          name: 'Candidate',
          component: Candidaturas,
          meta: { title: 'Applications' }
        },
        {
          path: '/my-jobs-openings/edit/:id',
          name: 'JobsOpeningsEdit',
          component: FormVaga,
          meta: { title: 'My Jobs' }
        },
        {
          path: '/my-jobs-openings/view/:id',
          name: 'JobsOpeningsView',
          component: VagaDetails,
          meta: { title: 'My Jobs' }
        },
        {
          path: '/my-jobs-openings/new',
          name: 'JobsOpeningsNew',
          component: FormVaga,
          meta: { title: 'My Jobs' }
        },
        {
          path: '/my-jobs-openings/new-simple',
          name: 'JobsOpeningsSimple',
          component: FormVagaSimples,
          meta: { title: 'Add Simple Vacancy' }
        },
        {
          path: '/my-jobs-openings/edit-simple/:id',
          name: 'JobsOpeningsSimpleEdit',
          component: FormVagaSimples,
          meta: { title: 'Edit Simple Vacancy' }
        },
        {
          path: '/plan/extract',
          name: 'PlanExtract',
          component: Extrato,
          meta: { title: 'Extract' }
        },
        {
          path: '/plan/custom',
          name: 'PlanoCustomizado',
          component: PlanoCustomizado,
          meta: { title: 'Customized plan' }
        },
        {
          path: '/plan/cancel',
          name: 'CancelarPlano',
          component: CancelarPlano,
          meta: { title: 'Cancel plan' }
        },
        {
          path: '/plan/pay',
          name: 'PlanPay',
          component: PagarPlano,
          meta: { title: 'Pay' }
        },
        {
          path: '/payments',
          name: 'PlanPayments',
          component: Pagamentos,
          meta: { title: 'Payments' }
        },
        {
          path: '/payments/alter-method',
          name: 'SubstituirCartao',
          component: SubstituirCartao,
          meta: { title: 'Replace card' }
        },
        {
          path: '/rooms',
          name: 'Rooms',
          component: Rooms,
          meta: { title: 'Rooms' }
        },
        // {
        //   path: '/room/:sid',
        //   name: 'RoomManager',
        //   component: RoomManager,
        //   meta: { title: 'Salas' }
        // },

        
        {
          path: '/plural',
          name: 'HomePlural',
          component: HomePlural,
          meta: { title: 'Jobecam Community' }
        },

        {
          path: '/plural/artigo/:url',
          name: 'PluralArtigoView',
          component: PluralArtigoView,
          meta: { title: 'Plural article' }

        },

        {
          path: '/plural/curso/:url',
          name: 'PluralCursoView',
          component: PluralCursoView,
          meta: { title: 'Plural Course' }

        },
        // {
        //   path: '/help',
        //   name: 'HelpPage',
        //   component: HelpPage,
        //   meta: { title: 'Ajuda' }
        // },
        {
          path: '/filter-candidates',
          name: 'CandidatoFilter',
          component: CandidatoFilter,
          meta: { title: 'Filter Candidates' }
        },
        {
          path: '/match',
          name: 'EmpresaAssessment',
          component: EmpresaAssessment,
          meta: { title: 'Match' }
        },
        {
          path: '/teams',
          name: 'Teams',
          component: Perfil,
          meta: { title: 'Teams' }
        },
        {
          path: '/teams/edit/:eid',
          name: 'EditTeam',
          component: Perfil,
          meta: { title: 'Team' }
        },
        {
          path: '/exams',
          name: 'Exams',
          component: Exams,
          meta: { title: 'Tests' }
        },
        {
          path: '/exam/add',
          name: 'AddExam',
          component: AddExam,
          meta: { title: 'Add Tests' }
        },
        {
          path: '/exam/add_essay',
          name: 'AddEssayExam',
          component: AddEssayExam,
          meta: { title: 'Writing Test' }
        },
        {
          path: '/exam/edit/:qid',
          name: 'EditExam',
          component: EditExam,
          meta: { title: 'Edit Tests' }
        },
        {
          path: '/invite',
          component: RootInviteMember,
          beforeEnter: isLogged,
          children: [
            {
              path: '/',
              name: 'NewInvite',
              component: NewInvite,
              meta: { title: 'Jobecam Community' }
            }
          ]
        },
        {
          path: '/reports',
          name: 'ReportPage',
          component: ReportPage,
          beforeEnter: isLogged,
          children: [
            {
              path: '',
              name: 'ReportListPage',
              component: ReportListPage,
              meta: { title: 'Reports' }
            },
            {
              path: 'table/:rid',
              name: 'ReportTablePage',
              component: ReportTablePage,
              meta: { title: 'Reports' }
            }
          ]
        }
      ]
    },
    {
      path: '/t',
      component: RootPublicCleanArea,
      children: [
        {
          path: 'info/:guid',
          name: 'HiddenTalentInfoPage',
          component: HiddenTalentInfoPage,
          meta: { title: 'Hidden talent' }
        }
      ]
    },
    {
      path: '/emp',
      component: RootPublicEmptyArea,
      beforeEnter: cleanSession,
      children: [
        {
          path: 'sala-entrevista/:sid',
          name: 'PublicRoomManager',
          component: LiveRoomManager,
          meta: { title: 'Interview Room' }
        },
        {
          path: 'eval/:tkn',
          name: 'ManagerEvaluationView',
          component: ManagerEvaluationView,
          meta: { title: 'Evaluation List' }
        },
        {
          path: 'room-detail/:tkn',
          name: 'ManagerRoomView',
          component: ManagerRoomView,
          meta: { title: 'Details - Interview Room' }
        },
        {
          path: 'entrevista/:tkn',
          name: 'PublicCandidatura',
          component: PublicCandidatura,
          meta: { title: 'Evaluate interview' }
        },
      ]
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: { title: 'Register' }
    },
  ]
};
const router = new Router(routesObject);

// tslint:disable-next-line: ban-types
router.beforeEach((to: Route, from: Route, next: Function) => {

  let lang: string = !to.query.lang ? navigator.language : (to.query.lang as string);

  if (from.path.indexOf('/login') > -1) {
    if (sessionStorage.getItem('profile')) {
      const profile: any = JSON.parse('' + sessionStorage.getItem('profile'));
      lang = profile.lang || 'en';
    }
  } else if (!lang) {
    lang = navigator.language;
  }
  // console.log(lang);

  if (['en', 'es', 'pt', 'fr'].indexOf(lang.substring(0, 2)) >= 0) {
    if (lang.substring(0, 2) == 'pt') {
      i18n.locale = 'pt-BR';
    } else {
      i18n.locale = lang.substring(0, 2);
    }
  } else {
    i18n.locale = 'en';
  }
  // console.log(i18n.locale);
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = i18n.t(nearestWithTitle.meta.title)+" - Jobecam";
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el: any) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key: any) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
