<template>
  <div class="container-children d-flex flex-column">
    <section class="section-plano-customizado">
      <b-row>
        <b-col md="12">
          <b-card class="customizado-card pt-3">
            <b-form
              novalidate
              @submit.prevent="send"
            >
              <b-container class="customizado-card-content">
                <b-row>
                  <b-col>
                    <h1>{{ $t('Cancel plan?') }}</h1>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <text-area
                      class="text-uppercase"
                      :label="$t('Request cancellation of the plan in the field below')"
                      v-model="$v.mensagem.$model"
                      :state="$v.mensagem.$dirty ? !$v.mensagem.$error : null"
                      :invalid-feedback="$t('Fill this field')"
                      variant="dark"
                      rows="10"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button type="submit" variant="aqua-green" class="btn-acessar">{{ $t('Send message') }}</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </b-card>
        </b-col>
      </b-row>  
    </section>
  </div>
</template>

<script lang="ts">
import PlanCard from '@/components/PlanCard.vue';
import ContatoService from '@/core/Services/Basic/Contato';
import { EmpresaEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    PlanCard
  },
})
export default class CancelarPlano extends Vue {
  private contatoService: ContatoService = new ContatoService();

  @Action("loadEmpresaInfo", { namespace: 'profile' })
  public loadEmpresaInfo: any;

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Validate({ required })
  private mensagem = '';

  public created() {
    if (!this.empresa)
      this.loadEmpresaInfo()

  }

  public send() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.contatoService
      .create({
        nome: this.empresa!.nomeEmpresa,
        email: this.empresa!.email,
        assunto: 'Plano customizado',
        mensagem: this.mensagem
      })
      .then((data: any) => {
        this.$alert.title = this.$t('Thank you');
        this.$alert.addMsg(this.$t('Your Contact was sent to our team, soon we will answer it'));
        this.$alert.callBoxCheck(this.$t('Ok').toString()).then((data: any) => {
          this.$router.push({ path: '/', query: {lang: this.$i18n.locale} });
        });
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.section-plano-customizado {
    background-color: white;
    padding: 1.5em;
    width: 100%;
    border-radius: 5px;

  .customizado-card {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    height: 100%;

    &-content {
      h1, h2, h3 {
        margin-bottom: 3%;
      }

      h1 {
        font-size: 2em;
        color: $green-light;
        text-transform: uppercase;
        font-weight: bold;
      }

      h2 {
        font-size: 1.3em;
      }

      h3 {
        font-size: 1em;
      }

      .btn-acessar {
        display: block;
        margin: 0 auto;
        padding: 1.3% 9%;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
</style>
