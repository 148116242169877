<template>
  <modal :show="show" :id="id" :size="'lg'" :hidefooter="true" :no-enforce-focus="true">
      <form-avaliacao
        :vaga="vaga"
        @saved="saved"
        :candidato="candidato"
        :coUID="coUID"
        :raToken="raToken"
        v-if="candidato"
      >
      </form-avaliacao>
  </modal>
</template>

<script lang="ts">
import { CandidatoEntity, VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormAvaliacao from '../FormAvaliacao.vue';
import Modal from '@/components/modal/Modal.vue';

@Component({
  components:{
    FormAvaliacao,
    Modal
  }
})
export default class ModalCreateAvaliacao extends Vue {

  @Prop({default: null})
  public id!: string;

  @Prop({default: null})
  public coUID!: string;

  @Prop({default: null})
  public raToken!: string;
  
  @Prop({default: false})
  public show!: boolean;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
  @Prop({ default: () => { new CandidatoEntity() } })
  public candidato!: CandidatoEntity;


  public saved() {
      this.$emit('done')
      this.$bvModal.hide(this.id)
  }
  
}
</script>

<style lang="scss" scoped>

</style>