
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { PluralPostEntity } from '@/core/models/Entities';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';


export default class PluralPost extends Repository {
    public entidade: PluralPostEntity;

    constructor() {
        super(new ApiRouter({
                // create: { url: '/v1/plural-post', method: 'POST', headers: {} },
                fetch: { url: '/v1/plural-post/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/plural-post', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                // update: { url: '/v1/plural-post/{id}', method: 'PUT', headers: {} }
              }));
        this.entidade = new PluralPostEntity();
    }

    public loadPluralPost(id: number): void {
        super.fetch(id).then((data: any) => {
            this.entidade = data;
        }).catch((err: any) => {
            // tslint:disable-next-line:no-console
            console.log('Couldn\'t load vaga.', err);
        });
    }

    public fetchWithData(data: any): Promise<any> {
        return this.call('fetch', (!data ? {} : data), 'application/json');
    }

    public fetchAll(data?: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token') === null) {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const d = {
                        rtoken,
                        ...data
                    }
                    super.fetchAll(d).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            } else {
                super.fetchAll(data).then(resolve).catch(reject);
            }
        });
    }

}


