
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';


export default class CompanyExamEvaluation extends Repository {

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/company-exam-evaluation', method: 'POST', headers: {} },
      fetch: { url: '/v1/company-exam-evaluation/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/company-exam-evaluation', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/company-exam-evaluation/{id}', method: 'PUT', headers: {} },
      delete: { url: '/v1/company-exam-evaluation/{id}', method: 'DELETE', headers: {} },
    }));
  }

  public call(key: string, data: any, ctype: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
        if (sessionStorage.getItem('access_token')) {
            super.call(key, data, ctype).then(resolve).catch(reject);
        } else {
            if (key != 'fetch') {
              reject();
              return;
            }
            (new ClientAuthentication()).auth().then((rtoken: any) => {
                const dta: any = {
                    rtoken,
                    ...data
                };
                const cdta = CB.genC(dta);
                super.call(key, cdta, ctype).then(resolve).catch(reject);
            });
        }
    });
  }


}


