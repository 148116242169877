<template>
  <form class="search search-box" @submit.prevent="doSearch">
    <label for="input-search-1" class="search__wrapper">
      <i class="search__wrapper__prefix fas fa-search"></i>
      <info-tag v-if="tagText" :text="tagText" :size="'small'" />
      <input
        for="input-search-1"
        class="search__wrapper__input"
        type="search"
        :placeholder="placeholder"
        v-model="search"
      />
    </label>
  </form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
  private search = '';
  private tagText = '';

  @Prop({default: ''}) public placeholder!: string;

  get currRouteName(): string {
    return this.$route.name!;
  }

  public created() {
    this.setTag();

    this.search = <string> this.$route.query.s || '';
  }

  public setTag() {
    const paths = {
      Vagas: 'vagas',
      VagasSalvas: 'vagas salvas',
      Candidaturas: 'candidaturas',
      Companies: 'empresas',
      FavoriteCompanies: 'empresas',
    };

    for (let prop in paths) {
      if (!paths.hasOwnProperty(prop))
        continue;
      
      if (this.currRouteName == prop) {
        this.tagText = (<any> paths)[prop];
      }
    }
  }

  public doSearch() {
    this.$emit('doSearch', this.search);
    // const pathsNames = [
    //   'Companies',
    //   'FavoriteCompanies',
    //   'VagasSalvas',
    //   'Candidaturas'
    // ];
    // let routeName: string;

    // if (pathsNames.includes(this.currRouteName)) {
    //   routeName = this.currRouteName;
    // }
    // else {
    //   routeName = 'Vagas';
    // }

    // this.$router.push({
    //   name: routeName,
    //   query: {
    //     lang: this.$i18n.locale,
    //     s: this.search
    //   }
    // });
  }
}
</script>

<style lang="scss">
.search {
  .badge {
    background: #066fb6;
    color: #fff;
  }
}
</style>

<style lang="scss" scope>
@import "@/assets/scss/_variables";

/**
** Search Component
**/
.search {
  display: inline-flex;
  width: 100%;
  max-width: 400px;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 32px;
    padding-right: 10px;
    border-radius: 50px;
    border: 1px solid $light-gray;

    margin: 0;

    &__prefix {
      align-self: center;
      padding: 0 20px;
      color: $light-gray;
    }

    &__input {
      width: 100%;
      background-color: transparent;
      border: 0;
      outline: none;

      &::placeholder {
        color: #000;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .search-box {
    display: none !important;
  }
}
</style>

