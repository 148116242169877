<template>
  <transition appear name="slide">
    <div class="v-card v-sheet theme--light">
        <div class="v-card__title font-weight-semibold">
          <div>
              <p v-if="nivel>0" class="mb-2"> {{ $t("You are a {level} company", { level: $t(strNivel) }) }} </p>
              <p v-else class="mb-2"> {{$t("You need to conquer a level")}} </p>
          </div>
        </div>
        <div class="v-card__text" align="center">
            <div class="d-flex justify-content-around align-items-center">
              <div v-for="(c,i) in constraints" :key="i">
                <b-icon-star-fill variant="warning" font-scale="4" v-if="nivel>c.comparator"></b-icon-star-fill>
                <b-icon-star font-scale="4" v-else></b-icon-star>
                <div class="py-1">{{$t(c.label)}}</div>                
              </div> 
            </div>
        </div>
    </div>
  </transition>
</template>



<script lang="ts">
import { AggDiversityEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CardCalcSelo extends Vue {
  
  @Prop({default: null})
  public dados!: AggDiversityEntity;
  
  @Watch('dados', {deep: true})
  public updateNivel() {
    this.calculaNivel();
  }

  public nivel: number = 0;
  public strNivel: string = '';

  public constraints: any = [
    { comparator: 0, label: "Modest" },
    { comparator: 1, label: "Enthusiastic" },
    { comparator: 2, label: "Promoter" },
    { comparator: 3, label: "Inspiring" }
  ];

  public mounted() {
    this.calculaNivel();
  }

  public levelCompare(comparator: number) {
    return (
        ( (this.dados.grp_feminino >= comparator ) ? 1 : 0) + 
        ( (this.dados.grp_mov_black >= comparator ) ? 1 : 0) + 
        ( (this.dados.grp_3idade >= comparator ) ? 1 : 0) + 
        ( (this.dados.grp_pcd >= comparator) ? 1 : 0) + 
        ( (this.dados.grp_lgbt >= comparator) ? 1 : 0) + 
        ( (this.dados.grp_imigrante >= comparator) ? 1 : 0)
      );
  }

  public calculaNivel(){
    if( this.levelCompare(1000) >= 6 ){
      this.nivel = 4;
      this.strNivel = "Inspiring";
    }else if ( this.levelCompare(500) >= 4){
      this.nivel = 3;
      this.strNivel = "Promoter";
    }else if( this.levelCompare(100) >= 3){
      this.nivel = 2;
      this.strNivel = "Enthusiastic";
    }else if( this.levelCompare(20) >= 3 ){
      this.nivel = 1;
      this.strNivel = "Modest";
    }
  }

}
</script>