<template>
  <sidebar v-if="empresa" :background-avatar="empresa.backgroundAvatar">
    <div class="sidebar-main">
      <avatar
        class="avatar--center"
        :src="fotoAvatar"
        :alt="primeiroNome"
        :nome-url="nomeUrl"
        border="primary"
      />
      <h5 class="greeting text-center mt-3 mb-0">
        {{$t('Hello')}},
        <span class="font-weight-light">{{primeiroNome}}</span>
      </h5>

      <div class="sidebar-main__jobecoins">
        <h3 class="sidebar-main__jobecoins--saldo">
          {{saldMain}} 
        </h3>
        <p class="sidebar-main__jobecoins--msg-saldo">{{ $t('Balance in Interviews') }}</p>
        
      </div>

      <nav class="sidebar-main__navigation" @click="emitSidebar">
        <router-link :to="{path:'/home', hash: '', query: {lang: $i18n.locale}}" class="sidebar-main__navigation__link">
          <icon name="home" size="lg" class="mr-2"></icon>
          {{$t('Home')}}
        </router-link>
        <router-link :to="{path:'/', hash: '', query: {lang: $i18n.locale}}" class="sidebar-main__navigation__link">
          <icon name="graficos" size="lg" class="mr-2"></icon>
          {{$t('Dashboard')}}
        </router-link>

        <router-link
          :to="{path:'/my-jobs-openings', hash: '#jobs', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="minhas-vagas" size="lg" class="mr-2" :usefa="false"></icon>
          {{$t('My interviews')}}
        </router-link>

        <router-link
          :to="{path:'/rooms', hash: '#room', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
          style="position:relative"
        >
          <icon name="fa fa-camera" size="lg" class="mr-2"></icon>
          {{$t('Interview Room')}} <small style="position:absolute;right:30px;top:0px"><b-badge pill variant="danger">beta</b-badge></small>
        </router-link>
        <router-link v-if="permitTests"
          :to="{path:'/exams', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
          style="position:relative"
        >
          <icon name="fas fa-tasks" size="lg" class="mr-2"></icon>
          {{$t('Tests')}} 
        </router-link>
        <router-link v-if="isADM && permitCulturalMatch"
          :to="{path:'/match', hash: '#match', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="user" size="lg" class="mr-2"></icon>
          <!-- {{$t('Match')}} -->
          {{$t('Cultural Match')}}
        </router-link>
        <router-link v-if="permitPlural"
          :to="{path:'/plural', hash: '#pl0', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="fas fa-universal-access" size="lg" class="mr-2"></icon>
          Plural
        </router-link>      
        <router-link v-if="permitTalentSearch"
          :to="{path:'/filter-candidates', hash: '#filter-candidates', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <i class="fas fa-filter mr-2"></i>
          {{$t('Filter Candidates')}}
        </router-link>
        <router-link  v-if="isADM && permitReports"
          :to="{path:'/reports', hash: '#rep', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="fa fa-tasks" size="lg" class="mr-2"></icon>
          {{$t('Reports')}}
        </router-link>
        <router-link v-if="isADM && permitCompanyPage"
          :to="{path:'/company-info', hash: '#in1', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="fa fa-building" size="lg" class="mr-2"></icon>
          {{$t('Company Page')}}
        </router-link>
        <router-link
          :to="{path:'/profile', hash: '#pr0', query: {lang: $i18n.locale}}"
          class="sidebar-main__navigation__link"
        >
          <icon name="fas fa-cog" size="lg" class="mr-2"></icon>
          {{$t('Settings')}}
        </router-link>

      </nav>
    </div>
  </sidebar>
</template>

<script lang="ts">
import Avatar from "@/components/avatar/Avatar.vue";
import ButtonIcon from "@/components/button-icon/ButtonIcon.vue";
import TextInputAppend from "@/components/form/TextInputAppend.vue";
import Icon from "@/components/icon/Icon.vue";
import Sidebar from "@/components/layout/BaseSidebar.vue";
import Report from '@/core/Services/Empresa/Dashboard';
import GestaoRecrutador from "@/core/Services/Empresa/GestaoRecrutador";
import { siteCandidateEndPoint } from '@/core/endpoint';
import { EmpresaEntity, RecrutadorEntity } from "@/core/models/Entities";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
 
const namespace: string = "profile";

@Component<SidebarMain>({
  components: {
    avatar: Avatar,
    "button-icon": ButtonIcon,
    sidebar: Sidebar,
    "text-input-append": TextInputAppend,
    icon: Icon
  }
})
export default class SidebarMain extends Vue {
  public idiomasAPPList: Array<any> = [];
  // public perfil: PerfilCandidatoEntity = new PerfilCandidatoEntity();
  // public myProfile: CandidatoEntity = new CandidatoEntity();
  public recrutadorService: GestaoRecrutador;
  public serviceDash: Report;
  public isADM: boolean = false;
  public uri: string = siteCandidateEndPoint;

  @Action("fetchEmpresaInfo", { namespace })
  public fetchEmpresaInfo: any;

  @Action("fetchSaldo", { namespace })
  public fetchSaldo: any;

  @Getter("companyInfo", { namespace })
  public empresa?: EmpresaEntity;

  @Getter("saldInfo", { namespace })
  public saldo!: number;

  @Getter("recruiterInfo", { namespace })
  public recrutador?: RecrutadorEntity;

  constructor() {
    super();
    this.recrutadorService = new GestaoRecrutador();
    this.serviceDash = new Report();
  }
  
  get IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get permitCulturalMatch() {
    return this.empresa?.configuracoes?.ENABLE_CULTURAL_MATCH == true
  }
  
  get permitPlural() {
    return this.empresa?.configuracoes?.ENABLE_HIDE_PLURAL != true
  }

  get permitTests() {
    return this.empresa?.configuracoes?.ENABLE_QUESTIONARY == true
  }

  get permitReports() {
    return this.empresa?.configuracoes?.ENABLE_MORE_REPORTS == true
  }

  get permitCompanyPage() {
    return this.empresa?.configuracoes?.ENABLE_COMPANY_PAGE == true
  }

  get permitTalentSearch() {
    return this.empresa?.configuracoes?.ENABLE_TALENT_SEARCH == true
  }
  public async created() {
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
    } 

    if(this.saldo === undefined) {
      this.fetchSaldo();
    }
    if (this.IsRecruiter) {
      this.isADM = await this.recrutadorService.isAdm();
    } else {
      this.isADM = true;
    }
  
  }

  get primeiroNome() {
    return (!this.IsRecruiter) ? (Boolean(this.empresa!.primeironome) ? this.empresa!.primeironome : null) : (Boolean(this.recrutador!.nome) ? this.recrutador!.nome : null);
  }

  get saldMain () {
    return this.saldo||0;
  } 

  get nomeUrl() {
    return Boolean(this.empresa!.nomeUrl)
      ? this.uri + "/e/" + this.empresa!.nomeUrl
      : null;
  }

  get fotoAvatar() {
    return (!this.IsRecruiter) ? (Boolean(this.empresa!.fotoAvatar) ? this.empresa!.fotoAvatar : null) : (Boolean(this.recrutador!.fotoAvatar) ? this.recrutador!.fotoAvatar : null);
  }

  get backgroundAvatar() {
    return Boolean(this.empresa!.backgroundAvatar)
      ? this.empresa!.backgroundAvatar
      : null;
  }

  public handleAvatarChange(value: any) {
    console.log(value);
  }

  public handleBackgroundImageChange(event: any) {
    console.log(event);
  }

  public emitSidebar() {
    if (window.innerWidth <= 992) {
      this.$emit("on-sidebar");
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/_mixins";

.sidebar-main {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__jobecoins {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5em;
    font-size: 0.7em;

    &--saldo {
      font-weight: 400;
      font-size: 2em;
      margin: 0;
    }
    &--msg-saldo {
      font-size: 1em;
      margin-bottom: 0.5em;
    }

    &--btn {
      // button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%));
      @include button-variant($green, $green);
      font-size: 1em;
    }
  }

  &__navigation {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // justify-content: center;

    .fa-filter, .fa-cog {
      font-size: 20px;
    }
    .icon--lg {
      font-size: 20px;
    }
    .iconfa--lg {
      font-size: 20px;
    }
    padding-left: 1.1em;
    margin-top: 1em;

    &__link {
      display: flex;
      align-items: center;

      height: 35px;
      width: 200px;
      padding-left: 20px;
      background-color: transparent;

      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;

      color: $white;
      font-size: 1em;
      text-decoration: none !important;

      margin-top: 1px;
      margin-bottom: 1px;
      
      &:hover {
        font-weight: bold;
        font-size: 1.01em;
        background-color: $white;
        color: $blue;
      }
    }

    &__icon {
      margin-right: 10px;
    }

    .router-link-exact-active {
      float: inherit;
      color: $blue;
      cursor: pointer;
      position: relative;
      top: 0px;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      margin-right: 0px;
      margin-left: 0px;
      background-color: $white;
      line-height: 1;
      line-height: inherit;
      text-transform: inherit;
    }
  }

  &__social-media-nav {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 7px 0 9px;
  }

  &__social-link {
    display: inline-flex;
    align-items: baseline;
    justify-content: center;

    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0 5px;

    background-color: $white !important;
    border-radius: 50%;

    color: var(--secondary) !important;
  }

  .avatar--center {
    margin: 0 auto;
  }

  .greeting {
    font-size: 1.5em;
  }
}
</style>