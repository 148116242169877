<template>
  <div class="box-container d-flex flex-column mb-5">
    <div class="row">
      <div class="col-md-12 mb-4">
        <h1 class="text-md-left text-center">{{$t("Company profile")}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="nav justify-content-center">
          <li class="nav-item" :class="{ 'active' : activeNav == 1}" v-if="(!IsRecruiter  || IsAdmin)">
            <a class="nav-link" @click="setActiveNav(1)">{{$t("Company data")}}</a>
          </li>
          <li class="nav-item" :class="{ 'active' : activeNav == 5}" v-if="!IsRecruiter || IsAdmin">
            <a class="nav-link" @click="setActiveNav(5)">{{$t("Company Page")}}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Aba de dados da empresa -->
    <div v-if="activeNav == 1 && (!IsRecruiter  || IsAdmin)" >
      <dados-perfil />
    </div>
    <!-- Aba de troca de senha -->
    <div v-if="activeNav == 5 && (!IsRecruiter  || IsAdmin)" >
      <pagina-institucional />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";
import DadosPerfil from './partials/DadosPerfil.vue';
import PaginaInstitucional from './partials/PaginaInstitucional.vue';
import { RecrutadorEntity, EmpresaEntity } from '../../core/models/Entities';

@Component<Institucional>({
  components: {
    DadosPerfil,
    PaginaInstitucional
  }
})
export default class Institucional extends Vue {

  public activeNav = 1;
  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('recruiterInfo',{ namespace })
  public recrutador!: RecrutadorEntity;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  public async mounted() {
    if (!this.recrutador && !this.empresa) {
      await this.fetchEmpresaInfo();
    }
    const x = location.hash.substring(3,location.hash.length);
    if (x && !isNaN(parseInt(x))) {
      this.activeNav = parseInt(x);
    }
    console.log(x)
  }

  get IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get IsAdmin() {
    return (sessionStorage.getItem("profile_type") == 'recruiter' && this.recrutador && this.recrutador.permissaoAdm == "Y") || (sessionStorage.getItem("profile_type") == 'company')
  }
  
  public setActiveNav(nav: number) {
      this.activeNav = nav;
      history.pushState({},'', this.$route.fullPath.replace(this.$route.hash,'')  + '#in' + nav )
  }
  
  public saveEmpresa() {

  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.box-container {
  min-height: auto;
  .nav {
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;

    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>