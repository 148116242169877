<template>
  <div class="title p-0 col-md-4" v-if="value">
    <div>
      <div class="mb-1">
        <h3 class="text-truncate m-0" :title="value.posicao">{{ value.posicao }}</h3>
        <small v-if="value.cargo">{{ value.cargo }}</small>
      </div>
      
      <div class="d-flex align-items-center description">
        <p v-if="value.recrutador" class="m-0">{{ value.recrutador.nome }}</p>
        <span v-if="value.recrutador" class="my-0 mx-1 text-muted">-</span>

        <p class="m-0">{{ MostrarData(value.dataCadastro.date) }}</p>
        <span class="my-0 mx-1 text-muted">-</span>

        <p class="m-0 text-truncate" :title="value.localidade">{{ value.localidade }}</p>
      </div>

      <div class="d-flex mt-4 badges">
        <span v-if="value.status == 'ATIVO'" class="mr-1 badge outline green rounded-pill">{{ $t("Active") }}</span>
        <span v-if="value.status == 'INATIVO'" class="mr-1 badge outline warning rounded-pill">{{ $t("Inactive") }}</span>
        <span v-if="value.status == 'ENCERRADA'" class="mr-1 badge outline danger rounded-pill">{{ $t("Closed") }}</span>

        <span v-if="value.confidencial == 'SIM'" class="badge primary outline bg-primary mr-1 rounded-pill">{{ $t("Confidencial") }}</span>
        <span v-if="value.blind" class="badge primary outline bg-primary mr-1 rounded-pill">{{ $t("Anonymous") }}</span>
        <span v-if="value.restrita == 'SIM'" class="badge primary outline bg-primary mr-1 rounded-pill">{{ $t("Restricted") }}</span>
        <span v-if="value.interna" class="badge primary outline bg-primary mr-1 rounded-pill">{{ $t("Internal") }}</span>

        <span
          v-if="(value.equipesVaga?.length || 0) > 0" 
          :id="tooltipId" 
          class="badge primary mr-1 rounded-pill outline"
        >
          {{ $t("Teams") }}
        </span>

        <b-tooltip
          v-if="(value.equipesVaga?.length || 0) > 0"
          :target="tooltipId"
          placement="bottom"
          custom-class="tooltip-checkbox_enableEquipes"
        >
          <div class="mr-1" v-for="(h,i) in value.equipesVaga" :key="i">
            <small> {{h.title}} </small>
          </div>
        </b-tooltip>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VagaEntity } from "@/core/models/Entities";
import moment from 'moment';

@Component({
  components: {
    // 'vaga-status': VagaStatus,
    // 'add-jobcoins': AdicionarJobcoins,
    // 'vaga-footer': VagaFooter,
  },
})
export default class VagaTitle extends Vue{

  @Prop()
  public value!: VagaEntity;

  get tooltipId() {
    return 'button-equipes_'+this.value.id
  }

  public MostrarData(data: string) {
    return moment(data).format("DD/MM/YYYY");
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  .title {
    span {
      font-size: 1rem;
    }
    button {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 1.3rem;
    }

    .badge {
      font-size: 0.8rem;
      font-weight: 500;
      padding: 4px 8px;
      color: $white;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .description {
      width: 80%;
    }

    .badge.green {
      background: $green;

      &.outline {
        background: none;
        border: 2px solid $green;
        color: $green;
      }
    }

    .badge.primary {
      background: $primary;

      &.outline {
        background: none !important;
        border: 2px solid $primary;
        color: $primary;
      }
    }

    .badge.warning {
      background: $warning;
      color: $dark;

      &.outline {
        background: none;
        border: 2px solid $warning;
        color: $warning;
      }
    }

    .badge.danger {
      background: $danger;

      &.outline {
        background: none;
        border: 2px solid $danger;
        color: $danger;
      }
    }

    @media screen and (max-width: 1260px) {
      .badges {
        flex-wrap: wrap;

        .badge {
          margin-bottom: 4px;
        }
      }
    }
  }

  @include media("<desktop") {
    
  }
  @include media(">desktop", "<=large") {
    
  }

  @include media(">large") {
    
  }
</style>