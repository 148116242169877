// tslint: disable: no-string-literal
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { SugestaoTagEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';


export default class SugestaoTag extends Repository {
    public entidade: SugestaoTagEntity;
    public sugestionList: SugestaoTagEntity[];

    constructor() {
        super(new ApiRouter({
            fetchAll: { url: '/v1/sugestao-tag', method: 'GET', headers: {} }
        }));
        this.entidade = new SugestaoTagEntity();
        this.sugestionList = [];
    }

    public fetchSugestions(position: string) {
        return super.fetchAll({ s: position }).then((data: SugestaoTagEntity[]) => {
            this.sugestionList = data;
        });
    }

}


