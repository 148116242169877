
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { clientId } from '../../endpoint';
import { EmpresaEntity, RecrutadorEntity } from '../../models/Entities';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';
import { EStatus } from '@/core/Enums/EStatus';
import { CB, Utilities } from '@/core/util';
import TimezoneHelper from '@/core/helper/TimezoneHelper';

export default class Empresa extends Repository {

  public entidade: EmpresaEntity;
  public entidadeRecrutador?: RecrutadorEntity;

  constructor(i18n?: any) {
    super(new ApiRouter({
      auth: { url: '/v1/company-auth', method: 'POST', headers: {} },
      create: { url: '/v1/empresa', method: 'POST', headers: {} },
      fetch: { url: '/v1/empresa/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/empresa', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      info: { url: '/v1/my-info', method: 'GET', headers: {} },
      me: { url: '/v1/me', method: 'GET', headers: {} },
      meup: { url: '/v1/me/{id}', method: 'PUT', headers: {} },
      logout: { url: '/v1/me/{id}', method: 'DELETE', headers: {} },
      update: { url: '/v1/empresa/{id}', method: 'PUT', headers: {} },
    }));
    if (i18n) {
      this.$i18n = i18n;
    }
    this.entidade = new EmpresaEntity();
  }

  public create(data: any, ctype?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
        reject(resp);
      });
    });
  }

  public loadEntidade(promisse: Promise<Empresa>, callback?: () => void) {
    promisse.then((data: any) => {
      this.entidade = data;
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line:no-console
      console.log('Couldn\'t load my info.', err);
    });
  }

  public loadFromNomeUrl(url: string, callback?: () => void): void {
    if (sessionStorage.getItem('access_token') !== null) {
      this.loadEntidade(super.call('fetch', { id: 0, urlCode: url }, 'application/json'), callback);
    } else {
      this.publicLoadFromNomeUrl(url, callback);
    }
  }

  public publicLoadFromNomeUrl(url: string, callback?: () => void): void {
    (new ClientAuthentication()).auth().then((rtoken: any) => {
      this.loadEntidade(super.call('fetch', { id: 0, urlCode: url, rtoken }, 'application/json'), callback);
    }).catch((resp) => {
      // tslint:disable-next-line:no-console
      console.log(resp);
    });
  }

  public whatIsMissing(perfil: any): string {
    let msg: string = '';
    if (perfil) {
      msg += !(perfil.nomeEmpresa) ? this.$i18n.t('Nome Empresa') + '<br />' : '';
      msg += !(perfil.cep) ? this.$i18n.t('CEP') + '<br />' : '';
      msg += !(perfil.endereco) ? this.$i18n.t('Endereço') + '<br />' : '';
      msg += !(perfil.bairro) ? this.$i18n.t('Bairro') + '<br />' : '';
    }
    return msg;
  }

  public checkCompanyStatus() {
    const arr: string[] = [];

    // openPaths
    const path: string = this.getRouter().currentRoute.fullPath;
    const openPaths: string[] = ['/profile','/plan/','/room/','/redirectLogin'];
    for (let index = 0; index < openPaths.length; index++) {
      if (path.indexOf(openPaths[index])  > -1)
        return true;
    }

    if (this.entidade.statusCadastro === EStatus.PENDENTE || this.entidade.statusPagamento === EStatus.PENDENTE) {
      let msg: string = '';
      let redirectUri: string = '';
      if (this.entidade.statusCadastro === EStatus.PENDENTE) {
        arr.push(this.$i18n.t('Seu cadastro esta incompleto').toString());
        redirectUri = '/profile';
        arr.push(this.$i18n.t('Vamos te redirecionar para seu formulário de perfil').toString());
      }
      if (this.entidade.statusPagamento === EStatus.PENDENTE) {
        msg = 'Seu pagamento não foi efetuado';
        if (!redirectUri) {
          redirectUri = '/';
          arr.push(this.$i18n.t('Vamos te redirecionar página de planos').toString());
        }
      }

      arr.push(this.$i18n.t(msg).toString());
      this.openAlert(arr, this.$i18n.t('Dados incompletos').toString());
      setTimeout(() => {
        const routObject: any = { path: redirectUri, query: { lang: this.getI18n().locale }, hash: '#pr1' };
        console.log(routObject)
        this.getRouter().push(routObject);
      }, 4000);
      return false;
    }
    return true;
  }

  public loadMyInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      super.myInfo().then((data: any) => {
        if (data.type === 'company') {
          this.entidade = data.entity;
        } else if (data.type === 'recruiter') {
          this.entidade = data.entity_company;
          this.entidadeRecrutador = data.entity;
        }
        this.checkCompanyStatus();
        resolve(data);
      }).catch((err: any) => {
        // tslint:disable-next-line: no-console
        console.log('Couldn\'t load my info.', err);
        reject(err);
      });
    });
    
  }

  public async auth(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = 'company';
    try {
      data.platform = navigator.platform;
      data.userAgent = navigator.userAgent;
      data.appVersion = navigator.appVersion;
      data.timezone = await TimezoneHelper.GuessTimezone();
      if(!window['jbdev']) {
        data.ip = await Utilities.getIP();
      }
    } catch (e) {
      console.log('Could not set navigator settings')
    }
    return super.auth(data);
  }

  public async authRecruiter(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = 'recruiter';
    
    try {
      data.platform = navigator.platform;
      data.userAgent = navigator.userAgent;
      data.appVersion = navigator.appVersion;
      data.timezone = await TimezoneHelper.GuessTimezone();
      data.ip = await Utilities.getIP();
    } catch (e) {
      console.log('Could not set navigator settings')
    }
    return super.auth(data);
  }


  public async save(): Promise<any> {
    return super.update(this.entidade)
      .then((data: any) => {
        console.log(data)
        this.entidade = data;
        return true;
      })
      .catch((err: any) => {
        // if (this.entidade.perfilempresa)
        //   this.entidade.perfil = this.entidade.perfilempresa;

        return false;
      });
  }

  public saveField(fieldName: string, value: any, empresaId?: number) {
    const data: any = {};
    data.id = empresaId || this.entidade!.id;
    data[fieldName] = value;
    return super.update(data);
  }

  public saveBackgroundAvatar(file: any): Promise<any> {
    return this.saveFile('bgavatar', file);
  }

  public saveAvatar(file: any): Promise<any> {
    return this.saveFile('avatar', file);
  }

  public saveFile(key: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', '' + this.entidade.id);
    formData.append(key, file);
    return super.update(formData, 'multipart/form-data');
  }

  public logout(): Promise<any> {
    return super.call('logout',  { id: 0 }, 'application/json').finally(() => { super.removeItensFromStorage(); this.pushToLogout(); });
  }

}
