import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';


export default class RoomAccess extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/room-access', method: 'POST', headers: {} },
            fetch: { url: '/v1/room-access/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/room-access', method: 'GET', headers: {} },
            update: { url: '/v1/room-access/{id}', method: 'PUT', headers: {} },
            delete: { url: '/v1/room-access/{id}', method: 'DELETE', headers: {} }
        }));
    }

    public call(key: string, data: any, ctype: string, blockCatch?: boolean): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call(key, data, ctype, blockCatch).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call(key, cdta, ctype, blockCatch).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            }
        });
    }
}


