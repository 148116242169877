
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CandidaturaEntity, VagaEntity } from '@/core/models/Entities';

export default class GestaoCandidatura extends Repository {

  public list: CandidaturaEntity[] | null = null;
  public iVaga: VagaEntity | null = null;
  public entidade: CandidaturaEntity | null = null;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/gestao-candidatura', method: 'POST', headers: {} },
      delete: { url: '/v1/gestao-candidatura/{id}', method: 'DELETE', headers: {} },
      fetch: { url: '/v1/gestao-candidatura/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/gestao-candidatura', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/gestao-candidatura/{id}', method: 'PUT', headers: {} }
    }));
  }

  public latests(d?: any): Promise<any> {
    let filter: any = {latest: true, limit: 3 };
    if (d) {
      filter = Object.assign(d, { latest: true });
    }
    return this.fetchAll(filter).then((data: any) => {
      this.list = data['candidaturas'];
      if (data.vaga)
        this.iVaga = data.vaga;
    });
  }

}
