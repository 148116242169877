<template>
  <transition appear name="slide">
    <b-row class="my-2">
      <b-col md="12 d-flex justify-content-between">
        <h4 class=" text-center  text-info"> {{$t("Qualifying questions")}}</h4>
        <b-button  variant="info"
          v-if="getListPerguntaCorte.length < 3"
          @click.prevent="addPerguntaCorte()"
          v-b-tooltip.hover
          :title="$t('Add new question')"
        >
        <i class="fa fa-plus-circle" /> {{$t('Add new question')}}
        </b-button>
      </b-col>
      <b-col md="4" sm="12" class="px-1" v-for="(per, i) in getListPerguntaCorte" v-bind:key="i">
          <b-card class="border m-1">
            <b-row>
              <b-col md="12" class="d-flex justify-content-between">
                <div class="flex-fill"> 
                  <text-input
                      variant="dark"
                      class="text-uppercase flex-fill"
                      v-model="per.pergunta"
                      :required="true"
                      :label="$t('Enter {index}° question', {index: i + 1})"
                    />
                </div>
                <div>
                  <b-button variant="outline-danger" class="shadow-sm-hover" pill 
                    v-b-tooltip.hover
                    :title="$t('Remove question')"
                      @click.prevent="deletePerguntaCorte(per)"
                    >
                    <i class="fa fa-times"></i>
                    </b-button>
                </div>
              </b-col>
              <b-col md="12" class="d-flex justify-content-start align-items-center">
                <div class="mx-2">
                    {{$t('Expected Answer')}}
                </div>
                  <b-form-radio-group
                    buttons
                    button-variant="outline-primary"
                    v-model="per.eliminatoria"
                    class="radio-group"
                    :name="'form'+i" >
                    <b-form-radio :required="false" :value="1">{{ $t('Yes') }}</b-form-radio>
                    <b-form-radio :required="false" :value="0">{{ $t('No') }}</b-form-radio>
                  </b-form-radio-group>
              </b-col>
            </b-row>
          </b-card>
      </b-col>
    </b-row>
  </transition>
</template>


<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  VagaPerguntaCorteEntity
} from "@/core/models/Entities";

@Component
export default class FormVagaPerguntaCorte extends Vue {

  @Prop({default: []})
  public value!: VagaPerguntaCorteEntity[];

  get getListPerguntaCorte() {
    return this.value;
  }
  
  public deletePerguntaCorte(pergunta: any) {
      let i = this.value.indexOf(pergunta);

      this.$confirm.addMsg(
        this.$t("Are you sure you want to delete the qualifying question?")
      );
      this.$confirm.callBoxSuccess().then((flag: boolean) => {
        if (flag) {
            this.$emit('removed',pergunta);
            this.value.splice(i, 1);
            this.$emit('input',this.value);
        } else {
          console.log("canceled");
        }
      });
    // }
  }

  public addPerguntaCorte() {
    if (this.value.length < 3) {
      try {
        this.value.push(new VagaPerguntaCorteEntity());
        this.$emit('input',this.value);
      } catch (e: any) {
        console.log(e.message);
      }
    } else {
      this.$alert.addMsg(this.$t("Maximum of questions reached"));
      this.$alert.callBoxWarn();
    }
  }

  public mounted() {
  }

}
</script>


