<template>
  <div class="box-container d-flex flex-column">
    <div class="col-md-4 py-3 py-md-0 mb-3">
      <h1 class="text-center text-md-left">{{$t("Filter Candidates")}}</h1>
    </div>
    <filter-form @submitFilter="doFilter"/>
        <div class="row mt-4">
          <div class="col-md-12 list-cards d-flex flex-wrap align-items-center justify-content-center">
            <card-candidato 
              v-for="candidato in candidatos" 
              :candidato="candidato" 
              :key="candidato.id"
              @showMessage="showMessage" />
          </div>
          <div class="col-md-12" v-if="currentPage>=1">
            <infinite-loading :identifier="infinitecandidatoId" spinner="bubbles" @infinite="infiniteHandler">
              <div class="mt-4" slot="no-more">
                <p >{{ $t("Sorry, this is all we got") }}</p>
              </div>
              <div class="mt-4" slot="no-results">
                <p >{{$t("No candidate found")}}</p>
              </div>
            </infinite-loading>
          </div>
        </div>
      
    <modal-message id="modalMessageInFilter" :candidato="candidatoSelecionado" :single="true" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CandidatoEntity, HabilidadeEntity, CandidatosFilterObject } from '@/core/models/Entities';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import CandidatoFilterForm from "./partials/CandidatoFilterForm.vue"
import CardCandidatos from "../candidatos/CardCandidatos.vue"
import InfiniteLoading from "vue-infinite-loading"
import ModalSendMessage from "@/components/modal/ModalSendMessage.vue";

@Component({
  components: {
    'filter-form': CandidatoFilterForm,
    'card-candidato': CardCandidatos,
    'infinite-loading': InfiniteLoading,
    'modal-message': ModalSendMessage,
  },
})
export default class CandidatoFilter extends Vue {
  public candidatos: CandidatoEntity[] = [];
  public candidatoSelecionado: CandidatoEntity = new CandidatoEntity()
  public candidatoSRV: CandidatoService;
  public totalCount: number = 0;
  public currentPage: number = 0;
  public page: number = 0;
  public perPage: number = 20;
  public limit: number = 20;
  public infinitecandidatoId: number = (new Date().getTime());
  public filter: CandidatosFilterObject = new CandidatosFilterObject();
  public fields: any[]|null = null;

  public constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.candidatos = []
  }

  public mounted() {
    this.fields = [
      { "key": "primeironome", "label": this.$t("Name"), "class": "text-center" },
      { "key": "sobrenome", "label": this.$t("Last name"), "class": "text-center" },
      { "key": "email", "label": this.$t("E-mail"), "class": "text-center" },
      { "key": "perfil", "label": this.$t("Profile"), "class": "text-center" },
      { "key": "localidade", "label": this.$t("Location"), "class": "text-center" },
      { "key": "actions", "label": '-', "class": "text-center" },
    ]
  }

  public showMessage(c: CandidatoEntity) {
    this.candidatoSelecionado = c;
    this.$bvModal.show('modalMessageInFilter')
  }

  public doFilter(filter: CandidatosFilterObject) {
    this.filter = filter
    this.candidatos = [];
    this.infinitecandidatoId++;
    this.currentPage = 1;
    this.filterResults(1);
  }

  public filterResults(page?: number, $state?: any) {
    this.filter.limit = this.limit ? this.limit : 8;
    this.filter.page = page||0;
    // let filter: CandidatosFilterObject = this.filter;
    // let s = filter.s ? filter.s : '';
    // let areaAtuacao = filter.areaAtuacao ? filter.areaAtuacao : '';
    // let escolaridade = filter.escolaridade ? filter.escolaridade : '';
    // let idioma = filter.idioma ? filter.idioma : '';
    // let idiomaNivel = filter.idiomaNivel ? filter.idiomaNivel : '';
    // let habilidade = filter.habilidade ? filter.habilidade : '';
    // let habilidades = filter.habilidades ? filter.habilidades : '';
    // let habilidadeNivel = filter.habilidadeNivel ? filter.habilidadeNivel : '';
    // let localidade = filter.localidade ? filter.localidade :'';
    this.candidatoSRV
      .fetchAll(this.filter.normalized())
      .then((data: any) => {
        if (data && data['candidatos'] && data['candidatos'].length > 0) {
          this.candidatos.push(...data["candidatos"])
          // data['candidatos'].forEach(element => {
          //   this.candidatos.push(element)  
          // });
          // this.totalCount = parseInt(data["size"]);
          if ($state) {
            $state.loaded();
          }
        } else if (!data["candidatos"].length && $state) {
         $state.complete();
        }
      })
      .catch((err: any) => {
        console.log(err);
        if($state)
          $state.complete();
      });
  }

  public infiniteHandler($state: any)
  {
    if(this.currentPage>=1)
      this.filterResults(++this.currentPage,$state);
  }
}
</script>

<style>

</style>