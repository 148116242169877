<template>
<div class="position-relative m-h-400" ref="containerLoader">
  <b-form @submit.prevent="saveData" class="simple-job" v-if="value">
    <b-row>
      <b-col md="6">
        <h2>{{$t('General information')}}</h2>
      </b-col>
      <b-col md="6" class="d-flex justify-content-end align-items-center" >
        <b-link v-if="value.id  && !simpleJobOnly" :to="{name: 'JobsOpeningsEdit', params: {id: value.id}, query: {lang: $i18n.locale}}"> 
          <small>{{$t('Change to complete')}}</small> 
        </b-link>
      </b-col>
      <div class="col-sm-12 col-md-4">
        <text-input
          variant="dark"
          :required="true"
          :label="$t('Position')"
          :placeholder="$t('Human resources analyst')"
          v-model="value.posicao"
        />
      </div>
      <div class="col-sm-12 col-md-4">
        <select-input
          :optionList="getListNivel"
          :required="true"
          :class="redForRequired(value.nivel)"
          variant="dark"
          :label="$t('Level')"
          v-model="value.nivel"
          :value="value.nivel"
        />
      </div>
      <div class="col-sm-4">
        <text-input
          variant="dark"
          :required="true"
          :label="$t('Location')"
          v-model="value.localidade"
          placeholder=""
        />
      </div>
    </b-row>
    
    <b-row>
      <b-col md="4">
        <h2>{{$t('Job Display Options')}}</h2>
        <b-list-group>

          <ListGroupItemCheckbox label="Hide Address" id="hideMap" v-model="value.hideMap" >
            <HelpTip id="hideMap" tip="Location is removed from the display to the candidate" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Send Feedback on close" id="sendMessageAfterClosing" v-model="value.sendMessageAfterClosing" >
            <HelpTip id="sendMessageAfterClosing" tip="Send automatic feedback email to not approved candidates in the process" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Confidencial" id="confidencial" v-model="value.confidencial" :checked="'SIM'" :unchecked="'NAO'">
            <HelpTip id="confidencial" tip="Detailed company data is hidden from candidate" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Restricted" id="restrita" v-model="value.restrita" :checked="'SIM'" :unchecked="'NAO'">
            <HelpTip id="restrita" tip="Only candidates who receive the job link can apply, the vacancy does not appear in the Jobecam board" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Internal" id="interna" v-model="value.interna">
            <HelpTip id="interna" tip="Internal vacancy to be worked within the company, when selecting the vacancy will not appear in the Board of Jobecam, only guests will be able to access the vacancy page" />
          </ListGroupItemCheckbox>

        </b-list-group>
      </b-col>
      <b-col md="4">
        <h2>{{$t('Talent Selection')}}</h2>
        <b-list-group>
          <ListGroupItemCheckbox label="Anonymous Interview" id="blind" :checked="1" :unchecked="0"
            :disabled="forceAnonimous" v-model="value.blind" v-if="permitBlind && !forceAnonimous">
            <HelpTip id="blind" tip="All candidate information is hidden, only the sound of the interview can be evaluated" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Anonymous Resume" id="blind_curr" :disabled="value.requireProfile" v-model="value.mostrarCurriculoOculto" v-if="permitBlind">
            <HelpTip id="blind_curr" tip="Enable hidden CV so candidate can share information for your hidden review" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Prevent talent revelation" id="block_approve" v-model="value.blockApprove" v-if="permitBlind && permitBlockApprove">
            <HelpTip id="block_approve" tip="Blocks the talent revelation functionality in the room" />
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Required Full Profile" id="fullprofile" :disabled="value.mostrarCurriculoOculto"
            v-model="value.requireProfile" v-if="!forceAnonimous">
            <HelpTip id="fullprofile" >
              <ul class="list-unstyled">
                <li> {{ $t("Contact data") }}</li>
                <li> {{ $t("Resume PDF") }}</li>
                <li> {{ $t("Professional experience") }}</li>
                <li> {{ $t("Academic background") }}</li>
                <li> {{ $t("Video Curriculum") }}</li>
                <li> {{ $t("In case of anonymous curriculum, this option is disabled") }}</li>
              </ul>
            </HelpTip>
          </ListGroupItemCheckbox>
          
          <ListGroupItemCheckbox label="Review Step" id="revisaoCurricular" v-model="value.revisaoCurricular" v-if="permitReview">
            <HelpTip id="revisaoCurricular" tip="Candidates can only record the interview after approval by Recruiter" />
          </ListGroupItemCheckbox>

        </b-list-group>
      </b-col>
        
      <b-col md="4">
        <h2>{{$t('Additional steps and settings')}}</h2>
        <b-list-group class="mt-1"> 

          <ListGroupItemCheckbox label="Skills" id="enableHabilities" v-model="enableHabilities">
            <HelpTip id="enableHabilities">
              <ul class="list-unstyled">
                <li>{{$t('Indicate here the skills required for this position')}}</li>
                <li>{{$t('Use this feature to enhance the screening of candidates.')}}</li>
                <li>{{$t('This action is optional, and you can indicate up to 6 skills with their weights.')}}</li>
                <li>{{$t('Candidates who do not reach an average of 70% will not record the interview and go to waiting list.')}}</li>
              </ul>
            </HelpTip>
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Qualifying questions" id="enableCut" v-model="enableCut">
            <HelpTip id="enableCut">
              <ul class="list-unstyled">
                <li>{{$t('Create up to 3 questions that can eliminate candidates for this position.')}}</li>
                <li>{{$t('Questions must be answered with YES or NO.')}}</li>
              </ul>
            </HelpTip>
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Tests" id="enableTestes" v-model="enableTestes" v-if="permitTests">
            <HelpTip id="enableTestes" tip="Define the tests to be applied to candidates after qualifying questions" />
          </ListGroupItemCheckbox>

          <ListGroupItemCheckbox label="Teams" id="enableEquipes" v-model="enableTeams" v-if="permitEquipes">
            <HelpTip id="enableEquipes" tip="Define the teams that can access the information and talent applications" />
          </ListGroupItemCheckbox>
        </b-list-group>
      </b-col>
    </b-row>

    <!-- Colocar aqui os filtros -->
    <b-row class="mb-2">
      <b-col md="12" class="simple-job__teams">
        <form-vaga-equipes v-if="enableTeams && permitEquipes" v-model="selectedTeams" :equipes-vaga="value.equipesVaga" />
      </b-col>
      <b-col md="12" class="simple-job__habilities ">
        <form-vaga-habilidades v-if="enableHabilities" v-model="value.vagaHabilidades" />
      </b-col>
      <b-col md="12" class="simple-job__pergunta_corte">
        <form-vaga-pergunta-corte v-if="enableCut" v-model="value.PerguntasCorte" />
      </b-col>
      <b-col md="12" class="simple-job__exams">
        <form-vaga-exames v-if="permitTests && enableTestes" :vagaId="value.id" v-model="value.questionaries"/>
      </b-col>
    </b-row>
    
    <!-- descrição de vaga -->
    <!-- <b-row>
      <b-col md="12">
        <h6 :class="redForRequired(value.descricao)">{{ $t("Descrição da vaga") }}*</h6>
        <editor :init="initTinySimpleSmall" v-model="value.descricao"></editor>
      </b-col>
    </b-row> -->

    <form-vaga-perguntas 
      :no-margin="true" 
      :simple="true"
      v-model="value"
    ></form-vaga-perguntas>

    <b-row class="mt-2">
      <b-col md="12" class="d-flex justify-content-end">
        <b-button type="submit" class="btn btn-info btn-salvar text-uppercase px-5 py-3 shadow" v-promise-btn="{promise}">
          <i class="far fa-paper-plane"></i>
          {{$t('Save and publish')}}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
  
</div>
</template>


<script lang="ts">
import ListGroupItemCheckbox from '@/components/lists/ListGroupItemCheckbox.vue';
import HelpTip from '@/components/tooltip/HelpTip.vue';
import CidadeService from "@/core/Services/Cidade";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import EstadoService from "@/core/Services/Estado";
import { EmpresaEntity, RecrutadorEntity, VagaEntity } from "@/core/models/Entities";
import Editor from "@tinymce/tinymce-vue";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import FormVagaEquipes from './FormVagaEquipes.vue';
import FormVagaExames from './FormVagaExames.vue';
import FormVagaHabilidades from './FormVagaHabilidades.vue';
import FormVagaPerguntaCorte from './FormVagaPerguntaCorte.vue';
import FormVagaPerguntas from './FormVagaPerguntas.vue';

@Component({
  components: {
    editor: Editor,
    FormVagaPerguntas,
    FormVagaHabilidades,
    FormVagaPerguntaCorte,
    FormVagaExames,
    FormVagaEquipes,
    ListGroupItemCheckbox,
    HelpTip
  }
})
export default class FullSimpleForm extends Vue {
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;

  public faixaVariavel: boolean = true;
  public dtFinaliza: Date | null = null;
  public service: GestaoVaga;
  public estadoId: any = "";
  public cidadeId: any = "";
  
  public listNivel: any = [];
  public promise: Promise<any> | null = null;
  
  public enableHabilities: boolean = false;
  public enableTeams: boolean = false;
  public enableCut: boolean = false;
  public enableTestes: boolean = false;
  public selectedTeams: any = []; 
  

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: 'profile' })
  public recrutador?: RecrutadorEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;
  
  public value: VagaEntity|null = null;

  constructor() {
    super();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
    this.service = new GestaoVaga();
    this.estadoSRV.loadOptions();
    // this.value = new VagaEntity();
  }

  get simpleJobOnly () {
    return (this.empresa?.configuracoes?.ENABLE_SIMPLE_JOB_ONLY == true);
  }
  
  get forceAnonimous () {
    return (this.empresa?.configuracoes?.ENABLE_FORCE_ANONIMOUS == true);
  }

  get permitBlind () {
    return (this.empresa?.configuracoes?.BLIND_JOB_REVIEW == true);
  }

  get permitReview () {
    return (this.empresa?.configuracoes?.REVIEW_ENABLED == true);
  }

  get permitTests() {
    return this.empresa?.configuracoes?.ENABLE_QUESTIONARY == true
  }
    
  get permitBlockApprove () {
    return (this.empresa?.configuracoes?.ENABLE_CLOSE_APPROVEMENT == true);
  }
  
  get permitEquipes () {
    return (this.empresa?.configuracoes?.ENABLE_RECRUITING_GROUP == true);
  }

  get getListNivel() {
    return this.listNivel = [
      { text: this.$t("Trainee"), value: "Trainee" },
      { text: this.$t("Aprendiz"), value: "Aprendiz" },
      { text: this.$t("Estágio"), value: "Estágio" },
      { text: this.$t("Júnior"), value: "Júnior" },
      { text: this.$t("Pleno"), value: "Pleno" },
      { text: this.$t("Sênior"), value: "Sênior" },
      { text: this.$t("Consultor"), value: "Consultor" },
      { text: this.$t("C Level"), value: "C Level" },
      { text: this.$t("Outros"), value: "Outros" }
    ];
  }

  public async mounted() {
    if (!this.empresa)
       await this.fetchEmpresaInfo();

    const vid: number = parseInt(this.$route.params.id);
    if (vid && !isNaN(vid)) {
      const _: any = this;
      _.openLoading(this.$refs.containerLoader);
      await this.service.loadVaga(vid);
      this.value = this.service.entidade;
      try {
        this.enableHabilities = (this.value.vagaHabilidades?.length > 0 );
        this.enableCut =  (this.value.PerguntasCorte?.length > 0 );  
        this.enableTestes =  (this.value.questionaries?.length > 0 );  
        this.enableTeams = !!(this.value.equipesVaga?.length||0 > 0 );  
      }catch(e) {

      }
      _.closeLoading();
    } else {
      this.value = new VagaEntity();
    }


    this.handleVagaChange()
  }

  public handleVagaChange() {
    if (this.value?.cidade) {
      this.estadoId = this.value?.cidade.ufId;
      this.chainToCity();
    }
  }

  public searchByCEP() {
    if(this.value?.zipcode)
      this.cidadeSRV
        .searchByCEP(this.value?.zipcode)
        .then(dta => {
          if (this.value) {
            this.value.localidade = `${dta.logradouro}, ${dta.bairro} - ${dta.localidade} / ${dta.uf}`;
            this.estadoId = this.estadoSRV.estadoByName(dta.uf);
            this.chainToCity(dta.localidade);
          }
        });
  }

  public getDateDB(v: Date): string {
    const day = v.getUTCDate().toString();
    const month = (v.getUTCMonth() + 1).toString();
    const year = v.getUTCFullYear();
    return (
      year +
      "-" +
      (month.length == 1 ? "0" + month : month) +
      "-" +
      (day.length == 1 ? "0" + day : day)
    );
  }

  public saveData() {
    if (this.value) {
      const hasInterviewQuestions = this.value.perguntas.length > 0;

      if (!hasInterviewQuestions) {
        this.alertInterviewMustHaveAtLeastOneQuestion();
        return;
      }

      this.value.equipes = this.selectedTeams?.map((t: any) => { return t.id })

      if (this.dtFinaliza)
        this.value.dataFinaliza = this.getDateDB(this.dtFinaliza);

      this.value.simplificada = true;

      if(!this.enableCut) 
        this.value.PerguntasCorte = [];
      
      if(!this.enableHabilities)
        this.value.vagaHabilidades = [];

      if(this.value.blind == 0) {
        this.value.blockApprove = false;
      }

      this.service.entidade = this.value;
      this.service.entidade.limited = 0;
      this.service.entidade.status = 'ATIVO';
      this.service.entidade.competencias = ' - ';
      this.service.entidade.descricao = ' - ';

      this.promise = this.service
        .save()
        .then(data => {
          //seta o id da vaga se deu certo.
          
          this.$emit('input',data);
          this.$emit("saved", data);
        })
        .catch(err => {
          console.log(err);
          this.$emit("error");
        });
    } else {
      console.error('Não inicializado objecto da Vaga');
      this.value = new VagaEntity();
    }
  }

  public async updateLocalidade() {
    if (!this.value?.cidadeId) 
      return;

    let cid = await this.cidadeSRV.fetch(this.value.cidadeId);

    if (this.value.localidade!.length <= 5) {
      this.value.localidade = cid.completoNome;
    }
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(this.estadoId);
    if (value)
      this.value!.cidadeId = this.cidadeSRV.cidadeByName(value);
  }

  private alertInterviewMustHaveAtLeastOneQuestion() {
    this.$toast.addTitle(this.$t('No interview questions').toString());
    this.$toast.addMsg(this.$t('The interview must have at least one interview question'));
    this.$toast.variant = 'danger';
    this.$toast.open();
  }
}
</script>

