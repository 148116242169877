<template>
  <div :class="{ 'box-questions': true, 'm-1': noMargin }">
    <hr v-if="simple" /> 
    <!-- Perguntas -->
    <div class="row">
      <div class="col-md-4 box-questions__question-titles">
        <h2>{{$t('Interview questions')}}</h2>
      </div>
      <div class="col-md-8 d-flex justify-content-end align-items-center">
        <div class="" v-if="simple">      
          <b-button variant="outline-info"  class="px-5 py-2" @click.prevent="addQuestion">
            <i class="fas fa-plus-circle"></i>
            {{$t('Question')}}
          </b-button>
        </div>
      </div>
      <div class="col-4 ">
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-md-12 mb-2"
        v-for="(question, index) in currentPerguntas"
        v-bind:key="'pergunta_'+index"
      >
        <div class="row pergunta">
          <div class="col-md-12 mb-2">
            <div class="row">
              <div class="col-md-8">
                <h3>{{`${index + 1}° ${$t('Question')}`}}</h3>
              </div>

              <div class="col-md-4 d-flex justify-content-end">
                <b-icon
                  class="icon-trash cursor-pointer"
                  @click.prevent="delQuestion(index)"
                  font-scale="2"
                  variant="danger"
                  icon="x-circle-fill"
                ></b-icon>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <auto-complete-input
                  v-model="question.pergunta"
                  :data="perguntasArr"
                  :required="true"
                  :title="question.pergunta"
                  :class="redForRequired(question.pergunta)"
                  :value="question.pergunta"
                  :label="$t('Enter question')"
                  :placeholder="$t('Ex.:')+' '+$t('perguntas.1')"
                  variant="dark"
                  @submit="question.pergunta = $event"
                />
              </div>
              <div class="col-md-3">
                <select-input
                  :optionList="optionList"
                  v-model="question.idioma"
                  variant="dark"
                  :required="true"
                  :class="redForRequired(question.pergunta)"
                  :label="$t('Language')"
                  :placeholder="'Português (Brasil)'"
                />
              </div>
              <div class="col-md-3 box-questions__answer-time" 
                  v-if="additionalMinutage">
                <select-input
                  :optionList="optionListAdditionalMinutage"
                  v-model="question.tempo"
                  variant="dark"
                  :required="true"
                  :class="redForRequired(question.tempo)"
                  :label="$t('Time to respond')"
                />
            </div>
              <div class="col-md-3 box-questions__answer-time" v-else>
                <label>{{$t('Time to respond')}}</label>
                <b-form-radio-group
                  
                  v-model="question.tempo"
                  :name="'form'+index"
                  class="radio-group-2"
                >
                  <b-form-radio :required="false" :value="1">{{ $t('1 minute') }}</b-form-radio>
                  <b-form-radio :required="false" :value="2">{{ $t('2 minutes') }}</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2 d-md-flex justify-content-between" v-if="permitAI">
            <form class="flex-fill" @submit.prevent="addKeyWord(question,index)">
              <p class="mb-1 ml-1">{{$t("Type the tag and press 'enter' on the keyboard to insert it. You need to insert at least 5 tags")}}.
              <b-button id="iconquest" variant="x" pill size="sm" class="text-blue" > <i class="icon-ajuda" /> </b-button>
            <b-tooltip target="iconquest" placement="right" custom-class="tooltip-iconquest">
              <p>{{ $t('From the keywords entered below, we will rank the candidates for you.') }}</p>
            </b-tooltip></p>
              <div class="list-tags">
                <info-tag
                  class="tags-question"
                  v-for="(keyW, indexW) in question.tags"
                  v-bind:key="keyW+'_'+indexW+'_'+index"
                  :text="keyW.tag"
                  :size="'small'"
                  icon="fa fa-times"
                  :name="'kw'+indexW"
                  @click="delKeyWords(indexW,index)"
                />
              </div>
              <text-input
                variant="dark"
                v-model="question.currentTag"
                :placeholder="$t('Eg: Commitment, Compliance, CRM')"
              />
            </form>
            <div class="px-1" v-if="$i18n.locale == 'pt-BR'">
              <b-button
                variant="light"
                class="border-primary float-right"
                size="sm"
                pill
                @click="openSugestionModal(question)"
              >{{$t('See tag suggestions')}}</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 d-flex justify-content-center mt-2" v-if="!simple">
        <button
          class="btn btn-info rounded-pill text-uppercase"
          @click.prevent="addQuestion"
        >{{$t('Add new question')}}</button>
      </div>
    </div>
    <div class="row buttons-footer" v-if="!simple">
      <div class="col-12 col-md-6">
        <button
          class="btn btn-outline-secondary text-uppercase rounded-pill"
          @click.prevent="backData"
        >{{$t('Back')}}</button>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end">
        <button
          class="btn btn-info btn-salvar text-uppercase"
          @click.prevent="saveQuestions"
          v-promise-btn="{promise}"
        >
          <i class="icon-check"></i>
          {{$t('Save and Proceed')}}
        </button>
      </div>
    </div>
    <modal-sugestao-tag id="modalSugestoes" :vaga="value" @hide="hideModal" />
  </div>
</template>


<script lang="ts">
import Alert from '@/components/alerts/Alert.vue';
import ModalSugestaoTag from "@/components/modal/ModalSugestaoTag.vue";
import GestaoVaga from "@/core/Services/Empresa/GestaoVaga";
import TagTranscricao from "@/core/Services/Vaga/TagTranscricao";
import {
EmpresaEntity,
TagTranscricaoEntity,
VagaEntity,
VagaPerguntaEntity
} from "@/core/models/Entities";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from 'vuex-class';
const namespace: string = "profile";

@Component<FormVagaPerguntas>({
  components: {
    ModalSugestaoTag
  }
})
export default class FormVagaPerguntas extends Vue {
  public serviceVaga: GestaoVaga;
  public serviceTag: TagTranscricao;
  public currentQuestion: VagaPerguntaEntity | null = null;
  public alert: Alert = new Alert;
  public optionListAdditionalMinutage: any = null;
  public optionList: any[] = [
    {
      value: "pt-BR",
      text: "Português (Brasil)"
    },
    {
      value: "en",
      text: "English"
    },
    {
      value: "es",
      text: "Español"
    }
  ];
  public qtyMaxQuestion: number = 3;
  public perguntasArr: any[] = [];
  public promise: Promise<any> | null = null;

  @Prop({ default: () => { return new VagaEntity(); } })
  public value!: VagaEntity;

  @Prop({ default: false })
  public noMargin!: boolean;

  @Prop({ default: false })
  public simple!: boolean;

  @Action('fetchEmpresaInfo',{ namespace })
  public fetchEmpresaInfo!: any;

  @Getter('companyInfo',{ namespace })
  public empresa!: EmpresaEntity;

  constructor() {
    super();
    this.serviceVaga = new GestaoVaga();
    this.serviceTag = new TagTranscricao();
  }

  public getResultValue(r: any) {
    return r.text;
  }
  get currentPerguntas() {
    return this.value.perguntas;
  }
  
  get permitAI () {
    return (this.empresa && this.empresa!.configuracoes && this.empresa!.configuracoes['ENABLE_AI'] == true);
  }

  get additionalMinutage () {
    return (this.empresa?.configuracoes?.ENABLE_REC_INTERVIEW_ADDITIONAL_MINUTAGE == true);
  }

  public async mounted() {
    if (!this.empresa) {
      await this.fetchEmpresaInfo();
    }
    this.initPerguntas();
    let i: number = 1;
    for (i; i <= 177; i++) {
      this.perguntasArr.push(this.$t("perguntas." + i).toString());
      // console.log(this.$t("perguntas." + i),this.$i18n.locale)
    }
    this.optionListAdditionalMinutage = [
      { value: null, text: this.$t('Select') },
    ];
    i = 1;
    for (i; i <= 10; i++) {
      this.optionListAdditionalMinutage.push({ value: i, text: i+' '+this.$t('minutes') });
    }
  }

  public initPerguntas() {
    if (
      !this.value.perguntas ||
      !this.value!.perguntas!.length
    ) {
      const c: VagaPerguntaEntity = new VagaPerguntaEntity();
      c.idioma = this.$i18n.locale;
      this.value.perguntas = [c];
    }
  }

  public hideModal(tags?: string[]) {
    if (tags && tags.length > 0) {
      tags.forEach((tag: string) => {
        const idp: number = this.currentQuestion!.id;
        const idv: number = this.value.id || 0;

        this.currentQuestion!.tags.push(
          new TagTranscricaoEntity(tag, idp, idv)
        );
      });
    }
    this.currentQuestion = null;
    this.$bvModal.hide("modalSugestoes");
  }

  public openSugestionModal(question: VagaPerguntaEntity) {
    this.currentQuestion = question;
    this.$bvModal.show("modalSugestoes");
  }

  public async addQuestion() {
    let add = true;
    
    if ( (this.value.perguntas.length % 3) == 0) {
      add = false;
      this.alert.addMsg(this.$t("1 interview will be debited every 3 questions."));
      this.alert.addMsg(this.$t("Do you wish to continue?"));
      let props = {
        b1Title: this.$t("No").toString(),
        b2Title: this.$t("Yes").toString(),
        b2CallBack: () => { 
          add = true;
        },
        b1CallBack: () => {
          add = false;
        }
      }
      await this.alert.callBoxCheckWithTitleAndDoubleButtons(props);
    }


    if (add) {
      let newQuestion: VagaPerguntaEntity = new VagaPerguntaEntity();
      newQuestion.idioma = this.$i18n.locale;
      newQuestion.numeroPergunta = this.value.perguntas.length + 1;

      this.value.perguntas.push(newQuestion);
    }

    this.$emit('input',this.value);

  }

  public delQuestion(index: number) {
    const pergunta: VagaPerguntaEntity = this.value.perguntas[index];

    if (!pergunta)
      return;

    if (pergunta && pergunta.id && pergunta.id > 0) {
      this.$confirm.addMsg(
        this.$t("Are you sure you want to delete the question?", {
          p: pergunta.numeroPergunta
        })
      );
      this.$confirm.callBoxSuccess().then((flag: boolean) => {
        if (flag) {
          this.serviceVaga
            .delete({
              pid: pergunta.id,
              id: this.value.id
            })
            .then(data => {
              this.value.perguntas.splice(index, 1);
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          console.log("canceled");
        }
      });
    } else {
      this.value.perguntas.splice(index, 1);
    }
    this.$emit('input',this.value);
  }

  public backData() {
    this.$emit("PrevStep", true);
  }

  public saveQuestions() {
    const hasInterviewQuestions = this.value.perguntas.length > 0;

    if (!hasInterviewQuestions) {
      this.alertInterviewMustHaveAtLeastOneQuestion();
      return;
    }

    this.serviceVaga.entidade = this.value;
    this.promise = this.serviceVaga
      .save()
      .then((data: VagaEntity) => {
        if (data && data.perguntas) {
          this.value.perguntas = data.perguntas
          this.$emit('input', data);
          this.$emit("NextStep", true);
        }
      })
      .catch(err => {
        console.log(err);
        this.$emit("error");
      });
  }

  public addKeyWord(question: VagaPerguntaEntity, iQuestion: number) {
    const idp: number = question.id;
    const idv: number = this.value.id || 0;

    if (question.currentTag) {
      const ArrTags = question.currentTag.replace(/;/g, ",").split(",");

      ArrTags.forEach(element => {
        this.value.perguntas[iQuestion].tags.push(
          new TagTranscricaoEntity(element.trim(), idp, idv)
        );
      });
    }
    question.currentTag = null;
  }

  public delKeyWords(iWord: number, iQuestion: number) {
    this.serviceTag.entidade = this.value.perguntas[
      iQuestion
    ].tags[iWord];
    if (this.serviceTag.entidade && this.serviceTag.entidade.id > 0) {
      this.serviceTag
        .delete({ id: this.serviceTag.entidade.id })
        .then((data: any) => {
          this.value.perguntas[iQuestion].tags.splice(iWord, 1);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      this.value.perguntas[iQuestion].tags.splice(iWord, 1);
    }
  }

  private alertInterviewMustHaveAtLeastOneQuestion() {
    this.$toast.addTitle(this.$t('No interview questions').toString());
    this.$toast.addMsg(this.$t('The interview must have at least one interview question'));
    this.$toast.variant = 'danger';
    this.$toast.open();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_media-queries";
@import "@/assets/scss/main";

.principal-container {
   .box-questions {
    margin-top: 7em;
    margin-right: 2em;
    margin-left: 2em;
    &__question-titles {
      p {
        font-size: 1rem;
      }
    }
    &__answer-time {
      font-size: 0.9rem;
    }
    h2 {
      font-size: 2em;
      margin: 0.5em 0;
    }

    .tags-question {
      cursor: pointer;
    }

    .pergunta {
      // margin: 1em;
      padding: 1em 0.5em;
      border: 2px solid #ececec;
      border-radius: 8px;
      h3 {
        font-size: 1.4rem;
        color: $font-title-gray;
      }
      .radio-group-2 {
        margin-top: 10px;
      }
      .sobrepor {
        display: none;
        background-color: white;
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9999999999;
      }
      .btn-criar-pergunta {
        display: none;
        z-index: 99999;
      }
      &.not-active {
        background-color: rgb(243, 243, 243);
        border-radius: 0.5em;
        .btn-remover-pergunta {
          display: none;
        }
        .btn-criar-pergunta {
          display: block;
          z-index: 99999999;
          background-color: $blue-light;
          border-color: $blue-light;
        }
        :after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(243, 243, 243, 0.3);
          z-index: 999;
        }
      }
      .list-tags {  
        max-height: 125px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .buttons-footer {
      margin: 1em 0;
    }
  }
}
</style>
