<template>
  <transition name="el-zoom-in-top">
    <div class="box-container d-flex flex-column">
      <div class="col-md-4 py-3 py-md-0 mb-5">
        <h1 class="text-center text-md-left">Match</h1>
      </div>
      <!-- Content Header (Page header) -->
      <!-- <section class="content-header d-none d-md-block">
          <el-button class="float-left" round @click="$router.go(-1)"> <i class="fa fa-chevron-left"></i> {{ $t("Voltar") }} </el-button>
          <h1 class="page-title float-left"><i class="fa fa-tasks"></i> {{ $t("Questionário de Matches") }} </h1>
      </section> -->
      <hr class="d-block d-md-none my-5 " />
      <!-- Main content -->
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="row" v-show="fase==0">
              <div class="col-md-12">
                <b-button variant="success" class="btn btn-primary mt-2 float-right btn-lg"
                @click="fase++">{{ $t("Let`s Go") }}
                </b-button>
                <assessment-description />
              </div>
            </div>
            <div class="row" v-show="fase==1">
              <div class="col-md-12">
                <button class="btn btn-primary mt-2 float-right btn-lg"
                @click="fase++">{{ $t("Begin") }}
                </button>
                <assessment-instruction />
              </div>
            </div>
            <div class="row" v-show="fase==2">
              <div class="col-md-12">
                <step-indicator :current="active" :total="6" currentColor="#4bb400" ></step-indicator>
              </div>
              <div class="col-md-12">
                <b-alert v-model="showDismissibleAlert" variant="warning" dismissible>
                  {{ serverMessage }}
                </b-alert>
              </div>
              <div 
                class="col-md-12 mt-5 step-content"
                :id="'question'+j"
                v-for="(question, j) in questionary.questions"
                v-bind:key="question.id"
              >
                <table class="table table-assessment">
                  <tr>
                    <th>
                      {{ (j+1)+". "+ $t(question.description)}}
                    </th>
                  </tr>
                  <tr v-for="(answer,i) in question.answers" v-bind:key="answer.id">  
                    <td class="pl-3">
                      <div>{{ (j+1)+". "+(i+1)+". "+ $t(answer.description) }}</div>
                      <div class="col-sm-12 col-md-8 offset-md-2 py-1">
                        <circle-rate
                          :rating="answer.rate"
                          @rateSelected="handleRateChange(answer, $event)"
                        ></circle-rate>
                      </div>
                    </td>
                  </tr>
                  <tfoot>
                    <tr>
                      <th class="total-distributed"> {{ $t("Total Distributed")+ ": " + sumByQuestion.get(question.id) }}  </th>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="col-md-12">
                <b-button variant="secondary" 
                  class="mt-2 float-left btn-marine footer-button"
                  @click="previous">{{ $t("Previous") }}
                </b-button>
                <b-button variant="primary"
                  v-if="active+1!==questionary.questions.length"
                  class="mt-2 float-right footer-button"
                  @click="next">{{ $t("Next") }}
                </b-button>
                <b-button variant="primary"
                  v-if="active+1===questionary.questions.length"
                  class="mt-2 float-right footer-button"
                  @click="save">{{ $t("Save") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<script lang="ts">
// tslint:disable
import CircleRate from "@/components/rate/CircleRate.vue";
import MatchService from "@/core/Services/Assessment/MatchEmpresa";
import QuestionaryService from "@/core/Services/Assessment/Questionary";
import {
AnswerEntity,
QCAnswerData,
QuestionaryEmpresaEntity,
QuestionaryEntity
} from "@/core/models/Entities";
import Vue from "vue";
import Component from 'vue-class-component';
import StepIndicator from 'vue-step-indicator';
import AssessmentDescription from "./AssessmentDescription.vue";
import AssessmentInstruction from "./AssessmentInstruction.vue";

@Component<EmpresaAssessment>({
    components: {
      'circle-rate': CircleRate,
      AssessmentDescription,
      AssessmentInstruction,
      StepIndicator
    }
})
export default class EmpresaAssessment extends Vue {
    public qService: QuestionaryService;
    public matchService: MatchService;
    public questionary: QuestionaryEntity;
    public qEmpresa: QuestionaryEmpresaEntity;
    public sumByQuestion!: Map<any,number>;
    public active: number = 0;
    public rating: number[] = [ 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ];
    public textArray: string[] = [];
    public fase: number = 0;
    public loaded: boolean = true;
  public showDismissibleAlert: boolean = false;
  public serverMessage: string = "";

    constructor() {
        super();
        this.qService = new QuestionaryService();
        this.matchService = new MatchService();
        this.questionary = new QuestionaryEntity();
        this.qEmpresa = new QuestionaryEmpresaEntity();
        this.sumByQuestion = new Map<any,number>();
    }

  public handleRateChange(answer: AnswerEntity, newRate: number) {
    // Atualizar rate com novo valor
    answer.rate = newRate;

    const qAnswer = this.qEmpresa.answers[answer.id!];
    qAnswer.rate = answer.rate;

    this.resetSumDist();

    this.$nextTick(() => {
      const currentTotalDist: number = this.sumByQuestion.get(qAnswer.questionId)!;

      if (currentTotalDist > 10) {
        answer.rate = 0;
        qAnswer.rate = 0;
        this.resetSumDist();

        this.showDismissibleAlert = true;
        this.serverMessage = this.$t("Every question, distribute up to 10 points between the statements below. Be you and signal preferences you give more value.").toString();
        setTimeout(() => {
          this.showDismissibleAlert = false;
        }, 3000);
      }
    });
  }

 public resetSumDist() {
    this.questionary.questions!.forEach((question: any) => {
      let currentRateDist: number = 0;

      this.sumByQuestion.set(question.id, currentRateDist);

      if (question.answers) {
        question.answers.forEach((answer: AnswerEntity) => {
          currentRateDist += answer.rate || 0;
          this.sumByQuestion.set(question.id, currentRateDist);
        });
      }
    });
  }

  public created() {
    this.loaded = true;
    this.matchService
      .fetch(1)
      .then(data => {
        if (data.questionaryAnswerEmpresas) {
          data.questionaryAnswerEmpresas.forEach((qaEmpresa: any) => {
            if (this.qEmpresa.answers) {
              this.qEmpresa.answers[qaEmpresa.answerId] = new QCAnswerData(
                qaEmpresa.answerId,
                qaEmpresa.rate
              );
            }
          });
        }
        this.qService
          .fetch(1)
          .then(data => {
            if (data.questions) {
              data.questions.forEach((question: any) => {
                let currentRateDist: number =0;
                this.sumByQuestion.set(question.id, currentRateDist);
                if (question.answers) {
                  question.answers.forEach((answer: AnswerEntity) => {
                    if (this.qEmpresa.answers && answer && answer.id) {
                      if (!this.qEmpresa.answers[answer.id]) {
                        this.qEmpresa.answers[answer.id] = new QCAnswerData(
                          answer.id,
                          0
                        );
                      } else {
                        answer.rate = this.qEmpresa.answers[answer.id].rate;
                        currentRateDist+=answer.rate!
                        this.sumByQuestion.set(question.id, currentRateDist);
                      }
                      this.qEmpresa.answers[answer.id].questionId =
                        question.id;
                    }
                  });
                }
              });
              //console.log(this.qEmpresa.answers);
            }
            this.questionary = data;
            this.qEmpresa.questionaryId = this.questionary.id;
            this.active = 0;
            setTimeout(this.showHideContentStep, 500);
            //  this.textArray = [''+this.$t('1'),''+this.$t('2'),''+this.$t('3')
            //                 ,''+this.$t('4'),''+this.$t('5'),''+this.$t('6')
            //                 ,''+this.$t('7'),''+this.$t('8'),''+this.$t('9')
            //                 ,''+this.$t('10')]
          })
          .catch((err: any) => {
            console.log(err);
          });
        this.loaded = false;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public previous() {
    if (this.active-- <= 0) this.active = 0;

    this.showHideContentStep();
  }
  public next() {
    if (this.active++ >= this.questionary.questions!.length - 1) {
      this.active = 0;
    }
    this.showHideContentStep();
  }

  public save() {
    this.matchService.create(this.qEmpresa)
    .then((data: any) => {
      this.successAlert()
      this.$router.push('/')
    })
    .catch((err: any) => {
      console.log(err.detail)
    });
  }

  public showHideContentStep() {
    const stepContentId = 'question'+this.active
    const divsContent = document.querySelectorAll('.step-content')
    divsContent.forEach((el) => {
      el.classList.remove("active");
    });
    document.getElementById(stepContentId)!.classList.add("active");
  }
  
  public successAlert() {
    this.$alert.addMsg(this.$t('Your Cultural Fit was saved successfully'));
    this.$alert.callBoxCheck(this.$t('Ok').toString())
  }
}
</script>

<style lang="scss" scoped>
  .table-assessment .table th, .table td {
    border-top: 1px solid #dee2e6 ;
  }
      
  .content {
    padding-bottom: 30px;
  }

  // .pic-wrapper {
  //   height: 200px;
  // }

  .step-content {
    margin-top: 30px;
      display: none;
      width: 100%;
      &.active {
        display: table;
      }
      &-footer {
        margin-top: 30px;
      }
  }

  .total-distributed {
    font-size: 18px;
  }

  .footer-button {
    width: 120px;
  }
</style>

<style src="vue-step-indicator/dist/vue-step-indicator.css"></style>
