<template>
  <modal :title="$t('Link generated')" :show="show" :id="id" :size="'md'">
    <!-- <p>{{$t('As informações compartilhadas são confidenciais e estará sujeito a multa conforme contrato de serviço e termos de privacidade, caso seja compartilhado com terceiros. Este link ficará disponível para visualização por 15 dias.')}}</p> -->
    <p>{{$t('The link has been generated and will be available for 30 days')}}</p>
    
    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="md"
          class="float-right"
          v-clipboard:copy="gerarLink"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          {{$t("Copy Link")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import { BitlyClient } from "bitly";
import PublicCandidaturaService from "@/core/Services/Empresa/PublicCandidatura";
import VagaShareService from "@/core/Services/Basic/VagaShare";
import Modal from '@/components/modal/Modal.vue'

@Component({
  components: {
    Modal
  }
})
export default class ModalCopyLink extends Vue {
  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
  public bitly = new BitlyClient('d7bda8e44e904a4f2a3bbe45282cb8b019b5557d', {});
	public pubCandidaturaService!: PublicCandidaturaService
  public vagaShareService = new VagaShareService();
  public currentPromise: any|null = null;
  public url: string = ''

  constructor() {
    super()
    this.pubCandidaturaService = new PublicCandidaturaService()
    // this.candidatura = new CandidaturaEntity()
  }

  public async updateUrl() {
    this.url = '';
    if (this.candidatura && this.candidatura.id) {
      await this.pubCandidaturaService.update({id: this.candidatura.id, linkOnly: true}).then( async (data: any) => {
        // let LinkEnc = await this.bitly.bitlyRequest('shorten',{'domain':'jobecam.in','longUrl':data.managerLink});
        const btPromise: Promise<any> = this.bitly.bitlyRequest('shorten',{
          'domain':'jobecam.in',
          "group_guid": "Bj2qhmHcCgV",
          "long_url": data.managerLink
        });
        btPromise.then((LinkEnc: any) => {
          if (LinkEnc && LinkEnc.link) {
            this.url = LinkEnc.link;
          } else {
            this.url = data.managerLink;
          }
        }).catch((err: any) => {
          this.url = data.managerLink;
        })        
      }).catch(() => {
        this.cancelMessage()
      });
    }
  }
  
  public mounted() {
    //this.updateUrl();
  }
  
  get gerarLink() {
    return this.url;
  }

  public onCopy(object: any) {
    this.successAlert(this.$t('Link copied successfully!').toString() + ' ' +object.text)
    this.$bvModal.hide('modalCopyLink')
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }

  public cancelMessage() {
    this.$alert.title = this.$t('Oops').toString()
    this.$alert.addMsg(this.$t('Please try again later'))
    this.$alert.callBoxWarn()
  }
}
</script>

<style>

</style>