<template>
  <button
    @click="clickEvent"
    :style="{ '--button-border': border, '--button-color': color, '--button-back-color-hover': backHover, '--font-size-em': fontSize, '--button-back-color': back }"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ButtonIconRounded extends Vue {
  @Prop({ default: "black" }) public color!: string;
  @Prop({ default: "black" }) public border!: string;
  @Prop({ default: "transparent" }) public back!: string;
  @Prop({ default: "white" }) public backHover!: string;
  @Prop({ default: "white" }) public fontSize!: string;
  public clickEvent(event: any) {
    this.$emit('click', event)
  }
}
</script>

<style lang="scss" scoped>
button {
  background: var(--button-back-color);
  border-radius: 50%;
  width: 2em;
  height: 2em;
  padding: 0;
  font-size: var(--font-size-em);
  line-height: var(--font-size-em);
  border: 1px solid var(--button-border);
  color: var(--button-color);
  &:hover {
    background-color: var(--button-back-color-hover);
  }
  span {
    &:hover {
      color: white !important;
    }
  }
}
</style>
