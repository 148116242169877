<template>
  <b-modal :id="mid" size="lg" dialog-class="image-modal" hide-footer>
    <div class="modal-body">
      <slot /> 
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Action, Getter, State } from 'vuex-class'; 
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ImageModal extends Vue {
  @Prop({default: ''})
  public imgSrc!:string;

  @Prop({default: 'image-modal'})
  public mid!:string;
}
</script>

<style lang="scss">

.image-modal {
   font-family: nerislight;
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-content {
    border: 0px;
    background: transparent;
  }
  .modal-body {
    display: flex;
    padding: 0;
  }
  .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    border: none;
  }
  .modal-dialog {
    display: flex;
    margin: 0;
    max-width: 100%;
    height: calc(100vh - 5em);
    width: calc(100% - 15em);
    header,
    footer {
      display: none;
    }
  }
}

</style>