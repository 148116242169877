<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="lg"
    title="Large Modal"
    ref="modalSugestaoTag"
    body-class="p-0"
  >

    <div class="modal-content m-content">
      <button class="btn btn-danger" @click="$bvModal.hide(id)">
        <i class="icon-cancelar"></i>
      </button>
      
      <b-row  class="sugestion-list">
        <b-col md="12" v-for="sugestion in service.sugestionList" v-bind:key="sugestion.id">
          <h4> {{sugestion.descricao}} </h4>
            <b-form-group>
              <b-form-checkbox-group
                size="sm"
                v-model="selected"
                :options="sugestion.tags"
              ></b-form-checkbox-group>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12" class="d-flex justify-content-end">
          <b-button variant="blue" pill  class=" border-primary mx-1" @click="done"> {{$t('Add Tags')}} </b-button>
          <b-button variant="light" pill class="border-danger mx-1" @click="hide"> {{$t('Cancel')}} </b-button>
        </b-col>
      </b-row>
    </div>  
  </b-modal>
</template>

<script lang="ts">
import Modal from '@/components/modal/Modal.vue';
import SugestaoTag from "@/core/Services/Vaga/SugestaoTag";
import { VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ModalSugestaoTag>({
  components: {
    Modal
  }
})
export default class ModalSugestaoTag extends Vue {
  public service: SugestaoTag;
  public selected: string[] = [];

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  public hide() {
    this.$emit('hide')
    this.selected = [];
  }

  public done() {
    this.$emit('hide', this.selected)
    this.selected = [];
  }

  constructor() {
    super();
    this.service = new SugestaoTag()
  }

  public mounted() {
    // TODO add position name to search
    this.selected = [];
    this.service.fetchSugestions('')
  }

}

</script>


<style lang="scss" scoped>
@import "@/assets/scss/main";

.m-content {
  padding: 3em;
  p {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.9em;
    padding-bottom: 1em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }
  button.secondary {
    // color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px
  }

  button.primary {
    color: #fff;
    font-size: 0.9em;
    height: 2.5em;
    padding: 4px 15px
  }
  .sugestion-list {
    max-height: 300px;
    overflow-y:scroll;
  }
}
</style>

<style lang="scss">
.sugestion-list {
  .custom-control {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    padding: 0 0 0 2em;
    margin: 5px;
    min-width: 160px;
  }
}
</style>