import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import ShareSession from './plugins/ShareSession';
import { i18n } from './plugins/i18n';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import VuePromiseBtn from 'vue-promise-btn';
import ConfirmPlugin from './plugins/ConfirmPlugin';
import AlertPlugin from './plugins/AlertPlugin';
import ToastPlugin from './plugins/ToastPlugin';
import MomentPlugin from './plugins/MomentPlugin';
import Donut from 'vue-css-donut-chart';
import VueTelInput from 'vue-tel-input';
import VueScrollTo from 'vue-scrollto';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Loading from 'vue-loading-overlay';

import '@/mixins/global';
import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css';
import 'vue-css-donut-chart/dist/vcdonut.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-promise-btn/dist/vue-promise-btn.css';
import 'video.js/dist/video-js.css';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import 'vue-multiselect/dist/vue-multiselect.min.css';

window['jbprod'] = process.env.NODE_ENV == 'production';
window['jbdev'] = process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'local';

if (process.env.NODE_ENV == 'production') {
  // Sentry.init({
  //   dsn: 'https://5e2138af5e4a4b1fb84982dce2966c2d@o300135.ingest.sentry.io/5413897',
  //   integrations: [new Integrations.Vue({Vue, attachProps: true})],
  // });
}

const c = new ShareSession();



Vue.use(Donut);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Loading);
// Custom Plugins
Vue.use(ConfirmPlugin);
Vue.use(AlertPlugin);
Vue.use(ToastPlugin);
Vue.use(MomentPlugin);
//
Vue.config.productionTip = false;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(VuePromiseBtn);
Vue.use(VueTelInput);

Vue.use(VueScrollTo, {
  container: '#page-content',
  duration: 0,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

// import Tag from '@/components/tags/Tag.vue';
// import CardSeparator from '@/components/tags/CardSeparator.vue';

import SelectInput from '@/components/form/SelectInput.vue';
import SearchInput from '@/components/search/Search.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import AutoCompleteInput from '@/components/form/AutoCompleteInput.vue';
import TextareaInput from '@/components/form/TextareaInput.vue';
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import Tag from '@/components/tags/Tag.vue';
import TextLabel from '@/components/form/TextLabel.vue';
import TextLabelValue from '@/components/form/TextLabelValue.vue';
import TextLabelHtml from '@/components/form/TextLabelHtml.vue';
import NivelBar from '@/components/NivelBar.vue';
import ButtonIconRounded from '@/components/ButtonIconRounded.vue';
import CreditCard from '@/components/CreditCard.vue';
import Icon from '@/components/icon/Icon.vue';
import TextArea from '@/components/form/TextArea.vue';
import Raters from 'vue-rate-it';
import VueApexCharts from 'vue-apexcharts';

Vue.component('fa-rating', Raters.FaRating);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('select-input', SelectInput);
Vue.component('search-input', SearchInput);
Vue.component('text-input', TextInput);
Vue.component('auto-complete-input', AutoCompleteInput);
Vue.component('text-area', TextArea);
Vue.component('textarea-input', TextareaInput);
Vue.component('checkbox-input', CheckboxInput);
Vue.component('text-label', TextLabel);
Vue.component('text-label-value', TextLabelValue);
Vue.component('text-label-html', TextLabelHtml);
Vue.component('info-tag', Tag);
Vue.component('nivel-bar', NivelBar);
Vue.component('icon', Icon);
Vue.component('button-icon-rounded', ButtonIconRounded);
Vue.component('star-rating2', Raters.StarRating);
Vue.component('credit-card', CreditCard);
Vue.component('date-input', DateInput);
Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
