<template>
  <div class="box-container d-flex flex-column">
    <div class="row">
      <div class="col-12 col-md-12">
        <h1 class="text-md-left text-center">{{$t('Payment and Invoice')}}</h1>
      </div>
    </div>
    <div class="row">
      <b-col md="6">
        <cartao-atual />
      </b-col>
      <b-col md="6">
        <saldo-atual />
      </b-col>
    </div>
    <historico-faturas />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import SaldoAtual from '@/views/plano/partials/SaldoAtual.vue';
import CartaoAtual from './partials/CartaoAtual.vue';
import HistoricoFaturas from './partials/HistoricoFaturas.vue';

@Component<Pagamentos>({
  components: {
    CartaoAtual,
    SaldoAtual,
    HistoricoFaturas
  }
})
export default class Pagamentos extends Vue {
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.box-saldo-jobcoins {
  background-color: $card-back-color;
  padding: 1em;
  button {
    font-size: 0.7em;
  }
}
</style>
<style lang="scss">
.box-historico-pagamento {
  thead,
  tbody {
    font-size: 0.9rem;
  }

  h5 {
    font-size: 1.5rem;
  }
}
</style>