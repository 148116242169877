<template>
  <div :class="{ 'row my-1 py-2 answer-content': true,'border-bottom':evaluation }" @keyup.esc="isEditable = false">
    <b-col md="1" class="d-flex justify-content-between flex-wrap align-items-center" v-if="!evaluation">
      <span>{{answerAlphaLetter[aIndex]}})</span>
      <input :name="'radio_q_'+qIndex" type="radio" v-if="questionTypeId == 2"  />
      <input :name="'checkbox_q_'+qIndex"  type="checkbox" v-if="questionTypeId == 1" />
      <b-form-checkbox v-model="value.correct" switch :value="true" :unchecked-value="false" :disabled="evaluation" :title="$t('Correct')" v-b-tooltip.hover  />
    </b-col>
    <b-col md="2" class="text-center border-right" v-if="evaluation && questionTypeId < 3">
        <i class="fas fa-minus-circle text-secondary" v-if="!isAnswered && value.correct" />
        <i class="fa fa-check-circle text-success" v-else-if="isAnswered && value.correct" />
        <i class="fa fa-times-circle text-danger" v-else-if="isAnswered && !value.correct" />
        <i v-else> - </i>
    </b-col>
    <b-col md="9" v-if="isEditable && !evaluation">
      <form @submit="doSubmit">
        <b-row>
          <b-col md="8">
              <text-input variant="dark" v-model="value.description" />
          </b-col>
          <b-col md="4" v-if="value.id>0">
            <label class="hidden-upload" :for="'hidden-upload-a'+aIndex">
              <b-form-file
                :id="'hidden-upload-a'+aIndex"
                accept="image/*"
                :placeholder="$t('Add Image')"
                drop-placeholder="Drop image here"
                @change="handleImageChange"
              ></b-form-file>
             <i class="fa fa-plus" /> &nbsp;  {{ $t('Add Image') }}
            </label>
          </b-col>
        </b-row>
      </form>
    </b-col>
    <b-col md="9" class="answer-description d-flex justify-content-start flex-wrap align-items-center" v-if="((!isEditable || evaluation) && questionTypeId < 3)" @click="isEditable = true">
      <div >{{ value.description }} </div>
    </b-col>
    <b-col md="1"  class="d-flex justify-content-center flex-wrap align-items-center" v-if="!evaluation && questionTypeId < 3">
      <b-button @click="removeAnswer" variant="outline-danger" pill size="sm">
        <i class="fa fa-times " /> 
      </b-button> 
    </b-col>
    <b-col md="12" v-if="questionTypeId == 3 && evaluation"  class="text-eval-read">
      <div v-html="answeredDescription"> </div>
    </b-col>
      <b-col md="12" v-if="value.images && value.images.length > 0"> 
        <b-container fluid class="p-2">
           <b-row class="justify-content-center">
              <b-col md="2" v-for="(img,index) in value.images" :key="img.id" class="text-center">
              <b-button variant="danger" block size="sm" @click="removeImage(img.id)"  v-if="isEditable && !evaluation">
                <i class="fa fa-times"></i> {{$t('Delete')}}
              </b-button>
              <b-img thumbnail fluid :src="img.url" :alt="'Image '+ index" @click="openImageModal(img.url)"></b-img>
              <p class="my-1"> <i>{{ $t('Figure')}} {{answerAlphaLetter[aIndex]}}.{{index+1}} </i> </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    <!-- REMOVENDO A AVALIAÇÂO descritiva POIS NÂO DEVE SER CONTADA NO RANKING -->
    <!-- <b-col md="12" class="mt-3 border-info border p-2 " v-if="questionTypeId == 3 && evaluation && haveAnswer && haveAnswer.rate == null" >
      <b-row >
        <b-col md="4"> 
          <text-input
            variant="light"
            type="number"
            :required="false"
            class="mb-1"
            :label="$t('Pontos')"
            :placeholder="$t('1')"
            v-model="pointsEvaluation"
            :maxNumber="points"
          />
        </b-col>
        <b-col md="8" class="d-flex flex-column"> 
          <b-button variant="success" block size="sm" @click="setCorrectForAnswer" :disabled="pointsEvaluation > points" v-promise-btn="{promiseCorrect}"> {{$t('Correta')}} </b-button>
      
          <b-button variant="warning" block size="sm" @click="setIncorrectForAnswer" v-promise-btn="{promiseInCorrect}"> {{$t('Incorreta')}} </b-button>
        </b-col>
      </b-row>
    </b-col> -->
    <b-col md="12" class="mt-2"  v-if="questionTypeId == 3 && evaluation && haveAnswer && haveAnswer.rate != null">
      <b-alert variant="warning" show>
        {{$t('Scored with')}}:  <strong> {{haveAnswer.rate}} pts </strong>
      </b-alert>
    </b-col>
  </div>
</template>

<script lang="ts">
// List Company evaluations
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AnswerEntity } from '@/core/models/Entities';
import CompanyExamEvaluation from '@/core/Services/Exams/CompanyExamEvaluation';
import ExamImage from '@/core/Services/Exams/ExamImage';


@Component
export default class FormAsnwer extends Vue {
  public service!: CompanyExamEvaluation;
  public promiseCorrect: Promise<any>|null = null;
  public promiseInCorrect: Promise<any>|null = null;
  public answerAlphaLetter: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');
  
  @Prop({ default: () => {return new AnswerEntity()}})
  public value!: AnswerEntity;

  @Prop({ default: 0})
  public questionTypeId!: number;

  @Prop({ default: 0 })
  public qIndex!: number;

  @Prop({ default: 0})
  public aIndex!: number;

  @Prop({default: false})
  public evaluation!: boolean

  @Prop({default: {}})
  public answers!: any;
  
  @Prop({default: 0})
  public points!: number

  public isEditable: boolean = false;
  public descricao: string  = '';
  public currentImgURL: string  = '';
  public pointsEvaluation: number|null = null;
  public imageService: ExamImage|null = null;
  constructor() {
    super();
  }

  get isAnswered() {
    return this.answers[this.value.id] != undefined;
  }
  
  get haveAnswer() {
    return this.answers[this.value.id];
  }

  get answeredDescription() {
    return !this.isAnswered ? '' : this.haveAnswer.description;
  }

  public mounted() {
    if (!this.value.description) {
      this.value.description = this.$t('Option').toString() + ' ' + (this.aIndex+1);
    } 
    this.descricao = this.value.description;
    this.pointsEvaluation = this.points;
  }

  public openImageModal(url: string) {
    this.$emit('openImageModal',url)
  }
  
  public handleImageChange(event: any) {
    if (this.value.id > 0 ) {
      this.imageService = new ExamImage();
      const file: any = event.target.files[0];
      if (file) {
        this.imageService.saveAnswer(this.value.id+"", file).then((d: any) => {
          this.$alert.title = " ";
          this.$alert.addMsg(this.$t("Saved successfully").toString());
          this.$alert.callBoxCheckWithTitle(this.$t("Ok").toString());
          if (!this.value.images) 
            this.value.images = [];
          this.value.images?.push(d);
        })
        .catch((d: any) => {
        })
        event.target.value = '';
      }
    } else {
      this.$toast.variant = 'danger';
      this.$toast.addMsg(this.$t('You must save this test to perform this action'))
      this.$toast.addTitle(this.$t('Oops').toString());
      this.$toast.open();
    }
  }
  public removeImage(id: number) {
    if (confirm(this.$t('Are you sure you want to delete this image?').toString())) {
      this.imageService = new ExamImage();
      if (id > 0) {
        this.imageService.delete({id, answer: '1'}).then((d: any) => {
          this.$toast.variant = 'success';
          this.$toast.addMsg(this.$t('Deleted Successfully'))
          this.$toast.addTitle(this.$t('Attention').toString());
          this.$toast.open();
          if (this.value.images) {
            const index: number = this.value.images.findIndex((a: any) => {
              return a.id == id;
            });
            if (index> -1)
              this.value.images?.splice(index,1);
          }
        })
        .catch((d: any) => {
        })
      }
    }
  }

  public doSubmit(event: any) {
    // this.value.description = this.descricao;
    this.isEditable = false;
    this.update()
    event.preventDefault();
    return false;
  }
  
  public update() {
    this.$emit('input', this.value)
  }

  public setCorrectForAnswer() {
    if (this.isAnswered) {
      this.service = new CompanyExamEvaluation();
      console.log(this.haveAnswer);
      this.promiseCorrect = this.service.update({id: this.haveAnswer.id, rate: this.pointsEvaluation })
      this.promiseCorrect.then((data: any) => {
        if (data && data.status) {
          this.$emit('pointGiven', this.value.id,this.haveAnswer.id, this.pointsEvaluation, data.status)
        }
        console.log(data)
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  public setIncorrectForAnswer() {
    if (this.isAnswered) {
      this.service = new CompanyExamEvaluation();
      console.log(this.haveAnswer);
      this.promiseInCorrect = this.service.update({id: this.haveAnswer.id, rate: 0 })
      this.promiseInCorrect.then((data: any) => {
        if (data && data.status) {
          this.$emit('pointGiven', this.value.id, this.haveAnswer.id, 0, data.status)
        }
        console.log(data)
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  public removeAnswer() {
    this.$emit('removeAnswer', this.aIndex);
  }

}
</script>

<style lang="scss" scoped>
.text-eval-read {
  max-height: 400px;
  overflow-y: scroll;
}
</style>