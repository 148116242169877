<template>
  <div class="col-12 d-flex justify-content-center">
    <div v-for="(s, index) in actions" :key="index" class="evaluation-btn">
      <button-icon-rounded
        color="gray"
        :border="s.color"
        :backHover="s.color"
        fontSize="1.2rem"
        class="mr-2"
        v-b-tooltip.hover
        :title="s.title"
        @click="s.btnAction"
      >
        <span :class="s.btnIcon"></span>
      </button-icon-rounded>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CandidaturaEntity, VagaEntity, CandidatoEntity, VagaVideoRespostaEntity, VagaPerguntaEntity } from "@/core/models/Entities";

@Component
export default class CandidatoEvaluationButtons extends Vue {
  @Prop({default: null})
  public candidaturaId!: number;
  
  @Prop({default: null})
  public candidaturaStatus!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;

  public actions: {
        title: string,
        btnIcon: string,
        color: string,
        btnAction: any
      }[]|null = null;
  public candidato!: CandidatoEntity
  public videosResposta!: Array<VagaVideoRespostaEntity>
  public vagaPerguntas!: Array<VagaPerguntaEntity>

  constructor() {
    super()
  }
  
  public showModalNewChance() {
    //TODO: add confirmação
    this.$emit('modalNewChance')
    // this.$bvModal.show('modalNewChance')
  }

  public async statusAprovado() {
    //TODO: add confirmação
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value)
      this.$emit('statusAprovado', this.candidaturaId)
  }

  public async statusReprovado() {
    //TODO: add confirmação
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value)
      this.$emit('statusReprovado', this.candidaturaId)
  }

  public async statusAnalise() {
    //TODO: add confirmação
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value)
      this.$emit('statusAnalise', this.candidaturaId)
  }

  public async darOportunidade() {
    //TODO: add confirmação
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value)
      this.$emit('repescagemRevisaoStatus', 'ATIVO', this.candidaturaId)
  }

  public async reprovar() {
    //TODO: add confirmação
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you Sure?').toString())
    if(value)
      this.$emit('repescagemRevisaoStatus', 'REPROVADO', this.candidaturaId)
  }

  @Watch("candidaturaId")
  public loadOptions() {
    console.log('currentChanged')
    this.actions = [];
    if (this.candidaturaStatus=="EMANALISE") {
      if(!this.vaga.blockApprove) {
        this.actions.push({
          title: this.$t('Approve').toString(),
          btnIcon: 'fas fa-check',
          color: 'rgb(151, 201, 130)',
          btnAction: this.statusAprovado
        });
        this.actions.push({
          title: this.$t('Disapprove').toString(),
          btnIcon: 'fas fa-times',
          color: '#dc3545',
          btnAction: this.statusReprovado
        });
      }
    } else if (this.candidaturaStatus=="APROVADO" && this.vaga && this.vaga.blind !== 1) {
      this.actions.push({
        title: this.$t('Disapprove').toString(),
        btnIcon: 'fas fa-times',
        color: '#dc3545',
        btnAction: this.statusReprovado
      });
      this.actions.push(
      {
        title: this.$t('Analyze').toString(),
        btnIcon: 'icon icon-analise',
        color: 'rgb(249, 163, 125)',
        btnAction: this.statusAnalise
      });
    } else if (this.candidaturaStatus=="REPROVADO" && this.vaga && this.vaga.blind !== 1) {
      this.actions.push({
        title: this.$t('Approve').toString(),
        btnIcon: 'fas fa-check',
        color: 'rgb(151, 201, 130)',
        btnAction: this.statusAprovado
      });
      this.actions.push(
      {
        title: this.$t('Analyze').toString(),
        btnIcon: 'icon icon-analise',
        color: 'rgb(249, 163, 125)',
        btnAction: this.statusAnalise
      });
    } else if ((this.candidaturaStatus=="EMREPESCAGEM" || this.candidaturaStatus=="REVISAOCURRICULAR") && this.vaga) {
      this.actions.push({
        title: this.$t('Give the opportunity').toString(),
        btnIcon: 'fas fa-child',
        color: '#007bff',
        btnAction: this.showModalNewChance
      });
      this.actions.push(
      {
        title: this.$t('Move to reproved list').toString(),
        btnIcon: 'fas fa-times',
        color: '#dc3545',
        btnAction: this.statusReprovado
      });
    }

    if (!this.isPublic && this.candidaturaStatus=="EMANALISE") {
      this.actions.push({
        title: this.$t('New chance to record').toString(),
        btnIcon: 'icon icon-trocar',
        color: 'rgb(106, 218, 215)',
        btnAction: this.showModalNewChance
      })
    }
  }

  public mounted() {
    this.loadOptions();
  }

}
</script>

<style>

</style>