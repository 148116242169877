import { EApplication } from '@/core/Enums/EApplication';
import { Endereco } from '@/core/models/Entities';
import moment from 'moment';
import Vue from 'vue';

Vue.mixin({
  data: function () {
    return {
      company: {},
      timeoutPID: 0,
      messagePID: 0,
      isCompanyApp: process.env.VUE_APP_APPLICATION! == EApplication.Company.toString(),
      isCandidateApp: process.env.VUE_APP_APPLICATION! == EApplication.Candidate.toString(),
      loadingEvent: false,
      loaderObj: null,
      vueTelProps: {
        mode: "international",
        defaultCountry: "BR",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ["BR"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      initTinySimpleSmall: {
        height: 150,
        menubar: false,
        plugins:
          "print preview paste searchreplace autolink fullscreen  link    table  hr   anchor toc",
        toolbar:
          "bold italic | alignleft aligncenter alignright alignjustify  | numlist bullist",
        content_css: "//www.tinymce.com/css/codepen.min.css"
      },
      initTinySimple: {
        height: 300,
        menubar: false,
        plugins:
          "print preview paste searchreplace autolink fullscreen  link    table  hr   anchor toc",
        toolbar:
          "bold italic | alignleft aligncenter alignright alignjustify  | numlist bullist",
        content_css: "//www.tinymce.com/css/codepen.min.css"
      },
      tinyMCEini: {
        height: 300,
        paste_data_images: true,
        content_css: "//www.tinymce.com/css/codepen.min.css",
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'paste undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: 'My page 1', value: 'http://www.tinymce.com' },
          { title: 'My page 2', value: 'http://www.moxiecode.com' }
        ],
        image_list: [
          { title: 'My page 1', value: 'http://www.tinymce.com' },
          { title: 'My page 2', value: 'http://www.moxiecode.com' }
        ],
        image_class_list: [
          { title: 'None', value: '' },
          { title: 'Some class', value: 'class-name' }
        ],
        importcss_append: true,
        templates: [
              { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
          { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
          { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
        ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'sliding',
        contextmenu: "link image imagetools table"
      }
    }
  },
  filters: {
    dateFormat(timeStamp: undefined | {date: string, timezone_type: number, timezone: string}) {
      return (timeStamp && timeStamp.date) ? moment(timeStamp.date).format('DD/MM/YYYY HH:mm') : ' - ';
    },
    dateOnly(timeStamp: {date: string, timezone_type: number, timezone: string} | undefined) {
      return (timeStamp && timeStamp.date) ? moment(timeStamp.date + 'Z').format('DD/MM/YYYY') : '';
    },
    formatEndereco(endereco: undefined | Endereco): string {
      let enderecoStr = '';

      if (endereco) {
        enderecoStr += endereco.rua ? endereco.rua : '';
        enderecoStr += endereco.endereco ? endereco.endereco : '';
        enderecoStr += endereco.numero ? ' , ' : '';
        enderecoStr += endereco.numero
          ? endereco.numero
          : '';
        enderecoStr += endereco.complemento ? ' - ' : '';
        enderecoStr += endereco.complemento
          ? endereco.complemento
          : '';

        enderecoStr += endereco.bairro ? ' | ' : '';

        enderecoStr += endereco.bairro
          ? endereco.bairro
          : '';
        enderecoStr += endereco.nomeCidade ? ' - ' : '';
        enderecoStr += endereco.nomeCidade
          ? endereco.nomeCidade
          : '';
        enderecoStr += endereco.nomeEstado ? ' - ' : '';
        enderecoStr += endereco.nomeEstado
          ? endereco.nomeEstado
          : '';

        enderecoStr += endereco.cep ? ' | ' : '';

        enderecoStr += endereco.cep ? endereco.cep : '';
        enderecoStr += endereco.pais ? ' - ' : '';
        enderecoStr += endereco.pais ? endereco.pais : '';
      }

      return enderecoStr;
    }
  },
  methods: {
    getDateToDBFormat: function(v) {
      try {
        if(v) {
          const day = (v.getUTCDate()).toString();
          const month = (v.getUTCMonth() + 1).toString();
          const year = v.getUTCFullYear();
          return `${year}-${month.padStart(2,"00")}-${day.padStart(2,"00")}`;
        }
        return '';
      } catch(e) {
        return ''
      }
    },
    scroolBottom: function(id) {
      const element: HTMLElement | null = document.getElementById(id);
      if (element)
        element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    fakeLoading: function() {
      // wololo
      const _this: any = this;
      _this.loadingEvent = true;
      setTimeout(() => {
        if (_this)
         _this.loadingEvent = false;
      },5000)
    }, 
    openLoading: function(container) {
      const _this: any = this;
      if(!_this.loadingEvent) {
        _this.loadingEvent = true;
        _this.loaderObj = this.$loading.show({
          // Optional parameters
          container,
          isFullPage: false,
          canCancel: false,
          loader: 'dots'
        });
      }
    },
    closeLoading: function() {
      const _this: any = this;
      if(_this.loadingEvent) {
        _this.loadingEvent = false;
        _this.loaderObj?.hide();
      }
    },
    redForRequired: function (id) {
      return !id ? 'red' : ''
    },
    initUW: function() {
      window['UserWay'].widgetOpen();
    },
    initHT: function() {
      try{
        if(window.handTalk) {
          window.handTalk.destroy();
          window.handTalk = null;
          return;
        }
      } catch (e) {
       console.log('ht not destroyed, maybe it was not initialized') 
      }
      const h: string[] = ["MAYA","HUGO"];
      const rndInt = Math.floor(Math.random() * 2);
      if(window.HT){
        window.handTalk = new window.HT({
          token: "8bee7199afae0759d70be1cffcd68778",
          avatar: h[rndInt],
          parentElement: document.body
        });
        return;
      }
    },
    destroyHT: function() {
      if(window.handTalk){
        window.handTalk.destroy()
      }
    },
    toastError: function(msg: string) {
      // wololo
      const _this: any = this;

      _this.$toast.variant = 'danger';
      _this.$toast.addMsg(this.$t(msg))
      _this.$toast.addTitle(this.$t('Oops').toString());
      _this.$toast.open();
    },
    toastSuccess: function(msg: string) {
      // wololo
      const _this: any = this;

      _this.$toast.variant = 'success';
      _this.$toast.addMsg(this.$t(msg))
      _this.$toast.addTitle(this.$t('Success').toString());
      _this.$toast.open();
    },
    saveLocalData: function(key :string,value :any,expireAt: any) {
      if(key && value) {
        if(typeof(value) == 'object') {
          value['jb_expires_at'] = expireAt;
          sessionStorage.setItem(key,btoa(JSON.stringify(value)))
        } else {
          sessionStorage.setItem(key,btoa(value))
        }
      }
    },
    _ti: function(min: number) {
      const d: Date = new Date();
      d.setMinutes(d.getMinutes()+min)
      return d.getTime();
    },
    dateOnly: function(timeStamp: {date: string, timezone_type: number, timezone: string} | undefined) {
      return (timeStamp && timeStamp.date) ? moment(timeStamp.date).format('DD/MM/YYYY') : '';
    },
    getLocalData: function(key:string) {
        try {
          if(sessionStorage.getItem(key)){
            const v: string = atob(sessionStorage.getItem(key)+"");
            if(v.indexOf("{") == 0) {
              const d: any = JSON.parse(v)
              const now: Date = new Date();
              if((d.jb_expires_at != null && d.jb_expires_at >= now.getTime()) || !d.jb_expires_at) {
                delete d.jb_expires_at;
                return d;
              }
            } else {
              return v;
            }
          } 
          return null;
        } catch (e) {
          console.log('cant parse local Data');
          sessionStorage.removeItem(key);
          return null;
        }
    }
  }
});
