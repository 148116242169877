<template>
  <b-form-group
    :label="label+(required?' *':'')"
    :class="variant"
    :state="state">
    <b-form-checkbox
      @input="handleInput($event)"
      @blur="handleBlur($event)"
      :class="variant"
      :checked="value"
      :value="checkedValue"
      :unchecked-value="uncheckedValue"
      :required="required"
      :state="state"
    >
      <slot />
    </b-form-checkbox>
    <b-form-invalid-feedback v-if="invalidFeedback" :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class CheckboxInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: false}) public value!: any;
  @Prop({default: true}) public checkedValue!: any;
  @Prop({default: false}) public uncheckedValue!: any;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
</script>

<style lang="scss" scoped>
</style>

