<template>
  <div class='container'>
    <div class='wrapper'>
      <h3 class='title'>{{ $t('Feel free to speak with us') }}</h3>
      <div class='text'>
        {{ $t(text) }}
      </div>
      <div>
        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=marina.hasselmann@jobecam.com&cc=amanda.oliveira@jobecam.com&su=Quero saber mais sobre a jobecam&body=
            Olá Time Jobecam, 
            Eu sou a (o)
            Gostaria de conhecer os planos e recursos que a Jobecam tem a oferecer!

            [Seu Nome]
            [Nome da Empresa]
            [nome@email.com]
            [número de telefone]
            [número de funcionários]
            [número de vagas mensais]

            Aguardo seu retorno," target="_blank">
          <b-button variant="outline-primary" class='btn-large'> {{ $t('Contact by email') }}</b-button>
        </a>
      </div>
      <div>
        <a href="https://wa.me/5521990732757/?text=Olá, gostaria de saber mais sobre a Jobecam" target="_blank">
          <b-button variant="primary" class='btn-large'> {{ $t('Chat with us via whatsapp') }}</b-button>
        </a>
      </div>

    </div>
  </div>
</template>
<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ContactForPlan extends Vue {
  @Prop({ default: "Talk to our team of specialists who are eager to present you with an offer perfectly aligned with your profile or that of your company, ensuring that you fully enjoy all the benefits of the services. We are ready to listen to you and respond to your needs in a fast and personalized way." })
  public text!: string;

  @Prop({ default: 'Olá Time Jobecam, Eu sou a(o) [SeuNome] Gostaria de conhecer os planos e recursos que a Jobecam tem a oferecer [Seu Nome];[Nome da Empresa];[nome@email.com]; [número de telefone]; [número de funcionários]; [número de vagas mensais]; Aguardo seu retorno,' })
  public message!: string;
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.wrapper {
  width: 55%;
  text-align: center;
}

.title {
  font-weight: 800;
  text-align: center;
}

.text {
  text-align: center;
  word-break: break-word;
  margin-bottom: 0.5rem;
}

.btn-large {
  width: 90%;
  margin-top: .5rem;
}
</style>