import { render, staticRenderFns } from "./VagaTitle.vue?vue&type=template&id=666aab09&scoped=true"
import script from "./VagaTitle.vue?vue&type=script&lang=ts"
export * from "./VagaTitle.vue?vue&type=script&lang=ts"
import style0 from "./VagaTitle.vue?vue&type=style&index=0&id=666aab09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666aab09",
  null
  
)

export default component.exports