/*!
=========================================================
* Iugu for Jobecam - v0.5.0
=========================================================
* Product Page: https://www.jobecam.com/
* Author: Eugênio B De Carli
=========================================================
*/

export default function JBIugu() {
  Iugu.setAccountID("F6C2E2F294E247A4AD34931465059A71");
  Iugu.setTestMode('production' !== process.env.NODE_ENV);
  this.valid = false;
  this.tokenResponseObject = null;
  this.name = '';
  this.firstname = '';
  this.lastname = '';
  this.ccNo = '';
  this.cvv = '';
  this.expMonth = '';
  this.expYear = '';
  this.expMonthYear = '';


  this.cSuccess = function() { console.log('callback from JBIugu') };
  this.cError = function() { console.log('callback from JBIugu') };

  this.sucess = function(data){
    
    
    document.getElementById('tokenIUGU').value = data.id;

    if(!data.errors) {
      console.logdata;
      this.valid = true;
      this.cSuccess();
    }else{
      this.valid = false;
      this.cError();
      // $.unblockUI();
      //       $.alert({
      //   title: 'Dados Inválidos', content: 'Os dados informados não foram processados corretamente.',
      //   icon: 'fa fa-question', theme: 'material', closeIcon: true, animation: 'scale', type: 'red',
      // });
    }
  };

  this.error = function(data){
    // $.unblockUI();
    this.valid = false;
    this.cError()
    console.log('erro')
  };

  this.validateCreditCardNumber = function() {
    let me = this
    let ccValidation = Iugu.utils.validateCreditCardNumber(me.ccNo)
    return ccValidation
  }

  this.validateExpDate = function() {
    let me = this
    let expDateValidation = Iugu.utils.validateExpirationString(me.expMonthYear)
    return expDateValidation
  }

  this.validateNumberCVV = function() {
    let me = this
    let cvvParam = Iugu.utils.getBrandByCreditCardNumber(me.ccNo)
    let cvvValidation = Iugu.utils.validateCVV(me.cvv, cvvParam)
    return cvvValidation
  }

  this.makeTokenRequest = function() {
    let me = this
    console.log('making request');
    // Call our token request function
    let creditCard = new Iugu.CreditCard(
      me.ccNo,
      me.expMonth,
      me.expYear,
      me.firstname,
      me.lastname,
      me.cvv
    );

    const form = document.getElementById('credit-card-form');

    form.addEventListener('submit', e => {
      e.preventDefault();
      const token = document.getElementById('tokenIUGU').value;
    });

    return new Promise((resolve, reject) => {
      Iugu.createPaymentToken(creditCard, function (data) {
        document.getElementById('tokenIUGU').value = JSON.stringify(data);

        if (!data.errors) {
          me.tokenResponseObject = data;

          resolve(data);
        }
        else {
        
          reject(data);
          // $.unblockUI();
          // $.alert({
          //   title: 'Dados Inválidos', content: 'Os dados informados não foram processados corretamente.',
          //   icon: 'fa fa-question', theme: 'material', closeIcon: true, animation: 'scale', type: 'red',
          // });
        }
      });
    });
  }
};



