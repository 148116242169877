<template>
  <b-card no-title  class="my-2 py-2 shadow rounded-card">
    <!-- body -->
    <b-row class="mb-3 ">
      <b-col md="12">
        <h3 class="text-info">{{$t('Talent Data')}}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="2" >
        <div class="img-wrapper  d-flex justify-content-center rounded-circle">
          <b-img :src="randomImg()" center width="150" class="mr-2 img-with "  />
        </div>        
      </b-col>
      <b-col md="10" class="mb-2">
        <b-row >
          <b-col md="12" class="d-md-flex d-sm-block justify-content-around mb-2">
            <text-label-html
              :label="$t('Name')"
             >
             <template v-slot:value>
               <h4 class="my-0" v-if="!displayName">{{$t('Hidden')}}</h4>
               <h4 class="my-0" v-else>{{$t(displayName)}}</h4>
             </template>
            </text-label-html>
            <!-- <text-label-value
              :label="$t('City')+'/'+$t('State')"
              :value="localidade" /> -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script lang="ts">
import { CandidatoEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DadosPessoaisView extends Vue {
  @Prop({ default: null })
  candidato!: CandidatoEntity;

  // showButtons is for to show or to hide video on the card
  @Prop({ default: true })
  public displayVideo!: boolean;

  @Prop({ default: false })
  public isPublic!: boolean;

  @Prop({ default: false })
  public ispdf!: boolean;

  @Prop({ default: "" })
  public displayName!: string;

  public pcdArrayDescritivo: any[] = [];
  public pcdDetails: any = {};

  get nomeCompleto() {
    return (this.candidato?.primeironome||'')+' '+(this.candidato?.sobrenome||'')
  }

  get localidade() {
    let loc = `${this.candidato?.perfil?.localidade}`;
    if(this.candidato?.perfil?.nomeCidade)
      loc = `${this.candidato?.perfil?.nomeCidade}/${this.candidato?.perfil?.nomeEstado}`;

    return loc;
  }

  public randomImg() {
    const n: number = (Math.floor((Math.random() * 28))+1);
    return `https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/blind/poster${n}.png`
  }

  get endereco(): string {
    return this.$options.filters!.formatEndereco(this.candidato.perfil);
  }

}
</script>

<style lang="scss" scoped>
  .img-with {
    width: auto;
    height: 150px;
  }
  .img-wrapper {
    width: 150px;
    height: 150px;
    overflow: hidden;
  }
</style>