import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class EntrevistaAgenda extends Repository {
  constructor() {
    super(
      new ApiRouter({
          create: { url: '/v1/entrevista-agenda', method: 'POST', headers: {} },
          fetch: { url: '/v1/entrevista-agenda/{id}', method: 'GET', headers: {} },
          fetchAll: { url: '/v1/entrevista-agenda', method: 'GET', headers: {} }, // tem parametro de Busca 's'
          update: { url: '/v1/entrevista-agenda/{id}', method: 'PUT', headers: {} },
          delete: { url: '/v1/entrevista-agenda/{id}', method: 'DELETE', headers: {} }
        }));
  }
}