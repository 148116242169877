<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="xl"
    ref="modal"
    body-class="p-0"
    lazy
  >
    <div class="modal-content m-content" v-if="candidatura.vagaHabilidades && candidatura.vagaHabilidades.length >0">
      <button class="btn btn-danger" @click="$bvModal.hide('modalCandidateSkills')">
        <i class="icon-cancelar"></i>
      </button>

      <!-- Title -->
      <div class="row m-content__title" >
        <div class="col-12">
          <h1>{{$t('Candidates skills')}}</h1>
        </div>
      </div>

      <b-row class="pb-3">
        <!-- Left column -->
        <b-col md="8" class="left" v-if="seriesJob">
          <p>{{$t('Minimum required skills')}}</p>
          <bar-chart
            v-if="seriesJob.length < 3"
            :labels="habNames" 
            :colors="['White']"
            :cColors="'#93DFFB'"
            :vColors="'#4C86F3'"
            :borderColor="'rgba(4, 73, 185, 0.77)'"
            :legend="true" 
            :seriesOne="seriesCandidate" 
            :seriesTwo="seriesJob" 
            :label="$t('Skills')" 
            :icon="'graficos'"/>

          <radar
            v-else
            :labels="habNames" 
            :colors="['White']"
            :borderColor="'rgba(4, 73, 185, 0.77)'"
            :legend="true" 
            :datasets="datasets"
            :label="$t('Skills')" 
            :icon="'graficos'"/>
        </b-col>

        <!-- Right column -->
        <b-col md="4" class="right pl-5">
          <p>{{$t('Other skills')}}</p>
          
          <span class="m-1 float-left" v-for="(h,i) in candidatura.vagaHabilidades" :key="i">
            <b-badge variant="success" class="mr-1">
              {{h.name}} <b-button variant="light" size="sm" pill> {{h.nivel}} </b-button>
            </b-badge>  
          </span>
              
            <!-- <b-col md="6" class="mb-3" v-for="(h,i) in candidatura.vagaHabilidades" v-bind:key="i">
              <b-row class="px-2">
                <b-col md="2" class="d-flex justify-content-center align-items-center right__image px-0" @click.prevent="selected">
                  <img :src="candidatura.candidato.fotoAvatar" v-if="candidatura.candidato.fotoAvatar && candidatura.candidato.fotoAvatar.indexOf('media.licdn.com')==-1"/>
                  <img src="/static/img/avatar.png" alt v-else/>
                </b-col>
                <b-col md="10" class="px-0">
                  <nivel-bar :label="h.name" :activeLevels="h.nivel"></nivel-bar>
                </b-col>
              </b-row>
            </b-col> -->
        </b-col>
      </b-row>
    </div>
    <div class="modal-content m-content" v-else>
      <h4 class="text-center text-gray">{{ $t('No skills registered in this job') }}</h4>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import RadarChart from "@/components/chart/RadarChart.vue"
import BarChart from "@/components/chart/BarChart.vue"

@Component({
  components: {
    'radar': RadarChart,
    'bar-chart': BarChart
  }
})
export default class ModalCandidateSkills extends Vue {
  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({default: () => { new CandidaturaEntity() }})
  public candidatura!: CandidaturaEntity;

  // @Prop()
  public seriesCandidate: number[]|null = null;
  public seriesJob: number[]|null = null;

  @Prop()
  public labels!: string[];

  get habNames() {
    let habilidadeNames = this.candidatura.vagaHabilidades
    return habilidadeNames!.map( _ => { return _.name });
  }

  get datasets() {
    return [
        {
          label: this.$t('Candidate').toString(),
          backgroundColor: 'rgba(155, 193, 253, 0.55)',
          borderColor: '#006400',
          borderWidth: [1],
          data: this.seriesCandidate,
        },
        {
          label: this.$t('Vacancy').toString(),
          backgroundColor: 'rgba(233, 47, 174, 0.6)',
          borderColor: '#645c00',
          borderWidth: [1],
          data: this.seriesJob,
        }
      ] 
  }


  @Watch('candidatura',{deep:true})
  public habMatch() {
    // return vagaIdsArr.filter(val => candIdsArr.includes(val))
    this.seriesCandidate = [];
    this.seriesJob = [];
    if (this.candidatura && this.candidatura.vagaHabilidades) {
      this.candidatura.vagaHabilidades!.forEach(o => {
        this.seriesCandidate!.push(o.nivel);
        this.seriesJob!.push(o.nivelVaga);
      })
    }  
  }


  public mounted() {
    this.habMatch();
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main";

.m-content {
  padding: 3em;
  p {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.9em;
    padding-bottom: 1em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }

  .left {
    text-align: -webkit-center;
    border-right: solid 1px lightgray;
  }
  
  .right {
    &__image {
      img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 32px;
        height: 32px;
      }
    }

    &__nivel-status-text {
      margin: 0;
    }

    .nivel {
      padding: 0;
      label {
        font-size: 11px
      }
    }
  }
}
</style>

<style lang="scss">
#modalAddJobcoins .modal-body {
  padding: 0;
}

#modalAddJobcoins .modal-dialog {
  width: 650px;
}

.m-content__jobcoin-input--input input.dark{
  font-size: 3rem;
  text-align: center;
}
</style>