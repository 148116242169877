
import { VagasHabilidadesEntity } from '@/core/models/Entities';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class VagasHabilidades extends Repository {
  public entidade: VagasHabilidadesEntity;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/gestao-vaga-skills', method: 'POST', headers: {} },
      delete: { url: '/v1/gestao-vaga-skills/{id}', method: 'DELETE', headers: {} },
      fetch: { url: '/v1/gestao-vaga-skills/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/gestao-vaga-skills', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/gestao-vaga-skills/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new VagasHabilidadesEntity();
  }


  public async save(): Promise<boolean> {
    if (!this.entidade.id) {
      return super.create(this.entidade)
        .then((data: any) => {
          this.entidade = data;
          return true;
        })
        .catch((err: any) => {
          return false;
        });
    } else if (this.entidade.id! > 0) {
      return super.update(this.entidade)
        .then((data: any) => {
          this.entidade = data;
          return true;
        })
        .catch((err: any) => {
          return false;
        });
    }

    return new Promise((resolve) => {
      resolve(false);
    });
  }
}
