<template>
  <modal :title="$t('What is the email to forward?')" :show="show" :id="id" :size="'md'">

    <b-form-input 
      class="mb-3" 
      placeholder="Email"
      v-model="inputValue">
    </b-form-input>
    <p class="text">{{$t('Separate by a comma (,)')}}</p>

    <!-- Footer -->
    <template v-slot:footer>
      <div class="w-100">
        <b-button
          variant="success"
          size="md"
          class="float-right"
          @click="sharePublic"
          v-promise-btn="{promise:currentPromise}"
        >
          {{$t("Send")}}
        </b-button>

        <b-button
          size="md"
          class="float-right mr-2"
          @click="$bvModal.hide(id)"
        >
          {{$t("Cancel")}}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import PublicCandidaturaService from "@/core/Services/Empresa/PublicCandidatura";
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import Modal from '@/components/modal/Modal.vue'

@Component({
  components: {
    Modal
  }
})
export default class ModalShareLink extends Vue {
  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({default: false})
  public single!: boolean;

  @Prop({default: () => { return []}})
  public multipleSelection!: any[];

	public pubCandidaturaService!: PublicCandidaturaService
  public inputValue: string = ''
  public currentPromise: any|null = null;

  constructor() {
    super()
    this.pubCandidaturaService = new PublicCandidaturaService()
    // this.candidatura = new CandidaturaEntity()
  }
  
  public cancelModal() {
    this.$bvModal.hide(this.id)

  }
  
  public sharePublic() {
    let emails: string[] = this.inputValue.split(",")

    emails.forEach(( email:string) => {
      
      let valid: boolean = true
      email = email.trim();
      
      if (email == '' || email == null) {
        this.errorAlert()
        valid = false
      } else if (email.match(/[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/) == null) {
        this.errorAlert(email)
        valid = false 
      }

      let ids: any[] = [];
      if (this.single && this.candidatura) {
        ids.push(this.candidatura.id)
      }
      if (!this.single && this.multipleSelection) {
        this.multipleSelection.forEach((id: number) => {
          if (ids.indexOf(id) < 0)
            ids.push(id);
        });
      }

      if (valid == true) {
        this.currentPromise = this.pubCandidaturaService.update({id: 0, ids: ids, email: email, mult: true}).then((data: any) => {
          this.successAlert(this.$t('The link has been shared and will be available for 15 days').toString() + ' - ' + email)
        }).catch(this.cancelMessage);
      }
      this.$bvModal.hide(this.id)
      
    });
    this.inputValue = ''
  }

  public successAlert(msg?: string) {
    this.$toast.variant = 'success';
    if (msg)
      this.$toast.addMsg(msg)
    this.$toast.addMsg(this.$t('E-mail shared successfully'));
    this.$toast.open()
  }

  public errorAlert(msg?: string) {
    this.$toast.variant = 'danger';
    this.$toast.addTitle(this.$t('Oops').toString())
    if (msg)
      this.$toast.addMsg(msg)
    this.$toast.addMsg(this.$t('It was not possible to send the email'))
    this.$toast.open()
  }

  public attentionAlert() {
    this.$alert.title = this.$t('Success')
    this.$alert.addMsg(this.$t('The link has been generated and will be available for 30 days'))
    // this.$alert.addMsg(this.$t('As informações compartilhadas são confidenciais e estará sujeito a multa conforme contrato de serviço e termos de privacidade, caso seja compartilhado com terceiros. Este link ficará disponível para visualização por 15 dias.'))
    this.$alert.callBoxCheck(this.$t('Ok').toString());
  }

  public cancelMessage() {
    // this.$alert.title = this.$t('Erro')
    this.$alert.addMsg(this.$t('Please try again later'))
    this.$alert.callBoxWarn()
  }
}
</script>

<style lang="scss" scoped>
  .text {
    font-size: 0.8rem;
    margin: 0;
  }
</style>
