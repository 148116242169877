<template>
  <div class="container-fluid mt-1">
    <!-- Tabela Completa -->
    <b-row >

      <b-col md="12">
        <div class="d-flex justify-content-md-between align-items-center">
          <div class="mx-3">{{$t('From')}}</div> 

          <b-form-datepicker
            id="input-date-pub"
            class="input -date-pub"
            v-model="dtIni"
            value-as-date
            :locale="$i18n.locale"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          />

          <div class="mx-3">{{$t('Until')}}</div> 

          <b-form-datepicker
            id="input-date-pub-end"
            class="input-date-pub-end"
            v-model="dtFim"
            value-as-date
            :locale="$i18n.locale"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          />
        </div>
      </b-col>
      
      <b-col md="12" class="mb-2 mt-4 d-flex justify-content-center">
          <div class="btn-animation m-2" style="position:relative"  v-show="itemsSimple && itemsSimple.length > 0" >
              <lootie animation="download" :show="true" :autoplay="true" :width="50" :height="50" v-model="lottieDownload"/>
            </div>
        <b-button variant="outline-primary" class="px-5 py-3"  @click="loadReport" v-promise-btn="{promise}">  
              <i class="fa fa-arrow-down"></i>  {{$t('Generate report')}} 
          </b-button>
          <a id="downloadLinkSimple" href="javascript:void(0)" ></a>
      </b-col>

      <b-col sm="12">
        <div class="text-left text-primary p-3 mx-3" v-if="isBusy">
          <div class="d-flex justify-content-center">
            <b-spinner class="align-middle mx-2" variant="primary"></b-spinner>
            <strong>{{$t('Loading your report')}}...</strong>
          </div>
        </div>
        <b-alert show variant="warning" class="text-center" v-else-if="itemsSimple && itemsSimple.length == 0">
          <strong>{{ $t("No application found") }}</strong>
        </b-alert>
        <div v-show="showTable">
        <transition name="slide-fade" >
          <b-table id="simpleRep" striped hover responsive :items="itemsSimple" :fields="fieldsSimple"  >
            <template v-slot:head()="data">
              {{ data.label.toUpperCase() }}
            </template>
          </b-table>
        </transition>
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script lang="ts">
import LootieAnimation from '@/components/loader/LootieAnimation.vue';
import Dashboard from '@/core/Services/Empresa/Dashboard';
import { siteCandidateEndPoint } from '@/core/endpoint';
import Datepicker from '@hokify/vuejs-datepicker';
import { Component, Vue } from 'vue-property-decorator';
import ExportTable from './ExportTable';

@Component<ReportTableSimple>({
  components: {
    Datepicker,
    'lootie': LootieAnimation
  }
})
export default class ReportTableSimple extends Vue {
  public service: Dashboard;
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;
  public fieldsSimple: any [] | null = null;
  public itemsSimple: any [] | null = null;
  public activeNav: number = 0;
  public dtIni: Date|null = null;
  public dtFim: Date|null = null;
  public vagaId: string = '';
  public vagaOptions: any[] = [];
  public hideJobs: boolean = false;
  public lottieDownload: any = null;
  public promise: Promise<any>|null = null;
  public showTable: boolean = false;

  constructor() {
    super();
    this.service = new Dashboard();
    this.isBusy = false;
  }

  
  public created() {
    this.dtFim = new Date();
    this.dtIni = this.referenceDate(this.dtFim, -30)
  }

  public async mounted() {
    this.loadFields();
  }

  public loadFields() {
    this.fieldsSimple = [
      { key: 'posicao', label: this.$t('Position'), class: 'text-left' },
      { key: 'datapublicacao', label: this.$t('Publication'), class: 'text-left' },
      { key: 'recrutador', label: this.$t('Recruiter'), class: 'text-left' },
      { key: 'candidaturas', label: this.$t('Applications'), class: 'text-left' },
      { key: 'status_vaga', label: 'Status', class: 'text-left' },
      { key: 'grp_black', label: this.$t('Afro-descendants Community'), class: 'text-left' },
      { key: 'grp_lgbt', label: 'LGBTQIA+', class: 'text-left' },
      { key: 'grp_refugiado', label: this.$t('Refugee and Immigrants'), class: 'text-left' },
      { key: 'grp_pcd', label: this.$t('PWD Group'), class: 'text-left' },
      { key: 'grp_fem', label: this.$t('Womens Movement'), class: 'text-left' },
    ];
  }

  public getDateString(v: Date) {
    const day = (v.getUTCDate()).toString();
    const month = (v.getUTCMonth() + 1).toString();
    const year = v.getUTCFullYear();
    return (day.length == 1 ? "0" + day : day) + "/" + (month.length == 1 ? "0" + month : month) + "/" + year;
  }

  public referenceDate(date: any, number: number) {
    let data: Date|null = null;
    try {
        if ((typeof date === "string")) { // dd/mm/yyyy
            data = new Date(parseInt(date.substring(6, 10)), parseInt(date.substring(3, 5)) - 1, parseInt(date.substring(0, 2)))
        } else {
            data = new Date(date);
        }

        const dataAux = new Date(data!);
        dataAux.setDate(data!.getDate() + (number));

        return dataAux;
    } catch (e) {
        console.error(e);
        return null;
    }
  }

  public downloadReport() {
    try {
      console.log('start aqui')
      const table: any = document.querySelector('#simpleRep');
      const fName: string = 'jobecam_report_simple_'+(new Date().getTime())+'.csv';  
      const x = new ExportTable(table,fName,document.querySelector('#downloadLinkSimple'))
      console.log('end aqui')
      
      this.lottieDownload.stop();
      this.lottieDownload.play();
      (document.querySelector('#downloadLinkSimple') as HTMLLinkElement)!.click();
    } catch (e) {
      console.error(e)
    }
  }
  
  public async loadReport() {
    this.promise = new Promise(async (resolve,reject) => {
    try {
      this.isBusy = true;
      this.$emit('done',false);
      const filter = {report: 'table'}
      const vuid = this.$route.params.vguid;

      if (this.dtIni) {
        filter['fts'] = `datap_i=${this.getDateString(this.dtIni)}`
      }
      if (this.dtFim) {
        filter['fts'] += (!!filter['fts'] ? ';' : '') + `datap_f=${this.getDateString(this.dtFim)}`
      }

      const data: any = await this.service.fetchAll(filter);
      // const data: any = await this.service.fetchTable(filter);
      console.log(data);
      this.items = [];
      this.itemsSimple = [];
      
      let dtIn: any = {};
      let dtInSimple: any = {};
      const arrayBinFields:string[] = ['grp_black','grp_lgbt','grp_indigena','grp_refugiado','grp_pcd','pcd','grp_fem','grp_3ida'];

      if (!this.hideJobs && this.vagaOptions.length == 0) {
        data['vagas'].forEach(vagaOptin => {
          this.vagaOptions.push({value: vagaOptin[0], text: vagaOptin[1]})
        });
      }
      data['report'].forEach((element: any ) => {
        this.fieldsSimple!.forEach((elKey: any) => {
          dtIn[elKey.key] = !element[elKey.key] ? ' - ' : (element[elKey.key]+'').replace('{CANDIDATE_LINK}', siteCandidateEndPoint);        
        });
        dtIn['hidden_cv'] = 'https://empresa.jobecam.com/t/info/'+element.cguid
        this.items!.push(dtIn);
        dtIn = {};
        if (!dtInSimple[element.id]) {
            dtInSimple[element.id] = {
              posicao: element.posicao,
              datapublicacao: element.datapublicacao,
              recrutador: element.recrutador,
              candidaturas: 0,
              entrevistas: 0,
              status_vaga: element.status_vaga,
              masculino: 0,
              feminino: 0,
              h_trans: 0,
              m_trans: 0,
              n_binario: 0,
              outros: 0,
              grp_pcd: 0,
              grp_black: 0,
              grp_fem: 0,
              grp_refugiado: 0,
              grp_indigena: 0,
              grp_lgbt: 0,
              grp_3ida: 0,
              ni: 0

            }
          } 
          dtInSimple[element.id].candidaturas += 1;
          dtInSimple[element.id].entrevistas += parseInt(element.n_respostas || "0");
          //diversidade grupos
          dtInSimple[element.id].grp_pcd += ((element.grp_pcd == 'Sim') ? 1 : 0);
          dtInSimple[element.id].grp_pcd += ((element.pcd == 'Sim') ? 1 : 0);

          dtInSimple[element.id].grp_black += ((element.grp_black == 'Sim') ? 1 : 0);

          dtInSimple[element.id].grp_fem += ((element.grp_fem == 'Sim') ? 1 : 0);
          dtInSimple[element.id].grp_fem += ((element.genero == 'FEMININO') ? 1 : 0);

          dtInSimple[element.id].grp_refugiado += ((element.grp_refugiado == 'Sim') ? 1 : 0);
          dtInSimple[element.id].grp_indigena += ((element.grp_indigena == 'Sim') ? 1 : 0);

          dtInSimple[element.id].grp_lgbt += ((element.grp_lgbt == 'Sim') ? 1 : 0);
          dtInSimple[element.id].grp_lgbt += ((element.genero == 'MULHER TRANS') ? 1 : 0)
          dtInSimple[element.id].grp_lgbt += ((element.genero == 'HOMEM TRANS') ? 1 : 0)
          dtInSimple[element.id].grp_lgbt += ((element.genero == 'NÃO-BINÁRIO') ? 1 : 0)

          //diversidade genero        m('MASCULINO','FEMININO','MULHER TRANS','HOMEM TRANS','NÃO-BINÁRIO','OUTROS') 
          dtInSimple[element.id].masculino += ((element.genero == 'MASCULINO') ? 1 : 0)
          dtInSimple[element.id].feminino += ((element.genero == 'FEMININO') ? 1 : 0)
          dtInSimple[element.id].m_trans += ((element.genero == 'MULHER TRANS') ? 1 : 0)
          dtInSimple[element.id].h_trans += ((element.genero == 'HOMEM TRANS') ? 1 : 0)
          dtInSimple[element.id].n_binario += ((element.genero == 'NÃO-BINÁRIO') ? 1 : 0)
          dtInSimple[element.id].outros += ((element.genero == 'OUTROS') ? 1 : 0)
          dtInSimple[element.id].ni += ((element.genero == 'Não informado') ? 1 : 0)
        
        });
        let ks: string[]  = Object.keys(dtInSimple)
        ks.forEach((_key: string) => {
          this.itemsSimple!.push(dtInSimple[_key])      
        });
        this.$emit('done',true);
        setTimeout(() => {
          this.downloadReport()
        },2000)
        resolve(true);
      } catch (error) {
        reject();
      } finally {
        this.isBusy = false;
      }
    })
  }

  
  public firstLetterCapitilize(str: string) {
    if(!str)
      return '';
      
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  }
  
  public setActiveNav(nav: number) {
      this.activeNav = nav;
  }
  

}

</script>


<style lang="scss" scoped>

</style>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

$card-qt-border-color: #f1f1f1;
.tabs {
.nav {
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
  .card-qt {
    border: 0.13em solid $card-qt-border-color;
    border-radius: 1.25em;
    min-height: 250px;

    &__header {
      font-size: 12px;
      padding: 5px 20px;
      margin-top: 1.25em;
      color: $gray;
    }
    &__body {
      &__row {
        width: 100%;
        max-height: 3000px;
        overflow-x: auto;
        font-size: 12px;
      }
      &__content {
        border-left: 0.1em solid $card-qt-border-color;
        width: 100%;
        text-align: center;

        &:first-child {
          border-left: none;
        }

        &__qt {
          font-size: 2.7em;
        }
      }
    }
  }
.latest-candidaturas {
  &__image {
    img {
      width: 2.5em;
      border-radius: 50%;
    }
  }
  &__names {
    line-height: 1.2em;
  }
}
}
</style>