<template>
  <div class="box-container d-flex flex-column mb-5">
    <form @submit="doSubmit" >
      <b-row>
        <b-col md="12" class="d-flex justify-content-between">
          <div>
            <h3>
              {{ $t("Add page content") }}
            </h3>   
          </div>
          
            <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="success"
              @dismiss-count-down="countDownChanged"
            >
              {{ $t('Saved successfully') }}
            </b-alert>

          <div class="mx-2">
            <b-button variant="primary" @click="save" v-promise-btn="{promise}"> {{$t('Save')}}</b-button>
            <b-button variant="outline-danger" class="mx-1" @click="remove"  v-promise-btn="{promise}"> <i class="fa fa-trash" /> </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <text-input
              variant="dark"
              type="text"
              :required="true"
              class="mb-1"
              :label="$t('Menu')"
              :placeholder="$t('Menu')"
              v-model="value.menu"
              @input="update"
            />
        </b-col>
        <b-col md="6">
          <text-input
              variant="dark"
              type="text"
              :required="true"
              class="mb-1"
              :label="$t('Page title')"
              :placeholder="$t('Title')"
              v-model="value.titulo"
              @input="update"
            />
        </b-col>
        <b-col md="12 mt-2">
          <b-form-group :label="$t('Page content')" class="form-text-area-mce">
            <editor :init="initTiny" v-model="value.corpo" @input="update" ></editor>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { PaginaInstitucionalEntity } from '@/core/models/Entities';
import Page from '@/core/Services/ContentManager/PaginaInstitucional';
import Editor from "@tinymce/tinymce-vue";

@Component<FormInstitucional>({
  components: {
    Editor
  }
})
export default class FormInstitucional extends Vue {
  public service: Page = new Page();
  public promise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public initTiny: any = {
    height: 600,
    paste_data_images: true,
    content_css: "//www.tinymce.com/css/codepen.min.css",
    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    imagetools_cors_hosts: ['picsum.photos'],
    menubar: 'file edit view insert format tools table help',
    toolbar: 'paste undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
    toolbar_sticky: true,
    autosave_ask_before_unload: true,
    autosave_interval: "30s",
    autosave_prefix: "{path}{query}-{id}-",
    autosave_restore_when_empty: false,
    autosave_retention: "2m",
    image_advtab: true,
    link_list: [
      { title: 'My page 1', value: 'http://www.tinymce.com' },
      { title: 'My page 2', value: 'http://www.moxiecode.com' }
    ],
    image_list: [
      { title: 'My page 1', value: 'http://www.tinymce.com' },
      { title: 'My page 2', value: 'http://www.moxiecode.com' }
    ],
    image_class_list: [
      { title: 'None', value: '' },
      { title: 'Some class', value: 'class-name' }
    ],
    importcss_append: true,
    templates: [
          { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
      { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
      { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
    ],
    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
    image_caption: true,
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: "mceNonEditable",
    toolbar_mode: 'sliding',
    contextmenu: "link image imagetools table"
  };

  @Prop({default: () => {new PaginaInstitucionalEntity()}})
  public value!: PaginaInstitucionalEntity;

  @Prop({default: -1})
  public index!: number;

  @Getter("getProfile", { namespace: 'profile' }) 
  public getProfile: any;


  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }

  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

  public doSubmit(event: any) {
    // this.value.description = this.descricao;
    this.update();
    event.preventDefault();
    return false;
  }
  
  public update() {
    this.$emit('input', this.value)
  }

  public save() {
    if (this.value.id == 0) {
      this.promise = this.service.create(this.value)
    } else {
      this.promise = this.service.update(this.value)
    }
    this.promise.then((data: PaginaInstitucionalEntity) => {
      this.$emit('input', data);   
      this.showAlert();   
    })
  }

  public async remove() {
    this.$confirm.addMsg(this.$t("Are you sure you want to remove this?").toString())
    const check = await this.$confirm.callBox();
    if (check) {
      if (this.value.id == 0) {
        this.$emit('remove', this.index)
      } else {
        this.promise = this.service.delete({id: this.value.id})
        this.promise.then((data: any) => {
          this.$emit('remove', this.index)
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
.box-container {
  min-height: calc(100vh - 55px - 32px);
  .box-formulario {
    > div {
      width: calc(50% - 1em);
    }
    .foto-perfil {
      img {
        width: 6em;
        border-radius: 50%;
      }
    }
  }
}

@include media("<desktop") {
  .box-container {
    font-size: 0.8em;
    h1 {
      font-size: 2em;
    }
    .btn-change-user-picture,
    .btn-close-window,
    .btn-save-recruiter {
      font-size: 0.9em;
    }
  }
}
</style>