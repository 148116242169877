import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { RecrutadorEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';

export default class GestaoRecrutador extends Repository {

  public entidade: RecrutadorEntity;

  constructor() {
    super(new ApiRouter({
        create: { url: '/v1/gestao-recrutador', method: 'POST', headers: {} },
        delete: { url: '/v1/gestao-recrutador/{id}', method: 'DELETE', headers: {} },
        fetch: { url: '/v1/gestao-recrutador/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/gestao-recrutador', method: 'GET', headers: {} },
        info: { url: '/v1/my-info', method: 'GET', headers: {} },
        update: { url: '/v1/gestao-recrutador/{id}', method: 'PUT', headers: {} },
      }));
    this.entidade = new RecrutadorEntity();
  }

  public loadMyInfo(callback?: () => void): Promise<any> {
    return super.myInfo().then((data: any) => {
      this.entidade = data.entity;
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
// tslint:disable-next-line: no-console
      console.log('Couldn\'t load my info.', err);
    });
  }

  public async isAdm(): Promise<boolean> {
    await this.loadMyInfo();
    return  (this.entidade && this.entidade.permissaoAdm === 'Y') || false;
  }

  public saveAvatar(file: any): Promise<any> {
    return this.saveFile('avatar', file);
  }

  public saveFile(key: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', '' + this.entidade.id);
    formData.append(key, file);
    return super.update(formData, 'multipart/form-data');
  }

}
