
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Pais extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/pais', method: 'POST', headers: {} },
                fetch: { url: '/v1/pais/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/pais', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/pais/{id}', method: 'PUT', headers: {} } }));
        this.optionList = [];
    }

    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.paises) {
            this.optionList = [
                {
                    text: this.getI18n().t('Selecione'),
                    value: ''
                }
            ];
            data.paises.forEach((item: any) => {
                this.optionList!.push({
                    text: item.nome,
                    value: item.sigla,
                });
            });
        }
    }

}
