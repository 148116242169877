<template>
  <div>
    <b-card :title="questionary.name" v-if="loaded && questionariesCandidate">
      <b-card-text >
        <b-row>
          <b-col md="6">
            <div>
              <span>{{$t('Total Candidate Points')}}: </span> 
              <strong> 
                <i class="fa fa-check text-success" v-if="passed" />
                <i class="fa fa-times text-danger" v-else />
                {{currentScore}} pts 
              </strong>
            </div>
            <div>
              <span>{{$t('Start Time')}}: </span> {{$showDateFromObject(questionariesCandidate.dateStart)}}
            </div>
            <div>
              <span>{{$t('End Time')}}: </span> {{$showDateFromObject(questionariesCandidate.dateEnd)}}
            </div>
          </b-col>
          <b-col md="6">
            <div>
              <span>{{$t('Minimun to Pass')}}: </span> <strong> {{totalCorte}} pts </strong>
            </div>
            <div>
              <span>{{$t('Time to perform the test')}}: </span> {{questionaryJob.tempoMaximo}}min
            </div>
          </b-col>
        </b-row>
      </b-card-text>
      <form-exam v-if="questionariesCandidate.answered" :evaluation="true" 
        :questionary="questionary" 
        :answers="answers"
        @saved="saved"
        @pointGiven="pointGiven"  />
      <b-alert variant="warning" v-else>
        {{$t('Candidate not answered until this moment')}}.
      </b-alert>
    </b-card>
    <div class="my-3 d-flex justify-content-center" v-else-if="!loaded"> 
      <b-spinner label="Loading..." style="width: 5rem; height: 5rem;" variant="primary"></b-spinner>
    </div>
    <b-alert v-else variant="info" show> {{$t('Nothing Found')}} </b-alert>
  </div>
</template>

<script lang="ts">
// List Company evaluations
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FormExam from './partials/FormExam.vue';
import { QuestionaryCandidatoEntity, QuestionaryEntity, QuestionaryJobEntity, QuestionEntity } from '@/core/models/Entities';
import CompanyExamEvaluation from '@/core/Services/Exams/CompanyExamEvaluation';

@Component({
  components: {
    FormExam
  }
})
export default class EditExam extends Vue {
  public service: CompanyExamEvaluation;
  public loaded: boolean = false;
  public answers: any[]|null = null;
  public questionariesCandidate: QuestionaryCandidatoEntity|null = null;
  
  @Prop({default: () => { return new QuestionaryEntity(); }})
  public questionary!: QuestionaryEntity
  
  @Prop({default: () => { return new QuestionaryJobEntity(); }})
  public questionaryJob!: QuestionaryJobEntity

  @Prop()
  public cid!: number

  @Prop()
  public token!: number
  
  constructor() {
    super();
    this.service = new CompanyExamEvaluation();
  }

  @Watch('cid')
  public candidateChanged() {
    this.loadData()
  }

  get totalCorte() {
    let total: number = 0;
    this.questionary.questions.forEach((q: QuestionEntity) => {
      if (q.questionTypeId < 3)
        total += q.points;
    })
    return Math.round((total*(this.questionaryJob.notaCorte/100.0))*100)/100.0
  }

  get currentScore() {
    return this.questionariesCandidate?.score||0;
  }

  get passed() {
    return this.currentScore > this.totalCorte;
  }

  public mounted() {
    this.loadData()
  }

  public loadData() {
    this.loaded = false;
    if ((this.cid > 0) || this.token) {
      this.service.fetchWithData({
        id: this.cid||0,
        token: this.token
        }).then((data: any) => {
        if (data && data.answers ) {
          this.answers = data.answers;
          this.questionariesCandidate = data.candidateQuestionary[this.questionary.id];
        }
      }).finally(() => {
        this.loaded = true;
      })
    } else {
      this.loaded = true;
    }
  }

  public saved(q: QuestionaryEntity) {
    this.$alert.addMsg(this.$t('Saved successfully').toString());
    this.$alert.callBoxCheckWithTitle(this.$t('Ok').toString());
  }
  
  public pointGiven(answerId: number, questionaryAnswerCandidateId: number, points: number, candidaturaStatus: string ) {
    this.$emit('pointGiven',answerId,questionaryAnswerCandidateId,points,candidaturaStatus);
  }


}
</script>

<style lang="scss">

</style>