<template>
    <b-container class="mt-5 position-relative m-h-400" ref="containerLoader">
        <b-row>
            <b-col md="12">
                <b-card no-body >
                    <transition name="slide-fade" v-if="!loadingEvent">
                        <b-tabs pills card vertical class="template-page-tabs">
                            <b-tab :title="$t('E-mail Templates')" disabled><p>_</p></b-tab>
                            <b-tab v-for="(conf,i) in list" :key="i" :title="$t(conf.label)" lazy>
                                <form-mail-template :code="conf.code" :language="'en'" />
                                <!-- <b-tabs class="template-page-tabs-lang" fill>
                                     <b-tab v-for="(lang,i) in conf.langs" :key="i" :title="getLanguageTitle(lang)" lazy >
                                        <form-mail-template :code="conf.code" :language="lang" />
                                    </b-tab> 
                                </b-tabs> -->
                            </b-tab>
                        </b-tabs>
                    </transition>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script lang="ts">
// List Company evaluations
import { Vue, Component, Prop } from 'vue-property-decorator';
import MailTemplate from '@/core/Services/Empresa/MailTemplate';
import FormMailTemplate from './partials/FormMailTemplate.vue';


@Component({
    components: {
        FormMailTemplate
    }
})
export default class MailTemplates extends Vue {
    public service: MailTemplate;
    public loading: boolean = true;
    public list: { code: string, label: string,langs: string[] }[] = [];

    constructor(d: any){
       super(d);
       this.service = new MailTemplate();
    }

    public mounted() {
        const _: any = this;
        _.openLoading(this.$refs.containerLoader);
        this.service.fetchAll().then((data: any) => {
            this.list = data;
        }).finally(() => {
            _.closeLoading();
        })
    }

    public getLanguageTitle(lang: string) {
        switch(lang) {
            case 'en':
                return 'English';
            case 'es':
                return 'Español';
            case 'pt-BR':
                return 'Português';
            default:
                return lang;
        }
    }
}
</script>