<template>
    <span class="label-text">{{ label }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        label: String
    }
})
export default class TextLabel extends Vue {}
</script>

<style lang="sass" scoped>
.label-text
  font-weight: bold
  margin-bottom: 0
  
</style>
