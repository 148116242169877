<template>
  <b-modal :id="id" 
  :title="title" 
  :size="size" 
  :header-class="headerClass" 
  :centered="centered"
  :no-enforce-focus="noEnforceFocus" 
  :hide-footer="hidefooter"
  :hide-header="hideHeader">
    <slot></slot>
    <template v-slot:modal-footer>
      <slot name="footer"></slot>      
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';


@Component
export default class Modal extends Vue {
  @Prop({default: ''})
  public id!: string;

  @Prop({default: ''})
  public title!: string;

  @Prop({default: ''})
  public headerClass!: string;

  @Prop({default: false})
  public centered!: string;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: false})
  public noEnforceFocus!: boolean;

  @Prop({default: false})
  public size!: boolean;

  @Prop({default: false})
  public hidefooter!: boolean;  
  
  @Prop({default: false})
  public hideHeader!: boolean;  
}
</script>

<style>

</style>