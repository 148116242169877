<template>
  <b-modal
    :id="id"
    centered
    :hide-header="true"
    :hide-footer="true"
    size="xl"
    title="Large Modal"
    ref="modal"
    body-class="p-0"
    lazy
  >
    <div class="modal-content m-content">
      <button class="btn btn-danger" @click="$bvModal.hide('modalCandidateMatch')">
        <i class="icon-cancelar"></i>
      </button>

      <!-- Title -->
      <div class="row m-content__title" >
        <div class="col-12">
          <h1>{{$t('Match between candidate and company')}}</h1>
        </div>
      </div>

      <b-row>
        <b-col :md="(hasPercentage ? '8': '12')">
          <apexchart type="radar" height="450" :options="chartOptions" :series="series"></apexchart>
        </b-col>
        <b-col md="4 d-flex w-100" v-if="hasPercentage">
          <b-alert variant="info" class="text-center w-100 d-flex  flex-wrap align-content-center align-items-center justify-content-center" show>
            <h4>{{$t('Match')}}</h4>
            <h1 class="font-weight-bold text-center w-100 large-heading p-0">
              {{candidatura.matchCompany.percentage + '%'}}
            </h1>
          </b-alert>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity } from "@/core/models/Entities";
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts';
import MatchType from '@/core/Services/Assessment/MatchType';

@Component({
  components: {
    'apexchart': VueApexCharts
  }
})
export default class ModalCandidateMatch extends Vue {
  public seriesEmpresa: number[] = [];
  public seriesCandidato: number[] = [];
  public service: MatchType = new MatchType();
  public series: any[] = [];
  public chartOptions: ApexOptions = {
            chart: {
              width: 450,
              type: 'radar',
              dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
              }
            },
            stroke: {
              width: 2
            },
            fill: {
              opacity: 0.1
            },
            markers: {
              size: 0
            },
            xaxis: {
              categories: ['Time', 'Inovação', 'Resultado', 'Processo']
            }
          };

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;

  @Prop({default: () => { new CandidaturaEntity() }})
  public candidatura!: CandidaturaEntity;

  @Prop({ default: false })
  public isPublic!: boolean;

  @Prop({ default: false })
  public token!: string;

  @Prop()
  public labels!: string[];

  get habNames() {
    let habilidadeNames = this.candidatura.vagaHabilidades
    return habilidadeNames!.map( _ => { return _.name });
  }

  get hasPercentage() {
    return this.candidatura.matchCompany && this.candidatura.matchCompany.percentage
  }

  public async habMatch() {
    // return vagaIdsArr.filter(val => candIdsArr.includes(val))
    this.seriesEmpresa = [0,0,0,0];
    this.seriesCandidato = [0,0,0,0]; 
    if (this.candidatura && this.candidatura.id !== undefined) {
      let data: any = null
      if(!this.isPublic) {
        data = await this.service.fetch(this.candidatura.id)
      } else if(this.token) {
        data = await this.service.fetchWithData({ id: 0, _tkn: this.token, from: this.$route.name })
      } else {
        console.error('No Token or identifier informed')
        return;
      }
        
      let xaxis: string[]= [ 
        this.$t('Team').toString(),
        this.$t('Innovation').toString(),
        this.$t('Result').toString(),
        this.$t('Process').toString()
      ];
      if (data.empresa) { 
        data.empresa.forEach((matchResult: any,index: number) => {
          this.seriesEmpresa[index] = matchResult.score
        });
      }
      if (data.candidato) { 
        data.candidato.forEach((matchResult: any,index: number) => {
          this.seriesCandidato[index] = matchResult.score
        });
      }
      this.series = [{
            name: this.$t('Company').toString(),
            data: this.seriesEmpresa,
          }, {
            name: this.$t('Candidate').toString(),
            data: this.seriesCandidato,
          } ];
      if (this.chartOptions.xaxis)
        this.chartOptions.xaxis.categories = xaxis;
    } 
  }

  public mounted() {
    this.habMatch();
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main";

.m-content {
  padding: 3em;
  p {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.9em;
    padding-bottom: 1em;
  }
  button.btn-danger {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0;
    top: 0;
    right: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.8em;
      display: flex;
    }
  }

  .left {
    text-align: -webkit-center;
    border-right: solid 1px lightgray;
  }
  
  .right {
    &__image {
      img {
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        width: 32px;
        height: 32px;
      }
    }

    &__nivel-status-text {
      margin: 0;
    }

    .nivel {
      padding: 0;
      label {
        font-size: 11px
      }
    }
  }
}
</style>

<style lang="scss">
#modalAddJobcoins .modal-body {
  padding: 0;
}

#modalAddJobcoins .modal-dialog {
  width: 650px;
}

.m-content__jobcoin-input--input input.dark{
  font-size: 3rem;
  text-align: center;
}
</style>