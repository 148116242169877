import { render, staticRenderFns } from "./Recrutadores.vue?vue&type=template&id=567df6cf&scoped=true"
import script from "./Recrutadores.vue?vue&type=script&lang=ts"
export * from "./Recrutadores.vue?vue&type=script&lang=ts"
import style0 from "./Recrutadores.vue?vue&type=style&index=0&id=567df6cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567df6cf",
  null
  
)

export default component.exports