// tslint:disable
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { ProfileState } from "./types";
import { EmpresaEntity, PerfilEmpresaEntity, RecrutadorEntity } from '@/core/models/Entities';
import router from '@/router'

export const getters: GetterTree<ProfileState, RootState> = {
    authorizationHeader(state: any): string {
        return `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`;
    },
    getProfile(state: any): any {
        return sessionStorage.getItem("profile");
    },
    getProfileType(state: ProfileState): any {
        return sessionStorage.getItem("profile_type");
    },
    isAuthenticated(state: any):boolean {
        const c:boolean = !!(state.token||sessionStorage.getItem("access_token"));
        return c
    },
    authStatus(state: any): any {
        return state.status;
    },
    companyInfo(state: ProfileState): EmpresaEntity|undefined|null {
        return state.company_info;
    },
    saldInfo(state: ProfileState): number|undefined|null {
        return state.saldo;
    },
    recruiterInfo(state: ProfileState): RecrutadorEntity|undefined|null {
        return state.recruiter_info;
    },
    profileInfo(state: ProfileState): PerfilEmpresaEntity|undefined|null {
        return state.profile_info;
    },
    isEntrevistaPage() {
        return router.currentRoute.name === 'Entrevista';
    }
};
