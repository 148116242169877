<template>
  <b-sidebar
      :id="id"
      :aria-controls="id"
      :aria-expanded="value?'true':'false'"
      :title="$t('Reviews of {name}', {name: theName })"
      width="400px"
      v-model="value"
      @shown="shown"
      @hidden="hidden"
      lazy
      right 
    >
    <div>
      <form-avaliacao
        :vaga="vaga"
        @evaluated="saved"
        :candidato="candidato"
        :coUID="coUID"
        :raToken="raToken"
        :publicName="publicName"
        :publicEmail="publicEmail"
        v-if="candidato"
        col-size="12"
      >
      </form-avaliacao>
    </div>
  </b-sidebar>
</template>

<script lang="ts">
import { CandidatoEntity, VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormAvaliacao from '../FormAvaliacao.vue';

@Component({
  components:{
    FormAvaliacao
  }
})
export default class SidebarCreateAvaliacao extends Vue {

  @Prop({default: null})
  public id!: string;

  @Prop({default: null})
  public coUID!: string;

  @Prop({default: null})
  public raToken!: string;

  @Prop({default: null})
  public publicName!: string;

  @Prop({default: null})
  public publicEmail!: string;
  
  @Prop({default: false})
  public value!: boolean;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
  @Prop({ default: () => { new CandidatoEntity() } })
  public candidato!: CandidatoEntity;

  get theName() {
    return this.candidato?.primeironome||this.$t('Candidate').toString()
  }

  public saved() {
    this.$emit('done')
    this.$emit('input',false)
  }

  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }
  
}
</script>

<style lang="scss" scoped>

</style>