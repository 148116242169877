import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';
import ClientAuthentication from '../ClientAuthentication';


export default class MailTemplate extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/mail-template', method: 'POST', headers: {} },
            fetch: { url: '/v1/mail-template/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/mail-template', method: 'GET', headers: {} }, 
            delete: { url: '/v1/mail-template/{id}', method: 'DELETE', headers: {} },
            update: { url: '/v1/mail-template/{id}', method: 'PUT', headers: {} },
        }));
    }
}
