<template>
  <transition name="fade">
      <b-row class="d-flex flex-wrap align-items-center justify-content-center">
        <b-col md="4" class="p-0">
          <b-img src="https://storage.googleapis.com/jobecam-assets/mulher_mgm.png" fluid alt="Invite Member Get Member"></b-img>
        </b-col>
        <b-col md="8" class=" py-4 bg-sidebar shadow">
          <b-row>
            <b-col md="12" class="text-center ">
              <h3> {{$t('Invite your colleagues and receive LIVE interview bonuses')}} </h3>
              <p>{{$t('Enter the details of your guest, at each acceptance, you will receive your prize')}} </p>
            </b-col>
            <b-col md="12" >
              <b-row class="text-center">
                <b-col md="1" class="border-bottom mb-4 pb-1">
                  <h6> &nbsp; </h6>
                </b-col>
                <b-col md="5" class="border-bottom mb-4 pb-1">
                  <h6> {{$t('Name')}} </h6>
                </b-col>
                <b-col md="5" class="border-bottom mb-4 pb-1">
                  <h6> {{$t('Corporate E-mail')}} </h6>
                </b-col>
                <b-col md="1" class="border-bottom mb-4 pb-1">
                  <h6> - </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" v-for="(v,index) in inviteList" :key="index">
              <form-invite v-model="inviteList[index]" />
            </b-col>
            <b-col md="5" offset-md="1">
                <b-button variant="pink" class="shadow" block  @click="sendInvites()"> 
                  <i class="fa fa-envelope"></i>  {{$t('Send Invites')}} 
                </b-button>
            </b-col>
            <b-col md="5" >
                <b-button variant="outline-yellow" block @click="remountInviteArray()"> 
                  <i class="fa fa-trash"></i>  {{$t('Clear')}} 
                </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </transition>
</template>

<script lang="ts">
import InviteMember from '@/core/Services/Invite/InviteMember';
import { ConviteEmpresaEntity } from '@/core/models/Entities';
import { Component, Vue } from 'vue-property-decorator';
import FormInvite from './partials/FormInvite.vue';

@Component({
  components: {
    FormInvite
  }
})
export default class NewInvite extends Vue {
  public inviteList: ConviteEmpresaEntity[] = [];
  public service: InviteMember = new InviteMember();

  public mounted() {
   this.remountInviteArray()
  }
  public remountInviteArray() {
    this.inviteList = [];
    for (let index = 0; index < 5; index++) {
      this.inviteList.push(new ConviteEmpresaEntity());
    }
  }

  public async sendInvites() {
    const l = this.inviteList.length;
    // validate
    for (let i = 0; i < l; i++) {
      const el: ConviteEmpresaEntity = this.inviteList[i];
      if(!el.emailConvidado)
        continue;
      
      if(!el.nomeConvidado) {
        this.alertFor(el);
        return;
      }
      el.sending = true;
    }
    for (let i = 0; i < l; i++) {
      const el: ConviteEmpresaEntity = this.inviteList[i];
      
      if(!el.emailConvidado || !el.nomeConvidado)
        continue;

      
      if(el.sended)
        continue;

      await this.service.create(el).then((e) => {
        this.inviteList[i].sended = true;
        this.inviteList[i].error = false;
        this.toastFor(el);
      }).catch((e: any) => {
        this.inviteList[i].error = true;
        this.$toast.addTitle(e.response.data.detail)
        this.$toast.addMsg(this.$t('Error by sending an invitation to').toString() +' '+ el.emailConvidado)
        this.$toast.variant="danger";
        this.$toast.open()
      })
      this.inviteList[i].sending = false;
    }
  }

  public alertFor(cc: ConviteEmpresaEntity) {
    this.$alert.addMsg(this.$t('Necessary to inform the name and email of the guest'))
    this.$alert.callBoxWarn()
  }
  public toastFor(cc: ConviteEmpresaEntity) {
    this.$toast.addMsg(this.$t('Invitation sent to').toString() +' '+ cc.emailConvidado)
    this.$toast.variant="success";
    this.$toast.open()
  }
}
</script>

<style>

</style>