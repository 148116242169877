<template>
  <div class="box-container d-flex flex-column mb-5">
    <plural-header
      :titulo="post.arquivo"
    />

    <div class="row">
      <div class="col-md-12 mb-4">
        <img :src="post.arquivo" alt="Imagem Artigo">
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div v-html="post.texto"> </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PluralPostService from '@/core/Services/Plural/PluralPost';
import PluralHeader from '@/views/plural/PluralHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component<PluralArtigoView>({
  components: {
    'plural-header' : PluralHeader
  }
})
export default class PluralArtigoView extends Vue {
  public service: PluralPostService;
  public post: any = null;
  public url: any = null;
  public loadingPage:boolean = false;


  constructor() {
    super();
    this.service = new PluralPostService(); 
  }  

  public async mounted() {
    this.loadingPage = true;

    this.post = this.$route.params.post;
    console.log(this.post)
    if(this.post == undefined || this.post == null){
      this.url = this.$route.params.url;
      this.service.fetchAll({s:this.url}).then((data: any) => {
        console.log(data);
        this.post = data['pluralPosts'][0];
      }).catch((err) => { 
        console.log(err) 
      }).finally(()=> {
        this.loadingPage = false;
      })
    }  
  }  


}
</script>