
import { EmpresaEntity } from '@/core/models/Entities';
import Empresa from '@/core/Services/Empresa/Empresa';
import GestaoCreditoService from "@/core/Services/Empresa/GestaoCredito";
import { i18n } from '@/plugins/i18n';
import { ProfileState } from '@/store/profile/types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

function removeItensFromStorage(): void {
  sessionStorage.removeItem('token_type');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('profile_type');
  sessionStorage.removeItem('profile');
}

function addItensOnStorage(t: string, tt: string, pt: string): void {
  sessionStorage.setItem('access_token', t);
  sessionStorage.setItem('token_type', tt);
  sessionStorage.setItem('profile_type', pt);
}

export const actions: ActionTree<ProfileState, RootState> = {
  authRequestCompany({ commit, dispatch }: any, user: any): Promise<any> {
      return new Promise((resolve: any, reject: any) => {
        const empresa: any = new Empresa();
        // TROCAR AQUI PARA CHAMAR OS DADOS DO RECRUTADOR
        empresa.authRecruiter(user).then((data: any) => {
          if (data.access_token != null) {
            addItensOnStorage(data.access_token, data.token_type, 'recruiter');
            commit('authSuccess', data); // to mutator
            dispatch('companyRequest'); // to action
          } else {
            commit('authError');
            removeItensFromStorage();
          }
          // here set the header of your ajax library to the token value.
          // example with axios
          resolve(data);
        }).catch((reason: any) => {
          if(reason.title==='expired_password' || reason.title==='two_factor_confirmation_needed'){
            console.log('expired_password or two_factor_confirmation_needed');
            reject(reason);
            return;
          }
          commit('authError', reason);
          removeItensFromStorage();
          reject(reason);
      });
    });
  },
  companyRequest({ commit, dispatch }: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      new Empresa().me().then((response) => {
        sessionStorage.setItem('profile', JSON.stringify(response));
        i18n.locale = response.lang;
        commit('companySucess', response);
        dispatch('fetchEmpresaInfo'); // to action
        resolve(response);
      }).catch((reason) => {
        commit('authError');
        reject(reason);
      });
    });
  },
  authLogout({commit, dispatch}: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      try {
        removeItensFromStorage();
        resolve('deslogado');
      } catch (e) {
        reject(e);
      }
    });
  },
  setEmpresaData({commit}: any, data: object) {
    commit('setEmpresaData', data);
  },
  loadEmpresaInfo({state, dispatch}): Promise<EmpresaEntity> {
    if (state.company_info) {
      // Se o dado já existir retornar promise resolvida com valor
      return new Promise((resolve) => {
        resolve((state.company_info as EmpresaEntity));
      });
    }
    return dispatch('fetchEmpresaInfo');
  },
  fetchEmpresaInfo({commit, dispatch}): Promise<EmpresaEntity> {
    return new Promise(async (resolve, reject) => {
      try {
        const service: Empresa = new Empresa();
        console.log('dispach to FEI')
        await service.loadMyInfo().then(() => {
          commit('setEmpresaInfo', service.entidade);

          if (service.entidadeRecrutador) {
            commit('setRecruiterInfo', service.entidadeRecrutador);
            sessionStorage.setItem('profile_type', 'recruiter');

          }

          resolve(service.entidade);
        });
        dispatch('fetchSaldo');
      } catch (e) {
        reject(e);
      }
    });
  },
  fetchSaldo({commit}): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        const service: GestaoCreditoService = new GestaoCreditoService();
        console.log('dispach to FEI')
        await service.fetchAll({saldOnly: true}).then((data: any) => {
          commit('setSaldInfo', data?.saldo||0);
          resolve(data?.saldo||0);
        });
      } catch (e) {
        reject(e);
      }
    });
  },
};
