<template>
  <div class="main">
    <div class="box-container d-flex flex-column" v-if="empty">
      <div class="container text-center no-messages">
        <img src="https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/message-img.png" alt="">
        <div><p>{{$t("You have no message")}}</p></div>
      </div>
    </div>
    
    <div class="messaging" v-else>
      <div class="inbox_msg">
        <div class="inbox_people" :class="{ 'isShow' : !(mobileIsShowMessages) }">
          <!-- <div class="headind_srch">
            <div class="row">
              <div class="col-12">
                <h1>{{$t("Mensagens")}}</h1>
              </div>
              <div class="col-12">
                <b-button
                  variant="blue"
                  class="rounded-pill mx-auto"
                  size="sm"
                  pill
                  v-b-modal.modalNewMessage
                >
                  <icon name="edit"></icon>
                  {{$t("ESCREVER MENSAGEM")}}
                </b-button>
              </div>
            </div>
          </div> -->
          <!-- Tabs -->
          <lista-mensagens @select="loadMessage" />
      </div>
      <mensagem ref="msgs" @sended="sended" :mobile="isMobile" @back="handleBack" />
    </div>
    </div>
    <modal-new-message />
  </div>
</template>

<script lang="ts">
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import ModalNewMessage from '@/components/modal/ModalNewMessage.vue';
import SearchInput from '@/components/search/Search.vue';
import MessageJob from '@/core/Services/Basic/MessageJob';
import { MessageJobEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import ListaMensagens from './ListaMensagens.vue';
import Mensagem from './Mensagem.vue';

@Component({
  components: {
    'search-input': SearchInput,
    CheckboxInput,
    TextInput,
    ListaMensagens,
    Mensagem,
    ModalNewMessage
  }
})
export default class Mensagens extends Vue {
  
  public messageJobService: MessageJob = new MessageJob;
  public tabActive = 1;
  public mobileIsShowMessages = false;
  public empty: boolean = false;
  public created() {
  }
  public doSearch(value: string) {
    console.log(value)
  }
  public closeModal() {
    this.$bvModal.hide('modalNewMessage');
  }

  get isMobile() {
    console.log('isMobile')
    return this.mobileIsShowMessages;
  }

  public sended(message?: MessageJobEntity) {    
    // TODO something
    this.loadOnlyMessages(message);
    this.mobileIsShowMessages = true;
  }

  public handleBack(v: boolean) {
    this.mobileIsShowMessages = v;
  }

  public loadOnlyMessages(message?: MessageJobEntity) {     
    if(message) {
      this.messageJobService.fetch(message!.id||0)
      .then((data) => {
        this.mobileIsShowMessages = true;
        if(data?.mensagens?.length > 0)
          (<Mensagem>this.$refs.msgs).setMessagesList(data.mensagens);
        // (<Mensagem>this.$refs.msgs).setMobile(this.mobileIsShowMessages);
      });
    }
  }

  public beforeDestroy() {
    const _this: any = this;
    console.log('clearPID message,',_this.messagePID)
    clearInterval(_this.messagePID)

  }

  public loadMessage(message?: MessageJobEntity) {    
    if (message) {
      this.messageJobService.fetch(message!.id||0)
      .then((data) => {
        this.mobileIsShowMessages = true;
        if (!message)
          this.empty = data.mensagens.length == 0;
        (<Mensagem>this.$refs.msgs).setRoot(message);
        (<Mensagem>this.$refs.msgs).setMessagesList(data.mensagens);
        // (<Mensagem>this.$refs.msgs).setMobile(this.mobileIsShowMessages);
      });
      const _this: any = this;
      clearInterval(_this.messagePID)
      _this.messagePID = setInterval(() => {
        console.log('load message')
        this.loadOnlyMessages(message);
      },10000);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

img {
  max-width: 100%;
}
.inbox_people {
  background: #ffffff none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 30%;
  margin-right: 0.5em;
  border-radius: 0.5em;
  height: 100%;
}
.inbox_msg {
  /* border: 1px solid #c4c4c4; */
  clear: both;
  overflow: hidden;
  height: 100%;
}

.headind_srch {
  padding: 2em 2em 1em 2em;
  overflow: hidden;
  h1 {
    color: $font-title-gray;
  }
  button {
    display: flex;
    i {
      margin: 0 10px;
      font-size: 1.3em;
      display: flex;
    }
  }
}

.no-messages {
  img {
    width: 400px;
  }
  p {
    font-size: 24px;
    color: cornflowerblue;
  }
}

.messaging {
  margin: 1em;
  height: calc(100vh - 55px - 2em);
}
::-webkit-scrollbar {
  width: 0.4em;
  background: #e3e0d6;
  margin: 1em;
  padding: 18px;
}
::-webkit-scrollbar-track {
  background: rgba(232, 232, 232, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(163, 243, 241);
}


// Media Queries
@include media(">desktop", "<large") {
  .main {
    font-size: 0.7em;
  }
}

@include media("<desktop") {
  .main {
    font-size: 0.6em;
  }

  .content-messages {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: calc(100vh - 90px);
  }
  .main-messages {
    font-size: 0.7em;
    padding: 0;
    .messaging {
      margin: 0;
    }

    .inbox_people {
      border-radius: 0;
      h1 {
        font-size: 3em;
      }
    }
  }
  .inbox_people {
    width: 100%;
    .mesgs-header {
      .go-to-messages {
        display: block;
      }
    }
  }
  
}
</style>

<style lang="scss">
#modalNewMessage .modal-body {
  padding: 0;
}
</style>