<template>
  <div class="tabs d-none">
    <b-card class="card-qt latest-candidaturas" no-body>
      <b-card-body class="card-qt__body">
        <!-- Tabela Completa -->
        <b-row no-gutters  class="card-qt__body__row" >
          <b-col sm="12">
            <b-table id="completeRep" striped hover responsive :items="items" :fields="fields" :busy="isBusy" >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:head()="data">
                {{ data.label.toUpperCase() }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>


<script lang="ts">
import Dashboard from '@/core/Services/Empresa/Dashboard';
import { siteCandidateEndPoint } from '@/core/endpoint';
import { Component, Vue } from 'vue-property-decorator';
import ExportTable from './ExportTable';

@Component
export default class ReportTable extends Vue {
  public service: Dashboard;
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;
  public fieldsSimple: any [] | null = null;
  public itemsSimple: any [] | null = null;
  public activeNav: number = 0;
  public dtIni: Date|null = null;
  public dtFim: Date|null = null;
  public vagaId: string = '';
  public vagaOptions: any[] = [];
  public hideJobs: boolean = false;

  constructor() {
    super();
    this.service = new Dashboard();
    this.isBusy = false;
  }

  
  public created() {
    this.dtFim = new Date();
    this.dtIni = this.referenceDate(this.dtFim, -30)
  }

  public async mounted() {
    this.activeNav = 0;
    if(this.$route.name == 'DashboardByJob'){
      this.hideJobs = true
    }

    this.loadFields();
    await this.loadReport()
  }

  public loadFields() {
    this.fields = [     
      { key: 'datapublicacao', label: this.$t('Publication'), class: 'text-left' },
      { key: 'recrutador', label: this.$t('Recruiter'), class: 'text-left' },
      { key: 'posicao', label: this.$t('Position'), class: 'text-left' },
      { key: 'status_vaga', label: this.$t('Job Status'), class: 'text-left' },
      { key: 'datacandidatura', label: this.$t('Application date'), class: 'text-left' },
      { key: 'idcandidatura', label: 'ID', class: 'text-left' },
      { key: 'nome_candidato', label: this.$t('Candidate'), class: 'text-left' },
      { key: 'email', label: this.$t('E-mail'), class: 'text-left' },
      { key: 'telefone', label: this.$t('Telephone'), class: 'text-left' },
      { key: 'link_perfil', label: this.$t('Profile'), class: 'text-left' },
      { key: 'link_curriculum_pdf', label: this.$t('Curriculum'), class: 'text-left' },
      { key: 'hidden_cv', label: this.$t('Anonymous Resume'), class: 'text-left' },
      { key: 'status_candidatura', label: this.$t('Status'), class: 'text-left' },
      { key: 'score', label: 'Score', class: 'text-left' },
      { key: 'perc_match', label: '% Match', class: 'text-left' },
      { key: 'avaliacao', label: this.$t('Recruiter Evaluation'), class: 'text-left' },
      { key: 'avaliacao_gestor', label: this.$t('Manager Evaluation'), class: 'text-left' },
      { key: 'nota_total', label: this.$t('Candidate note'), class: 'text-left' },
      { key: 'data_encerramento', label: this.$t('Closing'), class: 'text-left' },
      { key: 'diff_em_dias', label: this.$t('Closing time'), class: 'text-left' },
      { key: 'score_avg', label: this.$t('Exam average score'), class: 'text-left' },
      { key: 'genero', label: this.$t('Gender'), class: 'text-left' },
      { key: 'raca_cor', label: this.$t('Race/Ethnicity'), class: 'text-left' },
      { key: 'pcd', label: this.$t('People with disabilities'), class: 'text-left' },
      { key: 'grp_black', label: this.$t('Afro-descendants Community'), class: 'text-left' },
      { key: 'grp_lgbt', label: 'LGBTQIA+', class: 'text-left' },
      { key: 'grp_indigena', label: this.$t('Indigenous'), class: 'text-left' },
      { key: 'grp_refugiado', label: this.$t('Refugee and Immigrants'), class: 'text-left' },
      { key: 'grp_pcd', label: this.$t('PWD Group'), class: 'text-left' },
      { key: 'grp_fem', label: this.$t('Womens Movement'), class: 'text-left' },
      { key: 'grp_3ida', label: '50 +', class: 'text-left' },
    ];
    this.fieldsSimple = [
      { key: 'posicao', label: this.$t('Position'), class: 'text-left' },
      { key: 'datapublicacao', label: this.$t('Publication Date'), class: 'text-left' },
      { key: 'recrutador', label: this.$t('Recruiter'), class: 'text-left' },
      { key: 'candidaturas', label: this.$t('Applications'), class: 'text-left' },
      { key: 'entrevistas', label: this.$t('Recorded Interviews'), class: 'text-left' },
      { key: 'status_vaga', label: this.$t('Job Status'), class: 'text-left' },
      { key: 'grp_black', label: this.$t('Afro-descendants Community'), class: 'text-left' },
      { key: 'grp_lgbt', label: 'LGBTQIA+', class: 'text-left' },
      { key: 'grp_indigena', label: this.$t('Indigenous'), class: 'text-left' },
      { key: 'grp_refugiado', label: this.$t('Refugee and Immigrants'), class: 'text-left' },
      { key: 'grp_pcd', label: this.$t('PWD Group'), class: 'text-left' },
      { key: 'grp_fem', label: this.$t('Womens Movement'), class: 'text-left' },
      { key: 'pcd', label: this.$t('People with disabilities'), class: 'text-left' },
      { key: 'masculino', label: this.$t('Man'), class: 'text-left' },
      { key: 'feminino', label: this.$t('Woman'), class: 'text-left' },
      { key: 'h_trans', label: this.$t('Man Trans'), class: 'text-left' },
      { key: 'm_trans', label: this.$t('Woman Trans'), class: 'text-left' },
      { key: 'n_binario', label: this.$t('Non-binary'), class: 'text-left' },
      { key: 'outros', label: this.$t('Others'), class: 'text-left' }
    ];
  }

  public getDateString(v: Date) {
    const day = (v.getUTCDate()).toString();
    const month = (v.getUTCMonth() + 1).toString();
    const year = v.getUTCFullYear();
    return (day.length == 1 ? "0" + day : day) + "/" + (month.length == 1 ? "0" + month : month) + "/" + year;
  }

  public referenceDate(date: any, number: number) {
    let data: Date|null = null;
    try {
        if ((typeof date === "string")) { // dd/mm/yyyy
            data = new Date(parseInt(date.substring(6, 10)), parseInt(date.substring(3, 5)) - 1, parseInt(date.substring(0, 2)))
        } else {
            data = new Date(date);
        }

        const dataAux = new Date(data!);
        dataAux.setDate(data!.getDate() + (number));

        return dataAux;
    } catch (e) {
        console.error(e);
        return null;
    }
  }

  public downloadReport() {
    const table: any = document.querySelector('#'+ (this.activeNav==0?'completeRep':'simpleRep'));
    const fName: string = 'report_'+(new Date().getTime())+'.csv';
    const x = new ExportTable(table,fName,document.querySelector('#downloadLink'))
  }
  
  public async loadReport() {
    this.$emit('done',false);
    const filter = {report: 'table'}
    const vuid = this.$route.params.vguid;

    if(this.hideJobs) {
      filter['vuid'] = vuid;
    } else {
      if (this.dtIni) {
        filter['fts'] = `datap_i=${this.getDateString(this.dtIni)}`
      }
      if (this.dtFim) {
        filter['fts'] += (!!filter['fts'] ? ';' : '') + `datap_f=${this.getDateString(this.dtFim)}`
      }
      if (this.vagaId) {
        filter['fts'] += (!!filter['fts'] ? ';' : '') + `vid=${this.vagaId}`
      }
    }
    const data: any = await this.service.fetchAll(filter);
    this.items = [];
    this.itemsSimple = [];
    
    let dtIn: any = {};
    let dtInSimple: any = {};
    const arrayBinFields:string[] = ['grp_black','grp_lgbt','grp_indigena','grp_refugiado','grp_pcd','pcd','grp_fem','grp_3ida'];

    if (!this.hideJobs && this.vagaOptions.length == 0) {
      data['vagas'].forEach(vagaOptin => {
        this.vagaOptions.push({value: vagaOptin[0], text: vagaOptin[1]})
      });
    }
    data['report'].forEach((element: any ) => {
      this.fields!.forEach((elKey: any) => {
        dtIn[elKey.key] = !element[elKey.key] ? ' - ' : (element[elKey.key]+'').replace('{CANDIDATE_LINK}', siteCandidateEndPoint);
        if (element[elKey.key] == 'Sim')
          dtIn[elKey.key] = this.$t("Yes")  
        if (element[elKey.key] == 'Não')
          dtIn[elKey.key] = this.$t("No")
        if (element[elKey.key] == 'Não informado') 
          dtIn[elKey.key] = this.$t("Not Informed")
        

        if (arrayBinFields.indexOf(elKey.key) > -1 && element["aceite_dados_sensiveis"] != "1") {
          dtIn[elKey.key] = this.$t('Not Informed').toString();
        }
        if (elKey.key == 'genero') {
          dtIn[elKey.key] = this.$t(this.firstLetterCapitilize(element[elKey.key]?.toLocaleLowerCase())).toString()
        }
        
      });
      dtIn['hidden_cv'] = 'https://empresa.jobecam.com/t/info/'+element.cguid
      this.items!.push(dtIn);
      dtIn = {};
      if (!dtInSimple[element.id]) {
          dtInSimple[element.id] = {
            posicao: element.posicao,
            datapublicacao: element.datapublicacao,
            recrutador: element.recrutador,
            candidaturas: 0,
            entrevistas: 0,
            status_vaga: element.status_vaga,
            masculino: 0,
            feminino: 0,
            h_trans: 0,
            m_trans: 0,
            n_binario: 0,
            outros: 0,
            grp_pcd: 0,
            grp_black: 0,
            grp_fem: 0,
            grp_refugiado: 0,
            grp_indigena: 0,
            grp_lgbt: 0,
            grp_3ida: 0,
            ni: 0

          }
        } 
        dtInSimple[element.id].candidaturas += 1;
        dtInSimple[element.id].entrevistas += parseInt(element.n_respostas || "0");
        //diversidade grupos
        dtInSimple[element.id].grp_pcd += ((element.grp_pcd == 'Sim') ? 1 : 0);
        dtInSimple[element.id].grp_black += ((element.grp_black == 'Sim') ? 1 : 0);
        dtInSimple[element.id].grp_fem += ((element.grp_fem == 'Sim') ? 1 : 0);
        dtInSimple[element.id].grp_refugiado += ((element.grp_refugiado == 'Sim') ? 1 : 0);
        dtInSimple[element.id].grp_indigena += ((element.grp_indigena == 'Sim') ? 1 : 0);
        dtInSimple[element.id].grp_lgbt += ((element.grp_lgbt == 'Sim') ? 1 : 0);

        //diversidade genero        m('MASCULINO','FEMININO','MULHER TRANS','HOMEM TRANS','NÃO-BINÁRIO','OUTROS') 
        dtInSimple[element.id].masculino += ((element.genero == 'MASCULINO') ? 1 : 0)
        dtInSimple[element.id].feminino += ((element.genero == 'FEMININO') ? 1 : 0)
        dtInSimple[element.id].m_trans += ((element.genero == 'MULHER TRANS') ? 1 : 0)
        dtInSimple[element.id].h_trans += ((element.genero == 'HOMEM TRANS') ? 1 : 0)
        dtInSimple[element.id].n_binario += ((element.genero == 'NÃO-BINÁRIO') ? 1 : 0)
        dtInSimple[element.id].outros += ((element.genero == 'OUTROS') ? 1 : 0)
        dtInSimple[element.id].ni += ((element.genero == 'Não informado') ? 1 : 0)
       
    });
    let ks: string[]  = Object.keys(dtInSimple)
    ks.forEach((_key: string) => {
      this.itemsSimple!.push(dtInSimple[_key])      
    });
    this.$emit('done',true);
  }

  
  public firstLetterCapitilize(str: string) {
    if(!str)
      return '';
      
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  }
  
  public setActiveNav(nav: number) {
      this.activeNav = nav;
  }
  

}

</script>


<style lang="scss" scoped>

</style>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

$card-qt-border-color: #f1f1f1;
.tabs {
.nav {
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
  .card-qt {
    border: 0.13em solid $card-qt-border-color;
    border-radius: 1.25em;
    min-height: 250px;

    &__header {
      font-size: 12px;
      padding: 5px 20px;
      margin-top: 1.25em;
      color: $gray;
    }
    &__body {
      &__row {
        width: 100%;
        max-height: 3000px;
        overflow-x: auto;
        font-size: 12px;
      }
      &__content {
        border-left: 0.1em solid $card-qt-border-color;
        width: 100%;
        text-align: center;

        &:first-child {
          border-left: none;
        }

        &__qt {
          font-size: 2.7em;
        }
      }
    }
  }
.latest-candidaturas {
  &__image {
    img {
      width: 2.5em;
      border-radius: 50%;
    }
  }
  &__names {
    line-height: 1.2em;
  }
}
}
</style>