<template>
  <b-form-group
    :label="label+(required?' *':'')"
    class="contato-form-group"
    :class="variant"
    :state="state">
    <vue-tel-input
      :value="value"
      @input="handleInput($event)"
      :required="required"
      :placeholder="placeholder"
      :class="inputClasses"
      class="form-control"
      :autofocus="null"
    />
    <b-form-invalid-feedback v-if="invalidFeedback" :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
    <slot v-else name="invalidFeedback" />
  </b-form-group>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: ''}) public value!: string;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;

  public get inputClasses() {
    return {
      [this.variant]: !!this.variant,
      'was-validated': null !== this.state,
      'is-invalid': false === this.state,
      'is-valid': this.state,
    };
  }

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $light-grey: #cfcfcf;
  $blue: #00abcc;

  .form-group {
    & .col-form-label,
    &:valid .col-form-label {
      color: white !important;
    }

    .form-control {
      &::placeholder {
        color: rgba(140,140,140,0.65) !important;
      }

      &.vue-tel-input {
        border: none;
        border-bottom: 1.5px solid $light-grey !important;
        box-shadow: none;

        .was-validated &:invalid,
        &.is-invalid,
        &.is-invalid:focus {
          border-bottom-color: #dc3545 !important;
        }

        .was-validated &:valid, &.is-valid {
          border-color: #28a745;
          padding-right: calc(1.5em + 0.75rem);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right calc(0.375em + 0.1875rem) center;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }

        .was-validated &:valid:focus, &.is-valid:focus {
          border-color: #28a745;
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        }
      }
    }
  }
</style>
<style lang="scss">
  $light-grey: #cfcfcf;
  $blue: #00abcc;

  .contato-form-group {
    &.dark input {
      border-bottom-color: $light-grey !important;
      color: $blue !important;
    }
    &.dark input:focus {
      color: $blue !important;
      border-bottom-color: $blue !important;
    }

    & .col-form-label {
      font-size: 12px;
      padding: 0;
      color: $light-grey;
    }
    &:valid .col-form-label {
      font-weight: bold;
      color: white;
    }

    &.dark .col-form-label {
      color: #000;
    }
    &.dark:valid .col-form-label {
      color: #000;
    }

    &.dark.is-invalid .col-form-label {
      color: #dc3545;
    }
  }
</style>

