<template>
  <header>
    <b-navbar toggleable="lg" type="dark" class="bg-blue py-3">
      <b-navbar-brand :href="logoHref">
        <logo />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-nav class="ml-auto">
          <b-nav-item :href="logoHref" active>{{$t("Back")}}</b-nav-item>
          <!-- <b-nav-item href="#" active>{{$t("About us")}}</b-nav-item> -->
          <!-- <b-nav-item href="#" active>{{$t("Plans")}}</b-nav-item> -->
          <!-- <b-nav-item href="#">{{$t("Help")}}</b-nav-item> -->
          <!-- <b-nav-item href="https://blog.jobecam.com" target="_blank">Blog</b-nav-item> -->
        </b-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import Logo from '@/components/logo/Logo.vue';

@Component({
  components: {
    Logo,
  },
})
export default class PublicHeader extends Vue {
  private siteIsInPortuguese = /pt-br/i.test(this.$i18n.locale);

  public logoHref = `https://jobecamlanding.com/${this.siteIsInPortuguese ? '' : this.$i18n.locale}`;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
@import "@/assets/scss/main";

header {
  font-family: nerislight;
  font-size: 1rem;

  width: 100%;

  img.logo-image {
    height: 60px;
  }

  li.nav-item a {
    color: $white;
    border-radius: 50rem;

    &:hover {
      transition: 100ms;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  margin-bottom: 32px;
}
</style>
