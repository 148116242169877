// tslint:disable:no-string-literal
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class VagaPerguntaCorte extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/pergunta-corte', method: 'POST', headers: {} },
            delete: { url: '/v1/pergunta-corte/{id}', method: 'DELETE', headers: {} },
            fetch: { url: '/v1/pergunta-corte/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/pergunta-corte', method: 'GET', headers: {} }, // tem parametro de Busca "s"
            update: { url: '/v1/pergunta-corte/{id}', method: 'PUT', headers: {} }
        }));
    }
}