<template>
  <i v-bind:class="iconClass"></i>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {

  @Prop({default: ''}) public name!: string;
  @Prop({default: ''}) public size!: string;
  @Prop({default: ''}) public color!: string;

  public iconName = Boolean(this.name) ? this.name : 'info'
  public iconSize = Boolean(this.size) ? this.size : 'lg';
  public iconColor = Boolean(this.color) ? this.color : 'inherit';
  public usefa = (this.iconName.indexOf('fa ') > -1 || this.iconName.indexOf('fas ') > -1 || this.iconName.indexOf('fa ') > -1) ? true : false;

  private _classes: string[] =  ['icon', `icon--${this.iconSize}`, `icon--${this.iconColor}`, `icon-${this.iconName}`];
  private _classesFA: string[] =  [`iconfa--${this.iconSize}`, `iconfa--${this.iconColor}`, `${this.iconName}`];
  public iconClass: string = (this.usefa ? this._classesFA : this._classes).join(' ');
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

/**
** Button Icon
**/


.iconfa {

  &--xs {
    font-size: 12px;
    line-height: 8px;
  }

  &--sm {
    font-size: 14px;
    line-height: 8px;
  }

  &--md {
    font-size: 18px;
    line-height: 8px;
  }

  &--lg {
    font-size: 24px;
    line-height: 14px;
  }

  &--inherit {
    color: inherit;
  }

  &--dark {
    color: #000000;
  }

  &--light {
    color: #ffffff;
  }

  &--primary {
    color: $blue;
  }
}

.icon {
  display: block;
  background: transparent;
  text-align: center;
  border: 0;

  &--xs {
    font-size: 12px;
    line-height: 8px;
  }

  &--sm {
    font-size: 14px;
    line-height: 8px;
  }

  &--md {
    font-size: 18px;
    line-height: 8px;
  }

  &--lg {
    font-size: 24px;
    line-height: 14px;
  }

  &--2x {
    font-size: 32px;
    line-height: 24px;
  }

  &--3x {
    font-size: 48px;
    line-height: 24px;
  }

  &--5x {
    font-size: 80px;
    line-height: 24px;
  }

  &--10x {
    font-size: 160px;
    line-height: 24px;
  }

  &--inherit {
    color: inherit;
  }

  &--dark {
    color: #000000;
  }

  &--light {
    color: #ffffff;
  }

  &--primary {
    color: $blue;
  }
}
</style>