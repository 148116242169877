
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { AssinaturaEntity } from '@/core/models/Entities';

export default class Assinatura extends Repository {
  public entidade: AssinaturaEntity;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/assinatura-pagamento', method: 'POST', headers: {} },
      fetch: { url: '/v1/assinatura-pagamento/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/assinatura-pagamento', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/assinatura-pagamento/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new AssinaturaEntity();
  }

  public fetchWithData(data: any): Promise<any>  {
    return super.fetchWithData(data).then((d1: AssinaturaEntity) => {
      if (d1 && d1.id)
        this.entidade = d1;
    })
  }

}
