// tslint:disable
import ApiRouter from "@/core/apirouting/EndpointRouterInterface";
import { clientId } from '@/core/endpoint';
import { CB } from "@/core/util";
import ClientAuthentication from "../ClientAuthentication";
import Repository from "../Repository";


export default class AccountCofirmation extends Repository {

  constructor(i18n?: any) {
    super(new ApiRouter({
      create: { url: "/v1/c-a-confirmation", method: "POST", headers: {} },
      update: { url: "/v1/c-a-confirmation/0", method: "PUT", headers: {} },
    }));
  }


    // altero somente o create
    public update(data: any, ctype?: string, blockCatch?: boolean): Promise<any> {
      return new Promise((resolve: any, reject: any) => {
          if (sessionStorage.getItem('access_token')) {
              super.call('update', data, (!ctype ? 'application/json' : ctype)).then(resolve).catch(reject);
          } else {
              (new ClientAuthentication()).auth().then((rtoken: any) => {
                  data.client_id = atob(clientId);
                  const dta: any = {
                      rtoken,
                      ...data
                  };
                  const cdta = CB.genC(dta);
                  super.call('update', cdta, (!ctype ? 'application/json' : ctype)).then(resolve).catch(reject);
              });
          }
      });
  }

}
