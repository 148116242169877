<template>
  <div class="section-progress">
    <div>
      <div class="etapas">
        <div
          class="etapa"
          :class="{'active': n <= progress.currentSteps, 'cursor-pointer': canChange}"
          v-bind:key="n"
          v-for="n in progress.countSteps"
          @click="changeCurrent(n)"
        >
          {{ n }}
          <label
            class="label-for-step"
            :class="{'active': n <= progress.currentSteps}"
          >{{ progress.steps[(n - 1)] }}</label>
        </div>
      </div>
      <b-progress height="0.3rem" :value="progress.currentSteps" :max="progress.countSteps" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

interface Progress {
  currentSteps: number;
  countSteps: number;
  steps: string[];
}

@Component({
  components: {}
})
export default class SectionProgress extends Vue {
  @Prop({ default: 1 }) 
  public progress!: Progress;

  @Prop({ default: false }) 
  public canChange!: boolean;

  public changeCurrent(step: any) {
    this.$emit('current',step)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/main";
@import "@/assets/scss/_variables";

.section-progress {
  position: relative;
  padding: 0 0.5em;
  height: 40px !important;
  margin-bottom: 80px !important;

  .etapas {
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    right: 5%;
    left: 5%;
    bottom: -25px;

    .etapa {
      display: flex;
      width: 49px;
      height: 49px;
      border: 1px solid $light-gray;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: $light-gray;
      color: #fff;
      font-size: 1.6em;
      &.active {
        border: 1px solid $blue;
        background-color: $green;
      }
      .label-for-step {
        color: $dark-gray;
        width: 115px;
        position: absolute;
        top: 47px;
        font-size: 0.6em;
        text-align: center;
        &.active {
          color: $green-light;
        }
      }
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }

  .progress {
    height: 1rem;
    position: absolute;
    right: 6%;
    left: 6%;
    bottom: 0;

    .progress-bar {
      background-image: linear-gradient(to right, $blue, $green-light, $green);
    }
  }
}

@include media("<desktop") {
  .label-for-step {
    display: none;
  }
}
</style>
