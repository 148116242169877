import _Vue from 'vue';
import moment from 'moment';
import { DateEntity } from '@/core/models/Entities';


export class MomentPluginOptions {
  // add stuff
}
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function MomentPlugin(Vue: typeof _Vue, options?: MomentPluginOptions): void {
  // do stuff with options
  Vue.prototype.$moment = moment;
  Vue.prototype.$showDateFromObject = (data?: DateEntity) => {
    moment.locale('pt-BR');
    if (data && data.date) {
      return moment.utc(data.date + '').local().format('DD/MM/YYYY HH:mm');
    }
    return '';
  };
  Vue.prototype.$showDate = (data?: string) => {
    moment.locale('pt-BR');
    return moment.utc(data).local().format('DD/MM/YYYY HH:mm');
  };
  Vue.prototype.$showDateResponse = (data?: string) => {
    moment.locale('pt-BR');
    return moment.utc(data).local().format('YYYY-MM-DD HH:mm');
  };
  Vue.prototype.$dateUTCDB = (data: Date, hour?: string, offset?: number) => {
    moment.locale('pt-BR');
    let t: number = 0;
    let i: number = 0;
    if (hour && hour.indexOf(':') > 0) {
      t = parseInt(hour.split(':')[0], 10);
      i = parseInt(hour.split(':')[1], 10);
    }
    const y: number = data.getUTCFullYear();
    const mo: number = data.getUTCMonth();
    const d: number = data.getUTCDate();
    return moment(new Date(y, mo, d, t, i, 0)).format('YYYY-MM-DD HH:mm');
  };
  Vue.prototype.$formatDate = (data: Date, formatStr: string, locale?: string) => {
    moment.locale(locale||'pt-BR');
    return moment(data).format(formatStr);
  };
}
